import { FooterTabBar, FooterTabIcon } from "../../board/FooterTabBar";
import { ArrayUtil } from "../../common/ArrayUtil";
import { UserSession } from "../../common/auth/UserSession";
import { BCSDate } from "../../common/BCSDate";
import { ServerConfigProperties } from "../../common/config/ServerConfigProperties";
import { I18n } from "../../common/i18n/I18n";
import { AppConsole } from "../../common/log/AppConsole";
import { Log } from "../../common/log/Log";
import { Component } from "../../core/Component";
import { ConfigNode } from "../../core/Config/ConfigNode";
import { Controller } from "../../core/Controller";
import { MessageEntity, MessageType } from "../../core/Message/MessageEntity";
import { MessagePool } from "../../core/Message/MessagePool";
import { Registry } from "../../core/Registry";
import { Animation, AppNavigator } from "../../core/Router";
import { DurationValue } from "../../entities/values/DurationValue";
import { OidValue } from "../../entities/values/OidValue";
import { StringValue } from "../../entities/values/StringValue";
import { SubMenu } from "../../gui/content/SubMenu";
import { GUIEventPool } from "../../gui/event/GUIEventPool";
import { formChangeEventType } from "../../gui/form/Form";
import { GUIContext } from "../../gui/GUIContext";
import { GUIPage } from "../../gui/GUIPage";
import { ListFieldFeedback, ListFieldFeedbackType } from "../../gui/list/ListFieldFeedback";
import { ListView } from "../../gui/list/ListView";
import { ListViewContext } from "../../gui/list/ListViewContext";
import { PageDeleteMode } from "../../gui/navigation/PageDeleteMode";
import { ToolBar } from "../../gui/navigation/ToolBar";
import { ToolLink } from "../../gui/navigation/ToolLink";
import { SyncState, SyncStateType } from "../../sync/SyncState";
import { SyncStateManager } from "../../sync/SyncStateManager";
import { Booking, BookingType } from "./bookings/Booking";
import { ForecastController } from "./forecast/ForecastController";
import { TimeAttibutesDefinitions } from "./TimeAttibutesDefinitions";
import { TimeRecord } from "./TimeRecord";
import { TimeRecordingGUIDefinitions } from "./TimeRecordingGUIDefinitions";
import { TimeRecordingManager } from "./TimeRecordingManager";
import { TimeRecordListViewModel } from "./TimeRecordListViewModel";
import { TimeChanged } from "./TimeRuleOfThree";
import { Appointment } from "./timesheet/Appointment";
import { Requirement } from "./timesheet/Requirement";
import { Ticket } from "./timesheet/Ticket";
import { TimesheetEntry } from "./timesheet/TimesheetEntry";
import { Workflow } from "./timesheet/Workflow";
import { TimeSpan } from "./timespans/TimeSpan";
import { BooleanValue } from "../../entities/values/BooleanValue";

export class TimeRecordingDetailController implements Controller {
    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static BCS_COMPONENT_NAME = "timeRecordingDetail";
    isBookingTargetSelected: boolean;
    private navigator: AppNavigator;
    private animation: Animation;
    private i18n: I18n;
    private timeRecordingManager: TimeRecordingManager;
    private syncStateManager: SyncStateManager;
    private serverConfigProperties: ServerConfigProperties;
    private page: GUIPage;
    private context: GUIContext;
    private messagePool: MessagePool;
    private eventPool: GUIEventPool;
    private userSession: UserSession;
    private timeRecord: TimeRecord;
    private originalRemainigEffort: number = 0;
    private bookedDuration: number = 0;
    private forecastRecord: {
        description?;
        time?;
        estimation?;
    } = {
        estimation: 0,
    };
    private controllerConfigNode: ConfigNode;
    private foreCastFieldsIgnoredForBooking: string[] = ["remainingDescription"];
    private usedListViewDef: object;
    private usedListViewDefName: string;
    private timeRecordListView: ListView;
    private editListViewDef: object;
    /** Löschmodus für Buchung: 1. Oben rechts Mülleimer-Icon, dann Lösch-Button im Footer anklicken */
    private pageDeleteMode: PageDeleteMode;
    private showNavigationToForecastButton: boolean = false;
    private footerTabBar: FooterTabBar;
    private previousEffortExpense: number = 0;
    private isBookingDeleted: boolean = false;
    private suppressDeletionOnReturn: boolean = false;
    private isBlockGuiWhileSaving: boolean = false;
    private blockGuiWhileSavingDuration: number = 0;

    constructor() {}

    public getDependencyNames(): string[] {
        return [
            "I18n",
            "TimeRecordingManager",
            MessagePool.BCS_COMPONENT_NAME,
            UserSession.BCS_COMPONENT_NAME,
            SyncStateManager.BCS_COMPONENT_NAME,
            ServerConfigProperties.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }) {
        this.i18n = <I18n>depencencyComponents["I18n"];
        this.timeRecordingManager = <TimeRecordingManager>(
            depencencyComponents["TimeRecordingManager"]
        );

        this.eventPool = new GUIEventPool();
        this.registerEvents();

        this.context = new GUIContext(this.i18n, this.eventPool);

        this.messagePool = <MessagePool>depencencyComponents[MessagePool.BCS_COMPONENT_NAME];

        this.userSession = <UserSession>depencencyComponents[UserSession.BCS_COMPONENT_NAME];

        this.syncStateManager = <SyncStateManager>(
            depencencyComponents[SyncStateManager.BCS_COMPONENT_NAME]
        );

        this.serverConfigProperties = <ServerConfigProperties>(
            depencencyComponents[ServerConfigProperties.BCS_COMPONENT_NAME]
        );
    }

    public registerEvents() {
        this.eventPool.registerEventListener(
            "Message",
            "remove",
            this.removeFromMessagePool.bind(this),
        );
        this.eventPool.registerEventListener(
            SubMenu.BCS_COMPONENT_NAME,
            "openSubmenu",
            this.openSubMenu.bind(this),
        );
    }

    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        this.navigator = navigator;
        this.animation = animation;
        const selectedDate = parameters["clickedDate"];

        this.page = new GUIPage(this.context);
        this.page.composeMessagesContainer();

        const isNewBooking = typeof parameters.new_timerecord !== "undefined";

        const isOidSelected = typeof parameters.selectionChangedAttribute !== "undefined";

        const isTemplateBooking = typeof parameters.template_timerecord !== "undefined";

        AppConsole.debug(
            "[TimeRecordingDetailController]",
            isNewBooking,
            isOidSelected,
            parameters,
        );

        /* Handel es sich um ein NEUES Object */
        const self: TimeRecordingDetailController = this;

        if (isNewBooking) {
            this.composeNewBooking(parameters, self, selectedDate);
        } else {
            //beim anpassen einer bestehenden Buchung sicherheitshalber auch nochmal das Löschen aushebeln
            this.suppressDeletionOnReturn = true;
            // Oder soll eine bereits bestehende Buchung angepasst werden:
            const timeRecortOid = parameters.oid;
            if (timeRecortOid.indexOf("_JTimeSpan") != -1) {
                this.composeUpdateTimeSpan(timeRecortOid, self, isTemplateBooking);
            } else {
                this.composeUpdateBooking(timeRecortOid, self, isOidSelected, parameters);
            }
        }

        if (parameters[ForecastController.PARAMETER_RETURN_MESSAGE]) {
            this.messagePool.addMessage(
                MessageEntity.createFromJSON(
                    parameters[ForecastController.PARAMETER_RETURN_MESSAGE],
                ),
            );
            this.composeMessages();
        }
    }

    public composeMessages() {
        const messages: MessageEntity[] = this.messagePool.getMessages();
        this.page.composeMessages(messages, this.context);
    }

    public toolLinkNavigateToPage(targetComponentName: string): void {
        // TODO Jens, überprüfen, bei welchen Seiten wir eigentlich den Stack leeren wollen, z.B die Übersicht.
        this.navigator.cleanPages();
        this.navigateToPage(null, { navigate: targetComponentName });
    }

    public toolLinkToSync(): void {
        AppConsole.debug("toolLink1Clicked");
    }

    public popState(): void {
        const lastPageData: { parameters: {}; page: string } = this.navigator.popPage();
        if (!this.isBookingDeleted) {
            if (
                !this.timeRecord.getValue(Booking.STARTED_BOOKING).getSimpleValue() &&
                !this.suppressDeletionOnReturn
            ) {
                this.timeRecordingManager.deleteTimeRecordFromDB(this.timeRecord).then(() => {
                    this.navigator.navigateTo(
                        lastPageData.page,
                        { warnings: "unEditedBookingNotSaved" },
                        Animation.SLIDE_RIGHT,
                    );
                });
            } else {
                this.navigator.navigateTo(
                    lastPageData.page,
                    lastPageData.parameters,
                    Animation.SLIDE_RIGHT,
                );
            }
        } else {
            this.navigator.navigateTo(
                lastPageData.page,
                lastPageData.parameters,
                Animation.SLIDE_RIGHT,
            );
        }
    }

    public destroy(): void {
        // TODO ...
    }

    private async composeNewBooking(
        parameters: { [key: string]: string },
        self: TimeRecordingDetailController,
        selectedDate: string,
    ) {
        if (ArrayUtil.contains(["attendance", "pause"], parameters.new_timerecord)) {
            AppConsole.log(
                "[TimeRecordingDetailController] new newTimerecordingOid: " +
                    parameters.new_timerecord,
            );
            this.timeRecordingManager.createTimeSpan(parameters.new_timerecord).then(function (
                timespan: TimeSpan,
            ) {
                self.timeRecord = timespan;
                self.timeRecord.setDate(BCSDate.getDateFromStamp(selectedDate));
                self.usedListViewDef = TimeRecordingGUIDefinitions.TIMESPAN_LIST_VIEW_DEF;
                self.usedListViewDefName = "timespan";

                self.composePage();
            });
        } else if (
            ArrayUtil.contains(
                ["task", "ticket", "appointment", "requirement", "workflow"],
                parameters.new_timerecord,
            )
        ) {
            if (parameters.new_timerecord === "appointment") {
                const appointmentOid: string = parameters["effortEventRefOid"];
                this.timeRecordingManager
                    .getTimesheetEvent(appointmentOid)
                    .then((appointment: Appointment) => {
                        this.timeRecordingManager
                            .createAppointmentBooking(
                                BCSDate.getDateFromStamp(selectedDate),
                                appointment,
                            )
                            .then(function (booking: Booking) {
                                booking.setDisplayPSPPath(appointment.getDisplayPSPPath());
                                booking.setBookableElement(appointment);
                                self.timeRecord = booking;
                                self.timeRecord.setBookingType(BookingType.Appointment);
                                self.usedListViewDef =
                                    TimeRecordingGUIDefinitions.APPOINTMENT_LIST_VIEW_DEF;
                                self.usedListViewDefName = "appointment";
                                // self.navigator.replaceCurrentParameter("oid", self.timeRecord.getId());
                                // self.navigator.removeCurrentParameter("new_timerecord");
                                delete parameters["new_timerecord"];
                                parameters["oid"] = self.timeRecord.getId();
                                parameters["selectionChangedAttribute"] = "effortEventRefOid";
                                parameters["selectionChangedOid"] = appointmentOid;
                                self.navigator.replaceCurrentParameters(parameters);
                                self.composePage().then(() => {
                                    self.updateBookableElementSelected(parameters, appointment);
                                });
                            });
                    });
            } else {
                this.timeRecordingManager
                    .createBooking(
                        BCSDate.getDateFromStamp(selectedDate),
                        parameters.new_timerecord,
                    )
                    .then(function (booking: Booking) {
                        self.timeRecord = booking;
                        if (parameters.new_timerecord === "workflow") {
                            self.timeRecord.setBookingType(BookingType.Workflow);
                            self.usedListViewDef =
                                TimeRecordingGUIDefinitions.WORKFLOW_LIST_VIEW_DEF;
                            self.usedListViewDefName = "workflow";
                        } else if (parameters.new_timerecord === "requirement") {
                            self.timeRecord.setBookingType(BookingType.Requirement);
                            self.usedListViewDef =
                                TimeRecordingGUIDefinitions.REQUIREMENT_LIST_VIEW_DEF;
                            self.usedListViewDefName = "requirement";
                        } else if (parameters.new_timerecord === "ticket") {
                            self.timeRecord.setBookingType(BookingType.Ticket);
                            self.usedListViewDef = TimeRecordingGUIDefinitions.TICKET_LIST_VIEW_DEF;
                            self.usedListViewDefName = "ticket";
                        } else {
                            self.timeRecord.setBookingType(BookingType.Task);
                            self.usedListViewDef =
                                TimeRecordingGUIDefinitions.BOOKING_LIST_VIEW_DEF;
                            self.usedListViewDefName = "booking";
                        }
                        self.composePage();
                    });
            }
        } else {
            Log.error("Unknown Type of new TimeRecord.", parameters);
        }
    }

    private composeUpdateTimeSpan(
        timeRecortOid: string,
        self: TimeRecordingDetailController,
        isTemplateBooking: boolean,
    ) {
        AppConsole.log("[TimeRecordingDetailController] Update TimeSpan: " + timeRecortOid);
        this.timeRecordingManager.getTimeSpan(timeRecortOid).then(function (timeSpan: TimeSpan) {
            self.timeRecord = timeSpan;
            if (self.timeRecord.isBooking()) {
                self.usedListViewDef = TimeRecordingGUIDefinitions.BOOKING_LIST_VIEW_DEF;
                self.usedListViewDefName = "booking";
            } else {
                self.usedListViewDef = TimeRecordingGUIDefinitions.TIMESPAN_LIST_VIEW_DEF;
                self.usedListViewDefName = "timespan";
            }
            self.composePage().then(() => {
                if (isTemplateBooking) {
                    self.formFieldChanged({
                        formName: "timeSpanDuration",
                        entityId: timeRecortOid,
                        fieldName: "timeSpanDuration",
                        value: timeSpan.getValue("timeSpanDuration"),
                        formValues: timeSpan,
                    });
                }
            });
        });
    }

    private composeUpdateBooking(
        timeRecordOid: string,
        self: TimeRecordingDetailController,
        isOidSelected: boolean,
        parameters: { [key: string]: string },
    ) {
        this.timeRecordingManager.getBooking(timeRecordOid).then(function (booking: Booking) {
            self.timeRecord = booking;
            if (self.timeRecord.isBooking()) {
                // Namen der ausgewählten Aufgabe am OidValue (effortTargetOid) setzen
                const effortTargetOid = self.timeRecord.getValue("effortTargetOid").getString();

                const bookingType: BookingType = self.timeRecord.getBookingType();

                switch (bookingType) {
                    case BookingType.Task: {
                        self.usedListViewDef = TimeRecordingGUIDefinitions.BOOKING_LIST_VIEW_DEF;
                        self.usedListViewDefName = "booking";
                        self.timeRecordingManager
                            .getTimesheetTask(effortTargetOid)
                            .then((task) => {
                                if (task) {
                                    self.setOriginalRemainigEffort(task.getRemainingEffort());
                                    self.setBookedDuration(task.getBookedDuration());
                                    self.timeRecord.setValue(
                                        "effortTargetOid",
                                        OidValue.fromOidAndName(effortTargetOid, task.getName()),
                                    );
                                    (<Booking>self.timeRecord).setDisplayPSPPath(
                                        task.getDisplayPSPPath(),
                                    );
                                    (<Booking>self.timeRecord).setBookableElement(task);

                                    // Die Aufgabe kann eine Standard-Vorbelegung für die Abrechenbarkeit haben
                                    // Die wird zwar in updateBookableElementSelected() korrekt gesetzt, aber das ist erst nach dem Rendern...
                                    self.timeRecord.setValue(
                                        "effortBillability",
                                        new StringValue(task.getBillability()),
                                    );

                                    // Wenn wir eine Aufgabe an der Buchung haben, dann zeigen wir den Button an der zur Restaufwandsschätzung führt. Vorausgesetzt, das Feature ist nicht deaktiviert und man kann an der Aufgabe einen Restaufwand erfassen (beim Subtyp "task" und wenn das Aufwandsplanungsmodell nicht "Über Tickets" ist.
                                    if (
                                        self.serverConfigProperties.forecastEstimationEnabled() &&
                                        self.timeRecordingManager.isForecastRecordingAllowed(task)
                                    ) {
                                        self.showNavigationToForecastButton = true;
                                    }

                                    const previousEffortExpense: number = <number>(
                                        self.timeRecord.getValue("effortExpense").getSimpleValue()
                                    );
                                    if (previousEffortExpense != 0) {
                                        self.previousEffortExpense = previousEffortExpense;
                                    }
                                }
                                self.composePage().then(() => {
                                    if (isOidSelected) {
                                        self.updateBookableElementSelected(parameters, task);
                                    }
                                });
                            })
                            .catch(() => {
                                // Falls keine entsprechende Aufgabe gefunden wurde:
                                self.composePage();
                            });
                        break;
                    }
                    case BookingType.Ticket: {
                        const ticketID = self.timeRecord
                            .getValue("effortAnnotationOid")
                            .getString();

                        self.usedListViewDef = TimeRecordingGUIDefinitions.TICKET_LIST_VIEW_DEF;
                        self.usedListViewDefName = "ticket";

                        self.timeRecordingManager
                            .getTimesheetTicket(ticketID)
                            .then((ticket: Ticket) => {
                                if (ticket) {
                                    const taskOid = ticket.getTask();
                                    (<Booking>self.timeRecord).setDisplayPSPPath(
                                        ticket.getDisplayPSPPath(),
                                    );
                                    (<Booking>self.timeRecord).setBookableElement(ticket);
                                    self.timeRecord.setValue(
                                        "effortTargetOid",
                                        OidValue.fromOidAndName(taskOid, ticket.getName()),
                                    );
                                    self.timeRecord.setValue(
                                        "effortAnnotationOid",
                                        OidValue.fromOidAndName(ticketID, ticket.getName()),
                                    );
                                }
                                self.composePage().then(() => {
                                    if (isOidSelected) {
                                        self.updateBookableElementSelected(parameters, ticket);
                                    }
                                });
                            })
                            .catch(() => {
                                // Falls keine entsprechende Aufgabe gefunden wurde:
                                self.composePage();
                            });
                        break;
                    }
                    case BookingType.Requirement: {
                        const requirementID = self.timeRecord
                            .getValue("effortRequirementOid")
                            .getString();

                        self.usedListViewDef =
                            TimeRecordingGUIDefinitions.REQUIREMENT_LIST_VIEW_DEF;
                        self.usedListViewDefName = "requirement";

                        self.timeRecordingManager
                            .getTimesheetRequirement(requirementID)
                            .then((requirement: Requirement) => {
                                if (requirement) {
                                    const taskOid = requirement.getTask();
                                    (<Booking>self.timeRecord).setDisplayPSPPath(
                                        requirement.getDisplayPSPPath(),
                                    );
                                    (<Booking>self.timeRecord).setBookableElement(requirement);
                                    self.timeRecord.setValue(
                                        "effortTargetOid",
                                        OidValue.fromOidAndName(taskOid, requirement.getName()),
                                    );
                                    self.timeRecord.setValue(
                                        "effortRequirementOid",
                                        OidValue.fromOidAndName(
                                            requirementID,
                                            requirement.getName(),
                                        ),
                                    );
                                }
                                self.composePage().then(() => {
                                    if (isOidSelected) {
                                        self.updateBookableElementSelected(parameters, requirement);
                                    }
                                });
                            })
                            .catch(() => {
                                // Falls keine entsprechende Aufgabe gefunden wurde:
                                self.composePage();
                            });
                        break;
                    }
                    case BookingType.Workflow: {
                        const workflowID = self.timeRecord
                            .getValue("effortWorkflowOid")
                            .getString();

                        self.usedListViewDef = TimeRecordingGUIDefinitions.WORKFLOW_LIST_VIEW_DEF;
                        self.usedListViewDefName = "workflow";

                        self.timeRecordingManager
                            .getTimesheetWorkflow(workflowID)
                            .then((workflow: Workflow) => {
                                if (workflow) {
                                    (<Booking>self.timeRecord).setDisplayPSPPath(
                                        workflow.getDisplayPSPPath(),
                                    );
                                    (<Booking>self.timeRecord).setBookableElement(workflow);
                                    const taskOid = workflow.getTask();
                                    self.timeRecord.setValue(
                                        "effortTargetOid",
                                        OidValue.fromOidAndName(taskOid, workflow.getName()),
                                    );
                                    self.timeRecord.setValue(
                                        "effortWorkflowOid",
                                        OidValue.fromOidAndName(workflowID, workflow.getName()),
                                    );
                                }
                                self.composePage().then(() => {
                                    if (isOidSelected) {
                                        self.updateBookableElementSelected(parameters, workflow);
                                    }
                                });
                            })
                            .catch(() => {
                                // Falls keine entsprechende Aufgabe gefunden wurde:
                                self.composePage();
                            });
                        break;
                    }
                    case BookingType.Appointment: {
                        const appointmentID = self.timeRecord
                            .getValue("effortEventRefOid")
                            .getString();

                        self.usedListViewDef =
                            TimeRecordingGUIDefinitions.APPOINTMENT_LIST_VIEW_DEF;
                        self.usedListViewDefName = "appointment";

                        self.timeRecordingManager
                            .getTimesheetEvent(appointmentID)
                            .then((appointment: Appointment) => {
                                if (appointment) {
                                    const taskOid = appointment.getTask();
                                    (<Booking>self.timeRecord).setDisplayPSPPath(
                                        appointment.getDisplayPSPPath(),
                                    );
                                    (<Booking>self.timeRecord).setBookableElement(appointment);
                                    self.timeRecord.setValue(
                                        "effortTargetOid",
                                        OidValue.fromOidAndName(taskOid, appointment.getTaskName()),
                                    );
                                    self.timeRecord.setValue(
                                        "effortEventRefOid",
                                        OidValue.fromOidAndName(
                                            appointmentID,
                                            appointment.getName(),
                                        ),
                                    );
                                }
                                self.composePage().then(() => {
                                    if (isOidSelected) {
                                        self.updateBookableElementSelected(parameters, appointment);
                                    }
                                });
                            })
                            .catch(() => {
                                // Falls keine entsprechende Aufgabe gefunden wurde:
                                self.composePage();
                            });
                        break;
                    }
                }
            } else {
                self.usedListViewDef = TimeRecordingGUIDefinitions.TIMESPAN_LIST_VIEW_DEF;
                self.usedListViewDefName = "timespan";
                self.composePage();
            }
        });
    }

    private updateBookableElementSelected(
        parameters: { [key: string]: string },
        task: TimesheetEntry,
    ) {
        const attributeName: string = parameters.selectionChangedAttribute;
        const selectedOid: string = parameters.selectionChangedOid;
        this.formFieldChanged(
            {
                formName: attributeName,
                entityId: selectedOid,
                fieldName: attributeName,
                value: OidValue.fromOidAndName(task.getId(), task.getName()),
                formValues: this.timeRecord,
            },
            false,
        );

        const billability: string = task.getBookableObjectValue("chargeability");
        const effortBillability: StringValue = new StringValue(billability);

        this.timeRecord.setValue("effortBillability", effortBillability);
        this.formFieldChanged({
            formName: "effortBillability",
            entityId: selectedOid,
            fieldName: "effortBillability",
            value: effortBillability,
            formValues: this.timeRecord,
        });
    }

    private composeHeaderToolBar(page: GUIPage): ToolBar {
        const headerToolBar = new ToolBar()
            .setId("header_toolbar")
            .setTitle(this.getTitle())
            .addStyleClass("headBar")
            .addToolLinkLeft(
                new ToolLink()
                    .setStyleClass("backToDayTimeOverview")
                    .setImageName("icon-chevron-left.svg")
                    .onClick(this.popState.bind(this)),
            );
        page.addHeaderElement(headerToolBar);
        return headerToolBar;
    }

    private composeDeleteLink(page: GUIPage, headerToolBar: ToolBar): void {
        let deleteLabel = null;
        if (this.timeRecord.isBooking()) {
            deleteLabel = "MobileApp.timeRecordingOverview.deleteBooking";
        } else if (this.timeRecord.isPause()) {
            deleteLabel = "MobileApp.timeRecordingOverview.deletePause";
        } else {
            deleteLabel = "MobileApp.timeRecordingOverview.deleteAttendance";
        }

        // 2-stufiges Löschen der Buchen
        this.pageDeleteMode = new PageDeleteMode()
            .setPage(page)
            .setHeaderToolBar(headerToolBar)
            .setDeleteButtonLabel(this.i18n.get(deleteLabel))
            .onDelete(() => {
                // TODO falls nicht synchronisiert, dann nur in der DB löschen.
                this.timeRecordingManager.deleteTimeRecord(this.timeRecord).then(() => {
                    this.isBookingDeleted = true;
                    this.popState();
                });
            });
    }

    private getTitle(): string {
        let type = this.i18n.get("MobileApp.Booking");
        if (this.timeRecord.getBookingType() === BookingType.TimeSpan) {
            if (this.timeRecord.isPause()) {
                type = this.i18n.get("MobileApp.pause");
            } else {
                type = this.i18n.get("MobileApp.attendance");
            }
        }
        return type + ", " + new BCSDate(this.timeRecord.getDate()).formatShortDate("de");
    }

    private composePage(): Promise<void> {
        this.page.addStyleClass(TimeRecordingDetailController.BCS_COMPONENT_NAME);

        const headerToolBar = this.composeHeaderToolBar(this.page);
        this.composeDeleteLink(this.page, headerToolBar);

        this.composeEditForm();

        return new Promise((resolve, reject) => {
            this.syncStateManager
                .countUnsyncedElements()
                .then((count: number) => {
                    this.composeFooterTabBar();
                    this.page
                        .setAnimation(this.animation, this.navigator.doShowAnimations())
                        .compose($("body"));

                    if (this.showNavigationToForecastButton) {
                        $(".hideNavigationToForecastButton").removeClass(
                            "hideNavigationToForecastButton",
                        );
                    }
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    private composeEditForm(): void {
        const listViewContext = new ListViewContext()
            .setI18n(this.i18n)
            .setModel(new TimeRecordListViewModel(this.timeRecord));

        // Konfigurationsanpassungen: Attrtibute einblenden/ausblenden anwenden
        this.editListViewDef = this.serverConfigProperties.customizeGUIDefinitions(
            this.usedListViewDef,
            TimeRecordingDetailController.BCS_COMPONENT_NAME,
            this.usedListViewDefName,
        );
        this.timeRecordListView = new ListView(listViewContext, this.editListViewDef)
            .addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW)
            .onFormFieldChange(this.formFieldChanged.bind(this))
            .onRowClicked(this.listRowClicked.bind(this));

        this.page.addPageElement(this.timeRecordListView);
    }

    /**
     *
     * @param changeEvent
     * @param saveChange Wenn wir zum Beispiel eine Aufgabe setzen,
     *                  wollen wir dannach noch die Abrechenbarkeit setzen und nicht zwei mal speichern,
     *                  da dies zu "inzwischen geänderten Objekten" führen würde.
     */
    private formFieldChanged(changeEvent: formChangeEventType, saveChange: boolean = true): void {
        const isValid = this.maintainTimeConstraints(changeEvent);
        this.messagePool.cleanAffirmationMessages();
        const self = this;

        let canBeStoredInBCS = isValid;
        let isOnline = this.userSession.isOnline();

        const touchedFields: string[] = this.timeRecordingManager.getMinimalTouchedBookingFields();
        if (touchedFields.indexOf(changeEvent.fieldName) == -1) {
            touchedFields.push(changeEvent.fieldName);
        }

        // fügen evtl. Custom Attribute hinzu
        const names = ServerConfigProperties.getAllFieldNames(this.editListViewDef);
        for (const name of names) {
            if (touchedFields.indexOf(name) == -1) {
                touchedFields.push(name);
            }
        }

        const allRequiredAttributes = this.timeRecord.getRequiredAttributes();
        const fieldSetDefinitions = <object[]>this.usedListViewDef["fieldsets"];
        fieldSetDefinitions.forEach((fieldSetDefinition) => {
            const fields = fieldSetDefinition["fields"];
            for (const fieldNumber in fields) {
                const fieldName = fields[fieldNumber].field.name;

                touchedFields.push(fieldName);
                const itContainsRequired: boolean = allRequiredAttributes.indexOf(fieldName) !== -1;
                if (itContainsRequired) {
                    const value = this.timeRecord.getValue(fieldName);
                    if (value == null) {
                        canBeStoredInBCS = false;
                    } else {
                        if (!value.isDefined()) {
                            canBeStoredInBCS = false;
                        }
                    }
                }
            }
        });

        // Auto-Speichern der Buchung
        const dontSaveBookingOnForecastOnlyChange: boolean =
            this.foreCastFieldsIgnoredForBooking.indexOf(changeEvent.fieldName) > -1;
        if (
            typeof saveChange !== "undefined" &&
            saveChange &&
            !dontSaveBookingOnForecastOnlyChange
        ) {
            // Sofern der Benutzer zu schnell nacheinander in der App-Zeiterfassung mehrere Ziffern in das Feld "Dauer" eintippt,
            // darf das sofortige Speichern der folgenden Eingabe erst erfolgen, wenn der vorherige Speicher-Request fertig ist (#192139).
            // Bei neuen Buchungen könnten dabei 2 Buchungen enstehen.
            if (this.isBlockGuiWhileSaving && this.blockGuiWhileSavingDuration <= 60 * 1000) {
                this.blockGuiWhileSavingDuration += 100;
                window.setTimeout(() => self.formFieldChanged(changeEvent, saveChange), 100);
                return;
            }

            // Sofortiges Speichern auf BCS-Server kann per Konfiguration ausgeschaltet werden,
            // dann werden Änderungen an Buchungen nur beim Synchronisieren übertragen.
            if (
                this.serverConfigProperties.getProperty(
                    "MobileApp.TimeRecording.InstantSaveBooking.Enabled",
                ) === "false"
            ) {
                isOnline = false;
            }

            const saveInformations = new SaveEntryInformations(isOnline, canBeStoredInBCS);
            this.blockGuiWhileSaving();
            //console.time("blockGuiWhileSaving");

            if (saveInformations.couldSaveOnServer()) {
                this.storeTimeRecordOnServer(touchedFields)
                    .then((newTimeRecord) => {
                        // Setzen die bisher provisorische Id (Oid) auf die vom Server beim Speichern vergebene Oid
                        const id: string = newTimeRecord.getId();
                        self.timeRecord.setId(id);
                        AppConsole.log("[TimeRecordingDetailController] new oid: " + id);
                        saveInformations.setSavedSuccessfullyOnServer();
                        self.saveBookingLocal(saveInformations);
                        this.releaseBlockGuiWhileSaving();

                        // schreiben die OID in der URL um.
                        self.navigator.replaceCurrentParameter("oid", id);
                    })
                    .catch((errorInformations) => {
                        this.messagePool.addMessage(
                            MessageEntity.createServerErrorMessage(errorInformations, this.i18n),
                        );
                        self.composeMessages();
                        this.releaseBlockGuiWhileSaving();
                    });
            } else {
                // speichern/aktualisieren die Buchung in der Datenbank, falls die App geschlossen wird, oder in den Offline Modus gewechselt wird.
                self.saveBookingLocal(saveInformations);
                this.releaseBlockGuiWhileSaving();
            }
        }

        if (changeEvent.fieldName === "effortExpense") {
            this.timeRecordingManager
                .getBooking(this.timeRecord.getId(), false)
                .then((originalBookingBeforeChange) => {
                    const oldEffort = originalBookingBeforeChange.getEffortExpense();
                    const newEffort = (changeEvent.value as DurationValue).getDurationInMinutes();
                    if (newEffort === 0) {
                        this.timeRecordingManager
                            .updateRemainingEffortOnTimesheetTask(
                                originalBookingBeforeChange.getEffortTargetId(),
                                this.originalRemainigEffort,
                            )
                            .then((changedTask) => {
                                this.timeRecordingManager
                                    .updateBookedDurationOnTimesheetTask(
                                        changedTask.getId(),
                                        this.bookedDuration,
                                    )
                                    .then((changedTask) => {
                                        (this.timeRecord as Booking).setBookableElement(
                                            changedTask,
                                        );
                                        this.updateFormFields([changeEvent]);
                                    });
                            });
                    }
                    if (oldEffort !== newEffort) {
                        const diffinMin = newEffort - oldEffort;
                        const diffBookedDuration = oldEffort - newEffort;
                        this.forecastRecord.estimation = diffinMin;
                        this.timeRecordingManager
                            .updateRemainingEffortWithDiff(
                                originalBookingBeforeChange.getEffortTargetId(),
                                diffinMin,
                            )
                            .then((changedTask) => {
                                this.timeRecordingManager
                                    .updateBookedDurationWithDiff(
                                        changedTask.getId(),
                                        diffBookedDuration,
                                    )
                                    .then((changedTask) => {
                                        (this.timeRecord as Booking).setBookableElement(
                                            changedTask,
                                        );
                                        this.updateFormFields([changeEvent]);
                                    });
                            });
                    } else {
                        this.updateFormFields([changeEvent]);
                    }
                    AppConsole.log(
                        "Original Booked Duration " +
                            this.bookedDuration +
                            "Original Remaining Effort: " +
                            this.originalRemainigEffort +
                            " OldEffort: " +
                            oldEffort +
                            "New Effort " +
                            newEffort,
                    );
                });
        } else {
            this.updateFormFields([changeEvent]);
        }

        if (changeEvent.fieldName === "task__remainingDuration") {
            const remainingValue = (changeEvent.value as DurationValue).getDurationInMinutes();
            this.forecastRecord.time = remainingValue;
        }

        if (changeEvent.fieldName === "remainingDescription") {
            const remainingDescription = (changeEvent.value as StringValue).getString();
            this.forecastRecord.description = remainingDescription;
        }

        /**
         * Wenn sich die Aufgabe ändert, aber schon eine Dauer != 0 eingetragen wurde, dann soll die alte Dauer vom Rest t der neuen Aufgabe abgezogen bzw. auf das Ist t aufgeschlagen werden.
         */
        if (changeEvent.fieldName === "effortTargetOid") {
            this.timeRecordingManager
                .getBooking(this.timeRecord.getId(), false)
                .then((originalBookingBeforeChange) => {
                    const oldEffort = originalBookingBeforeChange.getEffortExpense();
                    if (oldEffort != 0) {
                        this.forecastRecord.estimation = oldEffort;
                        this.timeRecordingManager
                            .updateRemainingEffortWithDiff(
                                originalBookingBeforeChange.getEffortTargetId(),
                                oldEffort,
                            )
                            .then((changedTask) => {
                                this.timeRecordingManager
                                    .updateBookedDurationWithDiff(changedTask.getId(), oldEffort)
                                    .then((changedTask) => {
                                        (this.timeRecord as Booking).setBookableElement(
                                            changedTask,
                                        );
                                        this.updateFormFields([changeEvent]);
                                    });
                            });
                    }
                });
        }
    }

    /**
     * Setzt den ursprünglichen Restaufwand, sodass dieser anschließend wiederhergestellt werden kann.
     * @private
     */
    private setOriginalRemainigEffort(remainingEffort: number) {
        this.originalRemainigEffort = remainingEffort;
    }

    private blockGuiWhileSaving() {
        this.isBlockGuiWhileSaving = true;
        this.blockGuiWhileSavingDuration = 0;

        AppConsole.log("blockGuiWhileSaving");
        const pleaseWaitText = this.i18n.get("MobileApp.blockGuiWhileSavingInfoText");
        const layer = $("<div class='blockGuiLayer blockGuiHidden'>");
        const text = $(
            "</div><div class='blockGuiWhileSaving blockGuiHidden' ><div class='blockGuiText'>" +
                pleaseWaitText +
                "</div></div>",
        );

        const clickRemoveHideCallback = () => {
            $(".blockGuiHidden").removeClass("blockGuiHidden");
        };

        layer.click(clickRemoveHideCallback);
        text.click(clickRemoveHideCallback);

        this.page.getPageDOMElement().append(text);
        this.page.getPageDOMElement().append(layer);
    }

    private releaseBlockGuiWhileSaving() {
        this.isBlockGuiWhileSaving = false;
        this.blockGuiWhileSavingDuration = 0;

        // AppConsole.log("releaseBlockGuiWhileSaving");
        // console.timeEnd("blockGuiWhileSaving");
        $(".blockGuiWhileSaving").remove();
        $(".blockGuiLayer").remove();
    }

    /**
     *Prüft, ob Start, Ende und Dauer im richtigen Verhältnis stehen. Leitet die Werte dazu an den
     * @link(TimeRecordingManager.ts) weiter
     *
     * @param changeEvent , nur falls angegeben werden die Zeiten angepasst, sonst wird nur geprüft ob die Zeiten Valide sind.
     *
     */
    private maintainTimeConstraints(changeEvent?: formChangeEventType): boolean {
        let changedAttribute = null;

        const timeAttibutesDefinitions: TimeAttibutesDefinitions =
            this.timeRecord.getTimeAttibutesDefinitions();
        if (typeof changeEvent !== "undefined" && changeEvent !== null) {
            if (changeEvent.fieldName === timeAttibutesDefinitions.getStart()) {
                changedAttribute = TimeChanged.START;
            } else if (changeEvent.fieldName === timeAttibutesDefinitions.getEnd()) {
                changedAttribute = TimeChanged.END;
            } else if (changeEvent.fieldName === timeAttibutesDefinitions.getDuration()) {
                changedAttribute = TimeChanged.DURATION;
            }
        }
        const isValid = this.timeRecordingManager.maintainTimeConstraints(
            this.timeRecord,
            changedAttribute,
        );
        return isValid;
    }

    private async saveBookingLocal(saveEntryInfo: SaveEntryInformations) {
        let messageToUser = "MobileApp.TimeRecording.Saved.local";
        if (saveEntryInfo.isSavedSuccessfullyOnServer()) {
            messageToUser = "MobileApp.TimeRecording.Saved.server";
        }

        this.storeTimeRecordLocal(saveEntryInfo).then(() => {
            this.messagePool.addMessage(
                new MessageEntity(this.i18n.get(messageToUser), MessageType.AFFIRMATION),
            );
            this.composeMessages();
        });
    }

    private updateFormFields(changeEvents: formChangeEventType[]): void {
        // Alle Formularfelder aktualisieren
        const listFieldFeedback = new ListFieldFeedback();
        for (const changeEvent of changeEvents) {
            listFieldFeedback.addFeedback(
                ListFieldFeedbackType.SUCCESS,
                changeEvent.entityId,
                changeEvent.fieldName,
            );
        }
        this.timeRecordListView.updateFields(listFieldFeedback);
    }

    private listRowClicked(path: string, parameters: { [key: string]: string }): void {
        const currentID: string = this.timeRecord.getId();
        // Die aktuelle Oid kann sich mittlerweile geändert haben,
        // wenn die Buchung auf dem Server gespeichert wurde und die provisorische ID ersetzt wurde.
        if (currentID != parameters["return-parameter-oid"]) {
            parameters["return-parameter-oid"] = currentID;
            parameters["taskSelectBookingOid"] = currentID;
        }
        const taskOid = this.timeRecord.getValue("effortTargetOid");
        if (taskOid) {
            parameters["taskoid"] = (taskOid as OidValue).getOid();
        }

        this.navigator.navigateTo(path, parameters, Animation.SLIDE_LEFT);
    }

    private storeTimeRecordLocal(saveEntryInfo: SaveEntryInformations): Promise<TimeRecord> {
        this.timeRecord.setValue(Booking.STARTED_BOOKING, new BooleanValue(true));
        if (saveEntryInfo.isSavedSuccessfullyOnServer()) {
            // Übertragen die Änderungen nach erfolgreichem Speichern in die loklae DB
            return this.timeRecordingManager.storeTimeRecordLocal(
                this.timeRecord,
                SyncState.fromTimeRecord(
                    this.userSession.getCurrentUserOid(),
                    this.timeRecord,
                    SyncStateType.NoChangesInApp,
                ),
            );
        } else {
            if (!saveEntryInfo.isOnline() && saveEntryInfo.isMobilePreConditionsGood()) {
                // Fall: sind Offline, haben jedoch alles ausgefüllt.
                const syncState: SyncState = SyncState.fromTimeRecord(
                    this.userSession.getCurrentUserOid(),
                    this.timeRecord,
                    SyncStateType.ChangesInApp,
                );
                syncState.markChanged(false);
                return this.timeRecordingManager.storeTimeRecordLocal(this.timeRecord, syncState);
            } else {
                const syncState: SyncState = SyncState.fromTimeRecord(
                    this.userSession.getCurrentUserOid(),
                    this.timeRecord,
                    SyncStateType.SynchronisationIssue,
                );
                syncState.markSyncError(
                    "notAllRequiredAttributesFilledOut",
                    this.i18n.get("MobileApp.txtError.notAllRequiredAttributesFilledOut"),
                );
                return this.timeRecordingManager.storeTimeRecordLocal(this.timeRecord, syncState);
            }
        }
    }

    private storeTimeRecordOnServer(touchedFields: string[]): Promise<TimeRecord> {
        this.timeRecord.setValue(Booking.STARTED_BOOKING, new BooleanValue(true));
        AppConsole.log("[storeTimeRecordOnServer]: " + this.timeRecord.getId());
        return this.timeRecordingManager.storeTimeRecordOnServer(this.timeRecord, touchedFields);
    }

    private navigateToPage(clickEvent: Event, clickContext: any): void {
        if (
            !this.timeRecord.getValue(Booking.STARTED_BOOKING).getSimpleValue() &&
            !this.suppressDeletionOnReturn
        ) {
            this.timeRecordingManager.deleteTimeRecord(this.timeRecord).then(() => {
                this.navigator.navigateTo(
                    clickContext.navigate,
                    { warnings: "unEditedBookingNotSaved" },
                    Animation.SLIDE_LEFT,
                );
            });
        } else {
            this.navigator.navigateTo(clickContext.navigate, {}, Animation.SLIDE_LEFT);
        }
    }

    private navigateToPageByBar(clickEvent: Event, clickContext: FooterTabIcon): void {
        this.navigateToPage(clickEvent, { navigate: clickContext.iconName });
    }

    private removeFromMessagePool(event, transferObject: object) {
        if (transferObject.hasOwnProperty("messageEntity")) {
            this.messagePool.removeMessage(transferObject["messageEntity"]);
        } else {
            Log.debug(
                "[TimeRecordingController] Error while removing message in GUI.:" +
                    JSON.stringify(transferObject) +
                    " event: " +
                    JSON.stringify(event),
            );
        }
    }

    private openSubMenu(event, transferObject: object) {
        const callback = (action: string, navigationContext?: {}) => {
            if (action === "logout") {
                this.userSession.logout();
                this.navigateToPage(null, navigationContext);
            } else if (action === "navigate") {
                this.navigateToPage(null, navigationContext);
            }
        };

        this.page.openSubMenu(event, transferObject, this.i18n, callback);
    }

    private async composeFooterTabBar(): Promise<void> {
        const self = this;
        this.footerTabBar = new FooterTabBar(new GUIContext(this.i18n));
        await this.footerTabBar.composeDefaultFooter(
            TimeRecordingDetailController.BCS_COMPONENT_NAME,
            this.navigator,
            this.getNavigateBackParameters(),
            () => self.syncStateManager.countUnsyncedElements(),
        );
        this.page.addFooterElement(this.footerTabBar);
    }

    private getNavigateBackParameters(): { [key: string]: string } {
        return {
            navigateBackPath: TimeRecordingDetailController.BCS_COMPONENT_NAME,
            navigateBackParameters: JSON.stringify({
                oid: this.timeRecord.getId(),
            }),
        };
    }

    private setBookedDuration(bookedDuration: number) {
        this.bookedDuration = bookedDuration;
    }
}

class SaveEntryInformations {
    private readonly online: boolean;
    private readonly mobilePreConditionsGood: boolean;

    private savedSuccessfullyOnServer: boolean = false;

    constructor(isOnline, mobilePreConditionsGood) {
        this.online = isOnline;
        this.mobilePreConditionsGood = mobilePreConditionsGood;
    }

    public isOnline(): boolean {
        return this.online;
    }

    public isMobilePreConditionsGood(): boolean {
        return this.mobilePreConditionsGood;
    }

    public couldSaveOnServer(): boolean {
        return this.isOnline() && this.isMobilePreConditionsGood();
    }

    public setSavedSuccessfullyOnServer() {
        this.savedSuccessfullyOnServer = true;
    }

    public isSavedSuccessfullyOnServer() {
        return this.savedSuccessfullyOnServer;
    }
}

Registry.registerComponent(
    TimeRecordingDetailController.BCS_COMPONENT_NAME,
    TimeRecordingDetailController,
);
