import { Animation } from "./../core/Router";
import { ClickOutSidePool } from "./event/ClickOutSidePool";
import { FixedGUIElement } from "./FixedGUIElement";
import { GUIContext } from "./GUIContext";
import { GUIElement } from "./GUIElement";
import { I18n } from "../common/i18n/I18n";
import { Message } from "./messages/Message";
import { MessageContainer } from "./messages/MessageContainer";
import { MessageEntity } from "../core/Message/MessageEntity";
import { OnRenderBehavior } from "./OnRenderBehavior";
import { ProcessLayer } from "./loadingProcess/ProcessLayer";
import { SubMenu } from "./content/SubMenu";

export class GUIPage implements GUIElement {
    private pagePath: string;

    private pageEntityIds: string;

    private fixedHeaderElements: FixedGUIElement[] = [];

    private pageElements: GUIElement[] = [];

    private fixedFooterElements: FixedGUIElement[] = [];

    private fixedElements: FixedGUIElement[] = [];

    private $pageDomElement: JQuery;

    private styleClasses: string[] = [];

    /** Gesamthöhe aller Kopfzeilen (berechnet nach compose) */
    private headerFixBarsSumHeight = 0;

    /** Gesamthöhe aller Fusszeilen (berechnet nach compose) */
    private footerFixBarsSumHeight = 0;

    private containerMessage: MessageContainer;

    /** Animation des Seitenübnergangs nach rendern der Seite (keine, Slide-Links, Slide-Rechts ) */
    private animation: Animation;

    /** Flag, ob Animation per Konfiguration aktiv/deaktiviert */
    private doShowAnimations: boolean;

    /** Vertikale Scrollposition in px nach Rendern der Seite */
    private scrollToY = 0;

    /** Element-Id zu dem gescrollt wird nach Rendern der Seite */
    private scrollToElementDOMId: string;

    private guiContext: GUIContext;

    constructor(context: GUIContext, pagePath?: string, pageEntityIds?: string) {
        this.guiContext = context;
        this.pagePath = pagePath;
        this.pageEntityIds = pageEntityIds;

        ClickOutSidePool.init();
        // this.clickOutSidePool = new ClickOutSidePool();
    }

    public addStyleClass(styleClass: string): GUIPage {
        this.styleClasses.push(styleClass);
        return this;
    }

    public addHeaderElement(headerElement: FixedGUIElement): GUIPage {
        this.fixedHeaderElements.push(headerElement);

        if (this.isComposed()) {
            headerElement.compose(this.$pageDomElement);
            this.positionHeaderFixedBars();
        }

        return this;
    }

    public addPageElement(pageElement: GUIElement): GUIPage {
        this.pageElements.push(pageElement);

        if (this.isComposed()) {
            pageElement.compose(this.$pageDomElement);
        }

        return this;
    }

    public addFooterElement(footerElement: FixedGUIElement): GUIPage {
        if (!this.isComposed()) {
            this.fixedFooterElements.push(footerElement);
        } else {
            // Sofern die Seite schon gerendert wurde, neues Footer-Elemente als oberstes anzeigen.
            // (Footer-Elemente werden in umgekehrter Reihenfolge angezeigt.)
            this.fixedFooterElements.unshift(footerElement);

            footerElement.compose(this.$pageDomElement);
            this.positionFooterFixedBars();
        }

        return this;
    }

    public removeFooterElement(footerElement: FixedGUIElement): GUIPage {
        const idx = this.fixedFooterElements.indexOf(footerElement);
        if (idx >= 0) {
            this.fixedFooterElements.splice(idx, 1);
            this.positionFooterFixedBars();
        }
        return this;
    }

    public getFooterBarsSumHeight(): number {
        return this.footerFixBarsSumHeight;
    }

    public addFixedElement(fixedElement: FixedGUIElement): GUIPage {
        this.fixedElements.push(fixedElement);
        if (this.isComposed()) {
            fixedElement.compose(this.$pageDomElement);
        }

        return this;
    }

    public removeFixedElement(fixedElement: FixedGUIElement): GUIPage {
        const idx = this.fixedElements.indexOf(fixedElement);
        if (idx >= 0) {
            this.fixedElements.splice(idx, 1);
            this.positionFixedElements();
        }
        return this;
    }

    // public getClickOutSidePool(): ClickOutSidePool {
    //     return this.clickOutSidePool;
    // }

    /**
     * Setzt Seitenübergangsanimation, die nach dem Rendern der Seite gezeigt wird.
     * Gesetzt vom jeweilgen Seiten-Controller je nach Navigationsrichtung (links/rechts).
     *
     * @param animation Animation: Keine, Slide nach links, Slide nach rechts
     * @param doShowAnimations Gibt an, ob Animationen aktiv sind (Konfiguration)
     */
    public setAnimation(animation: Animation, doShowAnimations: boolean): GUIPage {
        this.animation = animation;
        this.doShowAnimations = doShowAnimations;
        return this;
    }

    /**
     * Setzt eine vertikale Scrollposition, zu der nach dem Rendern der Seite gescrollt wird.
     * Gesetzt vom jeweilgen Seiten-Controller, um z.B. nach Zurück-Navigation
     * zu der Listen-Zeilen runterzuscrollen, von der eine Unterseite geöffnet wurde.
     *
     * @param scrollToY Vertikale Scrollposition in px
     */
    public setScrollTop(scrollToY: number): GUIPage {
        this.scrollToY = scrollToY;
        return this;
    }

    /**
     * Setzt eine Element-Id, zu der nach dem Rendern der Seite gescrollt wird.
     * Gesetzt vom jeweilgen Seiten-Controller, um z.B. nach Hinzufügen einer neuen
     * Listen-Zeilen zu dieser runterzuscrollen.
     *
     * @param elementDOMId Element-Id
     */
    public setScrollToElement(scrollToElementDOMId: string): GUIPage {
        this.scrollToElementDOMId = scrollToElementDOMId;
        return this;
    }

    public compose($body: JQuery): void {
        // Sprache am html root Knoten setzen
        $("html").attr("lang", this.guiContext.getI18n().getLocale("-"));

        // Wurzel-DOM-Element der bisherigen Seite suchen (bevor Wurzel-DOM-Element der neuen Seite erstellt)
        const $currentPageRootTag = $("div.approot");
        $currentPageRootTag.attr("id", "prevapproot").css({
            opacity: 0,
            transition: "all 0.2s ease-out",
        });

        // Neue Seite initial nach oben scrollen, da vorherige Seite weiter nach unten gescrollt sein könnte.
        this.scrollTop(0);

        // Alle CSS-Klassen vom Body entfernen, ggf. neue setzen
        $("body").removeClass().addClass(this.styleClasses.join(" ")).css("height", "");

        // Wurzel-DOM-Element der neuen Seite erstellen (vorerst unsichtbar)
        const $pageRootTag = $("<div>")
            .appendTo($body)
            .attr("id", "approot")
            .addClass("approot")
            .css({
                display: "none",
            });

        this.$pageDomElement = $pageRootTag;

        this.composeGUIElements($pageRootTag, this.fixedHeaderElements);
        this.composeGUIElements($pageRootTag, this.pageElements);

        // Wurzel-DOM-Element der bisherigen Seite entfernen
        $currentPageRootTag.remove();

        new ProcessLayer().hide();

        $pageRootTag.css("display", "block");

        // Header-Elemente anzeigen
        this.positionHeaderFixedBars();

        // Footer-Elemente rendern und in umgekehrter Reihenfolge anzeigen
        // Nach Animation des Seitenübergangs, da fixierte Footerzeile bei Animation falsch positioniert würde.
        this.composeGUIElements($pageRootTag, this.fixedFooterElements);
        this.positionFooterFixedBars();
        this.composeGUIElements($pageRootTag, this.fixedElements);
        this.positionFixedElements();
        this.executeGUIElementsOnRender($body, this.pageElements);
        this.calculatePageHeight();
        // Animation des Seitenübergangs (Slide left/right je nach Navigationsrichtung und ob aktiv)
        this.showNewPageContent($pageRootTag);

        // Für WebTests
        window.setTimeout(() => this.tagPagePathAndPageEntity(), 10);
    }

    private minHeightFunction: () => number = () => -1;

    public setMinHeightFunction(minHeightFunction: () => number) {
        this.minHeightFunction = minHeightFunction;
    }

    private calculatePageHeight() {
        if (this.minHeightFunction() !== -1) {
            this.setHeight(this.minHeightFunction());
        }
    }

    /**
     * Für WebTests: PagePath und PageEntity als HTMLTag-Attribute hinterlegen.
     */
    private tagPagePathAndPageEntity(): void {
        this.$pageDomElement.attr("data-pagepath", this.pagePath ? this.pagePath : null);
        this.$pageDomElement.attr(
            "data-pageentityids",
            this.pageEntityIds ? this.pageEntityIds : null,
        );
    }

    private isComposed(): boolean {
        return !!this.$pageDomElement;
    }

    public getComponentChildren(): GUIElement[] {
        return this.pageElements;
    }

    public getPageDOMElement(): JQuery {
        return this.$pageDomElement;
    }

    private composeGUIElements($pageRootTag: JQuery, guiElements: GUIElement[]): void {
        for (let i = 0; i < guiElements.length; i++) {
            guiElements[i].compose($pageRootTag);
        }
    }

    private positionHeaderFixedBars(): void {
        this.headerFixBarsSumHeight = this.positionFixedBars(
            $("body"),
            this.fixedHeaderElements,
            "top",
        );
    }

    private positionFooterFixedBars(): void {
        this.footerFixBarsSumHeight = this.positionFixedBars(
            $("body"),
            this.fixedFooterElements.slice(0).reverse(),
            "bottom",
        );
    }

    /*
     * Erstmal nur für den "Plus"-Button, der bzgl des Abstandes zum bottom nicht wie eine Leiste berücksichtig werden soll,
     * damit man an der Page die Höhe der Footerbars sinnvoll abfragen kann.
     * Er soll aber trotzdem relativ zu den unteren Leisten positioniert werden.
     */
    private positionFixedElements(): void {
        let sumFixedHeight = 0;

        for (let i = 0; i < this.fixedElements.length; i++) {
            const fixedGUIElement = this.fixedElements[i];
            if (fixedGUIElement.isFixed()) {
                fixedGUIElement.setVerticalPosition(
                    "bottom",
                    this.footerFixBarsSumHeight + sumFixedHeight,
                );
                sumFixedHeight += fixedGUIElement.getFixedHeight();
            }
        }
    }

    /*
     * Alle FixedGUIElemente werden nach dem Rendern einer Seite von der Page nach ihrer Höhe befragt,
     * danach unter einander posititiert (z.B. erst die Haupt-Header-Toolbar, darunter eine TabbedViewBar) und
     * abschließend wird die Gesamthöhe als margin (top bzw. bottom) am, body gesetzt.
     *
     * @param $body body
     * @param fixedFooterElements Header bzw. Footer GUIElemente
     * @param verticalPositionKey "top" oder "bottom" für Header bzw. Footer
     * @returns Summe Höhe der Kopf- bzw. Fusszeilen
     */
    private positionFixedBars(
        $body: JQuery,
        fixedGUIElements: FixedGUIElement[],
        verticalPositionKey: string,
    ): number {
        let sumFixedHeight = 0;

        for (let i = 0; i < fixedGUIElements.length; i++) {
            const fixedGUIElement = fixedGUIElements[i];
            if (fixedGUIElement.isFixed()) {
                fixedGUIElement.setVerticalPosition(verticalPositionKey, sumFixedHeight);
                sumFixedHeight += fixedGUIElement.getFixedHeight();
            }
        }

        if (sumFixedHeight > 0) {
            $body.css("padding-" + verticalPositionKey, sumFixedHeight + "px");
        } else {
            $body.css("padding-" + verticalPositionKey, "0px");
        }

        return sumFixedHeight;
    }

    /**
     * Ausführen von optionalem Verhalten nach dem Rendern der Seite
     * @param guiElements Alle GUIElemente (Header, Seiteninhalte, Footer)
     */
    private executeGUIElementsOnRender($body: JQuery, guiElements: GUIElement[]): void {
        const stack = guiElements.slice(0);

        const stackAfter = guiElements.slice(0);

        while (stack.length > 0) {
            const currentChild = stack.pop();
            if ("executeOnRender" in currentChild) {
                (<OnRenderBehavior>currentChild).executeOnRender($body);
            }
            const chilrenOfChild = currentChild.getComponentChildren();
            if (chilrenOfChild.length > 0) {
                for (let i: number = 0; i < chilrenOfChild.length; i++) {
                    stack.push(chilrenOfChild[i]);
                }
            }
            if ("executeAfterChildrenRender" in currentChild) {
                stackAfter.push(currentChild);
            }
        }

        while (stackAfter.length > 0) {
            const currentChild = stackAfter.pop();
            if ("executeAfterChildrenRender" in currentChild) {
                (<any>currentChild).executeAfterChildrenRender($body);
            }
        }
    }

    private showNewPageContent($pageRootTag: JQuery): void {
        if (!this.doShowAnimations || !this.animation || this.animation == Animation.NONE) {
            this.scrollToYAfterCompose();
            this.finishCompose();
            return;
        }

        const sign = this.animation == Animation.SLIDE_LEFT ? "" : "-";
        $pageRootTag.css({
            "transform-origin": "0px 0px",
            transform: "translate(" + sign + "100%, -" + this.headerFixBarsSumHeight + "px)",
            "padding-top": this.headerFixBarsSumHeight + "px",
            height: $(window).innerHeight() - this.headerFixBarsSumHeight + "px",
            transition: "none",
        });

        // CSS-Änderungen jetzt rendern!
        $pageRootTag.innerWidth();

        // Wurzel-DOM-Element der neuen Seite sichtbar machen
        $pageRootTag
            .css({
                transition: "all 0.25s ease-in", // 0.25s
                transform: "translate(0px, -" + this.headerFixBarsSumHeight + "px)",
            })
            .on("transitionend", (event) => {
                $pageRootTag.css({
                    "padding-top": 0,
                    height: "auto",
                    transform: "none",
                    transition: "none",
                });

                this.scrollToYAfterCompose();
                this.finishCompose();
            });
    }

    private scrollToYAfterCompose(): void {
        if (this.animation == Animation.SLIDE_RIGHT && this.scrollToY > 0) {
            this.scrollTop(this.scrollToY);
            this.scrollToY = 0;
        }
        if (this.scrollToElementDOMId) {
            this.scrollToElement(this.scrollToElementDOMId);
            this.scrollToElementDOMId = null;
        }
    }

    private finishCompose(): void {
        // Erneutes Rendern der Seite (z.B. nach Drehen oder nach Hinzufügen von Elementen)
        // soll keine Slide-Animation zeigen.
        this.animation = Animation.NONE;
    }

    public composeMessagesContainer() {
        this.containerMessage = new MessageContainer();
        this.addPageElement(this.containerMessage);
    }

    public composeMessages(messages: MessageEntity[], context: GUIContext) {
        if (!this.containerMessage) {
            this.composeMessagesContainer();
        } else {
            this.containerMessage.cleanMessage();
        }
        messages.forEach((message) =>
            this.containerMessage.addElement(new Message(message, context)),
        );
    }

    public scrollTop(scrollTopY: number) {
        $(window).scrollTop(scrollTopY);
    }

    /**
     * Scrollt langsam zur Y-Position des per Id gegebenen Elements.
     * Die Höhe der fixierten Kopfzeilen wird dabei berücksichtigt.
     *
     * @param elementId Id des Zielelemments
     * @param time Dauer des Scrollen in ms [optional]
     */
    public scrollToElement(elementId: string, time?: number) {
        // $("body").scrollTop($("#" + elementId).offset().top - this.headerFixBarsSumHeight - 10));

        // document.getElementById("#" + elementId).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

        // Mit Timeout damit Seite nach Seiten-Animation abschließend gerendert wird.
        // Sonst wird nicht zur richtigen Y-Position des gegebenen Element runtergescrollt.
        window.setTimeout(() => {
            $("html, body").animate(
                {
                    scrollTop: $("#" + elementId).offset().top - this.headerFixBarsSumHeight - 10,
                },
                time || "slow",
                "swing",
            );
        }, 10);
    }

    public setHeight(height: number) {
        $("body").css("height", height + "px");
    }

    // TODO App - Statische Methode SubMenu.openSubMenu verwenden (da sie nur spezielle Logik für das SubMenu und nichts von der GUIPage benötigt)
    public openSubMenu(
        event,
        transferObject: object,
        i18n: I18n,
        selectEntryCallback: (action: string, navigationContext?: {}) => void,
    ) {
        SubMenu.openSubMenu(transferObject, i18n, selectEntryCallback);
    }

    /**
     * Teilt der Seite mit, dass sie geschlossen wird.
     *
     * Schließt potentiell geöffneten Kalender-Picker.
     */
    public leave(): void {
        // Klick im Body schließt potentiell geöffneten Kalender-Picker
        $("body").trigger("click");
    }
}
