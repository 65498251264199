import { GUIElement } from "../GUIElement";
import { FormField, formFieldChangeEventType, FormFieldMode } from "./FormField";
import { StringValue } from "../../entities/values/StringValue";
import { FormFieldAddOn } from "./FormFieldAddOn";

export class TextField implements GUIElement, FormField {
    private type: string = "text";

    private entityId: string;

    private name: string;

    private value: StringValue;

    private label: string;

    private placeholder: string;

    private mode: FormFieldMode = FormFieldMode.EDIT;

    private changeCallback: (changeEvent: formFieldChangeEventType) => void;

    private $inputTag: JQuery;

    private readOnly: boolean = false;
    private maxLength: number;

    public setType(type: string): TextField {
        this.type = type;
        return this;
    }

    public setEntityId(entityId: string): FormField {
        this.entityId = entityId;
        return this;
    }

    public setReadOnly(readOnly: boolean): FormField {
        this.readOnly = readOnly;
        return this;
    }

    public isReadOnly(): boolean {
        return this.readOnly;
    }

    public setName(name: string): TextField {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setValue(value: StringValue): TextField {
        this.value = value;
        return this;
    }

    public setLabel(label: string): TextField {
        this.label = label;
        return this;
    }

    public setPlaceholder(placeholder: string): TextField {
        this.placeholder = placeholder;
        return this;
    }

    public setMode(mode: FormFieldMode): FormField {
        this.mode = mode;
        return this;
    }

    public getMode(): FormFieldMode {
        return this.mode;
    }

    public registerFormFieldAddOn(formFieldAddOn: FormFieldAddOn): FormField {
        // untetstützt bislang bislang keine AddOns
        return this;
    }

    public onChange(changeCallback: (changeEvent: formFieldChangeEventType) => void): TextField {
        this.changeCallback = changeCallback;
        return this;
    }

    public compose($parent: JQuery): void {
        if (this.readOnly) {
            this.composeDisplayMode($parent);
            return;
        }

        switch (this.mode) {
            case FormFieldMode.DISPLAY:
                this.composeDisplayMode($parent);
                break;
            case FormFieldMode.EDIT:
            default:
                this.composeEditMode($parent);
                break;
        }
    }

    private composeDisplayMode($parent: JQuery): void {
        $("<span>")
            .appendTo($parent)
            .text(this.value && this.value.isDefined() ? this.value.getString() : "");
    }

    private composeEditMode($parent: JQuery): void {
        const textValue = this.value && this.value.isDefined() ? this.value.getString() : "";
        let showTextLines = textValue.split("\n").length;
        showTextLines = Math.min(Math.max(3, showTextLines), 7);

        if (this.label) {
            $("<label>").appendTo($parent).attr({ for: this.name }).text(this.label);
        }

        this.$inputTag = $("<textarea>")
            .appendTo($parent)
            .addClass("formfield")
            .css({
                overflow: "visible",
                height: showTextLines * 20 + "px",
                "max-height": showTextLines * 20 + "px",
            })
            .attr({
                name: this.name,
                maxlength: this.maxLength,
                placeholder: this.placeholder,
            })
            .val(this.value && this.value.isDefined() ? this.value.getString() : "")
            .on("change", this.valueChanged.bind(this));
    }

    public updateValue(value: StringValue): TextField {
        if (this.mode == FormFieldMode.EDIT && this.value != value) {
            this.value = value;
            this.$inputTag.data(this.value && this.value.isDefined() ? this.value.getString() : "");
        }
        return this;
    }

    public getValue(): StringValue {
        return new StringValue(<string>this.$inputTag.val());
    }

    private valueChanged(event: Event): void {
        if (this.changeCallback) {
            event.preventDefault();
            event.stopPropagation();

            this.changeCallback({
                entityId: this.entityId,
                name: this.getName(),
                value: this.getValue(),
            });
        }
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }

    setMaxLength(length: number): TextField {
        this.maxLength = length;
        return this;
    }
}
