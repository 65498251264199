import VCard from "vcfer";
import { Property } from "vcfer";
import { Log } from "../../../common/log/Log";

type MyvCard = {
    name?: string;
    userFirstname?: string;
    userAnrede?: string;
    userTitle?: string;
    userTel?: string;
    userHandy?: string;
    userFax?: string;
    mail?: string;
    userFunktion?: string;
    ouName?: string;
    userHomepage?: string;
    ouStreet?: string;
    ouCity?: string;
    ouCountry?: string;
};

/**
 * Umwandlung des gescannten Kontaktes in ein Objekt vom Typ Contact
 */
export class VCFParser {
    public card: VCard;
    private contact: MyvCard = {};

    public parse(result: string): MyvCard {
        if (result.indexOf("\r\n") < 0) {
            result = result.replace(/\n/g, "\r\n");
        }
        this.card = new VCard(result);

        if (this.cardHas("n")) {
            // prüft, ob der Name in der vCard enthalten ist
            try {
                const fullName: string = this.card.getOne("n").getValue();
                const nameArray: string[] = fullName.split(";");
                this.contact["name"] = nameArray[0]; // parsing des Nachnamens
                if (nameArray[1] != undefined) {
                    this.contact["userFirstname"] = nameArray[1]; // parsing des Vornamens
                }
                if (nameArray[2] != undefined) {
                    this.contact["userFirstname"] =
                        this.contact["userFirstname"] + " " + nameArray[2]; // parsing des Vornamens
                }

                if (nameArray[3] != undefined) {
                    this.contact["userTitle"] = nameArray[3]; // parsing des Titels
                }
            } catch (parsingErr) {
                Log.error("Error while Parsing the Name! => " + parsingErr);
            }
        }
        if (this.cardHas("gender")) {
            // Prüfung, ob die Anrede in der vCard enthalten ist
            try {
                switch (
                    this.card.getOne("gender").getValue() // parsing des Geschlechts
                ) {
                    case "F": {
                        this.contact["userAnrede"] = "Female";
                        break;
                    }
                    case "M": {
                        this.contact["userAnrede"] = "Male";
                        break;
                    }
                    case "N":
                    default: {
                        this.contact["userAnrede"] = "Impersonal";
                        break;
                    }
                }
            } catch (parsingErr) {
                Log.error("Error while Parsing the Gender! => " + parsingErr);
            }
        }

        if (this.cardHas("tel")) {
            // Prüfung, ob das Attribut der Telefonnummer in der vCard enthalten ist
            try {
                if (this.card.getOne("tel", "work").toString() === undefined) {
                    // parsing der Telefonnummer
                    if (this.card.getOne("tel", "voice").toString() === undefined) {
                        // parsing der Telefonnummer
                        this.contact["userTel"] = VCFParser.getValueIfDefined(
                            this.card.get("tel", "main-number")[0],
                        );
                    } else {
                        this.contact["userTel"] = VCFParser.getValueIfDefined(
                            this.card.get("tel", "voice")[0],
                        );
                    }
                } else {
                    this.contact["userTel"] = VCFParser.getValueIfDefined(
                        this.card.get("tel", "work")[0],
                    );
                }

                this.contact["userHandy"] = VCFParser.getValueIfDefined(
                    this.card.get("tel", "cell")[0],
                ); // parsing der Handynummer
                this.contact["userFax"] = VCFParser.getValueIfDefined(
                    this.card.get("tel", "fax")[0],
                ); // parsing der Faxnummer
            } catch (parsingErr) {
                Log.error("Error while Parsing Fax or PhoneNumber! => " + parsingErr);
            }
        }
        if (this.cardHas("email")) {
            // Prüfung, ob die Mailadresse in der vCard enthalten ist.
            try {
                this.contact["mail"] = this.card.getOne("email").getValue(); // parsing der Mailadresse
            } catch (parsingErr) {
                Log.error("Error while Parsing the Mailaddress! => " + parsingErr);
            }
        }
        if (this.cardHas("title")) {
            // Prüfung, ob die Stelle der Person in der vCard enthalten ist
            try {
                this.contact["userFunktion"] = this.card.getOne("title").getValue();
            } catch (parsingErr) {
                Log.error("Error while Parsing the UserFunction! => " + parsingErr);
            }
        }
        if (this.cardHas("org")) {
            // Prüfung, ob die Organisation in der vCard enthalten ist
            try {
                this.contact["ouName"] = this.card.getOne("org", "org").getValue(); // parsing der Orga
            } catch (parsingErr) {
                Log.error("Error while Parsing the OUName! => " + parsingErr);
            }
        }
        if (this.cardHas("url")) {
            // Prüfung, ob die Homepage in der vCard enthalten ist
            try {
                this.contact["userHomepage"] = this.card.getOne("url").getValue(); // parsing der Webseite
            } catch (parsingErr) {
                Log.error("Error while Parsing the Homepage! => " + parsingErr);
            }
        }
        if (this.cardHas("adr")) {
            // Prüfung, ob die Adresse in der vCard enthalten ist.
            try {
                const fullAddress: string = this.card.getOne("adr").toString();
                const addressArray: string[] = fullAddress.split(";");
                this.contact["ouStreet"] = addressArray[2]; // parsing der Adresse
                this.contact["ouCity"] = addressArray[3]; // "..."
                this.contact["ouAreacode"] = addressArray[5];
                this.contact["ouCountry"] = addressArray[6]; // "..."
            } catch (parsingErr) {
                Log.error("Error while Parsing the Address! => " + parsingErr);
            }
        }
        if (this.cardHas("note")) {
            // Prüfung, ob die vCard eine Beschreibung enthält
            try {
                this.contact["description"] = this.card.getOne("note").getValue(); // Parsing der Beschreibung, falls vorhanden
            } catch (parsingErr) {
                Log.error("Error while Parsing Contact Notes! => " + parsingErr);
            }
        }
        Log.debug("The Parsed Contact look like this: " + this.contact);
        return this.contact;
    }
    private static getValueIfDefined(myCardElement: Property): string {
        if (myCardElement != undefined) {
            return myCardElement.getValue();
        }
    }
    // Stellt sicher, dass der jew. Wert wirklich in der vCard enthalten ist.
    private cardHas(attribute: string): boolean {
        return this.card.has(attribute);
    }
}
