import { IndexedDBError } from "../IndexedDBError";

export class IndexedDBDestroyRequest {
    private dbName: string;

    constructor(dbName) {
        this.dbName = dbName;
    }

    then(successCallback, errorCallback) {
        const self = this;
        window.setTimeout(function () {
            self.destroyDatabase(successCallback, errorCallback);
        }, 10);
    }

    private destroyDatabase(successCallback, errorCallback) {
        const self = this;

        const request = window.indexedDB.deleteDatabase(this.dbName);
        request.onsuccess = function (event) {
            self.success(event, successCallback);
        };
        request.onerror = function (event) {
            self.error(event, errorCallback);
        };
        request.onblocked = function (event: any) {
            event.error = event.error || {};
            event.error.message = event.error.message || "Blocked";
            self.error(event, errorCallback);
        };
    }

    private success(event, successCallback) {
        successCallback.call(this);
    }

    private error(event, errorCallback) {
        errorCallback.call(this, new IndexedDBError(event));
    }
}
