import { AppConsole } from "../../../common/log/AppConsole";
import { Log } from "../../../common/log/Log";
import { ApplicationProperties } from "../../../common/properties/ApplicationProperties";
import { Schema } from "../../../common/schema/Schema";
import { IndexedDB } from "../../../database/IndexedDB";
import { IndexedDBQuery } from "../../../database/IndexedDBQuery";
import { IndexedDBVersion } from "../../../database/IndexedDBVersion";
import { TimeRecord } from "../TimeRecord";
import { Booking } from "./Booking";
import { BookingsClient } from "./BookingsClient";
import { TimeRecordingOptions } from "../TimeRecordingOptions";

export class BookingsPool {
    public static RECORDING_TERMS_PROPERTY_KEY = "TimeRecordingOptions";

    private static BOOKING_STORE_NAME = "bookings";

    private static INDEX_USER_RECORD_DATE = "effortDate";

    private schema: Schema;

    private indexedDB: IndexedDB;

    private applicationProperties: ApplicationProperties;

    // TODO App - TEMP Use IndexedDB!
    private bookingsById: { [key: string]: object } = {};

    private poolUpdateAge: number;
    private recordingOptions: TimeRecordingOptions;

    constructor(
        indexedDB: IndexedDB,
        applicationProperties: ApplicationProperties,
        schema: Schema,
    ) {
        this.indexedDB = indexedDB;
        this.schema = schema;

        this.applicationProperties = applicationProperties;

        this.indexedDB
            .registerStore(BookingsPool.BOOKING_STORE_NAME, IndexedDBVersion.DB_VERSION_1)
            .setIdKey("oid")
            .addIndex(
                BookingsPool.INDEX_USER_RECORD_DATE,
                ["effortUserOid", "effortDate", "effortTargetSubtyp", "effortStart"],
                false,
                IndexedDBVersion.DB_VERSION_1,
            );
    }

    public async synchronizeBookingsWithBCS(
        userOid: string,
        lastBCSSyncState: number,
    ): Promise<{ syncBCSStateTimestamp; bookingsJSONs? }> {
        AppConsole.debug("[BookingsPool] readBookingsFromBCS ", lastBCSSyncState);

        const self = this;
        return new Promise<{ syncBCSStateTimestamp; bookingsJSONs? }>((resolve, reject) => {
            new BookingsClient()
                .fetchBookings(lastBCSSyncState)
                .then(async (result) => {
                    AppConsole.debug("[BookingsPool] Bookings FETCHED", result, lastBCSSyncState);
                    const bookingResult: { syncBCSStateTimestamp: number; bookingsJSONs? } = {
                        syncBCSStateTimestamp: -1,
                    };
                    const has_content: boolean = result !== null;
                    if (has_content) {
                        const bookings: Booking[] = [];
                        if (result.hasOwnProperty("bookings")) {
                            const bookingsJSONs = result["bookings"];
                            bookingResult.bookingsJSONs = bookingsJSONs;
                        }
                        if (result.hasOwnProperty("syncStateTimestamp")) {
                            bookingResult.syncBCSStateTimestamp = result.syncStateTimestamp;
                        }
                        resolve(bookingResult);
                    } else {
                        // kein neuer Content daher passen wir den SyncTimestamp nicht an.
                        resolve(bookingResult);
                    }
                })
                .catch(reject);
        });
    }

    public cleanAllBookingsInDB(userOid: string): Promise<void> {
        const minRecordDate = "1970-01-01",
            maxRecordDate = "2199-12-31";
        const query = IndexedDBQuery.greaterOrEqualAndLesserOrEqual(
            [userOid, minRecordDate],
            [userOid, maxRecordDate],
        );

        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([BookingsPool.BOOKING_STORE_NAME])
                .deleteQuery(
                    BookingsPool.BOOKING_STORE_NAME,
                    BookingsPool.INDEX_USER_RECORD_DATE,
                    query,
                )
                .then(
                    (result) => {
                        AppConsole.debug("[BookingsPool] delete alle bookings", result, userOid);
                        resolve();
                    },
                    (error) => {
                        Log.error("[BookingsPool] Error while clean bookings:", error, error);
                        reject(error);
                    },
                );
        });
    }

    public writeBookingToBCSServer(booking: Booking, touchedFields: string[]): Promise<TimeRecord> {
        const self = this;
        AppConsole.debug("[BoolingPool.writeBookingToBCSServer]", booking);
        return new Promise((resolve, reject) => {
            new BookingsClient()
                .saveBooking(self.poolUpdateAge, booking.toTouchedValueObject(touchedFields))
                .then((result) => {
                    AppConsole.debug("[BookingsPool] Booking Saved", result, this.poolUpdateAge);
                    const savedBooking = self.wrapBookingIntoDomainObject(result["bookings"][0]);
                    resolve(savedBooking);
                })
                .catch((errorInformations) => {
                    reject(errorInformations);
                });
        });
    }

    public deleteBookingFromBCSServer(booking: TimeRecord): Promise<void> {
        const self = this;

        return new Promise((resolve, reject) => {
            new BookingsClient()
                .deleteBooking(self.poolUpdateAge, booking.toValueObject())
                .then((result) => {
                    AppConsole.debug("[BookingsPool] Booking Deleted", result, this.poolUpdateAge);
                    resolve();
                })
                .catch((errorInformations) => {
                    reject(errorInformations);
                });
        });
    }

    public getBookingById(id: string): Booking {
        if (this.bookingsById.hasOwnProperty(id)) {
            return new Booking(this.schema, this.bookingsById[id]);
        }
        return null;
    }

    public readAllBookingsFromDB(
        userOid: string,
        minRecordDate = "1970-01-01",
        maxRecordDate = "2199-12-31",
    ): Promise<Booking[]> {
        const query = IndexedDBQuery.greaterOrEqualAndLesserOrEqual(
            [userOid, minRecordDate],
            [userOid, maxRecordDate],
        );

        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([BookingsPool.BOOKING_STORE_NAME])
                .selectCursor(
                    BookingsPool.BOOKING_STORE_NAME,
                    BookingsPool.INDEX_USER_RECORD_DATE,
                    query,
                )
                .then(
                    (result) => {
                        const booking: Booking[] = [];
                        if (result && result.resultSet) {
                            resolve(self.wrapBookingsIntoDomainObjects(result.resultSet));
                        } else {
                            resolve([]);
                        }
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    public readBookingFromDB(userOid: string, oid: string): Promise<Booking> {
        const query = IndexedDBQuery.greaterOrEqualAndLesserOrEqual([userOid], [userOid]);

        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([BookingsPool.BOOKING_STORE_NAME])
                .selectId(BookingsPool.BOOKING_STORE_NAME, oid)
                .then(
                    (result) => {
                        const booking: Booking[] = [];
                        if (result && result.element) {
                            resolve(self.wrapBookingIntoDomainObject(result.element));
                        } else {
                            resolve(null);
                        }
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    public deleteBookingFromDB(id: string[]): Promise<TimeRecord> {
        const self = this;
        AppConsole.debug("[BookingPool.deleteBookingFromDB]", id);
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([BookingsPool.BOOKING_STORE_NAME])
                .deleteIds(BookingsPool.BOOKING_STORE_NAME, id)
                .then(resolve, reject);
        });
    }

    public writeBookingToDB(booking: Booking): Promise<void> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([BookingsPool.BOOKING_STORE_NAME])
                .updateElements(BookingsPool.BOOKING_STORE_NAME, [booking.toValueObject()])
                .then(resolve, reject);
        });
    }

    public wrapBookingIntoDomainObject(bookingValueObject: object): Booking {
        return new Booking(this.schema, bookingValueObject);
    }

    public async readTermsFromDB(currentUserOid: string): Promise<TimeRecordingOptions> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.applicationProperties
                .readProperty(BookingsPool.RECORDING_TERMS_PROPERTY_KEY, currentUserOid, null)
                .then((recordingTermsProperty) => {
                    this.recordingOptions = new TimeRecordingOptions(recordingTermsProperty);
                    resolve(this.recordingOptions);
                })
                .catch(reject);
        });
    }

    public async writeTermsToDB(currentUserOid: string, recordingTerms: TimeRecordingOptions) {
        this.recordingOptions = recordingTerms;

        const self = this;

        return new Promise((resolve, reject) => {
            const recordingOptionsProperty = recordingTerms.toValueObject();
            self.applicationProperties
                .writeProperty(
                    BookingsPool.RECORDING_TERMS_PROPERTY_KEY,
                    currentUserOid,
                    null,
                    recordingOptionsProperty,
                )
                .then(resolve)
                .catch(reject);
        });
    }

    private wrapBookingsIntoDomainObjects(bookingValueObjects: object[]): Booking[] {
        const booking: Booking[] = [];

        for (let i = 0; i < bookingValueObjects.length; i++) {
            booking.push(this.wrapBookingIntoDomainObject(bookingValueObjects[i]));
        }

        return booking;
    }
}
