import { GUIElement } from "../GUIElement";
import { FlexAlignItems, FlexJustifyContent, FlexDirection } from "./FlexBox";

/**
 * CSS-FlexItem
 *
 * Bietet Convenience-Funktionen zum Setzen des CSS-Eigenschaften.
 *
 * https://css-tricks.com/snippets/css/a-guide-to-flexbox/
 */
export class FlexItem implements GUIElement {
    /** Verhältnis beim Vergrößern zu anderen Items: flex-grow = number */
    private flexGrow: number;

    /** Verhältnis beim Verkleinern zu anderen Items: flex-shrink = number */
    private flexShrink: number;

    /** Basis-Verhältnis zu anderen Items: flex-basis = auto | 1  | 10em | 100px | 25% */
    private flexBasis: string = "1";

    /** Oben/Mitte/Unten-Bündigkeit abweichend für dieses Item: align-self = auto | flex-start | flex-end | center | baseline | stretch */
    private alignSelf: FlexAlignSelf;

    /** GUIElemente als Inhalt */
    private contentElements: GUIElement[] = [];

    private styleClasses: string[] = [];

    private flexBoxCSSMap: { [key: string]: string };

    private clickCallback: Function;

    private transitionEndCallback: Function;

    private $flexContainerTag: JQuery;

    /**
     * @param flexGrow Verhältnis beim Vergrößern (wenn Parent mehr Platz bietet) zu anderen Items: z.B. 1, 2, 4
     */
    public setFlexGrow(flexGrow: number): FlexItem {
        this.flexGrow = flexGrow;
        return this;
    }

    /**
     * @param flexShrink Verhältnis beim Verkleinern (wenn Parent mehr Platz bietet) zu anderen Items: z.B. 1, 2, 4
     */
    public setFlexShrink(flexShrink: number): FlexItem {
        this.flexShrink = flexShrink;
        return this;
    }

    /**
     * @param flexBasis  Basis-Verhältnis zu anderen Items: z.B. auto | 1  | 10em | 100px | 25%
     */
    public setFlexBasis(flexBasis: string): FlexItem {
        this.flexBasis = flexBasis;
        return this;
    }

    /**
     * Oben/Mitte/Unten-Bündigkeit abweichend für dieses Item
     *
     * @param alignSelf auto | flex-start | flex-end | center | baseline | stretch
     */
    public setAlignSelf(alignSelf: FlexAlignSelf): FlexItem {
        this.alignSelf = alignSelf;
        return this;
    }

    /**
     * @param contentElements alle Inhalts-GUIElemente
     */
    public setContentElements(contentElements: GUIElement[]): FlexItem {
        this.contentElements = contentElements;
        return this;
    }

    /**
     * Fügt ein ContentElement hinzu.
     * Alle ContentElemente werden beim composen dieses FlexItems gerendert.
     * Sofortiges rendern neuer ContentElemente, wenn GUIElement bereits composed
     *
     * @param contentElement ein Inhalts-GUIElement
     */
    public addContentElement(contentElement: GUIElement): FlexItem {
        this.contentElements.push(contentElement);

        // Sofortiges rendern neuer ContentElemente, wenn GUIElement bereits composed
        if (this.$flexContainerTag) {
            contentElement.compose(this.$flexContainerTag);
        }

        return this;
    }

    /**
     * @return Nested Flex-Item, das gleichzeit als FlexBox fungiert
     */
    public newFlexItem(
        justifyContent: FlexJustifyContent = FlexJustifyContent.SPACE_BETWEEN,
    ): FlexItem {
        this.flexBoxCSSMap = {
            display: "flex",
            "flex-direction": FlexDirection.ROW,
            "justify-content": justifyContent,
            "align-items": FlexAlignItems.STRETCH,
        };

        const flexItem = new FlexItem();
        this.addContentElement(flexItem);
        return flexItem;
    }

    public addStyleClass(styleClass: string): FlexItem {
        this.styleClasses.push(styleClass);
        if (this.$flexContainerTag) {
            this.$flexContainerTag.addClass(styleClass);
        }
        return this;
    }

    public removeStyleClass(styleClass: string): FlexItem {
        this.styleClasses = this.styleClasses.filter((className) => {
            return className != styleClass;
        });
        if (this.$flexContainerTag) {
            this.$flexContainerTag.removeClass(styleClass);
        }
        return this;
    }

    public clearStyleClasses(): FlexItem {
        this.styleClasses = [];
        if (this.$flexContainerTag) {
            this.$flexContainerTag.removeClass();
        }
        return this;
    }

    public onClick(clickCallback: Function): FlexItem {
        this.clickCallback = clickCallback;
        return this;
    }

    public onTransitionEnd(transitionEndCallback: Function): FlexItem {
        this.transitionEndCallback = transitionEndCallback;
        return this;
    }

    /**
     * HTML-DOM generieren
     *
     * @param $parent Parent-jQuery-Objekt
     */
    public compose($parent: JQuery): void {
        this.$flexContainerTag = $("<div>")
            .appendTo($parent)
            .addClass(this.styleClasses.join(" "))
            .css({
                "flex-grow": this.flexGrow,
                "flex-srink": this.flexShrink,
                "flex-basis": this.flexBasis,
                "align-self": this.alignSelf,
            });

        if (this.flexBoxCSSMap) {
            this.$flexContainerTag.css(this.flexBoxCSSMap);
        }

        for (let i = 0; i < this.contentElements.length; i++) {
            this.contentElements[i].compose(this.$flexContainerTag);
        }

        if (this.clickCallback) {
            this.$flexContainerTag.on("click", this.clickCallback.bind(this));
        }
        if (this.transitionEndCallback) {
            this.$flexContainerTag.on("transitionend", this.transitionEndCallback.bind(this));
        }
    }

    /**
     * Entfernt alle ContentElemente
     */
    public clearContentElements(): FlexItem {
        if (this.$flexContainerTag) {
            this.$flexContainerTag.empty();
        }
        this.contentElements = [];
        return this;
    }

    public getComponentChildren(): GUIElement[] {
        return this.contentElements;
    }

    /**
     * @return JQuery-DOM-Element des FlexItems (z.B. zum nachträglichen ändern von CSS-Eigenschaften)
     */
    public getFlexItemTag(): JQuery {
        return this.$flexContainerTag;
    }
}

/** Oben/Mitte/Unten-Bündigkeit abweichend für dieses Item */
export const enum FlexAlignSelf {
    AUTO = "auto",
    FLEX_START = "flex-start",
    FLEX_END = "flex-end",
    CENTER = "center",
    BASELINW = "baseline",
    STRETCH = "stretch",
}
