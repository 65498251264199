import { GUIElement } from "../GUIElement";

export class HTMLContent implements GUIElement {
    public static BCS_COMPONENT_NAME = "HTMLContent";

    private $contents: JQuery[] = [];

    constructor(content?: string | JQuery | JQuery[]) {
        if (typeof content !== "undefined") {
            if (Array.isArray(content)) {
                content.forEach((c) => this.addContent(c));
            } else {
                this.addContent(content);
            }
        }
    }

    public setContent(content: string | JQuery): HTMLContent {
        this.$contents = [];
        this.addContent(content);
        return this;
    }

    public addContent(content: string | JQuery): HTMLContent {
        this.$contents.push($(content));
        return this;
    }

    public compose($parent: JQuery): void {
        this.$contents.forEach(($c) => $c.appendTo($parent));
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}
