import { Component } from "../../core/Component";
import { Controller } from "../../core/Controller";
import { MessagePool } from "../../core/Message/MessagePool";
import { Registry } from "../../core/Registry";
import { Animation, AppNavigator } from "../../core/Router";
import { HTMLContent } from "../../gui/content/HTMLContent";
import { TextLabel } from "../../gui/content/TextLabel";
import { GUIEventPool } from "../../gui/event/GUIEventPool";
import { FlexBox, FlexJustifyContent } from "../../gui/flexbox/FlexBox";
import { Form } from "../../gui/form/Form";
import { FormButton } from "../../gui/form/FormButton";
import { GUIContext } from "../../gui/GUIContext";
import { GUIPage } from "../../gui/GUIPage";
import { ListRow } from "../../gui/list/ListRow";
import { ListView } from "../../gui/list/ListView";
import { I18n } from "../i18n/I18n";
import "./Logout.less";

/**
 * Nach Logout und Löschen aller Offline-Daten wird diese AfterLogout-Page angzeigt:
 * Zeigt Info dass alle Offline-DAten gelöscht wurden
 * Bietet Button zum Neuladen der App um sich erneut einloggen zu können.
 *
 * Notwendig, da nach Ausloggen und Löschen der Offline-Daten der Service-Worker deinstalliert und die IndexedDB gelöscht wurde.
 * Vor dem erneuten Einloggen muss die App neu geladen werden, damit der Service-Worker wieder installiert und die IndexedDB neu erstellt wird..
 */
export class AfterLogoutController implements Controller {
    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static readonly CONTROLLER_PATH = "afterlogout";

    private navigator: AppNavigator;

    private i18n: I18n;

    private messagePool: MessagePool;

    private page: GUIPage;

    private context: GUIContext;
    private eventPool: GUIEventPool;

    constructor() {
        this.eventPool = new GUIEventPool();
        this.registerEvents();
    }

    public getDependencyNames(): string[] {
        return [I18n.BCS_COMPONENT_NAME];
    }

    public registerEvents() {
        this.eventPool.registerEventListener(
            "Message",
            "remove",
            this.removeFromMessagePool.bind(this),
        );
    }

    public init(depencencyComponents: { [key: string]: Component }) {
        //Setzt die Theme Color nur für das Boardelement um
        if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
            $("meta[name='theme-color']").attr("content", "#121212"); // Farbe für den Darkmode
        } else {
            $("meta[name='theme-color']").attr("content", "#EBEFF3"); // Farbe für den Lightmode
        }

        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.i18n.useFallbackLabels();
        this.context = new GUIContext(this.i18n, this.eventPool);

        this.messagePool = <MessagePool>depencencyComponents[MessagePool.BCS_COMPONENT_NAME];
    }

    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        this.navigator = navigator;

        this.page = new GUIPage(this.context, AfterLogoutController.CONTROLLER_PATH);

        const logoutForm = new Form().setName("reload");
        this.page.addPageElement(logoutForm);

        const logoutListView = new ListView();

        const logoutFlexBox = new FlexBox()
            .addStyleClass("logoutFlexBox")
            .setJustifyContent(FlexJustifyContent.SPACE_BETWEEN);

        logoutFlexBox.newFlexItem().setFlexBasis("5%");

        logoutForm.addLayoutElement(logoutFlexBox);

        const center = logoutFlexBox.newFlexItem().setFlexBasis("95%");
        center.addContentElement(logoutListView);

        logoutFlexBox.newFlexItem().setFlexBasis("5%");

        // Login-Banner
        logoutListView.addRow(
            new ListRow()
                .addStyleClass("loginBanner")
                .addContentElement(
                    new HTMLContent([
                        $("<img>")
                            .addClass("image")
                            .attr("src", "images/icons/projektron-icon-big.svg"),
                        $("<span>").addClass("projektron").text("Projektron"),
                        $("<span>").addClass("bcs").text("BCS"),
                    ]),
                ),
        );

        // Infotext dass ausgelogged alle Offline-Daten gelösht wurden.
        const infoText = new TextLabel().setText(
            this.i18n.get("MobileApp.InfoTextAfterLogoutAndClearSiteData"),
        );
        logoutListView.addRow(
            new ListRow().addContentElement(infoText).addStyleClass("reInitialisationPageLabel"),
        );

        // Button zum neu laden der App (=Reload der Browser-Seite)
        const reloadAppButton = new FormButton()
            .setName("submit")
            .setLabel(this.i18n.get("MobileApp.ReloadApp"))
            .onClick(() => this.reloadApp());
        logoutListView.addRow(new ListRow().addContentElement(reloadAppButton));

        this.page.composeMessagesContainer();

        this.page.compose($("body"));
    }

    private reloadApp() {
        let url = window.location.href;
        url = url.substring(0, url.lastIndexOf("/app"));
        window.location.href = url + "/app";
    }

    public popState() {
        this.navigator.navigateTo("index", {}, Animation.SLIDE_RIGHT);
    }

    public destroy(): void {}

    private removeFromMessagePool(event, transferObject: object) {
        if (transferObject.hasOwnProperty("messageEntity")) {
            this.messagePool.removeMessage(transferObject["messageEntity"]);
        }
    }
}

Registry.registerComponent(AfterLogoutController.CONTROLLER_PATH, AfterLogoutController);
