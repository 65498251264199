import * as d3 from "d3";
import { BCSDate } from "../../common/BCSDate";
import { AppConsole } from "../../common/log/AppConsole";
import { ChartUtil } from "../../gui/chart/ChartUtil";
import { GUIElement } from "../../gui/GUIElement";
import { OnRenderBehavior } from "../../gui/OnRenderBehavior";
import { AppColors } from "../../util/globalDefinitions/AppColors";
import { TimeRecord } from "./TimeRecord";

/**    addDurationOnlyLastEnd(arg0: any): any {
        throw new Error("Method not implemented.");
    }

 *
 * <ul>
 *  <li></li>
 * </ul>
 *
 */
export class TimeRecordingAttendanceSummary implements GUIElement, OnRenderBehavior {
    public offsetXBefore: number = 30;
    private styleClasses: string[] = [];
    private startDate: Date;
    private endDate: Date;
    private id: string;
    private width: number;
    private height: number;
    private offsetXAfter: number = 50;

    private summaryLineHeight = 16;

    private isDurationOnly: boolean = false;

    private lastEndPoint: number = -1;

    private xScale;

    private bookings: TimeRecord[];

    private maxHours: number;

    private readonly mainColor_light: string = AppColors.TimeRecording_BookedTimeColor_light;
    private readonly mainColor_normal: string = AppColors.TimeRecording_BookedTimeColor;

    constructor(
        startDate: Date,
        endDate: Date,
        width: number,
        height: number,
        attendance: TimeRecord,
        bookings: TimeRecord[],
    ) {
        this.startDate = startDate;
        this.isDurationOnly = attendance.hasDurationOnly();
        this.endDate = endDate;
        this.width = width;
        this.height = height;
        this.bookings = bookings;

        if (attendance.hasSyncState() && attendance.isSyncOnlyChangedInApp()) {
            this.mainColor_light = AppColors.TimeRecording_IsSyncOnlyChangedInApp_Color_hex_BG;
            this.mainColor_normal = AppColors.TimeRecording_IsSyncOnlyChangedInApp_Color_hex;
        }

        this.id = attendance.getId();

        if (this.isDurationOnly) {
            this.maxHours = attendance.getEffortExpense() / 60;
            this.xScale = d3
                .scaleLinear()
                .domain([0, attendance.getEffortExpense() / 60])
                .range([0, this.width - (this.offsetXBefore + this.offsetXAfter)]);
        } else {
            this.xScale = d3
                .scaleTime()
                .domain([this.startDate, this.endDate])
                .range([0, this.width - (this.offsetXBefore + this.offsetXAfter)])
                .nice();
        }
    }

    public compose($parent: JQuery): void {
        const timeDistributions = this.xScale.ticks(2);
        const svgWrapper = $("<div class='backgroundRowWrapper'>");
        $parent.append(svgWrapper);
        const svg = d3
            .select(svgWrapper[0])
            .append("svg")
            .attr("height", this.height)
            .attr("width", this.width - 1)
            .attr("id", "attendanceSummary_" + this.id);

        // grau bis zum Anzeigebereich:
        svg.append("rect")
            .attr("class", "bg-rect")
            .attr("fill", "#ABAFB8")
            .attr("height", this.summaryLineHeight)
            .attr("width", this.offsetXBefore)
            .attr("x", 0)
            .attr("y", 18);

        // Anzeige:
        svg.append("rect")
            .attr("class", "bg-rect")
            .attr("fill", this.mainColor_normal)
            .attr("height", this.summaryLineHeight)
            .attr("width", this.width - this.offsetXAfter)
            .attr("x", this.offsetXBefore)
            .attr("y", 18);

        this.composeUndistributedEffort(svg);

        // nach der Anzeige, grau:
        svg.append("rect")
            .attr("class", "bg-rect")
            .attr("fill", "#ABAFB8")
            .attr("height", this.summaryLineHeight)
            .attr("width", this.offsetXAfter)
            .attr("x", this.width - this.offsetXAfter)
            .attr("y", 18);
        let lastX = 0;

        let unit = "";

        let distributionLength = timeDistributions.length;
        if (this.isDurationOnly) {
            if (timeDistributions[distributionLength - 1] != this.maxHours) {
                const format = d3.format(".1f");
                const maxHoursFormatted = format(this.maxHours);
                timeDistributions.push(maxHoursFormatted);
                distributionLength++;
            }
            unit = "h";
        } else {
            if (new BCSDate(timeDistributions[0]).minuteDiff(new BCSDate(this.startDate)) !== 0) {
                timeDistributions.unshift(this.startDate);
                distributionLength++;
            }
            if (
                new BCSDate(timeDistributions[distributionLength - 1]).minuteDiff(
                    new BCSDate(this.endDate),
                ) !== 0
            ) {
                timeDistributions.push(this.endDate);
                distributionLength++;
            }
        }

        // Achsen Ticks:
        for (let i = 0; i < distributionLength; i++) {
            const currentTime = this.xScale(timeDistributions[i]);

            svg.append("rect")
                .attr("class", "bg-rect")
                .attr("fill", "white")
                .attr("height", 8)
                .attr("width", 2)
                .attr("x", this.offsetXBefore + currentTime)
                .attr("y", 18);
            const isLast: boolean = i == distributionLength - 1;

            if (i == 0 || isLast) {
                let additionalClass = "";
                if (isLast) {
                    additionalClass = " isLast";
                }

                svg.append("text")
                    .text(this.getTimeTickText(timeDistributions[i], unit))
                    .attr("class", "attendanceTimeTickLabel" + additionalClass)
                    .attr("x", this.offsetXBefore + currentTime)
                    .attr("y", 0)
                    .attr("text-anchor", "middle")
                    .attr("fill", this.mainColor_normal)
                    .style("font-size", "14px")
                    .attr("alignment-baseline", "hanging")
                    // für FireFox:
                    .attr("dominant-baseline", "hanging");
            } else {
                svg.append("text")
                    .text(this.getTimeTickText(timeDistributions[i], unit))
                    .attr("class", "attendanceTimeTickLabel")
                    .attr("x", this.offsetXBefore + currentTime)
                    .attr("y", 0)
                    .attr("text-anchor", "middle")
                    .attr("fill", this.mainColor_normal)
                    .style("font-size", "13px")
                    .attr("alignment-baseline", "hanging")
                    // für FireFox:
                    .attr("dominant-baseline", "hanging");
            }

            lastX = currentTime;
        }
    }

    executeOnRender($body: JQuery<HTMLElement>): void {
        const svg = d3.select("svg#attendanceSummary_" + this.id);
        try {
            /* Manchmal überlagern sich die Label der Tickets, dies wollen wir ausschließen */
            let lastLabel = null;
            svg.selectAll(".attendanceTimeTickLabel").each(function () {
                const currentNode: any = this;
                if (lastLabel != null) {
                    const currentBB = currentNode.getBBox();
                    const lastBB = lastLabel.getBBox();
                    if (
                        ChartUtil.xCoordinatesOverlap(
                            lastBB.x,
                            lastBB.width,
                            currentBB.x,
                            currentBB.width,
                        )
                    ) {
                        const isLast = d3.select(currentNode).attr("class");
                        if (isLast.indexOf("isLast") !== -1) {
                            //d3.select(lastLabel).attr("fill", "red");
                            d3.select(lastLabel).remove();
                        } else {
                            //d3.select(currentNode).attr("fill", "red");
                            d3.select(currentNode).remove();
                        }
                    }
                }
                lastLabel = this;
            });
        } catch (exception) {
            AppConsole.error(exception);
        }
    }

    public getTimeTickText(currentTimeDistribution, unit: string): string {
        if (this.isDurationOnly) {
            return currentTimeDistribution + unit;
        } else {
            return new BCSDate(currentTimeDistribution).format("H:mm");
        }
    }

    public addStyleClass(styleClass: string): TimeRecordingAttendanceSummary {
        this.styleClasses.push(styleClass);
        return this;
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }

    public getScale(): any {
        return this.xScale;
    }

    /**
     * Wenn wir die Flächen der Buchungen unter einer Anwesenheit ohne Start und Endzeit malen.
     * Dann haben wir das Problem, dass die Dauern der Buchungen nacheinander horizontal abfolgen sollen.
     * Wir wissen jedoch nicht was der letzte Endpunkt der schon angezeigten Buchung ist. Daher merken wir uns diesen Endpunkt.
     * Tun wir dies nicht liegen alle Buchungen untereinander.
     *
     *
     * @param lastEndPoint
     */
    public setDurationOnlyLastEnd(lastEndPoint: number) {
        this.lastEndPoint = lastEndPoint;
    }

    public getDurationOnlyLastEnd(): number {
        return this.lastEndPoint;
    }

    private composeUndistributedEffort(svg: any) {
        const bookingLength = this.bookings.length;
        for (let i = 0; i < bookingLength; i++) {
            const booking: TimeRecord = this.bookings[i];
            if (this.bookings[i].isDummy()) {
                if (this.isDurationOnly) {
                    var startPoint: number = this.xScale(0) + this.offsetXBefore;
                    var endPoint: number =
                        this.xScale(booking.getEffortExpense() / 60) + this.offsetXBefore;
                } else {
                    var startPoint: number =
                        this.xScale(booking.getStartTime()) + this.offsetXBefore;
                    var endPoint: number = this.xScale(booking.getEndTime()) + this.offsetXBefore;

                    svg.append("rect")
                        .attr("class", "bg-rect")
                        .attr("fill", this.mainColor_light)
                        .attr("height", this.summaryLineHeight)
                        .attr("width", endPoint - startPoint)
                        .attr("x", startPoint)
                        .attr("y", 18);
                }
            }
        }
    }
}
