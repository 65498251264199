import { TimesheetClient } from "./TimesheetClient";
import { Task } from "./Task";
import { Schema } from "../../../common/schema/Schema";
import { TimesheetEntry } from "./TimesheetEntry";
import { Appointment } from "./Appointment";
import { IndexedDB } from "../../../database/IndexedDB";
import { IndexedDBVersion } from "../../../database/IndexedDBVersion";
import { UserSession } from "../../../common/auth/UserSession";
import { IndexedDBQuery } from "../../../database/IndexedDBQuery";
import { Ticket } from "./Ticket";
import { Requirement } from "./Requirement";
import { Workflow } from "./Workflow";
import { SyncStateManager } from "../../../sync/SyncStateManager";
import { Log } from "../../../common/log/Log";
import { AppConsole } from "../../../common/log/AppConsole";
import { ProgressFeedback } from "../../../util/progress/ProgressFeedback";

export class TimesheetPool {
    private static TIMESHEET_STORE_NAME = "timesheet";

    private static TIMESHEET_APPOINTMENTS_STORE_NAME = "timesheet_appointments";

    private static TIMESHEET_TICKETS_STORE_NAME = "timesheet_tickets";

    private static TIMESHEET_WORKFLOWS_STORE_NAME = "timesheet_workflows";

    private static TIMESHEET_REQUIREMENTS_STORE_NAME = "timesheet_requirement";

    private static INDEX_USER_RECORD = "userOid";

    // TODO App - TEMP Use IndexedDB!
    private timesheetData: object = {};

    private tasksById: { [key: string]: object } = {};
    private appointmentsById: { [key: string]: object } = {};

    private timesheetUpdateCheckSum: number;

    private schema: Schema;

    private userSession: UserSession;

    private syncStateTimeRecordPool: SyncStateManager;

    private indexedDB: IndexedDB;

    constructor(
        indexedDB: IndexedDB,
        schema: Schema,
        userSession: UserSession,
        syncStateTimeRecordPool: SyncStateManager,
    ) {
        this.indexedDB = indexedDB;
        this.schema = schema;
        this.userSession = userSession;
        this.syncStateTimeRecordPool = syncStateTimeRecordPool;

        this.indexedDB
            .registerStore(TimesheetPool.TIMESHEET_STORE_NAME, IndexedDBVersion.DB_VERSION_1)
            .setIdKey("task__oid")
            .addIndex(
                TimesheetPool.INDEX_USER_RECORD,
                ["userOid"],
                false,
                IndexedDBVersion.DB_VERSION_1,
            );

        this.indexedDB
            .registerStore(
                TimesheetPool.TIMESHEET_APPOINTMENTS_STORE_NAME,
                IndexedDBVersion.DB_VERSION_1,
            )
            .setIdKey("bookableElement__oid")
            .addIndex(
                TimesheetPool.INDEX_USER_RECORD,
                [
                    "userOid",
                    "defaultEventStartDate",
                    "defaultEventEndDate",
                    "defaultRecordStartTime",
                ],
                false,
                IndexedDBVersion.DB_VERSION_1,
            )
            .changeIndex(
                TimesheetPool.INDEX_USER_RECORD,
                ["userOid", "defaultEventStartDate", "defaultEventEndDate"],
                false,
                IndexedDBVersion.DB_VERSION_2,
            );

        this.indexedDB
            .registerStore(
                TimesheetPool.TIMESHEET_TICKETS_STORE_NAME,
                IndexedDBVersion.DB_VERSION_1,
            )
            .setIdKey("bookableElement__oid")
            .addIndex(
                TimesheetPool.INDEX_USER_RECORD,
                ["userOid"],
                false,
                IndexedDBVersion.DB_VERSION_1,
            );

        this.indexedDB
            .registerStore(
                TimesheetPool.TIMESHEET_REQUIREMENTS_STORE_NAME,
                IndexedDBVersion.DB_VERSION_1,
            )
            .setIdKey("bookableElement__oid")
            .addIndex(
                TimesheetPool.INDEX_USER_RECORD,
                ["userOid"],
                false,
                IndexedDBVersion.DB_VERSION_1,
            );

        this.indexedDB
            .registerStore(
                TimesheetPool.TIMESHEET_WORKFLOWS_STORE_NAME,
                IndexedDBVersion.DB_VERSION_1,
            )
            .setIdKey("bookableElement__oid")
            .addIndex(
                TimesheetPool.INDEX_USER_RECORD,
                ["userOid"],
                false,
                IndexedDBVersion.DB_VERSION_1,
            );
    }

    public async synchronizeTimesheetWithBCS(
        userOid: string,
        lastChecksum: number,
        progressFeedback: ProgressFeedback,
    ): Promise<number> {
        const timesheetResult = await new TimesheetClient().fetchTimesheet(
            lastChecksum,
            progressFeedback,
        );

        AppConsole.debug("[Timesheet] Timesheet FETCHED", timesheetResult, lastChecksum);

        // TODO App - Timesheet in Aufgaben, Tickets, Termine, Scrum-Elemente, Workflow-Schritte zerlegen ...
        this.timesheetData = timesheetResult;

        if (
            timesheetResult.hasOwnProperty("no_content") &&
            timesheetResult["no_content"] === true
        ) {
            return lastChecksum;
        } else {
            const checksum: number = timesheetResult["checksum"];
            this.timesheetUpdateCheckSum = checksum;
            const tasksJSONs = timesheetResult["Tasks"];
            for (let i = 0; i < tasksJSONs.length; i++) {
                const taskJSON: JSON = tasksJSONs[i];

                taskJSON["userOid"] = userOid;

                const id = taskJSON["task__oid"];
                this.tasksById[id] = taskJSON;
                this.writeTimesheetToDB(this.getTaskById(id, checksum));
            }

            const eventsJSONs = timesheetResult["Events"];
            if (typeof eventsJSONs !== "undefined") {
                // Löschen vorher alle alten Termine weg. Damit wir nur das aktuelle TimeSheet zur Verfügung haben.
                // Sonst würden Termine die in BCS gelöscht werden, noch in der App angezeigt werden.
                this.deleteTimesheetAllEventsInDBForUser(userOid);

                for (let i = 0; i < eventsJSONs.length; i++) {
                    const eventJSON = eventsJSONs[i];
                    eventJSON["userOid"] = userOid;

                    const id = eventJSON["bookableElement__oid"];
                    this.appointmentsById[id] = eventJSON;
                    this.writeTimesheetEventToDB(this.getAppointmentById(id));
                }
            }

            const ticketsJSONs = timesheetResult["Tickets"];
            if (typeof ticketsJSONs !== "undefined") {
                for (let i = 0; i < ticketsJSONs.length; i++) {
                    const ticketJSON = ticketsJSONs[i];
                    ticketJSON["userOid"] = userOid;

                    //let id = ticketJSON["bookableElement:oid"];
                    this.writeTimesheetTicketToDB(new Ticket(this.schema, ticketJSON));
                }
            }

            const requirementsJSONs = timesheetResult["Requirements"];
            if (typeof requirementsJSONs !== "undefined") {
                for (let i = 0; i < requirementsJSONs.length; i++) {
                    const requirementJSON = requirementsJSONs[i];
                    requirementJSON["userOid"] = userOid;

                    this.writeTimesheetRequirementToDB(
                        new Requirement(this.schema, requirementJSON),
                    );
                }
            }
            const workflowsJSONs = timesheetResult["ProcessWorkflows"];
            if (typeof workflowsJSONs !== "undefined") {
                for (let i = 0; i < workflowsJSONs.length; i++) {
                    const workflowJSON = workflowsJSONs[i];
                    workflowJSON["userOid"] = userOid;

                    this.writeTimesheetWorkflowToDB(new Workflow(this.schema, workflowJSON));
                }
            }

            return checksum;
        }

        return -1;
    }

    public writeTimesheetToDB(timesheet: TimesheetEntry): Promise<TimesheetEntry> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([TimesheetPool.TIMESHEET_STORE_NAME])
                .updateElements(TimesheetPool.TIMESHEET_STORE_NAME, [timesheet.toValueObject()])
                .then(resolve, reject);
        });
    }

    public writeTimesheetEventToDB(timesheet: TimesheetEntry): Promise<TimesheetEntry> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([TimesheetPool.TIMESHEET_APPOINTMENTS_STORE_NAME])
                .updateElements(TimesheetPool.TIMESHEET_APPOINTMENTS_STORE_NAME, [
                    timesheet.toValueObject(),
                ])
                .then(resolve, reject);
        });
    }

    public deleteTimesheetAllEventsInDBForUser(userOid: string): Promise<TimesheetEntry> {
        const minRecordDate = "1970-01-01",
            maxRecordDate = "2199-12-31";
        const query = IndexedDBQuery.greaterOrEqualAndLesserOrEqual(
            [userOid, minRecordDate],
            [userOid, maxRecordDate],
        );

        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([TimesheetPool.TIMESHEET_APPOINTMENTS_STORE_NAME])
                .deleteQuery(
                    TimesheetPool.TIMESHEET_APPOINTMENTS_STORE_NAME,
                    TimesheetPool.INDEX_USER_RECORD,
                    query,
                )
                .then(
                    (result) => {
                        AppConsole.debug(
                            "[TimesheetPool] delete all appointments",
                            result,
                            userOid,
                        );
                        resolve(null);
                    },
                    (error) => {
                        Log.error("[TimesheetPool] Error while clean appointments:", error, error);
                        reject(error);
                    },
                );
        });
    }

    public writeTimesheetTicketToDB(timesheet: TimesheetEntry): Promise<TimesheetEntry> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([TimesheetPool.TIMESHEET_TICKETS_STORE_NAME])
                .updateElements(TimesheetPool.TIMESHEET_TICKETS_STORE_NAME, [
                    timesheet.toValueObject(),
                ])
                .then(resolve, reject);
        });
    }

    public writeTimesheetRequirementToDB(timesheet: TimesheetEntry): Promise<TimesheetEntry> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([TimesheetPool.TIMESHEET_REQUIREMENTS_STORE_NAME])
                .updateElements(TimesheetPool.TIMESHEET_REQUIREMENTS_STORE_NAME, [
                    timesheet.toValueObject(),
                ])
                .then(resolve, reject);
        });
    }

    public writeTimesheetWorkflowToDB(timesheet: TimesheetEntry): Promise<TimesheetEntry> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([TimesheetPool.TIMESHEET_WORKFLOWS_STORE_NAME])
                .updateElements(TimesheetPool.TIMESHEET_WORKFLOWS_STORE_NAME, [
                    timesheet.toValueObject(),
                ])
                .then(resolve, reject);
        });
    }

    public readAllTimesheetEventFromDB(
        userOid: string,
        minRecordDate = "1970-01-01",
        maxRecordDate = "2199-12-31",
    ): Promise<Appointment[]> {
        const query = IndexedDBQuery.greaterOrEqualAndLesserOrEqual(
            [userOid, minRecordDate, minRecordDate],
            [userOid, maxRecordDate, maxRecordDate],
        );

        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([TimesheetPool.TIMESHEET_APPOINTMENTS_STORE_NAME])
                .selectCursor(
                    TimesheetPool.TIMESHEET_APPOINTMENTS_STORE_NAME,
                    TimesheetPool.INDEX_USER_RECORD,
                    query,
                )
                .then(
                    (result) => {
                        const booking: TimesheetEntry[] = [];
                        if (result && result.resultSet) {
                            resolve(self.wrapAppointmentsIntoDomainObjects(result.resultSet));
                        } else {
                            resolve([]);
                        }
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    public readAllTimesheetTasksFromDB(userOid: string, checksum: number): Promise<Task[]> {
        const query = IndexedDBQuery.only([userOid]);
        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([TimesheetPool.TIMESHEET_STORE_NAME])
                .selectCursor(
                    TimesheetPool.TIMESHEET_STORE_NAME,
                    TimesheetPool.INDEX_USER_RECORD,
                    query,
                )
                .then(
                    (result) => {
                        if (result && result.resultSet) {
                            resolve(self.wrapIntoTasks(result.resultSet, checksum));
                        } else {
                            resolve([]);
                        }
                    },
                    (error) => {
                        AppConsole.log(error);
                        reject(error);
                    },
                );
        });
    }

    public readTimesheetTask(userOid: string, taskOid: string): Promise<Task> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([TimesheetPool.TIMESHEET_STORE_NAME])
                .selectId(TimesheetPool.TIMESHEET_STORE_NAME, taskOid)
                .then(
                    (result) => {
                        if (result && result.element) {
                            resolve(self.wrapIntoTask(result.element));
                        } else {
                            resolve(null);
                        }
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    public updateRemainingEffortOnTimesheetTask(
        userOid: string,
        taskOid: string,
        remainingDuration: number,
    ): Promise<Task> {
        const self = this;

        return new Promise((resolve, reject) => {
            this.readTimesheetTask(userOid, taskOid).then((updatableTimesheet) => {
                updatableTimesheet.setRemainingEffort(remainingDuration);
                this.writeTimesheetToDB(updatableTimesheet)
                    .then(() => {
                        resolve(updatableTimesheet);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        });
    }

    public updateRemainingEffortWithDiff(
        userOid: string,
        taskOid: string,
        remainingDuration: number,
    ): Promise<Task> {
        const self = this;

        return new Promise((resolve, reject) => {
            this.readTimesheetTask(userOid, taskOid).then((updatableTimesheet) => {
                updatableTimesheet.reduceRemainingEffort(remainingDuration);
                this.writeTimesheetToDB(updatableTimesheet)
                    .then(() => {
                        resolve(updatableTimesheet);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        });
    }

    public readTimesheetAppointment(userOid: string, eventOid: string): Promise<Appointment> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([TimesheetPool.TIMESHEET_APPOINTMENTS_STORE_NAME])
                .selectId(TimesheetPool.TIMESHEET_APPOINTMENTS_STORE_NAME, eventOid)
                .then(
                    (result) => {
                        if (result && result.element) {
                            resolve(self.wrapAppointmentIntoDomainObject(result.element));
                        } else {
                            resolve(null);
                        }
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    public readTimesheetTicket(userOid: string, ticketOid: string): Promise<Ticket> {
        const query = IndexedDBQuery.only([userOid]);
        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([TimesheetPool.TIMESHEET_TICKETS_STORE_NAME])
                // .selectCursor(TimesheetPool.TIMESHEET_TICKETS_STORE_NAME, TimesheetPool.INDEX_USER_RECORD, query)
                .selectId(TimesheetPool.TIMESHEET_TICKETS_STORE_NAME, ticketOid)
                .then(
                    (result) => {
                        if (result && result.element) {
                            resolve(self.wrapIntoTicket(result.element));
                        } else {
                            resolve(null);
                        }
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    public readTimesheetRequirement(userOid: string, ticketOid: string): Promise<Requirement> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([TimesheetPool.TIMESHEET_REQUIREMENTS_STORE_NAME])
                .selectId(TimesheetPool.TIMESHEET_REQUIREMENTS_STORE_NAME, ticketOid)
                .then(
                    (result) => {
                        if (result && result.element) {
                            resolve(self.wrapIntoRequirement(result.element));
                        } else {
                            resolve(null);
                        }
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    public readTimesheetWorkflow(userOid: string, workflowOid: string): Promise<Workflow> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([TimesheetPool.TIMESHEET_WORKFLOWS_STORE_NAME])
                .selectId(TimesheetPool.TIMESHEET_WORKFLOWS_STORE_NAME, workflowOid)
                .then(
                    (result) => {
                        if (result && result.element) {
                            resolve(self.wrapIntoWorkflow(result.element));
                        } else {
                            resolve(null);
                        }
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    public readAllTimesheetTicketsFromDB(userOid: string): Promise<Ticket[]> {
        const query = IndexedDBQuery.only([userOid]);

        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([TimesheetPool.TIMESHEET_TICKETS_STORE_NAME])
                .selectCursor(
                    TimesheetPool.TIMESHEET_TICKETS_STORE_NAME,
                    TimesheetPool.INDEX_USER_RECORD,
                    query,
                )
                .then(
                    (result) => {
                        const booking: Ticket[] = [];
                        if (result && result.resultSet) {
                            resolve(self.wrapIntoTickets(result.resultSet));
                        } else {
                            resolve([]);
                        }
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    public readAllTimesheetRequirementsFromDB(userOid: string): Promise<Requirement[]> {
        const query = IndexedDBQuery.only([userOid]);

        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([TimesheetPool.TIMESHEET_REQUIREMENTS_STORE_NAME])
                .selectCursor(
                    TimesheetPool.TIMESHEET_REQUIREMENTS_STORE_NAME,
                    TimesheetPool.INDEX_USER_RECORD,
                    query,
                )
                .then(
                    (result) => {
                        const booking: Requirement[] = [];
                        if (result && result.resultSet) {
                            resolve(self.wrapIntoRequirements(result.resultSet));
                        } else {
                            resolve([]);
                        }
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    public readAllTimesheetWorkflowsFromDB(userOid: string): Promise<Workflow[]> {
        const query = IndexedDBQuery.only([userOid]);

        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([TimesheetPool.TIMESHEET_WORKFLOWS_STORE_NAME])
                .selectCursor(
                    TimesheetPool.TIMESHEET_WORKFLOWS_STORE_NAME,
                    TimesheetPool.INDEX_USER_RECORD,
                    query,
                )
                .then(
                    (result) => {
                        const booking: Workflow[] = [];
                        if (result && result.resultSet) {
                            resolve(self.wrapIntoWorkflows(result.resultSet));
                        } else {
                            resolve([]);
                        }
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    public getTaskById(id: string, checksum: number): Task {
        return new Task(this.schema, this.tasksById[id], checksum);
    }

    public getAppointmentById(id: string): Appointment {
        return new Appointment(this.schema, this.appointmentsById[id]);
    }

    public getTimesheetData(): object {
        return this.timesheetData;
    }

    updateBookedDurationOnTimesheetTask(
        currentUserOid: string,
        effortTargetId: string,
        bookedDuration: number,
    ): Promise<Task> {
        const self = this;

        return new Promise((resolve, reject) => {
            this.readTimesheetTask(currentUserOid, effortTargetId).then((updatableTimesheet) => {
                updatableTimesheet.setBookedDuration(bookedDuration);
                this.writeTimesheetToDB(updatableTimesheet)
                    .then(() => {
                        resolve(updatableTimesheet);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        });
    }

    updateBookedDurationWithDiff(
        currentUserOid: string,
        effortTargetId: string,
        diffinMin: number,
    ): Promise<Task> {
        const self = this;

        return new Promise((resolve, reject) => {
            this.readTimesheetTask(currentUserOid, effortTargetId).then((updatableTimesheet) => {
                updatableTimesheet.reduceBookedDuration(diffinMin);
                this.writeTimesheetToDB(updatableTimesheet)
                    .then(() => {
                        resolve(updatableTimesheet);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        });
    }

    private wrapAppointmentsIntoDomainObjects(
        timerecordValueObjects: object[],
        checksum?: number,
    ): Appointment[] {
        const appointment: Appointment[] = [];

        for (let i = 0; i < timerecordValueObjects.length; i++) {
            const currentTimesheetEntry = timerecordValueObjects[i];
            // Fügen nur Entries mit aktueller Checksumme hinzu.
            if (this.isOutdatedChecksum(checksum, currentTimesheetEntry["checksum"])) {
                continue;
            }
            appointment.push(this.wrapAppointmentIntoDomainObject(currentTimesheetEntry));
        }

        return appointment;
    }

    private wrapIntoTasks(timerecordValueObjects: object[], checksum?: number): Task[] {
        const task: Task[] = [];
        for (let i = 0; i < timerecordValueObjects.length; i++) {
            const currentTimesheetEntry = timerecordValueObjects[i];
            // Fügen nur Entries mit aktueller Checksumme hinzu.
            if (this.isOutdatedChecksum(checksum, currentTimesheetEntry["checksum"])) {
                continue;
            }
            task.push(this.wrapIntoTask(currentTimesheetEntry));
        }

        return task;
    }

    private wrapIntoTickets(timerecordValueObjects: object[], checksum?: number): Ticket[] {
        const appointment: Ticket[] = [];

        for (let i = 0; i < timerecordValueObjects.length; i++) {
            const currentTimesheetEntry = timerecordValueObjects[i];
            // Fügen nur Entries mit aktueller Checksumme hinzu.
            if (this.isOutdatedChecksum(checksum, currentTimesheetEntry["checksum"])) {
                continue;
            }
            appointment.push(this.wrapIntoTicket(currentTimesheetEntry));
        }

        return appointment;
    }

    private wrapIntoRequirements(
        timerecordValueObjects: object[],
        checksum?: number,
    ): Requirement[] {
        const requirements: Requirement[] = [];

        for (let i = 0; i < timerecordValueObjects.length; i++) {
            const currentTimesheetEntry = timerecordValueObjects[i];
            // Fügen nur Entries mit aktueller Checksumme hinzu.
            if (this.isOutdatedChecksum(checksum, currentTimesheetEntry["checksum"])) {
                continue;
            }
            requirements.push(this.wrapIntoRequirement(currentTimesheetEntry));
        }

        return requirements;
    }

    private wrapIntoWorkflows(timerecordValueObjects: object[], checksum?: number): Workflow[] {
        const workflows: Workflow[] = [];

        for (let i = 0; i < timerecordValueObjects.length; i++) {
            const currentTimesheetEntry = timerecordValueObjects[i];
            // Fügen nur Entries mit aktueller Checksumme hinzu.
            if (this.isOutdatedChecksum(checksum, currentTimesheetEntry["checksum"])) {
                continue;
            }
            workflows.push(this.wrapIntoWorkflow(currentTimesheetEntry));
        }

        return workflows;
    }

    /**
     * Wenn es eine aktuelle Checksumme gibt, dann wollen wir nur TimesheetEntries, die diese Checksumme haben.
     * Sonst bekommen wir evtl. Aufgaben, die garnicht mehr gefunden werden sollen z.B. geschlossene Aufgaben.
     * Alle Aufgaben die als letztes vom Server kamen, enthalten die aktuelle Checksumme.
     * Wir behalten trotzdem auch alte Timesheets, damit wenn eine Aufgabe geschlossen wurde,
     * im Tagesbuchen nicht direkt alle Informationen über die Aufgabe verloren gehen.(wie zum Beispiel der Anzeigename).
     *
     * Achtung, wir tun dies nur für Listen von z.B. Aufgaben. nicht aber, wenn wir direkt eine Aufgabe holen.
     * Da wir in diesem Fall wahrscheinlich nur die Informationen holen wollen.
     */
    private isOutdatedChecksum(currentChecksum, entryChecksum): boolean {
        return typeof currentChecksum !== "undefined" && entryChecksum !== currentChecksum;
    }

    private wrapIntoTicket(timepanValueObject: object): Ticket {
        return new Ticket(this.schema, timepanValueObject);
    }

    private wrapIntoRequirement(requirementValueObject: object): Requirement {
        return new Requirement(this.schema, requirementValueObject);
    }

    private wrapIntoWorkflow(workflowValueObject: object): Workflow {
        return new Workflow(this.schema, workflowValueObject);
    }

    private wrapIntoTask(timepanValueObject: object): Task {
        return new Task(this.schema, timepanValueObject);
    }

    private wrapAppointmentIntoDomainObject(timepanValueObject: object): Appointment {
        return new Appointment(this.schema, timepanValueObject);
    }
}
