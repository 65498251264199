export class PieChartEntry {
    private name: string = "";
    private value: number;
    private color: string;

    /**
     *
     * @param value  -  Welchen wert hat der Balken.
     * @param color:string - Farbe die der Balken haben soll.
     * @param entryName? - Label der an dem Balken steht.
     */
    constructor(value: number, color: string, entryName?: string) {
        this.value = value;
        this.color = color;
        if (typeof entryName !== "undefined") {
            this.name = entryName;
        }
    }

    public toObject(): object {
        return { name: this.name, value: this.value, color: this.color };
    }
}
