import { GUIElement } from "../GUIElement";
import { FormField, formFieldChangeEventType, FormFieldMode } from "./FormField";
import { StringField } from "./StringField";
import { StringValue } from "../../entities/values/StringValue";
import { DurationValue } from "../../entities/values/DurationValue";
import { FormFieldAddOn } from "./FormFieldAddOn";
import { BCSDate } from "../../common/BCSDate";
import { I18n } from "../../common/i18n/I18n";

export class DurationField implements GUIElement, FormField {
    private i18n: I18n;

    private stringField: StringField;

    private changeCallback: (changeEvent: formFieldChangeEventType) => void;

    constructor(i18n: I18n) {
        this.i18n = i18n;
        this.stringField = new StringField()
            .setType("time")
            .onChange(this.stringValueChanged.bind(this));
    }

    public setEntityId(entityId: string): FormField {
        this.stringField.setEntityId(entityId);
        return this;
    }

    public setName(name: string): DurationField {
        this.stringField.setName(name);
        return this;
    }

    public setReadOnly(readOnly: boolean): DurationField {
        this.stringField.setReadOnly(readOnly);
        return this;
    }

    public isReadOnly(): boolean {
        return this.stringField.isReadOnly();
    }

    public getName(): string {
        return this.stringField.getName();
    }

    public setValue(value: DurationValue): DurationField {
        const stringValue = new StringValue(this.convertToValidTimePattern(value.format()));
        this.stringField.setValue(stringValue);
        return this;
    }

    /**
     * TODO Jens, sollte eigentlich DurationValue richtig tun :-) + Test
     *
     * @param rawValue
     */
    private convertToValidTimePattern(rawValue: string): string {
        const isNumber: boolean = rawValue.indexOf(":") === -1;
        let result: string = "";
        if (isNumber) {
            const intValue = parseInt(rawValue);
            result = BCSDate.minnutesToDisplayTime(intValue);
        } else {
            // TODO Jens evtl. über BCSDate lösen
            const stringValue = rawValue.split(":");
            if (stringValue[0].length == 1) {
                rawValue = "0" + rawValue;
            }
            result = rawValue;
        }
        return result;
    }

    public setLabel(label: string): DurationField {
        this.stringField.setLabel(label);
        return this;
    }

    public setPlaceholder(placeholder: string): DurationField {
        this.stringField.setPlaceholder(placeholder);
        return this;
    }

    public setMode(mode: FormFieldMode): FormField {
        this.stringField.setMode(mode);
        return this;
    }

    public getMode(): FormFieldMode {
        return this.stringField.getMode();
    }

    public registerFormFieldAddOn(formFieldAddOn: FormFieldAddOn): FormField {
        // untetstützt bislang bislang keine AddOns
        return this;
    }

    public onChange(
        changeCallback: (changeEvent: formFieldChangeEventType) => void,
    ): DurationField {
        this.changeCallback = changeCallback;
        return this;
    }

    public compose($parent: JQuery): void {
        const $container = $("<span>").appendTo($parent).addClass("durationField");
        this.stringField.compose($container);

        if (this.getMode() === FormFieldMode.DISPLAY) {
            this.stringField.appendComposeDisplayText("h");
        }
    }

    public updateValue(value: DurationValue): DurationField {
        const stringValue = new StringValue(this.convertToValidTimePattern(value.format()));
        this.stringField.updateValue(stringValue);
        return this;
    }

    public getValue(): DurationValue {
        return DurationValue.parseFromString(this.stringField.getValue().getString());
    }

    private stringValueChanged(changeEvent: formFieldChangeEventType): void {
        if (this.changeCallback) {
            this.changeCallback({
                entityId: changeEvent.entityId,
                name: this.getName(),
                value: this.getValue(),
            });
        }
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}
