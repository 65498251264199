import { AppConsole } from "../common/log/AppConsole";
import { Browser } from "../util/common/Browser";
import { IndexedDB } from "./IndexedDB";
import { IndexedDBVersion } from "./IndexedDBVersion";

export class IndexedDBStore {
    private idKey: string;

    private indexesOperations: DBIndex[];

    private storeName: string;

    private dbVersion: number;

    private indexDB: IndexedDB;

    constructor(storeName, indexDB: IndexedDB, indexes?) {
        this.storeName = storeName;
        this.idKey = "id";
        this.indexesOperations = indexes || [];
        this.indexDB = indexDB;
    }

    public getStoreName(): string {
        return this.storeName;
    }

    /**
     * Setzt Id-Key (Default "id").
     * @param idKey Id-Key
     */
    public setIdKey(idKey): IndexedDBStore {
        this.idKey = idKey;
        return this;
    }

    public setDBVersion(dbVersion: number): IndexedDBStore {
        this.dbVersion = dbVersion;
        this.adjustVersion(dbVersion);
        return this;
    }

    public getDBVersion(): number {
        let version = this.dbVersion;
        for (let i = 0; i < this.indexesOperations.length; i++) {
            const index = this.indexesOperations[i];
            if (version < index.version) {
                version = index.version;
            }
        }
        return version;
    }

    /**
     * Registriert für diesen Store.
     * @param indexName Index-Name
     * @param indexKeys Keys dieses Index (besser nur 1 Key, manche Browser unterstützen bislang nur 1 Key)
     * @param unique wenn true müssen die Objekte bzgl dieses Index eindeutig sein
     * @returns BCS.IndexedDBStore
     */
    public addIndex(
        indexName: string,
        indexKeys: string[],
        unique: boolean,
        dbVersion: number,
    ): IndexedDBStore {
        this.adjustVersion(dbVersion);
        this.indexesOperations.push({
            name: indexName,
            keys: indexKeys,
            unique: unique ? true : false,
            operation: "create",
            version: dbVersion,
        });
        return this;
    }

    public changeIndex(
        indexName: string,
        indexKeys: string[],
        unique: boolean,
        version: number,
    ): IndexedDBStore {
        this.adjustVersion(version);
        this.indexesOperations.push({
            name: indexName,
            keys: indexKeys,
            unique: unique ? true : false,
            operation: "change",
            version: version,
        });
        return this;
    }

    /**
     * Die Version bildet das Maximum der regisiteren Stores und deren einzelnen Operationen, wie addIndex.
     * Daher können diese Steps/Operationen auch ihre Version einreichen.
     * Verwendet wird die höchste, die im System gefunden wurde.
     *
     * Ist wichtig, damit die datenbank erkennt, dass ihre Version upgeraded werden muss.
     * Darauf hin werden die benötigsten UpdateSteps getriggert.
     *
     * @param version Datenbankversion der neuen Operation.
     */
    public adjustVersion(version: number) {
        this.indexDB.setMaxVersionFromChildrenSteps(version);
    }

    public getIndexes(): DBIndex[] {
        return this.indexesOperations;
    }

    /**
     *
     * @param transaction Transaction
     * @returns true wenn Store existiert
     */
    storeExists(transaction: IDBTransaction): boolean {
        return transaction.objectStoreNames.contains(this.storeName);
    }

    /**
     * Wird bim Starten von der IndexedDB aufgerufen, wenn neue Stores/Indexed hinzugefügt werden sollen.
     * @param db
     * @param transaction
     * @param updateToVersion Version auf die die DB in diesem Aufruf upgedated werden soll (kann ein Zwischenschritt sein, z.B. von 1 auf 5 im 3ten Schritt)
     */
    createStore(db: IDBDatabase, transaction: IDBTransaction, updateToVersion: number) {
        try {
            const store = db.createObjectStore(this.storeName, { keyPath: this.idKey });

            for (let i = 0; i < this.indexesOperations.length; i++) {
                const index = this.indexesOperations[i];

                if (index.version === updateToVersion) {
                    let indexKeys = null;
                    if (Browser.isInternetExplorer()) {
                        indexKeys = index.keys.join("_");
                    } else {
                        indexKeys = index.keys;
                    }

                    if (index.operation === "create") {
                        store.createIndex(index.name, indexKeys, { unique: index.unique });

                        AppConsole.log(
                            "+++++++++++++++++++ INDEXED DB STORE",
                            index.name,
                            indexKeys,
                        );
                        //BCS.Log.debug("[IndexedDBStore] create index: db=" + db.name + ", store=" + this.storeName + ", index=" + index.name);
                    }
                }
            }
        } catch (err) {
            AppConsole.log(err);
        }
    }

    /**
     * Wird bim Starten von der IndexedDB aufgerufen, wenn neue Stores/Indexed hinzugefügt werden sollen.
     * @param db
     * @param transaction
     * @param updateToVersion Version auf die die DB in diesem Aufruf upgedated werden soll (kann ein Zwischenschritt sein, z.B. von 1 auf 5 im 3ten Schritt)
     */
    upgradeStore(db: IDBDatabase, transaction: IDBTransaction, updateToVersion: number) {
        for (let i = 0; i < this.indexesOperations.length; i++) {
            const index = this.indexesOperations[i];

            if (index.version === updateToVersion) {
                const store = transaction.objectStore(this.getStoreName());

                let indexKeys = null;
                if (Browser.isInternetExplorer()) {
                    indexKeys = index.keys.join("_");
                } else {
                    indexKeys = index.keys;
                }

                if (index.operation === "create") {
                    store.createIndex(index.name, indexKeys, { unique: index.unique });

                    AppConsole.log("+++++++++++++++++++ INDEXED DB STORE", index.name, indexKeys);
                    //BCS.Log.debug("[IndexedDBStore] create index: db=" + db.name + ", store=" + this.storeName + ", index=" + index.name);
                } else if (index.operation === "change") {
                    AppConsole.log("+++++++++++++++++++ INDEXED DB STORE CHANGE", index);
                    store.deleteIndex(index.name);
                    store.createIndex(index.name, indexKeys, { unique: index.unique });
                } else if (index.operation === "delete") {
                    AppConsole.log("+++++++++++++++++++ INDEXED DB STORE DELETE", index);
                    store.deleteIndex(index.name);
                }
            }
        }
    }
}

class DBIndex {
    name: string;
    keys: string[];
    unique: boolean;
    operation: "create" | "change" | "delete";
    version: number;
}
