export class AllowancePSP {
    private values: { [key: string]: string } = {};

    private searchValues: string[] = [];

    private defaultChargeabilityBySubtype: { [key: string]: string } = {};

    // "taskOid": "1106053040111_JTask",
    // "taskName": "[00-00] Projektmanagement",
    // "parentName": "[00] Projektmanagement",
    // "grandParentName": "GPS-Demonstrator",
    // "defaultChargeability_travelDurationAllowance": "not_chargeable",
    // "defaultChargeability_dailyAllowance": "not_chargeable",
    // "defaultChargeability_kilometreAllowance": "not_chargeable"
    // "defaultChargeability_accomodationAllowance": "not_chargeable",
    // "defaultChargeability_voucherAllowance": "not_chargeable",

    constructor(allowancePSPListValueObject: object) {
        this.values = JSON.parse(JSON.stringify(allowancePSPListValueObject));

        for (const name in this.values) {
            this.searchValues.push(this.values[name].toLowerCase());
        }

        for (const name in allowancePSPListValueObject) {
            if (name.substr(0, "defaultChargeability_".length) == "defaultChargeability_") {
                const allowanceSubtype = name.substr("defaultChargeability_".length);
                this.defaultChargeabilityBySubtype[allowanceSubtype] =
                    allowancePSPListValueObject[name];
            }
        }
    }

    public get(name: string): string {
        return this.values[name];
    }

    public getTaskOid(): string {
        return this.values["taskOid"];
    }

    public getTaskName(): string {
        return this.values["taskName"];
    }

    public matches(searchInput: string): boolean {
        const searchTokens = searchInput
            .trim()
            .toLowerCase()
            .split(" ")
            .filter((searchToken) => searchToken.length > 0);
        return this.allSearchTokenMatches(searchTokens);
    }

    private allSearchTokenMatches(searchTokens: string[]): boolean {
        return searchTokens.reduce(
            (isMatch: boolean, searchToken) =>
                isMatch && this.anyValueMatchesSearchToken(searchToken),
            true,
        );
    }

    private anyValueMatchesSearchToken(searchToken: string): boolean {
        return this.searchValues.reduce(
            (isMatch: boolean, searchValue) =>
                isMatch || this.valueMatchesSearchToken(searchValue, searchToken),
            false,
        );
    }

    private valueMatchesSearchToken(searchValue: string, searchToken: string): boolean {
        const searchRegExp = new RegExp(searchToken.split("").join(".*"));
        return searchRegExp.test(searchValue);
    }

    public getDefaultChargeability(allowanceSubtype: string): string {
        return this.defaultChargeabilityBySubtype[allowanceSubtype];
    }
}
