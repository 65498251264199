export const enum AllowanceState {
    CREATED = "Created",

    REQUESTED = "Requested",

    AUTHORIZED = "Authorized",

    RELEASED = "Released",

    REVIWED = "Reviewed",

    CLEARED = "Cleared",

    BOOKED = "Booked",
}
