import { BCSWeekday } from "../../../../common/BCSDate";
import { DateValue } from "../../../../entities/values/DateValue";
import { GUIElement } from "../../../../gui/GUIElement";
import { I18n } from "../../../../common/i18n/I18n";
import { TravelDaysViewModel } from "./TravelDaysViewModel";
import "./TravelDaysView.less";

export class TravelDaysView implements GUIElement {
    private i18n: I18n;

    /**
     * Kapselt Zugriff auf Mahlzeiten je Kalendertag des aktuellen Reiseabschnitts für die Bearbeitung.
     *
     * Merkt sich für alle Kalendertag der gesamten Dienstreise, welche Mahlzeiten angegeben wurden.
     */
    private travelDaysViewModel: TravelDaysViewModel;

    /** Callbacks, um geänderte Werte zu speichern */
    private changeCallback: Function;

    private $selectAllInputs: { [key: string]: JQuery } = {};

    private isSelectAllInputInternediate: { [key: string]: boolean } = {};

    private $inputs: { [key: string]: JQuery[] } = {};

    public setI18n(i18n: I18n): TravelDaysView {
        this.i18n = i18n;
        return this;
    }

    public setTravelDaysViewModel(travelDaysViewModel: TravelDaysViewModel): TravelDaysView {
        this.travelDaysViewModel = travelDaysViewModel;
        return this;
    }

    public onValueChanged(changeCallback: Function): TravelDaysView {
        this.changeCallback = changeCallback;
        return this;
    }

    public compose($parent: JQuery, parentElement?: GUIElement): void {
        const $travelDaysContainer = $("<div>").appendTo($parent).addClass("travelDaysView");

        this.composeTravelDaysTable($travelDaysContainer);
    }

    private composeTravelDaysTable($travelDaysContainer: JQuery): void {
        const $travelDaysTable = $("<table>")
            .appendTo($travelDaysContainer)
            .addClass("daysMealsMatrix");

        this.composeTravelDaysHeaderRow($travelDaysTable);

        if (this.travelDaysViewModel.countTravelDates() > 2) {
            this.composeSelectAllRow($travelDaysTable);
        }

        for (
            let travelDayNo = 0;
            travelDayNo < this.travelDaysViewModel.countTravelDates();
            travelDayNo++
        ) {
            this.composeTravelDayRow(travelDayNo, $travelDaysTable);
        }
    }

    private composeTravelDaysHeaderRow($travelDaysTable: JQuery): void {
        const $travelDayHeaderRow = $("<tr>").appendTo($travelDaysTable).addClass("header");

        $("<td>").appendTo($travelDayHeaderRow).attr("colspan", "2").css("width", "10%");

        const mealNamesAndValues = this.travelDaysViewModel.getTravelDay(0).getMealNamesAndValues();
        const columnWidth = Math.floor(90 / mealNamesAndValues.length) + "px";

        mealNamesAndValues.forEach((nameAndValue) => {
            $("<td>")
                .appendTo($travelDayHeaderRow)
                .css("width", columnWidth)
                .text(this.i18n.get("MobileApp.meals." + nameAndValue.name));

            this.$inputs[nameAndValue.name] = [];
        });
    }

    private composeSelectAllRow($travelDaysTable: JQuery): void {
        const $selectAllRow = $("<tr>").appendTo($travelDaysTable).addClass("selectall");

        $("<td>")
            .appendTo($selectAllRow)
            .attr("colspan", "2")
            .text(this.i18n.get("MobileApp.selectAllFlagRows"));

        // Sofern Dienstreise nicht bearbeitbar wird sie in diesem Reiseabschnitt disabled.
        const isAllDaysAndMealsDisabled = !this.travelDaysViewModel.isEditable();

        this.travelDaysViewModel
            .getTravelDay(0)
            .getMealNamesAndValues()
            .forEach((nameAndValue) => {
                const name = nameAndValue.name;
                const isAllTravelDaySelected =
                    this.travelDaysViewModel.isAllTravelDaySelected(name);

                const $selectAllCell = $("<td>").appendTo($selectAllRow).addClass("checkbox");

                this.$selectAllInputs[name] = $("<input>")
                    .appendTo($selectAllCell)
                    .attr({
                        id: "mealsSelectAll_" + name,
                        type: "checkbox",
                        name: "selectall_" + name,
                        checked: isAllTravelDaySelected ? "checked" : null,
                        disabled: isAllDaysAndMealsDisabled ? "disabled" : null,
                    })
                    .on(
                        "click",
                        ((name) => {
                            return (event) => this.allTravelDayRowsSelected(name);
                        })(name),
                    );
            });
    }

    private composeTravelDayRow(travelDayNo: number, $travelDaysTable: JQuery): void {
        const $travelDayRow = $("<tr>").appendTo($travelDaysTable);

        const travelDay = this.travelDaysViewModel.getTravelDay(travelDayNo);

        const travelDate = travelDay.getTravelDate();
        const weekdayName = travelDate.format("dd", this.i18n.getLocale("-"));
        const formattedDate = travelDate.formatShortDate(this.i18n.getLocale("-"));
        const sundayClass =
            travelDate.getBCSDate().getWeekday(this.i18n.getLocale("-")) == BCSWeekday.SUNDAY
                ? " sunday"
                : "";

        // Reisetag-Wochentag
        $("<td>")
            .appendTo($travelDayRow)
            .addClass("datename" + sundayClass)
            .text(weekdayName);

        // Reisetag-Datum
        $("<td>")
            .appendTo($travelDayRow)
            .addClass("date" + sundayClass)
            .text(formattedDate);

        travelDay.getMealNamesAndValues().forEach((mealNameAndValue) => {
            this.composeTravelDayMealCell(
                travelDayNo,
                travelDate,
                mealNameAndValue.name,
                mealNameAndValue.value,
                $travelDayRow,
            );
        });
    }

    private composeTravelDayMealCell(
        travelDayNo: number,
        travelDate: DateValue,
        mealName: string,
        value: boolean,
        $travelDayRow: JQuery,
    ): void {
        // Sofern Dienstreise nicht bearbeitbar ODER
        // für den selben Kalendertag die selbe Mahlzeit in einem anderen Reiseabschnitt bereits angegeben wurde,
        // wird sie in diesem Reiseabschnitt disabled.
        const isDayAndMealDisabled =
            !this.travelDaysViewModel.isEditable() ||
            this.travelDaysViewModel.isDayAndMealDisabled(travelDate, mealName);

        const $travelDayCell = $("<td>").appendTo($travelDayRow).addClass("checkbox");

        const self = this;
        const $input = $("<input>")
            .appendTo($travelDayCell)
            .attr({
                id: "mealsSelect_" + travelDate.getISODate() + "_" + mealName,
                type: "checkbox",
                name: mealName,
                checked: value ? "checked" : null,
                disabled: isDayAndMealDisabled ? "disabled" : null,
            })
            .on(
                "click",
                ((travelDayNo, name) => {
                    return (event) =>
                        self.travelDayValueChanged(
                            travelDayNo,
                            name,
                            (event.currentTarget as any).checked,
                        );
                })(travelDayNo, mealName),
            );
        this.$inputs[mealName].push($input);
    }

    /**
     * Aufruf als Callback nachdem eine Alle-Auswählen-Checkbox für eine Spalte aktiviert bzw. deaktiviert wurde.
     *
     * @param name Spalten-Name (z.B. "hasBreakfast")
     */
    private allTravelDayRowsSelected(name: string): void {
        // Für alle Checkboxen der Spalte aktiv bzw. deaktiv neuen Wert setzen:
        // Sofern nicht alle Checkboxen aktiviert und nicht im Zwischenzustand (nach Deaktivieren der ersten Checkbox),
        // werden alle Checkboxen aktiviert, ansosnten deaktiviert.
        const selectAllValue =
            !this.travelDaysViewModel.isAllTravelDaySelected(name) &&
            !this.isSelectAllInputInternediate[name];

        // Setzt den neuen Wert an allen Reisetagen für die Spalte
        this.travelDaysViewModel.selectAllTravelDays(name, selectAllValue);

        // Für alle Checkbox neuen Wert setzen
        this.$inputs[name]
            .filter(($input) => !$input.is(":disabled"))
            .forEach(($input) => $input.prop("checked", selectAllValue));

        // Alle-Auswählen-Checkbox nicht mehr auf Zwischenzustand ("-")
        this.isSelectAllInputInternediate[name] = false;

        // Aufruf des registrierten Callbacks, um geänderte Werte zu speichern
        if (this.changeCallback) {
            this.changeCallback();
        }
    }

    /**
     * Aufruf als Callback nachdem eine Checkbox für einen Reisetag für eine Spalte aktiviert bzw. deaktiviert wurde.
     *
     * @param travelDayNo Zeilenummer
     * @param name Spalten-Name (z.B. "hasBreakfast")
     * @param valueNeuer  Wert der Checkbox
     */
    private travelDayValueChanged(travelDayNo: number, name: string, value: boolean): void {
        // Setzt den neuen Wert am Reisetag für die Spalte
        this.travelDaysViewModel.getTravelDay(travelDayNo).setValue(name, value);

        // Anpassen des Zustandes der Alle-Auswählen-Checkbox in der ersten Zeile (sofern vorhanden bei mehr als 2 Reisetagen)
        // nach Änderungen einer Checkbox
        if (this.$selectAllInputs[name]) {
            // Alle-Auswählen-Checkbox nicht mehr auf Zwischenzustand ("-")
            this.isSelectAllInputInternediate[name] = false;

            if (value) {
                // Eine Checkbox wurde aktiviert ...
                if (this.travelDaysViewModel.isAllTravelDaySelected(name)) {
                    // sind alle Checkboxen der Spalte aktiviert: Alle-Auswählen-Checkbox auch aktivieren
                    this.$selectAllInputs[name].prop("checked", true);
                }
            } else {
                // Eine Checkbox wurde deaktiviert ...
                if (this.travelDaysViewModel.isNoneTravelDaySelected(name)) {
                    // sind alle Checkboxen der Spalte deaktiviert: Alle-Auswählen-Checkbox auch deaktivieren
                    this.$selectAllInputs[name].prop("indeterminate", false);
                    this.$selectAllInputs[name].prop("checked", false);
                } else {
                    // Alle-Auswählen-Checkbox auf Zwischenzustand ("-") setzen
                    this.$selectAllInputs[name].prop("indeterminate", true);
                    this.isSelectAllInputInternediate[name] = true;
                }
            }
        }

        // Aufruf des registrierten Callbacks, um geänderte Werte zu speichern
        if (this.changeCallback) {
            this.changeCallback();
        }
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}
