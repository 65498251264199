export const enum ListFieldFeedbackType {
    SUCCESS = "success",
    FAILURE = "failure",
}

export type ListFieldFeedbackEntry = {
    type: ListFieldFeedbackType;
    entityId: string;
    fieldName: string;
};

export class ListFieldFeedback {
    private feedbackEntries: { [key: string]: ListFieldFeedbackEntry } = {};

    public addFeedback(
        type: ListFieldFeedbackType,
        entityId: string,
        fieldName: string,
    ): ListFieldFeedback {
        this.feedbackEntries[entityId + "_" + fieldName] = {
            type: type,
            entityId: entityId,
            fieldName: fieldName,
        };
        return this;
    }

    public getFeedback(entityId: string, fieldName: string): ListFieldFeedbackEntry {
        return this.feedbackEntries[entityId + "_" + fieldName];
    }
}
