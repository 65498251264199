import { GUIElement } from "../GUIElement";
import { FormField, formFieldChangeEventType, FormFieldMode } from "./FormField";
import { StringField } from "./StringField";
import { StringValue } from "../../entities/values/StringValue";
import { TimeValue } from "../../entities/values/TimeValue";
import { FormFieldAddOn } from "./FormFieldAddOn";

export class TimeField implements GUIElement, FormField {
    private stringField: StringField;

    /* Zustände: */
    /**
     *
     */
    private isUseingBlurInstedOfChangeEvent = false;

    private changeCallback: (changeEvent: formFieldChangeEventType) => void;

    constructor() {
        this.stringField = new StringField()
            .setType("time")
            .onChange(this.stringValueChanged.bind(this))
            .onBlur(this.stringValueChanged.bind(this));
    }

    public setEntityId(entityId: string): FormField {
        this.stringField.setEntityId(entityId);
        return this;
    }

    public setReadOnly(readOnly: boolean): TimeField {
        this.stringField.setReadOnly(readOnly);
        return this;
    }

    public isReadOnly(): boolean {
        return this.stringField.isReadOnly();
    }

    public setName(name: string): TimeField {
        this.stringField.setName(name);
        return this;
    }

    public getName(): string {
        return this.stringField.getName();
    }

    public setValue(value: TimeValue): TimeField {
        const stringValue = new StringValue(value.format());
        this.stringField.setValue(stringValue);
        return this;
    }

    public setLabel(label: string): TimeField {
        this.stringField.setLabel(label);
        return this;
    }

    public setPlaceholder(placeholder: string): TimeField {
        this.stringField.setPlaceholder(placeholder);
        return this;
    }

    public setMode(mode: FormFieldMode): FormField {
        this.stringField.setMode(mode);
        return this;
    }

    public getMode(): FormFieldMode {
        return this.stringField.getMode();
    }

    public registerFormFieldAddOn(formFieldAddOn: FormFieldAddOn): FormField {
        // untetstützt bislang bislang keine AddOns
        return this;
    }

    public onChange(changeCallback: (changeEvent: formFieldChangeEventType) => void): TimeField {
        this.changeCallback = changeCallback;
        return this;
    }

    public compose($parent: JQuery): void {
        this.stringField.compose($parent);
    }

    public updateValue(value: TimeValue): TimeField {
        if (value.isDefined()) {
            const stringValue = new StringValue(value.format());
            this.stringField.updateValue(stringValue);
        } else {
            this.stringField.clearValue();
        }
        return this;
    }

    public useBlurInstedOfChangeEvent(): void {
        this.isUseingBlurInstedOfChangeEvent = true;
    }

    public getValue(): TimeValue {
        const strValue = this.stringField.getValue().getString();
        return TimeValue.parseFromString(strValue);
    }

    private stringValueChanged(changeEvent: formFieldChangeEventType): void {
        if (this.changeCallback) {
            const value = this.getValue();
            this.changeCallback({
                entityId: changeEvent.entityId,
                name: this.getName(),
                value: value,
            });

            if (value.getTimeInMinutes() == 0) {
                // Nach Löschen des Zeit-Feldes wieder mit 00:00 befüllen
                this.updateValue(value);
            }
        }
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}
