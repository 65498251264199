import { FooterTabBar } from "../../../../board/FooterTabBar";
import { ServerConfigProperties } from "../../../../common/config/ServerConfigProperties";
import { I18n } from "../../../../common/i18n/I18n";
import { AppConsole } from "../../../../common/log/AppConsole";
import { Component } from "../../../../core/Component";
import { Controller } from "../../../../core/Controller";
import { Registry } from "../../../../core/Registry";
import { Animation, AppNavigator } from "../../../../core/Router";
import { formChangeEventType } from "../../../../gui/form/Form";
import { GUIContext } from "../../../../gui/GUIContext";
import { GUIPage } from "../../../../gui/GUIPage";
import { ListFieldFeedback, ListFieldFeedbackType } from "../../../../gui/list/ListFieldFeedback";
import { ListView } from "../../../../gui/list/ListView";
import { ListViewContext } from "../../../../gui/list/ListViewContext";
import { ToolBar } from "../../../../gui/navigation/ToolBar";
import { ToolLink } from "../../../../gui/navigation/ToolLink";
import { SyncStateManager } from "../../../../sync/SyncStateManager";
import { AllowanceManager } from "../../AllowanceManager";
import { AccomodationAllowance } from "../../records/AccomodationAllowance";
import { BusinessTravel } from "../../records/BusinessTravel";
import { AllowanceGUIDefinitions } from "../AllowanceGUIDefinitions";
import { AllowancesListViewModel } from "../AllowancesListViewModel";

export class AccomodationsController implements Controller {
    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static CONTROLLER_PATH = "travel/accomodations";

    public static PARAMETER_BUSINESS_TRAVEL_ID = "oid";

    public static PARAMETER_ACCOMODATION_ID = "accomodationAllowanceOid";

    private navigator: AppNavigator;

    private animation: Animation;

    private i18n: I18n;

    private allowanceManager: AllowanceManager;

    private syncStateManager: SyncStateManager;

    private serverConfigProperties: ServerConfigProperties;

    private businessTravel: BusinessTravel;

    private accomodationAllowance: AccomodationAllowance;

    private allowanceGUIDefinitions: AllowanceGUIDefinitions;

    private page: GUIPage;

    private accomodationAllowanceListView: ListView;

    private footerTabBar: FooterTabBar;

    private allowanceWasEdited = false;

    public getDependencyNames(): string[] {
        return [
            I18n.BCS_COMPONENT_NAME,
            AllowanceManager.BCS_COMPONENT_NAME,
            SyncStateManager.BCS_COMPONENT_NAME,
            ServerConfigProperties.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }) {
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.allowanceManager = <AllowanceManager>(
            depencencyComponents[AllowanceManager.BCS_COMPONENT_NAME]
        );
        this.syncStateManager = <SyncStateManager>(
            depencencyComponents[SyncStateManager.BCS_COMPONENT_NAME]
        );
        this.serverConfigProperties = <ServerConfigProperties>(
            depencencyComponents[ServerConfigProperties.BCS_COMPONENT_NAME]
        );

        this.allowanceGUIDefinitions = new AllowanceGUIDefinitions();
    }

    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        this.navigator = navigator;
        this.animation = animation;

        if (!this.allowanceManager.getAllowanceRecordingTerms().isAllowanceRecordingAvailable()) {
            this.navigator.navigateTo("index", {}, Animation.SLIDE_RIGHT);
            return;
        }

        const businessTravelId = parameters[AccomodationsController.PARAMETER_BUSINESS_TRAVEL_ID];
        const accomodationAllowanceId =
            parameters[AccomodationsController.PARAMETER_ACCOMODATION_ID];
        if (businessTravelId) {
            const self = this;

            this.allowanceManager
                .fetchAllowanceById(businessTravelId)
                .then((allowance) => {
                    self.businessTravel = <BusinessTravel>allowance;
                    self.accomodationAllowance = <AccomodationAllowance>(
                        self.businessTravel.getSubAllowanceById(accomodationAllowanceId)
                    );

                    if (!self.accomodationAllowance) {
                        self.navigateUpToTravel();
                        return;
                    }

                    self.composeAccomodations();
                })
                .catch((error) => {
                    AppConsole.log("ERROR", error);
                }); // TODO Fehler anzeigen
        } else {
            this.navigator.navigateTo("travels", {});
        }
    }

    private async composeAccomodations(): Promise<void> {
        this.page = new GUIPage(
            new GUIContext(this.i18n),
            AccomodationsController.CONTROLLER_PATH,
            this.accomodationAllowance.getId(),
        );

        const headerToolBar = new ToolBar().setId("header_toolbar");

        headerToolBar.addToolLinkLeft(
            new ToolLink()
                .setId("navigateBack")
                .setImageName("icon-chevron-left.svg")
                .onClick(this.popState.bind(this)),
        );
        headerToolBar.setTitle(this.i18n.get("MobileApp.accomodations.title"));
        headerToolBar.addStyleClass("headBar");

        this.page.addHeaderElement(headerToolBar);

        this.composeAccomodationAllowance();

        await this.composeFooterTabBar();

        this.page
            .setAnimation(this.animation, this.navigator.doShowAnimations())
            .compose($("body"));
    }

    private composeAccomodationAllowance(): void {
        const listViewContext = new ListViewContext()
            .setI18n(this.i18n)
            .setModel(new AllowancesListViewModel([this.accomodationAllowance], this.i18n));

        const accomodationListViewDef = this.serverConfigProperties.customizeGUIDefinitions(
            this.allowanceGUIDefinitions.getAccomodationListViewDef(),
            AccomodationsController.CONTROLLER_PATH,
            "accomodationallowance",
        );

        this.accomodationAllowanceListView = new ListView(listViewContext, accomodationListViewDef)
            .setDOMId("accomodations")
            .addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW)
            .onRowClicked(this.listRowClicked.bind(this))
            .onFormFieldChange(this.formFieldChanged.bind(this));

        this.page.addPageElement(this.accomodationAllowanceListView);
    }

    /**
     * Fußzeile (u.a. mit Home-Link)
     */
    private async composeFooterTabBar(): Promise<void> {
        const self = this;
        this.footerTabBar = new FooterTabBar(new GUIContext(this.i18n));
        await this.footerTabBar.composeDefaultFooter(
            null,
            this.navigator,
            this.getNavigateBackParameters(),
            () => self.syncStateManager.countUnsyncedElements(),
        );
        this.page.addFooterElement(this.footerTabBar);
    }

    private listRowClicked(path: string, parameters: { [key: string]: string }): void {
        this.navigator.navigateTo(path, parameters, Animation.SLIDE_RIGHT);
    }

    private async formFieldChanged(changeEvent: formChangeEventType): Promise<void> {
        this.updateFormFields(changeEvent);
        await this.allowanceManager.storeAllowance(this.businessTravel);

        // Nach erster Formularfeldänderung, Zähler der zu synchronisierenden Objekt in Footer-Navigation aktualisieren
        if (!this.allowanceWasEdited) {
            this.allowanceWasEdited = true;

            const self = this;
            await this.footerTabBar.updateSyncBadgeCounter(FooterTabBar.ICON_SYNC, () =>
                self.syncStateManager.countUnsyncedElements(),
            );
        }
    }

    private updateFormFields(changeEvent: formChangeEventType): void {
        // Alle Formularfelder aktualisieren
        const listFieldFeedback = new ListFieldFeedback();
        listFieldFeedback.addFeedback(
            ListFieldFeedbackType.SUCCESS,
            changeEvent.entityId,
            changeEvent.fieldName,
        );
        this.accomodationAllowanceListView.updateFields(listFieldFeedback);
    }

    private getNavigateBackParameters(): { [key: string]: string } {
        return {
            navigateBackPath: AccomodationsController.CONTROLLER_PATH,
            navigateBackParameters: JSON.stringify({
                oid: this.businessTravel.getId(),
                accomodationAllowanceOid: this.accomodationAllowance.getId(),
            }),
        };
    }

    public popState(): void {
        this.navigateUpToTravel();
    }

    private navigateUpToTravel(): void {
        this.navigator.navigateTo(
            "travel",
            { oid: this.businessTravel.getId() },
            Animation.SLIDE_RIGHT,
        );
    }

    public destroy(): void {
        // TODO ...
    }
}

Registry.registerComponent(AccomodationsController.CONTROLLER_PATH, AccomodationsController);
