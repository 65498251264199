import { IdGenerator } from "../../../util/text/IdGenerator";
import { ImageTransformation, ImageRotationType } from "../util/ImageTransformation";

/**
 * Repräsentiert eine Datei verknüpft mit einbem BCS-Objekt.
 *
 * Die Datei kann aktuell ein Bild (jpg/png) oder ein PDF sein.
 */
export class BCSFile {
    public static FILE_ID = "fileUid";

    public static INS_USER_OID = "insUserOid";

    public static FILE_COMPONENT_OID = "fileComponentOid";

    private static CONTENT_TYPE = "contentType";

    private static FILE_NAME = "filename";

    private static IMAGE_WIDTH = "imageWidth";

    private static IMAGE_HEIGHT = "imageHeight";

    private static FILE_CONTENT = "fileContent";

    private fileId: string;

    private insUserOid: string;

    private fileComponentOid: string;

    /** Content-Type (z.B. "image/jpeg" oder "application/pdf") */
    private contentType: string;

    private filename: string;

    private imageWidth: number;

    private imageHeight: number;

    private fileContentAsBase64: string;

    public static create(
        insUserOid: string,
        fileComponentOid: string,
        fileContentAsBase64DataURL: string,
        filename?: string,
    ): BCSFile {
        // Dateinamen bereinigen
        filename = filename.replace(/[\\\/:\?<>"|*;]/g, "_");

        const fileValueObject = {};
        fileValueObject[BCSFile.FILE_ID] = IdGenerator.createUUID();
        fileValueObject[BCSFile.INS_USER_OID] = insUserOid;
        fileValueObject[BCSFile.FILE_COMPONENT_OID] = fileComponentOid;
        fileValueObject[BCSFile.FILE_NAME] = filename;

        const bcsFile = new BCSFile(fileValueObject);
        bcsFile.setContentFromDataURL(fileContentAsBase64DataURL);
        return bcsFile;
    }

    constructor(fileValueObject: object) {
        this.fileId = fileValueObject[BCSFile.FILE_ID];
        this.insUserOid = fileValueObject[BCSFile.INS_USER_OID];
        this.fileComponentOid = fileValueObject[BCSFile.FILE_COMPONENT_OID];
        this.contentType = fileValueObject[BCSFile.CONTENT_TYPE];
        this.filename = fileValueObject[BCSFile.FILE_NAME] || null;
        this.imageWidth = fileValueObject[BCSFile.IMAGE_WIDTH] || null;
        this.imageHeight = fileValueObject[BCSFile.IMAGE_HEIGHT] || null;
        this.fileContentAsBase64 = fileValueObject[BCSFile.FILE_CONTENT];
    }

    public setContentFromDataURL(
        fileContentAsBase64DataURL: string,
        imageWidth?: number,
        imageHeight?: number,
    ): void {
        const pos = fileContentAsBase64DataURL.indexOf(";base64,");
        this.contentType = new RegExp("^data:(.*?);").exec(
            fileContentAsBase64DataURL.substr(0, pos + 1),
        )[1];
        this.fileContentAsBase64 = fileContentAsBase64DataURL.substr(pos + 8);
        this.imageWidth = imageWidth;
        this.imageHeight = imageHeight;
    }

    public async transformImage(
        maxWidth: number,
        maxHeight: number,
        quality: number,
        rotateType: ImageRotationType,
    ): Promise<void> {
        const resultImage = await new ImageTransformation()
            .rotate(rotateType)
            .reduce(maxWidth, maxHeight)
            .apply(this.getImageDataURL(), quality);
        this.setContentFromDataURL(
            resultImage.toBase64Image(),
            resultImage.getWidth(),
            resultImage.getHeight(),
        );
    }

    public getId(): string {
        return this.fileId;
    }

    public getFileComponentOid(): string {
        return this.fileComponentOid;
    }

    public getFilename(): string {
        return this.filename;
    }

    public getContentType(): string {
        return this.contentType;
    }

    public isImage(): boolean {
        return this.contentType && this.contentType.toLowerCase().indexOf("image/") === 0;
    }

    public isPDF(): boolean {
        return (
            this.contentType &&
            (this.contentType.toLowerCase() == "application/pdf" ||
                this.contentType.toLowerCase() == "application/x-pdf")
        );
    }

    public getImageDataURL(): string {
        return "data:" + this.contentType + ";base64," + this.fileContentAsBase64;
    }

    public getFileContentAsBase64(): string {
        return this.fileContentAsBase64;
    }

    public getContentAsBinary(): Uint8Array {
        if (!this.fileContentAsBase64) {
            return null;
        }

        const binaryImage = atob(this.fileContentAsBase64);
        const length = binaryImage.length;
        const ab = new ArrayBuffer(length);
        const ua = new Uint8Array(ab);
        for (let i = 0; i < length; i++) {
            ua[i] = binaryImage.charCodeAt(i);
        }
        return ua;
    }

    public getImageWidth(): number {
        return this.imageWidth;
    }

    public getImageHeight(): number {
        return this.imageHeight;
    }

    public toValueObject(): object {
        const fileValueObject = {};

        fileValueObject[BCSFile.FILE_ID] = this.fileId;

        fileValueObject[BCSFile.INS_USER_OID] = this.insUserOid;

        fileValueObject[BCSFile.FILE_COMPONENT_OID] = this.fileComponentOid;

        fileValueObject[BCSFile.CONTENT_TYPE] = this.contentType;

        if (this.filename) {
            fileValueObject[BCSFile.FILE_NAME] = this.filename;
        }

        if (this.imageWidth) {
            fileValueObject[BCSFile.IMAGE_WIDTH] = this.imageWidth;
        }
        if (this.imageHeight) {
            fileValueObject[BCSFile.IMAGE_HEIGHT] = this.imageHeight;
        }

        fileValueObject[BCSFile.FILE_CONTENT] = this.fileContentAsBase64;

        return fileValueObject;
    }
}
