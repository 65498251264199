import { BCSDate } from "../../common/BCSDate";
import { CalendarControll } from "../content/CalendarControll";
import { ClickOutSidePool } from "../event/ClickOutSidePool";
import { ConfigNode } from "../../core/Config/ConfigNode";
import { DateValue } from "../../entities/values/DateValue";
import { FormField, formFieldChangeEventType, FormFieldMode } from "./FormField";
import { FormFieldAddOn } from "./FormFieldAddOn";
import { GUIContext } from "../GUIContext";
import { GUIElement } from "../GUIElement";
import { I18n } from "../../common/i18n/I18n";

export class DateField implements GUIElement, FormField {
    private useNativeDatePicker: boolean = false;

    private entityId: string;

    private name: string;

    private value: DateValue;

    private label: string;

    private placeholder: string;

    private mode: FormFieldMode = FormFieldMode.EDIT;

    private changeCallback: (changeEvent: formFieldChangeEventType) => void;

    private calendarControl: CalendarControll;

    private i18n: I18n;

    private $inputField: JQuery;

    private $fixedCalendarWrapper: JQuery;

    private readOnly: boolean = false;

    constructor(i18n: I18n) {
        this.i18n = i18n;
        this.useNativeDatePicker =
            i18n.get("MobileApp.fields.DateField.UseNativeDatePicker") === "true";
    }

    public setEntityId(entityId: string): DateField {
        this.entityId = entityId;
        return this;
    }

    public setReadOnly(readOnly: boolean): DateField {
        this.readOnly = readOnly;
        return this;
    }

    public isReadOnly(): boolean {
        return this.readOnly;
    }

    public setName(name: string): DateField {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setValue(value: DateValue): DateField {
        this.value = value;
        return this;
    }

    public getValue(): DateValue {
        return this.value;
    }

    public setLabel(label: string): DateField {
        this.label = label;
        return this;
    }

    public setPlaceholder(placeholder: string): DateField {
        this.placeholder = placeholder;
        return this;
    }

    public setMode(mode: FormFieldMode): DateField {
        this.mode = mode;
        return this;
    }

    public getMode(): FormFieldMode {
        return this.mode;
    }

    public registerFormFieldAddOn(formFieldAddOn: FormFieldAddOn): DateField {
        // untertstützt bislang bislang keine AddOns
        return this;
    }

    public onChange(changeCallback: (changeEvent: formFieldChangeEventType) => void): DateField {
        this.changeCallback = changeCallback;
        return this;
    }

    public compose($parent: JQuery): void {
        if (this.readOnly) {
            this.composeDisplay($parent);
            return;
        }

        if (this.mode == FormFieldMode.DISPLAY) {
            this.composeDisplay($parent);
        } else {
            if (this.useNativeDatePicker) {
                this.$inputField = $("<input>")
                    .appendTo($parent)
                    .attr({
                        type: "date",
                        name: this.name,
                    })
                    .on("change", this.parseChangedDateValue.bind(this));

                if (this.value.isDefined()) {
                    this.$inputField.attr("value", this.value.format("YYYY-MM-DD"));
                }
            } else {
                this.$inputField = $("<input>")
                    .appendTo($parent)
                    .attr({
                        type: "button",
                        name: this.name,
                    })
                    .addClass("formbutton")
                    .addClass("bcsDateField")
                    .on("click", this.showCalendarPopup.bind(this))
                    .on("change", this.parseChangedDateValue.bind(this));

                if (this.value.isDefined()) {
                    this.$inputField.attr(
                        "value",
                        this.value.format(this.i18n.getDateFormat("MobileApp.DatePattern")),
                    );
                }
            }
        }
    }

    private composeDisplay($parent: JQuery<HTMLElement>) {
        this.$inputField = $("<input>")
            .appendTo($parent)
            .addClass("displayMode")
            .addClass("formfield")
            .attr({
                type: "text",
                name: this.name,
                readonly: "readonly",
            });

        if (this.value.isDefined()) {
            this.$inputField.attr(
                "value",
                this.value.format(this.i18n.getDateFormat("MobileApp.DatePattern")),
            );
        }
    }

    public updateValue(value: DateValue): DateField {
        if (this.mode == FormFieldMode.EDIT && value.isDefined() && this.value != value) {
            this.value = value;
        }

        let formattedDate = "";
        if (this.useNativeDatePicker) {
            formattedDate = value.getISODate();
        } else {
            formattedDate = this.value.format(this.i18n.getDateFormat("MobileApp.DatePattern"));
        }
        this.$inputField.val(formattedDate);

        return this;
    }

    public showCalendarPopup(event: Event): void {
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();

        const fakeConfigNode: ConfigNode = ConfigNode.getDynamicCreatedConfigNode({
            nodeName: "CalendarControll",
        });
        const fakeGuiContext = new GUIContext(this.i18n);

        this.calendarControl = new CalendarControll(fakeConfigNode, fakeGuiContext);
        ClickOutSidePool.registerClickInsideLayer(".fixedCalendarWrapper", () => {
            $(".fixedCalendarWrapper").remove();
        });
        this.calendarControl.setDateChooserMode();
        this.calendarControl.setDateSelectionCallback((selectedDate: BCSDate) => {
            this.updateValue(DateValue.parseFromString(selectedDate.getISODate()));
            this.$fixedCalendarWrapper.remove();
            this.dateValueChanged();
        });

        $(".fixedCalendarWrapper").remove();

        this.$fixedCalendarWrapper = $("<div>")
            .appendTo($("body"))
            .addClass("fixedCalendarWrapper");
        this.calendarControl.compose(this.$fixedCalendarWrapper);
    }

    private dateValueChanged(): void {
        if (this.changeCallback) {
            this.changeCallback({
                entityId: this.entityId,
                name: this.getName(),
                value: this.getValue(),
            });
        }
    }

    /**
     * Für WebTests: Nach direkter Änderung des Datumswertes, ISO-Datum parsen.
     */
    private parseChangedDateValue(): void {
        const inputValue = <string>this.$inputField.val();
        if (inputValue) {
            const changedDateValue = DateValue.parseFromString(inputValue);

            if (!true) {
                this.$inputField.val(
                    changedDateValue.format(this.i18n.getDateFormat("MobileApp.DatePattern")),
                );
            }
            this.updateValue(changedDateValue);
            this.dateValueChanged();
        }
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}
