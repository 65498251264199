import { GUIElement } from "../GUIElement";
import { FormField, formFieldChangeEventType, FormFieldMode } from "./FormField";
import { BooleanValue } from "../../entities/values/BooleanValue";
import { FormFieldAddOn } from "./FormFieldAddOn";

export class BooleanField implements GUIElement, FormField {
    private entityId: string;

    private name: string;

    private value: BooleanValue;

    private label: string;

    private placeholder: string;

    private mode: FormFieldMode = FormFieldMode.EDIT;

    private changeCallback: (changeEvent: formFieldChangeEventType) => void;

    private $inputTag: JQuery;

    private readOnly: boolean = false;

    public setEntityId(entityId: string): FormField {
        this.entityId = entityId;
        return this;
    }

    public setReadOnly(readOnly: boolean): BooleanField {
        this.readOnly = readOnly;
        return this;
    }

    public isReadOnly(): boolean {
        return this.readOnly;
    }

    public setName(name: string): BooleanField {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setValue(value: BooleanValue): BooleanField {
        this.value = value;
        return this;
    }

    public setLabel(label: string): BooleanField {
        this.label = label;
        return this;
    }

    public setPlaceholder(placeholder: string): BooleanField {
        this.placeholder = placeholder;
        return this;
    }

    public setMode(mode: FormFieldMode): FormField {
        this.mode = mode;
        return this;
    }

    public getMode(): FormFieldMode {
        return this.mode;
    }

    public registerFormFieldAddOn(formFieldAddOn: FormFieldAddOn): FormField {
        // untetstützt bislang bislang keine AddOns
        return this;
    }

    public onChange(changeCallback: (changeEvent: formFieldChangeEventType) => void): BooleanField {
        this.changeCallback = changeCallback;
        return this;
    }

    public compose($parent: JQuery): void {
        if (this.readOnly) {
            this.composeDisplayMode($parent);
            return;
        }

        switch (this.mode) {
            case FormFieldMode.DISPLAY:
                this.composeDisplayMode($parent);
                break;
            case FormFieldMode.EDIT:
            default:
                this.composeEditMode($parent);
                break;
        }
    }

    private composeDisplayMode($parent: JQuery): void {
        this.composeEditMode($parent);

        // Im Display-Modus: Read-Only
        this.$inputTag.attr("disabled", "disabled");
    }

    private composeEditMode($parent: JQuery): void {
        const checkboxId = this.entityId ? this.entityId + "_" + this.name : this.name;

        this.$inputTag = $("<input>")
            .appendTo($parent)
            .attr({
                id: checkboxId,
                type: "checkbox",
                name: this.name,
                checked: this.value.getBoolean() ? "checked" : null,
            })
            .addClass("formcheckbox")
            .on("change", this.valueChanged.bind(this));

        if (this.label) {
            $("<label>")
                .appendTo($parent)
                .addClass("formcheckbox")
                .attr({ for: checkboxId })
                .text(this.label);
        }
    }

    public updateValue(value: BooleanValue): BooleanField {
        if (this.mode == FormFieldMode.EDIT && this.value != value) {
            this.value = value;
            this.$inputTag.prop("checked", this.value.getBoolean());
        }
        return this;
    }

    public getValue(): BooleanValue {
        return new BooleanValue(this.$inputTag.prop("checked"));
    }

    private valueChanged(event: Event): void {
        if (this.changeCallback) {
            event.preventDefault();
            event.stopPropagation();

            this.changeCallback({
                entityId: this.entityId,
                name: this.getName(),
                value: this.getValue(),
            });
        }
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}
