import { GUIElement } from "../GUIElement";
import { FlexBox, FlexJustifyContent } from "../flexbox/FlexBox";
import { FlexItem } from "../flexbox/FlexItem";
import { TextLabel } from "../content/TextLabel";
import { FixedGUIElement } from "../FixedGUIElement";
import "./TabbedViewBar.less";

type TabbedView = {
    name: string;
    label: string;
    active: boolean;
    contentElement: GUIElement;
    callback: Function;
};

/*
 * Tabbed Bar mit jeweils einem unterstrichenen aktiven Tab.
 * Inhalt des aktiven Tabs wird angezeigt.
 * Balken wechselt animiert zwischen aktiven Tabs.
 *
 * Anwendungsfälle: Liste der Dienstreisen / Einzelbelege, Reiseabschnitte / Belegspesen
 */
export class TabbedViewBar implements GUIElement, FixedGUIElement {
    private isBarFixed: boolean = true;

    private tabSwitchNames: string[] = [];

    private tabSwitches: { [key: string]: TabbedView } = {};

    private $contentParentTags: { [key: string]: JQuery } = {};

    private tabSwitchFlexItems: { [key: string]: FlexItem } = {};

    private activeTabSwitchName: string;

    private tabSwitchFlexBox: FlexBox;

    private switchLabels: { [key: string]: TextLabel } = {};

    /** Callback bei Tab-Wechsel */
    private tabChangedCallback: (switchName: string) => void;

    public setFixed(isBarFixed: boolean): TabbedViewBar {
        this.isBarFixed = isBarFixed;
        return this;
    }

    public addContentTabSwitch(
        name: string,
        label: string,
        active: boolean,
        contentElement: GUIElement,
    ): TabbedViewBar {
        this.tabSwitchNames.push(name);
        this.tabSwitches[name] = {
            name: name,
            label: label,
            active: active,
            contentElement: contentElement,
            callback: null,
        };
        return this;
    }

    public addActionTabSwitch(
        name: string,
        label: string,
        active: boolean,
        callback?: Function,
    ): TabbedViewBar {
        this.tabSwitchNames.push(name);
        this.tabSwitches[name] = {
            name: name,
            label: label,
            active: active,
            contentElement: null,
            callback: callback,
        };
        return this;
    }

    /**
     * Registriert Callback bei Tab-Wechsel.
     * @param tabChangedCallback Callback bei Tab-Wechsel
     */
    public onTabChange(tabChangedCallback: (switchName: string) => void): TabbedViewBar {
        this.tabChangedCallback = tabChangedCallback;
        return this;
    }

    /**
     * HTML-DOM generieren
     *
     * @param $parentTag Parent-jQuery-Objekt
     */
    public compose($parentTag: JQuery): void {
        const self = this;

        this.tabSwitchFlexBox = new FlexBox()
            .setJustifyContent(FlexJustifyContent.SPACE_EVENLY)
            .setStretchFlexItems(true)
            .addStyleClass("tabbed_view_switch");

        if (this.isFixed()) {
            this.tabSwitchFlexBox.addStyleClass("tabbed_view_switch_fixed");
        }

        for (let i = 0; i < this.tabSwitchNames.length; i++) {
            const switchName = this.tabSwitchNames[i];
            const tabSwitch = this.tabSwitches[switchName];

            this.switchLabels[switchName] = new TextLabel().setInlineText(tabSwitch.label);

            const tabSwitchFlexItem = new FlexItem()
                .addStyleClass("tab_switch" + i)
                .addStyleClass(tabSwitch.active ? "tab_switch active" : "tab_switch")
                .addContentElement(this.switchLabels[switchName])
                .onClick(
                    ((buttonName) => {
                        return (event) => self.tabSwitchClicked(buttonName);
                    })(tabSwitch.name),
                );
            this.tabSwitchFlexBox.addFlexItem(tabSwitchFlexItem);
            this.tabSwitchFlexItems[tabSwitch.name] = tabSwitchFlexItem;

            if (tabSwitch.active) {
                this.activeTabSwitchName = tabSwitch.name;
            }
        }

        // Blauer Balken unter aktiven Tab
        this.tabSwitchFlexBox.newFlexItem().addStyleClass("activeBar");

        this.tabSwitchFlexBox.compose($parentTag);

        for (let i = 0; i < this.tabSwitchNames.length; i++) {
            const tabSwitch = this.tabSwitches[this.tabSwitchNames[i]];

            const $contentParentTag = $("<div>")
                .appendTo($parentTag)
                .css({
                    display: tabSwitch.active ? "block" : "none",
                    with: "100%",
                });
            this.$contentParentTags[tabSwitch.name] = $contentParentTag;

            tabSwitch.contentElement.compose($contentParentTag);
        }
    }

    public updateTabSwitchLabel(switchName: string, title: string): void {
        this.switchLabels[switchName].setAdhocText(title);
    }

    /**
     * @return true, wenn GUIElement fixiert, false wenn nicht (für Elemente, die sowohl fixiert als auch mitscrollend sein können)
     */
    public isFixed(): boolean {
        return this.isBarFixed;
    }

    /**
     * @return Höhe des fixierten GUIElementes (in px) nach dem alle GUIElemente der Seite gerendert wurden
     */
    public getFixedHeight(): number {
        return this.tabSwitchFlexBox.getFlexBoxTag().outerHeight();
    }

    /**
     * Setzt Y-Position des fixierten GUIELementes (absoluten Abstand zum oberen bzw. unteren Rand).
     *
     * @param verticalPositionKey "top" oder "bottom" für Header bzw. Footer
     * @param verticalPos Y-Position vom oberen bzw. unteren Rand (z.B. 15 für "15px")
     */
    public setVerticalPosition(verticalPositionKey: string, verticalPos: number): void {
        this.tabSwitchFlexBox.getFlexBoxTag().css(verticalPositionKey, verticalPos + "px");
    }

    public getActiveTabSwitchName(): string {
        return this.activeTabSwitchName;
    }

    private tabSwitchClicked(name: string): void {
        const deactivatedTabSwitchName = this.activeTabSwitchName;
        this.activeTabSwitchName = name;

        this.tabSwitchFlexItems[deactivatedTabSwitchName].removeStyleClass("active");
        this.tabSwitchFlexItems[this.activeTabSwitchName].addStyleClass("active");

        this.$contentParentTags[deactivatedTabSwitchName].css("display", "none");
        this.$contentParentTags[this.activeTabSwitchName].css("display", "block");

        if (this.tabSwitches[name]["callback"]) {
            this.tabSwitches[name]["callback"]();
        }

        // Callback über Tab-Wechsel informieren
        if (this.tabChangedCallback) {
            this.tabChangedCallback(this.activeTabSwitchName);
        }
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}
