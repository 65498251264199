import { IndexedDBError } from "../IndexedDBError";

export class IndexedDBSelectIdRequest {
    private storeName: string;
    private transaction: IDBTransaction;
    private id: any;

    constructor(transaction: IDBTransaction, storeName, id: any) {
        this.transaction = transaction;
        this.storeName = storeName;
        this.id = id;
    }

    public then(successCallback, errorCallback) {
        const self = this;

        const store = this.transaction.objectStore(this.storeName);
        const request = store.get(this.id);
        request.onsuccess = function (event) {
            self.success(event, successCallback);
        };
        request.onerror = function (event) {
            self.error(event, errorCallback);
        };
    }

    private success(event, successCallback) {
        successCallback.call(this, { element: event.target.result });
    }

    private error(event, errorCallback) {
        errorCallback.call(this, new IndexedDBError(event).setId(this.id));
    }
}
