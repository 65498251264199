import { ListRowModel } from "../../gui/list/ListRowModel";
import { SyncState, SyncStateObjectType } from "../SyncState";
import { I18n } from "../../common/i18n/I18n";
import { SchemaDataType } from "../../common/schema/SchemaDataType";
import { AttributeDefinition } from "../../common/schema/AttributeDefinition";
import { EntityValue } from "../../entities/values/EntityValue";
import { StringValue } from "../../entities/values/StringValue";

export class SyncStateListRowModel implements ListRowModel {
    private synState: SyncState;

    private i18n: I18n;

    constructor(synState: SyncState, i18n: I18n) {
        this.synState = synState;
        this.i18n = i18n;
    }

    public getEntityId(name?: string): string {
        return this.synState.getId();
    }

    public getDataType(name: string): SchemaDataType {
        return this.getAttributeDefinition(name).getDataType();
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return this.synState.getTypeSubtypeDefinition().getAttributeDefinition(name);
    }

    public getValue(name: string): EntityValue {
        let value = this.synState.getValue(name);
        switch (name) {
            case "objectType":
                let label;
                switch (this.synState.getSyncStateObjectType()) {
                    case SyncStateObjectType.Attendance:
                        label = this.i18n.get("JTimeSpan.attendance");
                        break;
                    case SyncStateObjectType.Pause:
                        label = this.i18n.get("JTimeSpan.pause");
                        break;
                    case SyncStateObjectType.Booking:
                        label = this.i18n.get("JEffort.Personal");
                        break;
                    default:
                        label = this.i18n.get(
                            value.getString(),
                            this.i18n.get("MobileApp." + value.getString()),
                        );
                }
                value = new StringValue(label);
                break;
        }

        return value;
    }

    public setValue(name: string, value: EntityValue): void {
        throw new Error("[SyncStateListRowModel] Setting SyncState values not supported");
    }

    public isEditable(): boolean {
        return false;
    }

    public isDeletable(): boolean {
        return false;
    }
}
