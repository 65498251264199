import { GUIElement } from "../GUIElement";
import "./TableView.less";

export class TableView implements GUIElement {
    private styleClasses: string[] = [];

    private $tableTag: JQuery;

    private rows: TableViewRow[] = [];

    public addStyle(styleClass: string): TableView {
        this.styleClasses.push(styleClass);
        return this;
    }

    public newHeaderRow(): TableViewRow {
        const row = new TableViewRow().setHeaderRow(true);
        this.rows.push(row);
        return row;
    }

    public newRow(): TableViewRow {
        const row = new TableViewRow();
        this.rows.push(row);
        return row;
    }

    public compose($parent: JQuery): void {
        this.$tableTag = $("<table>")
            .addClass("tableview")
            .addClass(this.styleClasses.join(" "))
            .appendTo($parent);

        this.rows.forEach((row) => row.compose(this.$tableTag));
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}

export class TableViewRow implements GUIElement {
    private isHeaderRow = false;

    private cells: TableViewCell[] = [];

    private $rowTag: JQuery;

    public setHeaderRow(isHeaderRow: boolean = false): TableViewRow {
        this.isHeaderRow = isHeaderRow;
        return this;
    }

    public newLabelCell(): TableViewCell {
        const cell = new TableViewCell().setLabelColumn(true);
        this.cells.push(cell);
        return cell;
    }

    public newCell(): TableViewCell {
        const cell = new TableViewCell();
        this.cells.push(cell);
        return cell;
    }

    public compose($parent: JQuery): void {
        this.$rowTag = $("<tr>")
            .addClass(this.isHeaderRow ? "header" : "")
            .appendTo($parent);

        this.cells.forEach((cells) => cells.compose(this.$rowTag));
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}

export class TableViewCell implements GUIElement {
    private isLabelColumn = false;

    private cellContentElements: GUIElement[] = [];

    private $cellTag: JQuery;

    private styleClasses: string[] = [];

    public setLabelColumn(isLabelColumn: boolean = false): TableViewCell {
        this.isLabelColumn = isLabelColumn;
        return this;
    }

    public addContentElement(cellContentElement: GUIElement): TableViewCell {
        const row = new TableView();
        this.cellContentElements.push(cellContentElement);
        return this;
    }

    public addStyle(styleClass: string): TableViewCell {
        this.styleClasses.push(styleClass);
        return this;
    }

    public compose($parent: JQuery): void {
        this.$cellTag = $("<td>")
            .addClass(this.isLabelColumn ? "label" : "")
            .addClass(this.styleClasses.join(" "))
            .appendTo($parent);

        this.cellContentElements.forEach((cell) => cell.compose(this.$cellTag));
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}
