import { IndexedDBError } from "../IndexedDBError";
import { IndexedDBTransaction } from "../IndexedDBTransaction";

export class IndexedDBDeleteIdsRequest {
    private storeName: string;
    private transaction: IDBTransaction;
    private query: string;
    private indexName: string;
    private ids: any[];
    private deleteCounter: number;

    constructor(transaction: IDBTransaction, storeName: string, ids: string[]) {
        this.transaction = transaction;
        this.storeName = storeName;
        this.ids = Array.isArray(ids) ? ids : [ids];
        this.deleteCounter = 0;
    }

    public then(successCallback, errorCallback) {
        const self = this;

        const store = this.transaction.objectStore(this.storeName);

        const n = this.ids.length;
        for (let i = 0; i < n; i++) {
            var id = this.ids[i];

            const request = store.delete(id);
            request.onsuccess = function (event) {
                self.success(event, id, successCallback);
            };
            request.onerror = function (event) {
                self.error(event, id, errorCallback);
            };
        }
    }

    private success(event, id, successCallback) {
        if (++this.deleteCounter == this.ids.length) {
            successCallback.call(this);
        }
    }

    private error(event, id, errorCallback) {
        errorCallback.call(this, new IndexedDBError(event).setId(id));
    }
}
