import { SchemaDataType } from "../../common/schema/SchemaDataType";
import { EntityValue } from "./EntityValue";
import { StringValue } from "./StringValue";
import { NumberValue } from "./NumberValue";
import { CurrencyValue } from "./CurrencyValue";
import { DateTimeValue } from "./DateTimeValue";
import { DateValue } from "./DateValue";
import { TimeValue } from "./TimeValue";
import { BooleanValue } from "./BooleanValue";
import { DurationValue } from "./DurationValue";
import { OidValue } from "./OidValue";

export class EntityValueFactory {
    public static fromSimpleValue(
        dataType: SchemaDataType,
        simpleValue: string | number | boolean,
    ): EntityValue {
        switch (dataType) {
            case SchemaDataType.STRING:
                return new StringValue(<string>simpleValue);
            case SchemaDataType.OPTION:
                return new StringValue(<string>simpleValue);
            case SchemaDataType.CLASSIFIER:
                return new StringValue(<string>simpleValue);
            case SchemaDataType.LSTRING:
                return new StringValue(<string>simpleValue);
            case SchemaDataType.TEXT:
                return new StringValue(<string>simpleValue);
            case SchemaDataType.HTML:
                return new StringValue(<string>simpleValue);
            case SchemaDataType.PWD:
                return new StringValue(<string>simpleValue);
            case SchemaDataType.OID:
                return new OidValue(<string>simpleValue);
            case SchemaDataType.INT:
            case SchemaDataType.NUMBER:
                return new NumberValue(<number>simpleValue);
            case SchemaDataType.FLOAT:
                return new NumberValue(<number>simpleValue);
            case SchemaDataType.DURATION:
                return new DurationValue(<number>simpleValue);
            case SchemaDataType.CURRENCY:
                return new CurrencyValue(<string>simpleValue);
            case SchemaDataType.DATETIME:
                return new DateTimeValue(<string>simpleValue);
            case SchemaDataType.DATE:
                return new DateValue(<string>simpleValue);
            case SchemaDataType.TIME:
                return new TimeValue(<string>simpleValue);
            case SchemaDataType.BOOL:
                return new BooleanValue(<boolean>simpleValue);
            case SchemaDataType.HANDY:
                return new StringValue(<string>simpleValue);
            case SchemaDataType.PHONE:
                return new StringValue(<string>simpleValue);
            case SchemaDataType.MAIL:
                return new StringValue(<string>simpleValue);
            case SchemaDataType.MULTISTRING:
                return new StringValue(<string>simpleValue);
            case SchemaDataType.URL:
                return new StringValue(<string>simpleValue);
            default:
                throw new Error(
                    "[EntityValueFactory] Unknown EntityValue for DataType: " + dataType,
                );
        }
    }
}
