import { Component } from "./Component";
import { Controller } from "./Controller";
import { Registry } from "./Registry";
import { SingletonComponent } from "./SingletonComponent";
import { SingletonManager } from "./SingletonManager";

export class ComponentRegistry {
    private lazyScan = true;

    private singletonInstances: { [key: string]: SingletonComponent } = {};

    public scanForComponents(): void {
        this.lazyScan = false;

        Registry.getSingletonComponentNames().forEach((singletonName) => {
            this.singletonInstances[singletonName] = <SingletonComponent>(
                this.getComponent(singletonName)
            );
        });

        this.getSingletonManager().setSingletons(this.singletonInstances);
    }

    public getSingletonManager(): SingletonManager {
        return <SingletonManager>this.getComponent(SingletonManager.BCS_COMPONENT_NAME);
    }

    public getComponent(name: string): Component {
        if (this.lazyScan) {
            // Für JSUnitTests: Erst scannen, wenn erste Komponente abgeholt wird
            this.scanForComponents();
        }
        return this.getOrCreateComponent(name, 0);
    }

    private getOrCreateComponent(name: string, depth: number): Component {
        if (this.singletonInstances.hasOwnProperty(name)) {
            return <Component>this.singletonInstances[name];
        }

        let component = null;
        if (Registry.isSingleton(name)) {
            component = Object.create(Registry.getSingletonComponent(name).prototype);
        } else {
            component = Object.create(Registry.getComponent(name).prototype);
        }

        if (component == null) {
            throw new Error("[ComponentRegistry] Component not registered: " + name);
        }

        if (depth > 10) {
            throw new Error("[ComponentRegistry] Cyclic component dependencies: " + name);
        }

        component = new component.constructor();

        const depencencyNames = component.getDependencyNames();
        const depencencyComponents: { [key: string]: Component } = {};
        for (const depencencyName of depencencyNames) {
            const depencencyComponent = this.getOrCreateComponent(depencencyName, depth + 1);
            depencencyComponents[depencencyName] = depencencyComponent;
        }
        component.init(depencencyComponents);

        if ("start" in component) {
            this.singletonInstances[name] = <SingletonComponent>component;
        }

        return component;
    }

    public getController(name: string): Controller {
        const controller = <Controller>this.getComponent(name);
        return controller;
    }
}
