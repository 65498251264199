import { GUIElement } from "../GUIElement";
import { AppColors } from "../../util/globalDefinitions/AppColors";

import * as d3 from "d3";
import { Booking } from "../../domain/time_recording/bookings/Booking";
import { TimeRecordingAttendanceSummary } from "../../domain/time_recording/TimeRecordingAttendanceSummary";
import { BCSDate } from "../../common/BCSDate";
import { TimeRecord } from "../../domain/time_recording/TimeRecord";
import { DummyBooking } from "../../domain/time_recording/DummyBooking";

export class TimeRecordingRowBackground implements GUIElement {
    private styleClasses: string[] = [];

    private booking: TimeRecord;

    private attendanceSummary: TimeRecordingAttendanceSummary;

    constructor(booking: TimeRecord, scale: TimeRecordingAttendanceSummary) {
        this.booking = booking;
        this.attendanceSummary = scale;
    }

    public compose($parent: JQuery): void {
        const wrapper = $("<div class='rowBackground'>");
        $parent.append(wrapper);
        // normale Buchungsfarbe blau:
        let timeRecordColor = "var(--booking-BackgroundColor)";
        let timeRecordTextColor = "var(--booking-TextColor)";
        let rectHeight = 46;

        let opacity: number = 1;

        //Pause sollte grau sein:
        if (this.booking.isPause()) {
            timeRecordColor = "var(--pause-BackgroundColor)";
            timeRecordTextColor = "var(--pause-TextColor)";
            rectHeight = 46;
            if (this.booking.hasSyncState() && this.booking.isSyncOnlyChangedInApp()) {
                timeRecordTextColor = AppColors.TimeRecording_IsSyncOnlyChangedInApp_Color_hex;
                timeRecordColor = AppColors.TimeRecording_IsSyncOnlyChangedInApp_Color_hex_BG;
            }
        }

        //Pause sollte grau sein:
        if (this.booking.isDummy()) {
            const dummy: DummyBooking = <DummyBooking>this.booking;
            if (dummy.isAppointment()) {
                timeRecordColor = "var(--dummy-BackgroundColor)";
                timeRecordTextColor = "var(--dummy-TextColor)";
                rectHeight = 46;
                opacity = 0.5;
            } else {
                timeRecordColor = "var(--placeholderBooking-BackgroundColor)";
                timeRecordTextColor = "var(--placeholderBooking-TextColor)";
                rectHeight = 20;
            }
        } else if (this.booking.isBooking()) {
            const timeBooking = <Booking>this.booking;
            if (timeBooking.hasSyncState() && timeBooking.isSyncOnlyChangedInApp()) {
                timeRecordColor = "var(--synchedBooking-BackgroundColor)";
                timeRecordTextColor = "var(--synchedBooking-TextColor)";
                rectHeight = 46;
            }
        }

        wrapper.css("height", rectHeight + "px");

        const svg = d3
            .select(wrapper[0])
            .append("svg")
            .attr("height", rectHeight + "px")
            .attr("width", "100%");

        if (this.booking.hasDurationOnly()) {
            let lastEndPoint: number = this.attendanceSummary.getDurationOnlyLastEnd();
            // Falls wir die erste Duration zeichnen gibt es noch keinen letzten Endpunkt, also nehmen wir 0 an.
            if (lastEndPoint == -1) {
                lastEndPoint =
                    this.attendanceSummary.getScale()(0) + this.attendanceSummary.offsetXBefore;
            }
            var startPoint: number = lastEndPoint; /*+this.attendanceSummary.offsetXBefore;*/
            const duration = this.attendanceSummary.getScale()(
                this.booking.getEffortExpense() / 60,
            );
            var endPoint: number = startPoint + duration; //this.attendanceSummary.getScale()(this.booking.getEffortExpense()/60)+this.attendanceSummary.offsetXBefore;

            this.attendanceSummary.setDurationOnlyLastEnd(startPoint + duration);

            svg.append("rect")
                .attr("class", "bg-rect")
                .attr("fill", timeRecordColor)
                .attr("height", rectHeight)
                .attr("width", duration)
                .attr("opacity", opacity)
                .attr("x", startPoint)
                .attr("y", 0);
        } else {
            let startTime;
            if (this.booking.getStartTime() != null) {
                startTime = this.booking.getStartTime();
            }

            let endTime;
            if (this.booking.getEndTime() != null) {
                endTime = this.booking.getEndTime();
            }

            var endPoint: number =
                this.attendanceSummary.getScale()(endTime) + this.attendanceSummary.offsetXBefore;
            var startPoint: number =
                this.attendanceSummary.getScale()(startTime) + this.attendanceSummary.offsetXBefore;
            svg.append("rect")
                .attr("class", "bg-rect")
                .attr("fill", timeRecordColor)
                .attr("height", rectHeight)
                .attr("width", endPoint - startPoint)
                .attr("opacity", opacity)
                .attr("x", startPoint)
                .attr("y", 0);

            svg.append("text")
                .text(
                    new BCSDate(this.booking.getStartTime()).format("H:mm") +
                        "-" +
                        new BCSDate(this.booking.getEndTime()).format("H:mm"),
                )
                .attr("class", "rowTime")
                .attr("x", (endPoint - startPoint) / 2 + startPoint)
                .attr("y", 2)
                .attr("text-anchor", "middle")
                .attr("fill", timeRecordTextColor)
                .style("font-size", "13px")
                .attr("alignment-baseline", "hanging")
                // Für FireFox
                .attr("dominant-baseline", "hanging");
        }
    }

    public addStyleClass(styleClass: string): TimeRecordingRowBackground {
        this.styleClasses.push(styleClass);
        return this;
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}
