import { IndexedDBError } from "../IndexedDBError";
import { Log } from "../../common/log/Log";

export class IndexedDBUpdateElementsRequest {
    private storeName: string;
    private transaction: IDBTransaction;
    private elements: any[];
    private updateCounter: number;

    constructor(transaction: IDBTransaction, storeName: string, elements: object[]) {
        this.transaction = transaction;
        this.storeName = storeName;
        this.elements = Array.isArray(elements) ? elements : [elements];
        this.updateCounter = 0;
    }

    then(successCallback, errorCallback) {
        const store = this.transaction.objectStore(this.storeName);

        const self = this;

        const n = this.elements.length;
        for (let i = 0; i < n; i++) {
            var element = this.elements[i];

            try {
                const request = store.put(element);
                request.onsuccess = function (event) {
                    self.success(event, successCallback);
                };
                request.onerror = function (event) {
                    self.error(event, element, errorCallback);
                };
            } catch (exception) {
                Log.error("[IndexedDBUpdateElementsRequest] InvalidUpdateRequest", {
                    element: element,
                    exception: exception,
                });
                errorCallback.call(
                    this,
                    "[IndexedDBUpdateElementsRequest] invalid update request: " + exception,
                );
            }
        }
    }

    private success(event, successCallback) {
        if (++this.updateCounter == this.elements.length) {
            successCallback.call(this);
        }
    }

    private error(event, element, errorCallback) {
        const dbError = new IndexedDBError(event).setElement(element);
        Log.error("[IndexedDBUpdateElementsRequest] Update error", { error: dbError });
        errorCallback.call(this, dbError);
    }
}
