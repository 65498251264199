import { IndexedDBError } from "../IndexedDBError";

export class IndexedDBClearRequest {
    private storeName: string;
    private transaction: IDBTransaction;

    constructor(transaction: IDBTransaction, storeName: string) {
        this.transaction = transaction;
        this.storeName = storeName;
    }

    then(successCallback, errorCallback) {
        const self = this;

        const store = this.transaction.objectStore(this.storeName);
        const request = store.clear();
        request.onsuccess = function (event) {
            self.success(event, successCallback);
        };
        request.onerror = function (event) {
            self.error(event, errorCallback);
        };
    }

    private success(event, successCallback) {
        successCallback.call(this);
    }

    private error(event, errorCallback) {
        // TODO wo kommt im Orginal die id her?
        errorCallback.call(this, new IndexedDBError(event) /*.setId( id )*/);
    }
}
