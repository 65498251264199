import "./TextLabel.less";
import { GUIElement } from "../GUIElement";
import { ConfigNode } from "../../core/Config/ConfigNode";
import { GUIContext } from "../GUIContext";
import { Registry } from "../../core/Registry";

export const enum TextLabelContentType {
    TEXT = "text",
    HTML = "html",
}

export class TextLabel implements GUIElement {
    public static BCS_COMPONENT_NAME = "TextLabel";

    private text: string;

    private contentType: TextLabelContentType = TextLabelContentType.TEXT;

    private htmlTagName: string;

    private configNode: ConfigNode;

    private context: GUIContext;

    private $textLabelTag: JQuery;

    private styleClasses: string[] = [];

    private isShown: boolean = true;
    private singleStyles: Map<string, string> = new Map<string, string>();

    constructor(configNode?: ConfigNode, controller?: GUIContext) {
        this.context = controller;
        this.configNode = configNode;
    }

    public setInlineText(text: string): TextLabel {
        this.text = text;
        this.htmlTagName = "span";

        if (this.$textLabelTag) {
            this.$textLabelTag.text(text);
        }

        return this;
    }

    public getInlineText(): string {
        return this.text;
    }

    public setText(text: string): TextLabel {
        this.text = text;
        this.htmlTagName = "p";

        if (this.$textLabelTag) {
            this.$textLabelTag.text(text);
        }

        return this;
    }

    public setContentType(contentType: TextLabelContentType): TextLabel {
        this.contentType = contentType;
        return this;
    }

    public compose($parent: JQuery): void {
        let text = "";

        // erste Prio hat der Text der programmatisch gesetzt wurde.
        if (this.text) {
            text = this.text;
        } else if (this.configNode) {
            // zweite Prio hat der I18nKey der durch die Konfig gesetzt wurde
            const i18nKey = this.configNode.getAttribute("I18nKey", null);
            if (i18nKey !== null) {
                text = this.context.getI18n().get(i18nKey);
            }
        }

        this.$textLabelTag = $("<" + this.htmlTagName + ">");

        this.$textLabelTag.addClass(this.styleClasses.join(" ")).appendTo($parent);
        if (this.singleStyles.size != 0) {
            this.singleStyles.forEach((value: string, key: string) => {
                this.$textLabelTag.css(key, value);
            });
        }

        switch (this.contentType) {
            case TextLabelContentType.TEXT:
                this.$textLabelTag.text(text);
                break;
            case TextLabelContentType.HTML:
                this.$textLabelTag.css("white-space", "normal").html(text);
                break;
        }

        this.setDisplay(this.isShown);
    }

    public addStyleClass(styleClass: string): TextLabel {
        this.styleClasses.push(styleClass);
        return this;
    }

    public setAdhocText(text: string): TextLabel {
        this.$textLabelTag.text(text);
        return this;
    }

    public setDisplay(show: boolean): TextLabel {
        this.isShown = show;
        if (this.$textLabelTag) {
            this.$textLabelTag.css(
                "display",
                show ? (this.htmlTagName == "p" ? "block" : "inline") : "none",
            );
        }
        return this;
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }

    /**
     * Verkleinert den Text, wenn die Menge an Zeichen über einen bestimmten Wert steigt.
     * @param number - ab wie vielen Zeichen Textlänge soll der Text verkleinert werden
     * @param fontsize - die Größe der Schrift, kann jeder Wert als String sein, zB "10px" oder "medium"
     */
    adjustFontSizeRegardingLabelLengh(number: number, fontsize: string): TextLabel {
        if (Math.max(this.getInlineText().length) > number) {
            this.setStyle("font-size", fontsize);
        }
        return this;
    }

    /**
     * Setzt einen beliebigen Stylewert, der später direkt an das Textelement drangeschrieben wird
     * @param property - Stylewert
     * @param value - value des Styles
     * @private
     */
    private setStyle(property: string, value: string) {
        this.singleStyles.set(property, value);
        return this;
    }
}

Registry.registerGUIComponent(TextLabel.BCS_COMPONENT_NAME, TextLabel);
