import { CurrencyValue } from "../../entities/values/CurrencyValue";
import { FormField, formFieldChangeEventType, FormFieldMode } from "./FormField";
import { FormFieldAddOn } from "./FormFieldAddOn";
import { GUIElement } from "../GUIElement";
import { StringField } from "./StringField";
import { StringValue } from "../../entities/values/StringValue";
import { I18n } from "../../common/i18n/I18n";
import { InputModes } from "./InputModes";

export class CurrencyField implements GUIElement, FormField {
    private i18n: I18n;

    private stringField: StringField;

    private changeCallback: (changeEvent: formFieldChangeEventType) => void;

    private value: CurrencyValue;

    private currencySign: string;

    private readOnly: boolean = false;

    private inputMode: string = InputModes.FLOAT;

    constructor(i18n: I18n) {
        this.i18n = i18n;

        this.stringField = new StringField()
            .setType("number")
            .onChange(this.stringValueChanged.bind(this));
    }

    public setEntityId(entityId: string): FormField {
        this.stringField.setEntityId(entityId);
        return this;
    }

    public setReadOnly(readOnly: boolean): CurrencyField {
        this.stringField.setReadOnly(readOnly);
        return this;
    }

    public isReadOnly(): boolean {
        return this.readOnly;
    }

    public setName(name: string): CurrencyField {
        this.stringField.setName(name);
        return this;
    }

    public getName(): string {
        return this.stringField.getName();
    }

    public setValue(value: CurrencyValue): CurrencyField {
        this.value = value;
        this.currencySign = value.getCurrencySign();
        const stringValue = new StringValue(value.formatDecimalPlaces(2));
        this.stringField.setValue(stringValue);
        return this;
    }

    public setLabel(label: string): CurrencyField {
        this.stringField.setLabel(label);
        return this;
    }

    public setPlaceholder(placeholder: string): CurrencyField {
        this.stringField.setPlaceholder(placeholder);
        return this;
    }

    public setMode(mode: FormFieldMode): FormField {
        this.stringField.setMode(mode);
        return this;
    }

    public getMode(): FormFieldMode {
        return this.stringField.getMode();
    }

    public onChange(
        changeCallback: (changeEvent: formFieldChangeEventType) => void,
    ): CurrencyField {
        this.changeCallback = changeCallback;
        return this;
    }

    public registerFormFieldAddOn(formFieldAddOn: FormFieldAddOn): FormField {
        // untetstützt bislang bislang keine AddOns
        return this;
    }

    public compose($parent: JQuery): void {
        if (this.getMode() == FormFieldMode.DISPLAY) {
            if (this.value && this.value.isDefined()) {
                const formattedValue = this.value.format(this.i18n.getLocale("-"));
                const stringValue = new StringValue(
                    formattedValue + " " + this.value.getCurrencySign(),
                );
                this.stringField.setValue(stringValue);
            }
        }
        this.stringField.setInputmode(this.inputMode);
        this.stringField.compose($parent);
    }

    public updateValue(value: CurrencyValue): CurrencyField {
        const stringValue = new StringValue(
            value.isDefined() ? value.getNumber().toString() : null,
        );
        this.stringField.updateValue(stringValue);
        return this;
    }

    public getValue(): CurrencyValue {
        return CurrencyValue.parseFromString(
            this.currencySign,
            this.stringField.getValue().getString(),
        );
    }

    private stringValueChanged(changeEvent: formFieldChangeEventType): void {
        if (this.changeCallback) {
            this.changeCallback({
                entityId: changeEvent.entityId,
                name: this.getName(),
                value: this.getValue(),
            });
        }
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }

    public setInputmode(inputMode: string): CurrencyField {
        this.inputMode = inputMode;
        return this;
    }
}
