import { BCSDate } from "../../../common/BCSDate";

/** Ausrichtung als Reihe/Spalte */
export const enum AttendanceClockState {
    /** Vom Server wurde bestätigt das die Stoppuhr läuft.*/
    AttandanceRuning = "attendanceRunning",
    /** Benutzer hat auf Start in der App geklickt und der Server hat noch nicht bestätigt dass die Stoppuhr läuft. */
    AttandanceStarted = "attendanceStarted",
    /** Benutzer hat auf Stopp in der App geklickt und der Server hat noch nicht bestätigt dass die Stoppuhr nicht läuft.*/
    AttandanceStoping = "attendanceStoping",
    /** Vom Server bestätigt das keine Stoppuhr läuft. */
    AttandanceStoped = "attendanceStoped",

    PauseRunning = "pauseRunning",
    /** Benutzer hat auf Start in der App geklickt und der Server hat noch nicht bestätigt dass die Stoppuhr läuft. */
    PauseStarted = "pauseStarted",
    /** Benutzer hat auf Stopp in der App geklickt und der Server hat noch nicht bestätigt dass die Stoppuhr nicht läuft.*/
    PauseStoping = "pauseStoping",
    /** Vom Server bestätigt das keine Stoppuhr läuft. */
    PauseStoped = "pauseStoped",

    /** Kein Zustand, keine Synchronisation mit dem Server */
    None = "none",
}

export class AttendanceClock {
    private clockAttendanceState: AttendanceClockState = AttendanceClockState.None;
    private clockPauseState: AttendanceClockState = AttendanceClockState.None;

    private startTimeAttendanceInMinutes: number = -1;
    private startTimePauseInMinutes: number = -1;

    private attendanceDuration: number = -1;
    private pauseDuration: number = -1;
    /** Datum und Uhrzeit an dem die Stoppuhr gestarted wurde.
     *  Kann null sein. */
    //private startedDateTime : BCSDate;
    private startedAttendanceDateTime: BCSDate;
    private startedPauseDateTime: BCSDate;
    private stopedDateTime: BCSDate;

    constructor(jsonObject: Object) {
        if (jsonObject.hasOwnProperty("attendanceRunning")) {
            if (jsonObject["pauseRunning"] === "true") {
                this.clockPauseState = AttendanceClockState.PauseRunning;
                this.startTimePauseInMinutes = parseInt(jsonObject["pauseStartTime"]);
                this.startTimeAttendanceInMinutes = parseInt(jsonObject["attendanceStartTime"]);

                this.startedPauseDateTime = BCSDate.getDateFromStamp(jsonObject["pauseStartDate"]);
                this.startedPauseDateTime.setMinutes(this.startTimePauseInMinutes);

                this.startedAttendanceDateTime = BCSDate.getDateFromStamp(
                    jsonObject["attendanceStartDate"],
                );
                this.startedAttendanceDateTime.setMinutes(this.startTimeAttendanceInMinutes);
            } else {
                this.clockPauseState = AttendanceClockState.None;
            }

            if (jsonObject["attendanceRunning"] === "true") {
                this.clockAttendanceState = AttendanceClockState.AttandanceRuning;
                this.startTimeAttendanceInMinutes = parseInt(jsonObject["attendanceStartTime"]);
                this.startedAttendanceDateTime = BCSDate.getDateFromStamp(
                    jsonObject["attendanceStartDate"],
                );
                this.startedAttendanceDateTime.setMinutes(this.startTimeAttendanceInMinutes);
            } else {
                this.clockAttendanceState = AttendanceClockState.None;
            }
        }
        this.attendanceDuration = parseInt(jsonObject["attendanceSum"]);
        this.pauseDuration = parseInt(jsonObject["pauseSum"]);
    }

    public adjustNewServerInformation(jsonObject: Object) {
        if (jsonObject["pauseRunning"] === "true") {
            this.startTimePauseInMinutes = parseInt(jsonObject["pauseStartTime"]);
            this.startTimeAttendanceInMinutes = parseInt(jsonObject["attendanceStartTime"]);

            this.startedPauseDateTime = BCSDate.getDateFromStamp(jsonObject["pauseStartDate"]);
            this.startedPauseDateTime.setMinutes(this.startTimePauseInMinutes);

            this.startedAttendanceDateTime = BCSDate.getDateFromStamp(
                jsonObject["attendanceStartDate"],
            );
            this.startedAttendanceDateTime.setMinutes(this.startTimeAttendanceInMinutes);
        }

        if (jsonObject["attendanceRunning"] === "true") {
            this.startTimeAttendanceInMinutes = parseInt(jsonObject["attendanceStartTime"]);
            this.startedAttendanceDateTime = BCSDate.getDateFromStamp(
                jsonObject["attendanceStartDate"],
            );
            this.startedAttendanceDateTime.setMinutes(this.startTimeAttendanceInMinutes);
        }

        this.attendanceDuration = parseInt(jsonObject["attendanceSum"]);
        this.pauseDuration = parseInt(jsonObject["pauseSum"]);
    }

    public startAttendance(): AttendanceClock {
        this.clockAttendanceState = AttendanceClockState.AttandanceStarted;
        this.startedAttendanceDateTime = BCSDate.getToday();
        return this;
    }

    public stopAttendance(): AttendanceClock {
        this.clockAttendanceState = AttendanceClockState.AttandanceStoping;
        this.stopedDateTime = BCSDate.getToday();
        return this;
    }

    public startPause(): AttendanceClock {
        this.clockPauseState = AttendanceClockState.PauseStarted;
        this.startedPauseDateTime = BCSDate.getToday();
        return this;
    }

    public stopPause(): AttendanceClock {
        this.clockPauseState = AttendanceClockState.PauseStoping;
        this.stopedDateTime = BCSDate.getToday();
        return this;
    }

    public getAttendanceState(): AttendanceClockState {
        return this.clockAttendanceState;
    }

    public getPauseState(): AttendanceClockState {
        return this.clockPauseState;
    }

    public getSavedAttendanceStartTimeInMinutes(): number {
        return this.startTimeAttendanceInMinutes;
    }

    public getSavedPauseStartTimeInMinutes(): number {
        return this.startTimePauseInMinutes;
    }

    public getAttendanceSum(): number {
        return this.attendanceDuration;
    }

    public getPauseSum(): number {
        return this.pauseDuration;
    }

    public getStartedAttendaneDateTime(): BCSDate {
        return this.startedAttendanceDateTime;
    }

    public getStartedPauseDateTime(): BCSDate {
        return this.startedPauseDateTime;
    }

    public getStopingTime(): BCSDate {
        return this.stopedDateTime;
    }

    /**
     * Die Anwesenheitsstopuhr wurde erfolgreich zum Server transferiert.
     * Daher ändert sich der Status je nach dem von started in running oder von stoping in stoped.
     */
    public transferedState(): void {
        if (this.clockAttendanceState == AttendanceClockState.AttandanceStarted) {
            this.clockAttendanceState = AttendanceClockState.AttandanceRuning;
        } else if (this.clockAttendanceState == AttendanceClockState.AttandanceStoping) {
            this.clockAttendanceState = AttendanceClockState.AttandanceStoped;
        } else if (this.clockPauseState == AttendanceClockState.PauseStarted) {
            this.clockPauseState = AttendanceClockState.PauseRunning;
        } else if (this.clockPauseState == AttendanceClockState.PauseStoping) {
            this.clockPauseState = AttendanceClockState.PauseStoped;
        }
    }
}
