import { AppConsole } from "../../../common/log/AppConsole";
import { HttpRESTClient } from "../../../util/http/HttpRESTClient";

export class AttendanceClockClient {
    private eventCallback: Promise<object>;

    constructor() {}

    public fetchAttendanceClock(age): Promise<object> {
        return new Promise(function (resolve, reject) {
            return new HttpRESTClient()
                .createRequest()
                .setMethod("GET")
                .addPath("/attendanceclock/clockstate")
                .setParameter("age", age)
                .send()
                .then(function (result: object) {
                    switch (result["status"]) {
                        case 200:
                            resolve(result["json"]);
                            break;
                        case 204:
                            resolve([]);
                            break;
                        default:
                            reject({ error: result["status"] });
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    public sendStartAttendanceClock(transportObject: object): Promise<object> {
        return new Promise(function (resolve, reject) {
            return new HttpRESTClient()
                .createRequest()
                .setMethod("POST")
                .addPath("/attendanceclock/startclock")
                .setJSONBody(transportObject)
                .send()
                .then(function (result: object) {
                    switch (result["status"]) {
                        case 200:
                            result["success"] = true;
                            resolve(result);
                            break;
                        case 204:
                            resolve([]);
                            break;
                        default:
                            reject({ error: result["status"] });
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    public sendStopAttendanceClock(transportObject: object): Promise<object> {
        return new Promise(function (resolve, reject) {
            return new HttpRESTClient()
                .createRequest()
                .setMethod("POST")
                .addPath("/attendanceclock/stopclock")
                .setJSONBody(transportObject)
                .send()
                .then(function (result: object) {
                    switch (result["status"]) {
                        case 200:
                            result["success"] = true;
                            resolve(result);
                        case 204:
                            resolve([]);
                            break;
                        case 409:
                            AppConsole.log(result);
                            reject({ error: result["status"] });
                        default:
                            AppConsole.log(result);
                            reject({ error: result["status"] });
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }
}
