import { Log } from "../../../common/log/Log";
import { GUIElement } from "../../../gui/GUIElement";

export type fileSelectedCallbackType = (imageData: string, fileName: string) => void;

export class FileUploadField implements GUIElement {
    private fileSelectedCallback: fileSelectedCallbackType;

    private $photoForm: JQuery;

    private $uploadField: JQuery;

    public onFileUpload(fileUploadCallback: fileSelectedCallbackType): FileUploadField {
        this.fileSelectedCallback = fileUploadCallback;
        return this;
    }

    public compose($parentTag: JQuery): void {
        this.$photoForm = $("<form>").appendTo($parentTag);

        this.composeUploadField();
    }

    private composeUploadField(): void {
        this.$uploadField = $("<input>")
            .appendTo(this.$photoForm)
            .attr({
                id: "fileUpload",
                type: "file",
                name: "voucherphoto",
                accept: "image/*,application/pdf",
            })
            .css("display", "none")
            .on("change", this.readFile.bind(this));
    }

    public selectFile(): void {
        const self = this;
        window.setTimeout(() => self.$uploadField.click(), 10);
    }

    private readFile(uploadEvent: Event): void {
        uploadEvent.preventDefault();
        uploadEvent.stopPropagation();

        const input = uploadEvent.currentTarget;
        if (input["files"] && input["files"][0]) {
            const file = input["files"][0];
            Log.debug("File Data URL for Debugging: ", file);

            const fileReader = new FileReader();
            fileReader.addEventListener("load", (fileReadEvent) => {
                const imageData = fileReadEvent.target["result"] as string;

                this.$uploadField.remove();
                this.composeUploadField();

                this.fileSelectedCallback(imageData, file.name);
            });

            fileReader.readAsDataURL(file);
            Log.debug("File after Reader for Debugging: ", fileReader.result.toString);
        }
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}
