export class TimeAttibutesDefinitions {
    private start: string;
    private end: string;
    private duration: string;

    public constructor(start: string, end: string, duration: string) {
        this.start = start;
        this.end = end;
        this.duration = duration;
    }

    public getStart(): string {
        return this.start;
    }

    public getEnd(): string {
        return this.end;
    }

    public getDuration(): string {
        return this.duration;
    }
}
