import { GUIElement } from "../GUIElement";
import { FormField, formFieldChangeEventType, FormFieldMode } from "./FormField";
import { StringValue } from "../../entities/values/StringValue";
import { FormFieldAddOn } from "./FormFieldAddOn";

export class OptionField implements GUIElement, FormField {
    private entityId: string;

    private name: string;

    private value: StringValue;

    private label: string;

    private placeholder: string;

    private options: { label: string; value: string }[] = [];

    private unselectedOption: { label: string; value: string; disable: boolean };

    private emptyValueName: string;

    private mode: FormFieldMode = FormFieldMode.EDIT;

    private changeCallback: (changeEvent: formFieldChangeEventType) => void;

    private $selectTag: JQuery;

    private $optionTags: { [key: string]: JQuery } = {};

    private readOnly: boolean = false;

    public setEntityId(entityId: string): FormField {
        this.entityId = entityId;
        return this;
    }

    public setName(name: string): OptionField {
        this.name = name;
        return this;
    }

    public setReadOnly(readOnly: boolean): OptionField {
        this.readOnly = readOnly;
        return this;
    }

    public isReadOnly(): boolean {
        return this.readOnly;
    }

    public getName(): string {
        return this.name;
    }

    public setValue(value: StringValue): OptionField {
        this.value = value;
        return this;
    }

    public setLabel(label: string): OptionField {
        this.label = label;
        return this;
    }

    public setPlaceholder(placeholder: string): OptionField {
        this.placeholder = placeholder;
        return this;
    }

    public showUnselectedOption(
        doShowUnselectedOption: boolean,
        doDisableUnselectedOption: boolean,
        label: string,
        value = "*",
    ): OptionField {
        this.unselectedOption = doShowUnselectedOption
            ? { label: label, value: value, disable: doDisableUnselectedOption }
            : null;
        return this;
    }

    public setOptions(options: { label: string; value: string }[]): OptionField {
        this.options = options;
        return this;
    }

    public setEmptyValueName(emptyValueName: string): OptionField {
        this.emptyValueName = emptyValueName;
        return this;
    }

    public setMode(mode: FormFieldMode): FormField {
        this.mode = mode;
        return this;
    }

    public getMode(): FormFieldMode {
        return this.mode;
    }

    public registerFormFieldAddOn(formFieldAddOn: FormFieldAddOn): FormField {
        // untetstützt bislang bislang keine AddOns
        return this;
    }

    public onChange(changeCallback: (changeEvent: formFieldChangeEventType) => void): OptionField {
        this.changeCallback = changeCallback;
        return this;
    }

    public compose($parent: JQuery): void {
        if (this.readOnly) {
            this.composeDisplayMode($parent);
            return;
        }

        switch (this.mode) {
            case FormFieldMode.DISPLAY:
                this.composeDisplayMode($parent);
                break;
            case FormFieldMode.EDIT:
            default:
                this.composeEditMode($parent);
                break;
        }
    }

    private composeDisplayMode($parent: JQuery): void {
        let selectedOptionLabel = "";
        if (this.value && this.value.isDefined()) {
            for (let i = 0; i < this.options.length; i++) {
                if (this.options[i].value == this.value.getString()) {
                    selectedOptionLabel = this.options[i].label;
                }
            }

            // Aktueller Wert nicht (mehr) in Optionen vorhanden?
            // Wenigsten Wert selbst anzeigen, damit Feld nicht undefiniert aussieht.
            if (selectedOptionLabel.length === 0) {
                selectedOptionLabel = this.value.getString();
            }
        }

        // Falls Optionsattribut (im DisplayMode) nur als Link zu einer Auswahlseite angezeigt wird,
        // bei undefiniertem Wert, Platzhalter-Label "[Attributname]" anzeigen.
        if (
            (!selectedOptionLabel || selectedOptionLabel.length === 0) &&
            this.emptyValueName &&
            this.readOnly
        ) {
            selectedOptionLabel = this.emptyValueName;
        }

        $("<span>").appendTo($parent).text(selectedOptionLabel);
    }

    private composeEditMode($parent: JQuery): void {
        this.$selectTag = $("<select>")
            .appendTo($parent)
            .attr({
                name: this.name,
            })
            .addClass("formfield")
            .on("change", this.valueChanged.bind(this));

        const options = this.options.slice();
        if (this.unselectedOption) {
            options.unshift(this.unselectedOption);
        }

        let isAnyOptionSelected = false;
        for (let i = 0; i < options.length; i++) {
            isAnyOptionSelected =
                isAnyOptionSelected || options[i].value === this.value.getString();
        }

        for (let i = 0; i < options.length; i++) {
            const isSelected = options[i].value === this.value.getString();

            this.$optionTags[options[i].value] = $("<option>")
                .appendTo(this.$selectTag)
                .attr({
                    value: options[i].value,
                    selected: isSelected ? "selected" : null,
                    // Falls erste Option das Attributlabel zeigt, wird sie disabled, um nicht auswählbar zu sein.
                    disabled:
                        isAnyOptionSelected &&
                        i == 0 &&
                        this.unselectedOption &&
                        this.unselectedOption.disable
                            ? "disabled"
                            : null,
                })
                .text(options[i].label);
        }
    }

    public updateValue(value: StringValue): OptionField {
        if (this.mode == FormFieldMode.EDIT && this.value != value && this.value.isDefined()) {
            this.value = value;
            this.$optionTags[this.value.getString()].prop("selected", true);

            // Erste Option ist der Attributlabel.
            // Diese erste Option wird nach Auswahl einer Option disabled, damit sie nicht wieder ausgewählt werden kann.
            if (
                this.unselectedOption &&
                this.unselectedOption.disable &&
                this.value.getString() != this.unselectedOption.value
            ) {
                this.$optionTags[this.unselectedOption.value].attr("disabled", "disabled");
            }
        }
        return this;
    }

    public getValue(): StringValue {
        const value = this.$selectTag.val() + ""; // TODO App - was möchte der Abfrage bei keinem ausgeäwhltem Wert bekommen?

        // Sonderbehandlung: Auswahl "Keine Auswahl"
        if (this.unselectedOption && value == this.unselectedOption.value) {
            value == null;
        }

        return new StringValue(value);
    }

    public updateRequired(): void {
        // Nach Auswahl eines Wertes: Markierung als Pflichtfeld entfernen
        if (this.getValue().isDefined()) {
            this.$selectTag.closest(".required").addClass("notRequired");
        } else {
            this.$selectTag.closest(".required").removeClass("notRequired");
        }
    }

    private valueChanged(event: Event): void {
        if (this.changeCallback) {
            event.preventDefault();
            event.stopPropagation();

            this.changeCallback({
                entityId: this.entityId,
                name: this.getName(),
                value: this.getValue(),
            });

            this.updateRequired();
        }
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}
