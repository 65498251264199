import { ErrorMessageBox } from "../gui/messages/ErrorMessageBox";
import { I18nFallBackLabels } from "../common/i18n/I18nFallBackLabels";

export class AppRequirementCheck {
    public static checkRequirements(): void {
        this.checkSecureHTTPConnection();
    }

    private static checkSecureHTTPConnection(): void {
        if (
            !navigator.serviceWorker &&
            !window["applicationCache"] &&
            window.location.href.indexOf("_online") < 0
        ) {
            if (window.location.href.substr(0, 5) != "https") {
                // Keine sichere Verbindung, daher wahrscheinlich ServiceWorker nicht verfügbar
                AppRequirementCheck.showErrorBox("SecureHTTPConnectionRequired");
                throw Error(
                    "BCS Mobile App requires a secure HTTP connection with a valid certificate!",
                );
            } else {
                // Browser unterstürtzt kein ServiceWorker
                AppRequirementCheck.showErrorBox("OfflineModeNotSupported");
                throw Error("Browser not supported (no support of ServiceWorker)");
            }
        }
    }

    private static showErrorBox(errorKey: string): void {
        const i18nLabels = I18nFallBackLabels.getFallbackLabels(
            I18nFallBackLabels.getFallbackLanguage(),
        );
        const errorMessage =
            i18nLabels["MobileApp.txtError.AppRequirementCheck." + errorKey] || errorKey;

        new ErrorMessageBox().setMessage(errorMessage).compose($("body"));
    }
}
