import { HttpURL } from "./HttpURL";
import { HTTPError } from "./HTTPError";
import { Log } from "../../common/log/Log";

export type xhrProgressType = (isDownload: boolean, loaded: number, total: number) => void;

export class HttpRESTRequest {
    private url: HttpURL;

    private method: string;

    private contentType: string;

    private progressCallback: xhrProgressType;

    private data: any;

    /** Timeout eines REST-Requests (in ms, Standard 180s)*/
    private timeout: number = 180000;

    constructor(baseURL: HttpURL) {
        this.url = baseURL.copy();
        this.url.clearParameters();
        this.method = "GET";
        this.contentType = "text/plain; charset=utf-8";
    }

    public setMethod(method): HttpRESTRequest {
        this.method = method;
        return this;
    }

    public addPath(subPath): HttpRESTRequest {
        this.url.addPath(subPath);
        return this;
    }

    public setParameter(name, value): HttpRESTRequest {
        if (typeof value != "undefined" && value != null) {
            this.url.setParameter(name, value);
        }
        return this;
    }

    public setAllParameters(parameters): HttpRESTRequest {
        if (parameters) {
            this.url.setAllParameters(parameters);
        }
        return this;
    }

    public setTextBody(text): HttpRESTRequest {
        if (text) {
            this.data = text;
            this.contentType = "text/plain; charset=utf-8";
        }
        return this;
    }

    public setJSONBody(dataObject): HttpRESTRequest {
        if (dataObject) {
            this.data = JSON.stringify(dataObject);
            this.contentType = "application/json; charset=utf-8";
        }
        return this;
    }

    public setBinary(binaryData, contentType): HttpRESTRequest {
        if (binaryData) {
            this.data = binaryData;
            this.contentType = contentType || "application/binary";
        }
        return this;
    }

    /**
     * @param timeout  Timeout dieses REST-Requests (in ms)
     */
    public setTimeout(timeout: number): HttpRESTRequest {
        this.timeout = timeout;
        return this;
    }

    public onProgress(progressCallback: xhrProgressType): HttpRESTRequest {
        this.progressCallback = progressCallback;
        return this;
    }

    private notifyProgress(
        isDownload: boolean,
        lengthComputable: boolean,
        loaded: number,
        total: number,
    ): void {
        if (lengthComputable) {
            this.progressCallback(isDownload, loaded, total);
        }
    }

    /**
     * Liest das token aus dem Cookie aus.
     */
    private static getCsrfTokenFromCookie(): string {
        let token = "";
        const cookieString = decodeURIComponent(document.cookie);
        cookieString.split(";").forEach((cookie) => {
            cookie = cookie.trim();
            if (cookie.split("=")[0] === "CSRF_Token") {
                token = cookie.split("CSRF_Token=")[1];
            }
        });
        return token;
    }

    public send(): Promise<object> {
        const self = this;

        return new Promise((resolve, reject) => {
            $.ajax({
                type: self.method,
                url: self.url.toURL(),
                data: self.data,
                beforeSend: function (xhr) {
                    // CSRF senden
                    xhr.setRequestHeader("X-CSRF-Token", HttpRESTRequest.getCsrfTokenFromCookie());
                },
                contentType: self.contentType, // Request Content = text or json
                dataType: "text", // Response Content = text or json
                processData: false,
                success: function (responseText, success, response) {
                    const result = {
                        status: response.status,
                        responseText: responseText,
                        response: response,
                    };
                    if (
                        responseText &&
                        responseText.length > 1 &&
                        responseText.substring(0, 1) == "{"
                    ) {
                        result["json"] = JSON.parse(responseText);
                    }
                    resolve(result);
                },
                error: function (response, errorcode, error) {
                    if (response.status != 401) {
                        Log.error("[HttpRESTRequest] Error", {
                            response: JSON.stringify(response),
                            errorcode: JSON.stringify(errorcode),
                            error: JSON.stringify(error),
                        });
                        //AppConsole.log("[HttpRESTRequest] Error: ", JSON.stringify(response), JSON.stringify(errorcode), JSON.stringify(error));
                    }
                    reject(new HTTPError(errorcode, error, response, response.status));
                },
                xhr: function () {
                    const xhr = new window.XMLHttpRequest();

                    if (self.progressCallback) {
                        xhr.upload.addEventListener("progress", function (event) {
                            self.notifyProgress(
                                false,
                                event.lengthComputable,
                                event.loaded,
                                event.total,
                            );
                        });
                        xhr.addEventListener("progress", function (event) {
                            self.notifyProgress(
                                true,
                                event.lengthComputable,
                                event.loaded,
                                event.total,
                            );
                        });
                    }

                    return xhr;
                },
                // Timeout dieses Requests (in ms)
                timeout: self.timeout,
            });
        });
    }
}
