import { HttpRESTClient } from "../../../util/http/HttpRESTClient";
import { BCSDate } from "../../../common/BCSDate";

export class TimeSpanClient {
    public fetchTimeSpans(
        syncStateTimestamp,
    ): Promise<{ timespans: object[]; syncStateTimestamp: number }> {
        return new Promise((resolve, reject) => {
            new HttpRESTClient()
                .createRequest()
                .setMethod("GET")
                .addPath("/timerecording/timespans")
                .setParameter("syncStateTimestamp", syncStateTimestamp)
                .setParameter("minDate", BCSDate.getToday().subtractMonth(1).getISODate())
                .send()
                .then((result) => {
                    switch (result["status"]) {
                        case 200:
                            const syncStateTimestamp: number = result["json"]["syncStateTimestamp"];
                            var resultObject = {
                                timespans: result["json"]["timespans"],
                                syncStateTimestamp: syncStateTimestamp,
                            };
                            resolve(resultObject);
                            break;
                        case 204:
                            resolve(null);
                            break;
                        default:
                            reject(result);
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    public saveTimeSpan(age, transportObject: object): Promise<object> {
        return new Promise((resolve, reject) => {
            new HttpRESTClient()
                .createRequest()
                .setMethod("POST")
                .addPath("/timerecording/saveTimeSpan")
                .setParameter("age", age)
                .setJSONBody(transportObject)
                .send()
                .then((result) => {
                    switch (result["status"]) {
                        case 200:
                            resolve(result["json"]);
                            break;
                        case 204:
                            resolve({});
                            break;
                        default:
                            reject(result);
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    public deleteTimespan(age, transportObject: object): Promise<object> {
        return new Promise((resolve, reject) => {
            new HttpRESTClient()
                .createRequest()
                .setMethod("POST")
                .addPath("/timerecording/deleteTimespan")
                .setParameter("age", age)
                .setJSONBody(transportObject)
                .send()
                .then((result) => {
                    switch (result["status"]) {
                        case 200:
                            resolve(result["json"]);
                            break;
                        case 204:
                            resolve({});
                            break;
                        default:
                            reject(result);
                    }
                })
                .catch((errorInformations) => {
                    reject(errorInformations);
                });
        });
    }
}
