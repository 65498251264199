import { IndexedDBError } from "../IndexedDBError";

export class IndexedDBCountRequest {
    private storeName: string;
    private indexName: string;
    private query: IDBKeyRange;
    private transaction: IDBTransaction;

    constructor(
        transaction: IDBTransaction,
        storeName: string,
        indexName: string,
        query: IDBKeyRange,
    ) {
        this.transaction = transaction;
        this.storeName = storeName;
        this.indexName = indexName;
        this.query = query;
    }

    public then(successCallback, errorCallback) {
        const self = this;
        let request;
        if (this.indexName) {
            const index = this.transaction.objectStore(this.storeName).index(this.indexName);
            request = this.query ? index.count(this.query) : index.count();
        } else {
            const store = this.transaction.objectStore(this.storeName);
            request = this.query ? store.count(this.query) : store.count();
        }
        request.onsuccess = function (event) {
            self.success(event, successCallback);
        };
        request.onerror = function (event) {
            self.error(event, errorCallback);
        };
    }

    private success(event, successCallback) {
        successCallback.call(this, { count: event.target.result });
    }

    private error(event, errorCallback) {
        // TODO: Jens , testen ob undefined funktioniert
        errorCallback.call(this, new IndexedDBError(event, undefined).setQuery(this.query));
    }
}
