import * as d3 from "d3";
import { PieChartEntry } from "./PieChartEntry";

export class PieChart {
    private chartHeight: number = 100;
    private chartWidth: number = 100;
    private svg;
    private pieDataEntries: object[] = [];

    private name: string = "noName";

    public setData(entry: PieChartEntry): void {
        this.pieDataEntries.push(entry.toObject());
    }

    constructor(width: number, height: number, name: string) {
        this.chartWidth = width;
        this.chartHeight = height;
        this.name = name;
    }

    public draw(svg): void {
        this.svg = svg;
        svg.attr("width", this.chartWidth);
        svg.attr("height", this.chartHeight);

        if (this.pieDataEntries.length > 0) {
            this.drawChart(this.pieDataEntries);
        } else {
            throw new Error("No PieEntries for drawing.");
        }
    }

    private drawChart(data) {
        const arcs = d3
            .pie()
            .sort(null)
            .value(function (d: any) {
                return d.value;
            })(data);

        const arc: any = d3
            .arc()
            .outerRadius(this.chartHeight / 2)
            .innerRadius(this.chartHeight / 2 - 10)
            .padAngle(0.03)
            .cornerRadius(0);

        const pieG = d3
            .select("svg." + this.name)
            .selectAll("g")
            .data([data])
            .enter()
            .append("g")
            .attr("transform", "translate(" + [this.chartWidth / 2, this.chartHeight / 2] + ")");

        const block = pieG.selectAll(".arc").data(arcs);

        const newBlock = block.enter().append("g").classed("arc", true);

        newBlock
            .append("path")
            .attr("d", arc)
            .attr("id", function (d, i) {
                return "arc-" + i;
            })
            /*.attr("stroke", "gray")*/
            .attr("fill", function (d, i) {
                //AppConsole.log(d.data.color);
                return d.data["color"];
                //return d3.interpolateCool(Math.random())
            });

        newBlock
            .append("text")
            .attr("dx", 55)
            .attr("dy", -5)
            .append("textPath")
            .attr("xlink:href", function (d, i) {
                return "#arc-" + i;
            })
            .text(function (d) {
                //AppConsole.log(d:any);
                return d.data["name"];
            });
    }
}
