import { ListRowModel } from "../../../gui/list/ListRowModel";
import { Allowance } from "../records/Allowance";
import { SchemaDataType } from "../../../common/schema/SchemaDataType";
import { EntityValue } from "../../../entities/values/EntityValue";
import { AttributeDefinition } from "../../../common/schema/AttributeDefinition";
import { StringValue } from "../../../entities/values/StringValue";
import { BusinessTravel } from "../records/BusinessTravel";
import { I18n } from "../../../common/i18n/I18n";
import { DailyAllowance } from "../records/DailyAllowance";

export class AllowanceListRowModel implements ListRowModel {
    private allowance: Allowance;

    private i18n: I18n;

    private defaultModelAttribute: AllowanceAttribute;

    private virtualModelAttributes: { [key: string]: ModelAttribute } = {};

    constructor(allowance: Allowance, i18n: I18n) {
        this.allowance = allowance;
        this.i18n = i18n;

        this.defaultModelAttribute = new AllowanceAttribute(allowance);
        this.virtualModelAttributes[AllowanceStartEndInfo.NAME] = new AllowanceStartEndInfo(
            allowance,
            this.i18n,
        );
        this.virtualModelAttributes[AllowanceCountVouchersInfo.NAME] =
            new AllowanceCountVouchersInfo(allowance, this.i18n);
        this.virtualModelAttributes[AllowanceMealsCountInfo.NAME] = new AllowanceMealsCountInfo(
            allowance,
            this.i18n,
        );
    }

    public getEntityId(name?: string): string {
        return this.allowance.getId();
    }

    public getDataType(name: string): SchemaDataType {
        return this.getAttributeDefinition(name).getDataType();
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return (
            this.virtualModelAttributes[name] || this.defaultModelAttribute
        ).getAttributeDefinition(name);
    }

    public getValue(name: string): EntityValue {
        return (this.virtualModelAttributes[name] || this.defaultModelAttribute).getValue(name);
    }

    public setValue(name: string, value: EntityValue): void {
        this.allowance.setValue(name, value);
    }

    public isEditable(): boolean {
        return this.allowance.isEditable();
    }

    public isDeletable(): boolean {
        return this.allowance.isDeletable();
    }
}

interface ModelAttribute {
    getAttributeDefinition(name: string): AttributeDefinition;

    getValue(name: string): EntityValue;
}

class AllowanceAttribute implements ModelAttribute {
    private allowance: Allowance;

    constructor(allowance: Allowance) {
        this.allowance = allowance;
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return this.allowance.getAttributeDefinition(name);
    }

    public getValue(name: string): EntityValue {
        return this.allowance.getValue(name);
    }
}

class AllowanceStartEndInfo implements ModelAttribute {
    public static readonly NAME = "allowanceStartEndInfo";

    private allowance: Allowance;

    private i18n: I18n;

    constructor(allowance: Allowance, i18n: I18n) {
        this.allowance = allowance;
        this.i18n = i18n;
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return new AttributeDefinition(
            BusinessTravel.TYPE,
            this.allowance.getSubtype(),
            AllowanceStartEndInfo.NAME,
            { dataType: SchemaDataType.STRING },
        );
    }

    public getValue(name: string): EntityValue {
        const formattedStartDate = (<BusinessTravel>this.allowance)
            .getStartDateTime()
            .formatShortDate();
        const formattedEndDate = (<BusinessTravel>this.allowance)
            .getEndDateTime()
            .formatShortDate();

        return new StringValue(formattedStartDate + " - " + formattedEndDate);
    }
}

class AllowanceCountVouchersInfo implements ModelAttribute {
    public static readonly NAME = "allowanceCountVouchersInfo";

    private allowance: Allowance;

    private i18n: I18n;

    constructor(allowance: Allowance, i18n: I18n) {
        this.allowance = allowance;
        this.i18n = i18n;
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return new AttributeDefinition(
            BusinessTravel.TYPE,
            this.allowance.getSubtype(),
            AllowanceCountVouchersInfo.NAME,
            { dataType: SchemaDataType.STRING },
        );
    }

    public getValue(name: string): EntityValue {
        const countVoucherAllowances = (<BusinessTravel>this.allowance).countVocherAllowances();
        const vocherLabel = this.i18n.get(
            "MobileApp.JAllowance.voucherAllowance" + (countVoucherAllowances === 1 ? "" : "s"),
        );

        return new StringValue(countVoucherAllowances + " " + vocherLabel);
    }
}

class AllowanceMealsCountInfo implements ModelAttribute {
    public static readonly NAME = "allowanceMealsCountInfo";

    private allowance: Allowance;

    private i18n: I18n;

    constructor(allowance: Allowance, i18n: I18n) {
        this.allowance = allowance;
        this.i18n = i18n;
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return new AttributeDefinition(
            BusinessTravel.TYPE,
            this.allowance.getSubtype(),
            AllowanceMealsCountInfo.NAME,
            { dataType: SchemaDataType.STRING },
        );
    }

    public getValue(name: string): EntityValue {
        const mealSums: { [key: string]: number } = (<DailyAllowance>this.allowance).countMeals();

        return new StringValue(
            mealSums.hasBreakfast + "-" + mealSums.hasLunch + "-" + mealSums.hasDinner,
        );
    }
}
