import { GUIElement } from "../GUIElement";
import { FormField, formFieldChangeEventType, FormFieldMode } from "./FormField";
import { StringField } from "./StringField";
import { StringValue } from "../../entities/values/StringValue";
import { DurationValue } from "../../entities/values/DurationValue";
import { FormFieldAddOn } from "./FormFieldAddOn";
import { BCSDate, DurationTriple } from "../../common/BCSDate";
import { I18n } from "../../common/i18n/I18n";
import { InputModes } from "./InputModes";

enum DurationFormat {
    minutes = 1,
    hoursMinutes = 2,
    daysHoursMinutes = 3,
}

/** DurationField mit Tagen. Kann an Fields vom Typ Duration durch additionalParameters "duration.alwaysShowDays":"true" verwendet werden */
export class LongDurationField implements GUIElement, FormField {
    public static isLongDurationFieldRequested(additionalFieldParameters): boolean {
        for (const parameter in additionalFieldParameters) {
            if (parameter === "duration.alwaysShowDays") {
                return additionalFieldParameters[parameter];
            }
        }
    }

    private i18n: I18n;

    private daysField: StringField;
    private timeField: StringField;

    private changeCallback: (changeEvent: formFieldChangeEventType) => void;

    constructor(i18n: I18n) {
        this.i18n = i18n;
        this.daysField = new StringField()
            .setType("number")
            .setInputmode(InputModes.INT)
            .onChange(this.stringValueChanged.bind(this));
        this.timeField = new StringField()
            .setType("time")
            .onChange(this.stringValueChanged.bind(this));
    }

    public setEntityId(entityId: string): FormField {
        this.daysField.setEntityId(entityId);
        this.timeField.setEntityId(entityId);
        return this;
    }

    public setName(name: string): LongDurationField {
        this.daysField.setName(name);
        this.timeField.setName(name);
        return this;
    }

    public setReadOnly(readOnly: boolean): LongDurationField {
        this.timeField.setReadOnly(readOnly);
        return this;
    }

    public isReadOnly(): boolean {
        return this.timeField.isReadOnly();
    }

    public getName(): string {
        return this.timeField.getName();
    }

    public setValue(value: DurationValue): LongDurationField {
        let values: DurationTriple = this.convertToDaysHoursMinutes(value.formatWithDays());
        values = BCSDate.adjustDayHourMinuteTriple(values);
        const dayStringValue = new StringValue(values.getDaysString());
        this.daysField.setValue(dayStringValue);
        const timeStringValue = new StringValue(
            values.getHoursString() + ":" + values.getMinutesString(),
        );
        this.timeField.setValue(timeStringValue);
        return this;
    }

    /**
     *
     * @param rawValue
     */
    private convertToDaysHoursMinutes(rawValue: string): DurationTriple {
        const result: DurationTriple = new DurationTriple();

        const splitValue: string[] = rawValue.split(":").reverse();
        const format = splitValue.length;

        if (format >= DurationFormat.minutes) {
            result.minutes = parseInt(splitValue[0]);
        }
        if (format >= DurationFormat.hoursMinutes) {
            result.hours += parseInt(splitValue[1]);
        }
        if (format >= DurationFormat.daysHoursMinutes) {
            result.days += parseInt(splitValue[2]);
        }

        return result;
    }

    public setLabel(label: string): LongDurationField {
        this.daysField.setLabel(label);
        this.timeField.setLabel(label);
        return this;
    }

    public setPlaceholder(placeholder: string): LongDurationField {
        this.daysField.setPlaceholder("0");
        this.timeField.setPlaceholder(placeholder);
        return this;
    }

    public setMode(mode: FormFieldMode): FormField {
        this.daysField.setMode(mode);
        this.timeField.setMode(mode);
        return this;
    }

    public getMode(): FormFieldMode {
        return this.timeField.getMode();
    }

    public registerFormFieldAddOn(formFieldAddOn: FormFieldAddOn): FormField {
        // unterstützt bislang bislang keine AddOns
        return this;
    }

    public onChange(
        changeCallback: (changeEvent: formFieldChangeEventType) => void,
    ): LongDurationField {
        this.changeCallback = changeCallback;
        return this;
    }

    public compose($parent: JQuery): void {
        const $container = $("<span>").appendTo($parent).addClass("longDurationField");
        this.daysField.compose($container);
        this.timeField.compose($container);
    }

    public updateValue(value: DurationValue): LongDurationField {
        let values: DurationTriple = this.convertToDaysHoursMinutes(value.formatWithDays());
        values = BCSDate.adjustDayHourMinuteTriple(values);
        const dayStringValue = new StringValue(values.getDaysString());
        this.daysField.updateValue(dayStringValue);
        const timeStringValue = new StringValue(
            values.getHoursString() + ":" + values.getMinutesString(),
        );
        this.timeField.updateValue(timeStringValue);
        return this;
    }

    public getValue(): DurationValue {
        let days = this.daysField.getValue().getString();
        days = days === "" ? "0" : days;
        return DurationValue.parseFromStringWithDays(
            days + ":" + this.timeField.getValue().getString(),
        );
    }

    private stringValueChanged(changeEvent: formFieldChangeEventType): void {
        if (this.changeCallback) {
            this.changeCallback({
                entityId: changeEvent.entityId,
                name: this.getName(),
                value: this.getValue(),
            });
        }
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}
