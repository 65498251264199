export class IdGenerator {
    private static timestamp: number = new Date().getTime() - 1422745200000;

    private static oidPrefix = "";

    /**
     * Setzt den konfigurierte Präfix für alle in BCS generierten Oids.
     *
     * @param oidPrefix OdPrefix (Standard: "")
     */
    public static setOidPrefix(oidPrefix: string): void {
        IdGenerator.oidPrefix = oidPrefix;
    }

    /**
     * Returns a random integer between min (included) and max (included)
     * Using Math.round() will give you a non-uniform distribution!
     *
     * @param min min result (inclusive)
     * @param max max result (inclusive)
     */
    public static getRandomInt(min, max): number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    public static getTimestamp(): number {
        return this.timestamp++;
    }

    public static createId(): string {
        return (
            IdGenerator.oidPrefix +
            "app" +
            this.getTimestamp().toString(36) +
            this.getRandomInt(0, 999999).toString(36)
        );
    }

    /**
     * Generiert Zufalls UUID mit Datumsanteil
     * @see https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
     */
    public static createUUID(): string {
        let dt = new Date().getTime();
        if (typeof performance !== "undefined" && typeof performance.now === "function") {
            dt += performance.now(); // use high-precision timer if available
        }

        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            const r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
        });
    }
}
