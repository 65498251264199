import { Component } from "../core/Component";
import { SingletonComponent } from "../core/SingletonComponent";
import { IndexedDB } from "../database/IndexedDB";
import { UserSession } from "../common/auth/UserSession";
import { IndexedDBVersion } from "../database/IndexedDBVersion";
import { IndexedDBQuery } from "../database/IndexedDBQuery";
import { Registry } from "../core/Registry";
import { SyncState, SyncStateType, SyncStateObjectType } from "./SyncState";
import { resolve } from "path";

export class SyncStateManager implements Component, SingletonComponent {
    public static BCS_COMPONENT_NAME = "SyncStateManager";

    private static SYNC_STATE_STORE_NAME = "syncstates";

    private static INDEX_USER_TYPE = "user_type";

    private static INDEX_USER_STATE = "user_state";

    private static INDEX_USER_TYPE_STATE = "user_type_state";

    private indexedDB: IndexedDB;

    private userSession: UserSession;

    public getDependencyNames(): string[] {
        return [IndexedDB.BCS_COMPONENT_NAME, UserSession.BCS_COMPONENT_NAME];
    }

    public init(depencencyComponents: { [key: string]: Component }): void {
        this.userSession = <UserSession>depencencyComponents[UserSession.BCS_COMPONENT_NAME];
        this.indexedDB = <IndexedDB>depencencyComponents[IndexedDB.BCS_COMPONENT_NAME];

        this.indexedDB
            .registerStore(SyncStateManager.SYNC_STATE_STORE_NAME, IndexedDBVersion.DB_VERSION_1)
            .setIdKey("oid")
            .addIndex(
                SyncStateManager.INDEX_USER_TYPE,
                ["userOid", "objectType"],
                false,
                IndexedDBVersion.DB_VERSION_1,
            )
            .addIndex(
                SyncStateManager.INDEX_USER_STATE,
                ["userOid", "syncState"],
                false,
                IndexedDBVersion.DB_VERSION_1,
            )
            .addIndex(
                SyncStateManager.INDEX_USER_TYPE_STATE,
                ["userOid", "objectType", "syncState"],
                false,
                IndexedDBVersion.DB_VERSION_1,
            );
    }

    public notifyBeginUserSession(isOnline: boolean): Promise<void> {
        return Promise.resolve();
    }

    public async synchronize(): Promise<void> {}

    public start(): Promise<void> {
        return Promise.resolve();
    }

    public async getSyncStatesByIds(oids: string[]): Promise<SyncState[]> {
        const syncStates: SyncState[] = [];

        for (let i = 0; i < oids.length; i++) {
            syncStates.push(await this.getSyncStateById(oids[i]));
        }

        return syncStates;
    }

    public getSyncStateById(oid: string): Promise<SyncState> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([SyncStateManager.SYNC_STATE_STORE_NAME])
                .selectId(SyncStateManager.SYNC_STATE_STORE_NAME, oid)
                .then(
                    (result) => {
                        if (result && result.element) {
                            resolve(SyncState.fromValueObject(result.element));
                        } else {
                            resolve(null);
                        }
                    },
                    (error) => reject(error),
                );
        });
    }

    public async getOrCreateSyncStateById(
        oid: string,
        objectType: SyncStateObjectType,
    ): Promise<SyncState> {
        let syncState = await this.getSyncStateById(oid);
        if (!syncState) {
            const userOid = this.userSession.getCurrentUserOid();
            syncState = SyncState.forEntity(oid, objectType, SyncStateType.ChangesInApp, userOid);
        }
        return syncState;
    }

    public readTimeRecordingSynStateInDB(oid: string): Promise<SyncState> {
        return this.getSyncStateById(oid);
    }

    public readAllSyncStates(objectType: SyncStateObjectType): Promise<SyncState[]> {
        return this.readSyncStatesFromDB(null, objectType);
    }

    public async countUnsyncedElements(): Promise<number> {
        let count = await this.countSyncStates(SyncStateType.ChangesInApp);
        count += await this.countSyncStates(SyncStateType.SynchronisationIssue);
        count += await this.countSyncStates(SyncStateType.ErrorInObjectInApp);

        return count;
    }

    public async countSyncStatesByObjectType(): Promise<{
        [key: string]: { [key: string]: number };
    }> {
        const syncStatesWithChangesNumberByObjectType: {
            [key: string]: { [key: string]: number };
        } = {};

        for (const objectTypeKey in SyncStateObjectType) {
            const objectType = SyncStateObjectType[objectTypeKey];

            const numberWithChangesInApp = await this.countSyncStatesWithChangesInApp(
                <SyncStateObjectType>objectType,
            );
            const numberWithErrorInObjectInApp = await this.countSyncStatesWithErrorInObjectInApp(
                <SyncStateObjectType>objectType,
            );
            const numberWithSynchronisationIssue =
                await this.countSyncStatesWithSynchronisationIssue(<SyncStateObjectType>objectType);

            syncStatesWithChangesNumberByObjectType[objectType] = {};
            syncStatesWithChangesNumberByObjectType[objectType][SyncStateType.ChangesInApp] =
                numberWithChangesInApp;
            syncStatesWithChangesNumberByObjectType[objectType][SyncStateType.ErrorInObjectInApp] =
                numberWithErrorInObjectInApp;
            syncStatesWithChangesNumberByObjectType[objectType][
                SyncStateType.SynchronisationIssue
            ] = numberWithSynchronisationIssue;
        }

        return syncStatesWithChangesNumberByObjectType;
    }

    public countSyncStatesWithChangesInApp(objectType: SyncStateObjectType): Promise<number> {
        return this.countSyncStates(SyncStateType.ChangesInApp, objectType);
    }

    public countSyncStatesWithErrorInObjectInApp(objectType: SyncStateObjectType): Promise<number> {
        return this.countSyncStates(SyncStateType.ErrorInObjectInApp, objectType);
    }

    public countSyncStatesWithSynchronisationIssue(
        objectType: SyncStateObjectType,
    ): Promise<number> {
        return this.countSyncStates(SyncStateType.SynchronisationIssue, objectType);
    }

    private countSyncStates(
        syncStateType?: SyncStateType,
        objectType?: SyncStateObjectType,
    ): Promise<number> {
        const userOid = this.userSession.getCurrentUserOid();

        const index = this.choseIndex(syncStateType, objectType);
        const query = this.choseQuery(userOid, syncStateType, objectType);

        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([SyncStateManager.SYNC_STATE_STORE_NAME])
                .count(SyncStateManager.SYNC_STATE_STORE_NAME, index, query)
                .then(
                    (result) => resolve(result.count || 0),
                    (error) => reject(error),
                );
        });
    }

    public async readSyncAllStatesToBeSentToBCS(
        objectType: SyncStateObjectType,
    ): Promise<SyncState[]> {
        let syncStates: SyncState[] = [];
        syncStates = syncStates.concat(
            await this.readSyncStatesFromDB(SyncStateType.ChangesInApp, objectType),
        );
        syncStates = syncStates.concat(
            await this.readSyncStatesFromDB(SyncStateType.SynchronisationIssue, objectType),
        );
        return syncStates;
    }

    public async readSyncAllStatesWithErrors(
        objectType?: SyncStateObjectType,
    ): Promise<SyncState[]> {
        let syncStates: SyncState[] = [];
        syncStates = syncStates.concat(
            await this.readSyncStatesFromDB(SyncStateType.ErrorInObjectInApp, objectType),
        );
        syncStates = syncStates.concat(
            await this.readSyncStatesFromDB(SyncStateType.SynchronisationIssue, objectType),
        );
        return syncStates;
    }

    private readSyncStatesFromDB(
        syncStateType?: SyncStateType,
        objectType?: SyncStateObjectType,
    ): Promise<SyncState[]> {
        const userOid = this.userSession.getCurrentUserOid();

        const index = this.choseIndex(syncStateType, objectType);
        const query = this.choseQuery(userOid, syncStateType, objectType);

        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([SyncStateManager.SYNC_STATE_STORE_NAME])
                .selectCursor(SyncStateManager.SYNC_STATE_STORE_NAME, index, query)
                .then(
                    (result) => {
                        if (result && result.resultSet) {
                            const objectIds = result.resultSet.map((syncStateValueObject) =>
                                SyncState.fromValueObject(syncStateValueObject),
                            );
                            resolve(objectIds);
                        } else {
                            resolve([]);
                        }
                    },
                    (error) => reject(error),
                );
        });
    }

    private choseIndex(syncStateType?: SyncStateType, objectType?: SyncStateObjectType): string {
        let index: string;
        if (syncStateType && objectType) {
            index = SyncStateManager.INDEX_USER_TYPE_STATE;
        } else if (syncStateType) {
            index = SyncStateManager.INDEX_USER_STATE;
        } else if (objectType) {
            index = SyncStateManager.INDEX_USER_TYPE;
        } else {
            throw new Error(
                "[SyncStateManager] Cannot countSyncStates without syncStateType AND objectType",
            );
        }
        return index;
    }

    private choseQuery(
        userOid: string,
        syncStateType?: SyncStateType,
        objectType?: SyncStateObjectType,
    ): IDBKeyRange {
        let query: IDBKeyRange;
        if (syncStateType && objectType) {
            query = IndexedDBQuery.only([userOid, objectType, syncStateType]);
        } else if (syncStateType) {
            query = IndexedDBQuery.only([userOid, syncStateType]);
        } else if (objectType) {
            query = IndexedDBQuery.only([userOid, objectType]);
        } else {
            throw new Error(
                "[SyncStateManager] Cannot countSyncStates without syncStateType AND objectType",
            );
        }
        return query;
    }

    /**
     * Erstellt für erstmalig aus BCS importiert Objekte SyncStates (mit Status "Kein Änderungen").
     *
     * @param objectIds Ids aus BCS importer Objekte
     * @param objectType Objekttyp der importen Objekte
     * @param subtypesById Subtyp der einzelnen Objekt als Map je Id [optional]
     */
    public async createSyncStatesForNewImportedObjects(
        objectIds: string[],
        objectType: SyncStateObjectType,
        subtypesById?: { [key: string]: string },
    ): Promise<void> {
        const userOid = this.userSession.getCurrentUserOid();

        const createdSyncStates: SyncState[] = [];
        for (let i = 0; i < objectIds.length; i++) {
            const objectId = objectIds[i];
            const syncState = await this.getSyncStateById(objectId);
            if (!syncState) {
                const syncState = SyncState.forEntity(
                    objectId,
                    objectType,
                    SyncStateType.NoChangesInApp,
                    userOid,
                );
                if (subtypesById) {
                    syncState.setSubtype(subtypesById[objectId]);
                }
                createdSyncStates.push(syncState);
            }
        }
        await this.storeSyncStates(createdSyncStates);
    }

    /**
     * Sucht SyncStates für ObjektIds, die bei den aktuell aus BCS importierten Objekte nicht (mehr) enthalten sind,
     * löscht die SyncStates und liefert deren ObjectId (damit die Objekte selbst auch gelöscht werden können).
     *
     * @param objectIds Ids aus BCS importer Objekte
     * @param objectType Objekttyp der importen Objekte
     * @returns ObjektIds, die bei den aktuell aus BCS importierten Objekte nicht (mehr) enthalten sind
     */
    public async findAndDeleteSyncStatesMissingInImportedObjects(
        objectIds: string[],
        objectType: SyncStateObjectType,
    ): Promise<string[]> {
        // ObjektIds schneller prüfbar machen
        const idMap: { [key: string]: boolean } = {};
        objectIds.forEach((objectId) => (idMap[objectId] = true));

        // Alle SyncState dieses Objekttyps abfragen
        const allSyncStates = await this.readAllSyncStates(objectType);

        // Die Ids derjenigen SyncStates rausfiltern, die nicht als ObjektId gegeben und die in App nicht geändert markiert sind.
        const deleteIds = allSyncStates
            .filter((syncState) => !idMap.hasOwnProperty(syncState.getId()))
            .filter((syncState) => syncState.isNotChangedInApp())
            .map((syncState) => syncState.getId());

        // SyncStates löschen
        await this.deleteSyncStates(deleteIds);

        return deleteIds;
    }

    public storeSyncStates(syncStates: SyncState[]): Promise<void> {
        if (syncStates.length == 0) {
            return Promise.resolve();
        }

        const syncStatesValueObjects: object[] = syncStates.map((syncState) =>
            syncState.toValueObject(),
        );

        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([SyncStateManager.SYNC_STATE_STORE_NAME])
                .updateElements(SyncStateManager.SYNC_STATE_STORE_NAME, syncStatesValueObjects)
                .then(resolve, reject);
        });
    }

    public storeSyncState(syncState: SyncState): Promise<void> {
        return this.storeSyncStates([syncState]);
    }

    public writeSyncStateToDB(syncState: SyncState): Promise<void> {
        return this.storeSyncStates([syncState]);
    }

    public deleteSyncStates(syncStateIds: string[]): Promise<void> {
        if (syncStateIds.length == 0) {
            return Promise.resolve();
        }

        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([SyncStateManager.SYNC_STATE_STORE_NAME])
                .deleteIds(SyncStateManager.SYNC_STATE_STORE_NAME, syncStateIds)
                .then(resolve, reject);
        });
    }

    public deleteSyncState(syncStateId: string): Promise<void> {
        return this.deleteSyncStates([syncStateId]);
    }
}

Registry.registerSingletonComponent(SyncStateManager.BCS_COMPONENT_NAME, SyncStateManager);
