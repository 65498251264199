import { GUIElement } from "../GUIElement";
import "./SubMenu.less";
import { ConfigNode } from "../../core/Config/ConfigNode";
import { Registry } from "../../core/Registry";
import { ClickOutSidePool } from "../event/ClickOutSidePool";
import { I18n } from "../../common/i18n/I18n";

export class SubMenu implements GUIElement {
    public static BCS_COMPONENT_NAME: string = "SubMenu";

    private imageName: string = null;

    private text: string = null;

    private styleClass: string = "";

    private clickCallback: Function;

    private subMenuEntry: { id: string; callback: Function; label: string }[] = [];

    private $subMenuDivTag: JQuery = null;

    private clickedElementCoodinates: { x: number; y: number; width: number };

    private orientation: string = null;

    private position: string = null;

    private clickOutsidePool: ClickOutSidePool = null;

    private lowerBoundOffset: number = 0;

    constructor(configNode: ConfigNode, clickedElementX, clickedElmentY, clickedElementWidth) {
        this.orientation = configNode.getAttribute("Orientation", null);
        this.position = configNode.getAttribute("Fixed", null);
        this.clickedElementCoodinates = {
            x: clickedElementX,
            y: clickedElmentY,
            width: clickedElementWidth,
        };
    }

    public setImageName(imageName: string): SubMenu {
        this.imageName = imageName;
        return this;
    }

    public setText(text: string): SubMenu {
        this.text = text;
        return this;
    }

    public setStyleClass(styleClass: string): SubMenu {
        this.styleClass += " " + styleClass;
        return this;
    }

    public registerClickOutSidePool(clickOutside: ClickOutSidePool) {
        this.clickOutsidePool = clickOutside;
    }

    /**
     * HTML-DOM generieren
     *
     * @param $parent Parent-jQuery-Objekt
     */
    public compose($parent: JQuery): void {
        // Es sollte immer nur ein SubMenu zur gleichen Zeit geben, daher löschen wir alle die wir finden in jedem Fall raus.
        $("#submenu").remove();

        this.$subMenuDivTag = $(
            "<div id='submenu' style='top:" +
                this.clickedElementCoodinates.y +
                "px;left:" +
                this.clickedElementCoodinates.x +
                "px'>",
        )
            .appendTo($parent)
            .addClass("subMenu " + this.styleClass)
            .css("cursor", "pointer");

        const $table = $("<table></table>");
        const self = this;
        for (let i = 0; i < this.subMenuEntry.length; i++) {
            const row = $(
                "<tr><td id='" +
                    this.subMenuEntry[i].id +
                    "'>" +
                    this.subMenuEntry[i].label +
                    "</td></tr>",
            );
            row.on("click", function (event) {
                event.preventDefault();
                event.stopPropagation();
                self.subMenuEntry[i]["callback"](self.subMenuEntry[i], event);
            });
            $table.append(row);
        }
        this.$subMenuDivTag.append($table);

        // --- Vertikale Positionsanpassung
        if (this.orientation === "above") {
            // Wenn das submenü oberhalb angezeigt werden soll
            const submenuHeight = $table.height();
            this.$subMenuDivTag.css({
                top: this.clickedElementCoodinates.y - submenuHeight - 22 + "px",
            });
        } else {
            // Wenn das submenu unten heraus oder über die untere toolbar ragen würde, schieben wir es hoch
            const submenuHeight = $table.height() + 20; // parseInt($table.css("margin-top")); <-- geht leider nicht :-(
            // top-margin verschiebt uns nach unten aber ist nicht in der höhe mit drin
            const windowHeight = $(window).height();
            const lowerBoundExcess = this.clickedElementCoodinates.y + submenuHeight - windowHeight;
            if (lowerBoundExcess > 0) {
                this.$subMenuDivTag.css({
                    top:
                        this.clickedElementCoodinates.y -
                        lowerBoundExcess -
                        this.lowerBoundOffset +
                        "px",
                });
            }
        }

        // --- Horizontale Positionsanpassung
        const windowWidth = $(window).width();
        const width = $table.width();
        const offLeft = $table.offset().left;
        if (offLeft + width > windowWidth) {
            this.$subMenuDivTag.css({ left: windowWidth - width - 20 + "px" });
        }

        ClickOutSidePool.registerClickInsideLayer("#submenu", () => {
            $("#submenu").remove();
        });
    }

    public addEntry(id, callback, label) {
        this.subMenuEntry.push({ id: id, callback: callback, label: label });
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }

    public setLowerBoundOffset(offset: number) {
        this.lowerBoundOffset = offset;
    }

    public adhocRemoveClass(styleclass: string) {
        this.$subMenuDivTag.removeClass(styleclass);
    }

    public adhocAddClass(styleclass: string) {
        this.$subMenuDivTag.addClass(styleclass);
    }

    public static openSubMenu(
        transferObject: object,
        i18n: I18n,
        selectEntryCallback: (action: string, navigationContext?: {}) => void,
    ) {
        const clickedElement: JQuery = transferObject["clickedElement"];
        const submenuNode: ConfigNode = transferObject["submenuNode"];

        const offset = clickedElement.offset();
        const subMenu = new SubMenu(submenuNode, offset.left, offset.top, 50);

        const subMenuEntries = submenuNode.getChildren();
        for (let i = 0; i < subMenuEntries.length; i++) {
            const submenuItemConfigNode = subMenuEntries[i];
            const i18nLabel = submenuItemConfigNode.getAttribute(
                "I18nLabel",
                submenuItemConfigNode.getName(),
            );
            subMenu.addEntry(
                submenuItemConfigNode.getName(),
                () => {
                    const action = submenuItemConfigNode.getAttribute("Action");
                    if (action != null) {
                        if (action === "logout") {
                            const navigationPath =
                                submenuItemConfigNode.getAttribute("NavigationPath");
                            const navigationContext: {} = { navigate: navigationPath };
                            selectEntryCallback(action, navigationContext);
                        }
                    } else {
                        const navigationPath = submenuItemConfigNode.getAttribute("NavigationPath");
                        const navigationContext: {} = { navigate: navigationPath };

                        const rawParameter: string =
                            submenuItemConfigNode.getAttribute("Parameter");
                        if (rawParameter != null) {
                            const parameters: string[] = rawParameter.split(";");

                            const resultParameters: {} = {};
                            for (let i = 0; i < parameters.length; i++) {
                                const keyValue: string[] = parameters[i].split("=");
                                resultParameters[keyValue[0]] = keyValue[1];
                            }
                            navigationContext["parameters"] = resultParameters;
                        }
                        selectEntryCallback("navigate", navigationContext);
                    }
                },
                i18n.get(i18nLabel),
            );
        }

        subMenu.compose($("#approot"));
    }
}

Registry.registerGUIComponent(SubMenu.BCS_COMPONENT_NAME, SubMenu);
