import { DateValue } from "../../entities/values/DateValue";
import { AllowancePSP } from "./records/AllowancePSP";
import { Log } from "../../common/log/Log";

/**
 * Spesenerfassungsmodalitäten (Spesenaufgaben, Abrechenbarkeiten, Belegarten, ...)
 *
 */
export class AllowanceRecordingTerms {
    private recordingTerms: object;

    private checkSum: number = 0;

    private allowanceRecordingAvailable: boolean;

    private configProperties: { [key: string]: string } = {};

    private configSets: { [key: string]: string[] } = {};

    private workCountry: string;

    private allowanceCurrency: string;

    private allowancePSPList: AllowancePSP[] = [];

    private allowancePSPSearchNames: string[] = [];

    /** Auswählbare Belegarten mit Gültigkeitszeitraum */
    private recordTypes: { recordTypeId: string; validFrom: DateValue; validUntil: DateValue }[] =
        [];

    constructor(recordingTermValueObject?: object) {
        if (recordingTermValueObject) {
            this.recordingTerms = recordingTermValueObject;

            this.checkSum = recordingTermValueObject["checkSum"] || 0;

            this.readAllowanceAvailability(recordingTermValueObject["allowanceAvailability"]);
            this.readConfigProperties(recordingTermValueObject["allowanceConfigProperties"]);
            this.readConfigSets(recordingTermValueObject["allowanceConfigSets"]);
            this.readDynUserAttributes(recordingTermValueObject["dynUser"]);
            this.readAllowancePSPList(recordingTermValueObject["allowanceTasks"]);
            this.readAllowancePSPSearchNames(
                recordingTermValueObject["allowanceTasksSearchFieldNames"],
            );
            this.readAllowanceRecordTypes(recordingTermValueObject["allowanceRecordTypes"]);
        } else {
            // keine RecordingTerms aus Datenbank gelesen
        }
    }

    private readAllowanceAvailability(allowanceAvailabilityObject: object): void {
        this.allowanceRecordingAvailable =
            allowanceAvailabilityObject["isMobileAllowanceEnabled"] == true &&
            allowanceAvailabilityObject["isAllowanceFeatureEnabled"] == true &&
            allowanceAvailabilityObject["userHasAllowancesLicensed"] == true;
    }

    private readConfigProperties(configPropertiesObject: object): void {
        for (const propertyName in configPropertiesObject) {
            if (configPropertiesObject.hasOwnProperty(propertyName)) {
                this.configProperties[propertyName] = configPropertiesObject[propertyName];
            }
        }
    }

    private readConfigSets(configSetsObject: object): void {
        for (const setName in configSetsObject) {
            if (configSetsObject.hasOwnProperty(setName)) {
                const setStr = configSetsObject[setName];
                this.configSets[setName] = setStr ? setStr.split(" ") : [];
            }
        }
    }

    private readDynUserAttributes(configPropertiesObject: object): void {
        this.workCountry = configPropertiesObject["workCountry"];
        this.allowanceCurrency = configPropertiesObject["contextAllowanceCurrency"];
    }

    private readAllowancePSPList(allowancePSPListValueObjects: object[]): void {
        // "defaultChargeability_voucherAllowance": "not_chargeable",
        // "parentName": "[00] Projektmanagement",
        // "grandParentName": "GPS-Demonstrator",
        // "defaultChargeability_travelDurationAllowance": "not_chargeable",
        // "taskOid": "1106053040111_JTask",
        // "taskName": "[00-00] Projektmanagement",
        // "defaultChargeability_accomodationAllowance": "not_chargeable",
        // "defaultChargeability_dailyAllowance": "not_chargeable",
        // "defaultChargeability_kilometreAllowance": "not_chargeable"

        allowancePSPListValueObjects.forEach((allowancePSPListValueObject) =>
            this.allowancePSPList.push(new AllowancePSP(allowancePSPListValueObject)),
        );
    }

    private readAllowancePSPSearchNames(allowancePSPListValueObjects: string[]): void {
        if (allowancePSPListValueObjects) {
            allowancePSPListValueObjects.forEach((allowancePSPSearchNameValueObject) =>
                this.allowancePSPSearchNames.push(allowancePSPSearchNameValueObject),
            );
        } else {
            // Default vor Einführung der Konfiguration für Suchfelder
            this.allowancePSPSearchNames = ["taskName", "parentName", "grandParentName"];
        }
    }

    /**
     * Einlesen der Belegarten für aktuellen Benutzer mit Gültigkeitszeitraum.
     *
     * @param allowanceRecordTypeObjects Belegarten mit Belegart-Ids und Gültigkeitszeitraum als JSON-Array
     */
    private readAllowanceRecordTypes(allowanceRecordTypeObjects: object[]) {
        allowanceRecordTypeObjects.forEach((allowanceRecordTypeObject) => {
            this.recordTypes.push({
                recordTypeId: allowanceRecordTypeObject["recordType"],
                validFrom: DateValue.parseFromString(allowanceRecordTypeObject["validFrom"]),
                validUntil: DateValue.parseFromString(allowanceRecordTypeObject["validUntil"]),
            });
        });
    }

    public getCheckSum(): number {
        return this.checkSum;
    }

    public isAllowanceRecordingAvailable(): boolean {
        return this.allowanceRecordingAvailable;
    }

    public getConfigProperty(propertyName: string): string {
        if (!this.configProperties.hasOwnProperty(propertyName)) {
            throw new Error("Undefined allowance recording property: " + propertyName);
        }
        return this.configProperties[propertyName];
    }

    public getConfigSet(setName: string): string[] {
        if (!this.configSets.hasOwnProperty(setName)) {
            throw new Error("Undefined allowance recording set: " + setName);
        }
        return this.configSets[setName];
    }

    public getWorkCountry(): string {
        return this.workCountry;
    }

    public getAllowanceCurrency(): string {
        return this.allowanceCurrency;
    }

    public getAllowancePSPList(): AllowancePSP[] {
        return this.allowancePSPList;
    }

    /**
     * @returns Spesen-Aufgabenlisten-Felder die durchsucht werden
     */
    public getAllowancePSPSearchNames(): string[] {
        return this.allowancePSPSearchNames;
    }

    public getAllowancePSP(pspOid: string): AllowancePSP {
        const matchingAllowancePSPs = this.allowancePSPList.filter(
            (allowancePSP) => allowancePSP.getTaskOid() == pspOid,
        );
        return matchingAllowancePSPs.length > 0 ? matchingAllowancePSPs[0] : null;
    }

    /**
     * @param recordDate Belegdatum
     * @returns Belegart-Optionen für eingeloggten Benutzer (d.h. dessen Arbeitsland), die zum Belegdatum gültig sind
     */
    public getValidRecordTypes(recordDate: DateValue): string[] {
        if (!recordDate || typeof recordDate === "undefined") {
            recordDate = DateValue.today();
        }

        return this.recordTypes
            .filter(
                (recordType) =>
                    recordType.validFrom.isSameOrBefore(recordDate) &&
                    recordType.validUntil.isSameOrAfter(recordDate),
            )
            .map((recordType) => recordType.recordTypeId);
    }

    /**
     * @returns Auflösung (Höhe und Breite) von Belegfotos, auf die neue Belegfotos in der App reduziert werden (z.B. "1024x768")
     */
    public getVoucherPhotoDimensions(): { width: number; height: number } {
        const dimensionProperty = this.getConfigProperty(
            "MobileApp.Allowances.VoucherPhotoDimensions",
        );

        let dimensions: string[];
        if (dimensionProperty && dimensionProperty.indexOf("x") >= 0) {
            dimensions = dimensionProperty.split("x");
        } else {
            dimensions = ["1024", "768"];
        }
        return { width: parseInt(dimensions[0]), height: parseInt(dimensions[1]) };
    }

    /**
     * @returns Die Qualität von Bildern, die über die App eingescannt werden.
     */
    public getVoucherPhotoQuality(): number {
        const qualityProperty = this.getConfigProperty("MobileApp.Allowances.VoucherPhotoQuality");
        let quality: number = 0.75;
        try {
            quality = parseFloat(qualityProperty);
            if (Number.isNaN(quality)) {
                quality = 0.75;
            }
        } catch (err) {
            Log.error(
                "Error while Parsing Property: MobileApp.Allowances.VoucherPhotoQuality -->" + err,
            );
        }
        return quality;
    }

    public toValueObject(): object {
        return this.recordingTerms;
    }
}
