import { TimeRecord } from "./TimeRecord";
import { AttributeDefinition } from "../../common/schema/AttributeDefinition";
import { EntityValue } from "../../entities/values/EntityValue";
import { IdGenerator } from "../../util/text/IdGenerator";
import { BCSDate } from "../../common/BCSDate";
import { TimeAttibutesDefinitions } from "./TimeAttibutesDefinitions";
import { SyncState } from "../../sync/SyncState";
import { BookingType } from "./bookings/Booking";
import { Appointment } from "./timesheet/Appointment";

export class DummyBooking implements TimeRecord {
    private startDate: Date;
    private endDate: Date;
    private date: Date;
    private duration: number;

    private isAppointmentDummy: boolean = false;
    private appointment: Appointment = null;

    private id: string;

    constructor(startDate: Date, endDate: Date, duration: number, date: Date) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.duration = duration;
        this.date = date;

        this.id = IdGenerator.createId() + "_DummyBooking";
    }

    getId(): string {
        return this.id;
    }

    getSubtype(): string {
        return "dummy";
    }

    public setToAppointment(appointment: Appointment): TimeRecord {
        this.isAppointmentDummy = true;
        this.appointment = appointment;
        return this;
    }

    public getAppointment(): Appointment {
        return this.appointment;
    }

    /**
     * Wird nur für Buchungen verwendet.
     *
     */
    public getAvaliableTaskTypes() {
        return undefined;
    }

    getDate(): Date {
        return this.date;
    }

    getEndTime(): Date {
        return this.endDate;
    }

    getStartTime(): Date {
        return this.startDate;
    }

    isMiscellaneousEffort(): boolean {
        return false;
    }

    public isAppointment() {
        return this.isAppointmentDummy;
    }

    isPause(): boolean {
        return false;
    }

    isDummy(): boolean {
        return true;
    }

    isBooking(): boolean {
        throw new Error("Method not implemented.");
    }

    getEffortExpense(): number {
        return this.duration;
    }

    hasDurationOnly(): boolean {
        if (this.getStartTime() == null && this.getEndTime() == null) {
            return true;
        } else {
            return false;
        }
    }

    getAttributeDefinition(name: string): AttributeDefinition {
        throw new Error("Method not implemented.");
    }

    getValue(name: string): EntityValue {
        throw new Error("Method not implemented.");
    }

    setValue(name: string, value: EntityValue): void {
        throw new Error("Method not implemented.");
    }

    toValueObject(): object {
        throw new Error("Method not implemented.");
    }

    toString(): string {
        return (
            "<" +
            this.startDate +
            " - " +
            this.endDate +
            " => " +
            this.duration +
            " - " +
            this.getId() +
            ">"
        );
    }

    setId(id: string): void {
        throw new Error("Method not implemented.");
    }

    setDate(date: BCSDate): TimeRecord {
        throw new Error("Method not implemented.");
    }

    getRequiredAttributes(): string[] {
        throw new Error("Method not implemented.");
    }

    getTimeAttibutesDefinitions(): TimeAttibutesDefinitions {
        throw new Error("Method not implemented.");
    }

    getBookingType(): BookingType {
        throw new Error("Method not implemented.");
    }

    setBookingType(bookingType: string | BookingType) {
        throw new Error("Method not implemented.");
    }

    public attachSyncState(syncState: SyncState): void {
        // nix wir brauchen keinen SyncSate, solange wir noch ne Dummy Buchung sind, sind wir nicht gespeichert, weder in BCS noch in der Datenbank
    }

    /**
     *
     * @return syncState:boolean true => es gibt einen Synchronisations Stand
     */
    public hasSyncState(): boolean {
        return false;
    }

    public getSyncState(): SyncState {
        return null;
    }

    public isSyncOnlyChangedInApp(): boolean {
        return true;
    }
}
