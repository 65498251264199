import { AppConsole } from "../../../common/log/AppConsole";
import { Log } from "../../../common/log/Log";
import { ApplicationProperties } from "../../../common/properties/ApplicationProperties";
import { Schema } from "../../../common/schema/Schema";
import { IndexedDB } from "../../../database/IndexedDB";
import { IndexedDBQuery } from "../../../database/IndexedDBQuery";
import { IndexedDBVersion } from "../../../database/IndexedDBVersion";
import { TimeRecord } from "../TimeRecord";
import { TimeSpan } from "./TimeSpan";
import { TimeSpanClient } from "./TimeSpanClient";

export class TimeSpanPool {
    private static RECORDING_TERMS_PROPERTY_KEY = "TimeSpanRecordingTerms";

    private static TIMESPANS_STORE_NAME = "timespans";

    private static INDEX_USER_RECORD_DATE = "timeSpanDate";

    private schema: Schema;

    private indexedDB: IndexedDB;

    private applicationProperties: ApplicationProperties;

    private timespansById: { [key: string]: object } = {};

    private poolUpdateAge: number;

    constructor(
        indexedDB: IndexedDB,
        applicationProperties: ApplicationProperties,
        schema: Schema,
    ) {
        this.indexedDB = indexedDB;
        this.schema = schema;

        this.applicationProperties = applicationProperties;

        this.indexedDB
            .registerStore(TimeSpanPool.TIMESPANS_STORE_NAME, IndexedDBVersion.DB_VERSION_1)
            .setIdKey("oid")
            .addIndex(
                TimeSpanPool.INDEX_USER_RECORD_DATE,
                ["userOid", "timeSpanDate", "subtyp"],
                false,
                IndexedDBVersion.DB_VERSION_1,
            );
    }

    public synchronizeTimespansWithBCS(userOid: string, lastBCSSyncState: number): Promise<number> {
        AppConsole.debug("[TimeSpan] readTimeSpanFromBCS", lastBCSSyncState);
        const self = this;
        return new Promise((resolve, reject) => {
            new TimeSpanClient()
                .fetchTimeSpans(lastBCSSyncState)
                .then((result) => {
                    AppConsole.debug("[TimeSpanPool] TimeSpans FETCHED", result, lastBCSSyncState);

                    const no_content: boolean = result == null;

                    if (!no_content) {
                        const timespans: TimeSpan[] = [];
                        if (result.hasOwnProperty("timespans")) {
                            const timespanJSONs = result["timespans"];
                            for (let i = 0; i < timespanJSONs.length; i++) {
                                const timespansJSON = timespanJSONs[i];
                                const id = timespansJSON["oid"];

                                self.timespansById[id] = timespansJSON;
                                timespans.push(self.getTimeSpanById(id));
                                this.writeTimeSpanToDB(self.getTimeSpanById(id));
                            }
                        }
                        if (result.hasOwnProperty("syncStateTimestamp")) {
                            resolve(result.syncStateTimestamp);
                        } else {
                            // kein Sync TimeStamp gefunden
                            resolve(-1);
                        }

                        // self.cleanAllTimespansInDB(userOid).then(() => {

                        // });
                    } else {
                        // kein neuer Content daher passen wir den SyncTimestamp nicht an.
                        resolve(-1);
                    }
                })

                .catch(reject);
        });
    }

    public cleanAllTimespansInDB(userOid: string): Promise<void> {
        const minRecordDate = "1970-01-01",
            maxRecordDate = "2199-12-31";
        const query = IndexedDBQuery.greaterOrEqualAndLesserOrEqual(
            [userOid, minRecordDate],
            [userOid, maxRecordDate],
        );

        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([TimeSpanPool.TIMESPANS_STORE_NAME])
                .deleteQuery(
                    TimeSpanPool.TIMESPANS_STORE_NAME,
                    TimeSpanPool.INDEX_USER_RECORD_DATE,
                    query,
                )
                .then(
                    (result) => {
                        AppConsole.debug("[TimeSpanPool] delete alle bookings", result, userOid);
                        resolve();
                    },
                    (error) => {
                        Log.error(
                            "[TimeSpanPool] Error while cleaning timepspans in local db.",
                            error,
                            error,
                        );
                        reject(error);
                    },
                );
        });
    }

    public writeTimeSpanToBCSServer(timespan: TimeSpan): Promise<TimeSpan> {
        const self = this;
        // Diese Oid kann provisorisch sein:
        const oldTimeSpanID = timespan.getId();

        return new Promise((resolve, reject) => {
            new TimeSpanClient()
                .saveTimeSpan(self.poolUpdateAge, timespan.toValueObject())
                .then((result) => {
                    AppConsole.debug("[TimeSpanPool] Timespan Saved", result, this.poolUpdateAge);

                    if (result.hasOwnProperty("timespans")) {
                        const timespanJSONs = result["timespans"];
                        // TODO: Jens , wir sind hier eigentlich nur darauf ausgelegt einen TimeSpan zu speichern und den Alten zu löschen
                        for (let i = 0; i < timespanJSONs.length; i++) {
                            const timespansJSON = timespanJSONs[i];
                            const id = timespansJSON["oid"];
                            self.timespansById[id] = timespansJSON;

                            this.writeTimeSpanToDB(self.getTimeSpanById(id))
                                .then(() => {
                                    if (oldTimeSpanID != id) {
                                        this.delteTimeSpanFromDB([oldTimeSpanID]);
                                    }
                                })
                                .then(() => {
                                    const newTimeSpan: TimeSpan = self.wrapTimeSpanIntoDomainObject(
                                        self.timespansById[id],
                                    );
                                    resolve(newTimeSpan);
                                });
                        }
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    public deleteTimeSpanFromBCSServer(booking: TimeRecord): Promise<void> {
        const self = this;

        return new Promise((resolve, reject) => {
            new TimeSpanClient()
                .deleteTimespan(self.poolUpdateAge, booking.toValueObject())
                .then((result) => {
                    AppConsole.debug("[TimeSpanPool] TimeSpan Deleted", result, this.poolUpdateAge);
                    resolve();
                })
                .catch((errorInformations) => {
                    reject(errorInformations);
                });
        });
    }

    public getTimeSpanById(id: string): TimeSpan {
        if (this.timespansById.hasOwnProperty(id)) {
            return new TimeSpan(this.schema, this.timespansById[id]);
        }
        return null;
    }

    public writeTimeSpanToDB(booking: TimeSpan): Promise<TimeSpan> {
        const self = this;
        //AppConsole.log(""+JSON.stringify(booking.toValueObject())+",");

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([TimeSpanPool.TIMESPANS_STORE_NAME])
                .updateElements(TimeSpanPool.TIMESPANS_STORE_NAME, [booking.toValueObject()])
                .then(resolve, reject);
        });
    }

    public delteTimeSpanFromDB(id: string[]): Promise<void> {
        const self = this;
        AppConsole.debug("[TimeSpanPool] Delete Timespan", id);

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([TimeSpanPool.TIMESPANS_STORE_NAME])
                .deleteIds(TimeSpanPool.TIMESPANS_STORE_NAME, id)
                .then(resolve, reject);
        });
    }

    public readTimeSpansFromDB(userOid: string, oid: string): Promise<TimeSpan> {
        const query = IndexedDBQuery.greaterOrEqualAndLesserOrEqual([userOid], [userOid]);

        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([TimeSpanPool.TIMESPANS_STORE_NAME])
                .selectId(TimeSpanPool.TIMESPANS_STORE_NAME, oid)
                .then(
                    (result) => {
                        if (result && result.element) {
                            resolve(self.wrapTimeSpanIntoDomainObject(result.element));
                        } else {
                            resolve(null);
                        }
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    public readAllTimeSpansFromDB(
        userOid: string,
        minRecordDate = "1970-01-01",
        maxRecordDate = "2199-12-31",
    ): Promise<TimeSpan[]> {
        const query = IndexedDBQuery.greaterOrEqualAndLesserOrEqual(
            [userOid, minRecordDate],
            [userOid, maxRecordDate],
        );

        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([TimeSpanPool.TIMESPANS_STORE_NAME])
                .selectCursor(
                    TimeSpanPool.TIMESPANS_STORE_NAME,
                    TimeSpanPool.INDEX_USER_RECORD_DATE,
                    query,
                )
                .then(
                    (result) => {
                        //AppConsole.log(result.resultSet);
                        //console.table(result.resultSet , ["effortDate" ,"effortExpense", "effortTargetSubtyp", "effortStart"]);
                        //console.table(result.resultSet);

                        const booking: TimeSpan[] = [];
                        if (result && result.resultSet) {
                            resolve(self.wrapTimeSpansIntoDomainObjects(result.resultSet));
                        } else {
                            resolve([]);
                        }
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    private wrapTimeSpansIntoDomainObjects(timespanValueObjects: object[]): TimeSpan[] {
        const timespan: TimeSpan[] = [];

        for (let i = 0; i < timespanValueObjects.length; i++) {
            timespan.push(this.wrapTimeSpanIntoDomainObject(timespanValueObjects[i]));
        }

        return timespan;
    }

    private wrapTimeSpanIntoDomainObject(timepanValueObject: object): TimeSpan {
        /*switch (bookingValueObject["subtyp"]) {
            case BusinessTravel.SUBTYPE:
                return new BusinessTravel(this.schema, bookingValueObject);
            case VoucherAllowance.SUBTYPE:
                let typeSubtypeDefinition = this.schema.getTypeSubtypeDefinition(VoucherAllowance.TYPE, VoucherAllowance.SUBTYPE);
                return new VoucherAllowance(typeSubtypeDefinition, bookingValueObject);
            default:
                throw new Error("[AllowancePool] Unknown allowance subtype: " + bookingValueObject["subtyp"]);  */
        return new TimeSpan(this.schema, timepanValueObject);
    }
}
