import { ListRowModel } from "./ListRowModel";
import { ListViewModel } from "./ListViewModel";

export class EmptyListViewModel implements ListViewModel {
    public countRows(): number {
        return 0;
    }

    public getRowModelByNo(rowNo: number): ListRowModel {
        throw new Error("EmptyListViewModel");
    }

    public getRowModelById(entityId: string): ListRowModel {
        throw new Error("EmptyListViewModel");
    }

    public isResultTruncated(): boolean {
        return false;
    }
}
