import { Allowance } from "./Allowance";
import { AllowanceRecordingTerms } from "../AllowanceRecordingTerms";
import { AllowanceState } from "./AllowanceState";
import { AttributeDefinition } from "../../../common/schema/AttributeDefinition";
import { BusinessTravel } from "./BusinessTravel";
import { CurrencyValue } from "../../../entities/values/CurrencyValue";
import { DateTimeValue } from "../../../entities/values/DateTimeValue";
import { DateValue } from "../../../entities/values/DateValue";
import { Entity } from "../../../entities/Entity";
import { EntityValue } from "../../../entities/values/EntityValue";
import { IdGenerator } from "../../../util/text/IdGenerator";
import { OidValue } from "../../../entities/values/OidValue";
import { RootAllowance } from "./RootAllowance";
import { TravelSection } from "./TravelSection";
import { TypeSubtypeDefinition } from "../../../common/schema/TypeSubtypeDefinition";

export class VoucherAllowance implements Allowance, RootAllowance {
    public static readonly TYPE = "JAllowance";

    public static readonly SUBTYPE = "voucherAllowance";

    public static readonly USER_OID = "allowanceUserOid";

    public static readonly PSP_OID = "allowancePspOid";

    public static readonly BUSINESS_TRAVEL_OID = "allowanceTravelOid";

    public static readonly STATE = "allowanceState";

    public static readonly ACCOUNTING_MONTH = "allowanceAccountingMonth";

    public static readonly RECORD_DATE = "allowanceRecordDate";

    public static readonly RECORD_TYPE = "allowanceRecordType";

    public static readonly ORIGINAL_CURRENCY = "allowanceCurrency";

    public static readonly ORIGINAL_AMOUNT = "allowanceOriginalAmount";

    public static readonly ORIGINAL_TIP_AMOUNT = "allowanceIncludedOriginalTipAmount";

    public static readonly SOURCE = "allowanceSource";

    public static readonly CHARGEABILITY = "allowanceChargeability";

    /** Erstell-Datum/-uhrzeit */
    public static readonly INS_DATE = "insDate";

    /** Spesenerfassungsmodalitäten (Spesenaufgaben, Abrechenbarkeiten, Belegarten, ...) */
    private allowanceRecordingTerms: AllowanceRecordingTerms;

    /** Reiseabschnitt zu dem diese Belegspese gehört ODER null falls Einzelbeleg */
    private travelSection: TravelSection;

    private allowanceEntity: Entity;

    /** Fehlermeldung durch letzte Attributänderungen (seit letzter Abfrage) */
    private changeErrorKey: string = null;

    /**
     * Erstelle neue Belegspese
     *
     * @param travelSection Reiseabschnitt, zu dem Belegspese gehört oder NULL wenn Einzelbeleg
     * @param typeSubtypeDefinition Attributedefinition für Belegspesen
     * @param userOid Oid des Benutzers
     * @param businessTravelOid Oid der Dienstreise oder NULL falls Einzelbeleg
     */
    public static create(
        travelSection: TravelSection,
        allowanceRecordingTerms: AllowanceRecordingTerms,
        typeSubtypeDefinition: TypeSubtypeDefinition,
        userOid: string,
        businessTravelOid: string,
    ): VoucherAllowance {
        const allowanceValueObject = {
            oid: IdGenerator.createId() + "_" + VoucherAllowance.TYPE,
            typ: VoucherAllowance.TYPE,
            subtyp: VoucherAllowance.SUBTYPE,
        };

        allowanceValueObject[VoucherAllowance.USER_OID] = userOid;
        allowanceValueObject[VoucherAllowance.BUSINESS_TRAVEL_OID] = businessTravelOid;

        const currency = allowanceRecordingTerms.getAllowanceCurrency();
        allowanceValueObject[VoucherAllowance.ORIGINAL_CURRENCY] = currency;

        if (
            allowanceRecordingTerms
                .getConfigSet("Allowances.previousVoucherCopyAttributes")
                .indexOf(VoucherAllowance.RECORD_DATE) >= 0
        ) {
            allowanceValueObject[VoucherAllowance.RECORD_DATE] = DateValue.today().getISODate();
        }
        allowanceValueObject[VoucherAllowance.ACCOUNTING_MONTH] = DateValue.today().getISODate();

        const voucherAllowance = new VoucherAllowance(
            travelSection,
            allowanceRecordingTerms,
            typeSubtypeDefinition,
            allowanceValueObject,
            true,
        );

        // Werte des Reiseabschnitts übernehmen
        if (travelSection) {
            allowanceRecordingTerms
                .getConfigSet("Allowances.sectionVoucherCopyAttributes")
                .forEach((attributeName) => {
                    if (attributeName == VoucherAllowance.RECORD_DATE) {
                        // Reiseabschnitt-Startdatum als Belegdatum kopieren
                        voucherAllowance.setValue(
                            attributeName,
                            travelSection.getStartDateTime().getDateValue(),
                        );
                    } else {
                        voucherAllowance.setValue(
                            attributeName,
                            travelSection.getValue(attributeName),
                        );
                    }
                });
        }

        return voucherAllowance;
    }

    constructor(
        travelSection: TravelSection,
        allowanceRecordingTerms: AllowanceRecordingTerms,
        typeSubtypeDefinition: TypeSubtypeDefinition,
        allowanceValueObject: object,
        isNew = false,
    ) {
        this.allowanceRecordingTerms = allowanceRecordingTerms;

        this.travelSection = travelSection;

        this.allowanceEntity = new Entity(typeSubtypeDefinition, allowanceValueObject, isNew);

        // Aufgabennamen aus Spesendatenobjekt übernehmen (als Fallback, falls Aufgabe nicht (mehr) in Spesenaufgabenliste enthalten).
        if (allowanceValueObject.hasOwnProperty(VoucherAllowance.PSP_OID + ".name")) {
            this.setPSPName(allowanceValueObject[VoucherAllowance.PSP_OID + ".name"]);
        }
    }

    /**
     * Werte der vorheriges Belegspese übernehmen.
     *
     * @param precedingVoucherAllowance Vorherige Belegspese
     */
    public applyValuesFromPrecedingVoucherAllowance(precedingVoucherAllowance: VoucherAllowance) {
        this.allowanceRecordingTerms
            .getConfigSet("Allowances.previousVoucherCopyAttributes")
            .forEach((attributeName) => {
                this.setValue(attributeName, precedingVoucherAllowance.getValue(attributeName));
            });
    }

    public getId(): string {
        return this.allowanceEntity.getId();
    }

    public getSubtype(): string {
        return VoucherAllowance.SUBTYPE;
    }

    public getUserOid(): string {
        return this.allowanceEntity.getId(VoucherAllowance.USER_OID);
    }

    public getPSPOid(): string {
        return this.allowanceEntity.getId(VoucherAllowance.PSP_OID);
    }

    public setPSPName(pspName: string): void {
        const pspOidValue = OidValue.fromOidAndName(this.getPSPOid(), pspName);
        this.allowanceEntity.setValue(VoucherAllowance.PSP_OID, pspOidValue);
    }

    public getRecordDate(): DateValue {
        return <DateValue>this.allowanceEntity.getValue(VoucherAllowance.RECORD_DATE);
    }

    public getRecordType(): string {
        return this.allowanceEntity.getString(VoucherAllowance.RECORD_TYPE);
    }

    public getState(): AllowanceState {
        return <AllowanceState>this.allowanceEntity.getString(VoucherAllowance.STATE);
    }

    /**
     * @returns Erstell-Datum/-uhrzeit
     */
    public getInsDate(): DateTimeValue {
        return <DateTimeValue>this.allowanceEntity.getValue(VoucherAllowance.INS_DATE);
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return this.allowanceEntity.getTypeSubtypeDefinition().getAttributeDefinition(name);
    }

    public getValue(name: string): EntityValue {
        return this.allowanceEntity.getValue(name);
    }

    public getTravelSection(): TravelSection {
        return this.travelSection;
    }

    public setValue(name: string, value: EntityValue): void {
        this.allowanceEntity.setValue(name, value);

        switch (name) {
            case VoucherAllowance.RECORD_DATE:
                // Prüfen, ob ausgewählte Belegart zu geändertem Belegdatum noch zeitlich gültig ist, ggf. Warnung merken.
                this.validateRecordType();

                // Abrechnungsmonat in der App immer gleich Belegdatum setzen (sofern nicht vom Benutzer abweichend eingegeben)
                if (
                    (<DateValue>value).isEqual(
                        <DateValue>this.allowanceEntity.getValue(VoucherAllowance.ACCOUNTING_MONTH),
                    )
                ) {
                    this.allowanceEntity.setValue(VoucherAllowance.ACCOUNTING_MONTH, value);
                }
                break;
            case VoucherAllowance.ORIGINAL_CURRENCY:
            case VoucherAllowance.ORIGINAL_AMOUNT:
            case VoucherAllowance.ORIGINAL_TIP_AMOUNT:
                this.adjustCurrencyValue(
                    VoucherAllowance.ORIGINAL_CURRENCY,
                    VoucherAllowance.ORIGINAL_AMOUNT,
                );
                this.adjustCurrencyValue(
                    VoucherAllowance.ORIGINAL_CURRENCY,
                    VoucherAllowance.ORIGINAL_TIP_AMOUNT,
                );
                break;
            case VoucherAllowance.PSP_OID:
                // Nach Änderung der Spesen-Aufgabe: Abrechenbarkeit der Aufgabe an Belegspese vorbelegen
                const allowancePSP = this.allowanceRecordingTerms.getAllowancePSP(
                    value.getString(),
                );
                const defaultChargeability = allowancePSP
                    ? allowancePSP.getDefaultChargeability(VoucherAllowance.SUBTYPE)
                    : null;
                this.allowanceEntity.setString(
                    VoucherAllowance.CHARGEABILITY,
                    defaultChargeability,
                );
                break;
            default:
            // nichts
        }
    }

    /**
     * Prüfen, ob ausgewählte Belegart zu Belegdatum zeitlich gültig ist, ggf. Warnung merken.
     */
    private validateRecordType(): void {
        const recordType = this.getRecordType();
        if (
            recordType &&
            recordType.length > 0 &&
            this.allowanceRecordingTerms
                .getValidRecordTypes(this.getRecordDate())
                .indexOf(recordType) < 0
        ) {
            this.changeErrorKey = this.changeErrorKey || "recordTypeNotValidAtRecordDate";
        }
    }

    private adjustCurrencyValue(currencyAttribute: string, amountAttribute: string): void {
        const currencySign = this.allowanceEntity.getString(currencyAttribute);
        const currencyValue = <CurrencyValue>this.allowanceEntity.getValue(amountAttribute);

        if (
            currencySign &&
            currencyValue &&
            currencyValue.isDefined() &&
            currencySign != currencyValue.getCurrencySign()
        ) {
            const adjustedCurrencyValue = CurrencyValue.fromSignAndAmount(
                currencySign,
                currencyValue.getNumber(),
            );
            this.allowanceEntity.setValue(amountAttribute, adjustedCurrencyValue);
        }
    }

    public hasAmout(): boolean {
        return true;
    }

    public isEditable(): boolean {
        return this.travelSection ? this.travelSection.isEditable() : this.isDeletable();
    }

    public isDeletable(): boolean {
        return BusinessTravel.EDITABLE_STATES.indexOf(this.getState()) >= 0;
    }

    public toValueObject(): object {
        const valuesObject = this.allowanceEntity.toValueObject();

        // Damit der DB-Index auf "allowanceUserOid" und "allowanceStartTime" bzw. "allowanceEndTime" auch bei Einzel-Belegspesen greift,
        // müssen "allowanceStartTime" und "allowanceEndTime" gesetzt sein, obwohl es keine Attribute von Belegspesen ist.
        valuesObject[BusinessTravel.START_DATE_TIME] = valuesObject[VoucherAllowance.RECORD_DATE];
        valuesObject[BusinessTravel.END_DATE_TIME] = valuesObject[VoucherAllowance.RECORD_DATE];

        return valuesObject;
    }

    /**
     * @returns Warnung durch letzte Attributänderungen (seit letzter Abfrage)
     */
    public getAndClearChangeWarningKey(): string {
        const errorKey = this.changeErrorKey;
        this.changeErrorKey = null;
        return errorKey;
    }
}
