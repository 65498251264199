import { TimeRecord } from "./TimeRecord";
import { ListRowModel } from "../../gui/list/ListRowModel";
import { EntityValue } from "../../entities/values/EntityValue";
import { SchemaDataType } from "../../common/schema/SchemaDataType";
import { AttributeDefinition } from "../../common/schema/AttributeDefinition";
import { ListViewModel } from "../../gui/list/ListViewModel";
import { Booking } from "./bookings/Booking";
import { StringValue } from "../../entities/values/StringValue";
import { DurationValue } from "../../entities/values/DurationValue";

export class TimeRecordListViewModel implements ListViewModel {
    private timeRecord: TimeRecord;

    constructor(givenTimeRecord: TimeRecord) {
        this.timeRecord = givenTimeRecord;
    }

    public countRows(): number {
        return 1;
    }

    public getRowModelByNo(rowNo: number): ListRowModel {
        return new TimeRecordListRowModel(this.timeRecord);
    }

    public getRowModelById(entityId: string): ListRowModel {
        return new TimeRecordListRowModel(this.timeRecord);
    }

    public isResultTruncated(): boolean {
        return false;
    }
}

export class TimeRecordListRowModel implements ListRowModel {
    private timeRecord: TimeRecord;

    constructor(givenTimeRecord: TimeRecord) {
        this.timeRecord = givenTimeRecord;
    }

    public getEntityId(name?: string): string {
        return this.timeRecord.getId();
    }

    public getDataType(name: string): SchemaDataType {
        if (name === "pspPath") {
            return SchemaDataType.LSTRING;
        } else if (name.indexOf("task__") === 0) {
            const booking: Booking = <Booking>this.timeRecord;
            if (booking.hasBookableElement()) {
                return this.getAttributeDefinition(name).getDataType();
                // return booking.getBookableElementAttributeDefinition(name).getDataType();
            } else {
                // Falls noch keine Aufgabe ausgewählt wurde, dann wollen wir nur Text ausgeben (Keine Aufgabe ausgewählt)
                return SchemaDataType.STRING;
            }
        }
        return this.timeRecord.getAttributeDefinition(name).getDataType();
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        if (name.indexOf("effortTaskType") != -1) {
            const taskType = this.timeRecord.getAvaliableTaskTypes();
            if (taskType && taskType.getSimpleValue() !== null) {
                const taskTyoeDefinition = this.timeRecord.getAttributeDefinition(name);
                const attrDefinnition = taskTyoeDefinition.getPlaintAttributeDefinition();
                attrDefinnition.classifier = JSON.parse(taskType.getString());
                return new AttributeDefinition(
                    taskTyoeDefinition.getType(),
                    taskTyoeDefinition.getSubtype(),
                    taskTyoeDefinition.getAttribute(),
                    attrDefinnition,
                );
            } else {
                const taskTyoeDefinition = this.timeRecord.getAttributeDefinition(name);
                const attrDefinnition = taskTyoeDefinition.getPlaintAttributeDefinition();
                attrDefinnition.classifier = [];
                attrDefinnition.options = [];
                const attributeDefinition = new AttributeDefinition(
                    taskTyoeDefinition.getType(),
                    taskTyoeDefinition.getSubtype(),
                    taskTyoeDefinition.getAttribute(),
                    attrDefinnition,
                );
                attributeDefinition.setEmptyClassifier();
                return attributeDefinition;
            }
        }

        if (name === "pspPath") {
            return this.timeRecord.getAttributeDefinition("name");
        } else if (name.indexOf("task__") === 0) {
            const booking: Booking = <Booking>this.timeRecord;
            if (booking.hasBookableElement()) {
                return booking.getBookableElementAttributeDefinition(name);
            } else {
                // Falls noch keine Aufgabe ausgewählt wurde, dann wollen wir nur Text ausgeben (Keine Aufgabe ausgewählt)
                return this.timeRecord.getAttributeDefinition("name");
            }
        }
        return this.timeRecord.getAttributeDefinition(name);
    }

    public getValue(name: string): EntityValue {
        if (name === "pspPath" && this.timeRecord.isBooking) {
            return new StringValue((<Booking>this.timeRecord).getDisplayPSPPath());
        } else if (name.indexOf("task__") === 0) {
            const booking: Booking = <Booking>this.timeRecord;
            if (booking.hasBookableElement()) {
                return booking.getBookableElementValue(name);
            } else {
                // Falls noch keine Aufgabe ausgewählt wurde, dann wollen wir nur Text ausgeben (Keine Aufgabe ausgewählt)
                return new StringValue("");
            }
        }
        return this.timeRecord.getValue(name);
    }

    public setValue(name: string, value: EntityValue): void {
        if (name.indexOf("task__remainingDuration") === 0) {
            const booking: Booking = <Booking>this.timeRecord;
            if (booking.hasBookableElement()) {
                const bookalbeElement = booking.getBookableElement();
                bookalbeElement.setRemainingEffort((value as DurationValue).getDurationInMinutes());
            }
        } else {
            this.timeRecord.setValue(name, value);
        }
    }

    public isEditable(): boolean {
        return true;
    }

    public isDeletable(): boolean {
        return false;
    }
}
