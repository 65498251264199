import { GUIElement } from "../GUIElement";
import { Image } from "../content/Image";
import { ListField } from "./ListField";
import { ListFieldFeedback, ListFieldFeedbackType } from "./ListFieldFeedback";
import { ListRowContext } from "./ListRowContext";
import { ListRowElementFactory } from "./ListRowElementFactory";

export class ListRowCompoundField implements GUIElement, ListField {
    private listRowContext: ListRowContext;

    private fieldIconName: string;

    private superContentsFields: ContentRowFields[] = [];

    private mainContentFields: ContentRowFields;

    private subContentsFields: ContentRowFields[] = [];

    private contentFields: ListField[] = [];

    private styleClasses: string[] = [];

    /**
     * @param listViewContext ListView-Kontext (I18n, Erzeugen von FormFields, ...)
     * @param listFieldSetRowDefinition Definition dieser ListFieldSet-Komponente als JSON ausgelesen aus einer Konfiguration
     */
    constructor(listRowContext?: ListRowContext, listRowFieldDefinition?: object) {
        this.listRowContext = listRowContext;

        if (listRowFieldDefinition) {
            this.applyDefinition(listRowFieldDefinition);
        }
    }

    public addStyleClass(styleClass: string): ListRowCompoundField {
        this.styleClasses.push(styleClass);
        return this;
    }

    private applyDefinition(listRowFieldDefinition: object): void {
        this.fieldIconName = listRowFieldDefinition["icon"];

        // Zusatzinhalte oberhalb
        const superContentDefinitions = listRowFieldDefinition["superContents"] || [];
        superContentDefinitions.forEach((superContentDefinition) => {
            this.superContentsFields.push(this.applyContentDefinitions(superContentDefinition));
        });

        // Hauptinhalt
        this.mainContentFields = this.applyContentDefinitions(
            listRowFieldDefinition["mainContent"],
        );

        // Zusatzinhalte unterhalb
        const subContentDefinitions = listRowFieldDefinition["subContents"] || [];
        subContentDefinitions.forEach((subContentDefinition) => {
            this.subContentsFields.push(this.applyContentDefinitions(subContentDefinition));
        });
    }

    private applyContentDefinitions(contentDefinition: object): ContentRowFields {
        const contentFields = new ContentRowFields();

        if (contentDefinition) {
            contentFields.setIconName(contentDefinition["icon"]);

            const leftContentDefinition = contentDefinition["left"];
            if (leftContentDefinition) {
                contentFields.setLeftField(
                    ListRowElementFactory.createRowRowElement(
                        this.listRowContext,
                        leftContentDefinition,
                    ),
                );
            }

            const rightContentDefinition = contentDefinition["right"];
            if (rightContentDefinition) {
                contentFields.setRightField(
                    ListRowElementFactory.createRowRowElement(
                        this.listRowContext,
                        rightContentDefinition,
                    ),
                );
            }
        }

        return contentFields;
    }

    public addSuperContentFields(
        iconName: string,
        leftField: ListField,
        rightField: ListField,
    ): ListRowCompoundField {
        this.superContentsFields.push(
            new ContentRowFields()
                .setIconName(iconName)
                .setLeftField(leftField)
                .setRightField(rightField),
        );
        return this;
    }

    public setMainContentFields(
        iconName: string,
        leftField: ListField,
        rightField: ListField,
    ): ListRowCompoundField {
        this.mainContentFields = new ContentRowFields()
            .setIconName(iconName)
            .setLeftField(leftField)
            .setRightField(rightField);
        return this;
    }

    public addSubContentFields(
        iconName: string,
        leftField: ListField,
        rightField: ListField,
    ): ListRowCompoundField {
        this.subContentsFields.push(
            new ContentRowFields()
                .setIconName(iconName)
                .setLeftField(leftField)
                .setRightField(rightField),
        );
        return this;
    }

    public compose($parent: JQuery): void {
        const $tableTag = $("<table>")
            .appendTo($parent)
            .addClass("listRowCompoundField")
            .addClass(this.styleClasses.join(" "));

        for (let i = 0; i < this.superContentsFields.length; i++) {
            const superContentFields = this.superContentsFields[i];
            this.composeContentRow($tableTag, i + 1, superContentFields, "superContent");
        }

        this.composeContentRow($tableTag, 0, this.mainContentFields, "mainContent");

        for (let i = 0; i < this.subContentsFields.length; i++) {
            const subContentFields = this.subContentsFields[i];
            this.composeContentRow($tableTag, i + 1, subContentFields, "subContent");
        }
    }

    private composeContentRow(
        $tableTag: JQuery,
        rowNo: number,
        contentRowFields: ContentRowFields,
        styleClass: string,
    ) {
        const $rowTag = $("<tr>").appendTo($tableTag).addClass(styleClass);

        this.composeIcon(rowNo, 1 + this.subContentsFields.length, contentRowFields, $rowTag);

        const $leftCellTag = $("<td>").appendTo($rowTag).addClass("leftContent");
        if (contentRowFields.getLeftField()) {
            this.composeField(contentRowFields.getLeftField(), $leftCellTag);
        }

        if (contentRowFields.getRightField()) {
            const $rightCellTag = $("<td>").appendTo($rowTag).addClass("rightContent");
            this.composeField(contentRowFields.getRightField(), $rightCellTag);
        } else {
            $leftCellTag.attr("colspan", "2");
        }
    }

    private composeIcon(
        rowNo: number,
        countRows: number,
        contentRowFields: ContentRowFields,
        $rowTag: JQuery,
    ): void {
        let iconName = null;
        let iconRowSpan = 1;
        // Icon vor Tabelle / vor allen Zeilen?
        if (this.fieldIconName && rowNo == 0) {
            iconName = this.fieldIconName;
            iconRowSpan = countRows;
        }
        // Icon vor diese Zeile?
        if (contentRowFields.getIconName()) {
            iconName = contentRowFields.getIconName();
        }
        if (iconName) {
            const $iconCellTag = $("<td>")
                .appendTo($rowTag)
                .addClass("icon")
                .attr("rowspan", iconRowSpan);

            new Image()
                .setImageName(iconName + ".svg") // TODO App - +".svg" später entfernen
                .compose($iconCellTag);
        }
    }

    private composeField(contentField: ListField, $cellTag: JQuery) {
        this.contentFields.push(contentField);
        contentField.compose($cellTag);
    }

    public updateValues(listFieldFeedback: ListFieldFeedback): void {
        this.contentFields.forEach((fieldElement) => fieldElement.updateValues(listFieldFeedback));
    }

    public onUpdateValues(
        updateValuesCallback: (feedbackType: ListFieldFeedbackType) => void,
    ): void {
        this.contentFields.forEach((fieldElement) =>
            fieldElement.onUpdateValues(updateValuesCallback),
        );
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}

class ContentRowFields {
    private iconName: string;

    private leftField: ListField;

    private rightField: ListField;

    public setIconName(iconName: string): ContentRowFields {
        this.iconName = iconName;
        return this;
    }

    public getIconName(): string {
        return this.iconName;
    }

    public setLeftField(leftField: ListField): ContentRowFields {
        this.leftField = leftField;
        return this;
    }

    public getLeftField(): ListField {
        return this.leftField;
    }

    public setRightField(rightField: ListField): ContentRowFields {
        this.rightField = rightField;
        return this;
    }

    public getRightField(): ListField {
        return this.rightField;
    }
}
