import { ConfigNode } from "../../core/Config/ConfigNode";

/**
 * Statische GUI-Definition der Zeiterfasungs-Formulare (z.B. für Buchen auf Aufgabe Ticket, Workflow, ...).
 */
export class TimeRecordingGUIDefinitions {
    public static BOOKING_LIST_VIEW_DEF = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "table",
                        field: {
                            icon: "icon-stopwatch-grey",
                            styleClass: "timeRecordTimeAttributes",
                            rows: [
                                {
                                    columns: [
                                        {
                                            class: "field",
                                            field: {
                                                label: "MobileApp.effortStart",
                                                name: "effortStart",
                                                mode: "edit",
                                            },
                                        },
                                        {
                                            class: "field",
                                            field: {
                                                name: "effortEnd",
                                                label: "MobileApp.effortEnd",
                                                mode: "edit",
                                            },
                                        },
                                        {
                                            class: "field",
                                            field: {
                                                name: "effortExpense",
                                                label: "MobileApp.effortExpense",
                                                mode: "edit",
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                ],
            },
            {
                label: "MobileApp.timerecording_detail.details",
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "icon-task-grey",
                            name: "effortTargetOid",
                            mode: "edit",
                            styleClass: "effortTargetOid_row",
                        },
                        link: {
                            path: "bookingtaskselection",
                            parameters: {
                                taskSelectBookingOid: { attribute: "oid" },
                                "return-path": { value: "timeRecordingDetail" },
                                "return-parameter-oid": { attribute: "oid" },
                            },
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            readonly: "readonly",
                            name: "pspPath",
                            placeholder: "MobileApp.OidField.emptyValueName.effortTargetOid",
                            mode: "display",
                            styleClass: "underlayingEffortTarget",
                        },
                    },
                    // Summen: Plan/Ist/Rest
                    {
                        class: "table",
                        field: {
                            name: "planBookedRemaingSums",
                            icon: "NONE",
                            styleClass: "timeRecordTimeAttributes planBookedRemainSumsAttributes",
                            rows: [
                                {
                                    columns: [
                                        {
                                            class: "field",
                                            field: {
                                                name: "task__planDuration",
                                                label: "MobileApp.indicatorSumDedicatedExpense",
                                                mode: "display",
                                            },
                                        },
                                        {
                                            class: "field",
                                            field: {
                                                name: "task__bookedDuration",
                                                label: "MobileApp.indicatorSumRealExpense",
                                                mode: "display",
                                            },
                                        },
                                        {
                                            class: "field",
                                            field: {
                                                name: "task__remainingDuration",
                                                label: "MobileApp.indicatorSumForecastExpense",
                                                mode: "display",
                                            },
                                            styleClass: "rightColumn",
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "description",
                            mode: "edit",
                        },
                    },
                ],
            },
            {
                label: "MobileApp.timerecording_detail.charableAndActivity",
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "effortBillability",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "effortActivity",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "effortWorkingTimeType",
                            mode: "edit",
                        },
                    },
                ],
            } /*{
                "label": "MobileApp.timerecording_detail.taskParameters",
                "fields": [
                    {
                        "class": "field",
                        "field": {
                            "icon": "NONE",
                            "label": "MobileApp.task.remainingDuration",
                            "labelIfUndefined" : "MobileApp.noBookingTarget",
                            "name": "task__remainingDuration",
                            "mode": "edit"
                        }
                    }, {
                        "class": "field",
                        "field": {
                            "icon": "NONE",
                            "name": "remainingDescription",
                            "mode": "edit"
                        }
                    },

                ]
            },*/,
            {
                fields: [
                    {
                        class: "compound",
                        styleClass: "hideNavigationToForecastButton",
                        field: {
                            icon: "icon-stopwatch-grey",
                            name: "forecast",
                            mainContent: {
                                left: {
                                    class: "label",
                                    field: {
                                        label: "MobileApp.addforecast.pagetitle",
                                    },
                                },
                            },
                        },
                        link: {
                            path: "timeRecordingDetail/forecast",
                            parameters: {
                                oid: { attribute: "oid" },
                                "return-path": { value: "timeRecordingDetail" },
                            },
                        },
                    },
                ],
            },
        ],
    };

    public static TICKET_LIST_VIEW_DEF = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "table",
                        field: {
                            icon: "icon-stopwatch-grey",
                            styleClass: "timeRecordTimeAttributes",
                            rows: [
                                {
                                    columns: [
                                        {
                                            class: "field",
                                            field: {
                                                label: "MobileApp.effortStart",
                                                name: "effortStart",
                                                mode: "edit",
                                            },
                                        },
                                        {
                                            class: "field",
                                            field: {
                                                name: "effortEnd",
                                                label: "MobileApp.effortEnd",
                                                mode: "edit",
                                            },
                                        },
                                        {
                                            class: "field",
                                            field: {
                                                name: "effortExpense",
                                                label: "MobileApp.effortExpense",
                                                mode: "edit",
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                ],
            },
            {
                label: "MobileApp.timerecording_detail.details",
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "icon-task-grey",
                            name: "effortTargetOid",
                            placeholder: "MobileApp.OidField.emptyValueName.ticketOid",
                            mode: "edit",
                            styleClass: "effortTargetOid_row",
                        },
                        link: {
                            path: "bookingTicketSelection",
                            parameters: {
                                ticketSelectBookingOid: { attribute: "oid" },
                                "return-path": { value: "timeRecordingDetail" },
                                "return-parameter-oid": { attribute: "oid" },
                            },
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            readonly: "readonly",
                            name: "pspPath",
                            placeholder: "MobileApp.OidField.emptyValueName.effortTargetOid",
                            mode: "display",
                            styleClass: "underlayingEffortTarget",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "description",
                            mode: "edit",
                        },
                    },
                ],
            },
            {
                label: "MobileApp.timerecording_detail.charableAndActivity",
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "effortBillability",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "effortActivity",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "effortWorkingTimeType",
                            mode: "edit",
                        },
                    },
                ],
            },
        ],
    };

    public static REQUIREMENT_LIST_VIEW_DEF = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "table",
                        field: {
                            icon: "icon-stopwatch-grey",
                            styleClass: "timeRecordTimeAttributes",
                            rows: [
                                {
                                    columns: [
                                        {
                                            class: "field",
                                            field: {
                                                label: "MobileApp.effortStart",
                                                name: "effortStart",
                                                mode: "edit",
                                            },
                                        },
                                        {
                                            class: "field",
                                            field: {
                                                name: "effortEnd",
                                                label: "MobileApp.effortEnd",
                                                mode: "edit",
                                            },
                                        },
                                        {
                                            class: "field",
                                            field: {
                                                name: "effortExpense",
                                                label: "MobileApp.effortExpense",
                                                mode: "edit",
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                ],
            },
            {
                label: "MobileApp.timerecording_detail.details",
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "icon-task-grey",
                            name: "effortTargetOid",
                            placeholder: "MobileApp.OidField.emptyValueName.requirementOid",
                            mode: "edit",
                            styleClass: "effortTargetOid_row",
                        },
                        link: {
                            path: "bookingRequirementSelection",
                            parameters: {
                                requirementSelectBookingOid: { attribute: "oid" },
                                "return-path": { value: "timeRecordingDetail" },
                                "return-parameter-oid": { attribute: "oid" },
                            },
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            readonly: "readonly",
                            name: "pspPath",
                            placeholder: "MobileApp.OidField.emptyValueName.effortTargetOid",
                            mode: "display",
                            styleClass: "underlayingEffortTarget",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "description",
                            mode: "edit",
                        },
                    },
                ],
            },
            {
                label: "MobileApp.timerecording_detail.charableAndActivity",
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "effortBillability",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "effortActivity",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "effortWorkingTimeType",
                            mode: "edit",
                        },
                    },
                ],
            },
        ],
    };

    public static APPOINTMENT_LIST_VIEW_DEF = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "table",
                        field: {
                            icon: "icon-stopwatch-grey",
                            styleClass: "timeRecordTimeAttributes",
                            rows: [
                                {
                                    columns: [
                                        {
                                            class: "field",
                                            field: {
                                                label: "MobileApp.effortStart",
                                                name: "effortStart",
                                                mode: "edit",
                                            },
                                        },
                                        {
                                            class: "field",
                                            field: {
                                                name: "effortEnd",
                                                label: "MobileApp.effortEnd",
                                                mode: "edit",
                                            },
                                        },
                                        {
                                            class: "field",
                                            field: {
                                                name: "effortExpense",
                                                label: "MobileApp.effortExpense",
                                                mode: "edit",
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                ],
            },
            {
                label: "MobileApp.timerecording_detail.details",
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "icon-task-grey",
                            name: "effortEventRefOid",
                            readonly: "readonly",
                            placeholder: "MobileApp.OidField.emptyValueName.appointmentOid",
                            mode: "display",
                            styleClass: "effortTargetOid_row",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            readonly: "readonly",
                            name: "pspPath",
                            placeholder: "MobileApp.OidField.emptyValueName.effortTargetOid",
                            mode: "display",
                            styleClass: "underlayingEffortTarget",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "description",
                            mode: "edit",
                        },
                    },
                ],
            },
            {
                label: "MobileApp.timerecording_detail.charableAndActivity",
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "effortBillability",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "effortActivity",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "effortWorkingTimeType",
                            mode: "edit",
                        },
                    },
                ],
            },
        ],
    };

    public static WORKFLOW_LIST_VIEW_DEF = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "table",
                        field: {
                            icon: "icon-stopwatch-grey",
                            styleClass: "timeRecordTimeAttributes",
                            rows: [
                                {
                                    columns: [
                                        {
                                            class: "field",
                                            field: {
                                                label: "MobileApp.effortStart",
                                                name: "effortStart",
                                                mode: "edit",
                                            },
                                        },
                                        {
                                            class: "field",
                                            field: {
                                                name: "effortEnd",
                                                label: "MobileApp.effortEnd",
                                                mode: "edit",
                                            },
                                        },
                                        {
                                            class: "field",
                                            field: {
                                                name: "effortExpense",
                                                label: "MobileApp.effortExpense",
                                                mode: "edit",
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                ],
            },
            {
                label: "MobileApp.timerecording_detail.details",
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "icon-task-grey",
                            name: "effortTargetOid",
                            placeholder: "MobileApp.OidField.emptyValueName.workflowOid",
                            mode: "display",
                            styleClass: "effortTargetOid_row",
                        },
                        link: {
                            path: "bookingWorkflowSelection",
                            parameters: {
                                workflowSelectBookingOid: { attribute: "oid" },
                                "return-path": { value: "timeRecordingDetail" },
                                "return-parameter-oid": { attribute: "oid" },
                            },
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            readonly: "readonly",
                            name: "pspPath",
                            placeholder: "MobileApp.OidField.emptyValueName.effortTargetOid",
                            mode: "display",
                            styleClass: "underlayingEffortTarget",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "description",
                            mode: "edit",
                        },
                    },
                ],
            },
            {
                label: "MobileApp.timerecording_detail.charableAndActivity",
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "effortBillability",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "effortActivity",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "effortWorkingTimeType",
                            mode: "edit",
                        },
                    },
                ],
            },
        ],
    };

    public static TIMESPAN_LIST_VIEW_DEF = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "table",
                        field: {
                            icon: "icon-stopwatch-grey",
                            styleClass: "timeRecordTimeAttributes",
                            rows: [
                                {
                                    columns: [
                                        {
                                            class: "field",
                                            field: {
                                                label: "MobileApp.timeSpanStartTime",
                                                name: "timeSpanStartTime",
                                                mode: "edit",
                                            },
                                        },
                                        {
                                            class: "field",
                                            field: {
                                                name: "timeSpanEndTime",
                                                label: "MobileApp.timeSpanEndTime",
                                                mode: "edit",
                                            },
                                        },
                                        {
                                            class: "field",
                                            field: {
                                                name: "timeSpanDuration",
                                                label: "MobileApp.timeSpanDuration",
                                                mode: "edit",
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                ],
            },
            {
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "description",
                            mode: "edit",
                        },
                    },
                ],
            },
        ],
    };

    public static TIMERECORDING_SUBMENU_CONFIG_NODE: ConfigNode = new ConfigNode({
        nodeName: "SubMenu",
        children: [
            {
                nodeName: "PageLink",
                children: [],
                Parameter: "new_timerecord=attendance",
                I18nLabel: "MobileApp.JTimeSpan.attendance",
                NavigationPath: "timeRecordingDetail",
                Name: "Attendance",
            },
            {
                nodeName: "PageLink",
                children: [],
                Parameter: "new_timerecord=pause",
                I18nLabel: "MobileApp.JTimeSpan.pause",
                NavigationPath: "timeRecordingDetail",
                Name: "Pause",
            },
            {
                nodeName: "PageLink",
                children: [],
                Parameter: "new_timerecord=task",
                I18nLabel: "MobileApp.JTask",
                NavigationPath: "timeRecordingDetail",
                Name: "Task",
            },
            {
                nodeName: "PageLink",
                children: [],
                Parameter: "new_timerecord=ticket",
                I18nLabel: "MobileApp.Ticket",
                NavigationPath: "timeRecordingDetail",
                Name: "Ticket",
            },
            {
                nodeName: "PageLink",
                children: [],
                Parameter: "new_timerecord=requirement",
                I18nLabel: "MobileApp.JRequirement",
                NavigationPath: "timeRecordingDetail",
                Name: "Requirement",
            },
            {
                nodeName: "PageLink",
                children: [],
                Parameter: "new_timerecord=workflow",
                I18nLabel: "MobileApp.JWorkflow",
                NavigationPath: "timeRecordingDetail",
                Name: "Workflow",
            },
        ],
        Position: "fixed",
        Orientation: "above",
        Name: "addNewTimeRecord",
    });

    public static TIMERECORDING_SUBMENU_DUMMY_CONFIG_NODE: ConfigNode = new ConfigNode({
        nodeName: "SubMenu",
        children: [
            {
                nodeName: "PageLink",
                children: [],
                Parameter: "template_timerecord=pause",
                I18nLabel: "MobileApp.JTimeSpan.pause",
                NavigationPath: "timeRecordingDetail",
                Name: "Pause",
            },
            {
                nodeName: "PageLink",
                children: [],
                Parameter: "template_timerecord=task",
                I18nLabel: "MobileApp.JTask",
                NavigationPath: "timeRecordingDetail",
                Name: "Task",
            },
            {
                nodeName: "PageLink",
                children: [],
                Parameter: "template_timerecord=ticket",
                I18nLabel: "MobileApp.Ticket",
                NavigationPath: "timeRecordingDetail",
                Name: "Ticket",
            },
            {
                nodeName: "PageLink",
                children: [],
                Parameter: "template_timerecord=requirement",
                I18nLabel: "MobileApp.JRequirement",
                NavigationPath: "timeRecordingDetail",
                Name: "Requirement",
            },
            {
                nodeName: "PageLink",
                children: [],
                Parameter: "template_timerecord=workflow",
                I18nLabel: "MobileApp.JWorkflow",
                NavigationPath: "timeRecordingDetail",
                Name: "Workflow",
            },
        ],
        Position: "fixed",
        Orientation: "selfAdjusted",
        Name: "addTimeRecordViaDummyBooking",
    });
}
