import { HttpRESTClient } from "../../util/http/HttpRESTClient";

export class PreferencesClient {
    public fetchPrefernces(age): Promise<object> {
        return new Promise((resolve, reject) => {
            new HttpRESTClient()
                .createRequest()
                .setMethod("GET")
                .addPath("/preferences/userprofile")
                .setParameter("age", age)
                .send()
                .then((result) => {
                    switch (result["status"]) {
                        case 200:
                            resolve(result["json"]);
                            break;
                        case 204:
                            resolve({});
                            break;
                        default:
                            reject(result);
                    }
                })
                .catch(reject);
        });
    }
}
