import { AccomodationAllowance } from "./records/AccomodationAllowance";
import { Allowance } from "./records/Allowance";
import { AllowanceClient } from "./AllowanceClient";
import { AllowancePool } from "./AllowancePool";
import { AllowanceRecordingTerms } from "./AllowanceRecordingTerms";
import { AllowanceSummary } from "./AllowanceSummary";
import { AppEventManager } from "../../core/AppEventManager";
import { ApplicationProperties } from "../../common/properties/ApplicationProperties";
import { BCSDate } from "../../common/BCSDate";
import { BusinessTravel } from "./records/BusinessTravel";
import { Component } from "../../core/Component";
import { DomainSyncState } from "../../common/properties/DomainSyncState";
import { I18n } from "../../common/i18n/I18n";
import { IndexedDB } from "../../database/IndexedDB";
import { KilometreAllowance } from "./records/KilometreAllowance";
import { Log } from "../../common/log/Log";
import { ProgressFeedback } from "../../util/progress/ProgressFeedback";
import { Registry } from "../../core/Registry";
import { RootAllowance } from "./records/RootAllowance";
import { Schema } from "../../common/schema/Schema";
import { SingletonComponent } from "../../core/SingletonComponent";
import { SyncState, SyncStateObjectType } from "../../sync/SyncState";
import { SyncStateManager } from "../../sync/SyncStateManager";
import { UserSession } from "../../common/auth/UserSession";
import { VoucherAllowance } from "./records/VoucherAllowance";
import { StringValue } from "../../entities/values/StringValue";

export class AllowanceManager implements Component, SingletonComponent {
    /** Symbolischer Name dieser Komponente */
    public static BCS_COMPONENT_NAME = "AllowanceManager";

    private static ALLOWANCES_SYNC_STATE_PROPERTY_KEY = "AllowancesSyncState";

    private indexedDB: IndexedDB;

    private applicationProperties: ApplicationProperties;

    private i18n: I18n;

    private schema: Schema;

    private userSession: UserSession;

    private allowancePool: AllowancePool;

    private allowanceRecordingTerms: AllowanceRecordingTerms;

    private allowancesDomainSyncState: DomainSyncState;

    private syncStateManager: SyncStateManager;

    private appEventManager: AppEventManager;

    /**
     * Cache für Spesenzusammenfassung: Anzahl Dienstreisen/Einzelbelege, laufende Dienstreise mit Fortschritt
     * Wird beim Abruf berechnet, geleert wenn Spesen von BCS importiert / in App gespeichert oder gelöscht werden.
     */
    private allowanceSummary: AllowanceSummary;

    public getDependencyNames(): string[] {
        return [
            IndexedDB.BCS_COMPONENT_NAME,
            ApplicationProperties.BCS_COMPONENT_NAME,
            I18n.BCS_COMPONENT_NAME,
            Schema.BCS_COMPONENT_NAME,
            UserSession.BCS_COMPONENT_NAME,
            SyncStateManager.BCS_COMPONENT_NAME,
            AppEventManager.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }): void {
        this.indexedDB = <IndexedDB>depencencyComponents[IndexedDB.BCS_COMPONENT_NAME];
        this.applicationProperties = <ApplicationProperties>(
            depencencyComponents[ApplicationProperties.BCS_COMPONENT_NAME]
        );
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.schema = <Schema>depencencyComponents[Schema.BCS_COMPONENT_NAME];
        this.userSession = <UserSession>depencencyComponents[UserSession.BCS_COMPONENT_NAME];
        this.syncStateManager = <SyncStateManager>(
            depencencyComponents[SyncStateManager.BCS_COMPONENT_NAME]
        );
        this.appEventManager = <AppEventManager>(
            depencencyComponents[AppEventManager.BCS_COMPONENT_NAME]
        );

        // Registriert Pseudo-Attribute nur zur Verwendung in App
        this.schema.registerPseudoAttribute(
            KilometreAllowance.TYPE,
            KilometreAllowance.SUBTYPE,
            KilometreAllowance.TRAVEL_DISTANCE_IS_FULLY_INVOICABLE,
            { datatype: "Bool" },
        );
        this.schema.registerPseudoAttribute(
            AccomodationAllowance.TYPE,
            AccomodationAllowance.SUBTYPE,
            AccomodationAllowance.ACCOMODATION_COUNT_IS_FULLY_INVOICABLE,
            { datatype: "Bool" },
        );

        this.allowancePool = new AllowancePool(
            this.indexedDB,
            this.applicationProperties,
            this.schema,
        );
    }

    public start(): Promise<void> {
        return Promise.resolve();
    }

    public notifyBeginUserSession(
        isOnline: boolean,
        progressFeedback?: ProgressFeedback,
    ): Promise<void> {
        // Cache für Spesenzusammenfassung zurücksetzen
        this.allowanceSummary = null;

        const partProgressFeedbacks = progressFeedback.getPartProgressFeedbacks(3);

        const self = this;
        return self
            .readAllowanceRecordingTermsAndSyncStateFromDatabase()
            .then(() => {
                return isOnline
                    ? self.sendAllowancesToBCS(partProgressFeedbacks[0])
                    : Promise.resolve();
            })
            .then(() => {
                return isOnline
                    ? self.readAllowanceRecordingTermsFromBCS(partProgressFeedbacks[1])
                    : Promise.resolve();
            })
            .then(() => {
                return isOnline
                    ? self.readAllowancesFromBCS(partProgressFeedbacks[2])
                    : Promise.resolve();
            });
    }

    public synchronize(progressFeedback?: ProgressFeedback): Promise<void> {
        const partProgressFeedbacks = progressFeedback.getPartProgressFeedbacks(3);

        const self = this;
        return this.sendAllowancesToBCS(partProgressFeedbacks[0])
            .then(() => self.readAllowanceRecordingTermsFromBCS(partProgressFeedbacks[1]))
            .then(() => self.readAllowancesFromBCS(partProgressFeedbacks[2]));
    }

    private async readAllowanceRecordingTermsAndSyncStateFromDatabase(): Promise<void> {
        this.allowanceRecordingTerms = await this.allowancePool.readAllowanceRecordingTermsFromDB(
            this.userSession.getCurrentUserOid(),
        );

        this.allowancesDomainSyncState = await DomainSyncState.fetchFromApplicationProperties(
            AllowanceManager.ALLOWANCES_SYNC_STATE_PROPERTY_KEY,
            this.applicationProperties,
            this.userSession.getCurrentUserOid(),
        );
    }

    /**
     * In App geänderte Dienstreisen/Einzelbelege in BCS ändern
     */
    private async sendAllowancesToBCS(progressFeedback: ProgressFeedback): Promise<void> {
        try {
            // Alle SyncStates mit Änderungen in App holen
            const allowanceSyncStatesWithChanges =
                await this.syncStateManager.readSyncAllStatesToBeSentToBCS(
                    SyncStateObjectType.Allowance,
                );
            if (allowanceSyncStatesWithChanges.length == 0) {
                progressFeedback.notifyProgress(100, 100);
                return Promise.resolve();
            }

            // Einsammeln der geänderten und zu löschen SyncStates
            const allowanceSyncStatesUpdated: SyncState[] = [];
            const allowanceSyncStateIdsDeleted: string[] = [];

            // Neue und geänderte Dienstreisen und Einzelbelege in BCS speichern
            await this.sendNewAndEditedAllowancesToBCS(
                allowanceSyncStatesWithChanges,
                allowanceSyncStatesUpdated,
                progressFeedback,
            );

            // Gelöschte Dienstreisen und Einzelbelege in BCS löschen
            await this.sendDeletedAllowancesToBCS(
                allowanceSyncStatesWithChanges,
                allowanceSyncStatesUpdated,
                allowanceSyncStateIdsDeleted,
            );

            // SyncStates in DB speichern
            await this.syncStateManager.storeSyncStates(allowanceSyncStatesUpdated);
            await this.syncStateManager.deleteSyncStates(allowanceSyncStateIdsDeleted);
        } catch (error) {
            Log.error("[AllowanceManager] SendAllowancesToBCS failed: " + error, { error: error });
        }
    }

    /**
     * Neue und geänderte Dienstreisen und Einzelbelege in BCS speichern
     *
     * @param allowanceSyncStatesWithChanges Alle SyncStates mit Änderungen
     * @param allowanceSyncStatesUpdated Einsammeln der geänderten SyncStates
     */
    private async sendNewAndEditedAllowancesToBCS(
        allowanceSyncStatesWithChanges: SyncState[],
        allowanceSyncStatesUpdated: SyncState[],
        progressFeedback: ProgressFeedback,
    ): Promise<void> {
        // SyncStates für neue oder bearbeitete Dienstreisen und Einzelbelege raussuchen
        const allowanceSyncStatesWithCreateAndEdit = allowanceSyncStatesWithChanges.filter(
            (syncState) => !syncState.isDeleted(),
        );

        // Neue und geänderte Dienstreisen und Einzelbelege per Id laden
        const changedAllowances: RootAllowance[] = [];
        for (let i = 0; i < allowanceSyncStatesWithCreateAndEdit.length; i++) {
            const syncState = allowanceSyncStatesWithCreateAndEdit[i];
            changedAllowances.push(await this.fetchAllowanceById(syncState.getId()));
        }

        // Neue und geänderte Dienstreisen und Einzelbelege per REST an BCS senden
        const changedAllowanceValueObjects = changedAllowances.map((allowance) =>
            allowance.toValueObject(true),
        );
        const restSaveResult = await new AllowanceClient().sendAllowancesToBCS(
            changedAllowanceValueObjects,
            progressFeedback,
        );

        // SyncStates je nach Ergebnis als Erfolg oder Fehler markieren
        restSaveResult.updateSyncStates(allowanceSyncStatesWithCreateAndEdit);
        allowanceSyncStatesWithCreateAndEdit.forEach((syncState) =>
            allowanceSyncStatesUpdated.push(syncState),
        );
    }

    /**
     * Gelöschte Dienstreisen und Einzelbelege in BCS löschen
     *
     * @param allowanceSyncStatesWithChanges Alle SyncStates mit Änderungen
     * @param allowanceSyncStatesUpdated Einsammeln der geänderten  SyncStates
     * @param allowanceSyncStateIdsDeleted Einsammeln der zu löschen SyncStates
     */
    private async sendDeletedAllowancesToBCS(
        allowanceSyncStatesWithChanges: SyncState[],
        allowanceSyncStatesUpdated: SyncState[],
        allowanceSyncStateIdsDeleted: string[],
    ): Promise<void> {
        // SyncStates für zu löschende Dienstreisen und Einzelbelege raussuchen
        const allowanceSyncStatesWithDeletion = allowanceSyncStatesWithChanges.filter((syncState) =>
            syncState.isDeleted(),
        );

        // Gelöschte Dienstreisen und Einzelbelege per REST in BCS löschen
        for (let i = 0; i < allowanceSyncStatesWithDeletion.length; i++) {
            const deleteSyncState = allowanceSyncStatesWithDeletion[i];
            const deleteAllowanceId = deleteSyncState.getId();
            const restSaveResult = await new AllowanceClient().deleteAllowanceInBCS(
                deleteAllowanceId,
            );

            if (restSaveResult.countErrors() == 0) {
                allowanceSyncStateIdsDeleted.push(deleteSyncState.getId());
            } else {
                restSaveResult.updateSyncStates([deleteSyncState]);

                allowanceSyncStatesUpdated.push(deleteSyncState);
            }
        }
    }

    /**
     * Frage Spesenmodalitäten in BCS ab (sofern es Änderungeng gab).
     */
    private async readAllowanceRecordingTermsFromBCS(
        progressFeedback: ProgressFeedback,
    ): Promise<void> {
        try {
            // Fragt Spesenmodalitäten (Spesenerfassung aktiv, Spesen-Aufgaben, Belegarten, Arbeitsland, ...) in BCS ab (kein Ergebnis, wenn keine Änderungen)
            const allowanceRecordingTermsValueObject =
                await new AllowanceClient().readAllowanceRecordingTermsFromBCS(
                    this.allowanceRecordingTerms.getCheckSum(),
                    progressFeedback,
                );
            if (allowanceRecordingTermsValueObject) {
                this.allowanceRecordingTerms = new AllowanceRecordingTerms(
                    allowanceRecordingTermsValueObject,
                );
                await this.allowancePool.writeAllowanceRecordingTermsToDB(
                    this.userSession.getCurrentUserOid(),
                    this.allowanceRecordingTerms,
                );
            }
        } catch (error) {
            Log.error("[AllowanceManager] ReadAllowanceRecordingTermsFromBCS failed: " + error, {
                error: error,
            });
        }
    }

    /**
     * Frage Dienstreisen und Einzelbelege in BCS ab (sofern es Änderungen gab).
     * Löscht in der App nicht mehr vorhandene Dienstreisen und Einzelbelege.
     */
    private async readAllowancesFromBCS(progressFeedback: ProgressFeedback): Promise<void> {
        try {
            // Fragt Spesen aus BCS ab (Ergebnis leer, wenn keine Änderungen)
            const allowancesResult = await new AllowanceClient().readAllowancesFromBCS(
                this.allowancesDomainSyncState.getSyncStateTimestamp(),
                progressFeedback,
            );

            let importedAllowanceIds = [];
            if (allowancesResult.syncContainsChanges) {
                // Sofern die Abfrage der Spesen aus BCS ein Ergebnis liefert (kann auch ein leeres Eergebnis sein, z.B. wenn einzige Spese gelöscht)

                if (allowancesResult.allowancesValueObjects.length > 0) {
                    // Sofern die Abfrage der Spesen aus BCS neue oder geänderte Spesen liefert

                    // Speichert alle von BCS importierten Spesen in der DB
                    await this.allowancePool.importAllAllowancesToDB(
                        allowancesResult.allowancesValueObjects,
                    );

                    // Erstellt für erstmalig aus BCS importiert Objekte SyncStates (mit Status "Kein Änderungen").
                    importedAllowanceIds = allowancesResult.allowancesValueObjects.map(
                        (allowancesValueObject) => allowancesValueObject["oid"],
                    );
                    const subtypesById: { [key: string]: string } = {};
                    allowancesResult.allowancesValueObjects.forEach(
                        (allowancesValueObject) =>
                            (subtypesById[allowancesValueObject["oid"]] =
                                allowancesValueObject["subtyp"]),
                    );
                    await this.syncStateManager.createSyncStatesForNewImportedObjects(
                        importedAllowanceIds,
                        SyncStateObjectType.Allowance,
                        subtypesById,
                    );
                }

                // Sucht Ids für Dienstreisen und Einzelbelege, die bei den aktuell aus BCS importierten Objekte nicht mehr enthalten sind
                // und löscht diese Dienstreisen und Einzelbelege direkt in DB ohne Prüfung des SyncStates.
                const notImportedAllowanceIds =
                    await this.syncStateManager.findAndDeleteSyncStatesMissingInImportedObjects(
                        importedAllowanceIds,
                        SyncStateObjectType.Allowance,
                    );
                await this.allowancePool.deleteAllowancesFromDB(notImportedAllowanceIds);

                // Löscht mit gelöschten Spesen verknüpfte Objekte (hier Belegdateien von Belegspesen)
                await this.appEventManager.triggerObjectsDeleted(
                    VoucherAllowance.TYPE,
                    notImportedAllowanceIds,
                    true,
                );

                // Markt sich den Zeitstempel der letzen Anfrage von Spesen aus BCS
                await this.allowancesDomainSyncState.setSyncStateTimestamp(
                    allowancesResult["syncStateTimestamp"],
                );

                // Cache für Spesenzusammenfassung zurücksetzen
                this.allowanceSummary = null;
            }
        } catch (error) {
            Log.error("[AllowanceManager] ReadAllowancesFromBCS failed: " + error, {
                error: error,
            });
        }
    }

    public getAllowanceRecordingTerms(): AllowanceRecordingTerms {
        return this.allowanceRecordingTerms;
    }

    public createBusinessTravel(): Promise<BusinessTravel> {
        return new Promise((resolve, reject) => {
            const businessTravel = BusinessTravel.create(
                this.allowanceRecordingTerms,
                this.schema,
                this.userSession.getCurrentUserOid(),
            );

            return this.storeAllowance(businessTravel, true)
                .then(() => {
                    resolve(businessTravel);
                })
                .catch(reject);
        });
    }

    public createVoucherAllowance(): Promise<VoucherAllowance> {
        return new Promise((resolve, reject) => {
            const typeSubtypeDefinition = this.schema.getTypeSubtypeDefinition(
                VoucherAllowance.TYPE,
                VoucherAllowance.SUBTYPE,
            );
            const voucherAllowance = VoucherAllowance.create(
                null,
                this.allowanceRecordingTerms,
                typeSubtypeDefinition,
                this.userSession.getCurrentUserOid(),
                null,
            );

            return this.storeAllowance(voucherAllowance, true)
                .then(() => {
                    resolve(voucherAllowance);
                })
                .catch(reject);
        });
    }

    public fetchAllowanceById(allowanceId: string): Promise<RootAllowance> {
        return this.allowancePool.readAllowanceFromDB(
            allowanceId,
            this.userSession.getCurrentUserOid(),
        );
    }

    public fetchAllAllowances(): Promise<RootAllowance[]> {
        return this.allowancePool.readAllAllowancesFromDB(this.userSession.getCurrentUserOid());
    }

    public async fetchBusinessTravelsAndVoucherAllowances(): Promise<{
        businessTravels: BusinessTravel[];
        voucherAllowances: VoucherAllowance[];
    }> {
        const allowances = await this.fetchAllAllowances();
        const businessTravels = <BusinessTravel[]>(
            allowances.filter((allowance) => allowance.getSubtype() == BusinessTravel.SUBTYPE)
        );
        const voucherAllowances = <VoucherAllowance[]>(
            allowances.filter((allowance) => allowance.getSubtype() == VoucherAllowance.SUBTYPE)
        );
        return { businessTravels: businessTravels, voucherAllowances: voucherAllowances };
    }

    public async storeAllowance(allowance: Allowance, isNew: boolean = false): Promise<void> {
        await this.allowancePool.writeAllowanceToDB(allowance);

        // Spesen anhand ihres SyncStates als geändert markieren
        const allowanceSyncState = await this.syncStateManager.getOrCreateSyncStateById(
            allowance.getId(),
            SyncStateObjectType.Allowance,
        );
        allowanceSyncState.setSubtype(allowance.getSubtype());
        allowanceSyncState.markChanged(isNew);
        await this.syncStateManager.storeSyncState(allowanceSyncState);

        if (allowance.getSubtype() == BusinessTravel.SUBTYPE) {
            const deletedVoucherAllowanceIds = (<BusinessTravel>(
                allowance
            )).getDeletedVoucherAllowanceIds();

            if (deletedVoucherAllowanceIds.length > 0) {
                // Objekt-Gelöscht-Events auslösen, damit indirekt die Beleganhänge der gelöschten Belegspesen gelöscht werden.
                await this.triggerEventToDeleteVoucherFiles(deletedVoucherAllowanceIds);
            }
        }

        // Cache für Spesenzusammenfassung zurücksetzen
        this.allowanceSummary = null;
    }

    public async deleteBusinessTravels(businessTravelIds: string[]): Promise<void> {
        // Dienstreisen laden, um die Ids der indirekt gelöschten Belegspesen einzusammeln
        let voucherAllowanceIds: string[] = [];
        for (let i = 0; i < businessTravelIds.length; i++) {
            const businessTravel = await (<Promise<BusinessTravel>>(
                this.fetchAllowanceById(businessTravelIds[i])
            ));
            voucherAllowanceIds = voucherAllowanceIds.concat(
                businessTravel.getVoucherAllowanceIds(),
            );
        }

        // Dienstreisen löschen
        await this.deleteAllowances(businessTravelIds);

        // Objekt-Gelöscht-Events auslösen, damit indirekt die Beleganhänge der gelöschten Belegspesen gelöscht werden.
        await this.triggerEventToDeleteVoucherFiles(voucherAllowanceIds);
    }

    public async deleteSingleVoucherAllowance(voucherAllowanceIds: string[]): Promise<void> {
        await this.deleteAllowances(voucherAllowanceIds);

        // Objekt-Gelöscht-Events auslösen, damit indirekt die Beleganhänge der gelöschten Belegspesen gelöscht werden.
        await this.triggerEventToDeleteVoucherFiles(voucherAllowanceIds);
    }

    private async deleteAllowances(deleteAllowanceIds: string[]): Promise<void> {
        if (deleteAllowanceIds.length == 0) {
            return Promise.resolve();
        }

        // Abholen des SyncStates für zu löschende Spesen
        const allowanceSyncStates =
            await this.syncStateManager.getSyncStatesByIds(deleteAllowanceIds);

        // Einsammeln der als zu löschen zu markierenden und der zu löschenden SyncStates
        const updateAllowanceSyncStates: SyncState[] = [];
        const deleteAllowanceSyncStateIds: string[] = [];

        for (let i = 0; i < allowanceSyncStates.length; i++) {
            const allowanceSyncState = allowanceSyncStates[i];

            if (allowanceSyncState.isNew()) {
                // In App neu erstellte Spesen, die noch nicht an BCS gesandt wurden, können einfach zusammen mit ihrem SyncState gelöscht werden.
                deleteAllowanceSyncStateIds.push(allowanceSyncState.getId());
            } else {
                // Bereits an BCS vorhandene Spesen, müssen anhand ihres SyncState als in BCS zu löschen markiert werden.
                allowanceSyncState.markDeleted();
                updateAllowanceSyncStates.push(allowanceSyncState);
            }
        }

        // SyncStates als zu löschen markieren oder löschen
        await this.syncStateManager.storeSyncStates(updateAllowanceSyncStates);
        await this.syncStateManager.deleteSyncStates(deleteAllowanceSyncStateIds);

        // Spesen in DB löschen
        await this.allowancePool.deleteAllowancesFromDB(deleteAllowanceIds);

        // Cache für Spesenzusammenfassung zurücksetzen
        this.allowanceSummary = null;
    }

    /**
     * Objekt-Gelöscht-Events auslösen, damit indirekt die Beleganhänge der gelöschten Belegspesen gelöscht werden.
     *
     * @param voucherAllowanceIds Ids der gelöschten Belegspesen
     */
    private async triggerEventToDeleteVoucherFiles(voucherAllowanceIds: string[]): Promise<void> {
        await this.appEventManager.triggerObjectsDeleted(
            VoucherAllowance.TYPE,
            voucherAllowanceIds,
        );
    }

    /**
     * @returns Spesenzusammenfassung: Anzahl Dienstreisen/Einzelbelege, laufende Dienstreise mit Fortschritt
     */
    public async fetchAllowanceSummary(): Promise<AllowanceSummary> {
        if (!this.allowanceSummary) {
            const userOid = this.userSession.getCurrentUserOid();
            const todayStartOfDay = BCSDate.today();
            const todayEndOfDay = BCSDate.today().asEndOfDay();

            const countBusinessTravels = await this.allowancePool.countAllowances(
                userOid,
                BusinessTravel.SUBTYPE,
            );
            const countSingleVouchers = await this.allowancePool.countAllowances(
                userOid,
                VoucherAllowance.SUBTYPE,
            );
            const ongoingBusinessTravelsAndVouchers =
                await this.allowancePool.queryAllowanceByDatesFromDB(
                    userOid,
                    todayStartOfDay,
                    todayEndOfDay,
                );

            this.allowanceSummary = new AllowanceSummary()
                .setAllowanceRecordingAvailable(
                    this.getAllowanceRecordingTerms().isAllowanceRecordingAvailable(),
                )
                .setCountBusinessTravels(countBusinessTravels)
                .setCountSingleVouchers(countSingleVouchers)
                .setOngoingAllowances(ongoingBusinessTravelsAndVouchers);
        }

        return this.allowanceSummary;
    }
}

Registry.registerSingletonComponent(AllowanceManager.BCS_COMPONENT_NAME, AllowanceManager);
