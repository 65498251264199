import { HttpURL } from "./HttpURL";
import { HttpRESTRequest } from "./HttpRESTRequest";

export class HttpRESTClient {
    private baseURL: HttpURL;

    // unsafe==true meint REST-Requests ohne Login
    constructor(unsafe = false) {
        // Aktuelle URL zerlegen
        this.baseURL = new HttpURL(window.location.href);
        let path = this.baseURL.getPath();
        if (path.length > 0 && path.substring(path.length - 1) == "/") {
            path = path.substring(0, path.length - 1);
        }
        const pathParts = path.substring(1).split(/\//);

        // ggf. Dateinamen entfernen (z.B. "index.html")
        if (pathParts.length > 0 && pathParts[pathParts.length - 1].indexOf(".html") > 0) {
            pathParts.pop();
        }

        // Verzeichnis für App entfernen ("app")
        pathParts.pop();

        pathParts.push("app");
        if (!unsafe) {
            pathParts.push("rest");
        }

        this.baseURL.setPath("/" + pathParts.join("/"));
    }

    public createRequest(): HttpRESTRequest {
        const request = new HttpRESTRequest(this.baseURL);
        return request;
    }
}
