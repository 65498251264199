import { I18n } from "../../common/i18n/I18n";
import { FormFieldFactory } from "../form/FormFieldFactory";
import { ListViewModel } from "./ListViewModel";
import { ListRowContext } from "./ListRowContext";
import { EmptyListViewModel } from "./EmptyListViewModel";

export class ListViewContext {
    private i18n: I18n;

    private listViewModel: ListViewModel = new EmptyListViewModel();

    private formFieldFactory: FormFieldFactory = new FormFieldFactory();

    public setI18n(i18n: I18n): ListViewContext {
        this.i18n = i18n;
        return this;
    }

    public setModel(listViewModel: ListViewModel): ListViewContext {
        this.listViewModel = listViewModel;
        return this;
    }

    public setFormFieldFactory(formFieldFactory: FormFieldFactory): ListViewContext {
        this.formFieldFactory = formFieldFactory;
        return this;
    }

    public getI18n(): I18n {
        return this.i18n;
    }

    public getListModel(): ListViewModel {
        return this.listViewModel;
    }

    public getFormFieldFactory(): FormFieldFactory {
        return this.formFieldFactory;
    }

    public getListRowContext(rowNo: number): ListRowContext {
        return new ListRowContext()
            .setI18n(this.getI18n())
            .setModel(this.getListModel().getRowModelByNo(rowNo))
            .setFormFieldFactory(this.getFormFieldFactory());
    }

    public checkComplete(): void {
        if (!this.i18n || !this.listViewModel || !this.formFieldFactory) {
            throw new Error("ListViewContext::NotComplete");
        }
    }
}
