import { Entity } from "../../../entities/Entity";
import { Schema } from "../../../common/schema/Schema";
import { IdGenerator } from "../../../util/text/IdGenerator";
import { DateValue } from "../../../entities/values/DateValue";
import { NumberValue } from "../../../entities/values/NumberValue";
import { TimeRecord } from "../TimeRecord";
import { AttributeDefinition } from "../../../common/schema/AttributeDefinition";
import { EntityValue } from "../../../entities/values/EntityValue";
import { BCSDate } from "../../../common/BCSDate";
import { TimeAttibutesDefinitions } from "../TimeAttibutesDefinitions";
import { OidValue } from "../../../entities/values/OidValue";
import { SyncState } from "../../../sync/SyncState";
import { TimesheetEntry } from "../timesheet/TimesheetEntry";
import { BooleanValue } from "../../../entities/values/BooleanValue";

export enum BookingType {
    Task,
    Ticket,
    Requirement,
    Workflow,
    Appointment,
    TimeSpan,
}

export class Booking implements TimeRecord {
    public static TYPE = "JEffort";
    public static SUBTYPE = "Personal";
    public static STARTED_BOOKING: string = "startedBooking";
    private bookingEntity: Entity;
    private bookableElement: TimesheetEntry;
    private readonly MISCELLANEOUS: string = "miscellaneous";
    private isDurationOnly: boolean = false;

    private syncState: SyncState = null;

    private displayPSPPath: string = null;

    constructor(schema: Schema, bookingObject?: object) {
        const typeSubtypeDefinition = schema.getTypeSubtypeDefinition("JEffort", "Personal");

        let bookingValues = null;
        if (bookingObject) {
            bookingValues = bookingObject;
        } else {
            bookingValues = {
                oid: IdGenerator.createId() + "_JEffort",
                typ: "JEffort",
                subtyp: "Personal",
                startedBooking: false,
            };
            //  this.bookingEntity.setValue(Booking.STARTED_BOOKING,new BooleanValue(false));
        }

        if (bookingValues["effortTargetSubtyp"] && !bookingValues["bookingType"]) {
            bookingValues["bookingType"] =
                BookingType[
                    "" +
                        bookingObject["effortTargetSubtyp"].charAt(0).toUpperCase() +
                        bookingObject["effortTargetSubtyp"].slice(1)
                ];
        }
        this.bookingEntity = new Entity(typeSubtypeDefinition, bookingValues);
    }

    public static create(schema: Schema, userOid: string, newBookingType: string): Booking {
        const typeSubtypeDefinition = schema.getTypeSubtypeDefinition(
            Booking.TYPE,
            Booking.SUBTYPE,
        );

        const bookingObject = {
            oid: IdGenerator.createId() + "_" + Booking.TYPE,
            typ: Booking.TYPE,
            subtyp: Booking.SUBTYPE,
            userOid: userOid,
            effortTargetSubtyp: newBookingType,
            effortUserOid: userOid,
            effortStart: "0:-1",
            effortEnd: "0:-1",
            startedBooking: false,
        };

        const newBooking = new Booking(schema, bookingObject);

        // TODO Defaults setzen ...

        return newBooking;
    }

    public getId(): string {
        return this.bookingEntity.getId();
    }

    public setId(id: string): void {
        this.bookingEntity.setValue("oid", new OidValue(id));
    }

    public getSubtype(): string {
        return this.bookingEntity.getString("subtyp");
    }

    public getDate(): Date {
        const dateValue = <DateValue>this.bookingEntity.getValue("effortDate");
        return dateValue.getDate();
    }

    /**
     * Gibt den Subtype des Buchungsziels zurück.
     *
     * Wird beispielsweise verwendet um bei den Buchungen des Tages herrauszufinden,
     * was auf die Anwesenheitsaufgabe("miscellaneous") ging und was richtige Buchungen sind.
     * Alle ungebuchten Restaufwände gehen auf diese "Reste"-Aufgabe.
     *
     */
    public getTargetSubtype(): string {
        return this.bookingEntity.getString("effortTargetSubtyp");
    }

    /**
     * Gibt zurück ob es sich um die "Reste"-Augabe handelt.
     *
     * Wird beispielsweise verwendet um bei den Buchungen des Tages herrauszufinden,
     * was auf die Anwesenheitsaufgabe("miscellaneous") ging und was richtige Buchungen sind.
     * Alle ungebuchten Restaufwände gehen auf diese "Reste"-Aufgabe.
     */
    public isMiscellaneousEffort(): boolean {
        return this.getTargetSubtype() === this.MISCELLANEOUS;
    }

    public getEffortExpense(): number {
        const effortExpense: number = this.bookingEntity.getNumber("effortExpense");
        return effortExpense;
    }

    public setEffortExpense(value: number): Booking {
        this.bookingEntity.setValue("effortExpense", new NumberValue(value));
        return this;
    }

    public getStartTime(): Date {
        const startTimeRaw: string = this.bookingEntity.getString("effortStart");
        const hourAndMinute = startTimeRaw.split(":");
        let date = this.getDate();
        if (parseInt(hourAndMinute[1]) == -1) {
            date = null;
            this.isDurationOnly = true;
        } else {
            date.setHours(parseInt(hourAndMinute[0]));
            date.setMinutes(parseInt(hourAndMinute[1]));
        }
        return date;
    }

    public getEndTime(): Date {
        const startTimeRaw: string = this.bookingEntity.getString("effortEnd");
        const hourAndMinute = startTimeRaw.split(":");
        let date = this.getDate();

        if (parseInt(hourAndMinute[1]) == -1) {
            date = null;
            this.isDurationOnly = true;
        } else {
            date.setHours(parseInt(hourAndMinute[0]));
            date.setMinutes(parseInt(hourAndMinute[1]));
        }

        return date;
    }

    public isEndTimeDefined(): boolean {
        const endTime: EntityValue = this.bookingEntity.getValue("effortEnd");
        return endTime.isDefined();
    }

    public isStartTimeDefined(): boolean {
        const endTime: EntityValue = this.bookingEntity.getValue("effortStart");
        return endTime.isDefined();
    }

    public hasDurationOnly(): boolean {
        if (this.isStartTimeDefined() && this.isEndTimeDefined()) {
            return false;
        } else {
            return true;
        }
    }

    public getEffortTargetId(): string {
        return this.bookingEntity.getString("effortTargetOid");
    }

    public getAnnotationID(): string {
        return this.bookingEntity.getString("effortAnnotationOid");
    }

    public getRequirementID(): string {
        return this.bookingEntity.getString("effortRequirementOid");
    }

    public getWorkflowID(): string {
        return this.bookingEntity.getString("effortWorkflowOid");
    }

    public getAppointmentID(): string {
        return this.bookingEntity.getString("effortEventRefOid");
    }

    public toValueObject(): object {
        return this.bookingEntity.toValueObject();
    }

    public toTouchedValueObject(touchedFields: string[]): object {
        return this.bookingEntity.toTouchedValueObject(touchedFields);
    }

    public isPause(): boolean {
        return false;
    }

    public isDummy(): boolean {
        return false;
    }

    public isAppointment(): boolean {
        return this.getBookingType() === BookingType.Appointment;
    }

    public isBooking(): boolean {
        return true;
    }

    public isAnnotationBooking(): boolean {
        return this.bookingEntity.getValue("effortAnnotationOid").getSimpleValue() !== null;
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return this.bookingEntity.getTypeSubtypeDefinition().getAttributeDefinition(name);
    }

    public getRequiredAttributes(): string[] {
        return this.bookingEntity.getTypeSubtypeDefinition().getRequiredAttributes();
    }

    public getValue(name: string): EntityValue {
        return this.bookingEntity.getValue(name);
    }

    public setValue(name: string, value: EntityValue): void {
        this.bookingEntity.setValue(name, value);
    }

    public setDate(date: BCSDate): TimeRecord {
        const dateValue: DateValue = DateValue.parseFromString(date.getISODate());
        this.bookingEntity.setValue("effortDate", dateValue);
        return this;
    }

    public getBookingType(): BookingType {
        const entityBookingType = this.bookingEntity.getString("bookingType");
        if (entityBookingType) {
            return +entityBookingType as BookingType;
        } else {
            // Fallback Variante:
            if (this.bookingEntity.getValue("effortWorkflowOid").getSimpleValue() !== null) {
                return BookingType.Workflow;
            } else if (this.bookingEntity.getValue("effortEventRefOid").getSimpleValue() !== null) {
                return BookingType.Appointment;
            } else if (
                this.bookingEntity.getValue("effortAnnotationOid").getSimpleValue() !== null
            ) {
                return BookingType.Ticket;
            } else if (
                this.bookingEntity.getValue("effortRequirementOid").getSimpleValue() !== null
            ) {
                return BookingType.Requirement;
            } else if (this.bookingEntity.getValue("effortEventRefOid").getSimpleValue() !== null) {
                return BookingType.Requirement;
            }
        }

        return BookingType.Task;
    }

    public getAvaliableTaskTypes() {
        if (typeof this.bookableElement !== "undefined") {
            return this.bookableElement.getValue("task__effortTaskType");
        } else {
            return undefined;
        }
    }

    public setBookingType(bookingType: string | BookingType) {
        this.bookingEntity.setString("bookingType", bookingType.toString());
    }

    public getTimeAttibutesDefinitions(): TimeAttibutesDefinitions {
        return new TimeAttibutesDefinitions("effortStart", "effortEnd", "effortExpense");
    }

    public attachSyncState(syncState: SyncState): void {
        this.syncState = syncState;
    }

    /**
     *
     * @return syncState:boolean true => es gibt einen Synchronisations Stand
     */
    public hasSyncState(): boolean {
        return this.syncState !== null;
    }

    public getSyncState(): SyncState {
        return this.syncState;
    }

    public isSyncOnlyChangedInApp(): boolean {
        // TODO Jens und Bertram, darüber reden wie wir und ob wir SynchronisationsIssue und HasChanges anders visualisieren wollen.
        // visualisieren erstmal gleich.
        return this.syncState.isSyncOnlyChangedInApp() || this.syncState.hasSynchronisationIssue();
    }

    public toString(): string {
        return (
            this.getId() +
            "(" +
            this.getSubtype() +
            "): " +
            new BCSDate(this.getDate()).getISODate() +
            " " +
            new BCSDate(this.getStartTime()).format("HH:mm") +
            " - " +
            new BCSDate(this.getEndTime()).format("HH:mm")
        );
    }

    public getDisplayPSPPath(): string {
        if (this.displayPSPPath === null) {
            return "";
        }
        return this.displayPSPPath;
    }

    public setDisplayPSPPath(displaPSPPath: string): void {
        this.displayPSPPath = displaPSPPath;
    }

    public hasBookableElement(): boolean {
        if (typeof this.bookableElement === "undefined" || this.bookableElement === null) {
            return false;
        }
        return true;
    }

    public getBookableElementAttributeDefinition(name: string): AttributeDefinition | undefined {
        return this.bookableElement.getAttributeDefinition(name);
    }

    public getBookableElementValue(name: string) {
        return this.bookableElement.getValue(name);
    }

    public getBookableElement() {
        return this.bookableElement;
    }

    public setBookableElement(bookableElement: TimesheetEntry): void {
        this.bookableElement = bookableElement;
    }
}
