import { GUIElement } from "../GUIElement";
import { ListField } from "./ListField";
import { ListFieldFeedback, ListFieldFeedbackType } from "./ListFieldFeedback";

export class ListRowGUIElement implements GUIElement, ListField {
    private childGUIElements: GUIElement[] = [];

    public addContentElement(childGUIElement: GUIElement): ListRowGUIElement {
        this.childGUIElements.push(childGUIElement);
        return this;
    }

    public compose($parent: JQuery): void {
        this.childGUIElements.forEach((element) => element.compose($parent));
    }

    public onUpdateValues(
        updateValuesCallback: (feedbackType: ListFieldFeedbackType) => void,
    ): void {
        // nichts zu tun
    }

    public updateValues(listFieldFeedback: ListFieldFeedback): void {
        // nichts zu tun
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}
