import { GUIElement } from "../GUIElement";
import { FormField, formFieldChangeEventType, FormFieldMode } from "./FormField";
import { StringValue } from "../../entities/values/StringValue";
import { DateTimeValue } from "../../entities/values/DateTimeValue";
import { DateField } from "./DateField";
import { TimeField } from "./TimeField";
import { FlexBox } from "../flexbox/FlexBox";
import { I18n } from "../../common/i18n/I18n";
import { FormFieldAddOn } from "./FormFieldAddOn";
import { StringField } from "./StringField";

export class DateTimeField implements GUIElement, FormField {
    private name: string;

    private userLocale: string;

    private value: DateTimeValue;

    private displayDateTimeField: StringField;

    private dateField: DateField;

    private timeField: TimeField;

    private changeCallback: (changeEvent: formFieldChangeEventType) => void;

    constructor(i18n: I18n) {
        this.userLocale = i18n.getLocale();
        this.displayDateTimeField = <StringField>new StringField();
        this.dateField = <DateField>new DateField(i18n).onChange(this.fieldValueChanged.bind(this));
        this.timeField = new TimeField().onChange(this.fieldValueChanged.bind(this));
    }

    public setEntityId(entityId: string): FormField {
        this.displayDateTimeField.setEntityId(entityId);
        this.dateField.setEntityId(entityId);
        this.timeField.setEntityId(entityId);
        return this;
    }

    public setName(name: string): DateTimeField {
        this.name = name;
        this.displayDateTimeField.setEntityId(name);
        this.dateField.setName(name + "_date");
        this.timeField.setName(name + "_time");
        return this;
    }

    public setReadOnly(readOnly: boolean): DateTimeField {
        this.displayDateTimeField.setReadOnly(readOnly);
        return this;
    }

    public isReadOnly(): boolean {
        return this.displayDateTimeField.isReadOnly();
    }

    public getName(): string {
        return this.name;
    }

    public setValue(value: DateTimeValue): DateTimeField {
        this.value = value;

        const stringValue = new StringValue(
            value.isDefined() ? value.formatDateTime(this.userLocale) : "",
        );
        this.displayDateTimeField.setValue(stringValue);

        this.dateField.setValue(value.getDateValue());
        this.timeField.setValue(value.getTimeValue());

        return this;
    }

    public setLabel(label: string): DateTimeField {
        this.displayDateTimeField.setLabel(label);
        this.dateField.setLabel(label);
        this.timeField.setLabel(label);
        return this;
    }

    public setPlaceholder(placeholder: string): DateTimeField {
        this.displayDateTimeField.setPlaceholder(placeholder);
        this.dateField.setPlaceholder(placeholder);
        this.timeField.setPlaceholder(placeholder);
        return this;
    }

    public setMode(mode: FormFieldMode): FormField {
        this.displayDateTimeField.setMode(mode);
        this.dateField.setMode(mode);
        this.timeField.setMode(mode);
        return this;
    }

    public getMode(): FormFieldMode {
        return this.dateField.getMode();
    }

    public registerFormFieldAddOn(formFieldAddOn: FormFieldAddOn): FormField {
        // untetstützt bislang bislang keine AddOns
        return this;
    }

    public onChange(
        changeCallback: (changeEvent: formFieldChangeEventType) => void,
    ): DateTimeField {
        this.changeCallback = changeCallback;
        return this;
    }

    public compose($parent: JQuery): void {
        const fieldFlexbox = new FlexBox();

        switch (this.getMode()) {
            case FormFieldMode.DISPLAY:
                fieldFlexbox.newFlexItem().addContentElement(this.displayDateTimeField);
                break;
            case FormFieldMode.EDIT:
                fieldFlexbox
                    .newFlexItem()
                    .addContentElement(this.dateField)
                    .addStyleClass("dateTime_DateField");
                fieldFlexbox
                    .newFlexItem()
                    .addContentElement(this.timeField)
                    .addStyleClass("dateTime_TimeField");
                break;
            default:
                throw new Error("Invalid mode: " + this.getMode());
        }

        fieldFlexbox.compose($parent);
    }

    private fieldValueChanged(changeEvent: formFieldChangeEventType): void {
        if (this.changeCallback) {
            this.changeCallback({
                entityId: changeEvent.entityId,
                name: this.name,
                value: this.getValue(),
            });
        }
    }

    public updateValue(value: DateTimeValue): DateTimeField {
        if (!this.value.equals(value)) {
            this.value = value;

            const stringValue = new StringValue(
                value.isDefined() ? value.formatDateTime(this.userLocale) : "",
            );
            this.displayDateTimeField.setValue(stringValue);

            this.dateField.updateValue(value.getDateValue());
            this.timeField.updateValue(value.getTimeValue());
        }
        return this;
    }

    public getValue(): DateTimeValue {
        const dateValue = this.dateField.getValue();
        const timeValue = this.timeField.getValue();
        return DateTimeValue.fromDateAndTime(dateValue, timeValue);
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}
