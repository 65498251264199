import * as moment from "moment";

// import * as moment from "../external_interfaces/moment";

// TODO App - BCSDate sollte immutable sein - z.B. bcsDate2 = new BCSDate().addDays(1);

/**
 * Repräsentation eines Datums.
 * Diese Repräsentation stellt Funktionalität zur Verfügung um mit Datums-Objekten komportable zu arbeiten.
 * Im Hintergrund wird das Original Datum von Javascript verwendet.
 *
 */
export class BCSDate {
    // TODO App - User-TimeZone aus I18n verwenden und moment js timezone ausliefern+verwenden (?)
    private static bcsTimezone: string;

    private wrappedDate: Date;

    constructor(givenDate: Date) {
        this.wrappedDate = givenDate;
    }

    public static now(): BCSDate {
        return new BCSDate(new Date());
    }

    public static today(): BCSDate {
        return BCSDate.now().asStartOfDay();
    }

    public static getToday(): BCSDate {
        return new BCSDate(new Date());
    }
    /**
     *
     * @param dateString z.B. "2017-12-23"
     */
    public static getDateFromStamp(dateString: string): BCSDate {
        return BCSDate.getToday().setDateViaString(dateString);
    }

    public static getDateFromStampDisplay(dateString: string): BCSDate {
        return BCSDate.getToday().setDateViaDisplayString(dateString);
    }

    public static minnutesToDisplayTime(wholeMinutes: number): string {
        if (wholeMinutes === -1) {
            return "-1";
        }
        const minutes = wholeMinutes % 60;
        let minuteString: string = minutes + "";
        if (minutes < 10) {
            minuteString = "0" + minutes + "";
        }

        const hours = parseInt("" + wholeMinutes / 60, undefined);
        let hoursString: string = hours + "";
        if (hours < 10) {
            hoursString = "0" + hours + "";
        }

        return hoursString + ":" + minuteString + "";
    }

    public static minutesToDisplayTimeWithoutLeadingHourZero(wholeMinutes: number) {
        const minutes = wholeMinutes % 60;

        const minutesAbsolute = Math.abs(minutes);
        let minuteString: string = minutesAbsolute + "";
        if (minutesAbsolute < 10) {
            minuteString = "0" + minuteString;
        }

        const hours = parseInt("" + wholeMinutes / 60, undefined);
        let hoursString: string = hours + "";
        // erhalten auch bei negativen Minuten ohne Stunden, das negative Vorzeichen:
        if (wholeMinutes < 0 && wholeMinutes > -60) {
            hoursString = "-" + hoursString;
        }
        return hoursString + ":" + minuteString + "";
    }

    /** Nimmt Tage, Stunden, Minuten und passt eventuelle Überträge an */
    public static adjustDayHourMinuteTriple(input: DurationTriple): DurationTriple {
        const result: DurationTriple = new DurationTriple();

        result.minutes = Math.abs(input.minutes % 60);

        result.hours += Math.floor(input.minutes / 60); // Übertrag Minuten -> Stunden
        result.hours += Math.abs(input.hours); // Input-Stunden

        result.days += Math.floor(result.hours / 8); // Übertrag Stunden -> Tage
        result.hours = Math.abs(result.hours % 8); // Stunden nach Übertrag

        result.days += Math.abs(input.days);
        return result;
    }

    // TODO App - User-TimeZone aus I18n verwenden und moment js timezone ausliefern+verwenden (?)
    /**
     * @returns Zeitzonen-Offset der lokalen Zeit als ISO-String (z.B. "+02:00" für Berlin mit Sommerzeit)
     */
    public static getLocalTimeZoneISOOffset(): string {
        let timezoneOffset = -new Date().getTimezoneOffset(); // Date.getTimezoneOffset() = -120 für Berlin mit Sommerzeit

        if (timezoneOffset === 0) {
            return "Z";
        }

        const plusMinus = timezoneOffset > 0 ? "+" : "-";
        timezoneOffset = Math.abs(timezoneOffset);
        const hoursOffset = Math.floor(timezoneOffset / 60);
        const minutesOffset = timezoneOffset - hoursOffset * 60;
        const localTimeZoneISOOffset =
            plusMinus +
            (hoursOffset <= 9 ? "0" : "") +
            hoursOffset +
            ":" +
            (minutesOffset <= 9 ? "0" : "") +
            minutesOffset;
        return localTimeZoneISOOffset;
    }

    /**
     * Setzt ein Datum via formatierten String.
     *
     * Dies ist sinnvoll, wenn schnell ein menschenlesbares Datum gesetzt werden soll.
     * Wie es beispielsweise im Test der Fall ist.
     *
     * z.B. : "2017-12-23"
     *
     * @param parseableDateString string - z.B. : "2017-12-23"
     */
    public setDateViaString(parseableDateString: string): BCSDate {
        const date = moment(parseableDateString, "YYYY-MM-DD");
        this.wrappedDate = date.toDate();
        return this;
    }

    public setDateViaDisplayString(parseableDateString: string): BCSDate {
        const date = moment(parseableDateString, "DD.MM.YYYY");
        this.wrappedDate = date.toDate();
        return this;
    }

    /**
     *
     *
     *
     * @param dayOfMonth
     */
    public setDayOfMonth(dayOfMonth): BCSDate {
        // TODO: Jens  stimmt hier date(...)?
        this.wrappedDate = moment(this.wrappedDate).date(dayOfMonth).toDate();
        return this;
    }

    /**
     * Gibt die Ziffer des Monats zurück.
     *
     * @returns month:number - Bereich: 0-11
     */
    public getMonth(): number {
        return this.wrappedDate.getMonth();
    }

    /**
     * Setzt den Monat anhand seiner Ziffer (0-11)
     *
     * @param numberOfMonth:number - Bereich: 0-11
     */
    public setMonth(numberOfMonth: number): BCSDate {
        this.wrappedDate = moment(this.wrappedDate).set("month", numberOfMonth).toDate();
        return this;
    }

    public setYear(numberOfYear: number): BCSDate {
        this.wrappedDate = moment(this.wrappedDate).set("year", numberOfYear).toDate();
        return this;
    }

    /**
     * Liefert dieses Datum am selben Tag mit Uhrzeit 00:00:00
     */
    public asStartOfDay(): BCSDate {
        const m = moment(this.wrappedDate);
        m.startOf("day");
        return new BCSDate(m.toDate());
    }

    /**
     * Liefert dieses Datum am selben Tag mit Uhrzeit 23:59:59
     */
    public asEndOfDay(): BCSDate {
        const m = moment(this.wrappedDate);
        m.endOf("day");
        return new BCSDate(m.toDate());
    }

    public setMinutes(minutesOfDay: number): BCSDate {
        let hours: number = 0;
        let minutes: number = minutesOfDay;
        if (minutesOfDay >= 60) {
            hours = parseInt(minutesOfDay / 60 + "", undefined);
            minutes = minutesOfDay % 60;
        }

        this.wrappedDate = moment(this.wrappedDate).set("hour", hours).toDate();
        this.wrappedDate = moment(this.wrappedDate).set("minute", minutes).toDate();
        return this;
    }

    public getYear(): number {
        // TODO Jens hier ist noch nicht klar welches Jahr auf uns immer zutrift.
        return this.wrappedDate.getFullYear();
    }

    public getDayOfCalendar(): number {
        return this.wrappedDate.getDate();
    }

    public getDayOfWeek(): number {
        return this.wrappedDate.getDay();
    }

    public addMonth(numerOfMonth): BCSDate {
        this.wrappedDate = moment(this.wrappedDate).add(numerOfMonth, "month").toDate();
        return this;
    }

    public addDays(numberOfDays: number): BCSDate {
        this.wrappedDate = moment(this.wrappedDate).add(numberOfDays, "day").toDate();
        return this;
    }

    public subtractDays(numberOfDays: number): BCSDate {
        this.wrappedDate = moment(this.wrappedDate).subtract(numberOfDays, "day").toDate();
        return this;
    }

    public addYears(numberOfDays: number): BCSDate {
        this.wrappedDate = moment(this.wrappedDate).add(numberOfDays, "year").toDate();
        return this;
    }

    public subtractYear(numberOfDays: number): BCSDate {
        this.wrappedDate = moment(this.wrappedDate).subtract(numberOfDays, "year").toDate();
        return this;
    }

    public subtractMonth(numerOfMonth): BCSDate {
        this.wrappedDate = moment(this.wrappedDate).subtract(numerOfMonth, "month").toDate();
        return this;
    }

    /**
     * Gibt die Wochentags Ziffer des ersten Tages des betrachteten Monats zurück.
     *
     * z.B. Wenn das Datum das 13.12.2017 ist, da ist der Erste des Monats der 1.te Freitag.
     * Freitag ist nach ISO Woche der 5te Tag der Woche.
     *
     * @test BCSDateJSTest.getFirstDayOfMonth_should_work()
     */
    public getFirstDayOfMonth(): number {
        const firstDayOfMonth = moment(this.wrappedDate).startOf("month");
        // TODO: Americanisch : sollten day nehmen, nicht iso, da dieser am son starten
        return firstDayOfMonth.isoWeekday();
    }

    /**
     * Gibt die Wochentags-Ziffer des letztens Tages des betrachteten Monats zurück.
     *
     * z.B. Wenn das Datum das 13.12.2017 ist, da ist der Erste des Monats der 1.te Freitag.
     * Freitag ist nach ISO Woche der 5te Tag der Woche.
     *
     * @test BCSDateJSTest.getLastDayOfMonth_should_work()
     */
    public getLastDayOfMonth(): number {
        const firstDayOfMonth = moment(this.wrappedDate).endOf("month");
        // TODO: Americanisch : sollten day nehmen, nicht iso, da dieser am son starten
        return firstDayOfMonth.isoWeekday();
    }

    public getNumberOfDaysInMonth(): number {
        return moment(this.wrappedDate).daysInMonth();
    }

    public isWeekend(): boolean {
        const dayOfWeek = moment(this.wrappedDate).isoWeekday();
        return dayOfWeek === 6 || dayOfWeek === 7;
    }

    /**
     * Gibt zurück ob das betrachtete Datum heute ist.
     * Damit können wir beispielsweise im Kalender den Tag der Heute ist visuell hervorheben.
     *
     * @test BCSDateJSTest.isToday()_sollte_anzeigen,_ob_der_angegebene_Tag_heute_ist."
     * @test BCSDateJSTest.isToday()_sollte_nicht_anzeigen,_ob_der_angegebene_Tag_heute_ist.
     * @returns isToday:boolean, true => das Datum ist heute
     */
    public isToday(): boolean {
        //            let isToday:boolean = moment(this.wrappedDate).diff(new Date(), "day") == 0  && moment(new Date()).diff(this.wrappedDate, "day") == 0;
        const isToday: boolean = moment(this.wrappedDate).isSame(new Date(), "day");
        return isToday;
    }

    /**
     * Gibt zurück ob es sich bei dem Datum um Gestern handelt.
     *
     * Benötigen wir um im DaySelector gestern als label auszugeben.
     *
     */
    public isYesterday(): boolean {
        const isToday: boolean = moment(this.wrappedDate).isSame(
            BCSDate.getToday().subtractDays(1).getDate(),
            "day",
        );
        return isToday;
    }

    public isTomorrow(): boolean {
        const isToday: boolean = moment(this.wrappedDate).isSame(
            BCSDate.getToday().addDays(1).getDate(),
            "day",
        );
        return isToday;
    }

    /**
     * Erstellt eine Kopie des BCSDates.
     *
     * Wenn
     *
     */
    public getClone(): BCSDate {
        return new BCSDate(this.wrappedDate);
    }

    // TODO App - Alle Aufrufe sollten die Locale des eingeloggten Users übergeben, wenn sie z.B. den Wochentag-Namen ausgeben wollen
    public format(datePattern: string, userLocale: string = "de"): string {
        return moment(this.wrappedDate).locale(userLocale).format(datePattern);
    }

    // TODO App - Alle Aufrufe sollten die Locale des eingeloggten Users übergeben, wenn sie z.B. den Wochentag-Namen ausgeben wollen
    /**
     * @param userLocale Sprache des eingeloggten Benutzers
     * @return Formatiertes Datum im Langformat, z.B. für Locale "de" = "24.12.2018"
     */
    public formatLongDate(userLocale: string = "de"): string {
        return this.format(
            moment(this.wrappedDate).locale(userLocale).localeData().longDateFormat("L"),
            userLocale,
        );
    }

    // TODO App - Alle Aufrufe sollten die Locale des eingeloggten Users übergeben, wenn sie z.B. den Wochentag-Namen ausgeben wollen
    /**
     * @param userLocale Sprache des eingeloggten Benutzers
     * @return Formatiertes Datum im Langformat, z.B. für Locale "de" = "24.12.2018"
     */
    public formatShortDate(userLocale: string = "de"): string {
        let pattern = moment().locale(userLocale).localeData().longDateFormat("L");
        pattern = pattern.replace(new RegExp("\\/YYYY|YYYY"), ""); // Jahr aus Pattern entfernen
        return this.format(pattern, userLocale);
    }

    /**
     * @param userLocale Sprache des eingeloggten Benutzers
     * @return Formatiertes Datum mit Datum und Uhrzeit, z.B. für Locale "de" = "24.12.2018 12:00"
     */
    public formatDateTime(userLocale: string = "de"): string {
        const datePattern = moment(this.wrappedDate)
            .locale(userLocale)
            .localeData()
            .longDateFormat("L");
        const timePattern = moment(this.wrappedDate)
            .locale(userLocale)
            .localeData()
            .longDateFormat("LT");
        return this.format(datePattern + " " + timePattern, userLocale);
    }

    public getISODate(): string {
        return this.format("YYYY-MM-DD");
    }

    public getISODatTime(): string {
        return this.getDate().toISOString();
    }

    public getDate(): Date {
        return this.wrappedDate;
    }

    public isAfter(givenDate: BCSDate): boolean {
        // AppConsole.log("moment("+this.wrappedDate+").isAfter("+givenDate.getDate()+")"+ moment(this.wrappedDate).isAfter(givenDate.getDate()));
        return moment(this.wrappedDate).isAfter(givenDate.getDate());
    }

    public isSameHourAndMinute(givenDate: BCSDate): boolean {
        return (
            moment(this.wrappedDate).isSame(givenDate.getDate(), "hour") &&
            moment(this.wrappedDate).isSame(givenDate.getDate(), "minute")
        );
    }

    public isBefore(givenDate: BCSDate): boolean {
        // AppConsole.log("moment("+this.wrappedDate+").isAfter("+givenDate.getDate()+")"+ moment(this.wrappedDate).isAfter(givenDate.getDate()));
        return moment(this.wrappedDate).isBefore(givenDate.getDate());
    }

    /**
     * @returns Wochentag (als Enum 0-6)
     */
    public getWeekday(userLocale: string = "de"): BCSWeekday {
        return moment(this.wrappedDate).locale(userLocale).weekday() as BCSWeekday;
    }

    public minuteDiff(givenDate: BCSDate | number): number {
        if (typeof givenDate === "number") {
            return Math.abs(this.getMinutesOfDay() - givenDate);
        } else {
            return moment(givenDate.getDate()).diff(this.wrappedDate, "minute");
        }
    }

    public getMinutesOfDay(): number {
        const momentNow = moment(this.wrappedDate);
        return momentNow.get("minute") + momentNow.get("hour") * 60;
    }

    public getTimeAsString(): string {
        return BCSDate.minnutesToDisplayTime(this.getMinutesOfDay());
    }
}

export class DurationTriple {
    public minutes: number = 0;
    public hours: number = 0;
    public days: number = 0;

    public getMinutesString(): string {
        let mins: string = this.minutes.toString();
        if (mins.length === 1) {
            mins = "0" + mins;
        }
        return mins;
    }

    public getHoursString(): string {
        let hours: string = this.hours.toString();
        if (hours.length === 1) {
            hours = "0" + hours;
        }
        return hours;
    }

    public getDaysString(): string {
        return this.days.toString();
    }
}

export enum BCSWeekday {
    MONDAY = 0,
    TUESDAY = 1,
    WEDNESDAY = 2,
    THURSDAY = 3,
    FRIDAY = 4,
    SATURDAY = 5,
    SUNDAY = 6,
}
