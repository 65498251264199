import { Registry } from "../../core/Registry";
import { GUIPage } from "../GUIPage";
import { GUIContext } from "../GUIContext";
import { FooterTabBar } from "../../board/FooterTabBar";
import { Log } from "../../common/log/Log";
import { ConfigNode } from "../../core/Config/ConfigNode";

export class NavigationBar {
    public static BCS_COMPONENT_NAME = "navigationBar";

    public static ICON_CLICKED_TRIGGER = "navigationBarClickedTrigger";

    public syncBadgeText: string = null;

    public composeNavigationBar(
        page: GUIPage,
        guiContext: GUIContext,
        footerbars: ConfigNode,
        currentControllerPath: string,
    ): void {
        //var footerbars = this.controllerConfigNode.getChildren("FooterTabBar");
        const footerTabs: ConfigNode[] = footerbars.getChildren("FooterTab");

        const footerTabBar = new FooterTabBar(guiContext);
        for (let i = 0; i < footerTabs.length; i++) {
            const currentTab = footerTabs[i];
            const isNavigationLink = currentTab.getChildren().length == 0;

            let syncBadgeText = null;
            if (currentTab.getName() === "sync") {
                syncBadgeText = this.syncBadgeText;
            }

            if (isNavigationLink) {
                const isCurrentDisplay =
                    currentControllerPath == currentTab.getAttribute("NavigationPath");
                footerTabBar.addIcon(
                    currentTab.getName(),
                    isCurrentDisplay,
                    syncBadgeText,
                    currentTab.getAttribute("isSVG", false) === "true",
                );
            } else {
                footerTabBar.addComponent(currentTab, syncBadgeText);
            }
        }
        page.addFooterElement(footerTabBar);
    }

    /**
     *
     * Achtung, falls es keine SyncStates gibt, wollen wir keine Badge anzeigen, daher setzten wir den wert in deisem Fall nicht.
     *
     * @param countOfUnSynced
     */
    public setSyncBadge(countOfUnSynced: number): NavigationBar {
        if (countOfUnSynced !== 0) {
            this.syncBadgeText = countOfUnSynced + "";
        }
        return this;
    }
}

Registry.registerComponent(NavigationBar.BCS_COMPONENT_NAME, NavigationBar);
