import { Entity } from "../../../entities/Entity";
import { Schema } from "../../../common/schema/Schema";
import { IdGenerator } from "../../../util/text/IdGenerator";
import { TimesheetEntry } from "./TimesheetEntry";
import { AttributeDefinition } from "../../../common/schema/AttributeDefinition";
import { StringValue } from "../../../entities/values/StringValue";
import { EntityValue } from "../../../entities/values/EntityValue";
import { DurationValue } from "../../../entities/values/DurationValue";

export class Workflow implements TimesheetEntry {
    private workflowEntity: Entity;

    constructor(schema: Schema, timesheetObject?: object) {
        const typeSubtypeDefinition = schema.getTypeSubtypeDefinition(
            "TimesheetEntry",
            "ProcessWorkflows",
        );

        let timesheetValues = null;
        if (timesheetObject) {
            timesheetValues = timesheetObject;
        } else {
            timesheetValues = {
                oid: IdGenerator.createId() + "_TimesheetEntry",
                typ: "TimesheetEntry",
                subtyp: "Events",
            };
        }
        this.workflowEntity = new Entity(typeSubtypeDefinition, timesheetValues);
        this.workflowEntity.scanWithPrefix(timesheetValues, "task");

        this.extractPspPath(this.workflowEntity.getValue("pspPath").getString());
    }

    public getId(): string {
        return this.workflowEntity.getString("bookableElement__oid");
    }

    public getSubtype(): string {
        return this.workflowEntity.getString("subtyp");
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return this.workflowEntity.getTypeSubtypeDefinition().getAttributeDefinition(name);
    }

    /**
     * @param name Aufgaben-Attributename
     * @return Aufgaben-Attributswert
     */
    public getValue(name: string): EntityValue {
        if (name.indexOf("__") !== -1) {
            return this.workflowEntity.getValue(name);
        }
        if (this.workflowEntity.hasValue("bookableElement__" + name)) {
            return this.workflowEntity.getValue("bookableElement__" + name);
        } else {
            return this.workflowEntity.getValue(name);
        }
    }

    public getBookableObjectValue(name: string): string {
        return this.workflowEntity.getString(name);
    }

    public getDisplayPSPPath() {
        return (
            this.getGrandParentName() + " / " + this.getParentName() + " / " + this.getTaskName()
        );
    }

    public getGrandParentName(): string {
        return this.workflowEntity.getValue("grandParentName").getString();
    }

    public setRemainingEffort(value: number) {
        const durationValue = new DurationValue(value);

        this.workflowEntity.setValue("task__remainingDuration", durationValue);
    }

    public getParentName(): string {
        return this.workflowEntity.getValue("parentName").getString();
    }

    /**
     *
     *
     */
    public getTargetSubtype(): string {
        return this.workflowEntity.getString("bookableElement__subtyp");
    }

    public getName() {
        return this.workflowEntity.getString("bookableElement__name");
    }

    public toValueObject(): object {
        return this.workflowEntity.toValueObject();
    }

    public getTask(): string {
        return this.workflowEntity.getString("task__oid");
    }

    public getTaskName(): string {
        return this.workflowEntity.getString("task__name");
    }

    private extractPspPath(rawPspPath: string): void {
        const pathArray: [string] = JSON.parse(rawPspPath);
        pathArray.forEach((pspRawEntry, index) => {
            const pspEntry: { isGrandParent: boolean; name: string } = JSON.parse(pspRawEntry);

            if (pspEntry.isGrandParent) {
                this.setGrandParentName(pspEntry.name);
            }

            if (index === pathArray.length - 1) {
                this.setParentName(pspEntry.name);
            }
        });
    }

    private setGrandParentName(grandParentName: string): void {
        this.workflowEntity.setValue("grandParentName", new StringValue(grandParentName));
    }

    private setParentName(parentName: string): void {
        this.workflowEntity.setValue("parentName", new StringValue(parentName));
    }
}
