import { EntityValue } from "./EntityValue";

export class BooleanValue implements EntityValue {
    private value: boolean;

    public static parseFromString(strValue: string) {
        return new BooleanValue(strValue === "true" ? true : strValue === "false" ? false : null);
    }

    constructor(simpleValue: boolean) {
        this.value = simpleValue;
    }

    public isDefined(): boolean {
        return this.value === true || this.value === false;
    }

    public getSimpleValue(): string | number | boolean {
        return this.value;
    }

    public getString(): string {
        return this.value === true ? "true" : this.value === false ? "false" : "";
    }

    public getBoolean(): boolean {
        return this.value;
    }
}
