import { GUIElement } from "../GUIElement";
import { GUIContext } from "../GUIContext";
import { BoardElement } from "./BoardElement";
import { ConfigNode } from "../../core/Config/ConfigNode";
import { BoardMatrix } from "./BoardMatrix";
import { Log } from "../../common/log/Log";

export class BoardView implements GUIElement {
    private context: GUIContext;

    private CONF_MIN_TILE_HEIGHT = 40;

    private boardElements: BoardElement[] = [];
    private wholeWidthTiles: number;
    private currentWidthPx: number;
    private currentTileWidth: number;

    private dividableBoardWidth: number;
    private dividableTileWidthPx: number;

    private boardWrapper: JQuery;
    private sumHeight: number;

    constructor(wholeWidthTiles: number, boardConfig: ConfigNode, context: GUIContext) {
        this.context = context;

        this.wholeWidthTiles = wholeWidthTiles;
        this.updateDimensions();

        this.CONF_MIN_TILE_HEIGHT = boardConfig.getIntAttribute("MinTileHeightInPX", 40);
    }

    compose($parent: JQuery): void {
        const boardMatrix = new BoardMatrix(this.boardElements, 6);
        // boardMatrix.organizeTilesInMatrix(); //TODO: Klären, warum bei Zeile 81 ein Fehler auftritt.

        const paddingLeft = (this.currentWidthPx - this.dividableBoardWidth) / 2;

        this.boardWrapper = $("<div>")
            .addClass("boardView")
            .css({
                width: this.dividableBoardWidth + "px",
                marginLeft: paddingLeft + "px",
                /*"height": "100px"*/
            });

        this.sumHeight = 0;
        for (let i = 0; i < this.boardElements.length; i++) {
            const currentBoardElement = this.boardElements[i];
            // precompose BoardElements:
            currentBoardElement.setTileWidth(this.dividableTileWidthPx);
            currentBoardElement.setTileHeight(this.computeTileHeight());
            // compose:
            currentBoardElement.compose(this.boardWrapper);

            if (currentBoardElement.getXInTiles() == 1) {
                this.sumHeight += currentBoardElement.getElementHeight();
            }
        }
        this.boardWrapper.appendTo($parent);
    }

    public addBoardElement(boardElement: BoardElement) {
        this.boardElements.push(boardElement);
    }

    public getBoardElements(): BoardElement[] {
        return this.boardElements;
    }

    public getBoardHeight(): number {
        return this.sumHeight + 5; // 5 Pixel Sicherheit dazugegeben
    }
    private computeTileHeight() {
        return this.CONF_MIN_TILE_HEIGHT;
    }

    /**
     * Die Größe der Boardelemente wird hierüber dynamisch bestimmt, damit keine Leerräume entstehen.
     */
    public updateDimensions() {
        this.currentWidthPx = $(window).width();
        Log.debug("Gesamtbrreite", { currentWidthPx: this.currentWidthPx });
        this.currentTileWidth = this.currentWidthPx / this.wholeWidthTiles;
        Log.debug("Tile Breite", { currentWidthPx: this.currentTileWidth });

        this.dividableTileWidthPx = Math.floor(this.currentTileWidth);
        this.dividableBoardWidth = this.dividableTileWidthPx * this.wholeWidthTiles;

        Log.debug("teilbare Tilebreite: ", { dividableTileWidthPx: this.dividableTileWidthPx });
        Log.debug("Anzahl der Tiles: ", { wholeWidthTiles: this.wholeWidthTiles });
        Log.debug("teilbare Gesamtbreite: ", { dividableBoardWidth: this.dividableBoardWidth });
    }

    getComponentChildren(): GUIElement[] {
        return this.boardElements;
    }
}
