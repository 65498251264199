import { EntityValue } from "./EntityValue";

export class NumberValue implements EntityValue {
    private value: number;

    public static parseFromString(strValue: string) {
        return new NumberValue(parseFloat(strValue));
    }

    constructor(simpleValue: number) {
        this.value = simpleValue;
    }

    public isDefined(): boolean {
        return this.value === 0 || !!this.value;
    }

    public getSimpleValue(): string | number | boolean {
        return this.value;
    }

    public getString(): string {
        return this.value ? this.value.toString() : "";
    }

    public getNumber(): number {
        return this.value;
    }

    /**
     * Kaufmännisch Runden bzw. Abrunden auf Nachkommastellen.
     *
     * @param roundToDecimalPlaces Anzahl zu rundender Nachkommastellen
     * @param roundCommercially wenn true wird kaufmännisch gerundet, wenn false abgerundet [optional, default true]
     */
    public round(roundToDecimalPlaces: number, roundCommercially: boolean = true): NumberValue {
        const pow = Math.pow(10, roundToDecimalPlaces);
        const result = roundCommercially
            ? Math.round(this.getNumber() * pow) / pow
            : Math.floor(this.getNumber() * pow) / pow;
        return new NumberValue(result);
    }

    public format(userLocale: string): string {
        if (!this.isDefined()) {
            return "";
        }

        const numberFormat = new Intl.NumberFormat(userLocale, {});
        return numberFormat.format(this.value);
    }

    public isEqual(other: NumberValue): boolean {
        return (!this.isDefined() && !other.isDefined()) || this.getNumber() === other.getNumber();
    }
}
