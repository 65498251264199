import { EntityValue } from "./EntityValue";

export class DurationValue implements EntityValue {
    private value: number;

    public static parseFromString(strValue: string) {
        if (!strValue || strValue == "" || !strValue.match(/(\d+)\:(\d+)/)) {
            return new DurationValue(0);
        }
        const hours = parseInt(strValue.match(/(\d+)\:(\d+)/)[1]);
        const minutes = parseInt(strValue.match(/(\d+)\:(\d+)/)[2]);
        return new DurationValue(hours * 60 + minutes);
    }

    public static parseFromStringWithDays(strValue: string) {
        const regexMatch = strValue.match(/(\d*)\:(\d+)\:(\d+)/);
        const days = parseInt(regexMatch[1]);
        const hours = parseInt(regexMatch[2]);
        const minutes = parseInt(regexMatch[3]);

        return new DurationValue(days * 8 * 60 + hours * 60 + minutes);
    }

    constructor(simpleValue: number) {
        this.value = simpleValue;
    }

    public isDefined(): boolean {
        return this.value === 0 || !!this.value;
    }

    public getSimpleValue(): string | number | boolean {
        return this.value;
    }

    public getString(): string {
        return this.value ? this.value.toString() : "";
    }

    public format(): string {
        const hours = this.getHours();
        const minutes = this.getMinutes();
        return (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes;
    }

    public formatWithDays(): string {
        const minutes = this.getMinutes();
        const hours = this.getHours() % 8;
        const days = this.value ? Math.floor(this.value / 60 / 8) : 0;
        return (
            days + ":" + (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes
        );
    }

    public getHours(): number {
        return this.value ? Math.floor(this.value / 60) : 0;
    }

    public getMinutes(): number {
        return this.value ? this.value % 60 : 0;
    }

    public getDurationInMinutes(): number {
        return this.value ? this.value : 0;
    }
}
