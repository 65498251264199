import { GUIElement } from "../GUIElement";

/**
 * Einfache Fehlerbox mit rotem Rahmen, Titel und HTML-Meldung.
 *
 * Dient der Anzeige von fatalen Fehlermeldungen z.B. beim Starten der App.
 */
export class ErrorMessageBox implements GUIElement {
    private title: string = "Projektron BCS";

    private message: string;

    public setTitle(title: string): ErrorMessageBox {
        this.title = title;
        return this;
    }

    public setMessage(message: string): ErrorMessageBox {
        this.message = message;
        return this;
    }

    /**
     * HTML-DOM generieren
     *
     * @param $parent Parent-jQuery-Objekt
     */
    public compose($parent: JQuery): void {
        const $container = $("<div>").appendTo($parent).addClass("errorMessageBox");
        const $errorBox = $("<div>").appendTo($container).addClass("errorBox");
        $("<div>").appendTo($errorBox).addClass("title").text(this.title);
        $("<div>").appendTo($errorBox).html(this.message);
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}
