import "./TypeAheadSearch.less";
import { GUIElement } from "../GUIElement";
import { ListViewModel } from "./ListViewModel";
import { ListView } from "./ListView";
import { OnRenderBehavior } from "../OnRenderBehavior";
import { TypeAheadSearch } from "./TypeAheadSearch";
import { TypeAheadSearchResultHighlightFormFieldAddOn } from "./TypeAheadSearchResultHighlightFormFieldAddOn";

export class TypeAheadSearchView implements GUIElement, OnRenderBehavior {
    private typeAheadSearch: TypeAheadSearch;

    private resultListView: ListView;

    private typeAheadSearchResultHighlightFormFieldAddOn: TypeAheadSearchResultHighlightFormFieldAddOn;

    private placeholder: string;

    private $searchFieldTag: JQuery;

    private $resultContainerTag: JQuery;

    public setSearchModel(
        totalListViewModel: ListViewModel,
        searchFieldNames: string[],
    ): TypeAheadSearchView {
        this.typeAheadSearch = new TypeAheadSearch(totalListViewModel, searchFieldNames);
        return this;
    }

    public setResultListView(resultListView: ListView): TypeAheadSearchView {
        this.resultListView = resultListView;

        this.typeAheadSearchResultHighlightFormFieldAddOn =
            new TypeAheadSearchResultHighlightFormFieldAddOn();
        this.resultListView.registerFormFieldAddOn(
            this.typeAheadSearchResultHighlightFormFieldAddOn,
        );

        return this;
    }

    public setPlaceholder(placeholder: string): TypeAheadSearchView {
        this.placeholder = placeholder;
        return this;
    }

    public compose($parent: JQuery): void {
        const $containerTag = $("<div>").appendTo($parent).addClass("typeAheadSearch");

        const $searchFieldContainerTag = $("<div>").appendTo($containerTag).addClass("search");

        this.$searchFieldTag = $("<input>")
            .appendTo($searchFieldContainerTag)
            .attr({
                type: "search",
                value: "",
                placeholder: this.placeholder,
            })
            .addClass("searchfield")
            .on("keyup", this.searchInputChanged.bind(this)) // inkrementell suchen nach jedem geänderten Zeichen
            .on("search", this.searchInputChanged.bind(this)); // Suchen nach Enter oder Löschen des Feldes [X]

        // Leere Ergebnisliste composen
        this.$resultContainerTag = $("<div>").appendTo($containerTag);
        this.resultListView.compose(this.$resultContainerTag);
    }

    private searchInputChanged(): void {
        const searchInput = <string>this.$searchFieldTag.val();
        // Suchen
        const resultListViewModel = this.typeAheadSearch.search(searchInput);

        // Ergebnisliste mit neuen Daten composen
        this.typeAheadSearchResultHighlightFormFieldAddOn.setSearchInput(searchInput);
        this.resultListView.recompose(resultListViewModel);
    }

    public executeOnRender($body: JQuery): void {
        this.searchInputChanged();
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}
