import { TimeRecord } from "../domain/time_recording/TimeRecord";
import { TypeSubtypeDefinition } from "../common/schema/TypeSubtypeDefinition";
import { Entity } from "../entities/Entity";
import { DateTimeValue } from "../entities/values/DateTimeValue";
import { EntityValue } from "../entities/values/EntityValue";
import { ForecastRecord } from "../domain/time_recording/forecast/ForecastRecord";

export const enum SyncStateType {
    // Es liegen keine Änderungen vor.
    NoChangesInApp = "noChanges",
    // Änderungen in der App, die noch nicht synchronisiert wurden.
    ChangesInApp = "changesInApp",
    // Fehler beim Speichern, daher nicht synchron
    ErrorInObjectInApp = "errorInObjectInApp",
    // Fehler bei der Synchronisation
    SynchronisationIssue = "synchronisationIssue",
}

/**
 * Synchrionisierte Objekttypen (wird als Attribut eines SyncState gespeichert).
 *
 * Darf nicht const sein, da sonst nicht mehr im Javascript-Code vorhanden und man könnte nicht loopen:
 * for (let t in SyncStateObjectType) { }
 */
export enum SyncStateObjectType {
    Booking = "Booking",
    Attendance = "Attendance",
    Forecast = "Forecast",
    Pause = "Pause",
    Allowance = "JAllowance",
    File = "JFile",
    Contact = "Contact",
}

export class SyncState {
    private static readonly TYPE_SUBTYPE_DEFINITION = new TypeSubtypeDefinition(
        "SyncState",
        "default",
        {
            oid: { datatype: "Oid" },
            objectType: { datatype: "String" },
            subtype: { datatype: "String" },
            syncState: { datatype: "String" },
            isNewObject: { datatype: "Bool" },
            isDeletedObject: { datatype: "Bool" },
            lastStateTouch: { datatype: "DateTime" },
            userOid: { datatype: "Oid" },
            errorKey: { datatype: "String" },
            errorMessage: { datatype: "Html" },
            errorObjectOid: { datatype: "Oid" },
        },
    );

    private syncStateEntity: Entity;

    public static fromValueObject(valuesObject: object) {
        return new SyncState(valuesObject);
    }

    public static forEntity(
        objectId: string,
        objectType: SyncStateObjectType,
        syncStateType: SyncStateType,
        userOid: string,
    ) {
        return new SyncState({
            oid: objectId,
            objectType: objectType,
            syncState: syncStateType,
            isNewObject: false,
            isDeletedObject: false,
            lastStateTouch: DateTimeValue.now(),
            userOid: userOid,
        });
    }

    public static fromTimeRecord(
        userOid: string,
        timeRecord: TimeRecord,
        syncStateType: SyncStateType,
    ) {
        const id = timeRecord.getId();
        let objectType = null;

        if (timeRecord.isBooking()) {
            objectType = SyncStateObjectType.Booking;
        } else if (timeRecord.isPause()) {
            objectType = SyncStateObjectType.Pause;
        } else {
            objectType = SyncStateObjectType.Attendance;
        }

        return SyncState.forEntity(id, objectType, syncStateType, userOid);
    }

    public static fromForecast(
        userOid: string,
        forecast: ForecastRecord,
        syncStateType: SyncStateType,
    ) {
        const id = forecast.getId();
        let objectType = null;
        objectType = SyncStateObjectType.Forecast;
        return SyncState.forEntity(id, objectType, syncStateType, userOid);
    }

    constructor(valuesObject: object) {
        this.syncStateEntity = new Entity(SyncState.TYPE_SUBTYPE_DEFINITION, valuesObject);
    }

    /**
     * @return Id des SyncState / gleich der Id des synchronisieten Objektes
     */
    public getId(): string {
        return this.syncStateEntity.getId();
    }

    /**
     * @return Objecttyp des Synchronisationsstatus
     */
    public getSyncStateObjectType(): SyncStateObjectType {
        return <SyncStateObjectType>this.syncStateEntity.getString("objectType");
    }

    public getSubtype(): string {
        return this.syncStateEntity.getString("subtype");
    }

    public setSubtype(subtype: string): SyncState {
        this.syncStateEntity.setString("subtype", subtype);
        return this;
    }

    /**
     * @return Status-Typ dieses Synchronisationsstatus (z.B. changesInApp)
     */
    public getSyncStateType(): SyncStateType {
        return <SyncStateType>this.syncStateEntity.getString("syncState");
    }

    public setSyncStateType(syncStateType: SyncStateType): SyncState {
        this.syncStateEntity.setString("syncState", syncStateType);
        return this;
    }

    public markChanged(isNew: boolean): SyncState {
        this.setSyncStateType(SyncStateType.ChangesInApp);
        if (isNew) {
            this.syncStateEntity.setBoolean("isNewObject", true);
        }
        return this;
    }

    /**
     * Objekt als in App gelöscht markieren (aber die Löschung nicht nach BCS synchronisiert)
     */
    public markDeleted(): void {
        this.setSyncStateType(SyncStateType.ChangesInApp);
        this.syncStateEntity.setBoolean("isDeletedObject", true);
    }

    public markSyncSucess(): SyncState {
        this.setSyncStateType(SyncStateType.NoChangesInApp);
        this.syncStateEntity.setBoolean("isNewObject", false);
        return this;
    }

    public markSyncError(
        errorKey: string,
        errorMessage: string,
        errorObjectOid?: string,
    ): SyncState {
        this.setSyncStateType(SyncStateType.SynchronisationIssue);
        this.setErrorKey(errorKey);
        this.setErrorMessage(errorMessage);
        this.setErrorObjectOid(errorObjectOid);
        return this;
    }

    /**
     * @return true, wenn Objekt in App neu erstellt, aber noch nicht nach BCS synchronisiert wurde
     */
    public isNew(): boolean {
        return this.syncStateEntity.getBoolean("isNewObject");
    }

    /**
     * @return true, wenn Objekt in App gelöscht wurde,aber die Löschung nicht nach BCS synchronisiert wurde
     */
    public isDeleted(): boolean {
        return this.syncStateEntity.getBoolean("isDeletedObject");
    }

    public setErrorKey(errorKey: string): SyncState {
        this.syncStateEntity.setString("errorKey", errorKey);
        return this;
    }

    public setErrorMessage(errorMessage: string): SyncState {
        this.syncStateEntity.setString("errorMessage", errorMessage);
        return this;
    }

    public setErrorObjectOid(errorObjectOid: string): SyncState {
        this.syncStateEntity.setString("errorObjectOid", errorObjectOid);
        return this;
    }

    /**
     * @returns Oid des Objektes, an dem der Fehler entstanden ist (optional falls an Exception verfügbar)
     */
    public getErrorObjectOid(): string {
        return this.syncStateEntity.getString("errorObjectOid");
    }

    public getTypeSubtypeDefinition(): TypeSubtypeDefinition {
        return this.syncStateEntity.getTypeSubtypeDefinition();
    }

    public getValue(name: string): EntityValue {
        return this.syncStateEntity.getValue(name);
    }

    public toValueObject(): object {
        return this.syncStateEntity.toValueObject();
    }

    /**
     * @returns true, wenn Objekt in App nicht geändert wurde (mit und ohne Fehler)
     */
    public isNotChangedInApp(): boolean {
        return this.getSyncStateType() === SyncStateType.NoChangesInApp;
    }

    public isSyncOnlyChangedInApp(): boolean {
        return this.getSyncStateType() === SyncStateType.ChangesInApp;
    }

    public hasSynchronisationIssue(): boolean {
        return this.getSyncStateType() === SyncStateType.SynchronisationIssue;
    }
}
