export class HttpURL {
    private protocol: string;

    private hostname: string;

    private port: number;

    private path: string;

    private hash: string;

    private parameters: { [key: string]: string } = {};

    constructor(url: string = "/") {
        const a = document.createElement("a");
        a.href = url;

        this.protocol = a["protocol"];
        this.hostname = a["hostname"];
        const portStr = a["port"];
        if (portStr.length == 0) {
            this.port = this.protocol == "http:" ? 80 : 443;
        } else {
            this.port = Number(portStr).valueOf();
        }

        this.setPath(a["pathname"]);

        const search = a["search"];
        this.parseParameters(search);

        this.hash = a["hash"];
        if (this.hash.indexOf("#") == 0) {
            this.hash = decodeURIComponent(this.hash.substring(1));
        }
    }

    private parseParameters(search: string) {
        this.parameters = {};
        if (search && search.length > 0) {
            const parameterTokens = search.substring(1).split("&");
            for (let i = 0, n = parameterTokens.length; i < n; i++) {
                const keyValuePair = parameterTokens[i].split("=");
                this.parameters[decodeURIComponent(keyValuePair[0])] = decodeURIComponent(
                    keyValuePair[1],
                );
            }
        }
    }

    public setPath(path: string): HttpURL {
        this.path = path;

        /* Für lokale File-URLs: Laufwerk raus löschen (z.B. "/G:/ -> "/" */
        if (this.path.match(new RegExp("/[A-Za-z]:/"))) {
            this.path = this.path.substring(3);
        }

        // Sicherstellen, dass Pfad mit einem / beginnt
        if (this.path.length == 0 || this.path.substring(0, 1) != "/") {
            this.path = "/" + this.path;
        }

        return this;
    }

    public addPath(subPath): HttpURL {
        // Sicherstellen, dass aktueller Pfad auf einen / endet
        let currentPath = this.path;
        if (currentPath.length == 0 || currentPath.substring(currentPath.length - 1) != "/") {
            currentPath += "/";
        }

        // Sicherstellen, dass anzuhängender Pfad nicht mit einem / beginnt
        if (subPath.length > 0 && subPath.substring(0, 1) == "/") {
            subPath = subPath.substring(1);
        }

        this.setPath(currentPath + subPath);

        return this;
    }

    public getPath(): string {
        return this.path;
    }

    public setParameter(name, value): HttpURL {
        this.parameters[name] = value;
        return this;
    }

    public setAllParameters(parameters): HttpURL {
        for (const name in parameters) {
            if (parameters.hasOwnProperty(name)) {
                this.setParameter(name, parameters[name]);
            }
        }
        return this;
    }

    public clearParameters(): HttpURL {
        this.parameters = {};
        return this;
    }

    public getParameters(): { [key: string]: string } {
        return this.parameters;
    }

    public setHash(hash): HttpURL {
        this.hash = hash;
        return this;
    }

    public getHash(): string {
        return this.hash;
    }

    public toURL(): string {
        let url = "";

        if (this.hostname) {
            url += this.protocol + "//" + this.hostname;
            if (
                (this.protocol == "http:" && this.port != 80) ||
                (this.protocol == "https:" && this.port != 443)
            ) {
                url += ":" + this.port;
            }
        }

        return url + this.toRelativeURL();
    }

    public toRelativeURL(): string {
        let url = this.path;

        if (Object.keys(this.parameters).length > 0) {
            url += "?" + $.param(this.parameters);
        }

        if (this.hash && this.hash.length > 0) {
            url += "#" + encodeURIComponent(this.hash);
        }

        return url;
    }

    public copy(): HttpURL {
        const httpURL = new HttpURL();
        httpURL.protocol = this.protocol;
        httpURL.hostname = this.hostname;
        httpURL.port = this.port;
        httpURL.path = this.path;
        httpURL.hash = this.hash;
        httpURL.parameters = this.parameters;
        return httpURL;
    }
}
