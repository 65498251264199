import { TypeSubtypeDefinition } from "../common/schema/TypeSubtypeDefinition";
import { EntityValue } from "./values/EntityValue";
import { EntityValueFactory } from "./values/EntityValueFactory";
import { StringValue } from "./values/StringValue";
import { OidValue } from "./values/OidValue";
import { BooleanValue } from "./values/BooleanValue";
import { NumberValue } from "./values/NumberValue";

export class Entity {
    private typeSubtypeDefinition: TypeSubtypeDefinition;

    private values: { [key: string]: EntityValue } = {};

    constructor(typeSubtypeDefinition: TypeSubtypeDefinition, valuesObject: object, isNew = false) {
        this.typeSubtypeDefinition = typeSubtypeDefinition;

        // Defaultwerte aus Schema setzen (sofern Attribute noch undefiniert)
        if (isNew) {
            this.typeSubtypeDefinition.getAttributeNames().forEach((attributeName) => {
                if (!valuesObject[attributeName]) {
                    const defaultValue = this.typeSubtypeDefinition
                        .getAttributeDefinition(attributeName)
                        .getDefaultValue();
                    if (defaultValue) {
                        valuesObject[attributeName] = defaultValue;
                    }
                }
            });
        }

        // Primitive Werte als DataValue-Objekte kapseln
        const names = typeSubtypeDefinition.getAttributeNames();
        for (let i = 0; i < names.length; i++) {
            const name = names[i];
            if (valuesObject.hasOwnProperty(name)) {
                const dataType = typeSubtypeDefinition.getAttributeDefinition(name).getDataType();
                const simpleValue = valuesObject[name];
                this.values[name] = EntityValueFactory.fromSimpleValue(dataType, simpleValue);
            }
        }
    }

    public scanWithPrefix(
        valuesObject: { [key: string]: string | number | boolean },
        prefix: string,
    ) {
        const names = this.typeSubtypeDefinition.getAttributeNames();
        for (let i = 0; i < names.length; i++) {
            const name = names[i];
            const prefixName = prefix + "__" + name;
            if (valuesObject.hasOwnProperty(prefixName)) {
                const dataType = this.typeSubtypeDefinition
                    .getAttributeDefinition(name)
                    .getDataType();
                const simpleValue = valuesObject[prefixName];
                this.values[prefixName] = EntityValueFactory.fromSimpleValue(dataType, simpleValue);
            }
        }
    }

    public getTypeSubtypeDefinition(): TypeSubtypeDefinition {
        return this.typeSubtypeDefinition;
    }

    public getId(name: string = "oid"): string {
        const value = <OidValue>this.getValue(name);
        return value ? value.getOid() : null;
    }

    public getString(name: string): string {
        const value = this.getValue(name);
        return <string>value.getString();
    }

    public getNumber(name: string): number {
        const value = this.getValue(name);
        const rawValue = value.getSimpleValue();
        if (typeof rawValue == "string") {
            return parseInt(rawValue);
        }
        return <number>rawValue;
    }

    public getBoolean(name: string): boolean {
        const value = this.getValue(name);
        return <boolean>value.getSimpleValue();
    }

    public getValue(name: string): EntityValue {
        this.typeSubtypeDefinition.checkAttributeDefined(name);

        let value: EntityValue = this.values[name];
        if (!value) {
            const dataType = this.typeSubtypeDefinition.getAttributeDefinition(name).getDataType();
            value = EntityValueFactory.fromSimpleValue(dataType, null);
        }

        return value;
    }

    public hasValue(name: string): boolean {
        return this.values.hasOwnProperty(name);
    }

    public setValue(name: string, value: EntityValue): Entity {
        this.typeSubtypeDefinition.checkAttributeDefined(name);
        this.values[name] = value;

        return this;
    }

    public setString(name: string, value: string): Entity {
        this.setValue(name, new StringValue(value));
        return this;
    }

    public setOid(name: string, value: string): Entity {
        this.setValue(name, new OidValue(value));
        return this;
    }

    public setNumber(name: string, value: number): Entity {
        this.setValue(name, new NumberValue(value));
        return this;
    }

    public setBoolean(name: string, value: boolean): Entity {
        this.setValue(name, new BooleanValue(value));
        return this;
    }

    public toValueObject(): object {
        const valuesObject = {};

        for (const name in this.values) {
            if (this.values.hasOwnProperty(name)) {
                valuesObject[name] = this.values[name].getSimpleValue();
            }
        }

        return valuesObject;
    }

    public toTouchedValueObject(touchedFields: string[]): object {
        const valuesObject = {};

        for (const key in touchedFields) {
            const name = touchedFields[key];
            if (this.values.hasOwnProperty(name)) {
                valuesObject[name] = this.values[name].getSimpleValue();
            }
        }

        return valuesObject;
    }
}
