import { GUIElement } from "../GUIElement";
import { ConfigNode } from "../../core/Config/ConfigNode";
import { GUIContext } from "../GUIContext";
import { ControllerLink } from "../content/ControllerLink";

export abstract class BoardElement implements GUIElement {
    protected CSS_ELEMENT_MARGIN: number = 1;

    protected currentTileWidth: number;
    protected xTiles: number;
    protected yTiles: number;
    protected widthTiles: number;
    protected heightTiles: number;
    protected currentTileHeight = 9;
    protected name: string;

    protected wholeContainerLinksTo: string = null;

    protected guiElements: GUIElement[] = [];

    protected styleClasses: string[] = [];

    protected configNode: ConfigNode;

    protected context: GUIContext;

    protected boardElementWrapper: JQuery;

    protected elementHeight: number;

    constructor(boardElementConfig: ConfigNode, context: GUIContext) {
        this.xTiles = boardElementConfig.getIntAttribute("PosX");
        this.yTiles = boardElementConfig.getIntAttribute("PosY");
        this.widthTiles = boardElementConfig.getIntAttribute("SizeX");
        this.heightTiles = boardElementConfig.getIntAttribute("SizeY");

        this.wholeContainerLinksTo = boardElementConfig.getAttribute("WholeContainerLinksTo", null);

        this.name = boardElementConfig.getAttribute("Name");
        this.styleClasses.push(this.name);

        this.configNode = boardElementConfig;

        this.context = context.getClone();
        // Setzen das Board-Elemente in den Context für seine unter Komponenten.
        this.context.setBoardElement(this);
    }

    /**
     * Compose des Board-Elements
     */
    public compose($parent: JQuery): void {
        this.initializeChildren();

        this.boardElementWrapper = $("<div>").addClass("boardElementWrapper");

        this.elementHeight = this.heightTiles * this.currentTileHeight;
        const elementWidth = this.widthTiles * this.currentTileWidth;
        this.boardElementWrapper.css({
            top: this.getTopCoordinatePx() + "px",
            left: this.getLeftCoordinatePx() + "px",

            height: this.elementHeight + "px",
            width: elementWidth + "px",
        });

        const paddingBetweenElements = 2;
        const boardElement = $("<div>")
            .addClass("boardElement " + this.styleClasses.join(" "))
            .css({
                height: this.elementHeight - paddingBetweenElements + "px",
                width: elementWidth - paddingBetweenElements + "px",
                margin: paddingBetweenElements / 2 + "px",
            });

        if (this.wholeContainerLinksTo !== null) {
            const fakeConfigNode: ConfigNode = ConfigNode.getDynamicCreatedConfigNode({
                nodeName: "ControllerLink",
                WholeContainerLinksTo: this.wholeContainerLinksTo,
            });

            const link = new ControllerLink(fakeConfigNode, this.context);
            this.linkChildren(link);
            link.compose(boardElement);
        } else {
            this.composeChildren(boardElement);
        }

        boardElement.appendTo(this.boardElementWrapper);
        this.boardElementWrapper.appendTo($parent);
    }

    public reCompose(): void {
        const parent = this.boardElementWrapper.parent();
        this.boardElementWrapper.detach();
        this.boardElementWrapper = null;
        this.guiElements = [];
        this.compose(parent);
    }

    /**
     * Sollte von erbenden Klassen überschrieben werden,
     * um Child-Elemente zu initialisieren.
     */
    protected abstract initializeChildren();

    /**
     * Composed die Child-Elemente.
     * Braucht nur überschrieben werden, wenn die Kinder Sonderbehandlungen benötigen.
     */
    protected composeChildren(parent: JQuery) {
        for (let i = 0; i < this.guiElements.length; i++) {
            this.guiElements[i].compose(parent);
        }
    }

    /**
     * Hängt die Child-Elemente in einen übergeordneten Link ein.
     * Wird verwendet, wenn "wholeContainerLinksTo" verwendet wird.
     * Braucht nur überschrieben werden, wenn die Kinder Sonderbehandlungen benötigen.
     */
    protected linkChildren(link: ControllerLink) {
        for (let i = 0; i < this.guiElements.length; i++) {
            link.addContentElement(this.guiElements[i]);
        }
    }

    public addContentElement(guiElement: GUIElement) {
        this.guiElements.push(guiElement);
    }
    public setTileWidth(currentTileWidth: number) {
        this.currentTileWidth = currentTileWidth;
    }

    public setTileHeight(currentTileHeight: number) {
        this.currentTileHeight = currentTileHeight;
    }

    public getXInTiles() {
        return this.xTiles;
    }

    public getYInTiles() {
        return this.yTiles;
    }

    public getWidthInTiles() {
        return this.widthTiles;
    }

    public getHeightInTiles() {
        return this.heightTiles;
    }

    public getElementHeight(): number {
        return this.elementHeight;
    }

    /**
     * Bei Kollisionen verschieben wir das Boardelement nach unten.
     * Dies übernimmt diese Methode um den angegebenen Offset.
     *
     * @see BoardMatrix
     * @param offset
     */
    public addYTiles(offset: number) {
        this.yTiles = this.yTiles + offset;
    }

    public addStyleClass(styleClass: string): BoardElement {
        this.styleClasses.push(styleClass);
        return this;
    }

    private getLeftCoordinatePx(): number {
        return (this.xTiles - 1) * this.currentTileWidth;
    }

    private getTopCoordinatePx(): number {
        return (this.yTiles - 1) * this.currentTileHeight;
    }

    public getComponentChildren(): GUIElement[] {
        return this.guiElements;
    }
}
