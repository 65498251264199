import { AppConsole } from "../../../common/log/AppConsole";
import { Schema } from "../../../common/schema/Schema";
import { AttendanceClock, AttendanceClockState } from "./AttendanceClock";
import { AttendanceClockClient } from "./AttendanceClockClient";

export class AttendanceClockPool {
    private schema: Schema;

    private jsonObject: Object;

    private poolUpdateAge: number;

    private syncCallback: Promise<object>;

    constructor(schema: Schema) {
        this.schema = schema;
    }

    public synchronizeAttendancesWithBCS(): Promise<object> {
        const promise = this.readAttendanceClockFromBCS();
        return promise;
    }

    public getAttendanceClock(): AttendanceClock {
        return new AttendanceClock(this.jsonObject);
    }

    public clickedAttendanceClock(attendanceClock: AttendanceClock): Promise<object> {
        let isStart: boolean = true;
        let transportObject = null;
        if (attendanceClock.getAttendanceState() === AttendanceClockState.AttandanceStarted) {
            transportObject = {
                startedSubtype: "attendance",
                startedDateTime: attendanceClock.getStartedAttendaneDateTime().getDate(),
            };
            isStart = true;
        } else if (
            attendanceClock.getAttendanceState() === AttendanceClockState.AttandanceStoping
        ) {
            transportObject = {
                stopingSubtype: "attendance",
                stopingDateTime: attendanceClock.getStopingTime().getDate(),
            };
            isStart = false;
        } else if (attendanceClock.getPauseState() === AttendanceClockState.PauseStarted) {
            transportObject = {
                startedSubtype: "pause",
                startedDateTime: attendanceClock.getStartedPauseDateTime().getDate(),
            };
            isStart = true;
        } else if (attendanceClock.getPauseState() === AttendanceClockState.PauseStoping) {
            transportObject = {
                stopingSubtype: "pause",
                stopingDateTime: attendanceClock.getStopingTime().getDate(),
            };
            isStart = false;
        }

        if (transportObject !== null) {
            const self = this;
            return new Promise(function (resolve, reject) {
                if (isStart) {
                    new AttendanceClockClient()
                        .sendStartAttendanceClock(transportObject)
                        .then(function (result: object) {
                            attendanceClock.adjustNewServerInformation(result["json"]);
                            AppConsole.debug(result, attendanceClock);
                            resolve(result);
                        });
                } else {
                    new AttendanceClockClient()
                        .sendStopAttendanceClock(transportObject)
                        .then(function (result: object) {
                            attendanceClock.adjustNewServerInformation(result["json"]);
                            AppConsole.debug(result);
                            resolve(result);
                        });
                }
            });
        } else {
            throw new Error("AttendanceClock is not started");
        }
    }

    private readAttendanceClockFromBCS(): Promise<object> {
        AppConsole.debug("[AttendanceClockPool] readAttendanceClockFromBCS");

        const self = this;
        return new Promise(function (resolve, reject) {
            new AttendanceClockClient()
                .fetchAttendanceClock(self.poolUpdateAge)
                .then(function (result) {
                    self.attendanceClockReadFromBCS(result);
                    resolve({});
                });
        });
    }

    private attendanceClockReadFromBCS(result: object): void {
        AppConsole.debug(
            "[AttendanceClockPool] AttendanceClock FETCHED",
            result,
            this.poolUpdateAge,
        );
        this.jsonObject = result;
        //        let callback = this.syncCallback;
        //        this.syncCallback = null;
    }
}
