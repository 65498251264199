export class ImageDimension {
    private imageWidth: number;

    private imageHeight: number;

    constructor(imageWidth, imageHeight) {
        this.imageWidth = imageWidth;
        this.imageHeight = imageHeight;
    }

    public getWidth(): number {
        return this.imageWidth;
    }

    public getHeight(): number {
        return this.imageHeight;
    }

    public reduce(
        maxWidth: number,
        maxHeight: number,
        allowSwapWidthHeight: boolean,
    ): ImageDimension {
        if (allowSwapWidthHeight) {
            if (maxHeight < maxWidth && this.imageHeight > this.imageWidth) {
                maxHeight = [maxWidth, (maxWidth = maxHeight)][0];
            }
        }

        let scaledWidth = this.imageWidth;
        let scaledHeight = this.imageHeight;
        if (scaledWidth > maxWidth) {
            scaledHeight = Math.floor((scaledHeight * maxWidth) / scaledWidth);
            scaledWidth = maxWidth;
        }
        if (scaledHeight > maxHeight) {
            scaledWidth = Math.floor((scaledWidth * maxHeight) / scaledHeight);
            scaledHeight = maxHeight;
        }

        return new ImageDimension(scaledWidth, scaledHeight);
    }
}
