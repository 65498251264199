import { I18n } from "../../../../common/i18n/I18n";
import { Registry } from "../../../../core/Registry";
import { ProgressBar } from "../../../../gui/content/ProgressBar";
import { TextLabel } from "../../../../gui/content/TextLabel";
import {
    FlexAlignItems,
    FlexBox,
    FlexDirection,
    FlexJustifyContent,
} from "../../../../gui/flexbox/FlexBox";
import { GUIElement } from "../../../../gui/GUIElement";
import { AllowanceSummary } from "../../AllowanceSummary";
import "./AllowanceSummary.less";

/**
 * Spesen-Boardelement:
 *   - Neue Dienstreise / Aktuelle Dienstreise - Link zum Erstellen einer neuen / Öffnen der aktuellen Dienstreise
 *   - Anzeige "Spesenerfassung nicht verfügbar", wenn Spesenerfassung nicht lizensiert / deaktiviert
 */
export class AllowanceTravelElement implements GUIElement {
    public static BCS_COMPONENT_NAME = "AllowanceTravel";

    private allowanceSummaryModel: AllowanceSummary;

    private i18n: I18n;

    constructor(allowanceSummaryModel: AllowanceSummary, i18n: I18n) {
        this.allowanceSummaryModel = allowanceSummaryModel;
        this.i18n = i18n;
    }

    compose($parent: JQuery): void {
        if (this.allowanceSummaryModel.isAllowanceRecordingAvailable()) {
            this.showStartContinueTravel($parent);
        } else {
            this.showAllowanceRecordingDisabledMessage($parent);
        }
    }

    private showStartContinueTravel($parent: JQuery): void {
        let createBusinessTravelLabel = "";
        let businessTravelLabel = "";

        const containerFlexBox = new FlexBox()
            .setFlexDirection(FlexDirection.COLUMN)
            .setAlignItems(FlexAlignItems.CENTER)
            .setJustifyContent(FlexJustifyContent.CENTER)
            .addStyleClass("allowanceSummary")
            .setDOMId("allowanceSummaryNewOrCurrentTravel");

        if (!this.allowanceSummaryModel.isTravelRunning()) {
            createBusinessTravelLabel = this.i18n.get("MobileApp.board.createBusinessTravel");

            containerFlexBox
                .newFlexItem()
                .addContentElement(
                    new TextLabel()
                        .addStyleClass("newTravel")
                        .setInlineText(createBusinessTravelLabel),
                );
        } else {
            businessTravelLabel = this.i18n.get("JAllowance.businessTravel");
            const startDate = this.allowanceSummaryModel
                .getRunningTravelStartDate()
                .format(this.i18n.getDateFormat("MobileApp.DateMonthFormat"));
            const endDate = this.allowanceSummaryModel
                .getRunningTravelEndDate()
                .format(this.i18n.getDateFormat("MobileApp.DateMonthFormat"));

            containerFlexBox
                .newFlexItem()
                .addContentElement(
                    new TextLabel()
                        .addStyleClass("currentTravel")
                        .setInlineText(businessTravelLabel),
                );
            containerFlexBox
                .newFlexItem()
                .addContentElement(
                    new TextLabel()
                        .addStyleClass("currentTravelDates")
                        .setInlineText(startDate + " - " + endDate),
                );

            const progressBar = new ProgressBar().setProgressPercentage(
                this.allowanceSummaryModel.getRunningTravelElapsedPercentage(),
            );
            containerFlexBox.newFlexItem().addContentElement(progressBar);
        }

        containerFlexBox.compose($parent);
    }

    private showAllowanceRecordingDisabledMessage($parent: JQuery): void {
        const allowanceRecordingDisabledLabel = this.i18n.get(
            "MobileApp.board.allowanceRecordingDisabled",
        );

        const containerFlexBox = new FlexBox()
            .setFlexDirection(FlexDirection.COLUMN)
            .setAlignItems(FlexAlignItems.CENTER)
            .setJustifyContent(FlexJustifyContent.CENTER)
            .addStyleClass("allowanceSummary");

        containerFlexBox
            .newFlexItem()
            .addContentElement(
                new TextLabel()
                    .addStyleClass("allowanceRecordingDisabled")
                    .setText(allowanceRecordingDisabledLabel),
            );

        containerFlexBox.compose($parent);
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}

Registry.registerGUIComponent(AllowanceTravelElement.BCS_COMPONENT_NAME, AllowanceTravelElement);
