import { BoardElement } from "./BoardElement";

export class BoardMatrix {
    private boardElements: BoardElement[] = [];
    private wholeWidthTiles: number;

    constructor(boardElements: BoardElement[], wholeWidthTiles: number) {
        this.boardElements = boardElements;
        this.wholeWidthTiles = wholeWidthTiles;
    }

    public organizeTilesInMatrix(): void {
        let orderedBoardElements = {};

        orderedBoardElements = this.sortBoardElementsForInsertion(orderedBoardElements);

        const boardMatrix = [[]]; // Erste Zeile irrelevant, da wir bei 1 anfangen zu zählen. Daher Dummy-Zeile

        this.fillMatrix(orderedBoardElements, boardMatrix);
    }

    private sortBoardElementsForInsertion(orderedBoardElements: Object): Object {
        const tmpElements = this.boardElements.slice(0);

        // Sortieren die BoardElemente von oben nach unten, so das wir sie nacheinander einfügen können
        // und sie bei Kollisionen nur noch nach unten verschieben müssen.
        for (let i = 0; i < this.boardElements.length; i++) {
            let smallestIndex = null;
            let minX = 9999;
            let minY = 9999;
            // nimm immer das ab weitesten oben und links liegende Element aus der noch zu betrachtende Menge
            // und füge sie mit der gefundenen Reihenfolge in ein neues Object mit garantierter Sortierung ein.
            // Dadurch erhalten wir eine sortierte Liste von BoardElementen.
            for (let j = 0; j < tmpElements.length; j++) {
                const comparableBoardElement = tmpElements[j];
                if (
                    minX >= comparableBoardElement.getXInTiles() &&
                    minY >= comparableBoardElement.getYInTiles()
                ) {
                    minX = comparableBoardElement.getXInTiles();
                    minY = comparableBoardElement.getYInTiles();
                    smallestIndex = j;
                }
            }
            if (smallestIndex !== null) {
                orderedBoardElements[i] = tmpElements[smallestIndex];
                tmpElements.splice(smallestIndex, 1);
            }
        }
        return orderedBoardElements;
    }

    private extendWholeMatrix(boardMatrix: Array<Object>) {
        // Erweitere das Array, auf die Menge der maximalen Elemente
        const row = [];
        for (let cell = 1; cell <= this.wholeWidthTiles; cell++) {
            row[cell] = -1;
        }
        boardMatrix.push(row);
    }

    private fillMatrix(orderedBoardElements: Object, boardMatrix: Array<Object>): void {
        // befülle Matrix von oben nach unten mit Board-Elementen
        for (let i = 0; i < Object.keys(orderedBoardElements).length; i++) {
            const boardElement = <BoardElement>orderedBoardElements[i];
            const x = boardElement.getXInTiles();
            const y = boardElement.getYInTiles();
            const w = boardElement.getWidthInTiles();
            const h = boardElement.getHeightInTiles();

            let hasCollision: boolean = false;
            let isFree: boolean = false;
            let tilesToFree = 1;

            const changedTiles = [];
            // Versuche das Element in die Matrix einzufügen:
            for (let matrixX = x; matrixX <= x + w - 1; matrixX++) {
                for (let matrixY = y; matrixY <= y + h - 1; matrixY++) {
                    this.extendWholeMatrix(boardMatrix);

                    if (
                        boardMatrix[matrixY][matrixX] === -1 &&
                        typeof boardMatrix[matrixY][matrixX] !== "undefined"
                    ) {
                        boardMatrix[matrixY][matrixX] = i;
                        changedTiles.push({ x: matrixX, y: matrixY });
                    } else {
                        if (typeof boardMatrix[matrixY][matrixX] !== "undefined") {
                            hasCollision = true;
                            isFree = false;
                            while (!isFree) {
                                if (
                                    boardMatrix[matrixY + tilesToFree][matrixX] === -1 ||
                                    typeof boardMatrix[matrixY][matrixX] !== "undefined"
                                ) {
                                    isFree = true;
                                    break;
                                } else {
                                    tilesToFree++;
                                }
                            }
                        }
                    }
                }
            }
            // Wenn wir eine Kollision gefunden haben, dann schieben wir das derzeitige Element nach unten.
            // Und zwar um so viel wie wir Platz gefunden haben.
            if (hasCollision) {
                // räumen evtl. schon in der Matrix gesetzte Tiles des derzeitigen Elemnts auf.
                for (let changed = 0; changed < changedTiles.length; changed++) {
                    boardMatrix[changedTiles[changed].y][changedTiles[changed].x] = -1;
                }
                // verschieben das Board um "tilesToFree" nach unten
                boardElement.addYTiles(tilesToFree);
                // setzten den BoardElementIterator eins zurück,
                // da es eine Kollision gab und wir das derzeitige Board-Element
                // mit verschiebung nach unten, erneut versuchen einzufügen.
                i = i - 1;
            }
        }
    }
}
