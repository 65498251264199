import { Registry } from "../../../core/Registry";
import { Controller } from "../../../core/Controller";
import { Animation, AppNavigator } from "../../../core/Router";
import { I18n } from "../../../common/i18n/I18n";
import { GUIPage } from "../../../gui/GUIPage";
import { ListView } from "../../../gui/list/ListView";
import { TypeAheadSearchView } from "../../../gui/list/TypeAheadSearchView";
import { ToolBar } from "../../../gui/navigation/ToolBar";
import { ToolLink } from "../../../gui/navigation/ToolLink";
import { ListViewContext } from "../../../gui/list/ListViewContext";
import { EmptyListViewModel } from "../../../gui/list/EmptyListViewModel";
import { ListViewModel } from "../../../gui/list/ListViewModel";
import { ListRowModel } from "../../../gui/list/ListRowModel";
import { AttributeDefinition } from "../../../common/schema/AttributeDefinition";
import { EntityValue } from "../../../entities/values/EntityValue";
import { Booking } from "../bookings/Booking";
import { TimeRecordingManager } from "../TimeRecordingManager";
import { Component } from "../../../core/Component";
import { OidValue } from "../../../entities/values/OidValue";
import { SchemaDataType } from "../../../common/schema/SchemaDataType";
import { Workflow } from "../timesheet/Workflow";
import { SyncState, SyncStateType } from "../../../sync/SyncState";
import { UserSession } from "../../../common/auth/UserSession";
import { Log } from "../../../common/log/Log";
import { ServerConfigProperties } from "../../../common/config/ServerConfigProperties";
import { GUIContext } from "../../../gui/GUIContext";

/**
 * Controller für Auswahlseite einer Aufgabe für eine Buchung.
 *
 * - Zeigt alle Aufgaben der Aufgabenliste.
 * - Bietet Möglichkeit zum Filtern durch Texteingabe
 * -- Jedes durch Leerzeichen getrennte Suchwort muss in einem der durchsuchten Aufgaben-Felder vorkommen.
 * -- Suchworte werden buchtabenweise mit Wildcards zwischen jedem Buchstaben Aufgaben-Felder gesucht.
 */
export class TimeRecordingWorkflowSelectionController implements Controller {
    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static readonly CONTROLLER_PATH = "bookingWorkflowSelection";

    public static readonly SELECTION_TYPE = "Workflow";

    /** Seiten-Parameter für Oid der Buchung */
    public static readonly PARAMETER_BOOKING_ID = "workflowSelectBookingOid";

    public static readonly SELECTION_ATTRIBUTE = "effortTargetOid";

    /** Seiten-Parameter Pfad der Seite, zu der nach getrofener Auswahl zurückgekehrt werden soll. */
    public static readonly PARAMETER_RETURN_PATH = "return-path";

    /** Präfix für alle Seiten-Parameter, mit denen (ohne Präfix) die Rückkehrseite augerufen werden soll. */
    public static readonly PARAMETER_RETURN_PARAMETER_PREFIX = "return-parameter-";

    /** Aufgaben-Felder die durchsucht werden */
    private static readonly BOOKING_PSP_SEARCH_NAMES = ["name", "task__name"];

    /** Referenz auf Navigator zum Aufruf einer anderen Seite */
    private navigator: AppNavigator;

    private animation: Animation;

    /** Parameter, mit der diese Seite aufgerufen wurde */
    private pageParameters: object;

    /** I18n */
    private i18n: I18n;

    /** Zeiterfassungsverwaltung */
    private timeRecordingManager: TimeRecordingManager;

    /** Aufgabenliste */
    private workflowlist: Workflow[];

    /** Aktuell geöffnete Buchung für Aufgabenauswahl */
    private booking: Booking;

    /** Wurzel GUI-Element für Seite */
    private page: GUIPage;

    /** ListView für Ergebnisliste mit Aufgaben */
    private resultListView: ListView;

    /** GUI-Element für Sucheingabe, Filterung der Ergebnisliste und Neuanzeige des Ergebnis-ListView */
    private typeAheadSearchView: TypeAheadSearchView;

    private userSession: UserSession;

    private serverConfigProperties: ServerConfigProperties;

    /**
     * Aufruf vor Erzeugen vor Initialisierung einer Instanz.
     *
     * @returns Symblische Name der benötigten Abhängigkeiten dieses Controllers
     */
    public getDependencyNames(): string[] {
        return [
            I18n.BCS_COMPONENT_NAME,
            TimeRecordingManager.BCS_COMPONENT_NAME,
            UserSession.BCS_COMPONENT_NAME,
            ServerConfigProperties.BCS_COMPONENT_NAME,
        ];
    }

    /**
     * Aufruf für Initialisierung dieser Instanz.
     * @param depencencyComponents Benötigten Abhängigkeiten mit symblischen Namen
     */
    public init(depencencyComponents: { [key: string]: Component }) {
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.timeRecordingManager = <TimeRecordingManager>(
            depencencyComponents[TimeRecordingManager.BCS_COMPONENT_NAME]
        );
        this.userSession = <UserSession>depencencyComponents[UserSession.BCS_COMPONENT_NAME];
        this.serverConfigProperties = <ServerConfigProperties>(
            depencencyComponents[ServerConfigProperties.BCS_COMPONENT_NAME]
        );
    }

    /**
     * Aufruf, wenn Seite gerendert werdern soll.
     *
     * @param parameters Parameter dieser Seite
     * @param animation Animation beim Einblenden
     * @param navigator Referenz auf Navigator zum Aufruf einer anderen Seite
     */
    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        this.navigator = navigator;
        this.animation = animation;
        this.pageParameters = parameters;

        // Abholen der Aufgabenliste,
        // Laden der Buchung, an der die Aufgabe ausgewählt werden soll,
        // Danach Rendern der Seite.
        const self = this;
        this.fetchWorkflowlistAndBooking(parameters).then(() => self.composePage());
    }

    /**
     * Aufruf via Callback nachdem die Browser-Back-Taste angeklickt wurde.
     * Controller soll zum vorherigen Zustand zurückgehen, z.B. ein Menü schließen
     * oder abschließend zur übergeordneten Seite zurücknavigieren.
     */
    public popState(): void {
        this.navigateToReturnPath();
    }

    public destroy(): void {
        // leer
    }

    /**
     * Laden der Aufgabenliste und
     * Laden der Buchung, an der die Aufgabe ausgewählt werden soll.
     *
     * @param parameters Parameter dieser Seite
     */
    private async fetchWorkflowlistAndBooking(parameters: {
        [key: string]: string;
    }): Promise<void> {
        // Abholen Aufgabenliste

        this.workflowlist = await this.timeRecordingManager.getTimesheetWorkflows();
        // Laden der Buchung, an der die Aufgabe ausgewählt werden soll,
        const bookingId = parameters[TimeRecordingWorkflowSelectionController.PARAMETER_BOOKING_ID];
        if (bookingId) {
            // Buchung laden
            this.booking = await this.timeRecordingManager.getBooking(bookingId);
        }
    }

    /**
     * Rendern der Seite.
     */
    private composePage(): void {
        if (!this.booking) {
            // Wenn Buchung nicht geladen: Zurück zur Ausgangsseite
            this.navigateToReturnPath();
        }

        // Seite (Wurzelelement) erstellen
        this.page = new GUIPage(new GUIContext(this.i18n));

        // Kopfzeile (mit Navigation und Titel)
        this.composeHeader();

        // Suchfeld und Ergebnisliste mit Aufgaben
        this.composeSearchAndResultList();

        // Seite rendern
        this.page
            .setAnimation(this.animation, this.navigator.doShowAnimations())
            .compose($("body"));
    }

    /**
     * Rendert Kopfzeile (mit Navigation und Titel).
     */
    private composeHeader(): void {
        const headerToolBar = new ToolBar().setId("header_toolbar").addStyleClass("headBar");

        // Zurück-Navigation
        headerToolBar.addToolLinkLeft(
            new ToolLink()
                .setStyleClass("backToDetailFromSelection")
                .setImageName("icon-chevron-left.svg")
                .onClick(this.popState.bind(this)),
        );

        // Seitentitel
        headerToolBar.setTitle(this.i18n.get("MobileApp.bookingWorkflowSelection.pagetitle"));

        this.page.addHeaderElement(headerToolBar);
    }

    /**
     * Rendern des Suchfeldes und der Ergebnisliste mit Aufgaben
     */
    private composeSearchAndResultList(): void {
        // ListView-Kontext vorest mit leerem ListViewModel
        // Nach Rendern der Seite und nach jeder Sucheingabe wird Ergebnisliste mit Suchergebnis als ListViewModel neu gerendert.
        const listViewContext = new ListViewContext()
            .setI18n(this.i18n)
            .setModel(new EmptyListViewModel());

        const pspSelectionResultListViewDef = this.serverConfigProperties.customizeGUIDefinitions(
            TimeRecordingWorkflowSelectionGUIDefinition.WORKFLOW_SELECTION_RESULT_LIST_VIEW_DEF,
            TimeRecordingWorkflowSelectionController.CONTROLLER_PATH,
            TimeRecordingWorkflowSelectionController.CONTROLLER_PATH,
            "display",
        );

        // ListView mit Aufgaben-Ergebnisliste (GUIDefinition geben an, welche Aufgaben-Attribute angezeigt werden)
        const resultListView = new ListView(listViewContext, pspSelectionResultListViewDef)
            .addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW)
            .onRowClicked(this.listRowClicked.bind(this));

        const selectionAttributeNames = this.serverConfigProperties.getSet(
            "MobileApp.TimeRecordingDetail.SelectionFields",
        );
        TimeRecordingWorkflowSelectionController.BOOKING_PSP_SEARCH_NAMES.forEach((attribute) =>
            selectionAttributeNames.push(attribute),
        );

        // GUI-Element für Sucheingabe, Filterung der Ergebnisliste und Neuanzeige des Ergebnis-ListView
        this.typeAheadSearchView = new TypeAheadSearchView()
            .setPlaceholder(this.i18n.get("MobileApp.bookingtaskselection.filterWorkflow"))
            .setSearchModel(
                new BookinfWorkflowSearchListViewModel(this.workflowlist),
                selectionAttributeNames,
            )
            .setResultListView(resultListView);
        this.page.addPageElement(this.typeAheadSearchView);
    }

    /**
     * Callback: Wird aufgerufen, wenn eine Zeile der Aufgaben-Ergebnisliste angeklickt wurde.
     * Pfad und Parameter werden in der GUIDefinition als "link" definiert.
     * Hier: "link": {"path": "SELECT",  "parameters": {"selectedWorkflowOid": {"attribute": "taskOid"}}
     *
     * @param selectionPath Pfad der zu öffnenden Seite (hier nicht verwendet)
     * @param parameters Parameter für zu öffnenden Seite (enthalten hier die Oid der ausgewählten Aufgabe)
     */
    private async listRowClicked(selectionPath: string, parameters: { [key: string]: string }) {
        // Ausgewählte Aufgabe an Buchung setzen

        const selectedWorkflowOid = parameters["selectedWorkflowOid"];

        if (selectedWorkflowOid) {
            const workflow: Workflow =
                await this.timeRecordingManager.getTimesheetWorkflow(selectedWorkflowOid);
            const taskId = workflow.getTask();
            this.booking.setValue("effortTargetOid", new OidValue(taskId));
            this.booking.setValue("effortWorkflowOid", new OidValue(selectedWorkflowOid));
        }

        // Buchung  speichern,
        // dann zur aufrufenden Seite (typischerweise mit Formular, in dem u.a. die Aufgabe auswählbar ist) zurückkehren.
        const self = this;
        const syncState: SyncState = SyncState.fromTimeRecord(
            this.userSession.getCurrentUserOid(),
            this.booking,
            SyncStateType.ChangesInApp,
        );
        this.timeRecordingManager
            .storeTimeRecordLocal(this.booking, syncState)
            .then((booking) => self.navigateToReturnPath(selectedWorkflowOid))
            .catch((error) =>
                Log.error(
                    "[TimeRecordingWorkflowSelectionController] Error while store booking and syncstate in local app.",
                    error,
                    error,
                ),
            ); // TODO App - Fehler anzeigen
    }
    /**
     * Navigiert zurück zur aufrufenden Seite, typischerweise mit Formular, in dem u.a. die Aufgabe ausgewählt werden kann.
     * Aufruf der Seite, die als Parameter "return-path"  beim Aufruf dieser Seite angegeben wurde.
     * Als Parametern, werden die Parameter mitgesendet, die beim Aufruf dieser Seite mit dem Präfix "return-parameter-" gegeben waren.
     */
    private navigateToReturnPath(selectedWorkflowOid?: string) {
        let returnPath = null;
        const returnParameters: { [key: string]: string } = {};

        for (const name in this.pageParameters) {
            if (name == TimeRecordingWorkflowSelectionController.PARAMETER_RETURN_PATH) {
                // Pfad der Zielaseite aus Parameter "return-path" auslesen
                returnPath = this.pageParameters[name];
            } else if (
                name.substr(
                    0,
                    TimeRecordingWorkflowSelectionController.PARAMETER_RETURN_PARAMETER_PREFIX
                        .length,
                ) == TimeRecordingWorkflowSelectionController.PARAMETER_RETURN_PARAMETER_PREFIX
            ) {
                // Parameter der Zielaseite aus Parametern mit Präfix "return-parameter-" auslesen
                const returnParameterName = name.substr(
                    TimeRecordingWorkflowSelectionController.PARAMETER_RETURN_PARAMETER_PREFIX
                        .length,
                );
                returnParameters[returnParameterName] = this.pageParameters[name];
            }
        }

        returnParameters["selectionChangedAttribute"] =
            TimeRecordingWorkflowSelectionController.SELECTION_ATTRIBUTE;
        returnParameters["selectionChangedOid"] = selectedWorkflowOid;
        returnParameters["selectionType"] = TimeRecordingWorkflowSelectionController.SELECTION_TYPE;

        this.navigator.navigateTo(returnPath, returnParameters, Animation.SLIDE_RIGHT);
    }
}

/**
 * ListViewModel mit Aufgaben-Ergebnisliste
 */
class BookinfWorkflowSearchListViewModel implements ListViewModel {
    private tasklist: Workflow[];

    constructor(tasklist: Workflow[]) {
        this.tasklist = tasklist;
    }

    public countRows(): number {
        return this.tasklist.length;
    }

    public getRowModelByNo(rowNo: number): ListRowModel {
        return new BookingWorkflowSearchListRowModel(this.tasklist[rowNo]);
    }

    public getRowModelById(entityId: string): ListRowModel {
        return new BookingWorkflowSearchListRowModel(
            this.tasklist.filter((task) => task.getId() == entityId)[0],
        );
    }

    public isResultTruncated(): boolean {
        return false;
    }
}

/**
 * ListRowModel mit einer Aufgabe
 */
class BookingWorkflowSearchListRowModel implements ListRowModel {
    private workflow: Workflow;

    constructor(task: Workflow) {
        this.workflow = task;
    }

    public getEntityId(name?: string): string {
        return this.workflow.getId();
    }

    public getDataType(name: string): SchemaDataType {
        return SchemaDataType.STRING;
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return null;
    }

    public getValue(name: string): EntityValue {
        return this.workflow.getValue(name);
    }

    public setValue(name: string, value: EntityValue): void {
        // geht hier nicht
    }

    public isEditable(): boolean {
        return true;
    }

    public isDeletable(): boolean {
        return false;
    }
}

/**
 * GUIDefinition für Liste mit Suchergebnissen der Aufgaben-Such/Auswahl-Seite für die Buchungsaufgabe.
 */
class TimeRecordingWorkflowSelectionGUIDefinition {
    public static readonly WORKFLOW_SELECTION_RESULT_LIST_VIEW_DEF = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "compound",
                        field: {
                            name: "pspPath",
                            superContents: [
                                {
                                    left: {
                                        class: "field",
                                        field: {
                                            icon: "icon-task-grey",
                                            name: "task__name",
                                            mode: "display",
                                            styleClass: "fieldSmallFont",
                                        },
                                    },
                                },
                            ],
                            mainContent: {
                                left: {
                                    class: "field",
                                    field: {
                                        icon: "NONE",
                                        name: "name",
                                        mode: "display",
                                    },
                                },
                            },
                            subContents: [
                                {
                                    left: {
                                        class: "field",
                                        field: {
                                            icon: "NONE",
                                            name: "customer.name",
                                            mode: "display",
                                            styleClass: "fieldSmallFont",
                                        },
                                    },
                                },
                            ],
                        },
                        link: {
                            path: "SELECT",
                            parameters: {
                                selectedWorkflowOid: { attribute: "oid" },
                            },
                            linkImage: "NONE",
                        },
                    },
                ],
            },
        ],
    };
}

// Controller unter seinem Pfad registrieren
Registry.registerComponent(
    TimeRecordingWorkflowSelectionController.CONTROLLER_PATH,
    TimeRecordingWorkflowSelectionController,
);
