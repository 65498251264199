import { IndexedDB } from "../../database/IndexedDB";
import { IndexedDBVersion } from "../../database/IndexedDBVersion";

export class LogPool {
    private static LOGS_STORE_NAME = "logs";

    private indexedDB: IndexedDB;

    private maxIdTimestamp: Date;

    constructor(indexedDB: IndexedDB) {
        this.indexedDB = indexedDB;
        this.indexedDB.registerStore(LogPool.LOGS_STORE_NAME, IndexedDBVersion.DB_VERSION_1);
    }

    public writeLogToDB(logRows: object[]): Promise<void> {
        const self = this;
        return new Promise((resolve, reject) => {
            this.indexedDB
                .getConnection()
                .readWriteTransaction([LogPool.LOGS_STORE_NAME])
                .updateElements(LogPool.LOGS_STORE_NAME, logRows)
                .then(
                    () => {
                        resolve();
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    public readLogsFromDB(): Promise<{ resultSet: object[] }> {
        const self = this;
        return new Promise((resolve, reject) => {
            const self = this;
            this.indexedDB
                .getConnection()
                .readOnlyTransaction([LogPool.LOGS_STORE_NAME])
                .selectCursor(LogPool.LOGS_STORE_NAME)
                .then(
                    (result) => {
                        resolve(result && result.resultSet ? result : { resultSet: [] });
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    public clearLogsInDB(): Promise<void> {
        const self = this;
        return new Promise((resolve, reject) => {
            this.indexedDB
                .getConnection()
                .readWriteTransaction([LogPool.LOGS_STORE_NAME])
                .clear(LogPool.LOGS_STORE_NAME)
                .then(
                    () => {
                        resolve();
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }
}
