import { Component } from "../../core/Component";
import { Registry } from "../../core/Registry";
import { SingletonComponent } from "../../core/SingletonComponent";
import { IndexedDB } from "../../database/IndexedDB";
import { UserSession } from "../auth/UserSession";
import { AppConsole } from "./AppConsole";
import { LogClient } from "./LogClient";
import { LogLevel } from "./LogLevel";
import { LogPool } from "./LogPool";

export class Log implements Component, SingletonComponent {
    public static BCS_COMPONENT_NAME = "Log";

    public static LOG_LEVELS: LogLevel[] = [
        LogLevel.FATAL,
        LogLevel.ERROR,
        LogLevel.WARN,
        LogLevel.INFO,
        LogLevel.DEBUG,
        LogLevel.TRACE,
    ];

    private static LOG_QUEUE_INTERVAL = 5000;

    private static currentLogLevel: LogLevel = LogLevel.INFO;

    private static logQueue: object[] = [];

    private static maxIdTimestamp: Date = new Date();

    private indexedDB: IndexedDB;

    private logPool: LogPool;

    public getDependencyNames(): string[] {
        return [IndexedDB.BCS_COMPONENT_NAME, UserSession.BCS_COMPONENT_NAME];
    }

    public init(depencencyComponents: { [key: string]: Component }): void {
        this.indexedDB = <IndexedDB>depencencyComponents[IndexedDB.BCS_COMPONENT_NAME];

        this.logPool = new LogPool(this.indexedDB);
    }

    public start(): Promise<void> {
        this.queueWriteLogToDB();
        return Promise.resolve();
    }

    public notifyBeginUserSession(isOnline: boolean): Promise<void> {
        return isOnline ? this.synchronize() : Promise.resolve();
    }

    public synchronize(): Promise<void> {
        const self = this;
        return self.logPool
            .readLogsFromDB()
            .then((result) => new LogClient().fetchLogLevelAndSendLogs(result.resultSet))
            .then((result) => {
                Log.currentLogLevel = result["level"];
                if (window.localStorage) {
                    window.localStorage.setItem("Log.Level", Log.currentLogLevel);
                }

                self.logPool.clearLogsInDB();
            });
    }

    private queueWriteLogToDB() {
        const self = this;
        window.setTimeout(() => self.writeLogQueueToDB(), Log.LOG_QUEUE_INTERVAL);
    }

    private writeLogQueueToDB(): void {
        this.readServiceWorkerLog();

        const logEntries = Log.logQueue;
        Log.logQueue = [];

        if (logEntries.length > 0) {
            const self = this;
            this.logPool
                .writeLogToDB(logEntries)
                .then(() => self.queueWriteLogToDB())
                .catch((error) =>
                    AppConsole.log(
                        "[Log.writeLogQueueToDB] Error writing log entries to DB: ",
                        error,
                        logEntries,
                    ),
                );
        } else {
            this.queueWriteLogToDB();
        }
    }

    /**
     * Log des ServiceWorker auslesen
     */
    private readServiceWorkerLog(): void {
        const serviceWorkerLog = window.localStorage.getItem("Log.ServiceWorkerLog");
        if (serviceWorkerLog) {
            const logEntries: string[] = JSON.parse(serviceWorkerLog);

            logEntries.forEach((entry) => {
                switch (entry["level"]) {
                    case "ERROR":
                        Log.error(entry["message"]);
                        break;
                    case "INFO":
                    default:
                        Log.info(entry["message"]);
                }
            });

            window.localStorage.removeItem("Log.ServiceWorkerLog");
        }
    }

    private static createLogEntry(logLevel: string, message: string, properties: object = {}) {
        // Timestamp als Id des Log-Eintrages für DB übernehmen, ggf. +n, falls mehrere Log-Einträge zu selbem Timestamp kommen.
        const timestamp = new Date();
        let idTimestamp = timestamp;
        if (idTimestamp.getTime() <= Log.maxIdTimestamp.getTime()) {
            idTimestamp = new Date(Log.maxIdTimestamp.getTime() + 1);
        }
        Log.maxIdTimestamp = idTimestamp;

        properties = JSON.parse(JSON.stringify(properties)) || {};
        properties["window.location.href"] = window.location.href;
        properties["navigator.userAgent"] = window.navigator.userAgent;
        properties["navigator.platform"] = window.navigator.platform;

        return {
            id: idTimestamp.toISOString(),
            level: logLevel,
            message: message,
            properties: JSON.parse(JSON.stringify(properties)),
        };
    }

    private static log(logLevel: LogLevel, message: string, properties: object = {}) {
        const logLevelNo = Log.LOG_LEVELS.indexOf(logLevel);
        const currentLogLevelNo = Log.LOG_LEVELS.indexOf(this.currentLogLevel);
        if (logLevelNo > currentLogLevelNo) {
            return;
        }

        if (Log.logQueue.length >= 999) {
            Log.logQueue = [];
        }
        Log.logQueue.push(Log.createLogEntry(logLevel, message, properties));
    }

    public static error(message: string, properties: object = {}, error?: any) {
        properties = properties || {};
        properties["error"] = "" + error;
        if (error && error.stack) {
            properties["stack"] = error.stack;
        }
        // Nach Möglichkeit den Stack direkt auf der Console ausgeben, dann kann man die Codestellen Links anklicken und gelangt zu den stellen.
        // in stringify werden Zeichen an den Linm geschrieben, die das debugging schwer machen.
        if (error) {
            properties["error"] = "" + error;
            if (error.stack) {
                properties["stack"] = error.stack;
            }
            console.error(error, JSON.stringify(properties));
        } else {
            console.error(message, JSON.stringify(properties));
        }

        Log.log(LogLevel.ERROR, message, properties);
    }

    public static warn(message: string, properties: object = {}) {
        Log.log(LogLevel.WARN, message, properties);
    }

    public static info(message: string, properties: object = {}) {
        Log.log(LogLevel.INFO, message, properties);
    }

    public static debug(message: string, properties: object = {}) {
        Log.log(LogLevel.DEBUG, message, properties);
    }

    public static trace(message: string, properties: object = {}) {
        Log.log(LogLevel.TRACE, message, properties);
    }

    public static logUnhandledError(
        message: any,
        uri: string,
        lineNumber: number,
        columnNumber?: number,
        error?: any,
    ) {
        Log.error(message, {}, error);
    }

    public static logUnhandledPromiseReject = function (event) {
        if (event && event.reason && typeof event.reason === "object") {
            Log.error("Unhandled Promise Reject", { reason: event.reason }, event.reason);
        } else {
            Log.error("Unhandled Promise Reject", { reason: event.reason });
        }
    };

    public static setLogLevel(logLevel: LogLevel): void {
        Log.currentLogLevel = logLevel;
    }
}

// Initialen LogLevel aus LocalStorage lesen, damit Logging (Debug/Trace) bereits beim Starten der App aktiv sein kann.
if (window.localStorage) {
    const logLevel = window.localStorage.getItem("Log.Level");
    if (logLevel) {
        Log.setLogLevel(<LogLevel>logLevel);
    }
}

Registry.registerSingletonComponent(Log.BCS_COMPONENT_NAME, Log);

window.onerror = Log.logUnhandledError;
window.addEventListener("unhandledrejection", Log.logUnhandledPromiseReject);
