export type progressCallback = (
    mainProgressPercentage: number,
    subProgressPercentage: number,
) => void;

export class ProgressFeedback {
    private totalProgressCallback: progressCallback;

    private totalProgressPercentage = 0;

    private subProgressFeedback: ProgressFeedback;

    private partProgressFeedbacks: ProgressFeedback[];

    public getSubProgressFeedback(): ProgressFeedback {
        if (!this.subProgressFeedback) {
            this.subProgressFeedback = new ProgressFeedback().onProgress(
                this.notifyCallback.bind(this),
            );
        }
        return this.subProgressFeedback || (this.subProgressFeedback = new ProgressFeedback());
    }

    public getPartProgressFeedbacks(partCount: number): ProgressFeedback[] {
        this.partProgressFeedbacks = [];
        for (let i = 0; i < partCount; i++) {
            this.partProgressFeedbacks.push(
                new ProgressFeedback().onProgress(this.notifyPartProgress.bind(this)),
            );
        }
        return this.partProgressFeedbacks;
    }

    public onProgress(totalProgressCallback: progressCallback): ProgressFeedback {
        this.totalProgressCallback = totalProgressCallback;
        return this;
    }

    public notifyProgress(currentTotalCount: number, fullTotalCount: number): void {
        this.totalProgressPercentage = Math.round((currentTotalCount * 100) / fullTotalCount);

        if (this.subProgressFeedback) {
            this.subProgressFeedback.notifyProgress(0, 0);
        }

        this.notifyCallback();
    }

    private notifyCallback(): void {
        if (this.totalProgressCallback) {
            this.totalProgressCallback(
                this.totalProgressPercentage,
                this.subProgressFeedback ? this.subProgressFeedback.getProgressPercentage() : 0,
            );
        }
    }

    private notifyPartProgress(): void {
        const progressPercentage = this.partProgressFeedbacks.reduce(
            (sumProgress, partProgressFeedback) => {
                return (
                    sumProgress +
                    partProgressFeedback.getProgressPercentage() / this.partProgressFeedbacks.length
                );
            },
            0,
        );
        this.notifyProgress(progressPercentage, 100);
    }

    public getProgressPercentage(): number {
        return this.totalProgressPercentage;
    }
}
