import { GUIElement } from "../GUIElement";
import d3 = require("d3");
import { AppColors } from "../../util/globalDefinitions/AppColors";

export class IconBadge implements GUIElement {
    private $parentTag: JQuery<HTMLElement>;

    private name: string = "noName";

    private text: string = "";

    constructor(name: string, text: string) {
        this.name = name;
        this.text = text;
    }

    compose($parent: JQuery<HTMLElement>, parentElement?: GUIElement): void {
        this.$parentTag = $parent;

        if (!this.text || this.text == "") {
            return;
        }

        const svg = d3
            .select($parent[0])
            .append("svg")
            .attr("class", "iconBadge " + this.name);
        svg.append("circle")
            .attr("cx", 40)
            .attr("cy", 10)
            .attr("r", 10)
            .style("fill", AppColors.TimeRecording_IsSyncOnlyChangedInApp_Color_hex);

        svg.append("circle")
            .attr("cx", 50)
            .attr("cy", 10)
            .attr("r", 10)
            .style("fill", AppColors.TimeRecording_IsSyncOnlyChangedInApp_Color_hex);
        svg.append("rect")
            .attr("x", 40)
            .attr("y", 0)
            .attr("width", 10)
            .attr("height", 20)
            .style("fill", AppColors.TimeRecording_IsSyncOnlyChangedInApp_Color_hex);

        let xText = 36;

        if (this.text.length === 1) {
            xText += 5;
        }
        svg.append("text")
            .attr("dx", xText)
            .attr("dy", 16)
            .text(this.text)
            .style("fill", AppColors.WHITE_hex)
            .style("font-size", 17);
    }

    public updateBadgeText(text: string): void {
        this.text = text;
        this.$parentTag.find("svg").remove();
        this.compose(this.$parentTag);
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}
