import { FooterTabBar } from "../../../../board/FooterTabBar";
import { ServerConfigProperties } from "../../../../common/config/ServerConfigProperties";
import { I18n } from "../../../../common/i18n/I18n";
import { AppConsole } from "../../../../common/log/AppConsole";
import { Component } from "../../../../core/Component";
import { Controller } from "../../../../core/Controller";
import { MessageEntity, MessageType } from "../../../../core/Message/MessageEntity";
import { Registry } from "../../../../core/Registry";
import { Animation, AppNavigator } from "../../../../core/Router";
import { TextLabel } from "../../../../gui/content/TextLabel";
import { GUIEventPool } from "../../../../gui/event/GUIEventPool";
import { GUIContext } from "../../../../gui/GUIContext";
import { GUIElement } from "../../../../gui/GUIElement";
import { GUIPage } from "../../../../gui/GUIPage";
import { ListView } from "../../../../gui/list/ListView";
import { ListViewContext } from "../../../../gui/list/ListViewContext";
import { FloatingActionButton } from "../../../../gui/navigation/FloatingActionButton";
import { TabbedViewBar } from "../../../../gui/navigation/TabbedViewBar";
import { ToolBar } from "../../../../gui/navigation/ToolBar";
import { ToolLink } from "../../../../gui/navigation/ToolLink";
import { SyncStateManager } from "../../../../sync/SyncStateManager";
import { AllowanceManager } from "../../AllowanceManager";
import { BusinessTravel } from "../../records/BusinessTravel";
import { VoucherAllowance } from "../../records/VoucherAllowance";
import { AllowanceGUIDefinitions } from "../AllowanceGUIDefinitions";
import { AllowancesListViewModel } from "../AllowancesListViewModel";

export class BusinessTravelsController implements Controller {
    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static readonly CONTROLLER_PATH = "travels";

    private static readonly TAB_SWITCH_TRAVELS = "travels";

    private static readonly TAB_SWITCH_VOUCHERS = "vouchers";

    private static readonly STATE_PROPERTY_TAB_SWITCH = "currentTabSwitch";

    private navigator: AppNavigator;

    private animation: Animation;

    /** Meldung von vorheriger Seite oder nach Aktion zum Anzeigen nach Seitenaufbau */
    private messages: MessageEntity[] = [];

    private i18n: I18n;

    private allowanceManager: AllowanceManager;

    private serverConfigProperties: ServerConfigProperties;

    private syncStateManager: SyncStateManager;

    private businessTravels: BusinessTravel[] = [];

    private voucherAllowances: VoucherAllowance[] = [];

    private allowanceGUIDefinitions: AllowanceGUIDefinitions;

    private page: GUIPage;

    private headerToolBar: ToolBar;

    private tabbedViewBar: TabbedViewBar;

    private activeTabSwitchName: string;

    /** Alternativ sichtbare ListViews je nmach aktivem Tab (Dienstreisen bzw. Einzelbelege) */
    private listViews: { [key: string]: ListView } = {};

    /** Button zum Hinzufügen neuer Dienstreisen bzw. Einzelbelege */
    private addTravelOrVoucherButton: FloatingActionButton;

    public getDependencyNames(): string[] {
        return [
            I18n.BCS_COMPONENT_NAME,
            AllowanceManager.BCS_COMPONENT_NAME,
            ServerConfigProperties.BCS_COMPONENT_NAME,
            SyncStateManager.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }) {
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.allowanceManager = <AllowanceManager>(
            depencencyComponents[AllowanceManager.BCS_COMPONENT_NAME]
        );
        this.serverConfigProperties = <ServerConfigProperties>(
            depencencyComponents[ServerConfigProperties.BCS_COMPONENT_NAME]
        );
        this.syncStateManager = <SyncStateManager>(
            depencencyComponents[SyncStateManager.BCS_COMPONENT_NAME]
        );

        this.allowanceGUIDefinitions = new AllowanceGUIDefinitions();
    }

    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        this.navigator = navigator;
        this.animation = animation;

        // Meldung von vorheriger Seite zum späteren Anzeigen merken
        if (parameters["affirmations"]) {
            this.addMessage(
                new MessageEntity(
                    this.i18n.get("MobileApp.travels.message." + parameters["affirmations"]),
                    MessageType.AFFIRMATION,
                ),
            );
        }
        if (parameters["warnings"]) {
            this.addMessage(
                new MessageEntity(
                    this.i18n.get("MobileApp.travels.message." + parameters["warnings"]),
                    MessageType.WARNING,
                ),
            );
        }

        // Aufräumen der Parameter dieser Seite (für sauberere History oder DeepLinks):
        // Behalten der Parameter, die eine Eigenschaft der Seite definieren (z.B. Id der Dienstreise),
        // Entfernen der Parameter, die temporär speziellen Aufrufbedingungen definieren (z.B. Herkunft).
        this.navigator.replaceCurrentParameters({});

        if (!this.allowanceManager.getAllowanceRecordingTerms().isAllowanceRecordingAvailable()) {
            this.navigator.navigateTo("index", {}, Animation.SLIDE_RIGHT);
            return;
        }

        this.allowanceManager
            .fetchBusinessTravelsAndVoucherAllowances()
            .then((result) => {
                this.businessTravels = result.businessTravels;
                this.voucherAllowances = result.voucherAllowances;
                this.composeBusinessTravelList();
            })
            .catch((error) => {
                AppConsole.log("ERROR", error);
            }); // TODO Fehler anzeigen
    }

    private async composeBusinessTravelList(): Promise<void> {
        this.page = new GUIPage(
            new GUIContext(this.i18n),
            BusinessTravelsController.CONTROLLER_PATH,
        );

        this.composeHeader();

        // Bei Rückkehr von einer Unterseite: Letzten aktiven Tab wieder aktiv setzen
        if (!this.activeTabSwitchName) {
            this.activeTabSwitchName = BusinessTravelsController.TAB_SWITCH_TRAVELS;
            if (this.animation == Animation.SLIDE_RIGHT) {
                this.activeTabSwitchName = <string>(
                    this.navigator
                        .getControllerState()
                        .getProperty(
                            BusinessTravelsController.STATE_PROPERTY_TAB_SWITCH,
                            this.activeTabSwitchName,
                        )
                );
            }
        }

        this.tabbedViewBar = new TabbedViewBar()
            .setFixed(true)
            .addContentTabSwitch(
                BusinessTravelsController.TAB_SWITCH_TRAVELS,
                this.i18n.get("MobileApp.travels.TabSwitch.businessTravels"),
                this.activeTabSwitchName == BusinessTravelsController.TAB_SWITCH_TRAVELS,
                this.composeBusinessTravelsList(),
            )
            .addContentTabSwitch(
                BusinessTravelsController.TAB_SWITCH_VOUCHERS,
                this.i18n.get("MobileApp.travels.TabSwitch.voucherAllowances"),
                this.activeTabSwitchName == BusinessTravelsController.TAB_SWITCH_VOUCHERS,
                this.composeSingleVoucherAllowanceList(),
            )
            .onTabChange(this.tabbedViewChanged.bind(this));
        this.page.addHeaderElement(this.tabbedViewBar);

        // Button zum Hinzufügen neuer Dienstreisen bzw. Einzelbelege
        this.addTravelOrVoucherButton = new FloatingActionButton()
            .setImageName("icon-add-white.svg")
            .onClick(this.createAllowance.bind(this));
        this.page.addFooterElement(this.addTravelOrVoucherButton);

        await this.composeFooterTabBar();

        // Meldung(en) von vorheriger Seite oder nach Aktion anzeigen
        this.page.composeMessages(this.messages, new GUIContext(this.i18n, new GUIEventPool()));
        this.messages = [];

        this.page
            .setAnimation(this.animation, this.navigator.doShowAnimations())
            .setScrollTop(this.navigator.getControllerState().getPageScrollTop())
            .compose($("body"));

        // Keine Animation nach Löschen und Re-Compose der Seite
        this.animation = Animation.NONE;
    }

    private composeHeader(): void {
        this.headerToolBar = new ToolBar()
            .setTitle(this.i18n.get("MobileApp.travels.title"))
            .addStyleClass("headBar")
            .setId("header_toolbar");

        this.headerToolBar.addToolLinkLeft(
            new ToolLink()
                .setId("navigateBack")
                .setImageName("icon-chevron-left.svg")
                .onClick(this.popState.bind(this)),
        );

        this.page.addHeaderElement(this.headerToolBar);
    }

    private composeBusinessTravelsList(): GUIElement {
        const listViewContext = new ListViewContext()
            .setI18n(this.i18n)
            .setModel(new AllowancesListViewModel(this.businessTravels, this.i18n));

        const businessTravelsListViewDef = this.serverConfigProperties.customizeGUIDefinitions(
            this.allowanceGUIDefinitions.getBusinessTravelsListViewDef(),
            BusinessTravelsController.CONTROLLER_PATH,
            "businesstravels",
            "display",
        );

        const businessTravelsListView = new ListView(listViewContext, businessTravelsListViewDef)
            .setDOMId("businessTravels")
            .addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW)
            .onRowClicked(this.listRowClicked.bind(this));

        this.listViews[BusinessTravelsController.TAB_SWITCH_TRAVELS] = businessTravelsListView;

        if (listViewContext.getListModel().countRows() == 0) {
            const noRowsTextLabel = new TextLabel()
                .setText(this.i18n.get("MobileApp.travels.noTravels"))
                .addStyleClass("textLabelNoContent")
                .addStyleClass("textLabelWithMarginTop");
            return noRowsTextLabel;
        }

        return businessTravelsListView;
    }

    private composeSingleVoucherAllowanceList(): GUIElement {
        const listViewContext = new ListViewContext()
            .setI18n(this.i18n)
            .setModel(new AllowancesListViewModel(this.voucherAllowances, this.i18n));

        const singleVoucherAllowancesListViewDef =
            this.serverConfigProperties.customizeGUIDefinitions(
                this.allowanceGUIDefinitions.getSingleVoucherAllowancesListViewDef(),
                BusinessTravelsController.CONTROLLER_PATH,
                "singlevouchers",
                "display",
            );

        const vouchersListView = new ListView(listViewContext, singleVoucherAllowancesListViewDef)
            .setDOMId("vouchers")
            .addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW)
            .onRowClicked(this.listRowClicked.bind(this));

        this.listViews[BusinessTravelsController.TAB_SWITCH_VOUCHERS] = vouchersListView;

        if (listViewContext.getListModel().countRows() == 0) {
            const noRowsTextLabel = new TextLabel()
                .setText(this.i18n.get("MobileApp.travels.noVouchers"))
                .addStyleClass("textLabelNoContent")
                .addStyleClass("textLabelWithMarginTop");
            return noRowsTextLabel;
        }

        return vouchersListView;
    }

    /**
     * Fußzeile (u.a. mit Home-Link)
     */
    private async composeFooterTabBar(): Promise<void> {
        const footerTabBar = new FooterTabBar(new GUIContext(this.i18n, null)); // this.eventPool));
        await footerTabBar.composeDefaultFooter(
            null,
            this.navigator,
            this.getNavigateBackParameters(),
            () => this.syncStateManager.countUnsyncedElements(),
        );
        this.page.addFooterElement(footerTabBar);
    }

    /**
     * Aufruf nach Wechsel des Tabs ("Dienstreisen" oder "Einzelbelege")
     * @param switchName Name des aktiven Tabs
     */
    private tabbedViewChanged(switchName: string): void {
        this.activeTabSwitchName = switchName;

        this.page.scrollTop(0);
    }

    private listRowClicked(path: string, parameters: { [key: string]: string }): void {
        this.navigator.navigateTo(path, parameters, Animation.SLIDE_LEFT);
    }

    private async createAllowance(): Promise<void> {
        switch (this.tabbedViewBar.getActiveTabSwitchName()) {
            case BusinessTravelsController.TAB_SWITCH_TRAVELS:
                // Dienstreisen-Seite mit Id "*" öffnen, damit neue Dienstreise erzeugt wird.
                // Id muss "*", damit Fall von Aufruf aus Board (ohne irgendeinen Parameter) möglich.
                // Aufruf vom Board aus setzt entweder laufende Dienstreise fort oder erzeugt neue Dienstreise.
                this.openBusinessTravel("*");
                break;
            case BusinessTravelsController.TAB_SWITCH_VOUCHERS:
                // BelegspesenSeite ohne Id öffnen, damit neue Belegspese erzeugt wird.
                // Ohne Id genügt, damit eindeutig, dass neue Belegspese erzeugt wurde
                this.openVoucherAllowance(null);
                break;
        }
    }

    private openBusinessTravel(businessTravelOid: string): void {
        this.navigator.navigateTo("travel", { oid: businessTravelOid }, Animation.SLIDE_LEFT);
    }

    private openVoucherAllowance(voucherAllowanceOid: string): void {
        this.navigator.navigateTo(
            "voucher",
            { voucherAllowanceOid: voucherAllowanceOid },
            Animation.SLIDE_LEFT,
        );
    }

    /**
     * Fügt Meldung zum Anzeigen nach Seitenaufbau hinzu.
     * @param message Meldung
     */
    private addMessage(message: MessageEntity): void {
        this.messages.push(message);
    }

    private getNavigateBackParameters(): { [key: string]: string } {
        return {
            navigateBackPath: BusinessTravelsController.CONTROLLER_PATH,
        };
    }

    public popState(): void {
        this.navigator.navigateTo("index", {}, Animation.SLIDE_RIGHT);
    }

    public destroy(): void {
        // Merken, welcher Tab (Dienstreisen / Einzelbelege zuletzt aktiv war)
        if (this.tabbedViewBar) {
            this.navigator
                .getControllerState()
                .setProperty(
                    BusinessTravelsController.STATE_PROPERTY_TAB_SWITCH,
                    this.tabbedViewBar.getActiveTabSwitchName(),
                );
        }
    }
}

Registry.registerComponent(BusinessTravelsController.CONTROLLER_PATH, BusinessTravelsController);
