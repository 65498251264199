import { BCSDate } from "./common/BCSDate";
import { Booking } from "./domain/time_recording/bookings/Booking";
import { BookingsPerDay } from "./domain/time_recording/bookings/BookingsPerDay";
import { Bootstrap } from "./core/Bootstrap";
import { ComponentRegistry } from "./core/ComponentRegistry";
import { GUIEventPool } from "./gui/event/GUIEventPool";
import { I18n } from "./common/i18n/I18n";
import { IdGenerator } from "./util/text/IdGenerator";
import { IndexedDB } from "./database/IndexedDB";
import { IndexedDBQuery } from "./database/IndexedDBQuery";
import { MessageEntity } from "./core/Message/MessageEntity";
import { MessagePool } from "./core/Message/MessagePool";
import { TimeSpan } from "./domain/time_recording/timespans/TimeSpan";
import "./bcs-app-general.less";
import "./bcs-app-dark-full.less";
import "./global_types";
const GLOBAL = globalThis as any;
const WINDOW = window as any;

GLOBAL.jQuery = $;
WINDOW.jQuery = $;

GLOBAL.$ = $;
WINDOW.$ = $;
window.bootstrap = new Bootstrap();
// Für JSUnitTests: Setzen am window, damit für Tests aufrufbar
window.componentReg = new ComponentRegistry();
window.BCSDate = BCSDate;
window.MessagePool = MessagePool;
window.MessageEntity = MessageEntity;
window.IndexedDBQuery = IndexedDBQuery;
window.IndexedDB = IndexedDB;
window.IdGenerator = IdGenerator;
window.I18n = I18n;
window.GUIEventPool = GUIEventPool;
window.TimeSpan = TimeSpan;
window.BookingsPerDay = BookingsPerDay;

window.Booking = Booking;

/**
 *   Being invoked when all components of the website are loaded.
 */
const bootstrapOnLoad = function () {
    const isAnJSUnitTest = typeof window.isJSUnitTest != "undefined" && window.isJSUnitTest;
    if (!isAnJSUnitTest) {
        window.bootstrap = new Bootstrap().start();
    }
};
window.addEventListener("load", bootstrapOnLoad, false);

// Ausgabe in Console für App-Demos im Handy-Rahmen mit geöffneten Browser Development-Tools (F12)
console.log(
    " ____            _      _    _                     ____   ____ ____       _                \n" +
        "|  _ \\ _ __ ___ (_) ___| | _| |_ _ __ ___  _ __   | __ ) / ___/ ___|     / \\   _ __  _ __  \n" +
        "| |_) | '__/ _ \\| |/ _ \\ |/ / __| '__/ _ \\| '_ \\  |  _ \\| |   \\___ \\    / _ \\ | '_ \\| '_ \\ \n" +
        "|  __/| | | (_) | |  __/   <| |_| | | (_) | | | | | |_) | |___ ___) |  / ___ \\| |_) | |_) |\n" +
        "|_|   |_|  \\___// |\\___|_|\\_\\\\__|_|  \\___/|_| |_| |____/ \\____|____/  /_/   \\_\\ .__/| .__/ \n" +
        "              |__/                                                            |_|   |_|    \n\n" +
        "Hinweise zur Demonstration der BCS App im Browser mit Handy-Rahmen siehe https://support.projektron.de/bcs/faqdetail?id=505",
);
