import { Component } from "../../core/Component";
import { SingletonComponent } from "../../core/SingletonComponent";
import { I18n } from "../i18n/I18n";
import { IndexedDB } from "../../database/IndexedDB";
import { Schema } from "../schema/Schema";
import { Registry } from "../../core/Registry";
import { UserSession } from "../auth/UserSession";
import { PreferencesPool } from "./PreferencesPool";
import { ApplicationProperties } from "../properties/ApplicationProperties";
import { ServerPreferences } from "./ServerPreferences";
import { EntityValue } from "../../entities/values/EntityValue";
import { ServerConfigProperties } from "../config/ServerConfigProperties";
import { ClientPreferences } from "./ClientPreferences";
import { BooleanValue } from "../../entities/values/BooleanValue";

/**
 * Manager für die Benutzereinstellungen (Pausenlänge, etc) und App-Spezifische Einstellungen. Importiert dafür auch die ServerConfig Properties, um Default Werte vorzubelegen. Das Abfragen der Einstellungen bleibt weiterhin den Controllern vorbehalten. Auch werden, nicht durch den Nutzer änderbare Properties (GUI-Definitionen, Filter, Rechte) weiterhin nicht hier, sondern am Controller ausgelesen.
 */
export class PreferencesManager implements Component, SingletonComponent {
    /** Symbolischer Name dieser Komponente */
    public static BCS_COMPONENT_NAME = "PreferencesManager";

    private readonly POST_FILTER: string = "postFilterTaskList";

    private readonly CONTACT_DOWNLOAD_ENABLED: string = "contactDownload";

    private indexedDB: IndexedDB;

    private userSession: UserSession;

    private schema: Schema;

    private currentUserOid: string;

    private preferencesPool: PreferencesPool;

    private applicationProperties: ApplicationProperties;

    private serverPreferences: ServerPreferences;

    private serverConfigProperties: ServerConfigProperties;

    private clientPreferences: ClientPreferences = new ClientPreferences();

    constructor() {}

    public getDependencyNames(): string[] {
        return [
            I18n.BCS_COMPONENT_NAME,
            Schema.BCS_COMPONENT_NAME,
            IndexedDB.BCS_COMPONENT_NAME,
            UserSession.BCS_COMPONENT_NAME,
            ApplicationProperties.BCS_COMPONENT_NAME,
            ServerConfigProperties.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }): void {
        this.schema = <Schema>depencencyComponents[Schema.BCS_COMPONENT_NAME];

        this.indexedDB = <IndexedDB>depencencyComponents[IndexedDB.BCS_COMPONENT_NAME];
        this.userSession = <UserSession>depencencyComponents[UserSession.BCS_COMPONENT_NAME];

        this.applicationProperties = <ApplicationProperties>(
            depencencyComponents[ApplicationProperties.BCS_COMPONENT_NAME]
        );

        this.preferencesPool = new PreferencesPool(
            this.schema,
            this.applicationProperties,
            this.indexedDB,
            this.userSession,
        );
        this.serverConfigProperties = <ServerConfigProperties>(
            depencencyComponents[ServerConfigProperties.BCS_COMPONENT_NAME]
        );
    }

    public start(): Promise<void> {
        return Promise.resolve();
    }

    public async notifyBeginUserSession(isOnline: boolean): Promise<void> {
        this.currentUserOid = this.userSession.getCurrentUserOid();

        if (isOnline) {
            await this.synchronize();
        }
        await this.readPreferencesFromDatabase();
    }

    public synchronize(): Promise<void> {
        const self = this;
        return self.preferencesPool.getServerSidePreferences();
    }

    private readPreferencesFromDatabase(): Promise<void> {
        const self = this;

        return this.applicationProperties
            .readProperty(PreferencesPool.PREFERENCES_STORE_NAME, this.currentUserOid, null)
            .then((serverPreferences) => {
                if (serverPreferences) {
                    self.serverPreferences = new ServerPreferences(this.schema, serverPreferences);
                }
            });
    }

    public getServerPreferences(): ServerPreferences {
        return this.serverPreferences;
    }

    public getProfileCSSTheme(): string {
        return this.serverPreferences.getValue("profileCSSTheme").getString();
    }

    public getWorkEnd(): number {
        const value: EntityValue = this.serverPreferences.getValue("profileWorkEnd");
        const workEnd = parseInt(value.getString());
        return workEnd;
    }

    public getWorkStart(): number {
        const value: EntityValue = this.serverPreferences.getValue("profileWorkStart");
        const workStart = parseInt(value.getString());
        return workStart;
    }

    public getPauseLength(): number {
        const value: EntityValue = this.serverPreferences.getValue("profilePauseLength");
        const pauseLength = parseInt(value.getString());
        return pauseLength;
    }

    public getEffortRecordingWithAttendances(): boolean {
        const value: EntityValue = this.serverPreferences.getValue(
            "profileEffortRecordingWithAttendances",
        );
        // Das Ganze ist etwas blöd... wir haben ein StringValue und wenn wir .getString() machen, bekommen wir trotzdem ein boolean,
        // weil da auch ein boolean drin steck :´-)
        return value.getSimpleValue() === true;
    }
    /***
     * Properties, die lokal überschrieben werden können
     */
    /**
     * Schreibt die ServerConfigPropertys, die auch lokal in der App änderbar sein sollen in die Indexed DB. Sinn der Sache ist, dass wir die Einstellungen der App mit dem vom Admin (oder von uns) eingestellten Default vorbelegen wollen. Offen wäre hier, ob wir die Properties nicht vielleicht doch mal abrüsten (weil wir die Properties nicht für wichtig genug halten, dass er Admin sie einstellen kann). Dann legen wir hier die Defaults fest, die bisher in der Config.xml stehen.
     * Zukünftige Settings müssten also hier nachgetragen werden
     */
    getConfigPropertyDefaults(): Promise<void> {
        this.clientPreferences.setValue(
            this.CONTACT_DOWNLOAD_ENABLED,
            new BooleanValue(this.downloadEnabledConfigDefault()),
        );
        this.clientPreferences.setValue(
            this.POST_FILTER,
            new BooleanValue(this.postFilterTaskListConfigDefault()),
        );
        return new Promise((resolve, reject) => {
            this.preferencesPool.writePreferencesToDB(this.clientPreferences, resolve, reject);
        });
    }
    /**
     * Ist der direkte Download von Kontaktkarten über die App ein- oder ausgeschaltet?
     * @returns ja oder nein
     */
    public downloadEnabled(): boolean {
        return this.downloadEnabledConfigDefault();

        // return (
        //     this.clientPreferences.getValue(this.CONTACT_DOWNLOAD_ENABLED).getSimpleValue() === true
        // );
    }
    /**
     * @returns Holt den Default aus der Config oder setzt ihn später lokal
     */
    private downloadEnabledConfigDefault(): boolean {
        const contactDownload: string = this.serverConfigProperties.getProperty(
            "MobileApp.ContactDownload",
        );
        if (contactDownload != null) {
            if (contactDownload.valueOf().toLowerCase() == "true") {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    /**
     * Ist die Filterung der Aufgabenliste (aus Performance oder anderen Gründen) deaktiviert?
     * @returns ja oder nein
     */
    public postFilterTaskList(): boolean {
        //  return this.clientPreferences.getValue(this.POST_FILTER).getSimpleValue() === true;

        return this.postFilterTaskListConfigDefault();
    }
    /**
     * @returns Holt den Default aus der Config oder setzt ihn später lokal
     */
    private postFilterTaskListConfigDefault(): boolean {
        const contactDownload: string = this.serverConfigProperties.getProperty(
            "MobileApp.TimeRecording.PostFilterTaskList",
        );
        if (contactDownload != null) {
            if (contactDownload.valueOf().toLowerCase() == "true") {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }
}

Registry.registerSingletonComponent(PreferencesManager.BCS_COMPONENT_NAME, PreferencesManager);
