import { Controller } from "../../../core/Controller";
import { AppNavigator, Animation } from "../../../core/Router";
import { I18n } from "../../../common/i18n/I18n";
import { FileManager } from "../FileManager";
import { BCSFile } from "../records/BCSFile";
import { GUIPage } from "../../../gui/GUIPage";
import { Component } from "../../../core/Component";
import { ToolBar } from "../../../gui/navigation/ToolBar";
import { ToolLink } from "../../../gui/navigation/ToolLink";
import { Image } from "../../../gui/content/Image";
import { Registry } from "../../../core/Registry";
import { GUIContext } from "../../../gui/GUIContext";

/**
 * Generische Detailseite, die eine Bild-Datei aus der Dateiablage im Vollbild anzeigt.
 *
 * Als Parameter werden die Oid der Bilddatei und der anzuzeigende Seitentitel
 * sowie der Pfad und die Parameter (als stringified JSON) zum Aufruf bei Zurück-Navigation erwaretet.
 *
 * (Aktuell nicht mehr genutzt, da Beleganhänge eigenen Controller haben)
 */
export class ImageDetailController implements Controller {
    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static CONTROLLER_PATH = "image";

    /** Aufruf-Parameter für die Oid der anzuzeigenden Bilddatei */
    public static PARAMETER_IMAGE_FILE_ID = "imageFileId";

    /** Aufruf-Parameter für I18n-Key des Seitentitles */
    public static PARAMETER_TITLE_KEY = "titleKey";

    /** Aufruf-Parameter für Pfad zur aufzurufende Seite bei Zurück-Navigation */
    public static PARAMETER_NAVIGATE_BACK_PATH = "navigateBackPath";

    /** Aufruf-Parameter für Parameter (als stringified JSON) für aufzurufende Seite bei Zurück-Navigation */
    public static PARAMETER_NAVIGATE_BACK_PARAMETERS = "navigateBackParameters";

    private navigator: AppNavigator;

    private animation: Animation;

    private i18n: I18n;

    private fileManager: FileManager;

    private imageFile: BCSFile;

    private page: GUIPage;

    /** I18n-Key des Seitentitles */
    private pageTitleKey;

    /** Pfad zur aufzurufende Seite bei Zurück-Navigation */
    private navigateBackPath;

    /** Parameter für aufzurufende Seite bei Zurück-Navigation */
    private navigateBackParameters;

    public getDependencyNames(): string[] {
        return [I18n.BCS_COMPONENT_NAME, FileManager.BCS_COMPONENT_NAME];
    }

    public init(depencencyComponents: { [key: string]: Component }) {
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.fileManager = <FileManager>depencencyComponents[FileManager.BCS_COMPONENT_NAME];
    }

    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        this.navigator = navigator;
        this.animation = animation;

        this.pageTitleKey = parameters[ImageDetailController.PARAMETER_TITLE_KEY];
        this.navigateBackPath = parameters[ImageDetailController.PARAMETER_NAVIGATE_BACK_PATH];
        this.navigateBackParameters = JSON.parse(
            parameters[ImageDetailController.PARAMETER_NAVIGATE_BACK_PARAMETERS] || "{}",
        );

        const self = this;
        this.fetchImageFile(parameters).then(() => {
            if (self.imageFile) {
                // Wenn Bild geladen: Seite anzeigen
                self.composePage();
            } else {
                // Wenn Bild nicht geladen: Zurück zur Aufrufer-Seite
                this.popState();
            }
        });
    }

    private async fetchImageFile(parameters: { [key: string]: string }): Promise<void> {
        const imageFileId = parameters[ImageDetailController.PARAMETER_IMAGE_FILE_ID];

        if (imageFileId) {
            this.imageFile = await (<Promise<BCSFile>>this.fileManager.fetchFileById(imageFileId));
        }
    }

    private composePage() {
        // Seite (Wurzelelement) erstellen
        this.page = new GUIPage(
            new GUIContext(this.i18n),
            ImageDetailController.CONTROLLER_PATH,
            this.imageFile.getId(),
        );

        // Kopfzeile (mit Navigation und Bearbeiten-/Löschen-Modus)
        this.composeHeader();

        // Belegfoto anzeigen
        this.composePhoto();

        // Seite rendern
        this.page
            .setAnimation(this.animation, this.navigator.doShowAnimations())
            .compose($("body"));
    }

    private composeHeader(): void {
        const headerToolBar = new ToolBar()
            .setId("header_toolbar")
            .setTitle(this.i18n.get(this.pageTitleKey))
            .addStyleClass("headBar")
            .addToolLinkLeft(
                new ToolLink()
                    .setId("navigateBack")
                    .setImageName("icon-chevron-left.svg")
                    .onClick(this.popState.bind(this)),
            );
        this.page.addHeaderElement(headerToolBar);
    }

    private composePhoto(): void {
        const image = new Image()
            .addStyleClass("imagefile")
            .setImageDataURL(this.imageFile.getImageDataURL());

        this.page.addPageElement(image);
    }

    public popState(): void {
        this.navigator.navigateTo(
            this.navigateBackPath,
            this.navigateBackParameters,
            Animation.SLIDE_RIGHT,
        );
    }

    public destroy(): void {
        // nichts zu tun
    }
}

Registry.registerComponent(ImageDetailController.CONTROLLER_PATH, ImageDetailController);
