import { Component } from "../../core/Component";
import { Registry } from "../../core/Registry";
import { SingletonComponent } from "../../core/SingletonComponent";
import { ProgressFeedback } from "../../util/progress/ProgressFeedback";
import { UserSession } from "../auth/UserSession";
import { ApplicationProperties } from "../properties/ApplicationProperties";
import { I18nClient } from "./I18nClient";
import { I18nFallBackLabels } from "./I18nFallBackLabels";

export class I18n implements Component, SingletonComponent {
    public static BCS_COMPONENT_NAME = "I18n";

    private applicationProperties: ApplicationProperties;

    private userSession: UserSession;

    private labels: { [key: string]: string } = {};

    private locale: string;

    /** BCS-Version des Servers */
    private revision: string = "?";

    private i18nAge: number;

    public getDependencyNames(): string[] {
        return [ApplicationProperties.BCS_COMPONENT_NAME, UserSession.BCS_COMPONENT_NAME];
    }

    public init(depencencyComponents: { [key: string]: Component }): void {
        this.applicationProperties = <ApplicationProperties>(
            depencencyComponents[ApplicationProperties.BCS_COMPONENT_NAME]
        );
        this.userSession = <UserSession>depencencyComponents[UserSession.BCS_COMPONENT_NAME];

        this.useFallbackLabels();
    }

    public useFallbackLabels() {
        this.locale = I18nFallBackLabels.getFallbackLanguage();
        this.labels = I18nFallBackLabels.getFallbackLabels(this.locale);
    }

    public start(): Promise<void> {
        return Promise.resolve();
    }

    public notifyBeginUserSession(
        isOnline: boolean,
        progressFeedback?: ProgressFeedback,
    ): Promise<void> {
        return this.readI18nFromDatabase().then(() => {
            return isOnline ? this.readI18nFromBCS(progressFeedback) : Promise.resolve();
        });
    }

    public synchronize(progressFeedback?: ProgressFeedback): Promise<void> {
        return this.readI18nFromBCS(progressFeedback);
    }

    private readI18nFromDatabase(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.applicationProperties
                .readProperty(
                    I18n.BCS_COMPONENT_NAME,
                    null,
                    this.userSession.getCurrentUserLanguage(),
                )
                .then((i18n) => {
                    if (i18n) {
                        this.labels = i18n["labels"];
                        this.locale = i18n["locale"];
                        this.revision = i18n["revision"];
                        this.i18nAge = i18n["age"];
                    } else {
                        this.i18nAge = 0;
                        this.locale = I18nFallBackLabels.getFallbackLanguage();
                        this.revision = "?";
                        this.labels = I18nFallBackLabels.getFallbackLabels(this.locale);
                    }
                    resolve();
                })
                .catch(reject);
        });
    }

    private readI18nFromBCS(progressFeedback: ProgressFeedback): Promise<void> {
        return new Promise((resolve, reject) => {
            new I18nClient()
                .fetchI18nLabels(
                    this.i18nAge,
                    this.userSession.getCurrentUserLanguage(),
                    progressFeedback,
                )
                .then((i18n) => {
                    if (i18n["labels"] && i18n["cache"] != "uptodate") {
                        //AppConsole.log("[I18n] I18n FETCHED from BCS", this.i18nAge + " < " + i18n["age"]);

                        // wenn lokale gespeicherte I18n-Labels nicht mehr aktuell,
                        // von BCS geladene I18n-Label verwenden und lokal speichern
                        this.labels = i18n["labels"];
                        this.locale = i18n["locale"];
                        this.revision = i18n["revision"];
                        this.i18nAge = i18n["age"];

                        this.applicationProperties
                            .writeProperty(I18n.BCS_COMPONENT_NAME, null, this.locale, i18n)
                            .then(resolve)
                            .catch(reject);
                    } else {
                        //AppConsole.log("[I18n] I18n FETCHED Lokal I18n uptodate", this.i18nAge);
                        resolve();
                    }
                })
                .catch(reject);
        });
    }

    /**
     * @param separator Trennzeichen zwischen Sprache und Region, z.B. de_DE oder de-DE (optional)
     * @return Locale dieses I18ns (= des eingeloggten Benutzers)
     */
    public getLocale(separator = "_"): string {
        if (this.userSession.isLoggedIn()) {
            const userLanguage = this.userSession.getCurrentUserLanguage();
            if (userLanguage) {
                return userLanguage.replace(new RegExp("_"), separator);
            }
        } else {
            return this.locale.replace(new RegExp("_"), separator);
        }
    }

    /**
     * @return BCS-Version
     */
    public getRevision(): string {
        return this.revision;
    }

    /**
     * Übersetzt gegebenen Key in die Sprache des eingeloggten Benutzers.
     * @param key I18n-Key
     * @return Übersetzung
     */
    public get(key: string, defaultValue?: string): string {
        if (typeof defaultValue !== "undefined") {
            return this.labels.hasOwnProperty(key) ? this.labels[key] : defaultValue;
        } else {
            return this.labels.hasOwnProperty(key) ? this.labels[key] : "[" + key + "?]";
        }
    }

    /**
     * Übersetzt gegebenen Attributnamen in die Sprache des eingeloggten Benutzers.
     * Es wird immer die Variante "Objekttyp.Subtyp.Attribut" verwendet, das diese per REST-Schnittstelle so ausgeliefert wird.
     *
     * @param type Objekttyp
     * @param subtype Subtyp
     * @param attribute Attribut
     * @return Übersetzung für Attribut
     */
    public getAttribute(type: string, subtype: string, attribute: string): string {
        return (
            this.labels["MobileApp." + type + "." + subtype + "." + attribute] ||
            this.labels[type + "." + subtype + "." + attribute] ||
            this.labels["MobileApp." + type + "." + attribute] ||
            this.labels[type + "." + attribute] ||
            this.labels["MobileApp." + attribute] ||
            "[" + attribute + "?]"
        );
    }

    /**
     * Übersetzt gegebenen Attribut-Optionswert in die Sprache des eingeloggten Benutzers.
     * Es werden alle Varianten mit dem Objekttyp / Subtyp / Attribut als Präfix probiert.
     *
     * @param type Objekttyp
     * @param subtype Subtyp
     * @param attribute Attribut
     * @param option Option
     * @return Übersetzung für Option
     */
    public getAttributeOption(
        type: string,
        subtype: string,
        attribute: string,
        option: string,
    ): string {
        return (
            this.labels[type + "." + subtype + "." + attribute + "." + option] ||
            this.labels["MobileApp." + type + "." + subtype + "." + attribute + "." + option] ||
            this.labels[type + "." + attribute + "." + option] ||
            this.labels["MobileApp." + type + "." + attribute + "." + option] ||
            this.labels[type + "." + option] ||
            this.labels["MobileApp." + type + "." + option] ||
            this.labels[attribute + "." + option] ||
            this.labels["MobileApp." + attribute + "." + option] ||
            this.labels[option] ||
            this.labels["MobileApp." + option] ||
            "[" + option + "?]"
        );
    }

    /**
     * Übersetzt gegebenen Key in die Sprache des eingeloggten Benutzers und setzt gegebene Parameter ein.
     * @param key I18n-Key
     * @param parameters Platzhalter-Werten, um Platzhalter {0}, {1}, {2}, ... in Übersetzung zu ersetzen
     * @return Übersetzung mit eingesetzten Platzhalter-Werten
     */
    public format(key: string, parameters: string[] | number[]): string {
        // Übersetzung als Pattern verwenden
        const value = this.get(key);
        // Platzhalter {0}, {1}, {2}, ... durch Parameter ersetzen
        return value.replace(
            /\{\d+\}/,
            (placeholder) => parameters[placeholder.substring(1, placeholder.length - 1)],
        );
    }

    /**
     * Formatiert eine Meldung mit einer Anzahl betroffener Objekte und verwendet für Singluar und Plural verchiedene I18n-Keys.
     * Bsp.:
     * formatMessageWithQuantity("message.entry.deleted", 1): message.entry.deleted.singular="1 Eintrag wurde gelöscht."
     * formatMessageWithQuantity("message.entry.deleted", 3): message.entry.deleted.plural="3 Einträge wurden gelöscht."
     *
     * @param key I18n-Basis-Key für Meldung
     * @param quantity Anzahl betroffener Objekte
     */
    public formatMessageWithQuantity(key: string, quantity: number): string {
        if (quantity == 1 && this.labels.hasOwnProperty(key + ".singular")) {
            return this.format(key + ".singular", [quantity]);
        }

        if (this.labels.hasOwnProperty(key + ".plural")) {
            return this.format(key + ".plural", [quantity]);
        }

        return this.format(key, [quantity]);
    }

    /**
     * @param key I18n-Key der Datumsformates
     * @returns Datumsformat für gegebenen Key passend zur Locale des eingeloggten Benutzers in moment.js-Syntax (z.B. DD.MM.YYYY)
     */
    public getDateFormat(key: string): string {
        return this.get(key)
            .replace(new RegExp("dd"), "DD")
            .replace(new RegExp("yyyy"), "YYYY")
            .replace(new RegExp("yy"), "YY");
    }

    public getLocaleDaysOfWeek(): string[] {
        const weekdays = [
            "weekdayMonday",
            "weekdayTuesday",
            "weekdayWednesday",
            "weekdayThursday",
            "weekdayFriday",
            "weekdaySaturday",
            "weekdaySunday",
        ];
        const weekdaysWithPrefixes: string[] = [];
        for (let i: number = 0; i < weekdays.length; i++) {
            weekdaysWithPrefixes.push("MobileApp." + weekdays[i]);
        }
        // TODO: nur der Default, sollte vom Server kommen
        return weekdaysWithPrefixes;
    }

    public getLocaleMonthOfYear(): string[] {
        // TODO: nur der Default, sollte vom Server kommen
        const monthOfYear: string[] = [
            "monthName.January",
            "monthName.February",
            "monthName.March",
            "monthName.April",
            "monthName.May",
            "monthName.June",
            "monthName.July",
            "monthName.August",
            "monthName.September",
            "monthName.October",
            "monthName.November",
            "monthName.December",
        ];
        const monthsWithPrefixes: string[] = [];
        for (let i: number = 0; i < monthOfYear.length; i++) {
            monthsWithPrefixes.push("MobileApp." + monthOfYear[i]);
        }
        return monthsWithPrefixes;
    }
}

Registry.registerSingletonComponent(I18n.BCS_COMPONENT_NAME, I18n);
