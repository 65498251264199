import { BusinessTravel } from "./records/BusinessTravel";
import { VoucherAllowance } from "./records/VoucherAllowance";
import { BCSDate } from "../../common/BCSDate";

/**
 * Zusammenfassung der existierenden Dienstreisen/Einzelbelege.
 */
export class AllowanceSummary {
    private allowanceRecordingAvailable: boolean;

    private businessTravelsNumber: number;

    private singleVouchersNumber: number;

    private runningTravelId: string;

    private runningTravelStartDate: BCSDate;

    private runningTravelEndDate: BCSDate;

    public setAllowanceRecordingAvailable(allowanceRecordingAvailable: boolean): AllowanceSummary {
        this.allowanceRecordingAvailable = allowanceRecordingAvailable;
        return this;
    }

    public isAllowanceRecordingAvailable(): boolean {
        return this.allowanceRecordingAvailable;
    }

    public setCountBusinessTravels(businessTravelsNumber: number): AllowanceSummary {
        this.businessTravelsNumber = businessTravelsNumber;
        return this;
    }

    public countBusinessTravels(): number {
        return this.businessTravelsNumber;
    }

    public setCountSingleVouchers(singleVouchersNumber: number): AllowanceSummary {
        this.singleVouchersNumber = singleVouchersNumber;
        return this;
    }

    public countSingleVouchers(): number {
        return this.singleVouchersNumber;
    }

    public setOngoingAllowances(ongoingBusinessTravelsAndVouchers: {
        businessTravels: BusinessTravel[];
        voucherAllowances: VoucherAllowance[];
    }): AllowanceSummary {
        const ongoingBusinessTravels = ongoingBusinessTravelsAndVouchers.businessTravels;

        if (ongoingBusinessTravels.length > 0) {
            const runningBusinessTravel = ongoingBusinessTravels[ongoingBusinessTravels.length - 1];
            this.runningTravelId = runningBusinessTravel.getId();
            this.runningTravelStartDate = runningBusinessTravel.getStartDateTime().getBCSDate();
            this.runningTravelEndDate = runningBusinessTravel.getEndDateTime().getBCSDate();
        }

        return this;
    }

    public isTravelRunning(): boolean {
        return !!this.runningTravelId;
    }

    public getRunningTravelId(): string {
        return this.runningTravelId;
    }

    public getRunningTravelStartDate(): BCSDate {
        return this.runningTravelStartDate;
    }

    public getRunningTravelEndDate(): BCSDate {
        return this.runningTravelEndDate;
    }

    /**
     * Berechnung der aktuell der verstrichenen Reisezeit (gerundet in %).
     *
     * @returns Fortschritt der aktuell laufenden Dienstreise (gerundet in %)
     */
    public getRunningTravelElapsedPercentage(): number {
        const durationMS =
            this.runningTravelEndDate.getDate().getTime() -
            this.runningTravelStartDate.getDate().getTime();
        const elapsedMS = new Date().getTime() - this.runningTravelStartDate.getDate().getTime();
        let progressPercent = Math.round((elapsedMS * 100) / durationMS);
        progressPercent = Math.max(0, Math.min(progressPercent, 100));
        return progressPercent;
    }
}
