import { I18n } from "../../common/i18n/I18n";

export class HTTPError {
    private status: number;

    private errorcode: string;

    private error: string;

    private response: JQueryXHR | {};

    private bcsErrorMessage: string;

    constructor(errorcode: string, error: string, response: JQueryXHR | {}, status: number) {
        this.status = status;
        this.errorcode = errorcode;
        this.error = error;
        this.response = response;

        // this.errorResult = {"errorcode": errorcode, "error": error, "response": response, "status": response.status};
        // TODO Jens: Fehlermeldung für 500 sollte nicht nur undefined sein

        // Sofern bei als Body JSON mit {""errorMessage": ...} mit gesandt wird, wird diese Fehlermeldung angezeigt.
        if (response.hasOwnProperty("responseText")) {
            const responseText = response["responseText"];
            // HTTP-Response als JSON parsen (falls es JSON ist, eine Error-Page von BCS wäre es z.B. nicht)
            if (responseText && responseText.length > 1 && responseText.substring(0, 1) == "{") {
                const responseObject = JSON.parse(responseText);
                if (responseObject.hasOwnProperty("errorMessage")) {
                    const errorMessage = responseObject["errorMessage"];
                    this.bcsErrorMessage = errorMessage;
                }
            }
        }
    }

    public isLoginDataIncorrect(): boolean {
        return this.status === 401;
    }

    /**
     * @returns true, im Falle von HTTP Status 503, dies wird explizit gesendet, wenn  die Mobile App deaktiviert ist.
     */
    public isServiceUnavailable(): boolean {
        return this.status === 503;
    }

    public isInternalServerError(): boolean {
        return this.status === 500;
    }

    public isNoResponseFromServer(): boolean {
        return this.status === 0;
    }

    public getBCSErrorMessage(): string {
        return this.bcsErrorMessage;
    }

    public hasBCSErrorMessage(): boolean {
        return typeof this.bcsErrorMessage !== "undefined";
    }

    public getStatusCode(): number {
        return this.status;
    }

    public getOriginalErrorInfo(): object {
        return {
            errorcode: this.errorcode,
            error: this.error,
            response: this.response,
        };
    }
}
