import { UserSession } from "../../common/auth/UserSession";
import { BCSDate } from "../../common/BCSDate";
import { I18n } from "../../common/i18n/I18n";
import { AppConsole } from "../../common/log/AppConsole";
import { Log } from "../../common/log/Log";
import { ApplicationProperties } from "../../common/properties/ApplicationProperties";
import { DomainSyncState } from "../../common/properties/DomainSyncState";
import { Schema } from "../../common/schema/Schema";
import { Component } from "../../core/Component";
import { Registry } from "../../core/Registry";
import { SingletonComponent } from "../../core/SingletonComponent";
import { IndexedDB } from "../../database/IndexedDB";
import { OidValue } from "../../entities/values/OidValue";
import { TimeValue } from "../../entities/values/TimeValue";
import { SyncState, SyncStateObjectType, SyncStateType } from "../../sync/SyncState";
import { SyncStateManager } from "../../sync/SyncStateManager";
import { AttendanceClock } from "./attendance/AttendanceClock";
import { AttendanceClockPool } from "./attendance/AttendanceClockPool";
import { Booking } from "./bookings/Booking";
import { BookingsPool } from "./bookings/BookingsPool";
import { TimeAttibutesDefinitions } from "./TimeAttibutesDefinitions";
import { TimeRecord } from "./TimeRecord";
import { TimeChanged, TimeRuleOfThree } from "./TimeRuleOfThree";
import { Appointment } from "./timesheet/Appointment";
import { Requirement } from "./timesheet/Requirement";
import { Task } from "./timesheet/Task";
import { Ticket } from "./timesheet/Ticket";
import { TimesheetPool } from "./timesheet/TimesheetPool";
import { Workflow } from "./timesheet/Workflow";
import { TimeSpan } from "./timespans/TimeSpan";
import { TimeSpanPool } from "./timespans/TimeSpanPool";
import { TimeRecordingOptions } from "./TimeRecordingOptions";
import { BookingsClient } from "./bookings/BookingsClient";
import { ProgressFeedback } from "../../util/progress/ProgressFeedback";

export class TimeRecordingManager implements Component, SingletonComponent {
    /** Symbolischer Name dieser Komponente */
    public static BCS_COMPONENT_NAME = "TimeRecordingManager";

    private static BOOKING_SYNC_STATE_PROPERTY_KEY = "BookingSyncState";

    private static TIMESPAN_SYNC_STATE_PROPERTY_KEY = "TimespanSyncState";

    private static TIMESHEET_CHECKSUM_PROPERTY_KEY = "TimesheetChecksum";

    private static TIMESHEET_TASKS_SYNC_STATE_PROPERTY_KEY = "TimesheetTasksSyncState";

    private static TIMESHEET_APPOINTMENT_SYNC_STATE_PROPERTY_KEY = "TimesheetAppointmentSyncState";

    private static readonly TASK_SUBTYPE: string = "task";

    private static readonly TICKETS_EXPENSE_PLANNING: string = "withTickets";

    private applicationProperties: ApplicationProperties;

    private i18n: I18n;

    private schema: Schema;

    private userSession: UserSession;

    private currentUserOid: string;

    private timesheetPool: TimesheetPool;

    private bookingsPool: BookingsPool;

    private timespanPool: TimeSpanPool;

    private attendancePool: AttendanceClockPool;

    private syncStateTimeRecordPool: SyncStateManager;

    private bookingSyncState: DomainSyncState;
    private timespanSyncState: DomainSyncState;
    private timesheetAppointmentsSyncState: DomainSyncState;
    private timesheetTasksSyncState: DomainSyncState;

    private timesheetSyncState: DomainSyncState;
    private recordingOptions: TimeRecordingOptions;

    constructor() {}

    /**
     * Liefert die TimerecordingOptions zurück, die Informationen über aktivierte Features und Lizenzen enthalten
     */
    public getRecordingOptions(): TimeRecordingOptions {
        return this.recordingOptions;
    }

    public getDependencyNames(): string[] {
        return [
            I18n.BCS_COMPONENT_NAME,
            Schema.BCS_COMPONENT_NAME,
            IndexedDB.BCS_COMPONENT_NAME,
            UserSession.BCS_COMPONENT_NAME,
            SyncStateManager.BCS_COMPONENT_NAME,
            ApplicationProperties.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }): void {
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.schema = <Schema>depencencyComponents[Schema.BCS_COMPONENT_NAME];

        this.userSession = <UserSession>depencencyComponents[UserSession.BCS_COMPONENT_NAME];

        this.syncStateTimeRecordPool = <SyncStateManager>(
            depencencyComponents[SyncStateManager.BCS_COMPONENT_NAME]
        );

        const indexedDB = <IndexedDB>depencencyComponents[IndexedDB.BCS_COMPONENT_NAME];

        this.timesheetPool = new TimesheetPool(
            indexedDB,
            this.schema,
            this.userSession,
            this.syncStateTimeRecordPool,
        );

        this.timespanPool = new TimeSpanPool(indexedDB, null, this.schema);

        this.attendancePool = new AttendanceClockPool(this.schema);
        this.applicationProperties = <ApplicationProperties>(
            depencencyComponents[ApplicationProperties.BCS_COMPONENT_NAME]
        );

        //Meldet ein Pseudo-Attribut zur Verwendung in der App an (ob die Buchung bereits begonnen wurde und daher nicht gelöscht werden darf)
        this.schema.registerPseudoAttribute(
            Booking.TYPE,
            Booking.SUBTYPE,
            Booking.STARTED_BOOKING,
            { datatype: "Bool" },
        );
        this.schema.registerPseudoAttribute(
            TimeSpan.TYPE,
            TimeSpan.ATTENDANCE,
            Booking.STARTED_BOOKING,
            { datatype: "Bool" },
        );
        this.bookingsPool = new BookingsPool(indexedDB, this.applicationProperties, this.schema);
    }

    public start(): Promise<void> {
        return Promise.resolve();
    }

    /**
     * Aufruf, wenn nach Start der App der Benutzer eingeloggt und online ist.
     *
     * @param isOnline
     */
    public notifyBeginUserSession(
        isOnline: boolean,
        progressFeedback?: ProgressFeedback,
    ): Promise<void> {
        // TODO App Lesen aus DB und Synchronisaton mit BCS analog AllowanceManager
        this.currentUserOid = this.userSession.getCurrentUserOid();

        const self = this;
        //return self.synchronize()
        //    .then(() => { return isOnline ? self.synchronize() : Promise.resolve(); })

        // Um nur die Aufgaben auszulesen, die auch aktuell sind (beim letzten Synch vom Server bestätigt wurden),
        // brauchen wir den SyncState auch im Offline-Fall (im Online-Fall wird das vom doSynchronize() mit erledigt)
        // #153519 Offline Betrieb der App funktioniert nicht reibungslos
        return isOnline ? this.doSynchronize(progressFeedback) : this.readSyncStateFromDatabase();
    }

    /**
     * Aufruf wenn der Benutzer in der App manuell die Synchronisationsansicht öffnet
     */
    public synchronize(progressFeedback: ProgressFeedback): Promise<void> {
        if (this.userSession.isOnline()) {
            return this.doSynchronize(progressFeedback);
        } else {
            return Promise.resolve();
        }
    }

    private async doSynchronize(progressFeedback: ProgressFeedback): Promise<void> {
        const progressParts = progressFeedback.getPartProgressFeedbacks(1);
        await this.readTimeRecordingOptionsFromBCS();
        await this.tryToSynUnSyncedBookings();
        await this.readSyncStateFromDatabase();
        await this.updateBookingsIfOnline();
        await this.updateTimspansIfOnline();
        await this.updateTimesheetIfOnline(progressParts[0]);
        await this.attendancePool.synchronizeAttendancesWithBCS();
    }

    private async tryToSynUnSyncedBookings(): Promise<void> {
        let syncStatesBookings: SyncState[] = [];
        let syncStatesAttendances: SyncState[] = [];
        let syncStatesPause: SyncState[] = [];
        syncStatesBookings = syncStatesBookings.concat(
            await this.syncStateTimeRecordPool.readSyncAllStatesToBeSentToBCS(
                SyncStateObjectType.Booking,
            ),
        );
        syncStatesAttendances = syncStatesAttendances.concat(
            await this.syncStateTimeRecordPool.readSyncAllStatesToBeSentToBCS(
                SyncStateObjectType.Attendance,
            ),
        );
        syncStatesPause = syncStatesPause.concat(
            await this.syncStateTimeRecordPool.readSyncAllStatesToBeSentToBCS(
                SyncStateObjectType.Pause,
            ),
        );

        AppConsole.debug("[TimeRecordingManager] tryToSynUnSyncedBookings", syncStatesBookings);
        syncStatesBookings.forEach((syncState: SyncState) => {
            this.getBooking(syncState.getId(), false)
                .then((booking: TimeRecord) => {
                    const isValidAppSide = this.maintainTimeConstraints(booking);
                    if (isValidAppSide) {
                        this.storeTimeRecordOnServer(booking, this.getMinimalTouchedBookingFields())
                            .then((bcsResultTimeRecord) => {
                                Log.debug(
                                    "[TimeRecordingManager] - tryToSynUnSyncedBookings stored booking on server",
                                    booking,
                                );
                                this.storeTimeRecordLocal(
                                    bcsResultTimeRecord,
                                    bcsResultTimeRecord.getSyncState(),
                                ).then((appResultTimeRecord) => {
                                    Log.debug(
                                        "[TimeRecordingManager] - tryToSynUnSyncedBookings stored resulting booking in App DB",
                                        appResultTimeRecord,
                                    );
                                });
                            })
                            .catch((error) => {
                                Log.error(
                                    "[TimeRecordingManager] Error while store timerecord on Server:" +
                                        JSON.stringify(syncState.getId()),
                                    booking,
                                    error,
                                );
                            });
                    }
                })
                .catch((error) => {
                    Log.error(
                        "[TimeRecordingManager] Error while get booking from App DB:" +
                            JSON.stringify(syncState.getId()),
                        syncState,
                        error,
                    );
                });
        });

        const promises = [];
        syncStatesAttendances.forEach((syncState: SyncState) => {
            promises.push(
                new Promise((resolve, reject) => {
                    this.getTimeSpan(syncState.getId())
                        .then((attendance: TimeRecord) => {
                            const isValidAppSide = this.maintainTimeConstraints(attendance);
                            if (isValidAppSide) {
                                this.storeTimeRecordOnServer(
                                    attendance,
                                    this.getMinimalTouchedBookingFields(),
                                )
                                    .then(() => {
                                        Log.debug(
                                            "[TimeRecordingManager] - tryToSynUnSyncedBookings stored attendance",
                                            attendance,
                                        );
                                        resolve(null);
                                    })
                                    .catch((error) => {
                                        Log.error(
                                            "[TimeRecordingManager] Error while store timerecord (attandance) on Server:" +
                                                JSON.stringify(attendance),
                                        );
                                        resolve(null);
                                    });
                            } else {
                                // TODO: Jens, evtl eine Nachricht rausschreiben?
                                resolve(null);
                            }
                        })
                        .catch((error) => {
                            Log.error(
                                "[TimeRecordingManager] Error while get timespan(attandance) vom mobile db:" +
                                    JSON.stringify(syncState.getId()),
                                syncState,
                                error,
                            );
                            resolve(null);
                        });
                }),
            );
        });

        // nachdem alle Anwesenheiten synchronisiert sind, synchronisieren wir die Pausen. Da eine Pause immer in einer Anwesenheit liegen sollte.
        const finish: Promise<void> = Promise.all(promises).then((values) => {
            syncStatesPause.forEach((syncState: SyncState) => {
                AppConsole.log("storePause");
                this.getTimeSpan(syncState.getId())
                    .then((pause: TimeRecord) => {
                        const isValidAppSide = this.maintainTimeConstraints(pause);
                        if (isValidAppSide) {
                            this.storeTimeRecordOnServer(
                                pause,
                                this.getMinimalTouchedBookingFields(),
                            )
                                .then(() => {
                                    Log.debug(
                                        "[TimeRecordingManager] - tryToSynUnSyncedBookings stored pause",
                                        pause,
                                    );
                                })
                                .catch((error) => {
                                    Log.error(
                                        "[TimeRecordingManager] Error while store timespan(pause) on Server:" +
                                            JSON.stringify(pause),
                                    );
                                });
                        }
                    })
                    .catch((error) => {
                        Log.error(
                            "[TimeRecordingManager] Error while get timespan(pause) vom mobile db:" +
                                JSON.stringify(syncState.getId()),
                            syncState,
                            error,
                        );
                    });
            });
        });

        return finish;
    }
    public getMinimalTouchedBookingFields(): string[] {
        const touchedFields: string[] = [];

        touchedFields.push("oid");
        touchedFields.push("typ");
        touchedFields.push("subtyp");
        touchedFields.push("effortUserOid");
        touchedFields.push("effortDate");
        touchedFields.push("effortStart");
        touchedFields.push("effortEnd");
        touchedFields.push("effortExpense");

        touchedFields.push("effortTargetOid");
        touchedFields.push("effortAnnotationOid");
        touchedFields.push("effortRequirementOid");
        touchedFields.push("effortWorkflowOid");

        touchedFields.push("effortBillability");
        touchedFields.push("effortActivity");

        return touchedFields;
    }

    /**
     * Gibt alle Aufgaben der Aufgabenliste zurück, es sei denn, der optionale Parameter ist true, dann werden nur die Aufgaben zurückgegeben, für die eine Restaufwandsschätzung möglich ist (also wenn die Aufgabe vom Subtypen "task" ist und das Aufwandsplanungsmodell nicht "Über Tickets" ist).
     * @see isForecastRecordingAllowed
     * @param forForecastRecording - Optional, bestimmt den Umfang der Liste.
     */
    public async getTimesheetTasks(
        postFilterTasklist: boolean,
        forForecastRecording: boolean = false,
    ): Promise<Task[]> {
        let tasklist: Task[] = await this.timesheetPool.readAllTimesheetTasksFromDB(
            this.currentUserOid,
            this.timesheetSyncState.getSyncStateTimestamp(),
        );
        if (forForecastRecording) {
            tasklist = tasklist.filter((task: Task) => this.isForecastRecordingAllowed(task));
        }
        if (postFilterTasklist) {
            // Aufgaben werden wegen OID Index unsortiert in die DB reingeschrieben  :/
            // Also sortieren wir die halt nochmal nach, konfigurativ ausschaltbar, weil Angst um Performance
            return this.sortTaskTimesheets(tasklist);
        } else {
            return tasklist;
        }
    }

    /**
     * Sortiert die Aufgabenliste vor der Anzeige nach Projekt und Aufgabenname (wie in der Voreinstellung der Aufgabenliste im internen BCS)
     * @param tasks die unsortierte Aufgabenliste
     * @returns Liste, aber sortiert...
     */
    private sortTaskTimesheets(tasks: Task[]): Task[] {
        return tasks.sort((a: Task, b: Task) => {
            // sortiert zuerst nach dem Projektnamen
            if (a.getGrandParentName() < b.getGrandParentName()) return -1;
            if (a.getGrandParentName() > b.getGrandParentName()) return 1;

            // und dann nach dem Namen der Aufgabe
            if (a.getName() < b.getName()) return -1;
            if (a.getName() > b.getName()) return 1;

            return 0;
        });
    }

    /**
     * Prüfe, ob man an der aktuellen Aufgabe einen Restaufwand erfassen kann.
     * Dies ist der Fall, wenn die Aufgabe vom Subtypen "task" ist und das Aufwandsplanungsmodell nicht "Über Tickets" ist.
     * @param task - Die Aufgabe, für die die Prüfung erfolgt.
     */
    public isForecastRecordingAllowed(task: Task): boolean {
        return (
            TimeRecordingManager.TASK_SUBTYPE === task.getSubtype() &&
            !(TimeRecordingManager.TICKETS_EXPENSE_PLANNING === task.getExpensePlanningType())
        );
    }
    public getTimesheetTask(taskOid: string): Promise<Task> {
        return this.timesheetPool.readTimesheetTask(this.currentUserOid, taskOid);
    }

    public updateRemainingEffortOnTimesheetTask(
        taskOid: string,
        newValueInMin: number,
    ): Promise<Task> {
        return this.timesheetPool.updateRemainingEffortOnTimesheetTask(
            this.currentUserOid,
            taskOid,
            newValueInMin,
        );
    }

    updateRemainingEffortWithDiff(effortTargetId: string, diffinMin: number) {
        return this.timesheetPool.updateRemainingEffortWithDiff(
            this.currentUserOid,
            effortTargetId,
            diffinMin,
        );
    }

    public getTimesheetEvent(taskOid: string): Promise<Appointment> {
        return this.timesheetPool.readTimesheetAppointment(this.currentUserOid, taskOid);
    }

    public getTimesheetTickets(): Promise<Ticket[]> {
        return this.timesheetPool.readAllTimesheetTicketsFromDB(this.currentUserOid);
    }

    public getTimesheetTicket(ticketOid: string): Promise<Ticket> {
        return this.timesheetPool.readTimesheetTicket(this.currentUserOid, ticketOid);
    }

    public getTimesheetRequirements(): Promise<Requirement[]> {
        return this.timesheetPool.readAllTimesheetRequirementsFromDB(this.currentUserOid);
    }

    public getTimesheetRequirement(requirementOid: string): Promise<Requirement> {
        return this.timesheetPool.readTimesheetRequirement(this.currentUserOid, requirementOid);
    }

    public getTimesheetWorkflows(): Promise<Workflow[]> {
        return this.timesheetPool.readAllTimesheetWorkflowsFromDB(this.currentUserOid);
    }

    public getTimesheetWorkflow(workflowOid: string): Promise<Workflow> {
        return this.timesheetPool.readTimesheetWorkflow(this.currentUserOid, workflowOid);
    }

    public getBookingsFromToday(): Promise<Booking[]> {
        return this.getBookingsFromDate(BCSDate.getToday());
    }

    public getBookingsFromDate(date: BCSDate): Promise<Booking[]> {
        /** Wenn man den Tag "2018-03-03" haben möchte, muss man von "2018-03-03" bis "2018-03-04" filtern, erhält aber nur die Tage am 3ten */
        return this.updateBookingsIfOnline().then(() => {
            return this.bookingsPool.readAllBookingsFromDB(
                this.currentUserOid,
                date.getClone().getISODate(),
                date.getClone().addDays(1).getISODate(),
            );
        });
    }

    /**
     * Verwendung: Wenn man alle Buchungen vom 3ten bis einschließtlich alle Termine des 4ten haben möchte, gibt man z.B. an:  "2018-03-03" bis "2018-03-04" an.
     *             Die Datenbank würde hier anders rechnen, daher wurde hier eine hoffentliche Vereinfachung getroffen.
     * @param startDate
     * @param endDate
     */
    public getBookingsBetweeenDates(startDate: BCSDate, endDate: BCSDate): Promise<Booking[]> {
        /** Wenn man den Tag "2018-03-03" haben möchte, muss man von "2018-03-03" bis "2018-03-04" filtern, erhält aber nur die Tage am 3ten */
        return this.updateBookingsIfOnline().then(() => {
            return this.bookingsPool.readAllBookingsFromDB(
                this.currentUserOid,
                startDate.getClone().getISODate(),
                endDate.getClone().addDays(1).getISODate(),
            );
        });
    }

    /**
     * Gibt zu einer Oid die Entity/ das Buchungs-Objekt zurück.
     *
     * Wird verwendet um auf der Detailseite, die Buchung anpassen zu können.
     *
     * @param oid
     */
    public getBooking(oid: string, refreshBookingOnline: boolean = true): Promise<Booking> {
        if (refreshBookingOnline) {
            return this.updateBookingsIfOnline()
                .then(() => {
                    return this.bookingsPool.readBookingFromDB(this.currentUserOid, oid);
                })
                .catch(() => {
                    // Es kann vorkommen, dass wir hier rein kommen und noch denken, dass wir online wären, aber tatsächlich offline sind
                    // (also refreshBookingOnline === true, weil man eigentlich online fragen möchte UND userSession.isOnline() wurde noch nicht aktualisiert)
                    // z.B. wenn wir die Verbindung verlieren während wir eine Buchung geöffnet haben und dann eine Aufgabe auswählen wollen
                    // Wir gehen dann offline und nehmen stattdessen die Buchung aus der DB
                    this.userSession.setToOfflineMode();
                    return this.bookingsPool.readBookingFromDB(this.currentUserOid, oid);
                });
        } else {
            return this.bookingsPool.readBookingFromDB(this.currentUserOid, oid);
        }
    }

    public async updateTimspansIfOnline(): Promise<void> {
        if (this.userSession.isOnline()) {
            const checksum = await this.timespanPool.synchronizeTimespansWithBCS(
                this.currentUserOid,
                this.timespanSyncState.getSyncStateTimestamp(),
            );

            if (checksum != -1) {
                await this.timespanSyncState.setSyncStateTimestamp(checksum);
            }
        } else {
            return Promise.resolve();
        }
    }

    public async updateTimesheetIfOnline(progressFeedback: ProgressFeedback): Promise<void> {
        if (this.userSession.isOnline()) {
            const checksum = await this.timesheetPool.synchronizeTimesheetWithBCS(
                this.currentUserOid,
                this.timesheetSyncState.getSyncStateTimestamp(),
                progressFeedback,
            );

            if (checksum != -1) {
                await this.timesheetSyncState.setSyncStateTimestamp(checksum);
            }
        } else {
            return Promise.resolve();
        }
    }

    public async updateBookingsIfOnline(): Promise<void> {
        if (this.userSession.isOnline()) {
            this.bookingsPool
                .synchronizeBookingsWithBCS(
                    this.currentUserOid,
                    this.bookingSyncState.getSyncStateTimestamp(),
                )
                .then(async (bookingResult) => {
                    if (bookingResult.syncBCSStateTimestamp != -1) {
                        const bcsBookings = bookingResult.bookingsJSONs;
                        const dbSavedBookings = await this.bookingsPool.readAllBookingsFromDB(
                            this.currentUserOid,
                        );
                        const bookingIdsInDbButInBCS = [];
                        for (let i = 0; i < bcsBookings.length; i++) {
                            const bookingsJSON = bcsBookings[i];
                            const id = bookingsJSON["oid"];
                            const dbSavedBookingsWithID = dbSavedBookings.filter(
                                (dbSavedBooking) => dbSavedBooking.getId() === id,
                            );
                            if (dbSavedBookingsWithID.length === 1) {
                                bookingIdsInDbButInBCS.push(dbSavedBookingsWithID[0]);
                            }
                            const booking =
                                this.bookingsPool.wrapBookingIntoDomainObject(bookingsJSON);
                            this.bookingsPool.writeBookingToDB(booking);
                        }

                        // Buchungen die in der Datenbank sind, aber nicht in BCS, wurden warhscheinlich in BCS gelöscht.
                        const difference = dbSavedBookings.filter(
                            (x) => !bookingIdsInDbButInBCS.includes(x),
                        );
                        for (const bookingInDbNotInBCS of difference) {
                            const syncState = await this.syncStateTimeRecordPool.getSyncStateById(
                                bookingInDbNotInBCS.getId(),
                            );
                            if (syncState === null || syncState.isNotChangedInApp()) {
                                this.bookingsPool.deleteBookingFromDB([
                                    bookingInDbNotInBCS.getId(),
                                ]);
                            }
                        }
                        const differenceIds = difference.map((booking) => booking.getId());

                        return this.bookingSyncState.setSyncStateTimestamp(
                            bookingResult.syncBCSStateTimestamp,
                        );
                    }
                    return Promise.resolve();
                })
                .catch(() => {
                    // Es kann vorkommen, dass wir hier rein kommen und noch denken, dass wir online wären, aber tatsächlich offline sind
                    // (also refreshBookingOnline === true, weil man eigentlich online fragen möchte UND userSession.isOnline() wurde noch nicht aktualisiert)
                    // z.B. wenn wir die Verbindung verlieren während wir eine Buchung geöffnet haben und dann eine Aufgabe auswählen wollen
                    // Wir gehen dann offline und nehmen stattdessen die Buchung aus der DB
                    this.userSession.setToOfflineMode();
                    return Promise.resolve();
                });
        } else {
            return Promise.resolve();
        }
    }

    public getTimeSpan(oid: string): Promise<TimeSpan> {
        /** Wenn man den Tag "2018-03-03" haben möchte, muss man von "2018-03-03" bis "2018-03-04" filtern, erhält aber nur die Tage am 3ten */
        return this.timespanPool.readTimeSpansFromDB(this.currentUserOid, oid);
    }

    public getTimSpansFromDate(givenDate: BCSDate): Promise<TimeSpan[]> {
        const date = givenDate.getClone();
        /** Wenn man den Tag "2018-03-03" haben möchte, muss man von "2018-03-03" bis "2018-03-04" filtern, erhält aber nur die Tage am 3ten */
        return this.timespanPool.readAllTimeSpansFromDB(
            this.currentUserOid,
            date.getISODate(),
            date.addDays(1).getISODate(),
        );
    }

    /**
     * Verwendung: Wenn man alle Buchungen vom 3ten bis einschließtlich alle Termine des 4ten haben möchte, gibt man z.B. an:  "2018-03-03" bis "2018-03-04" an.
     *             Die Datenbank würde hier anders rechnen, daher wurde hier eine hoffentliche Vereinfachung getroffen.
     * @param startDate
     * @param endDate
     */
    public getTimeSpansBetweeenDates(startDate: BCSDate, endDate: BCSDate): Promise<TimeSpan[]> {
        /** Wenn man den Tag "2018-03-03" haben möchte, muss man von "2018-03-03" bis "2018-03-04" filtern, erhält aber nur die Tage am 3ten */
        return this.updateTimspansIfOnline().then(() => {
            return this.timespanPool.readAllTimeSpansFromDB(
                this.currentUserOid,
                startDate.getClone().getISODate(),
                endDate.getClone().addDays(1).getISODate(),
            );
        });
    }

    public getTimesheetEventFromDate(givenDate: BCSDate): Promise<Appointment[]> {
        const date = givenDate.getClone();
        /** Wenn man den Tag "2018-03-03" haben möchte, muss man beide Male den "2018-03-03" einreichen, es wird ein "GreaterOrEqualAndLesserOrEqual"-Filter angewandt. */
        return this.timesheetPool.readAllTimesheetEventFromDB(
            this.currentUserOid,
            date.getISODate(),
            date.getISODate(),
        );
    }

    public getAttendanceClock(): AttendanceClock {
        return this.attendancePool.getAttendanceClock();
    }

    public clickedAttendanceClock(attendanceClock: AttendanceClock): Promise<object> {
        return this.attendancePool.clickedAttendanceClock(attendanceClock);
    }

    public storeTimeRecordLocal(timeRecord: TimeRecord, syncSate: SyncState): Promise<TimeRecord> {
        if (timeRecord.isBooking()) {
            const booking = <Booking>timeRecord;

            return new Promise((resolve, reject) => {
                this.bookingsPool
                    .writeBookingToDB(booking)
                    .then(() => {
                        this.syncStateTimeRecordPool.writeSyncStateToDB(syncSate);
                        resolve(booking);
                    })
                    .catch(reject);
            });
        } else {
            const timespan = <TimeSpan>timeRecord;
            timespan.addEndTimeToTimespan();
            return new Promise((resolve, reject) => {
                this.timespanPool
                    .writeTimeSpanToDB(timespan)
                    .then(() => {
                        this.syncStateTimeRecordPool.writeSyncStateToDB(syncSate);
                        resolve(timespan);
                    })
                    .catch(reject);
            });
        }
    }

    public storeTimeRecordOnServer(
        timeRecord: TimeRecord,
        touchedFields: string[],
    ): Promise<TimeRecord> {
        // Diese Oid kann provisorisch sein:
        const oldTimeRecordID = timeRecord.getId();
        /* var stack = new Error().stack;
        AppConsole.log("PRINTING CALL STACK");
        AppConsole.log( stack ); */
        AppConsole.debug("storeTimeRecordOnServer " + oldTimeRecordID);
        if (this.userSession.isOnline()) {
            if (timeRecord.isBooking()) {
                const booking = <Booking>timeRecord;

                return new Promise((resolve, reject) => {
                    this.bookingsPool
                        .writeBookingToBCSServer(booking, touchedFields)
                        .then((newBooking) => {
                            if (oldTimeRecordID != newBooking.getId()) {
                                this.bookingsPool
                                    .deleteBookingFromDB([oldTimeRecordID])
                                    .then(() => {
                                        // löschen den alten SyncState, da wir einen neuen anlegen.
                                        this.syncStateTimeRecordPool
                                            .deleteSyncState(oldTimeRecordID)
                                            .then((syncState) => {
                                                // legen den neuen SyncState an und setzen ihn an der Buchung
                                                this.syncStateTimeRecordPool
                                                    .getOrCreateSyncStateById(
                                                        newBooking.getId(),
                                                        SyncStateObjectType.Booking,
                                                    )
                                                    .then((syncState) => {
                                                        syncState.setSyncStateType(
                                                            SyncStateType.NoChangesInApp,
                                                        );
                                                        newBooking.attachSyncState(syncState);
                                                        resolve(newBooking);
                                                    });
                                            });
                                    });
                            } else {
                                this.syncStateTimeRecordPool
                                    .readTimeRecordingSynStateInDB(oldTimeRecordID)
                                    .then((syncState) => {
                                        resolve(newBooking);
                                    });
                            }
                        })
                        .catch((errorInformations) => {
                            AppConsole.debug(
                                "[ERROR - TimeRecordingManager.storeTimeRecordOnServer()] ",
                                errorInformations,
                            );
                            // lesen den alten SyncSate um Ihn umzuschreiben
                            this.syncStateTimeRecordPool
                                .readTimeRecordingSynStateInDB(oldTimeRecordID)
                                .then((syncState) => {
                                    let errorMessage: string = "Unknown Error";
                                    if (errorInformations.hasOwnProperty("bcsErrorMessage")) {
                                        errorMessage = errorInformations.bcsErrorMessage;
                                    }
                                    syncState = syncState.markSyncError(
                                        "bookingError",
                                        errorMessage,
                                    );
                                    // schreiben den alten SyncState um, so das der Fehler gemerkt wird
                                    this.syncStateTimeRecordPool
                                        .writeSyncStateToDB(syncState)
                                        .then(() => {
                                            reject(errorInformations);
                                        });
                                });
                        });
                });
            } else {
                const timespan = <TimeSpan>timeRecord;
                timespan.addEndTimeToTimespan();
                return new Promise((resolve, reject) => {
                    this.timespanPool
                        .writeTimeSpanToBCSServer(timespan)
                        .then((newTimespan) => {
                            if (oldTimeRecordID != newTimespan.getId()) {
                                this.timespanPool
                                    .delteTimeSpanFromDB([oldTimeRecordID])
                                    .then(() => {
                                        // löschen den alten SyncState, da wir einen neuen anlegen.
                                        this.syncStateTimeRecordPool
                                            .deleteSyncState(oldTimeRecordID)
                                            .then((syncState) => {
                                                resolve(newTimespan);
                                            });
                                    });
                            } else {
                                this.syncStateTimeRecordPool
                                    .readTimeRecordingSynStateInDB(oldTimeRecordID)
                                    .then((syncState) => {
                                        resolve(newTimespan);
                                    });
                            }
                        })
                        .catch((errorInformations) => {
                            // lesen den alten SyncSate um Ihn umzuschreiben
                            this.syncStateTimeRecordPool
                                .readTimeRecordingSynStateInDB(oldTimeRecordID)
                                .then((syncState) => {
                                    let errorMessage: string = "Unknown Error";
                                    if (errorInformations.hasOwnProperty("bcsErrorMessage")) {
                                        errorMessage = errorInformations.bcsErrorMessage;
                                    }
                                    syncState = syncState.markSyncError(
                                        "bookingError",
                                        errorMessage,
                                    );
                                    // schreiben den alten SyncState um, so das der Fehler gemerkt wird
                                    this.syncStateTimeRecordPool
                                        .writeSyncStateToDB(syncState)
                                        .then(() => {
                                            reject(errorInformations);
                                        });
                                });
                        });
                });
            }
        } else {
            return new Promise((resolve) => {
                resolve(null);
            });
        }
    }

    public getSyncStateForBooking(oid: string): Promise<SyncState> {
        return this.syncStateTimeRecordPool.readTimeRecordingSynStateInDB(oid);
    }

    public storeTimeSpan(timeRecord: TimeSpan): Promise<TimeSpan> {
        if (!timeRecord.isBooking()) {
            const timespan: TimeSpan = <TimeSpan>timeRecord;

            timespan.addEndTimeToTimespan();

            return new Promise((resolve, reject) => {
                this.timespanPool
                    .writeTimeSpanToDB(timespan)
                    .then(() => resolve(timespan))
                    .catch(reject);
            });
        }
    }

    public createBooking(date: BCSDate, newBookingType: string): Promise<Booking> {
        return new Promise((resolve, reject) => {
            const timeRecord = Booking.create(
                this.schema,
                this.userSession.getCurrentUserOid(),
                newBookingType,
            );

            // setzten das Datum:
            timeRecord.setDate(date);

            const syncState: SyncState = SyncState.fromTimeRecord(
                this.userSession.getCurrentUserOid(),
                timeRecord,
                SyncStateType.SynchronisationIssue,
            );
            syncState.markSyncError(
                "notAllRequiredAttributesFilledOut",
                this.i18n.get("MobileApp.txtError.notAllRequiredAttributesFilledOut"),
            );

            return this.storeTimeRecordLocal(timeRecord, syncState)
                .then((booking) => {
                    resolve(<Booking>booking);
                })
                .catch(reject);
        });
    }

    public createAppointmentBooking(date: BCSDate, appointment: Appointment): Promise<Booking> {
        return new Promise((resolve, reject) => {
            const timeRecord = Booking.create(
                this.schema,
                this.userSession.getCurrentUserOid(),
                appointment.getTargetSubtype(),
            );
            const appointmentOid: string = appointment.getId();
            const effortTargetOid: string = appointment.getTaskId();
            // setzten das Datum:
            timeRecord.setDate(date);
            if (!appointment.isFulltimmEvent()) {
                timeRecord.setValue(
                    "effortStart",
                    TimeValue.fromMinutes(appointment.getStartTime()),
                );
                timeRecord.setValue("effortEnd", TimeValue.fromMinutes(appointment.getEndTime()));
            }

            timeRecord.setValue(
                "effortEventRefOid",
                OidValue.fromOidAndName(appointmentOid, appointment.getName()),
            );
            timeRecord.setValue(
                "effortTargetOid",
                OidValue.fromOidAndName(effortTargetOid, appointment.getTaskName()),
            );
            timeRecord.setEffortExpense(appointment.getDuration());

            // Termin hat eigentlisch schon alle Daten umgespeichert zu werden:
            const syncState: SyncState = SyncState.fromTimeRecord(
                this.userSession.getCurrentUserOid(),
                timeRecord,
                SyncStateType.ChangesInApp,
            );
            return this.storeTimeRecordLocal(timeRecord, syncState)
                .then((booking) => {
                    resolve(<Booking>booking);
                })
                .catch(reject);
        });
    }

    public deleteTimeRecord(timeRecord): Promise<void> {
        if (this.userSession.isOnline()) {
            // TODO falls nicht synchronisiert, dann nur in der DB löschen.
            return this.deleteTimeRecordFromServer(timeRecord).then(() => {
                this.deleteTimeRecordFromDB(timeRecord);
            });
        } else {
            return this.deleteTimeRecordFromDB(timeRecord);
        }
    }

    /**
     * Erstellt eine Buchung mit Start und Endzeit.
     *
     * Dies wird zum Beispiel verwendet, wenn wir eine vorgeschlagene DummyBuchung anlegen möchten.
     * Bei dieser Wissen wir schon die Start- und Endzeit un das Datum.
     *
     * @param start
     * @param end
     * @param date
     */
    public createBookingWithTime(
        start: BCSDate,
        end: BCSDate,
        date: BCSDate,
        newBookingType: string,
    ): Promise<Booking> {
        return new Promise((resolve, reject) => {
            const timeRecord = Booking.create(
                this.schema,
                this.userSession.getCurrentUserOid(),
                newBookingType,
            );
            // setzten das Datum:
            timeRecord.setDate(date);

            // setzten dis Start- und Endzeit:
            const timeAttributes: TimeAttibutesDefinitions =
                timeRecord.getTimeAttibutesDefinitions();
            timeRecord.setValue(timeAttributes.getStart(), new TimeValue(start.getTimeAsString()));
            timeRecord.setValue(timeAttributes.getEnd(), new TimeValue(end.getTimeAsString()));

            // setzten die Dauer, abhängig von Start und Endzeit:
            const startMinutes = start.getMinutesOfDay();
            const endMinutes = end.getMinutesOfDay();
            TimeRuleOfThree.maintainRuleOfThree(
                startMinutes,
                endMinutes,
                null,
                TimeChanged.START,
                timeRecord,
            );

            const syncState: SyncState = SyncState.fromTimeRecord(
                this.userSession.getCurrentUserOid(),
                timeRecord,
                SyncStateType.ChangesInApp,
            );
            // speichern die Buchung erstmal local, um sie z.B. in den Buchungsdetails anzeigen zu können.
            return this.storeTimeRecordLocal(timeRecord, syncState)
                .then((booking) => {
                    resolve(<Booking>booking);
                })
                .catch(reject);
        });
    }

    public createBookingWithDuration(
        duration: number,
        date: BCSDate,
        newBookingType: string,
    ): Promise<Booking> {
        return new Promise((resolve, reject) => {
            const timeRecord = Booking.create(
                this.schema,
                this.userSession.getCurrentUserOid(),
                newBookingType,
            );

            // setzten das Datum:
            timeRecord.setDate(date);
            timeRecord.setEffortExpense(duration);

            const syncState: SyncState = SyncState.fromTimeRecord(
                this.userSession.getCurrentUserOid(),
                timeRecord,
                SyncStateType.ChangesInApp,
            );
            return this.storeTimeRecordLocal(timeRecord, syncState)
                .then((booking) => {
                    resolve(<Booking>booking);
                })
                .catch(reject);
        });
    }

    public createTimeSpan(subtyp: string): Promise<TimeSpan> {
        return new Promise((resolve, reject) => {
            const timespan = TimeSpan.create(
                this.schema,
                this.userSession.getCurrentUserOid(),
                subtyp,
            );
            return this.storeTimeSpan(timespan)
                .then((timespan) => {
                    this.syncStateTimeRecordPool.writeSyncStateToDB(
                        SyncState.fromTimeRecord(
                            this.currentUserOid,
                            timespan,
                            SyncStateType.ChangesInApp,
                        ),
                    );
                    resolve(<TimeSpan>timespan);
                })
                .catch(reject);
        });
    }

    public createTimeSpanWithDuration(
        subtyp: string,
        duration: number,
        date: BCSDate,
    ): Promise<TimeSpan> {
        return new Promise((resolve, reject) => {
            const timespan = TimeSpan.create(
                this.schema,
                this.userSession.getCurrentUserOid(),
                subtyp,
            );

            timespan.setDate(date);
            timespan.setDuration(duration);

            return this.storeTimeSpan(timespan)
                .then((timespan) => {
                    this.syncStateTimeRecordPool.writeSyncStateToDB(
                        SyncState.fromTimeRecord(
                            this.currentUserOid,
                            timespan,
                            SyncStateType.ChangesInApp,
                        ),
                    );

                    resolve(<TimeSpan>timespan);
                })
                .catch(reject);
        });
    }

    public createTimeSpanWithTime(
        subtyp: string,
        duration: number,
        date: BCSDate,
        start: BCSDate,
        end: BCSDate,
    ): Promise<TimeSpan> {
        return new Promise((resolve, reject) => {
            const timespan = TimeSpan.create(
                this.schema,
                this.userSession.getCurrentUserOid(),
                subtyp,
            );

            // setzten dis Start- und Endzeit:
            const timeAttributes: TimeAttibutesDefinitions = timespan.getTimeAttibutesDefinitions();
            timespan.setValue(timeAttributes.getStart(), new TimeValue(start.getTimeAsString()));
            timespan.setValue(timeAttributes.getEnd(), new TimeValue(end.getTimeAsString()));

            timespan.setDate(date);
            timespan.setDuration(duration);

            return this.storeTimeSpan(timespan)
                .then((timespan) => {
                    this.syncStateTimeRecordPool.writeSyncStateToDB(
                        SyncState.fromTimeRecord(
                            this.currentUserOid,
                            timespan,
                            SyncStateType.ChangesInApp,
                        ),
                    );
                    resolve(<TimeSpan>timespan);
                })
                .catch(reject);
        });
    }

    /**
     *Prüft, ob Start, Ende und Dauer im richtigen Verhältnis stehen. Leitet die Werte dazu an den
     * @link(TimeRuleOfThree.ts) weiter
     *
     *
     */
    public maintainTimeConstraints(timerecord: TimeRecord, changedAttribute = null): boolean {
        const isValid = TimeRuleOfThree.maintainRuleOfThree(
            new BCSDate(timerecord.getStartTime()).getMinutesOfDay(),
            new BCSDate(timerecord.getEndTime()).getMinutesOfDay(),
            timerecord.getEffortExpense(),
            changedAttribute,
            timerecord,
        );
        return isValid;
    }

    updateBookedDurationOnTimesheetTask(
        effortTargetId: string,
        bookedDuration: number,
    ): Promise<Task> {
        return this.timesheetPool.updateBookedDurationOnTimesheetTask(
            this.currentUserOid,
            effortTargetId,
            bookedDuration,
        );
    }

    updateBookedDurationWithDiff(effortTargetId: string, diffinMin: number): Promise<Task> {
        return this.timesheetPool.updateBookedDurationWithDiff(
            this.currentUserOid,
            effortTargetId,
            diffinMin,
        );
    }

    private async readSyncStateFromDatabase(): Promise<void> {
        this.bookingSyncState = await DomainSyncState.fetchFromApplicationProperties(
            TimeRecordingManager.BOOKING_SYNC_STATE_PROPERTY_KEY,
            this.applicationProperties,
            this.userSession.getCurrentUserOid(),
        );

        this.timesheetSyncState = await DomainSyncState.fetchFromApplicationProperties(
            TimeRecordingManager.TIMESHEET_CHECKSUM_PROPERTY_KEY,
            this.applicationProperties,
            this.userSession.getCurrentUserOid(),
        );

        this.timespanSyncState = await DomainSyncState.fetchFromApplicationProperties(
            TimeRecordingManager.TIMESPAN_SYNC_STATE_PROPERTY_KEY,
            this.applicationProperties,
            this.userSession.getCurrentUserOid(),
        );

        this.timesheetAppointmentsSyncState = await DomainSyncState.fetchFromApplicationProperties(
            TimeRecordingManager.TIMESHEET_APPOINTMENT_SYNC_STATE_PROPERTY_KEY,
            this.applicationProperties,
            this.userSession.getCurrentUserOid(),
        );

        this.timesheetTasksSyncState = await DomainSyncState.fetchFromApplicationProperties(
            TimeRecordingManager.TIMESHEET_TASKS_SYNC_STATE_PROPERTY_KEY,
            this.applicationProperties,
            this.userSession.getCurrentUserOid(),
        );
        Log.debug("Try to retrieve TimeRecording Options from Index Database");
        this.recordingOptions = await this.bookingsPool.readTermsFromDB(
            this.userSession.getCurrentUserOid(),
        );
    }

    public deleteTimeRecordFromDB(timerecord: TimeRecord): Promise<void> {
        const id = timerecord.getId();
        if (timerecord.isBooking()) {
            return new Promise((resolve, reject) => {
                this.bookingsPool
                    .deleteBookingFromDB([id])
                    .then(() => {
                        this.syncStateTimeRecordPool.deleteSyncState(id).then((syncState) => {
                            resolve();
                        });
                    })
                    .catch(reject);
            });
        } else {
            return new Promise((resolve, reject) => {
                this.timespanPool
                    .delteTimeSpanFromDB([id])
                    .then(() => {
                        this.syncStateTimeRecordPool.deleteSyncState(id).then((syncState) => {
                            resolve();
                        });
                    })
                    .catch(reject);
            });
        }
    }

    private deleteTimeRecordFromServer(timerecord: TimeRecord): Promise<void> {
        if (!timerecord.isDummy()) {
            if (timerecord.isBooking()) {
                return this.bookingsPool.deleteBookingFromBCSServer(timerecord);
            } else {
                return this.timespanPool.deleteTimeSpanFromBCSServer(timerecord);
            }
        }
    }

    private async readTimeRecordingOptionsFromBCS() {
        Log.debug("Try to retrieve TimeRecording Options from BCS");
        try {
            const timeRecordingObject = await new BookingsClient().readOptionsFromBCS(0); // TODO: Warum steht bei Checksum eine 0??
            if (timeRecordingObject) {
                this.recordingOptions = new TimeRecordingOptions(timeRecordingObject);
                await this.bookingsPool.writeTermsToDB(
                    this.userSession.getCurrentUserOid(),
                    this.recordingOptions,
                );
            }
        } catch (error) {
            Log.error("[TimeRecordingManager] Failed to Read TimeRecording Options:" + error, {
                error: error,
            });
        }
    }
}

Registry.registerSingletonComponent(TimeRecordingManager.BCS_COMPONENT_NAME, TimeRecordingManager);
