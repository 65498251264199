import { ListViewModel } from "../../../gui/list/ListViewModel";
import { ListRowModel } from "../../../gui/list/ListRowModel";
import { ContactListRowModel } from "./ContactListRowModel";
import { Contact } from "../Contact";
import { I18n } from "../../../common/i18n/I18n";

export class ContactListViewModel implements ListViewModel {
    private contact: Contact;

    private i18n: I18n;

    constructor(contact: Contact, i18n: I18n) {
        this.contact = contact;
        this.i18n = i18n;
    }

    countRows(): number {
        return 1;
    }

    getRowModelByNo(rowNo: number): ListRowModel {
        return new ContactListRowModel(this.contact, this.i18n);
    }

    getRowModelById(id: string): ListRowModel {
        return new ContactListRowModel(this.contact, this.i18n);
    }

    isResultTruncated(): boolean {
        return false;
    }
}
