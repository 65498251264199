import { BCSDate } from "../../../common/BCSDate";
import { I18n } from "../../../common/i18n/I18n";
import { AppConsole } from "../../../common/log/AppConsole";
import { ConfigNode } from "../../../core/Config/ConfigNode";
import { Registry } from "../../../core/Registry";
import { TextLabel } from "../../../gui/content/TextLabel";
import { FlexBox, FlexDirection, FlexJustifyContent } from "../../../gui/flexbox/FlexBox";
import { GUIContext } from "../../../gui/GUIContext";
import { GUIElement } from "../../../gui/GUIElement";
import { OnRenderBehavior } from "../../../gui/OnRenderBehavior";
import { StringTools } from "../../../util/text/StringTools";
import { AttendanceClock, AttendanceClockState } from "./AttendanceClock";
import "./AttendanceClock.less";
import { AttendanceClockButtons } from "./AttendanceClockButtons";

/**
 *
 *
 */
export class AttendanceClockControl implements GUIElement, OnRenderBehavior {
    public static BCS_COMPONENT_NAME = "AttendanceClockControl";
    public attendanceLink: AttendanceClockButtons;
    private configNode: ConfigNode;
    private context: GUIContext;
    private i18n: I18n;
    private parent: JQuery;
    private currentAttendanceWrapper: JQuery;
    private attendanceClock: AttendanceClock;

    /**
     * Wollen dem Kalendar-Blatt in eingeschränkten Parametern eine dynamische Höhe geben.
     * Daher merken wir uns wie viel Platz wir noch für die Ziffern übrig haben.
     */
    private avaliableCalendarHeight: number;
    private pauseInterval: number;
    private attendanceInterval: number;

    constructor(configNode?: ConfigNode, guiContext?: GUIContext) {
        this.context = guiContext;
        this.configNode = configNode;
        if (typeof this.context !== "undefined") {
            this.i18n = this.context.getI18n();
        }
        this.attendanceClock = this.context.getAttendanceClockEntity();
    }

    public compose($parent: JQuery): void {
        this.attendanceLink = new AttendanceClockButtons(null, this.context);

        this.attendanceLink.setStyleClass("button");
        this.attendanceLink.onAttendanceClick(this.attendanceClicked.bind(this));
        this.attendanceLink.onPauseClick(this.pauseClicked.bind(this));
        const horizontalBox = new FlexBox()
            .setJustifyContent(FlexJustifyContent.SPACE_EVENLY)
            .setFlexDirection(FlexDirection.ROW)
            .addStyleClass("attendanceClockBTNBox")
            .useFullWidth(true);
        horizontalBox.newFlexItem().addContentElement(this.attendanceLink);
        horizontalBox.compose($parent);

        this.composeCurrentAttendances($parent);
    }

    executeOnRender($body: JQuery): void {
        //this.adjustButtonState();
    }

    executeAfterChildrenRender($body: JQuery): void {
        this.adjustButtonState();
    }

    /**
     * Für aynamisches Aktualiseren nach z.B. einem Klick auf einen Button und der Antwort des Servers.
     */
    public reComposeCurrentAttendances() {
        this.currentAttendanceWrapper.empty();
        this.composeCurrentAttendances(this.currentAttendanceWrapper);
    }

    public startClockIntervall(textLabel: TextLabel, isPause, startTimeInMinutes: number) {
        if (isPause) {
            this.pauseInterval = window.setInterval(function () {
                const now: BCSDate = BCSDate.getToday();
                const currentAttendanceTime = now.minuteDiff(startTimeInMinutes);
                textLabel.setAdhocText(BCSDate.minnutesToDisplayTime(currentAttendanceTime) + "h");
            }, 1000);
        } else {
            this.attendanceInterval = window.setInterval(function () {
                const now: BCSDate = BCSDate.getToday();
                const currentAttendanceTime = now.minuteDiff(startTimeInMinutes);
                textLabel.setAdhocText(BCSDate.minnutesToDisplayTime(currentAttendanceTime) + "h");
            }, 1000);
        }
    }

    public attendanceClicked(event): void {
        AppConsole.debug("attendanceClicked");
        const self = this;
        const transferObject = {};
        transferObject[AttendanceClockControl.BCS_COMPONENT_NAME] = this;
        transferObject["parentGUIElement"] = this.context.getBoardElement();

        this.context.triggerEvent(
            AttendanceClockControl.BCS_COMPONENT_NAME,
            "clickedAttendance",
            event,
            transferObject,
        );
    }

    public pauseClicked(event): void {
        AppConsole.debug("pauseClicked");
        const self = this;
        const transferObject = {};
        transferObject[AttendanceClockControl.BCS_COMPONENT_NAME] = this;
        transferObject["parentGUIElement"] = this.context.getBoardElement();

        this.context.triggerEvent(
            AttendanceClockControl.BCS_COMPONENT_NAME,
            "clickedPause",
            event,
            transferObject,
        );
    }

    getComponentChildren(): GUIElement[] {
        const result = [];
        result.push(this.attendanceLink);
        return result;
    }

    public adjustButtonState(): void {
        if (this.attendanceClock.getAttendanceState() == AttendanceClockState.AttandanceRuning) {
            this.attendanceLink.setAttendanceRunning();
        } else {
            this.attendanceLink.setAttendanceStopped();
        }

        if (this.attendanceClock.getPauseState() == AttendanceClockState.PauseRunning) {
            this.attendanceLink.setAttendanceRunning();
            this.attendanceLink.setPauseRunning();
        } else {
            this.attendanceLink.setPauseStopped();
        }
    }

    private composeCurrentAttendances($parent: JQuery) {
        const horizontalBox = new FlexBox()
            .setJustifyContent(FlexJustifyContent.SPACE_EVENLY)
            .setFlexDirection(FlexDirection.ROW)
            .useFullWidth(true);

        this.currentAttendanceWrapper = $("<div id='currentAttendances'></div>");

        this.composeSavedAttendancesBlock(horizontalBox);
        if (this.attendanceClock.getAttendanceState() == AttendanceClockState.AttandanceRuning) {
            this.composeRunningWatchBlock(horizontalBox, "attendance");
        }

        if (this.attendanceClock.getPauseState() == AttendanceClockState.PauseRunning) {
            // Pause
            this.composeRunningWatchBlock(horizontalBox, "pause");
        }

        $parent.append(this.currentAttendanceWrapper);

        horizontalBox.compose(this.currentAttendanceWrapper);
    }

    private composeRunningWatchBlock(horizontalBox: FlexBox, subtype: string) {
        const isPause: boolean = subtype === "pause";
        const upperSubtye: string = StringTools.capitalizeFirstLetter(subtype);

        // Überschrift: z.B. laufende Anwesenheit
        const attendanceStartBox = new FlexBox()
            .setJustifyContent(FlexJustifyContent.SPACE_BETWEEN)
            .setFlexDirection(FlexDirection.COLUMN);
        attendanceStartBox
            .newFlexItem()
            .addContentElement(
                new TextLabel()
                    .setText(this.i18n.get("MobileApp.attendanceClock.running" + upperSubtye))
                    .addStyleClass("headlabel"),
            );

        // Horizontale Box sorgt dafür das Anwesenheits- und Pauseblock nebeneinander liegen
        const horizontalPartBox = new FlexBox()
            .setJustifyContent(FlexJustifyContent.SPACE_BETWEEN)
            .setFlexDirection(FlexDirection.ROW)
            .addStyleClass("horizontalPartBox")
            .useFullWidth(false);

        // Block für die Anzeige der Startzeit
        const boxStartTime = new FlexBox()
            .setJustifyContent(FlexJustifyContent.SPACE_BETWEEN)
            .setFlexDirection(FlexDirection.COLUMN)
            .useFullWidth(false)
            .addStyleClass("attendanceBox");
        // Label z.B. Anwesenheit
        var descriptionPart = boxStartTime.newFlexItem().setFlexBasis("90%");
        descriptionPart.addContentElement(
            new TextLabel().setText("Startzeit").addStyleClass("label"),
        );

        var durationPart = boxStartTime.newFlexItem().setFlexBasis("10%");
        let startDateTime: BCSDate = this.attendanceClock.getStartedAttendaneDateTime();

        if (isPause) {
            startDateTime = this.attendanceClock.getStartedPauseDateTime();
        }

        durationPart.addContentElement(
            new TextLabel().setText(startDateTime.format("HH:mm") + "h").addStyleClass("value"),
        );
        horizontalPartBox.newFlexItem().setFlexBasis("50%").addContentElement(boxStartTime);

        // Block für die Anzeige der Dauer
        const boxDuration = new FlexBox()
            .setJustifyContent(FlexJustifyContent.SPACE_BETWEEN)
            .setFlexDirection(FlexDirection.COLUMN)
            .useFullWidth(false)
            .addStyleClass("attendanceBox");
        var descriptionPart = boxDuration.newFlexItem().setFlexBasis("90%");
        descriptionPart.addContentElement(new TextLabel().setText("Dauer").addStyleClass("label"));

        var durationPart = boxDuration.newFlexItem().setFlexBasis("10%");
        const now: BCSDate = BCSDate.getToday();

        let startTimeInMinutes: number =
            this.attendanceClock.getSavedAttendanceStartTimeInMinutes();

        if (isPause) {
            startTimeInMinutes = this.attendanceClock.getSavedPauseStartTimeInMinutes();
        }

        const currentAttendanceTime: number = now.minuteDiff(startTimeInMinutes);
        const textLabel: TextLabel = new TextLabel()
            .setText(BCSDate.minnutesToDisplayTime(currentAttendanceTime) + "h")
            .addStyleClass("value");
        durationPart.addContentElement(textLabel);

        this.startClockIntervall(textLabel, isPause, startTimeInMinutes);

        horizontalPartBox.newFlexItem().setFlexBasis("50%").addContentElement(boxDuration);
        attendanceStartBox.newFlexItem().addContentElement(horizontalPartBox);
        horizontalBox.newFlexItem().addContentElement(attendanceStartBox);
    }

    private composeSavedAttendancesBlock(horizontalSavedBox: FlexBox /*wrapper:JQuery*/) {
        /*
                let horizontalSavedBox =  new FlexBox().setJustifyContent(FlexJustifyContent.SPACE_BETWEEN)
                                                        .setFlexDirection(FlexDirection.ROW)
                                                        .addStyleClass("horizontalSavedBox")
                                                        .useFullWidth(false);
        */
        // Überschrift: z.B. laufende Anwesenheit
        const attendanceStartBox = new FlexBox()
            .setJustifyContent(FlexJustifyContent.SPACE_BETWEEN)
            .setFlexDirection(FlexDirection.COLUMN);
        attendanceStartBox
            .newFlexItem()
            .addContentElement(
                new TextLabel()
                    .setText(this.i18n.get("MobileApp.attendanceClock.savesAttendancesAndPauses"))
                    .addStyleClass("headlabel"),
            );

        // Horizontale Box sorgt dafür das Anwesenheits- und Pauseblock nebeneinander liegen
        const horizontalPartBox = new FlexBox()
            .setJustifyContent(FlexJustifyContent.SPACE_BETWEEN)
            .setFlexDirection(FlexDirection.ROW)
            .addStyleClass("horizontalPartBox")
            .useFullWidth(false);

        // Block für die Anzeige der Anwesenehit
        const boxAttendance = new FlexBox()
            .setJustifyContent(FlexJustifyContent.SPACE_BETWEEN)
            .setFlexDirection(FlexDirection.COLUMN)
            .useFullWidth(false)
            .addStyleClass("attendanceBox");
        var descriptionPart = boxAttendance.newFlexItem().setFlexBasis("90%");
        descriptionPart.addContentElement(
            new TextLabel().setText(this.i18n.get("MobileApp.attendance")).addStyleClass("label"),
        );

        const durationAttendancePart = boxAttendance.newFlexItem().setFlexBasis("10%");
        var now: BCSDate = BCSDate.getToday();
        const currentAttendanceTime: number = this.attendanceClock.getAttendanceSum();
        durationAttendancePart.addContentElement(
            new TextLabel()
                .setText(BCSDate.minnutesToDisplayTime(currentAttendanceTime) + "h")
                .addStyleClass("value"),
        );
        horizontalPartBox.newFlexItem().addContentElement(boxAttendance);
        //boxAttendance.compose(currentAttendanceWrapper);

        const boxPause = new FlexBox()
            .setJustifyContent(FlexJustifyContent.SPACE_BETWEEN)
            .setFlexDirection(FlexDirection.COLUMN)
            .useFullWidth(false);
        var descriptionPart = boxPause.newFlexItem().setFlexBasis("90%");
        descriptionPart.addContentElement(
            new TextLabel().setText(this.i18n.get("MobileApp.pause")).addStyleClass("label"),
        );

        const durationPausePart = boxPause.newFlexItem().setFlexBasis("10%");
        var now: BCSDate = BCSDate.getToday();
        const currentPauseTime: number = this.attendanceClock.getPauseSum();
        durationPausePart.addContentElement(
            new TextLabel()
                .setText(BCSDate.minnutesToDisplayTime(currentPauseTime) + "h")
                .addStyleClass("value"),
        );

        horizontalPartBox.newFlexItem().setFlexBasis("50%").addContentElement(boxPause);
        attendanceStartBox.newFlexItem().addContentElement(horizontalPartBox);
        horizontalSavedBox.newFlexItem().addContentElement(attendanceStartBox);

        //horizontalSavedBox.compose(wrapper);
    }
}

Registry.registerGUIComponent(AttendanceClockControl.BCS_COMPONENT_NAME, AttendanceClockControl);
