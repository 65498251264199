/**
 * Statische GUI-Definition der Listen der Sync-Seite.
 *
 * Vorerst statisch, perspektivisch serverseitig als XML konfigurierbar und via AppConfig per REST abfragbar.
 */
export class SyncGUIDefinitions {
    constructor() {}

    public getErrorSyncStatesListViewDef(): object {
        return SyncGUIDefinitions.ERROR_SYNC_STATES_LIST_VIEW_DEF;
    }

    /** Liste mit Synchronisationsfehler */
    private static ERROR_SYNC_STATES_LIST_VIEW_DEF = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "compound",
                        field: {
                            icon: "NONE",
                            mainContent: {
                                left: {
                                    class: "field",
                                    field: {
                                        name: "objectType",
                                        mode: "display",
                                    },
                                },
                                /*
                                "right": {
                                    "class": "field", 
                                    "field": {
                                        "name": "syncState", 
                                        "mode": "display"
                                    }
                                }
                                */
                            },
                            subContents: [
                                {
                                    left: {
                                        class: "field",
                                        field: {
                                            name: "errorMessage",
                                            mode: "display",
                                        },
                                    },
                                    /*
                                    "right": {
                                        "class": "field", 
                                        "field": {
                                            "name": "objectType", 
                                            "mode": "display"
                                        }
                                    }
                                    */
                                },
                            ],
                        },
                        link: {
                            path: "link",
                            parameters: {
                                oid: { attribute: "oid" },
                            },
                        },
                    },
                ],
            },
        ],
    };
}
