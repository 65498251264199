import { I18n } from "../../common/i18n/I18n";
import { HTMLContent } from "../../gui/content/HTMLContent";
import { TextLabel } from "../../gui/content/TextLabel";
import { FlexAlignItems, FlexBox, FlexDirection } from "../../gui/flexbox/FlexBox";
import { GUIElement } from "../../gui/GUIElement";
import "./Placeholder.less";

export class PlaceholderChild implements GUIElement {
    private i18n: I18n;

    constructor(i18n: I18n) {
        this.i18n = i18n;
    }

    compose($parent: JQuery<HTMLElement>, _parentElement?: GUIElement): void {
        this.showPlaceholder($parent);
        const viewX: number[] = [35.4, 283.6],
            animationDelay = 600,
            invader: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById("invader");

        let frame = 0;

        setInterval(() => {
            frame = ++frame % 2;
            //@ts-ignore
            invader.viewBox.baseVal.x = viewX[frame];
        }, animationDelay);
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }

    private showPlaceholder($parent: JQuery): void {
        const label: string = this.i18n.get("MobileApp.Placeholder");
        const containerFlexBox: FlexBox = new FlexBox()
            .setFlexDirection(FlexDirection.COLUMN)
            .setAlignItems(FlexAlignItems.CENTER)
            .addStyleClass("placeholder")
            .setDOMId("placeholderElement");

        containerFlexBox.newFlexItem().addContentElement(this.composeIcon());
        containerFlexBox.newFlexItem().addContentElement(new TextLabel());
        containerFlexBox.newFlexItem().addContentElement(new TextLabel().setInlineText(label));

        containerFlexBox.compose($parent);
    }

    private composeIcon(): GUIElement {
        const selector: string =
            '<svg id="invader" xmlns="http://www.w3.org/2000/svg" viewBox="35.4 35.4 195.8 141.8"><path d="M70.9 35.4v17.8h17.7V35.4H70.9zm17.7 17.8v17.7H70.9v17.7H53.2V53.2H35.4V124h17.8v17.7h17.7v17.7h17.7v-17.7h88.6v17.7h17.7v-17.7h17.7V124h17.7V53.2h-17.7v35.4h-17.7V70.9h-17.7V53.2h-17.8v17.7H106.3V53.2H88.6zm88.6 0h17.7V35.4h-17.7v17.8zm17.7 106.2v17.8h17.7v-17.8h-17.7zm-124 0H53.2v17.8h17.7v-17.8zm17.7-70.8h17.7v17.7H88.6V88.6zm70.8 0h17.8v17.7h-17.8V88.6z"/><path d="M319 35.4v17.8h17.6V35.4H319zm17.6 17.8v17.7H319v17.7h-17.7v17.7h-17.7V159.4h17.7V124h17.7v35.4h17.7v-17.7H425.2v17.7h17.7V124h17.7v35.4h17.7V106.3h-17.7V88.6H443V70.9h-17.7V53.2h-17.7v17.7h-53.2V53.2h-17.7zm88.6 0h17.7V35.4h-17.7v17.8zm0 106.2h-35.5v17.8h35.5v-17.8zm-88.6 0v17.8h35.5v-17.8h-35.5zm0-70.8h17.7v17.7h-17.7V88.6zm70.9 0h17.7v17.7h-17.7V88.6z"/></svg>';
        const Icon = $(selector);
        Icon.addClass("boardIcon");
        const content = new HTMLContent(Icon);
        return content;
    }
}
