import { ListViewModel } from "../../../../gui/list/ListViewModel";
import { ListRowModel } from "../../../../gui/list/ListRowModel";
import { contactOptionType } from "./ContactOuContrySelectionController";
import { ContactOptionSearchListRowModel } from "./ContactOptionSearchListRowModel";
import { I18n } from "../../../../common/i18n/I18n";

export class ContactOptionSearchListViewModel implements ListViewModel {
    private optionList: contactOptionType[];
    private i18n: I18n;

    constructor(optionList: { optionLabel: string; optionId: string }[], i18n: I18n) {
        this.optionList = optionList;
        this.i18n = i18n;
    }

    countRows(): number {
        return this.optionList.length;
    }

    getRowModelById(id: string): ListRowModel {
        return new ContactOptionSearchListRowModel(
            this.optionList.filter((contactOption) => contactOption.optionId == id)[0],
            this.i18n,
        );
    }

    getRowModelByNo(rowNo: number): ListRowModel {
        return new ContactOptionSearchListRowModel(this.optionList[rowNo], this.i18n);
    }

    isResultTruncated(): boolean {
        return false;
    }
}
