import { SchemaDataType } from "./SchemaDataType";

export class AttributeDefinition {
    private type: string;

    private subtype: string;

    private attribute: string;

    private attributeDef: { [key: string]: string | number | boolean | string[] } = {};

    constructor(
        type: string,
        subtype: string,
        attribute: string,
        attributeDef: { [key: string]: string | number | boolean | string[] },
    ) {
        this.type = type;
        this.subtype = subtype;
        this.attribute = attribute;
        this.attributeDef = attributeDef;
    }

    public getDataType(): SchemaDataType {
        const dataType = this.attributeDef.hasOwnProperty("datatype")
            ? <SchemaDataType>this.attributeDef["datatype"]
            : SchemaDataType.STRING;

        // Datentype "LString" und "Text" mit Flag HTML wird als eigener Pseudo-Datentyp "HTML" zurückgegeben,
        // damit die aufrufenden Stelle keine weiteren Fallunterscheidung mit/ohne HTML machen müssen.
        if (
            (dataType == SchemaDataType.LSTRING || dataType == SchemaDataType.TEXT) &&
            this.isHTML()
        ) {
            return SchemaDataType.HTML;
        }

        // Datentype "String" mit Optionen wird als eigener Pseudo-Datentyp "Option" zurückgegeben,
        // damit die aufrufenden Stelle keine weiteren Fallunterscheidung mit/ohne Optionen machen müssen.
        if (
            dataType == SchemaDataType.STRING &&
            (this.getClassifier().length > 0 || this.isEmptyClassifier())
        ) {
            return SchemaDataType.CLASSIFIER;
        }
        // Datentype "String" mit Optionen wird als eigener Pseudo-Datentyp "Option" zurückgegeben,
        // damit die aufrufenden Stelle keine weiteren Fallunterscheidung mit/ohne Optionen machen müssen.
        if (dataType == SchemaDataType.STRING && this.getOptions().length > 0) {
            return SchemaDataType.OPTION;
        }

        return dataType;
    }

    public getType(): string {
        return this.type;
    }

    public getSubtype(): string {
        return this.subtype;
    }

    public getAttribute(): string {
        return this.attribute;
    }

    public isHTML(): boolean {
        return this.attributeDef.hasOwnProperty("html") ? this.attributeDef["html"] == true : false;
    }

    /**
     * @returns true wenn Pflichtattribut
     *          Attribute mit Default="#undefined" werden ausgenommen, da sie implizit zu Pflichtattributen werden,
     *          wenn UndefinedInsteadOfNull="true" und Default="#undefined" konfiguriert ist (z.B. "JEffort.effortWorkingTimeType"),
     *          die App aber die vom Benutzer auszufüllenden Attribute wissen möchte.
     */
    public isRequired(): boolean {
        const required = this.attributeDef.hasOwnProperty("required")
            ? !!this.attributeDef["required"]
            : false;
        return required && this.getDefaultValue() !== "#undefined";
    }

    public getMaxLength(): number {
        return this.attributeDef.hasOwnProperty("maxlength")
            ? <number>this.attributeDef["maxlength"]
            : null;
    }

    public getMinValue(): number {
        return this.attributeDef.hasOwnProperty("minvalue")
            ? <number>this.attributeDef["minvalue"]
            : null;
    }

    public getMaxValue(): number {
        return this.attributeDef.hasOwnProperty("maxvalue")
            ? <number>this.attributeDef["maxvalue"]
            : null;
    }

    public getOptions(): string[] {
        return this.attributeDef.hasOwnProperty("options")
            ? <string[]>this.attributeDef["options"]
            : [];
    }

    public getClassifier(): string[] {
        return this.attributeDef.hasOwnProperty("classifier")
            ? <string[]>this.attributeDef["classifier"]
            : [];
    }

    public setEmptyClassifier(): void {
        this.attributeDef["isEmptyClassifier"] = true;
    }

    public isEmptyClassifier(): boolean {
        return this.attributeDef.hasOwnProperty("isEmptyClassifier") ? true : false;
    }

    public getPlaintAttributeDefinition() {
        return this.attributeDef;
    }

    public getDefaultValue(): string | number | boolean {
        if (!this.attributeDef.hasOwnProperty("default")) {
            return null;
        }

        const defaultValue = <string>this.attributeDef["default"];
        if (typeof defaultValue === "undefined") {
            return null;
        }

        const dataType = this.getDataType();
        switch (this.getDataType()) {
            case SchemaDataType.STRING:
            case SchemaDataType.LSTRING:
            case SchemaDataType.TEXT:
            case SchemaDataType.HTML:
            case SchemaDataType.OPTION:
            case SchemaDataType.MULTISTRING:
            case SchemaDataType.URL:
            case SchemaDataType.MAIL:
            case SchemaDataType.CLASSIFIER:
                return defaultValue;
            case SchemaDataType.INT:
            case SchemaDataType.LONG:
                return parseInt(defaultValue);
            case SchemaDataType.FLOAT:
            case SchemaDataType.DOUBLE:
                return parseFloat(defaultValue);
            case SchemaDataType.BOOL:
                return defaultValue == "true";
            default:
                return null;
        }
    }

    public getCurrencyAttribute(): string {
        return this.attributeDef.hasOwnProperty("currencyattribute")
            ? <string>this.attributeDef["currencyattribute"]
            : null;
    }
}
