import { I18n } from "../common/i18n/I18n";
import { GUIEventPool } from "./event/GUIEventPool";
import { Booking } from "../domain/time_recording/bookings/Booking";
import { AttendanceClock } from "../domain/time_recording/attendance/AttendanceClock";
import { BoardElement } from "./board/BoardElement";
import { BookingsPerDay } from "../domain/time_recording/bookings/BookingsPerDay";

export class GUIContext {
    private i18n: I18n;

    /**
     * Die größt mögliche Höhe die der Inhalt einer Komponente im Moment annehmen kann.
     *
     * Komponenten wie beispielsweise Board-Elemente haben eine feste höhe vorgegeben.
     * Da es Inhalte wie das CalendarControll gibt, die sich an die Höhe anpassen müssen,
     * gibt es diese Höhenangabe.
     *
     */
    private possibleContentHeightPx: number = -1;

    private bookings: Booking[] = [];

    private bookingsPerDay: BookingsPerDay;

    private attendanceClock: AttendanceClock;

    private eventPool: GUIEventPool;

    private boardModels: { [key: string]: object } = {};

    /**
     * Falls die aufrufende Komponente unter einem Board instanziert wurde.
     * Ist dieses Attribute mit dem Board gefüllt.
     *
     * Wird benötigt um nur das Board Element, bei gewissen aktionen vom Controller neuzuzeichnen.
     * Beim Auslösen des Listeners z.B. Klick an den Controller, muss dem Controller mitgegeben werden,
     * welches BoardElement aktualisiert werden soll.
     */
    private boardElement: BoardElement;

    constructor(
        givenI18n: I18n,
        givenEventPool?: GUIEventPool,
        givenBookings?: Booking[],
        attendanceClock?: AttendanceClock,
        boardElement?: BoardElement,
        bookingsPerDay?: BookingsPerDay,
    ) {
        this.i18n = givenI18n;
        this.eventPool = givenEventPool;
        this.bookings = givenBookings;
        this.attendanceClock = attendanceClock;
        this.boardElement = boardElement;
        this.bookingsPerDay = bookingsPerDay;
    }

    public getI18n(): I18n {
        return this.i18n;
    }
    /**
     *
     * @param componentName
     * @param componentEventName
     * @param event
     * @param eventContext - Da wir das orginale Event vom Browser von unseren Event-Informationen trennen wollen,
     *                       gibt dieses Object die Möglichkeit mit Informationen an den Controller zu reichen.
     */
    public triggerEvent(
        componentName: string,
        componentEventName: string,
        event: Event,
        eventContext: Object,
    ) {
        this.eventPool.triggerEvent(componentName, componentEventName, event, eventContext);
    }

    /**
     * Komponenten wie beispielsweise Board-Elemente haben eine feste höhe vorgegeben.
     * Da es Inhalte wie das CalendarControll gibt, die sich an die Höhe anpassen müssen,
     * gibt es diese Höhenangabe.
     *
     * @param contentHeightPx Die größt mögliche Höhe die der Inhalt einer Komponente im Moment annehmen kann.
     */
    public setPossibleContentHeight(contentHeightPx: number): GUIContext {
        this.possibleContentHeightPx = contentHeightPx;
        return this;
    }

    /**
     * Die größt mögliche Höhe die der Inhalt einer Komponente im Moment annehmen kann.
     *
     * Komponenten wie beispielsweise Board-Elemente haben eine feste höhe vorgegeben.
     * Da es Inhalte wie das CalendarControll gibt, die sich an die Höhe anpassen müssen,
     * gibt es diese Höhenangabe.
     */
    public getPossibleContentHeightPx(): number {
        return this.possibleContentHeightPx;
    }

    public getClone() {
        const guiContext = new GUIContext(
            this.i18n,
            this.eventPool,
            this.bookings,
            this.attendanceClock,
            this.boardElement,
            this.bookingsPerDay,
        );

        for (const name in this.boardModels) {
            guiContext.setBoardModel(name, this.getBoardModel(name));
        }

        return guiContext;
    }

    public set(bookings: Booking[]) {
        this.bookings = bookings;
    }

    public setBookingRangeEntites(bookings: Booking[]) {
        this.bookings = bookings;
    }

    public setBookingsTodayEntites(bookings: Booking[]) {
        this.bookings = bookings;
    }

    public setBookingsPerDay(bookingsPerDay: BookingsPerDay): GUIContext {
        this.bookingsPerDay = bookingsPerDay;
        return this;
    }

    public getBookingsPerDay(): BookingsPerDay {
        return this.bookingsPerDay;
    }

    public setAttendanceClockEntity(attendanceClock: AttendanceClock) {
        this.attendanceClock = attendanceClock;
    }

    public getAttendanceClockEntity(): AttendanceClock {
        return this.attendanceClock;
    }

    public getBookingEntities(): Booking[] {
        return this.bookings;
    }

    public setBoardElement(boardElement: BoardElement) {
        this.boardElement = boardElement;
    }

    public setBoardModel(name: string, boardModel: object): GUIContext {
        this.boardModels[name] = boardModel;
        return this;
    }

    public getBoardModel(name: string): object {
        return this.boardModels[name];
    }

    public getBoardElement(): BoardElement {
        return this.boardElement;
    }
}
