import { EntityValue } from "./EntityValue";

export class OidValue implements EntityValue {
    private value: string;

    public static fromOidAndName(oid: string, name: string): OidValue {
        if (!oid) {
            return new OidValue(null);
        }
        if (!name) {
            return new OidValue(oid);
        }
        return new OidValue(oid + "|" + name);
    }

    constructor(simpleValue: string) {
        this.value = simpleValue;
    }

    public isDefined(): boolean {
        return !!this.value && this.value.length > 0;
    }

    public getSimpleValue(): string | number | boolean {
        return this.value;
    }

    public getString(): string {
        return this.getOid();
    }

    public getOid(): string {
        return this.getOidAndName().oid;
    }

    public getNameOrDefault(defaultName: string): string {
        const name = this.getOidAndName().name;
        return name ? name : defaultName;
    }

    public getOidAndName(): { oid: string; name: string } {
        if (!this.value) {
            return { oid: null, name: null };
        }

        if (this.value.indexOf("|") < 0) {
            return { oid: this.value, name: null };
        }

        const result = new RegExp("^(.+?_[A-Za-z0-9_]+)\\|(.+)$").exec(this.value);
        return { oid: result[1], name: result[2] };
    }
}
