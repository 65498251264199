import { GUIElement } from "../GUIElement";
import { FixedGUIElement } from "../FixedGUIElement";
import { Image } from "../content/Image";
import "./FloatingActionButton.less";

export class FloatingActionButton implements GUIElement, FixedGUIElement {
    private imageName: string;

    private clickCallback: Function;

    private $buttonTag: JQuery;

    private isShown = true;

    public setImageName(imageName: string): FloatingActionButton {
        this.imageName = imageName;
        return this;
    }

    public onClick(clickCallback: Function): FloatingActionButton {
        this.clickCallback = clickCallback;
        return this;
    }

    /**
     * HTML-DOM generieren
     *
     * @param $parentTag Parent-jQuery-Objekt
     */
    public compose($parentTag: JQuery): void {
        const self = this;

        this.$buttonTag = $("<div>")
            .appendTo($parentTag)
            .addClass("floating_action_button")
            .on("click", this.actionButtonClicked.bind(this));

        if (this.imageName) {
            new Image().setImageName(this.imageName).compose(this.$buttonTag);
        }
    }

    public hide(): FloatingActionButton {
        this.$buttonTag.css({ display: "none" });
        this.isShown = false;
        return this;
    }

    public show(): FloatingActionButton {
        this.$buttonTag.css({ display: "inline-block" });
        this.isShown = true;
        return this;
    }

    /**
     * @return true, wenn GUIElement fixiert, false wenn nicht (für Elemente, die sowohl fixiert als auch mitscrollend sein können)
     */
    public isFixed(): boolean {
        return true;
    }

    /**
     * @return Höhe des fixierten GUIElementes (in px) nach dem alle GUIElemente der Seite gerendert wurden
     */
    public getFixedHeight(): number {
        return this.isShown ? this.$buttonTag.outerHeight() + 20 : 0; // Höhe + je 10px Luft oberhalb+unterhalöb
    }

    /**
     * Setzt Y-Position des fixierten GUIELementes (absoluten Abstand zum oberen bzw. unteren Rand).
     *
     * @param verticalPositionKey "top" oder "bottom" für Header bzw. Footer
     * @param verticalPos Y-Position vom oberen bzw. unteren Rand (z.B. 15 für "15px")
     */
    public setVerticalPosition(verticalPositionKey: string, verticalPos: number): void {
        this.$buttonTag.css(verticalPositionKey, verticalPos + 10 + "px");
    }

    private actionButtonClicked(event: JQueryEventObject): void {
        if (this.clickCallback) {
            this.clickCallback(event, this.$buttonTag.offset(), "addNewTimeRecord");
        }
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}
