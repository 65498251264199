import { Contact } from "../Contact";
import VCard, { CardVersion } from "vcfer";
import { EntityValue } from "../../../entities/values/EntityValue";
import { AppConsole } from "../../../common/log/AppConsole";
import { Log } from "../../../common/log/Log";

/**Möglichkeiten zum Herunterladen von vCards */
export class VCFFileDownloader {
    /**
     * Durch diese Methode wird er Download gestartet.
     */
    private static downloadFile(file: string): void {
        const element = document.createElement("a");
        element.href = "data:text/vcard;charset=utf-8," + encodeURIComponent(file);
        element.target = "_blank";
        element.type = "text/vcard";
        element.download = "Contactcard";
        document.body.appendChild(element);
        element.click();
    }

    private static convertEntityIfDefined(entity: EntityValue): string {
        if (entity.isDefined()) {
            return entity.getString();
        } else return "";
    }

    private static stringIsEmpty(input: string): boolean {
        return input === "";
    }

    /**Methode, um direkt nach dem Scannen einen Download starten zu können. Aktuell noch am Scanner deaktiviert. */
    public createFileFromScan(scanResult: string): void {
        VCFFileDownloader.downloadFile(scanResult);
    }

    /**Methode, um vom Bearbeitungsformular aus einen Download des Kontakts starten zu können. */
    public createFileFromContact(contact: Contact): void {
        const vCardVersion: CardVersion = "3.0"; // iOS will noch nicht mit Version 4 umgehen. Deswegen ist es hier wichtig, das Version 3 gesetzt ist! Bitte nur ändern, wenn die exportierte Datei auch mit Umlauten (ßöäü+#/*1&§$) gut aussieht.

        const name = "name";
        const userFirstname = "userFirstname";
        const userAnrede = "userAnrede";
        const userTitle = "userTitle";
        const userTel = "userTel";
        const userHandy = "userHandy";
        const userFax = "userFax";
        const mail = "mail";
        const userFunktion = "userFunktion";
        const ouName = "ouName";
        const userHomepage = "userHomepage";
        const ouStreet = "ouStreet";
        const ouAreacode = "ouAreacode";
        const ouCity = "ouCity";
        const ouCountry = "ouCountry";
        const description = "description";

        const nameString: string = VCFFileDownloader.convertEntityIfDefined(contact.getValue(name));
        const userFirstnameString: string = VCFFileDownloader.convertEntityIfDefined(
            contact.getValue(userFirstname),
        );
        const userAnredeString: string = VCFFileDownloader.convertEntityIfDefined(
            contact.getValue(userAnrede),
        );
        const userTitelString: string = VCFFileDownloader.convertEntityIfDefined(
            contact.getValue(userTitle),
        );
        const userTelString: string = VCFFileDownloader.convertEntityIfDefined(
            contact.getValue(userTel),
        );
        const userHandyString: string = VCFFileDownloader.convertEntityIfDefined(
            contact.getValue(userHandy),
        );
        const userFaxString: string = VCFFileDownloader.convertEntityIfDefined(
            contact.getValue(userFax),
        );
        const mailString: string = VCFFileDownloader.convertEntityIfDefined(contact.getValue(mail));
        const userFunktionString: string = VCFFileDownloader.convertEntityIfDefined(
            contact.getValue(userFunktion),
        );
        const ouNameString: string = VCFFileDownloader.convertEntityIfDefined(
            contact.getValue(ouName),
        );
        const userHomepageString: string = VCFFileDownloader.convertEntityIfDefined(
            contact.getValue(userHomepage),
        );
        const ouStreetString: string = VCFFileDownloader.convertEntityIfDefined(
            contact.getValue(ouStreet),
        );
        const ouAreacodeString: string = VCFFileDownloader.convertEntityIfDefined(
            contact.getValue(ouAreacode),
        );
        const ouCityString: string = VCFFileDownloader.convertEntityIfDefined(
            contact.getValue(ouCity),
        );
        const ouCountryString: string = VCFFileDownloader.convertEntityIfDefined(
            contact.getValue(ouCountry),
        );
        const descriptionString: string = VCFFileDownloader.convertEntityIfDefined(
            contact.getValue(description),
        );

        const vcfCard: VCard = new VCard();
        if (
            !VCFFileDownloader.stringIsEmpty(nameString) ||
            !VCFFileDownloader.stringIsEmpty(userFirstnameString)
        ) {
            try {
                vcfCard.add("n", nameString + ";" + userFirstnameString + ";;" + userTitelString);
            } catch (downloadErr) {
                Log.error("Error while setting the Name of the vCard => " + downloadErr);
            }
        }
        if (!VCFFileDownloader.stringIsEmpty(userAnredeString)) {
            try {
                switch (userAnredeString) {
                    case "Male":
                        vcfCard.add("gender", "M");
                        break;
                    case "Female":
                        vcfCard.add("gender", "F");
                        break;
                    case "Impersonal":
                        vcfCard.add("gender", "N");
                        break;
                    default:
                        AppConsole.log("Writing Gender Failed");
                        break;
                }
            } catch (downloadErr) {
                Log.error("Error while setting the Gender of the vCard => " + downloadErr);
            }
        }

        if (!VCFFileDownloader.stringIsEmpty(userTelString)) {
            try {
                vcfCard.add("tel;work;", userTelString);
            } catch (downloadErr) {
                Log.error("Error while setting the Phone Number of the vCard => " + downloadErr);
            }
        }
        if (!VCFFileDownloader.stringIsEmpty(userHandyString)) {
            try {
                vcfCard.add("tel;cell", userHandyString);
            } catch (downloadErr) {
                Log.error("Error while setting the Handy Number of the vCard => " + downloadErr);
            }
        }
        if (!VCFFileDownloader.stringIsEmpty(userFaxString)) {
            try {
                vcfCard.add("tel;work;fax", userFaxString);
            } catch (downloadErr) {
                Log.error("Error while setting the Fax Number of the vCard => " + downloadErr);
            }
        }
        if (!VCFFileDownloader.stringIsEmpty(mailString)) {
            try {
                vcfCard.add("email", mailString);
            } catch (downloadErr) {
                Log.error("Error while setting the Mail Address of the vCard => " + downloadErr);
            }
        }

        if (!VCFFileDownloader.stringIsEmpty(userFunktionString)) {
            try {
                if (userFunktionString.includes(".")) {
                    // Muss einen Punkt enthalten, falls eine Option ohne Punkt geliefert wird, würde er sonst fehlschlagen
                    vcfCard.add("title", userFunktionString.split(".")[1]);
                } else if (userFunktionString !== "*") {
                    vcfCard.add("title", userFunktionString);
                }
            } catch (downloadErr) {
                Log.error("Error while setting the Function of the vCard => " + downloadErr);
            }
        }
        if (!VCFFileDownloader.stringIsEmpty(ouNameString)) {
            try {
                vcfCard.add("org", ouNameString);
            } catch (downloadErr) {
                Log.error("Error while setting the OUName of the vCard => " + downloadErr);
            }
        }
        /**
         * Nur wenn wirklich *alle* Parameter der Adresse leer sind exportieren wir gar nichts. Ansonsten schreiben wir die leeren Strings in den Export, damit die anderen Adressparameter in der vCard landen
         */
        if (
            !(
                VCFFileDownloader.stringIsEmpty(ouStreetString) &&
                VCFFileDownloader.stringIsEmpty(ouCityString) &&
                VCFFileDownloader.stringIsEmpty(ouAreacodeString) &&
                VCFFileDownloader.stringIsEmpty(ouCountryString)
            )
        ) {
            try {
                vcfCard.add(
                    "adr",
                    ";;" +
                        ouStreetString +
                        ";" +
                        ouCityString +
                        ";;" +
                        ouAreacodeString +
                        ";" +
                        ouCountryString,
                );
            } catch (downloadErr) {
                Log.error("Error while setting the Address of the vCard => " + downloadErr);
            }
            if (!VCFFileDownloader.stringIsEmpty(userHomepageString)) {
                try {
                    vcfCard.add("url", userHomepageString);
                } catch (downloadErr) {
                    Log.error("Error while setting the Homepage of the vCard => " + downloadErr);
                }
            }
            if (!VCFFileDownloader.stringIsEmpty(descriptionString)) {
                try {
                    vcfCard.add("note", descriptionString);
                } catch (downloadErr) {
                    Log.error("Error while setting the Description of the vCard => " + downloadErr);
                }
            }

            const finalVCard = JSON.parse(JSON.stringify(vcfCard.toString(vCardVersion)));
            Log.debug(
                "VCF-FILE DOWNLOADER => The Following vCard will be send to the Downloader: " +
                    finalVCard,
            );
            VCFFileDownloader.downloadFile(finalVCard);
        }
    }
}
