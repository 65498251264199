import * as d3 from "d3";
import "./ProgressMeter.less";
import { ProgressArc } from "./ProgressArc";
import { ProgressFeedback } from "../../util/progress/ProgressFeedback";

export class ProgressMeter {
    private width: number;

    private height: number;

    private svg: any;

    private outerProgressArc: ProgressArc;

    private innerProgressArc: ProgressArc;

    private totalProgressPercentage = 0;

    private subProgressPercentage = 0;

    private useInnerProgress = false;

    constructor(progressFeedback: ProgressFeedback) {
        progressFeedback.onProgress(this.totalProgress.bind(this));
    }

    public show(parentId: string): void {
        this.width = window.innerWidth;
        this.height = window.innerHeight;

        this.svg = d3
            .select("#" + parentId)
            .append("svg")
            .attr("id", "synccircle")
            .attr("width", this.width)
            .attr("height", this.height)
            .append("g")
            .attr("transform", "translate(" + this.width / 2 + "," + this.height / 2 + ")");

        this.outerProgressArc = new ProgressArc(this.svg, "outer", 0, true);
        if (this.useInnerProgress) {
            this.innerProgressArc = new ProgressArc(this.svg, "inner", 20, false);
        }
    }

    private totalProgress(totalProgressPercentage: number, subProgressPercentage: number): void {
        if (
            totalProgressPercentage != this.totalProgressPercentage &&
            !isNaN(totalProgressPercentage)
        ) {
            this.totalProgressPercentage = totalProgressPercentage;
            this.outerProgressArc.aprogress(totalProgressPercentage);
        }
        if (
            this.useInnerProgress &&
            subProgressPercentage != this.subProgressPercentage &&
            !isNaN(subProgressPercentage)
        ) {
            this.subProgressPercentage = subProgressPercentage;
            this.innerProgressArc.aprogress(subProgressPercentage);
        }
    }
}
