import { Entity } from "../../../entities/Entity";
import { Schema } from "../../../common/schema/Schema";
import { IdGenerator } from "../../../util/text/IdGenerator";
import { TravelSection } from "./TravelSection";
import { Allowance } from "./Allowance";
import { EntityValue } from "../../../entities/values/EntityValue";
import { TypeSubtypeDefinition } from "../../../common/schema/TypeSubtypeDefinition";
import { TravelDays } from "./TravelDays";
import { AttributeDefinition } from "../../../common/schema/AttributeDefinition";
import { DateTimeValue } from "../../../entities/values/DateTimeValue";
import { AllowanceState } from "./AllowanceState";
import { VoucherAllowance } from "./VoucherAllowance";
import { RootAllowance } from "./RootAllowance";
import { AllowanceRecordingTerms } from "../AllowanceRecordingTerms";
import { DateValue } from "../../../entities/values/DateValue";

export class BusinessTravel implements Allowance, RootAllowance {
    public static readonly TYPE = "JAllowance";

    public static readonly SUBTYPE = "businessTravel";

    public static readonly USER_OID = "allowanceUserOid";

    public static readonly ACCOUNTING_MONTH = "allowanceAccountingMonth";

    public static readonly START_DATE_TIME = "allowanceStartTime";

    public static readonly END_DATE_TIME = "allowanceEndTime";

    public static readonly TRAVEL_COUNTRY = "allowanceTravelCountry";

    public static readonly TRAVEL_DESTINATION = "allowanceTravelDestination";

    public static readonly STATE = "allowanceState";

    public static readonly EDITABLE_STATES = [
        AllowanceState.CREATED,
        AllowanceState.REQUESTED,
        AllowanceState.AUTHORIZED,
    ];

    /** Spesenerfassungsmodalitäten (Spesenaufgaben, Abrechenbarkeiten, Belegarten, ...) */
    private allowanceRecordingTerms: AllowanceRecordingTerms;

    private allowanceTypeSubtypeDefinitions: { [key: string]: TypeSubtypeDefinition } = {};

    private allowanceEntity: Entity;

    private travelSections: TravelSection[] = [];

    private travelSectionsById: { [key: string]: TravelSection } = {};

    /** Merken der gelöschten Belegspese, damit beim Speichern ggf. deren Dateianhänge gelöscht werden können */
    private deleteVoucherAllowanceIds: string[] = [];

    public static create(
        allowanceRecordingTerms: AllowanceRecordingTerms,
        schema: Schema,
        userOid: string,
    ): BusinessTravel {
        const typeSubtypeDefinition = schema.getTypeSubtypeDefinition(
            BusinessTravel.TYPE,
            BusinessTravel.SUBTYPE,
        );

        const businessTravelValueObject = {
            oid: IdGenerator.createId() + "_" + BusinessTravel.TYPE,
            typ: BusinessTravel.TYPE,
            subtyp: BusinessTravel.SUBTYPE,
        };

        const now = DateTimeValue.roundedDownNow(5);

        businessTravelValueObject[BusinessTravel.USER_OID] = userOid;
        businessTravelValueObject[BusinessTravel.START_DATE_TIME] = now.getISODate();
        businessTravelValueObject[BusinessTravel.END_DATE_TIME] = now.getISODate();
        businessTravelValueObject[BusinessTravel.ACCOUNTING_MONTH] = now
            .getDateValue()
            .getISODate();

        // Reiseland mit Arbeitsland der Benutzes vorbelegen (obwohl für Dienstreise nicht relevant)
        businessTravelValueObject[BusinessTravel.TRAVEL_COUNTRY] =
            allowanceRecordingTerms.getWorkCountry();

        const businessTravel = new BusinessTravel(
            allowanceRecordingTerms,
            schema,
            businessTravelValueObject,
            true,
        );
        businessTravel.createTravelSection();

        return businessTravel;
    }

    constructor(
        allowanceRecordingTerms: AllowanceRecordingTerms,
        schema: Schema,
        allowanceValueObject: object,
        isNew = false,
    ) {
        this.allowanceRecordingTerms = allowanceRecordingTerms;

        // Spesen-Typ/Subtyp-Definitionen merken, um Unter-Spesen erstellen zu können
        schema.getSubtypes(BusinessTravel.TYPE).forEach((subtype) => {
            this.allowanceTypeSubtypeDefinitions[subtype] = schema.getTypeSubtypeDefinition(
                BusinessTravel.TYPE,
                subtype,
            );
        });
        this.allowanceTypeSubtypeDefinitions[TravelDays.SUBTYPE] = schema.getTypeSubtypeDefinition(
            TravelDays.TYPE,
            TravelDays.SUBTYPE,
        );

        const typeSubtypeDefinition = this.allowanceTypeSubtypeDefinitions[BusinessTravel.SUBTYPE];

        this.allowanceEntity = new Entity(typeSubtypeDefinition, allowanceValueObject, isNew);

        const travelSectionsObjects = allowanceValueObject["[travelSections]"] || [];
        for (let i = 0; i < travelSectionsObjects.length; i++) {
            const travelSectionsObject = travelSectionsObjects[i];

            const travelSection = new TravelSection(
                this,
                allowanceRecordingTerms,
                this.allowanceTypeSubtypeDefinitions,
                travelSectionsObject,
                this.getUserOid(),
            );
            this.travelSections.push(travelSection);
            this.travelSectionsById[travelSection.getId()] = travelSection;
        }
    }

    public getId(): string {
        return this.allowanceEntity.getId();
    }

    public getSubtype(): string {
        return BusinessTravel.SUBTYPE;
    }

    public getUserOid(): string {
        return this.allowanceEntity.getString(BusinessTravel.USER_OID);
    }

    public getTravelDestination(): string {
        return this.allowanceEntity.getString(BusinessTravel.TRAVEL_DESTINATION);
    }

    /**
     * @return Liste aller Resiseabschnitte
     */
    public getTravelSections(): TravelSection[] {
        return this.travelSections;
    }

    /**
     * @param allowanceOid Oid einer Reisespese bzw. einer Belegspese (eines beliebigen Reiseabschnitts)
     * @return Reisespese bzw. Belegspese (eines beliebigen Reiseabschnitts)
     */
    public getSectionById(dailyAllowanceOid: string): TravelSection {
        return this.travelSectionsById[dailyAllowanceOid];
    }

    public createTravelSection(): TravelSection {
        const newTravelSection = TravelSection.create(
            this,
            this.allowanceRecordingTerms,
            this.allowanceTypeSubtypeDefinitions,
            this.getUserOid(),
        );

        // Werte des vorheriges Reiseabschnitts übernehmen
        if (this.travelSections.length > 0) {
            newTravelSection.applyValuesFromPrecedingTravelSection(
                this.travelSections[this.travelSections.length - 1],
            );
        }
        newTravelSection.setDefaultTravelDestination(
            this.getValue(BusinessTravel.TRAVEL_DESTINATION),
            this.travelSections.length,
        );

        this.travelSections.push(newTravelSection);
        this.travelSectionsById[newTravelSection.getId()] = newTravelSection;
        return newTravelSection;
    }

    public deleteTravelSection(deleteTravelSection: TravelSection): void {
        const pos = this.travelSections.indexOf(deleteTravelSection);
        if (pos >= 0) {
            if (this.travelSections.length <= 1) {
                throw new Error("BusinessTravel::CannotDeleteLastTravelSection");
            }

            // Start-/End-Datum/Uhrzeit ab zu löschen Reiseabschnitt wird auf eine Dauer von 0 gesetzt,
            // danach die Start-/End-Datum/Uhrzeiten der Vorgängen-/Nachfolger-REiseabschnitt neu berechnet,
            // so dass nach dem Löschen des Reiseabschnits keine zeitliche Lücke entsteht.
            if (pos == 0) {
                // Falls erster Reiseabschnitt gelöscht wird
                deleteTravelSection.setEndDateTime(deleteTravelSection.getStartDateTime());
            } else {
                // Falls mittlerer oder letzter Reiseabschnitt gelöscht wird
                deleteTravelSection.setStartDateTime(deleteTravelSection.getEndDateTime());
            }
            this.reArrangeTravelSectionStartEnd(deleteTravelSection);

            // Merken der gelöschten Belegspesen, damit beim Speichern ggf. deren Dateianhänge gelöscht werden können
            deleteTravelSection
                .getVoucherAllowances()
                .forEach((voucher) => this.deleteVoucherAllowanceIds.push(voucher.getId()));

            this.travelSections.splice(pos, 1);
            delete this.travelSectionsById[deleteTravelSection.getId()];
        }
    }

    public deleteVoucherAllowance(deleteVoucherAllowance: VoucherAllowance): void {
        // Merken der gelöschten Belegspese, damit beim Speichern ggf. deren Dateianhänge gelöscht werden können
        this.deleteVoucherAllowanceIds.push(deleteVoucherAllowance.getId());

        this.travelSections.forEach((travelSection) =>
            travelSection.deleteVoucherAllowance(deleteVoucherAllowance),
        );
    }

    /**
     * @param allowanceOid Oid einer Reisespese bzw. einer Belegspese (eines beliebigen Reiseabschnitts)
     * @return Reisespese bzw. Belegspese (eines beliebigen Reiseabschnitts)
     */
    public getSubAllowanceById(allowanceOid: string): Allowance {
        if (this.getId() == allowanceOid) {
            return this;
        }

        let subAllowance = null;
        this.travelSections.forEach(
            (travelSection) =>
                (subAllowance = subAllowance || travelSection.getSubAllowanceById(allowanceOid)),
        );
        return subAllowance;
    }

    public countVocherAllowances(): number {
        return this.travelSections.reduce(
            (sum, travelSection) => sum + travelSection.getVoucherAllowances().length,
            0,
        );
    }

    public getVoucherAllowanceIds(): string[] {
        const voucherAllowanceIds: string[] = [];

        this.getTravelSections().forEach((travelSection) => {
            travelSection.getVoucherAllowances().forEach((voucherAllowance) => {
                voucherAllowanceIds.push(voucherAllowance.getId());
            });
        });

        return voucherAllowanceIds;
    }

    /**
     * @returns Ids der gelöschten Belegspesen
     */
    public getDeletedVoucherAllowanceIds(): string[] {
        return this.deleteVoucherAllowanceIds;
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return this.allowanceEntity.getTypeSubtypeDefinition().getAttributeDefinition(name);
    }

    public getValue(name: string): EntityValue {
        return this.allowanceEntity.getValue(name);
    }

    public setValue(name: string, value: EntityValue): void {
        switch (name) {
            case BusinessTravel.START_DATE_TIME:
                this.setStartDateTime(<DateTimeValue>value);
                break;
            case BusinessTravel.END_DATE_TIME:
                this.setEndDateTime(<DateTimeValue>value);
                break;
            case BusinessTravel.TRAVEL_DESTINATION:
                // Belegt noch undefinierte Reisezwecke der Reiseabschnitt mit dem Reisezweck der Dienstreise vor.
                for (let s = 0; s < this.travelSections.length; s++) {
                    this.travelSections[s].setDefaultTravelDestination(value, s);
                }
                this.allowanceEntity.setValue(name, value);
            default:
                this.allowanceEntity.setValue(name, value);
                break;
        }
    }

    public getState(): AllowanceState {
        return <AllowanceState>this.allowanceEntity.getString(BusinessTravel.STATE);
    }

    public getStartDateTime(): DateTimeValue {
        return <DateTimeValue>this.allowanceEntity.getValue(BusinessTravel.START_DATE_TIME);
    }

    public getEndDateTime(): DateTimeValue {
        return <DateTimeValue>this.allowanceEntity.getValue(BusinessTravel.END_DATE_TIME);
    }

    setStartDateTime(startDateTime: DateTimeValue): void {
        this.allowanceEntity.setValue(BusinessTravel.START_DATE_TIME, startDateTime);

        // Abrechnungsmonat in der App immer gleich Startdatum setzen (sofern nicht vom Benutzer abweichend eingegeben)
        if (
            startDateTime
                .getDateValue()
                .isEqual(<DateValue>this.allowanceEntity.getValue(BusinessTravel.ACCOUNTING_MONTH))
        ) {
            this.allowanceEntity.setValue(
                BusinessTravel.ACCOUNTING_MONTH,
                startDateTime.getDateValue(),
            );
        }
    }

    setEndDateTime(endDateTime: DateTimeValue): void {
        this.allowanceEntity.setValue(BusinessTravel.END_DATE_TIME, endDateTime);
    }

    /**
     * Aufgerufen, wenn an einem Reiseabschnitt Start- bzw. End-Datum/Uhrzeit geändert wurde.
     *
     * Ggf. wird Start/Ende aller vorherigen und nachfolgenden Reiseabschnitte sowie der Dienstreise angepasst.
     *
     * Start/Ende aller vorherigen Abschnitte werden so geändert, dass die kleiner/gleich dem Start dieses Abschnitts sind,
     * Start/Ende aller nachfolgenden Abschnitte werden so geändert, dass die größer/gleich dem Ende dieses Abschnitts sind.
     *
     * Es enstehen so immer Reiseabsachnitte ohne zeitliche Lücke.
     * In Extremfällen entstehen so Reiseabschnitte mit Start-Datum/Uhrzeit == Ende-Datum/Uhrzeit.
     */
    public reArrangeTravelSectionStartEnd(travelSection: TravelSection): void {
        // Nummer geänderten Abschnitts
        const sectionNo = this.travelSections.indexOf(travelSection);

        // Start/Ende aller vorherigen Abschnitte werden so geändert, dass die gleich dem Start dieses Abschnitts sind.
        let succeedingStartDateTime = travelSection.getStartDateTime();
        for (let i = sectionNo - 1; i >= 0; i--) {
            const section = this.travelSections[i];
            if (!section.getEndDateTime().equals(succeedingStartDateTime)) {
                section.setEndDateTime(succeedingStartDateTime);
            }
            if (section.getStartDateTime().after(section.getEndDateTime())) {
                section.setStartDateTime(section.getEndDateTime());
            }
            succeedingStartDateTime = section.getStartDateTime();
        }

        // Start/Ende aller nachfolgenden Abschnitte werden so geändert, dass die gleich dem Ende dieses Abschnitts sind.
        let precedingEndDateTime = travelSection.getEndDateTime();
        for (let i = sectionNo + 1; i < this.travelSections.length; i++) {
            const section = this.travelSections[i];

            if (!section.getStartDateTime().equals(precedingEndDateTime)) {
                section.setStartDateTime(precedingEndDateTime);
            }
            if (section.getEndDateTime().before(section.getStartDateTime())) {
                section.setEndDateTime(section.getStartDateTime());
            }
            precedingEndDateTime = section.getEndDateTime();
        }

        // Start/Ende der Dienstreise an frühesten/spätesten Abschnitt anpassen.
        this.setStartDateTime(succeedingStartDateTime);
        this.setEndDateTime(precedingEndDateTime);
    }

    public hasAmout(): boolean {
        return true;
    }

    public isEditable(): boolean {
        return this.isDeletable();
    }

    public isDeletable(): boolean {
        return BusinessTravel.EDITABLE_STATES.indexOf(this.getState()) >= 0;
    }

    public getEntityIdTree(): object {
        return {
            businessTravel: this.getId(),
            SECTIONS: this.getTravelSections().map((section) => section.getEntityIdTree()),
        };
    }

    public toValueObject(onlyWithAmount: boolean = false): object {
        const allowanceValueObject = this.allowanceEntity.toValueObject();

        const travelSectionValueObjects = [];
        for (let i = 0; i < this.travelSections.length; i++) {
            const travelSection = this.travelSections[i];
            travelSectionValueObjects.push(travelSection.toValueObject(onlyWithAmount));
        }
        allowanceValueObject["[travelSections]"] = travelSectionValueObjects;

        return allowanceValueObject;
    }

    /**
     * @returns Warnung durch letzte Attributänderungen (seit letzter Abfrage)
     */
    public getAndClearChangeWarningKey(): string {
        return null;
    }
}
