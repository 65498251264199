import { GUIElement } from "../GUIElement";
import { ListRowField } from "./ListRowField";
import { ListRowFieldTable } from "./ListRowFieldTable";
import { ListRowTextLabel } from "./ListRowTextLabel";
import { ListRowContext } from "./ListRowContext";
import { ListField } from "./ListField";
import { ListRowHTMLLabel } from "./ListRowHTMLLabel";
import { ListRowCompoundField } from "./ListRowCompoundField";
import { ListRowButton } from "./ListRowButton";

export class ListRowElementFactory {
    public static createRowRowElement(
        listRowContext: ListRowContext,
        elementDefinition: object,
    ): ListField {
        const className = elementDefinition["class"];
        const fieldDefinition = elementDefinition["field"];
        if (elementDefinition["link"]) {
            fieldDefinition["isLink"] = true;
        }

        switch (className) {
            case "label":
                return new ListRowTextLabel(listRowContext, fieldDefinition);
            case "htmlLabel":
                return new ListRowHTMLLabel(listRowContext, fieldDefinition);
            case "field":
                return new ListRowField(listRowContext, fieldDefinition);
            case "compound":
                return new ListRowCompoundField(listRowContext, fieldDefinition);
            case "table":
                return new ListRowFieldTable(listRowContext, fieldDefinition);
            case "button":
                return new ListRowButton(listRowContext, fieldDefinition);
            default:
                throw new Error("[ListRowElementFactory] Unknow display type: " + className);
        }
    }
}
