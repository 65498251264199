import { ConfigNode } from "../../core/Config/ConfigNode";
import { BookingsPerDay } from "../../domain/time_recording/bookings/BookingsPerDay";
import { BoardElement } from "../../gui/board/BoardElement";
import { CalendarControll } from "../../gui/content/CalendarControll";
import { GUIContext } from "../../gui/GUIContext";

/* <BoardElement PosX= "1" PosY= "5" SizeX= "6" SizeY="8" Name="overviewCalendar">
					<CalendarControll I18nHeaderDatePattern="MobileApp.overviewBoard.calendarControll.headerDatePattern"/>
				</BoardElement> */

/**
 * Kalender-Boardelement:
 * - stellt die Tage des aktuellen Monats dar
 */
export class CalendarOverviewBoardElement extends BoardElement {
    static readonly ELEMENT_NAME: string = "overviewCalendar";

    private bookingsPerDay: BookingsPerDay;

    constructor(
        boardElementConfig: ConfigNode,
        context: GUIContext,
        bookingsPerDay: BookingsPerDay,
    ) {
        super(boardElementConfig, context);

        this.name = CalendarOverviewBoardElement.ELEMENT_NAME;

        this.xTiles = boardElementConfig.getIntAttribute("PosX", 1);
        this.yTiles = boardElementConfig.getIntAttribute("PosY", 5);
        this.widthTiles = boardElementConfig.getIntAttribute("SizeX", 6);
        this.heightTiles = boardElementConfig.getIntAttribute("SizeY", 8);

        this.wholeContainerLinksTo = null;
        this.styleClasses.push(this.name);

        this.bookingsPerDay = bookingsPerDay;
    }

    protected initializeChildren() {
        this.context.setPossibleContentHeight(
            this.currentTileHeight * this.heightTiles - this.CSS_ELEMENT_MARGIN * 2,
        );
        this.guiElements = [
            new CalendarControll(
                this.configNode.getChildByTagOrEmpty(CalendarControll.BCS_COMPONENT_NAME),
                this.context,
                this.bookingsPerDay,
            ),
        ];
    }
}
