import { FormField, formFieldChangeEventType, FormFieldMode } from "./FormField";
import { FormFieldAddOn } from "./FormFieldAddOn";
import { GUIElement } from "../GUIElement";
import { OidValue } from "../../entities/values/OidValue";
import { StringField } from "./StringField";
import { StringValue } from "../../entities/values/StringValue";

export class OidField implements GUIElement, FormField {
    private stringField: StringField;

    private emptyValueName: string;

    private changeCallback: (changeEvent: formFieldChangeEventType) => void;

    constructor() {
        this.stringField = new StringField()
            .setType("number")
            .setMode(FormFieldMode.DISPLAY)
            .onChange(this.stringValueChanged.bind(this));
    }

    public setEntityId(entityId: string): FormField {
        this.stringField.setEntityId(entityId);
        return this;
    }

    public setReadOnly(readOnly: boolean): FormField {
        this.stringField.setReadOnly(readOnly);
        return this;
    }

    public isReadOnly(): boolean {
        return this.stringField.isReadOnly();
    }

    public setName(name: string): OidField {
        this.stringField.setName(name);
        return this;
    }

    public getName(): string {
        return this.stringField.getName();
    }

    /**
     *
     * Achtung hat eine starke Abhänigkeit zu get- und setPlaceholder()
     */
    public setValue(value: OidValue): OidField {
        const stringValue = new StringValue(value.getNameOrDefault(this.emptyValueName));
        this.stringField.setValue(stringValue);
        return this;
    }

    public setLabel(label: string): OidField {
        this.stringField.setLabel(label);
        return this;
    }

    /**
     *
     * Achtung hat eine starke Abhänigkeit zu get- und setValue()
     */
    public setPlaceholder(placeholder: string): OidField {
        this.emptyValueName = placeholder;
        this.stringField.setPlaceholder(placeholder);
        return this;
    }

    public setMode(mode: FormFieldMode): FormField {
        // OidField wird immer im Display-Modus dargestellt. Über Link sollte Auswahl-Seite geöffnet werden.
        return this;
    }

    public getMode(): FormFieldMode {
        return this.stringField.getMode();
    }

    public registerFormFieldAddOn(formFieldAddOn: FormFieldAddOn): FormField {
        // untetstützt bislang bislang keine AddOns
        return this;
    }

    public onChange(changeCallback: (changeEvent: formFieldChangeEventType) => void): OidField {
        this.changeCallback = changeCallback;
        return this;
    }

    public compose($parent: JQuery): void {
        this.stringField.compose($parent);
    }

    public updateValue(value: OidValue): OidField {
        const stringValue = new StringValue(value.getNameOrDefault(this.emptyValueName));
        this.stringField.updateValue(stringValue);
        return this;
    }

    public getValue(): OidValue {
        return new OidValue(this.stringField.getValue().getString());
    }

    private stringValueChanged(changeEvent: formFieldChangeEventType): void {
        if (this.changeCallback) {
            this.changeCallback({
                entityId: changeEvent.entityId,
                name: this.getName(),
                value: this.getValue(),
            });
        }
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}
