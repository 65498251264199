import * as moment from "moment";
import { EntityValue } from "./EntityValue";
import { BCSDate } from "../../common/BCSDate";

export class DateValue implements EntityValue {
    private value: string;

    // TODO App - Alle Aufrufe dieser Funktion mit einem ISODate müssen den Konstruktor benutzen!
    public static parseFromString(strValue: string) {
        // TODO parse  locale date string using i18n (z.B. "11.3.2017")
        return new DateValue(strValue);
    }

    public static fromDate(date: Date): DateValue {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return new DateValue(
            year + "-" + (month <= 9 ? "0" : "") + month + "-" + (day <= 9 ? "0" : "") + day,
        );
    }

    public static today(): DateValue {
        return DateValue.fromDate(new Date());
    }

    constructor(simpleValue: string) {
        if (simpleValue) {
            // Falls ISO-Datum eine Zeitangabe enthält, darf es nur UTC 00:00 sein
            const idx = simpleValue.indexOf("T");
            if (idx > 0) {
                const timeSuffix = simpleValue.substring(idx);
                if (timeSuffix != "T00:00:00Z" && timeSuffix != "T00:00:00.000Z") {
                    throw new Error("DateValue::InvalidISODate " + simpleValue);
                }
                simpleValue = simpleValue.substring(0, idx);
            }
        }

        this.value = simpleValue;
    }

    public isDefined(): boolean {
        return !!this.value;
    }

    public getSimpleValue(): string | number | boolean {
        return this.value;
    }

    public getString(): string {
        return this.value ? this.value : "";
    }

    public getISODate(): string {
        return this.value;
    }

    public getISODateTime(): string {
        return this.value + "T00:00:00.000Z";
    }

    public getDate(): Date {
        if (!this.isDefined()) {
            return null;
        }

        let resultDate;
        if (this.value.indexOf(".") !== -1) {
            resultDate = BCSDate.getDateFromStampDisplay(this.value).getDate();
        } else {
            resultDate = new Date(this.value + "T00:00:00");
        }
        return resultDate;
    }

    public getBCSDate(): BCSDate {
        return this.isDefined() ? new BCSDate(new Date(this.value + "T00:00:00")) : null;
    }

    // TODO App - Alle Aufrufe sollten die Locale des eingeloggten Users übergeben, wenn sie z.B. den Wochentag-Namen ausgeben wollen
    public format(pattern: string, userLocale: string = "de"): string {
        return new BCSDate(this.getDate()).format(pattern, userLocale);
    }

    // TODO App - Alle Aufrufe sollten die Locale des eingeloggten Users übergeben, wenn sie z.B. den Wochentag-Namen ausgeben wollen
    public formatLongDate(userLocale: string = "de"): string {
        return new BCSDate(this.getDate()).formatLongDate(userLocale);
    }

    /**
     * @param userLocale Sprache des eingeloggten Benutzers
     * @return Formatiertes Datum im Langformat, z.B. für Locale "de" = "24.12." / "en" = "12/24"
     */
    public formatShortDate(userLocale: string = "de"): string {
        return new BCSDate(this.getDate()).formatShortDate(userLocale);
    }

    public isBefore(other: DateValue): boolean {
        return moment(this.getISODate()).isBefore(moment(other.getISODate()));
    }

    public isAfter(other: DateValue): boolean {
        return moment(this.getISODate()).isAfter(moment(other.getISODate()));
    }

    public isSameOrBefore(other: DateValue): boolean {
        return moment(this.getISODate()).isSameOrBefore(moment(other.getISODate()));
    }

    public isSameOrAfter(other: DateValue): boolean {
        return moment(this.getISODate()).isSameOrAfter(moment(other.getISODate()));
    }

    public isEqual(other: DateValue): boolean {
        return moment(this.getISODate()).isSame(moment(other.getISODate()));
    }

    public compare(other: DateValue): number {
        const thisTime = this.isDefined() ? this.getDate().getTime() : 0;
        const otherTime = other.isDefined() ? other.getDate().getTime() : 0;
        return thisTime - otherTime;
    }

    public addDays(numberOfDays: number): DateValue {
        return new DateValue(
            moment(this.getISODateTime()).utc().add(numberOfDays, "day").toISOString(),
        );

        //AppConsole.log("(A) " + this.getISODateTime() + " +" + numberOfDays + " = " + moment(this.getISODateTime()).add(numberOfDays, "day").toISOString());
        //let v = new DateValue(moment(this.getISODateTime()).utc().add(numberOfDays, "day").toISOString());
        //AppConsole.log("(B) " + this.getISODateTime() + " +" + numberOfDays + " = " + v.getISODate());
        //return v;
    }

    public static dateValues(startDate: DateValue, endDate: DateValue): DateValue[] {
        const dates: DateValue[] = [];

        let day = startDate;
        while (day.isSameOrBefore(endDate)) {
            dates.push(day);
            day = day.addDays(1);
        }

        return dates;
    }
}
