import { Preferences } from "./Preferences";
import { IdGenerator } from "../../util/text/IdGenerator";
import { BooleanValue } from "../../entities/values/BooleanValue";
import { Exception } from "@zxing/library";
import { AttributeDefinition } from "../schema/AttributeDefinition";

export class ClientPreferences extends Preferences {
    constructor(preferenceObject?: object) {
        super();

        let preferenceValues = null;
        if (preferenceObject) {
            preferenceValues = preferenceObject;
        } else {
            preferenceValues = {
                oid: IdGenerator.createId() + "__AppPreferences",
                typ: "AppPreferences",
                subtyp: "*",
                postFilterTaskList: BooleanValue.parseFromString("true"),
                contactDownload: BooleanValue.parseFromString("true"),
                settingsOverwritten: BooleanValue.parseFromString("false"),
            };
        }
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        throw new Exception("not implemented");
    }
}
