import { Entity } from "../../../entities/Entity";
import { Schema } from "../../../common/schema/Schema";
import { IdGenerator } from "../../../util/text/IdGenerator";
import { DateValue } from "../../../entities/values/DateValue";
import { TimesheetEntry } from "./TimesheetEntry";
import { AttributeDefinition } from "../../../common/schema/AttributeDefinition";
import { BCSDate } from "../../../common/BCSDate";
import { StringValue } from "../../../entities/values/StringValue";
import { EntityValue } from "../../../entities/values/EntityValue";
import { DurationValue } from "../../../entities/values/DurationValue";

export class Appointment implements TimesheetEntry {
    private appointmentEntity: Entity;

    constructor(schema: Schema, timesheetObject?: object) {
        const typeSubtypeDefinition = schema.getTypeSubtypeDefinition("TimesheetEntry", "Events");

        let timesheetValues = null;
        if (timesheetObject) {
            timesheetValues = timesheetObject;
        } else {
            timesheetValues = {
                oid: IdGenerator.createId() + "_TimesheetEntry",
                typ: "TimesheetEntry",
                subtyp: "Events",
            };
        }
        this.appointmentEntity = new Entity(typeSubtypeDefinition, timesheetValues);
        this.appointmentEntity.scanWithPrefix(timesheetValues, "task");

        this.extractPspPath(this.appointmentEntity.getValue("pspPath").getString());
    }

    public getId(): string {
        return this.appointmentEntity.getString("bookableElement__oid");
    }

    public getTaskId(): string {
        return this.appointmentEntity.getString("task__oid");
    }

    public getSubtype(): string {
        return this.appointmentEntity.getString("subtyp");
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return this.appointmentEntity.getTypeSubtypeDefinition().getAttributeDefinition(name);
    }

    public getBookableObjectValue(name: string): string {
        return this.appointmentEntity.getString(name);
    }

    /**
     *
     *
     */
    public getTargetSubtype(): string {
        return this.appointmentEntity.getString("bookableElement__subtyp");
    }

    public getName() {
        return this.appointmentEntity.getString("bookableElement__name");
    }

    public isFulltimmEvent(): boolean {
        if (this.appointmentEntity.getBoolean("isFullTimeEvent") == null) {
            return false;
        } else return this.appointmentEntity.getBoolean("isFullTimeEvent");
    }

    public getTaskName() {
        return this.appointmentEntity.getString("task__name");
    }

    public getTask() {
        return this.appointmentEntity.getString("task__oid");
    }

    public toValueObject(): object {
        return this.appointmentEntity.toValueObject();
    }

    public getStartTime(): number {
        return this.appointmentEntity.getNumber("defaultRecordStartTime");
    }

    public getStartDateTime(): Date {
        const dateValue = <DateValue>this.appointmentEntity.getValue("defaultEventStartDate");
        if (this.isFulltimmEvent()) {
            return dateValue.getDate();
        }
        const bcsDate = new BCSDate(dateValue.getDate()).setMinutes(this.getStartTime());
        return bcsDate.getDate();
    }

    public getEndTime(): number {
        return this.appointmentEntity.getNumber("defaultRecordEndTime");
    }

    public getEndDateTime(): Date {
        const dateValue = <DateValue>this.appointmentEntity.getValue("defaultEventEndDate");
        if (this.isFulltimmEvent()) {
            return dateValue.getDate();
        }
        const bcsDate = new BCSDate(dateValue.getDate()).setMinutes(this.getEndTime());
        return bcsDate.getDate();
    }

    public getDuration() {
        return this.getEndTime() - this.getStartTime();
    }

    /**
     * @param name Aufgaben-Attributename
     * @return Aufgaben-Attributswert
     */
    public getValue(name: string): EntityValue {
        if (name === "grandParentName" || name === "parentName") {
            return this.appointmentEntity.getValue(name);
        }
        const sameNameTaskAttributes = ["name", "oid", "subtype"];
        if (sameNameTaskAttributes.indexOf(name) !== -1) {
            return this.appointmentEntity.getValue("task__" + name);
        } else {
            return this.appointmentEntity.getValue(name);
        }
    }

    public setRemainingEffort(value: number) {
        const durationValue = new DurationValue(value);

        this.appointmentEntity.setValue("task__remainingDuration", durationValue);
    }

    public getDisplayPSPPath() {
        return (
            this.getGrandParentName() + " / " + this.getParentName() + " / " + this.getTaskName()
        );
    }

    public getGrandParentName(): string {
        return this.appointmentEntity.getValue("grandParentName").getString();
    }

    public getParentName(): string {
        return this.appointmentEntity.getValue("parentName").getString();
    }

    /**
     * TODO: Jens, das muss nicht jeder TimeSheetEntry nochmal mitbringen
     * @param rawPspPath
     */
    private extractPspPath(rawPspPath: string): void {
        const pathArray: [string] = JSON.parse(rawPspPath);
        pathArray.forEach((pspRawEntry, index) => {
            const pspEntry: { isGrandParent: boolean; name: string } = JSON.parse(pspRawEntry);

            if (pspEntry.isGrandParent) {
                this.setGrandParentName(pspEntry.name);
            }

            if (index === pathArray.length - 1) {
                this.setParentName(pspEntry.name);
            }
        });
    }

    private setGrandParentName(grandParentName: string): void {
        this.appointmentEntity.setValue("grandParentName", new StringValue(grandParentName));
    }

    private setParentName(parentName: string): void {
        this.appointmentEntity.setValue("parentName", new StringValue(parentName));
    }
}
