import { GUIContext } from "../gui/GUIContext";
import { I18n } from "../common/i18n/I18n";
import { ToolBar } from "../gui/navigation/ToolBar";
import { GUIElement } from "../gui/GUIElement";
import { ToolLink } from "../gui/navigation/ToolLink";
import { FixedGUIElement } from "../gui/FixedGUIElement";
import { SubMenu } from "../gui/content/SubMenu";
import { NavigationBar } from "../gui/navigation/NavigationBar";
import { Animation, AppNavigator } from "../core/Router";
import { ConfigNode } from "../core/Config/ConfigNode";
import { BoardGUIDefinitions } from "../core/Config/BoardGUIDefinitions";

export type FooterTabIcon = {
    iconName?: string;
    active?: boolean;
    callback?: Function;
    isComponent: boolean;
    config?: ConfigNode;
    syncBadgeText?: string;
    isSVG: boolean;
};

export class FooterTabBar implements GUIElement, FixedGUIElement {
    public static ICON_OVERVIEW = "index";

    public static ICON_STOPWATCH = "stopwatch";

    public static ICON_NOTIFICATIONS = "notifications";

    public static ICON_SYNC = "sync";

    public static ICON_MORE = "more";

    private guiContext: GUIContext;

    private i18n: I18n;

    private iconNames: string[] = [];

    private FooterTabIcons: { [key: string]: FooterTabIcon } = {};

    private footerToolBar: ToolBar;

    private navigator: AppNavigator;

    private toolLinks: { [key: string]: ToolLink } = {};

    private countUnsyncedElements: number;

    constructor(guiContext: GUIContext) {
        this.guiContext = guiContext;
        this.i18n = guiContext.getI18n();
    }

    public async composeDefaultFooter(
        currentPageName: string,
        navigator: AppNavigator,
        navigateBackParameters: { [key: string]: string } = {},
        countUnsyncedElementsFunction?: () => Promise<number>,
    ): Promise<FooterTabBar> {
        this.navigator = navigator;

        this.countUnsyncedElements = await countUnsyncedElementsFunction();

        this.addIcon(
            FooterTabBar.ICON_OVERVIEW,
            FooterTabBar.ICON_OVERVIEW == currentPageName,
            null,
            true,
            () => {
                navigator.navigateTo("index", {}, Animation.SLIDE_RIGHT);
            },
        );

        this.addIcon(
            FooterTabBar.ICON_SYNC,
            FooterTabBar.ICON_SYNC == currentPageName,
            this.countUnsyncedElements > 0 ? this.countUnsyncedElements.toString() : "",
            true,
            () => {
                navigator.navigateTo("sync", navigateBackParameters, Animation.SLIDE_LEFT);
            },
        );

        this.addComponent(BoardGUIDefinitions.getMoreFooterTabDefault(), null);

        return this;
    }

    public addIcon(
        iconName: string,
        active: boolean = false,
        syncBadgeText: string = null,
        isSVG: boolean = false,
        callback?: Function,
    ): FooterTabBar {
        if (active && callback) {
            // Klick auf Icon für Link zur aktuellen Seite braucht nicht ausgeführt zu werden
            callback = () => {};
        }

        this.iconNames.push(iconName);

        this.FooterTabIcons[iconName] = {
            iconName: iconName,
            active: active,
            callback: callback,
            isComponent: false,
            syncBadgeText: syncBadgeText,
            isSVG: isSVG,
        };
        return this;
    }

    public addComponent(footerTabConfig: ConfigNode, syncBadgeText?: string): FooterTabBar {
        const iconName = footerTabConfig.getName();
        this.iconNames.push(iconName);
        this.FooterTabIcons[iconName] = {
            iconName: iconName,
            active: false,
            isComponent: true,
            config: footerTabConfig,
            syncBadgeText: syncBadgeText,
            isSVG: true,
        };
        return this;
    }

    /**
     * HTML-DOM generieren
     *
     * @param $parentTag Parent-jQuery-Objekt
     */
    public compose($parentTag: JQuery): void {
        const self = this;
        this.footerToolBar = new ToolBar().setId("footer_toolbar");
        for (let i = 0; i < this.iconNames.length; i++) {
            const quickLinkIcon = this.FooterTabIcons[this.iconNames[i]];

            if (quickLinkIcon.isComponent) {
                const configNode = quickLinkIcon.config;
                const childrenNodes = configNode.getChildren();
                for (let j = 0; j < childrenNodes.length; j++) {
                    const currentChildNode = childrenNodes[j];
                    if (currentChildNode.getNodeName() === "SubMenu") {
                        const iconName = this.getImageName(quickLinkIcon);
                        this.footerToolBar.addToolLink(
                            new ToolLink()
                                .setImageName(iconName, quickLinkIcon.isSVG)
                                .setId(quickLinkIcon.iconName + "_toollink")
                                .setBadgeText(quickLinkIcon.syncBadgeText)
                                .setText(
                                    this.i18n.get("MobileApp.footerbar." + quickLinkIcon.iconName),
                                )
                                .onClick(() => {
                                    self.openSubMenu(
                                        $("#" + quickLinkIcon.iconName + "_toollink"),
                                        currentChildNode,
                                    );
                                }),
                        );
                    }
                }
            } else {
                const iconName = this.getImageName(quickLinkIcon);
                let activeStyle: string;
                if (iconName.includes("active")) {
                    activeStyle = "active";
                } else {
                    activeStyle = "normal"; // wird zur Zeit nicht verwendet, aber sonst wäre der Style null, das erschien mir unschön
                }
                this.toolLinks[this.iconNames[i]] = new ToolLink()
                    .setStyleClass(activeStyle)
                    .setId("FooterTabBar_" + iconName)
                    .setImageName(iconName, quickLinkIcon.isSVG)
                    .setBadgeText(quickLinkIcon.syncBadgeText)
                    .setText(this.i18n.get("MobileApp.footerbar." + quickLinkIcon.iconName))
                    .onClick(() => {
                        self.quickLinkClicked(quickLinkIcon.iconName);
                    });
                this.footerToolBar.addToolLink(this.toolLinks[this.iconNames[i]]);
            }
        }

        this.composeRevision($parentTag);

        this.footerToolBar.compose($parentTag);
    }

    private composeRevision($parentTag: JQuery): void {
        // let appRevision = $("html").attr("data-revision") || "?";
        let serverRevision = this.i18n.getRevision() || "?";
        // appRevision = appRevision.replace(/bcs/, "").replace(/\-/, "");
        serverRevision = serverRevision.replace(/bcs/, "").replace(/\-/, "");

        // let revisionInfo = appRevision == serverRevision ? serverRevision : appRevision + " " + serverRevision;

        this.footerToolBar.setMiniLabel(serverRevision);
    }

    private getImageName(quickLinkIcon: FooterTabIcon): string {
        let iconName = quickLinkIcon.iconName + "_icon_normal";
        if (quickLinkIcon.active === true) {
            iconName = quickLinkIcon.iconName + "_icon_active";
        }
        return iconName;
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }

    /**
     * @return true, wenn GUIElement fixiert, false wenn nicht (für Elemente, die sowohl fixiert als auch mitscrollend sein können)
     */
    public isFixed(): boolean {
        return true;
    }

    /**
     * @return Höhe des fixierten GUIElementes (in px) nach dem alle GUIElemente der Seite gerendert wurden
     */
    public getFixedHeight(): number {
        return this.footerToolBar.getFixedHeight();
    }

    /**
     * Setzt Y-Position des fixierten GUIELementes (absoluten Abstand zum oberen bzw. unteren Rand).
     *
     * @param verticalPositionKey "top" oder "bottom" für Header bzw. Footer
     * @param verticalPos Y-Position vom oberen bzw. unteren Rand (z.B. 15 für "15px")
     */
    public setVerticalPosition(verticalPositionKey: string, verticalPos: number): void {
        this.footerToolBar.setVerticalPosition(verticalPositionKey, verticalPos);
    }

    public async updateSyncBadgeCounter(
        iconName: string,
        countUnsyncedElementsFunction?: () => Promise<number>,
    ): Promise<void> {
        const newCountUnsyncedElements = await countUnsyncedElementsFunction();
        if (this.countUnsyncedElements != newCountUnsyncedElements) {
            this.countUnsyncedElements = newCountUnsyncedElements;
            this.toolLinks[iconName].updateIconBadge(
                this.countUnsyncedElements > 0 ? this.countUnsyncedElements.toString() : "",
            );
        }
    }

    private quickLinkClicked(iconName: string): void {
        if (this.FooterTabIcons[iconName].callback) {
            this.FooterTabIcons[iconName].callback();
        } else {
            this.guiContext.triggerEvent(
                NavigationBar.BCS_COMPONENT_NAME,
                NavigationBar.ICON_CLICKED_TRIGGER,
                null,
                this.FooterTabIcons[iconName],
            );
        }
    }

    /**
     * Aufruf nachdem der Link "..." zum Öffnen des Sonstiges-Menüs angeklickt wurde.
     *
     * @param clickedElement
     * @param submenuNode
     */
    private openSubMenu(clickedElement: JQuery, submenuNode: ConfigNode) {
        const transferObject = { clickedElement: clickedElement, submenuNode: submenuNode };

        if (this.navigator) {
            // Standard-Footer (bekommt einen Navigator zum Aufruf anderer Seiten)
            SubMenu.openSubMenu(transferObject, this.i18n, this.subMenuItemClicked.bind(this));
        } else {
            // Generischer Kontext, der den GUIContext zum triggern von Events verwendet
            this.guiContext.triggerEvent(
                SubMenu.BCS_COMPONENT_NAME,
                "openSubmenu",
                null,
                transferObject,
            );
        }
    }

    /**
     * Aufruf nachdem ein Menüpunkt aus dem Sonstiges-Menüs angeklickt wurde.
     *
     * @param action Aktion
     * @param navigationContext Enthält u.a. Pfad und Parameter einer aufzurufenden Seite
     */
    private subMenuItemClicked(action: string, navigationContext?: any): void {
        const path = navigationContext.navigate;
        const parameters = navigationContext.parameters || {};

        switch (action) {
            case "logout":
                parameters["logout"] = "true";
            // fall through
            case "navigate":
                this.navigator.navigateTo(path, parameters, Animation.SLIDE_RIGHT);
                break;
        }
    }
}
