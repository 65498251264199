import { TimeRecord } from "../TimeRecord";
import { TimeSpan } from "../timespans/TimeSpan";
import { BCSDate } from "../../../common/BCSDate";
import { DummyBooking } from "../DummyBooking";

export class AttendanceBookingStructure {
    private bookings: TimeRecord[] = [];
    private miscellaneousEfforts: TimeRecord[] = [];

    private sumOfBookings: number = 0;
    private bookingOnMiscellaneousSum: number = 0;

    private attendance: TimeSpan;

    constructor(givenAttendance: TimeSpan) {
        this.attendance = givenAttendance;
    }

    public hasOnlyDuration(): boolean {
        return this.attendance.hasDurationOnly();
    }

    public isInStartEndTime(booking: TimeRecord): boolean {
        const attendanceStart: BCSDate = new BCSDate(this.attendance.getStartTime());
        const attendanceEnd: BCSDate = new BCSDate(this.attendance.getEndTime());
        const bookingStart: BCSDate = new BCSDate(booking.getStartTime());
        const bookingEnd: BCSDate = new BCSDate(booking.getEndTime());

        /**
         * Es muss mindestens Start oder Ende in der Anwesenheit liegen.
         *
         */
        const startIsInAttendance =
            (attendanceStart.isBefore(bookingStart) ||
                attendanceStart.isSameHourAndMinute(bookingStart)) &&
            (attendanceEnd.isAfter(bookingStart) ||
                attendanceEnd.isSameHourAndMinute(bookingStart));

        const endIsInAttendance =
            (attendanceStart.isBefore(bookingEnd) ||
                attendanceStart.isSameHourAndMinute(bookingEnd)) &&
            (attendanceEnd.isAfter(bookingEnd) || attendanceEnd.isSameHourAndMinute(bookingEnd));

        if (startIsInAttendance || endIsInAttendance) {
            return true;
        }

        return false;
    }

    /**
     * Gibt zurück, ob die Dauer der Anwesenheit bereits mit Buchungen ausgefüllt ist.
     *
     * Wurde eingeführt für Anwesenheiten ohne Start und Endzeit, sondern nur mit Dauer.
     *
     * @param booking: TimeRecord
     */
    public isNotAlreadyFull(booking: TimeRecord): boolean {
        const maximalDuration = this.attendance.getEffortExpense();

        if (this.sumOfBookings + booking.getEffortExpense() > maximalDuration) {
            return false;
        }
        return true;
    }

    public addBooking(booking: TimeRecord): AttendanceBookingStructure {
        if (booking.isMiscellaneousEffort()) {
            this.bookingOnMiscellaneousSum += booking.getEffortExpense();
            this.insertSortedBooking(booking, this.miscellaneousEfforts);
        } else {
            this.sumOfBookings += booking.getEffortExpense();
            this.insertSortedBooking(booking, this.bookings);
        }
        return this;
    }

    public getChunkedBookings(): [TimeRecord[]] {
        const bookingsLength = this.bookings.length;
        let currentChunk: TimeRecord[] = [];
        const result: [TimeRecord[]] = [currentChunk];
        for (let i = 0; i < bookingsLength; i++) {
            if (i == 0) {
                currentChunk.push(this.bookings[i]);
            } else {
                const first = this.bookings[i - 1];
                const sec = this.bookings[i];
                if (
                    Math.abs(
                        new BCSDate(first.getEndTime()).minuteDiff(new BCSDate(sec.getStartTime())),
                    ) > 0
                ) {
                    const newChunk: TimeRecord[] = [];
                    newChunk.push(sec);
                    result.push(newChunk);
                    currentChunk = newChunk;
                } else {
                    currentChunk.push(this.bookings[i]);
                }
            }
        }

        return result;
    }

    public getBookings(): TimeRecord[] {
        return this.bookings;
    }

    public getBookingsWithDummys(): TimeRecord[] {
        // Wenn der Aufwand der Anwesenheit großer ist, als die Summe aller Buchungen, dann gibt es mindestens einen Bereich ohne Buchung.
        if (this.attendance.getEffortExpense() > this.sumOfBookings) {
            this.enrichBookingsWithNotBookedTime();
        }

        return this.bookings;
    }

    public getDayBookingSum(): number {
        return this.sumOfBookings;
    }

    public getDayMiscellaneousBookingSum(): number {
        return this.bookingOnMiscellaneousSum;
    }

    public getAttendance(): TimeSpan {
        return this.attendance;
    }

    public hasDurationOnly(): boolean {
        return this.attendance.hasDurationOnly();
    }

    public toString() {
        let resultString = "";
        for (let i = 0; i < this.bookings.length; i++) {
            resultString += "\t" + this.bookings[i].toString() + "\n";
        }
        return resultString;
    }

    private insertSortedBooking(booking: TimeRecord, bookingArray: TimeRecord[]) {
        const bookingsLength = bookingArray.length;
        if (bookingsLength == 0) {
            bookingArray[0] = booking;
        } else {
            const inserted = false;
            for (let i = 0; i < bookingsLength; i++) {
                if (
                    new BCSDate(bookingArray[i].getStartTime()).isAfter(
                        new BCSDate(booking.getStartTime()),
                    )
                ) {
                    bookingArray.splice(i, 0, booking);
                    return;
                }
            }
            // falls wir oben in der Schleife klein größeres Element gefunden haben, dann fügen wir das neue Element hinten ein.
            bookingArray.push(booking);
        }
    }

    private enrichBookingsWithNotBookedTime() {
        const hasBookings = this.bookings.length > 0;

        if (!hasBookings) {
            if (this.hasDurationOnly()) {
                var duration = this.getAttendance().getEffortExpense();
                var dummyTimeRecord = new DummyBooking(
                    null,
                    null,
                    duration,
                    this.getAttendance().getDate(),
                );
                this.insertSortedBooking(dummyTimeRecord, this.bookings);
            } else {
                var duration = this.getAttendance().getEffortExpense();
                var dummyTimeRecord = new DummyBooking(
                    this.getAttendance().getStartTime(),
                    this.getAttendance().getEndTime(),
                    duration,
                    this.getAttendance().getDate(),
                );
                this.insertSortedBooking(dummyTimeRecord, this.bookings);
            }
        } else {
            if (this.hasDurationOnly()) {
                var duration = this.getAttendance().getEffortExpense() - this.sumOfBookings;
                var dummyTimeRecord = new DummyBooking(
                    null,
                    null,
                    duration,
                    this.getAttendance().getDate(),
                );
                this.insertSortedBooking(dummyTimeRecord, this.bookings);
            } else {
                const firstTime = this.getAttendance().getStartTime();
                const endTime = this.getAttendance().getEndTime();
                const bookingsLength = this.bookings.length;

                const dummyBooking = this.getTimeSpanBetween(
                    firstTime,
                    this.bookings[0].getStartTime(),
                );
                const dummyBookings = [];
                if (dummyBooking.getEffortExpense() > 0) {
                    dummyBookings.push(dummyBooking);
                }

                for (var i = 0; i < bookingsLength; i++) {
                    if (i >= bookingsLength - 1) {
                        break;
                    }

                    const middleDummyBooking = this.getTimeSpanBetween(
                        this.bookings[i].getEndTime(),
                        this.bookings[i + 1].getStartTime(),
                    );
                    if (middleDummyBooking.getEffortExpense() > 0) {
                        dummyBookings.push(middleDummyBooking);
                    }
                }

                const endDummyBooking = this.getTimeSpanBetween(
                    this.bookings[bookingsLength - 1].getEndTime(),
                    endTime,
                );

                if (endDummyBooking.getEffortExpense() > 0) {
                    dummyBookings.push(endDummyBooking);
                }
                for (var i = 0; i < dummyBookings.length; i++) {
                    this.insertSortedBooking(dummyBookings[i], this.bookings);
                }
            }
        }
    }

    private getTimeSpanBetween(endDate: Date, startDate: Date): DummyBooking {
        const duration: number = new BCSDate(endDate).minuteDiff(new BCSDate(startDate));
        const dummyTimeRecord = new DummyBooking(endDate, startDate, duration, startDate);
        return dummyTimeRecord;
    }
}
