import { BCSDate } from "../../common/BCSDate";
import { ConfigNode } from "../../core/Config/ConfigNode";
import { TimeRecordingOptions } from "../../domain/time_recording/TimeRecordingOptions";
import { BoardElement } from "../../gui/board/BoardElement";
import { TimeRecordingTacho } from "../../gui/content/TimeRecordingTacho";
import { GUIContext } from "../../gui/GUIContext";

/* <BoardElement PosX= "1" PosY= "1" SizeX= "3" SizeY="4" Name="currentTimeRecordingDetails"  WholeContainerLinksTo="timeRecordingOverview">
					<TimeRecordingTacho Name="timeRecordingTacho" DatePattern="DD.MM."/>
                    </BoardElement> */

/**
 * Tacho-Boardelement:
 * - zeigt das heute Datum sowie den Tacho für die heutige Zeit-/Anwesenheitserfassung an
 */
export class CurrentTimeRecordingDetailsBoardElement extends BoardElement {
    static readonly ELEMENT_NAME: string = "currentTimeRecordingDetails";

    private timeRecordingOptions: TimeRecordingOptions;
    private useAttendances: boolean;

    constructor(
        boardElementConfig: ConfigNode,
        context: GUIContext,
        timeRecordingOptions: TimeRecordingOptions,
        useAttendances: boolean,
    ) {
        super(boardElementConfig, context);

        this.name = CurrentTimeRecordingDetailsBoardElement.ELEMENT_NAME;

        this.xTiles = boardElementConfig.getIntAttribute("PosX", 1);
        this.yTiles = boardElementConfig.getIntAttribute("PosY", 1);
        this.widthTiles = boardElementConfig.getIntAttribute("SizeX", 3);
        this.heightTiles = boardElementConfig.getIntAttribute("SizeY", 4);

        this.wholeContainerLinksTo = boardElementConfig.getAttribute(
            "WholeContainerLinksTo",
            "timeRecordingOverview",
        );
        this.styleClasses.push(this.name);

        this.timeRecordingOptions = timeRecordingOptions;
        this.useAttendances = useAttendances;
    }

    protected initializeChildren() {
        this.context.setPossibleContentHeight(
            this.currentTileHeight * this.heightTiles - this.CSS_ELEMENT_MARGIN * 2,
        );
        this.guiElements = [
            new TimeRecordingTacho(
                this.configNode.getChildByTagOrEmpty(TimeRecordingTacho.BCS_COMPONENT_NAME),
                this.context,
                this.timeRecordingOptions,
                this.useAttendances,
                BCSDate.today(),
                TimeRecordingTacho.Layout.Board,
            ),
        ];
    }
}
