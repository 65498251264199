import { GUIElement } from "../GUIElement";
import { FlexItem } from "./FlexItem";

/**
 * CSS-FlexBox
 *
 * Bietet Convenience-Funktionen zum Setzen des CSS-Eigenschaften.
 *
 * https://css-tricks.com/snippets/css/a-guide-to-flexbox/
 */
export class FlexBox implements GUIElement {
    /** Id zur Markierung des Root-DOM-Elements */
    private domId: string;

    /** Ob an der Box/Div 100% Width dran geschrieben wird. Dies ist für Ausrichtungen nicht immer sinnvoll. Default: true*/
    private isUseingFullWidth: boolean = true;

    /** Ausrichtung als Reihe/Spalte: flex-direction = row | row-reverse | column | column-reverse */
    private flexDirection: FlexDirection = FlexDirection.ROW;

    /** Umbruch der Items: flex-wrap = nowrap | wrap | wrap-reverse */
    private flexWrap: FlexWrap;

    /** Links/Mitte/Rechts-Bündigkeit der Items: justify-content = flex-start | flex-end | center | space-between | space-around | space-evenly */
    private justifyContent: FlexJustifyContent;

    /** Platz über/zwischen/unter den Reihen */
    private alignContent: FlexAlignContent;

    /** Oben/Mitte/Unten-Bündigkeit der Items: align-items = flex-start | flex-end | center | baseline | stretch */
    private alignItems: FlexAlignItems;

    /**
     * wenn true, dann wird die FlexBox-Breite gleichmäßig auf alle FlexItems verteilt
     * wenn false, sind alle FlexItems so breit wie ihr Inhalt.
     */
    private stretchFlexItems: boolean;

    /** Flex-Items */
    private flexItems: FlexItem[] = [];

    private styleClasses: string[] = [];

    private $flexContainerTag: JQuery;

    private clickCallback: Function;

    private customAttributes: { attributeName: string; value: string }[] = [];

    public setDOMId(domId: string): FlexBox {
        this.domId = domId;
        return this;
    }

    /**
     * Ausrichtung der Items
     *
     * @param flexDirection row | row-reverse | column | column-reverse
     */
    public setFlexDirection(flexDirection: FlexDirection): FlexBox {
        this.flexDirection = flexDirection;
        return this;
    }

    /**
     * Umbruch der Items
     *
     * @param flexWrap nowrap | wrap | wrap-reverse
     */
    public setFlexWrap(flexWrap: FlexWrap): FlexBox {
        this.flexWrap = flexWrap;
        return this;
    }

    /**
     * Links/Mitte/Rechts-Bündigkeit der Items
     *
     * @param justifyContent flex-start | flex-end | center | space-between | space-around | space-evenly
     */
    public setJustifyContent(justifyContent: FlexJustifyContent): FlexBox {
        this.justifyContent = justifyContent;
        return this;
    }

    /**
     * Platz über/zwischen/unter den Reihen.
     *
     * @param alignContent flex-start | flex-end | center | strecht | space-between | space-around
     */
    public setAlignContent(alignContent: FlexAlignContent): FlexBox {
        this.alignContent = alignContent;
        return this;
    }

    /**
     * Oben/Mitte/Unten-Bündigkeit der Items
     *
     * @param alignItems flex-start | flex-end | center | baseline | stretch
     */
    public setAlignItems(alignItems: FlexAlignItems): FlexBox {
        this.alignItems = alignItems;
        return this;
    }

    /**
     * @param stretchFlexItems wenn true, dann wird die FlexBox-Breite gleichmäßig auf alle FlexItems verteilt
     *                         wenn false, sind alle FlexItems so breit wie ihr Inhalt.
     */
    public setStretchFlexItems(stretchFlexItems: boolean): FlexBox {
        this.stretchFlexItems = stretchFlexItems;
        return this;
    }

    /**
     * @param flexItem Flex-Item
     */
    public addFlexItem(flexItem: FlexItem): FlexBox {
        this.flexItems.push(flexItem);
        return this;
    }

    /**
     * @return Flex-Item
     */
    public newFlexItem(): FlexItem {
        const flexItem = new FlexItem();
        this.flexItems.push(flexItem);
        return flexItem;
    }

    public addStyleClass(styleClass: string): FlexBox {
        this.styleClasses.push(styleClass);
        return this;
    }

    public onClick(clickCallback: Function): FlexBox {
        this.clickCallback = clickCallback;
        return this;
    }

    public useFullWidth(isUseingFullWidth: boolean): FlexBox {
        this.isUseingFullWidth = isUseingFullWidth;
        return this;
    }

    /**
     * HTML-DOM generieren
     *
     * @param $parent Parent-jQuery-Objekt
     */
    public compose($parent: JQuery): void {
        const cssMap = {
            display: "flex",
            "flex-direction": this.flexDirection,
            "flex-wrap": this.flexWrap,
            "justify-content": this.justifyContent,
            "align-content": this.alignContent,
            "align-items": this.alignItems,
        };

        if (this.isUseingFullWidth) {
            cssMap["width"] = "100%";
            cssMap["box-sizing"] = "border-box";
        }

        this.$flexContainerTag = $("<div>")
            .attr("id", this.domId)
            .addClass(this.styleClasses.join(" "))
            .appendTo($parent)
            .css(cssMap);

        this.customAttributes.forEach((a) => this.$flexContainerTag.attr(a.attributeName, a.value));

        for (let i = 0; i < this.flexItems.length; i++) {
            if (this.stretchFlexItems) {
                this.flexItems[i].setFlexBasis(Math.floor(100 / this.flexItems.length) + "%");
            }
            this.flexItems[i].compose(this.$flexContainerTag);
        }

        if (this.clickCallback) {
            this.$flexContainerTag.on("click", this.clickCallback.bind(this));
        }
    }

    getComponentChildren(): GUIElement[] {
        return this.flexItems;
    }

    /**
     * @return JQuery-DOM-Element der FlexBox (z.B. zum nachträglichen ändern von CSS-Eigenschaften)
     */
    public getFlexBoxTag(): JQuery {
        return this.$flexContainerTag;
    }

    public remove(): void {
        if (this.$flexContainerTag) {
            this.$flexContainerTag.remove();
            this.$flexContainerTag = null;
        }
    }

    // TODO App Rename setAtttribute -> setAttribute
    public setAtttribute(attributeName: string, value: string): FlexBox {
        if (typeof this.$flexContainerTag !== "undefined") {
            this.$flexContainerTag.attr(attributeName, value);
        }
        this.customAttributes.push({
            attributeName: attributeName,
            value: value,
        });
        return this;
    }
}

/** Ausrichtung als Reihe/Spalte */
export const enum FlexDirection {
    ROW = "row",
    ROW_REVERSE = "row-reverse",
    COLUMN = "column",
    COLUMN_REVERSE = "column-reverse",
}

/** Umbruch innerhalb */
export const enum FlexWrap {
    NOWRAP = "nowrap",
    WRAP = "wrap",
    WRAP_REVERSE = "wrap-reverse",
}

/** Links/Mitte/Rechts-Bündigkeit der Items */
export const enum FlexJustifyContent {
    FLEX_START = "flex-start",
    FLEX_END = "flex-end",
    CENTER = "center",
    SPACE_BETWEEN = "space-between",
    SPACE_AROUND = "space-around",
    SPACE_EVENLY = "space-evenly",
}

/** Platz über/zwischen/unter den Reihen */
export const enum FlexAlignContent {
    FLEX_START = "flex-start",
    FLEX_END = "flex-end",
    CENTER = "center",
    STRETCH = "stretch",
    SPACE_BETWEEN = "space-between",
    SPACE_AROUND = "space-around",
}

/** Oben/Mitte/Unten-Bündigkeit der Items */
export const enum FlexAlignItems {
    FLEX_START = "flex-start",
    FLEX_END = "flex-end",
    CENTER = "center",
    BASELINW = "baseline",
    STRETCH = "stretch",
}
