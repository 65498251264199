import { HttpRESTClient } from "../../util/http/HttpRESTClient";
import { RESTSaveResult } from "../../sync/RESTSaveResult";
import { BCSFile } from "./records/BCSFile";

export class FileClient {
    public async sendFileToBCS(file: BCSFile): Promise<RESTSaveResult> {
        const result = await new HttpRESTClient()
            .createRequest()
            .setMethod("PUT")
            .addPath("/filestore/files/" + file.getFileComponentOid() + "/" + file.getId())
            .setParameter("filename", file.getFilename())
            .setBinary(file.getContentAsBinary(), file.getContentType())
            .send();
        return new RESTSaveResult(result);
    }

    public async deleteFileInBCS(fileId: string): Promise<RESTSaveResult> {
        const result = await new HttpRESTClient()
            .createRequest()
            .setMethod("DELETE")
            .addPath("/filestore/files/" + fileId)
            .send();
        return new RESTSaveResult(result);
    }
}
