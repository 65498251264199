import { Component } from "../Component";
import { MessageEntity } from "./MessageEntity";
import { Registry } from "../Registry";

/**
 * Fragen:
 * - Soll MessagePool lieber ein Singelton sein, um Nachrichten über mehrere Seiten erhalten zu können.
 *   - Nachrichten müssten dann wieder raus genommen werden.
 *   - Speicher Aktions Feedback geht oft über mehrere Seiten
 *   - Server Infos sollten auf allen Seiten zu sehen sein?
 *     - Zeigen wir Server Infos an? Der Server ist von 14:00 - 18:00 nicht erreichbar wegen Wartungen?
 *
 *
 */
export class MessagePool implements Component {
    /** Symbolischer Name dieser Komponente */
    public static BCS_COMPONENT_NAME = "MessagePool";

    private messages: MessageEntity[];

    constructor() {
        this.messages = [];
    }

    public getDependencyNames(): string[] {
        return [];
    }

    public init(depencencyComponents: { [key: string]: Component }): void {}

    public addMessage(newMessage: MessageEntity): void {
        this.messages.push(newMessage);
    }

    public getMessages(): MessageEntity[] {
        return this.messages;
    }

    public getMessage(messageEntity) {
        for (let i = 0; i < this.messages.length; i++) {
            const currentMessage: MessageEntity = this.messages[i];
            if (currentMessage.equals(messageEntity)) {
                return currentMessage;
            }
        }
    }

    public removeMessage(messageEntity: MessageEntity) {
        let foundAndRemoved = false;
        for (let i = 0; i < this.messages.length; i++) {
            const currentMessage: MessageEntity = this.messages[i];
            if (currentMessage.equals(messageEntity)) {
                this.messages.splice(i, 1);
                foundAndRemoved = true;
            }
        }
        return foundAndRemoved;
    }

    public cleanAffirmationMessages() {
        for (let i = 0; i < this.messages.length; i++) {
            const currentMessage: MessageEntity = this.messages[i];
            if (!currentMessage.isSticky()) {
                this.messages.splice(i, 1);
            }
        }
    }
}
Registry.registerComponent(MessagePool.BCS_COMPONENT_NAME, MessagePool);
