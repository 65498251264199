import "./ContactList.less";
import { FooterTabBar } from "../../../../board/FooterTabBar";
import { UserSession } from "../../../../common/auth/UserSession";
import { ServerConfigProperties } from "../../../../common/config/ServerConfigProperties";
import { I18n } from "../../../../common/i18n/I18n";
import { Component } from "../../../../core/Component";
import { ConfigNode } from "../../../../core/Config/ConfigNode";
import { Controller } from "../../../../core/Controller";
import { Registry } from "../../../../core/Registry";
import { Animation, AppNavigator } from "../../../../core/Router";
import { SubMenu } from "../../../../gui/content/SubMenu";
import { TextLabel } from "../../../../gui/content/TextLabel";
import { GUIEventPool } from "../../../../gui/event/GUIEventPool";
import { GUIContext } from "../../../../gui/GUIContext";
import { GUIElement } from "../../../../gui/GUIElement";
import { GUIPage } from "../../../../gui/GUIPage";
import { ListView } from "../../../../gui/list/ListView";
import { ListViewContext } from "../../../../gui/list/ListViewContext";
import { FloatingActionButton } from "../../../../gui/navigation/FloatingActionButton";
import { ToolBar } from "../../../../gui/navigation/ToolBar";
import { ToolLink } from "../../../../gui/navigation/ToolLink";
import { SyncStateManager } from "../../../../sync/SyncStateManager";
import { Contact } from "../../Contact";
import { ContactManager } from "../../ContactManager";
import { ContactGUIDefinitions } from "../ContactGUIDefinitions";
import { ContactOverviewListViewModel } from "../ContactOverviewListViewModel";
import { MessageEntity, MessageType } from "../../../../core/Message/MessageEntity";
import { TypeAheadSearchView } from "../../../../gui/list/TypeAheadSearchView";
import { Log } from "../../../../common/log/Log";

/**
 * Kontaktliste, welche eine Übersicht aus allen erfassten Kontakten zusammenbaut.
 */
export class ContactListController implements Controller {
    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static BCS_COMPONENT_NAME = "contactList";

    private navigator: AppNavigator;

    private animation: Animation;

    private i18n: I18n;

    private syncStateManager: SyncStateManager;

    private serverConfigProperties: ServerConfigProperties;

    private page: GUIPage;

    private footerTabBar: FooterTabBar;

    private contactManager: ContactManager;

    private contactGUIDefinitions: ContactGUIDefinitions;

    private userSession: UserSession;

    private eventPool: GUIEventPool;

    private context: GUIContext;

    private contacts: Contact[] = [];

    private message: MessageEntity[] = [];

    private typeAheadSearchView: TypeAheadSearchView;

    public getDependencyNames(): string[] {
        return [
            I18n.BCS_COMPONENT_NAME,
            ContactManager.BCS_COMPONENT_NAME,
            SyncStateManager.BCS_COMPONENT_NAME,
            ServerConfigProperties.BCS_COMPONENT_NAME,
        ];
    }

    public registerEvents() {
        this.eventPool.registerEventListener(
            SubMenu.BCS_COMPONENT_NAME,
            "openSubmenu",
            this.openSubMenu.bind(this),
        );
    }

    public init(depencencyComponents: { [key: string]: Component }) {
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.contactManager = <ContactManager>(
            depencencyComponents[ContactManager.BCS_COMPONENT_NAME]
        );
        this.syncStateManager = <SyncStateManager>(
            depencencyComponents[SyncStateManager.BCS_COMPONENT_NAME]
        );
        this.serverConfigProperties = <ServerConfigProperties>(
            depencencyComponents[ServerConfigProperties.BCS_COMPONENT_NAME]
        );
        this.context = new GUIContext(this.i18n, this.eventPool);
        this.contactGUIDefinitions = new ContactGUIDefinitions();
        this.userSession = <UserSession>depencencyComponents[UserSession.BCS_COMPONENT_NAME];
    }

    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        this.navigator = navigator;
        this.animation = animation;

        if (!this.contactManager.getContactTerms().isContactRecordingAllowed()) {
            this.navigator.navigateTo("index", {}, Animation.SLIDE_RIGHT);
        }
        if (parameters["affirmations"]) {
            this.addMessage(
                new MessageEntity(
                    this.i18n.get("MobileApp.contactDeleted.message"),
                    MessageType.AFFIRMATION,
                ),
            );
        }

        this.contactManager
            .readAllContactsFromDB()
            .then((result) => {
                this.contacts = result;
                this.composeList();
            })
            .catch((error) => {
                Log.error("ERROR", error);
            });
    }

    public popState(): void {
        this.navigateUpToIndex();
    }

    public toolLinkNavigateToPage(targetComponentName: string): void {
        this.navigateToPage(null, { navigate: targetComponentName });
    }

    public showSubMenu(event: JQueryEventObject, offset: { left: number; top: number }): void {
        // damit SubMenu nicht gleich wieder geschlosssen wird.
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
        const subMenuNode: ConfigNode = ConfigNode.removeDisabledNodes(
            ContactGUIDefinitions.getSubMenuNode(),
            this.serverConfigProperties.getPropertySet("MobileApp.ContactListSubMenu"),
        );
        const subMenu = new SubMenu(subMenuNode, offset.left, offset.top, 50);
        const subMenuEntries = subMenuNode.getChildren();
        const self: ContactListController = this;

        for (let i = 0; i < subMenuEntries.length; i++) {
            const submenuItemConfigNode = subMenuEntries[i];
            const i18nLabel = submenuItemConfigNode.getAttribute(
                "I18nLabel",
                submenuItemConfigNode.getName(),
            );
            subMenu.addEntry(
                submenuItemConfigNode.getName(),
                (): void => {
                    const navigationPath = submenuItemConfigNode.getAttribute("NavigationPath");
                    const navigationContext: {} = { navigate: navigationPath };
                    self.navigateToPage(null, navigationContext);
                },
                this.i18n.get(i18nLabel),
            );
        }
        subMenu.compose(this.page.getPageDOMElement());
    }

    public destroy(): void {
        // TODO ...
    }

    /**
     * Bisher noch nicht verwendet, soll mal den Upload von Daten aus dem Telefonspeicher ermöglichen.

    public async uploadContact() {

        try {
            const opts = {multiple: true};
            // console.log('Contact Picker Supported');
            // @ts-ignore // Leider behauptet TypeScript noch, der Navigator habe die ContactPicker API nicht, daher der ignore
            let uploadedContacts = await navigator.contacts.select(navigator.contacts.getProperties(), opts);
            if (uploadedContacts.length) {
                uploadedContacts.forEach(upload => {
                    let myvcard = this.vcfParser.parse(upload);
                    this.contactManager.saveMyCardAsContact(myvcard);
                });
                this.navigator.navigateTo(ContactListController.BCS_COMPONENT_NAME, {}, Animation.NONE);
            }
        } catch (uploadErr) {
            Log.error('Uploading your Contact failed with Error: ' + uploadErr);
        }
    } */

    private async composeList(): Promise<void> {
        this.page = new GUIPage(this.context, ContactListController.BCS_COMPONENT_NAME);
        this.page.addStyleClass(ContactListController.BCS_COMPONENT_NAME);
        const headerToolBar = new ToolBar().setId("header_toolbar");

        headerToolBar.addToolLinkLeft(
            new ToolLink()
                .setId("navigateBack")
                .setImageName("icon-chevron-left.svg")
                .onClick(this.popState.bind(this)),
        );
        headerToolBar.setTitle(this.i18n.get("MobileApp.Contacts.List"));
        headerToolBar.addStyleClass("headBar");

        this.page.addPageElement(this.composeContactList());

        this.page.addHeaderElement(headerToolBar);
        this.page.addFixedElement(
            new FloatingActionButton()
                .setImageName("icon-add-white.svg")
                .onClick(this.showSubMenu.bind(this)),
        );

        await this.composeFooterTabBar();
        // Meldung(en) von vorheriger Seite oder nach Aktion anzeigen
        this.page.composeMessages(this.message, new GUIContext(this.i18n, new GUIEventPool()));
        this.message = [];

        this.page
            .setAnimation(this.animation, this.navigator.doShowAnimations())
            .compose($("body"));
    }

    private composeContactList(): GUIElement {
        const listViewContext = new ListViewContext()
            .setI18n(this.i18n)
            .setModel(new ContactOverviewListViewModel(this.contacts, this.i18n));

        const contactListDef = this.serverConfigProperties.customizeGUIDefinitions(
            this.contactGUIDefinitions.getOverviewListViewDef(
                this.serverConfigProperties.returnContactListDef(),
            ),
            ContactListController.BCS_COMPONENT_NAME,
            "contactOverview",
            "display",
        );

        const contactListView = new ListView(listViewContext, contactListDef)
            .setDOMId("contactList")
            .addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW)
            .onRowClicked(this.listRowClicked.bind(this));
        const numberOfRows: number = listViewContext.getListModel().countRows();
        if (numberOfRows == 0) {
            // Setzt das Label, "keine Kontakte erfasst"
            return new TextLabel()
                .setText(this.i18n.get("MobileApp.noContacts"))
                .addStyleClass("textLabelNoContent")
                .addStyleClass("noContacts")
                .addStyleClass("textLabelWithMarginTop");
        } else {
            for (let i = 0; i < numberOfRows; i++) {
                if (
                    listViewContext
                        .getListRowContext(i)
                        .getRowModel()
                        .getValue("syncedDate")
                        .isDefined()
                ) {
                    contactListView.addStyleClass("defaultListView.disabledField.iconShown");
                }
            }
        }
        if (numberOfRows > 7) {
            //Baut die Suche zusammen, wenn die Liste zu groß wird.
            this.typeAheadSearchView = new TypeAheadSearchView()
                .setPlaceholder(this.i18n.get("MobileApp.contactList.search"))
                .setSearchModel(new ContactOverviewListViewModel(this.contacts, this.i18n), [
                    "name",
                    "userFirstname",
                    "ouName",
                ])
                .setResultListView(contactListView);
            this.page.addPageElement(this.typeAheadSearchView);
        }
        return contactListView;
    }

    /**
     * Fußzeile (u.a. mit Home-Link)
     */
    private async composeFooterTabBar(): Promise<void> {
        const self = this;
        this.footerTabBar = new FooterTabBar(new GUIContext(this.i18n));
        await this.footerTabBar.composeDefaultFooter(
            null,
            this.navigator,
            this.getNavigateBackParameters(),
            () => self.syncStateManager.countUnsyncedElements(),
        );
        this.page.addFooterElement(this.footerTabBar);
    }

    private listRowClicked(path: string, parameters: { [key: string]: string }): void {
        this.navigator.navigateTo(path, parameters, Animation.SLIDE_LEFT);
    }

    private navigateUpToIndex(): void {
        this.navigator.navigateTo("index", {}, Animation.SLIDE_RIGHT);
    }

    private getNavigateBackParameters(): { [key: string]: string } {
        return {
            navigateBackPath: ContactListController.BCS_COMPONENT_NAME,
        };
    }

    private openSubMenu(event, transferObject: object) {
        const callback = (action: string, navigationContext?: {}) => {
            if (action === "logout") {
                this.userSession.logout();
                this.navigateToPage(null, navigationContext);
            } else if (action === "navigate") {
                this.navigateToPage(null, navigationContext);
            }
        };

        this.page.openSubMenu(event, transferObject, this.i18n, callback);
    }

    private navigateToPage(clickEvent: Event, clickContext: any): void {
        this.navigator.navigateTo(
            clickContext.navigate,
            clickContext.parameters,
            Animation.SLIDE_LEFT,
        );
    }

    private addMessage(message: MessageEntity): void {
        this.message.push(message);
    }
}

Registry.registerComponent(ContactListController.BCS_COMPONENT_NAME, ContactListController);
