import { Entity } from "../../../entities/Entity";
import { IdGenerator } from "../../../util/text/IdGenerator";
import { Allowance } from "./Allowance";
import { EntityValue } from "../../../entities/values/EntityValue";
import { TypeSubtypeDefinition } from "../../../common/schema/TypeSubtypeDefinition";
import { AttributeDefinition } from "../../../common/schema/AttributeDefinition";
import { TravelSection } from "./TravelSection";
import { DateTimeValue } from "../../../entities/values/DateTimeValue";
import { DailyAllowance } from "./DailyAllowance";
import { TravelAllowance } from "./TravelAllowance";
import { NumberValue } from "../../../entities/values/NumberValue";

export class AccomodationAllowance implements Allowance, TravelAllowance {
    public static readonly TYPE = "JAllowance";

    public static readonly SUBTYPE = "accomodationAllowance";

    public static readonly USER_OID = "allowanceUserOid";

    /** Anzahl privater Übernachtungen */
    public static readonly ACCOMODATION_COUNT = "allowanceCountAccomodations";

    /** Anzahl abrechenbarer privater Übernachtungen */
    public static readonly ACCOMODATION_COUNT_INVOICABLE = "allowanceCountAccomodationsInvoiceable";

    /** Pseudo-Attribute nur zur Verwendung in App: Gibt an, ob Übernachtungen vollständig abrechenbar ist. */
    public static readonly ACCOMODATION_COUNT_IS_FULLY_INVOICABLE =
        "allowanceCountAccomodationsIsFullyInvoiceable";

    /** Reiseabschnitt zu dem diese Übernachtungsspese gehört */
    private travelSection: TravelSection;

    private allowanceEntity: Entity;

    public static create(
        travelSection: TravelSection,
        typeSubtypeDefinition: TypeSubtypeDefinition,
        userOid: string,
        businessTravelOid: string,
    ): AccomodationAllowance {
        const allowanceValueObject = {
            oid: IdGenerator.createId() + "_" + AccomodationAllowance.TYPE,
            typ: AccomodationAllowance.TYPE,
            subtyp: AccomodationAllowance.SUBTYPE,
            allowanceUserOid: userOid,
            allowanceTravelOid: businessTravelOid,
        };

        const dailyAllowance = new AccomodationAllowance(
            travelSection,
            typeSubtypeDefinition,
            allowanceValueObject,
            true,
        );

        return dailyAllowance;
    }

    constructor(
        travelSection: TravelSection,
        typeSubtypeDefinition: TypeSubtypeDefinition,
        allowanceValueObject: object,
        isNew = false,
    ) {
        this.travelSection = travelSection;
        this.allowanceEntity = new Entity(typeSubtypeDefinition, allowanceValueObject, isNew);

        // Sofern Übernachtungen = Abrechenbare Übernachtungen: Flag "Vollständig abrechenbar" setzen
        this.flagAsFullyChargeable();
    }

    public getId(): string {
        return this.allowanceEntity.getId();
    }

    public getSubtype(): string {
        return AccomodationAllowance.SUBTYPE;
    }

    public getUserOid(): string {
        return this.allowanceEntity.getString(AccomodationAllowance.USER_OID);
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return this.allowanceEntity.getTypeSubtypeDefinition().getAttributeDefinition(name);
    }

    public setStartDateTime(startDateTime: DateTimeValue): void {
        this.allowanceEntity.setValue(DailyAllowance.START_DATE_TIME, startDateTime);
    }

    public setEndDateTime(endDateTime: DateTimeValue): void {
        this.allowanceEntity.setValue(DailyAllowance.END_DATE_TIME, endDateTime);
    }

    public getValue(name: string): EntityValue {
        return this.allowanceEntity.getValue(name);
    }

    public setValue(name: string, value: EntityValue): void {
        switch (name) {
            case AccomodationAllowance.ACCOMODATION_COUNT_INVOICABLE:
                // Nach manueller Änderung der abrechenbaren Übernachtungen: Flag "Vollständig abrechenbar" entfernen
                this.allowanceEntity.setBoolean(
                    AccomodationAllowance.ACCOMODATION_COUNT_IS_FULLY_INVOICABLE,
                    false,
                );
                break;
        }

        // Geänderten Wert setzen
        this.allowanceEntity.setValue(name, value);

        switch (name) {
            case AccomodationAllowance.ACCOMODATION_COUNT:
            case AccomodationAllowance.ACCOMODATION_COUNT_IS_FULLY_INVOICABLE:
                // Sofern Flag "Vollständig abrechenbar" gesetzt: immer Abrechenbare Übernachtungen = Übernachtungen setzen
                this.copyAccmodationsToInvoiceableAccmodations();
                break;
        }
    }

    /**
     * Sofern Übernachtungen = Abrechenbare Übernachtungen: Flag "Vollständig abrechenbar" setzen
     */
    private flagAsFullyChargeable(): void {
        const travelDistance = <NumberValue>(
            this.allowanceEntity.getValue(AccomodationAllowance.ACCOMODATION_COUNT)
        );
        const travelDistanceInvoicable = <NumberValue>(
            this.allowanceEntity.getValue(AccomodationAllowance.ACCOMODATION_COUNT_INVOICABLE)
        );
        this.allowanceEntity.setBoolean(
            AccomodationAllowance.ACCOMODATION_COUNT_IS_FULLY_INVOICABLE,
            travelDistance.isEqual(travelDistanceInvoicable),
        );
    }

    /**
     * Sofern Flag "Vollständig abrechenbar" gesetzt: immer Abrechenbare Übernachtungen = Übernachtungen setzen.
     */
    private copyAccmodationsToInvoiceableAccmodations(): void {
        if (
            this.allowanceEntity.getBoolean(
                AccomodationAllowance.ACCOMODATION_COUNT_IS_FULLY_INVOICABLE,
            )
        ) {
            this.allowanceEntity.setValue(
                AccomodationAllowance.ACCOMODATION_COUNT_INVOICABLE,
                this.allowanceEntity.getValue(AccomodationAllowance.ACCOMODATION_COUNT),
            );
        }
    }

    public hasAmout(): boolean {
        const accomationCountValue = this.allowanceEntity.getValue(
            AccomodationAllowance.ACCOMODATION_COUNT,
        );
        return (
            accomationCountValue.isDefined() && (<NumberValue>accomationCountValue).getNumber() > 0
        );
    }

    public isEditable(): boolean {
        return this.travelSection.isEditable();
    }

    public isDeletable(): boolean {
        return false;
    }

    public toValueObject(): object {
        return this.allowanceEntity.toValueObject();
    }

    /**
     * @returns Warnung durch letzte Attributänderungen (seit letzter Abfrage)
     */
    public getAndClearChangeWarningKey(): string {
        return null;
    }
}
