/**
 * Wird verwendet, um bei einem OAuth / OpenID Connect Request (erkennbar an dem Query-Parameter "code")
 * den Query-String vorzuhalten, um damit eine REST-Anfrage für die OAuth-Authentifizierung zu senden.
 */
export const OAUTH_CODE_QUERY = window.location.search;

let isRedirectOAuthRequestPending: boolean =
    OAUTH_CODE_QUERY !== undefined && OAUTH_CODE_QUERY.indexOf("code") !== -1;

/**
 * @returns true, wenn das gerade verarbeitete Request ein OAuth / OpenID Connect Request ist.
 */
export function isOAuthRequestPending(): boolean {
    return isRedirectOAuthRequestPending;
}

/**
 * Setzt den Status zurück, dass gerade ein OAuth-Redirect-Request verarbeitet wird
 */
export function setOAuthRequestPendingOff(): void {
    isRedirectOAuthRequestPending = false;
}
