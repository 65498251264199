import { Schema } from "../../../common/schema/Schema";
import { Entity } from "../../../entities/Entity";
import { OidValue } from "../../../entities/values/OidValue";
import { EntityValue } from "../../../entities/values/EntityValue";
import { AttributeDefinition } from "../../../common/schema/AttributeDefinition";
import { StringValue } from "../../../entities/values/StringValue";

export class ForecastRecord {
    private entity: Entity;

    private displayPath: string;

    constructor(schema: Schema, enityObject?: object) {
        const typeSubtypeDefinition = schema.getTypeSubtypeDefinition(
            "JAnnotation",
            "ForecastExplicitChange",
        );
        this.entity = new Entity(typeSubtypeDefinition, enityObject);
    }

    public getId(): string {
        return this.entity.getId();
    }

    public setId(id: string): void {
        this.entity.setValue("oid", new OidValue(id));
    }

    public getValue(name: string): EntityValue {
        return this.entity.getValue(name);
    }

    public getAttachmentOid(): string {
        return (this.getValue("attachmentComponent") as OidValue).getOid();
    }

    public setValue(name: string, value: EntityValue): void {
        this.entity.setValue(name, value);
    }

    public toTouchedValueObject(touchedFields: string[]): object {
        return this.entity.toTouchedValueObject(touchedFields);
    }

    public toValueObject(): object {
        return this.entity.toValueObject();
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return this.entity.getTypeSubtypeDefinition().getAttributeDefinition(name);
    }

    public setPspDisplayPath(displayPath) {
        this.displayPath = displayPath;
    }

    public getPspDisplayPath(): StringValue {
        return new StringValue(this.displayPath);
    }
}
