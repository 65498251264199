import { empty } from "rxjs";
import { GUIContext } from "../../gui/GUIContext";
import { GUIElement } from "../../gui/GUIElement";
import { BoardGUIDefinitions } from "./BoardGUIDefinitions";
import { PropertySet, ServerConfigProperties } from "../../common/config/ServerConfigProperties";
import { GUIComponentRegistry } from "../../gui/GUIComponentRegistry";

export type configJSONNodeType =
    | { [key: string]: string | number | boolean }
    | { children: configJSONNodeType[] };

export class ConfigNode {
    private static GUIComponentRegistrySingelton: GUIComponentRegistry;

    /**
     * Name des Konfigurationsknotens
     */
    private CONF_NODENAME = "nodeName";

    /**
     * Name der Komponente
     */
    private CONF_NAME = "Name";

    private configJSON: configJSONNodeType[];

    constructor(configJSON: configJSONNodeType[] | Object) {
        this.configJSON = <configJSONNodeType[]>configJSON;
    }

    private isReferingDefaultConfigNode(): boolean {
        return (
            this.configJSON["isReferingDefaultConfigNode"] === "true" ||
            this.configJSON["isReferingDefaultConfigNode"] === true
        );
    }

    /**
     * Name des Knotens, z.B. "Board" oder "BoardElement".
     * Identifiziert die
     *
     * @return Name des Konfigurationsknotens, falls nicht vorhanden null
     */
    public getNodeName(): string {
        if (this.configJSON.hasOwnProperty(this.CONF_NODENAME)) {
            return this.configJSON[this.CONF_NODENAME];
        } else {
            return null;
        }
    }

    public getAttribute(attributeName: string, defaultValue?: any): string {
        //            attributeName = attributeName.toLowerCase();
        if (this.configJSON.hasOwnProperty(attributeName)) {
            return this.configJSON[attributeName];
        } else {
            if (typeof defaultValue !== "undefined") {
                return defaultValue;
            } else {
                return null;
            }
        }
    }

    public setAttribute(attributeName: string, value: string | number | boolean) {
        this.configJSON[attributeName] = value;
    }

    public getIntAttribute(attributeName: string, defaultValue?: number): number {
        const attributeValue = this.getAttribute(attributeName, defaultValue);
        return parseInt(attributeValue);
    }

    public getBooleanAttribute(attributeName: string, defaultValue?: boolean): boolean {
        const attributeValue = this.configJSON[attributeName];
        if (attributeValue !== "undefined") {
            return attributeValue;
        } else {
            if (typeof defaultValue !== "undefined") {
                return defaultValue;
            } else {
                return null;
            }
        }
    }

    /**
     * @return Name der Komponente, falls nicht vorhanden null
     */
    public getName(): string {
        if (this.configJSON.hasOwnProperty(this.CONF_NAME)) {
            return this.configJSON[this.CONF_NAME];
        } else {
            return null;
        }
    }

    public getChildren(nodeName?: string): ConfigNode[] {
        const childrenConfigNodes = [];
        const children = this.configJSON["children"];
        if (children) {
            for (let index = 0; index < children.length; index++) {
                const configNode = new ConfigNode(children[index]);
                if (typeof nodeName !== "undefined") {
                    if (nodeName === configNode.getNodeName()) {
                        childrenConfigNodes.push(configNode);
                    }
                } else {
                    childrenConfigNodes.push(configNode);
                }
            }
        }
        return childrenConfigNodes;
    }

    /**
     *
     * @param name
     */
    public getChildByName(name?: string): ConfigNode {
        let resultConfigNode: ConfigNode = null;
        const children = this.configJSON["children"];
        for (let index = 0; index < children.length; index++) {
            const configNode = new ConfigNode(children[index]);
            if (typeof name !== "undefined") {
                if (name === configNode.getName()) {
                    resultConfigNode = configNode;
                }
            }
        }
        return resultConfigNode;
    }

    /**
     * Entfernt ein Kind aus der ConfigNode, wenn es den Namen erhält. Wird gebraucht, um das SubMenü neu "zusammenzusetzen" (also alles rauszuwerfen, was ned passt).
     * @param removeName der Name der Option (z.B. Tickets) die gekillt werden soll
     * @returns ConfigNode mit einem Element weniger
     */
    public removeChildByNameAndCopy(removeName: string): ConfigNode {
        const children = this.configJSON["children"] || [];
        const filteredChildren = children
            .filter((child: configJSONNodeType) => child[this.CONF_NAME] !== removeName)
            .map((child: configJSONNodeType) => Object.assign({}, child));

        const copiedJSON = Object.assign({}, this.configJSON);
        copiedJSON["children"] = filteredChildren;
        return new ConfigNode(copiedJSON);
    }

    public getChildByTag(name: string): ConfigNode {
        let resultConfigNode: ConfigNode = null;
        const children = this.configJSON["children"];
        for (let index = 0; index < children.length; index++) {
            const configNode = new ConfigNode(children[index]);
            if (name === configNode.getNodeName()) {
                resultConfigNode = configNode;
            }
        }
        return resultConfigNode;
    }

    /**
     *
     * @param name Falls nicht vorhanden, wird eine leere Node mit dem gegebenen Namen zurückgegeben
     */
    public getChildByTagOrEmpty(name: string) {
        let resultConfigNode: ConfigNode = null;

        const children = this.configJSON["children"];
        if (children) {
            for (let index = 0; index < children.length; index++) {
                const configNode = new ConfigNode(children[index]);
                if (name === configNode.getNodeName()) {
                    resultConfigNode = configNode;
                }
            }
        }

        if (resultConfigNode === null) {
            const emptyNode: configJSONNodeType = {};
            emptyNode["name"] = name;
            emptyNode["nodeName"] = name;
            return new ConfigNode(emptyNode);
        } else {
            return resultConfigNode;
        }
    }

    /**
     *Von der AppConfig hierher verschoben
     * @param context
     */
    public getGUIComponent(context: GUIContext): GUIElement {
        let guiRegistry: GUIComponentRegistry = ConfigNode.GUIComponentRegistrySingelton;
        if (!guiRegistry) {
            guiRegistry = new GUIComponentRegistry();
        }
        return <GUIElement>guiRegistry.getComponent(this, context);
    }

    public static getDynamicCreatedConfigNode(json: Object): ConfigNode {
        json["isDynamicCreated"] = true;
        json["name"] = "dynamicNode";

        return new ConfigNode(json);
    }

    getConfigJSON(): Object {
        return this.configJSON;
    }

    /**
     * Im Rahmen von UserStory technischeThemen-000557 - Buttons in der App konfigurierbar machen (Abrüsten des JS Dokuments), wurde die Möglichkeit geschaffen, einzelne SubMenu Elemente konfigurativ ausblenden zu können.
     * Diese Elemente werden hier identifiziert und direkt aus der SubMenu Node entfernt.
     * @param subMenuNode - die vollständige SubMenu Node mit allen Elementen
     * @param configProperties - das PropertySet, dass den Status der SubMenu Elemente definiert
     * @returns eine neue ConfigNode, mit den bereinigten Elementen
     */
    static removeDisabledNodes(subMenuNode: ConfigNode, configProperties: PropertySet): ConfigNode {
        const subMenuElements: ConfigNode[] = subMenuNode.getChildren();
        subMenuElements.forEach((element: ConfigNode) => {
            const menuName: string = JSON.parse(JSON.stringify(element.getConfigJSON())).Name;
            // wenn eine gleichnamige Property gefunden wird, die false ist, dann wird die Node zum Entfernen vorgemerkt
            if (configProperties[menuName] === "false") {
                subMenuNode = subMenuNode.removeChildByNameAndCopy(menuName);
            }
        });
        return subMenuNode;
    }
}
