import { I18n } from "../../../../common/i18n/I18n";
import { Registry } from "../../../../core/Registry";
import { TableView } from "../../../../gui/content/TableView";
import { TextLabel } from "../../../../gui/content/TextLabel";
import {
    FlexAlignItems,
    FlexBox,
    FlexDirection,
    FlexJustifyContent,
} from "../../../../gui/flexbox/FlexBox";
import { GUIElement } from "../../../../gui/GUIElement";
import { AllowanceSummary } from "../../AllowanceSummary";
import "./AllowanceSummary.less";

/**
 * Spesen-Boardelement:
 *   - Anzahl Dienstreise / Einzelbelege - Link zu Spesenliste
 */
export class AllowanceSummaryElement implements GUIElement {
    public static BCS_COMPONENT_NAME = "AllowanceSummary";

    private allowanceSummaryModel: AllowanceSummary;

    private i18n: I18n;

    constructor(allowanceSummaryModel: AllowanceSummary, i18n: I18n) {
        this.allowanceSummaryModel = allowanceSummaryModel;
        this.i18n = i18n;
    }

    compose($parent: JQuery): void {
        if (this.allowanceSummaryModel.isAllowanceRecordingAvailable()) {
            this.showCountAndLinkAllowances($parent);
        } else {
            $parent.addClass("disabled");
        }
    }

    private showCountAndLinkAllowances($parent: JQuery): void {
        const travelI18nKey =
            "MobileApp.travels.TabSwitch.businessTravel" +
            (this.allowanceSummaryModel.countBusinessTravels() == 1 ? "" : "s");
        const voucherI18nKey =
            "MobileApp.travels.TabSwitch.voucherAllowance" +
            (this.allowanceSummaryModel.countSingleVouchers() == 1 ? "" : "s");
        const travelI18nLabel = this.i18n.get(travelI18nKey);
        const voucherI18nLabel = this.i18n.get(voucherI18nKey);

        const containerFlexBox = new FlexBox()
            .setFlexDirection(FlexDirection.COLUMN)
            .setAlignItems(FlexAlignItems.CENTER)
            .setJustifyContent(FlexJustifyContent.CENTER)
            .addStyleClass("allowanceSummary")
            .setDOMId("allowanceSummaryCounters");

        const table = new TableView().addStyle("summaryTable");
        const row1 = table.newRow();
        row1.newCell()
            .addStyle("countTravels")
            .addStyle("alignRight")
            .addContentElement(
                new TextLabel().setInlineText(
                    this.allowanceSummaryModel.countBusinessTravels().toString(),
                ),
            );
        row1.newCell()
            .addStyle("countTravelsLabel")
            .addContentElement(new TextLabel().setInlineText(travelI18nLabel));
        const row2 = table.newRow();
        row2.newCell()
            .addStyle("countVouchers")
            .addStyle("alignRight")
            .addContentElement(
                new TextLabel().setInlineText(
                    this.allowanceSummaryModel.countSingleVouchers().toString(),
                ),
            );
        row2.newCell()
            .addStyle("countVouchersLabel")
            .addContentElement(new TextLabel().setInlineText(voucherI18nLabel));
        containerFlexBox.newFlexItem().addContentElement(table);

        containerFlexBox.compose($parent);

        // Schriftgröße bei längeren Labeln redizuieren, damit sie nicht vom BoardElement abgeschnitten werden.
        this.adjustFontSizeRegardingLabelLength(
            travelI18nLabel,
            voucherI18nLabel,
            containerFlexBox.getFlexBoxTag(),
        );
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }

    /**
     * Schriftgröße bei längeren Labeln redizuieren, damit sie nicht vom BoardElement abgeschnitten werden.
     *
     * @param $flexBoxTag
     */
    private adjustFontSizeRegardingLabelLength(
        label1: string,
        label2: string,
        $flexBoxTag: JQuery,
    ): void {
        if (Math.max(label1.length, label2.length) > 15) {
            $flexBoxTag.css("font-size", "1.1em");
        }
    }
}

Registry.registerGUIComponent(AllowanceSummaryElement.BCS_COMPONENT_NAME, AllowanceSummaryElement);
