import { Component } from "../../core/Component";
import { Controller } from "../../core/Controller";
import { MessagePool } from "../../core/Message/MessagePool";
import { Registry } from "../../core/Registry";
import { Animation, AppNavigator } from "../../core/Router";
import { GUIPage } from "../../gui/GUIPage";
import { ListView } from "../../gui/list/ListView";
import { ListViewContext } from "../../gui/list/ListViewContext";
import { ToolBar } from "../../gui/navigation/ToolBar";
import { ToolLink } from "../../gui/navigation/ToolLink";
import { UserSession } from "../auth/UserSession";
import { I18n } from "../i18n/I18n";
import { AppConsole } from "../log/AppConsole";
import { LocalPreferences } from "./LocalPreferences";
import { Preferences } from "./Preferences";
import { PreferencesListViewModel } from "./PreferencesListViewModel";
import { PreferencesManager } from "./PreferencesManager";
import { PreferencesLISTDefinition } from "./PreferencesLISTDefinition";
import { GUIContext } from "../../gui/GUIContext";

export class PreferencesController implements Component, Controller {
    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static BCS_COMPONENT_NAME = "preferencesPage";

    private navigator: AppNavigator;

    private page: GUIPage;

    private i18n: I18n;

    private messagePool: MessagePool;

    private userSession: UserSession;

    private preferencesManager: PreferencesManager;

    private localPreferences: Preferences;

    private serverPreferences: Preferences;

    private listDefinitions: PreferencesLISTDefinition;

    public getDependencyNames(): string[] {
        return [
            UserSession.BCS_COMPONENT_NAME,
            I18n.BCS_COMPONENT_NAME,
            MessagePool.BCS_COMPONENT_NAME,
            PreferencesManager.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }): void {
        AppConsole.log("PreferencesController: init ...");
        // TODO: wenn die Seite mal verwendet werden soll braucht sie bestimmt eine Config Node. Die müsste man dann hier sich erzeugen:
        //  this.controllerConfigNode = PreferencesGUIDefinition.getConfigNode() oder so in der Art, wie bei den anderen Seiten
        this.userSession = <UserSession>depencencyComponents[UserSession.BCS_COMPONENT_NAME];
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];

        this.messagePool = <MessagePool>depencencyComponents[MessagePool.BCS_COMPONENT_NAME];

        this.preferencesManager = <PreferencesManager>(
            depencencyComponents[PreferencesManager.BCS_COMPONENT_NAME]
        );

        this.localPreferences = new LocalPreferences();

        this.serverPreferences = this.preferencesManager.getServerPreferences();
    }

    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        this.navigator = navigator;

        // this.preferencesManager.readPreferences();

        this.page = new GUIPage(new GUIContext(this.i18n));
        this.composeHeaderToolBar();

        this.composeEditForm();
        this.page.setAnimation(animation, this.navigator.doShowAnimations()).compose($("body"));
    }

    private composeHeaderToolBar() {
        const headerToolBar = new ToolBar().setId("header_toolbar");

        headerToolBar.addToolLinkLeft(
            new ToolLink().setImageName("icon-chevron-left.svg").onClick(this.popState.bind(this)),
        );
        headerToolBar.setTitle("booking");
        headerToolBar.addStyleClass("headBar");

        this.page.addHeaderElement(headerToolBar);
    }

    private composeEditForm(): void {
        const listViewContext = new ListViewContext()
            .setI18n(this.i18n)
            .setModel(new PreferencesListViewModel(this.localPreferences));
        const timeRecordListView = new ListView(
            listViewContext,
            this.listDefinitions.getPreferenceListDef(),
        ).addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW);

        this.page.addPageElement(timeRecordListView);

        const listViewContext2 = new ListViewContext()
            .setI18n(this.i18n)
            .setModel(new PreferencesListViewModel(this.serverPreferences));
        const timeRecordListView2 = new ListView(
            listViewContext2,
            this.listDefinitions.getPreferenceServerDef(),
        ).addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW);

        this.page.addPageElement(timeRecordListView2);
    }

    popState(): void {
        const lastPageData: { parameters: {}; page: string } = this.navigator.popPage();
        this.navigator.navigateTo(
            lastPageData.page,
            lastPageData.parameters,
            Animation.SLIDE_RIGHT,
        );
    }
    destroy(): void {}
}

Registry.registerComponent(PreferencesController.BCS_COMPONENT_NAME, PreferencesController);
