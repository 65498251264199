import { IndexedDBError } from "../IndexedDBError";
import { Log } from "../../common/log/Log";
import { Browser } from "../../util/common/Browser";

export class IndexedDBSelectCursorRequest {
    private storeName: string;
    private transaction: IDBTransaction;
    private indexName: string;
    private query: IDBKeyRange;
    private elementCallback: Function;
    private resultSet: any[];
    private doSelectIdsOnly: boolean;

    constructor(
        transaction: IDBTransaction,
        storeName: string,
        indexName?: string,
        query?: IDBKeyRange,
        doSelectIdsOnly: boolean = false,
    ) {
        this.transaction = transaction;
        this.storeName = storeName;
        this.indexName = indexName;
        this.query = query;
        this.doSelectIdsOnly = doSelectIdsOnly;

        this.elementCallback = null;
        this.resultSet = [];
    }

    public each(elementCallback) {
        this.elementCallback = elementCallback;
        return this;
    }

    public then(successCallback, errorCallback) {
        const self = this;

        try {
            let cursor: IDBRequest;

            const store = this.transaction.objectStore(this.storeName);
            if (this.indexName) {
                const index = store.index(this.indexName);

                if (this.doSelectIdsOnly) {
                    cursor = this.query ? index.openKeyCursor(this.query) : index.openKeyCursor();
                } else {
                    cursor = this.query ? index.openCursor(this.query) : index.openCursor();
                }
            } else {
                cursor = store.openCursor();
            }
            cursor.onsuccess = function (event) {
                self.success(event, successCallback, errorCallback);
            };
            cursor.onerror = function (event) {
                self.error(event, errorCallback);
            };
        } catch (e) {
            Log.error("[IndexedDBSelectCursorRequest] Opening store failed", {
                store: this.storeName,
                index: this.indexName,
            });
            throw e;
        }
    }

    private success(event, successCallback, errorCallback) {
        const cursor = event.target.result;
        if (cursor) {
            let element;
            if (this.doSelectIdsOnly) {
                element = cursor.primaryKey;
            } else {
                element = cursor.value;
            }

            //BCS.Log.debug("[IndexedDBSelectCursorRequest] Key: " + cursor.key + ", id: " + element);

            if (this.elementCallback) {
                const selectCursor = new IndexedDBSelectCursor(cursor, element);
                this.elementCallback.call(this, { element: element, cursor: selectCursor });
                selectCursor.then(function () {
                    cursor["continue"]();
                }, errorCallback);
            } else {
                this.resultSet.push(element);
                cursor["continue"]();
            }
        } else {
            // Workaround für iOS/Safari bei Verwendung eines Index mit genau einem Feld.
            // Index ist definiert als Array [Feldname]. Bei Verwendung einer Query mit diesem Index
            // darf der Suchwert an der Query aber nicht als Array [Wert] sondern muss als einfacher Wert gesetzt sein.
            // Wird bei Suche nach Array [Wert] kein Ergebnis gefunden, so wird unter iOS noch mit dem einfachem Wert gesucht.
            // Aufgrund Schwierigkeit neuere iPads sicher zu erkennen: nicht nur unter iOS sonder immer! (#149787)
            if (
                this.resultSet.length === 0 &&
                this.query &&
                this.indexName && // Browser.isIOS() &&
                (!this.query.lower ||
                    (Array.isArray(this.query.lower) && this.query.lower.length == 1)) &&
                (!this.query.upper ||
                    (Array.isArray(this.query.upper) && this.query.upper.length == 1))
            ) {
                if (this.query.lower && !this.query.upper) {
                    this.query = IDBKeyRange.lowerBound(this.query.lower[0], this.query.lowerOpen);
                } else if (!this.query.lower && this.query.upper) {
                    this.query = IDBKeyRange.upperBound(this.query.upper[0], this.query.upperOpen);
                } else if (this.query.lower[0] === this.query.upper[0]) {
                    this.query = IDBKeyRange.only(this.query.lower[0]);
                } else {
                    this.query = IDBKeyRange.bound(
                        this.query.lower[0],
                        this.query.upper[0],
                        this.query.lowerOpen,
                        this.query.upperOpen,
                    );
                }

                new IndexedDBSelectCursorRequest(
                    this.transaction,
                    this.storeName,
                    this.indexName,
                    this.query,
                    this.doSelectIdsOnly,
                ).then(
                    (result) => successCallback(this.elementCallback ? {} : result),
                    errorCallback,
                );
            } else {
                successCallback.call(
                    this,
                    this.elementCallback ? {} : { resultSet: this.resultSet },
                );
            }
        }
    }

    private error(event, errorCallback) {
        errorCallback.call(this, new IndexedDBError(event).setQuery(this.query));
    }
}

class IndexedDBSelectCursor {
    doDelete: boolean;
    doUpdate: boolean;
    cursor: any;
    currentElement: any;

    constructor(cursor, currentElement) {
        this.cursor = cursor;
        this.currentElement = currentElement;
        this.doUpdate = false;
        this.doDelete = false;
    }

    public updateElement(updateElement) {
        this.doUpdate = true;
        this.updateElement = updateElement;
    }

    public deleteElement() {
        this.doDelete = true;
    }

    public then(successCallback, errorCallback) {
        const updateRequest = this.doUpdate
            ? this.cursor.update(this.updateElement)
            : this.doDelete
              ? this.cursor["delete"]()
              : null;
        if (updateRequest) {
            const self = this;
            updateRequest.onsuccess = function () {
                successCallback.call(this);
            };
            updateRequest.onerror = function (event) {
                self.errorUpdateDelete(event, errorCallback);
            };
        } else {
            successCallback.call(this);
        }
    }

    private errorUpdateDelete(event, errorCallback) {
        const errorElement = this.updateElement || this.currentElement;
        errorCallback.call(this, new IndexedDBError(event).setElement(errorElement));
    }
}
