import { ConfigNode } from "../../core/Config/ConfigNode";
import { ForecastElement } from "../../domain/time_recording/forecast/ForecastElement";
import { forecastSummaryType } from "../../domain/time_recording/forecast/ForecastManager";
import { BoardElement } from "../../gui/board/BoardElement";
import { GUIContext } from "../../gui/GUIContext";

/*
<BoardElement PosX="1" PosY="15" SizeX="6" SizeY="1" Name="showForecastElement" WholeContainerLinksTo="forecastEstimationTaskSelection">
    <!-- Restaufwï¿½nde BCS_APP-000006 - Rest t in BCS App -->
    <ForecastSummary/>
</BoardElement> */

/**
 * Restaufwands-Boardelement:
 * - Stellt eine Verknüpfung zum Schätzen der Restaufwände dar
 */
export class ForecastBoardElement extends BoardElement {
    static readonly ELEMENT_NAME: string = "showForecastElement";

    private forecastSummary: forecastSummaryType;

    constructor(
        boardElementConfig: ConfigNode,
        context: GUIContext,
        forecastSummary: forecastSummaryType,
    ) {
        super(boardElementConfig, context);

        this.forecastSummary = forecastSummary;

        this.name = ForecastBoardElement.ELEMENT_NAME;

        this.xTiles = boardElementConfig.getIntAttribute("PosX", 1);
        this.yTiles = boardElementConfig.getIntAttribute("PosY", 13);
        this.widthTiles = boardElementConfig.getIntAttribute("SizeX", 6);
        this.heightTiles = boardElementConfig.getIntAttribute("SizeY", 1);

        this.wholeContainerLinksTo = "forecastEstimationTaskSelection";

        this.styleClasses.push(this.name);
    }

    protected initializeChildren() {
        this.context.setPossibleContentHeight(
            this.currentTileHeight * this.heightTiles - this.CSS_ELEMENT_MARGIN * 2,
        );
        this.guiElements = [new ForecastElement(this.context, this.forecastSummary)];
    }
}
