import { AppConsole } from "../../../common/log/AppConsole";
import { HttpRESTClient } from "../../../util/http/HttpRESTClient";
import { ProgressFeedback } from "../../../util/progress/ProgressFeedback";

export class TimesheetClient {
    public async fetchTimesheet(checksum, progressFeedback: ProgressFeedback): Promise<object> {
        const result = await new HttpRESTClient()
            .createRequest()
            .setMethod("GET")
            .addPath("/timerecording/timesheet")
            .setParameter("checksum", checksum)
            .onProgress((isDownload, loaded, total) =>
                progressFeedback.notifyProgress(loaded, total),
            )
            .send();

        switch (result["status"]) {
            case 200:
                return result["json"];
            case 204:
                return { no_content: true };
            default:
                return result;
        }
    }

    public getSelectedTimesheets(requestedOids: string[]): Promise<object> {
        return new Promise((resolve, reject) => {
            new HttpRESTClient()
                .createRequest()
                .setMethod("GET")
                .addPath("/timerecording/selectedTimesheets")
                .setParameter("requestedOids", JSON.stringify(requestedOids))
                .send()
                .then((result) => {
                    AppConsole.log("getSelectedTimesheets");
                    AppConsole.log(result);
                    switch (result["status"]) {
                        case 200:
                            resolve(result["json"]);
                            break;
                        case 204:
                            resolve({ no_content: true });
                            break;
                        default:
                            reject(result);
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }
}
