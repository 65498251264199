import { PrototypeObject } from "../util/prototypes/PrototypeScanner";
import { GUIElement } from "./GUIElement";
import { GUIContext } from "./GUIContext";
import { ConfigNode } from "../core/Config/ConfigNode";
import { Registry } from "../core/Registry";

export class GUIComponentRegistry {
    private components: { [key: string]: PrototypeObject } = {};

    private singletons: { [key: string]: GUIElement } = {};

    constructor() {
        //this.scanForComponents();
    }
    /*
    public scanForComponents() : void {
        this.scanPackageForComponents(Window, 0);
    }

    private scanPackageForComponents(namespaceObject: Object, depth: number) : void {
        for (let name in namespaceObject) {
            if (namespaceObject.hasOwnProperty(name)) {
                let property = <PrototypeObject>namespaceObject[name];

                if (property.hasOwnProperty("BCS_COMPONENT_NAME")) {
                    this.registerComponent(property);
                }

                if (typeof namespaceObject == "object" && name.substr(0, 3) == "BCS" && depth < 1) {
                    this.scanPackageForComponents(property, depth + 1);
                }
            }
        }
    }*/
    /*
    private registerComponent(componentObject: PrototypeObject) : void {
        let name = componentObject["BCS_COMPONENT_NAME"];
        this.components[name] = componentObject;
    }
    */

    public getComponent(configNode: ConfigNode, context: GUIContext): GUIElement {
        return this.getOrCreateComponent(configNode, 0, context);
    }

    private getOrCreateComponent(
        configNode: ConfigNode,
        depth: number,
        context: GUIContext,
    ): GUIElement {
        const name = configNode.getNodeName();
        if (this.singletons.hasOwnProperty(name)) {
            return this.singletons[name];
        }
        const componentObject = Registry.getGUIComponent(name);

        if (componentObject === null || typeof componentObject === "undefined") {
            throw new Error("[ComponentRegistry] Component not registered: " + name);
        }

        const component = Object.create(componentObject.prototype);

        component.constructor(configNode, context);

        if (component.hasOwnProperty("start")) {
            this.singletons[name] = component;
        }

        return component;
    }
}
