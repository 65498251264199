import { PrototypeObject } from "../util/prototypes/PrototypeScanner";

export class Registry {
    private static components: { [key: string]: PrototypeObject } = {};

    private static singletonComponents: { [key: string]: PrototypeObject } = {};

    private static guiComponents: { [key: string]: PrototypeObject } = {};

    public static registerComponent(componentName: string, componentPrototype: PrototypeObject) {
        this.components[componentName] = componentPrototype;
    }

    public static registerSingletonComponent(
        componentName: string,
        componentPrototype: PrototypeObject,
    ) {
        this.singletonComponents[componentName] = componentPrototype;
    }

    public static registerGUIComponent(componentName: string, prototypeObject: PrototypeObject) {
        this.guiComponents[componentName] = prototypeObject;
    }

    public static getComponents() {
        return this.components;
    }

    public static getComponent(name: string): PrototypeObject {
        return this.components[name];
    }

    public static isSingleton(name: string) {
        return this.singletonComponents.hasOwnProperty(name);
    }

    public static getSingletonComponent(name: string): PrototypeObject {
        return this.singletonComponents[name];
    }

    public static getSingletonComponents(): { [key: string]: PrototypeObject } {
        return this.singletonComponents;
    }

    public static getSingletonComponentNames(): string[] {
        return Object.keys(this.singletonComponents);
    }

    public static getGUIComponent(name: string): PrototypeObject {
        return this.guiComponents[name];
    }

    public static getGUIComponents(): { [key: string]: PrototypeObject } {
        return this.guiComponents;
    }
}
