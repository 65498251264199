import { FooterTabBar } from "../../board/FooterTabBar";
import { UserSession } from "../../common/auth/UserSession";
import { I18n } from "../../common/i18n/I18n";
import { AppConsole } from "../../common/log/AppConsole";
import { Log } from "../../common/log/Log";
import { Component } from "../../core/Component";
import { Controller } from "../../core/Controller";
import { MessageEntity, MessageType } from "../../core/Message/MessageEntity";
import { Registry } from "../../core/Registry";
import { Animation, AppNavigator } from "../../core/Router";
import { SingletonManager, SyncResult } from "../../core/SingletonManager";
import { GUIEventPool } from "../../gui/event/GUIEventPool";
import { GUIContext } from "../../gui/GUIContext";
import { GUIPage } from "../../gui/GUIPage";
import { ProcessLayer } from "../../gui/loadingProcess/ProcessLayer";
import { BottomActionButton } from "../../gui/navigation/BottomActionButton";
import { ToolBar } from "../../gui/navigation/ToolBar";
import { ToolLink } from "../../gui/navigation/ToolLink";
import { ProgressFeedback } from "../../util/progress/ProgressFeedback";
import { SyncStateManager } from "../SyncStateManager";
import { SyncErrorsListSubController } from "./SyncErrorsListSubController";
import { SyncGUIDefinitions } from "./SyncGUIDefinitions";
import { SyncMessagesListSubController } from "./SyncMessagesListSubController";

/**
 * Controller für Synchronisationsseite.
 *
 * Synchronisationsseite ist in fast allen Seite über das Sync-Icon in der Footer-Navigation aufrufbar.
 * Synchronisationsseite bietet Aktionslink in Header-Navigation zum Aufruf der  Synchronisation mit BCS.
 * Synchronisationsseite zeigt Synchronisationsmeldungen.
 *   Erfolgsmeldungen
 *   Fehler je Entity, deren Synchronisation fehlgeschlagen ist.
 */
export class SyncController implements Controller {
    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static readonly CONTROLLER_PATH = "sync";

    /** Aufruf-Parameter für Pfad zur aufzurufende Seite bei Zurück-Navigation */
    public static readonly PARAMETER_NAVIGATE_BACK_PATH = "navigateBackPath";

    /** Aufruf-Parameter für Parameter (als stringified JSON) für aufzurufende Seite bei Zurück-Navigation */
    public static readonly PARAMETER_NAVIGATE_BACK_PARAMETERS = "navigateBackParameters";

    /** AppNavigator bietet Möglichkeit auf eine andere Seite zu navigieren */
    private navigator: AppNavigator;

    /** Slide-Animation beim Anzeigen der Seite */
    private animation: Animation;

    /** User Session */
    private userSession: UserSession;

    /** Label */
    private i18n: I18n;

    /** Verwaltung der Synchronisationsstatus aller Objekte */
    private syncStateManager: SyncStateManager;

    /** Verwaltung aller Singletons */
    private singletonManager: SingletonManager;

    /** Pfad zur aufzurufende Seite bei Zurück-Navigation */
    private navigateBackPath;

    /** Parameter für aufzurufende Seite bei Zurück-Navigation */
    private navigateBackParameters;

    private syncMessagesListSubController: SyncMessagesListSubController;

    /** Statische GUI-Definition der Listen der Sync-Seite */
    private syncGUIDefinitions: SyncGUIDefinitions;

    private syncErrorsListSubController: SyncErrorsListSubController;

    /** Wurzel-Element des GUI-Baums der aktuellen Seite */
    private page: GUIPage;

    /** Kopfzeile für Navigation */
    private headerToolBar: ToolBar;

    /** Button im Footer zum Starten des Synchronisation */
    private startSyncActionButton: BottomActionButton;

    /** Gibta n, ob bereits eine Synchronisation läuft */
    private isSyncRunning = false;

    public getDependencyNames(): string[] {
        return [
            UserSession.BCS_COMPONENT_NAME,
            I18n.BCS_COMPONENT_NAME,
            SyncStateManager.BCS_COMPONENT_NAME,
            SingletonManager.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }) {
        this.userSession = <UserSession>depencencyComponents[UserSession.BCS_COMPONENT_NAME];
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.syncStateManager = <SyncStateManager>(
            depencencyComponents[SyncStateManager.BCS_COMPONENT_NAME]
        );
        this.singletonManager = <SingletonManager>(
            depencencyComponents[SingletonManager.BCS_COMPONENT_NAME]
        );

        this.syncMessagesListSubController = new SyncMessagesListSubController(
            this.syncStateManager,
            this.i18n,
        ).onErrorSyncStateRowClicked(this.errorSyncStateRowClicked.bind(this));

        this.syncGUIDefinitions = new SyncGUIDefinitions();
        this.syncErrorsListSubController = new SyncErrorsListSubController(
            this.syncStateManager,
            this.syncGUIDefinitions,
            this.i18n,
        ).onErrorSyncStateRowClicked(this.errorSyncStateRowClicked.bind(this));
    }

    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        this.navigator = navigator;
        this.animation = animation;

        this.navigateBackPath = parameters[SyncController.PARAMETER_NAVIGATE_BACK_PATH] || "index";
        this.navigateBackParameters = JSON.parse(
            parameters[SyncController.PARAMETER_NAVIGATE_BACK_PARAMETERS] || "{}",
        );

        const self = this;
        this.syncMessagesListSubController
            .countAllSyncStatesWithChangesInApp()
            .then(() => self.syncErrorsListSubController.readAllSyncStatesWithErrors())
            .then(() => self.composePage())
            .catch((error) => AppConsole.error(error)); // TODO App Fehler in GUI anzeigen
    }

    private async composePage(): Promise<void> {
        // Seite (Wurzelelement) erstellen
        this.page = new GUIPage(new GUIContext(this.i18n), SyncController.CONTROLLER_PATH);
        this.page.addStyleClass("defaultBackground");

        // Kopfzeile (mit Navigation, Titel und Bearbeiten-/Löschen-Modus)
        this.composeHeader();

        // Offene Synchronisationen und Ergebnismeldungen einer gerade ausgeführen Synchronisation anzeigen (z.B. "2 Dienstreisen in BCS gespeichert")
        this.syncMessagesListSubController.composeSyncMessages(this.page);

        // Persistente SyncStates mit Synchronisation-Fehlern anzeigen (z.B. "Fehler bei Dienstreise: Startdatum fehlt")
        this.syncErrorsListSubController.composeErrorSyncStates(this.page);

        // Button zum Start der Synchronisation, deutlich sichtbar im unteren Seitenbereich
        this.composeSyncButton();

        // Fußzeile (u.a. mit Home-Link)
        await this.composeFooterTabBar();

        // Seite rendern
        this.page
            .setAnimation(this.animation, this.navigator.doShowAnimations())
            .compose($("body"));
        // Aktualisierung der Seite nach Synchronisation zeigt keine Animation
        this.animation = Animation.NONE;
    }

    /**
     * Kopfzeile (mit Navigation, Titel und Bearbeiten-/Löschen-Modus)
     */
    private composeHeader(): void {
        this.headerToolBar = new ToolBar()
            .setId("header_toolbar")
            .setTitle(this.i18n.get("MobileApp.sync.title"))
            .addStyleClass("headBar")
            .addToolLinkLeft(
                new ToolLink()
                    .setId("navigateBack")
                    .setImageName("icon-chevron-left.svg")
                    .onClick(this.popState.bind(this)),
            );
        this.page.addHeaderElement(this.headerToolBar);
    }

    private composeSyncButton(): void {
        this.startSyncActionButton = new BottomActionButton()
            .setPrimaryActionLabel(this.i18n.get("MobileApp.sync.startSync"))
            .onClick(this.startSync.bind(this));
        this.page.addFooterElement(this.startSyncActionButton);
    }

    /**
     * Fußzeile (u.a. mit Home-Link)
     */
    private async composeFooterTabBar(): Promise<void> {
        const self = this;
        const footerTabBar = new FooterTabBar(new GUIContext(this.i18n));
        await footerTabBar.composeDefaultFooter(
            SyncController.CONTROLLER_PATH,
            this.navigator,
            {},
            () => self.syncStateManager.countUnsyncedElements(),
        );
        this.page.addFooterElement(footerTabBar);
    }

    /**
     * Startet Synchronisation mit BCS
     */
    private async startSync(): Promise<void> {
        if (this.isSyncRunning) {
            return;
        }

        try {
            this.isSyncRunning = true;

            const progressFeedback = new ProgressFeedback();
            const processLayer = new ProcessLayer();
            processLayer.show(progressFeedback);

            const syncResult = await this.singletonManager.synchronizeSingletons(progressFeedback);

            processLayer.hide();

            Log.info("[SyncController] startSync", {
                syncResult: syncResult,
            });

            switch (syncResult) {
                case SyncResult.SYNC_SUCCESS:
                    // Synchronisation erfolgt
                    this.userSession.setToOnlineMode();
                    this.reComposeSyncPage();
                    break;
                case SyncResult.NOSYNC_OFFLINE:
                    this.userSession.setToOfflineMode();
                    this.reComposeSyncPage("MobileApp.sync.message.offline", MessageType.INFO);
                    break;
                case SyncResult.NOSYNC_NO_SESSION:
                    // BCS erreichbar, aber Session abgelaufen: Loginseite aufrufen
                    this.userSession.setToOnlineMode();
                    this.navigator.navigateTo("login");
                    break;
                case SyncResult.NOSYNC_ERROR:
                default:
                    // Allgemeiner Fehler bei Aufruf der Synchronisation: Offline / HTTP 500
                    this.reComposeSyncPage("MobileApp.sync.message.failure", MessageType.ERROR);
                    break;
            }

            this.isSyncRunning = false;
        } catch (e) {
            Log.error("[SyncController] Sync failed: " + e);

            this.isSyncRunning = false;
        }
    }

    private async reComposeSyncPage(
        syncResultMessage?: string,
        messageType?: MessageType,
    ): Promise<void> {
        await this.syncMessagesListSubController.countAllSyncStatesWithChangesInApp();
        await this.syncErrorsListSubController.readAllSyncStatesWithErrors();

        await this.composePage();

        // Erfolgsmeldung oder Fehlermeldung ausgeben
        let message: MessageEntity;
        if (syncResultMessage) {
            // Allgemeiner Fehler bei Aufruf der Synchronisation: Offline / HTTP 500
            message = new MessageEntity(
                this.i18n.get(syncResultMessage),
                messageType ? messageType : MessageType.ERROR,
            );
        } else if (this.syncErrorsListSubController.hasSyncErrors()) {
            // Synchronisation erfolgt: Es gibt Fehlereinträge (z.B. Unvollständige Daten)
            message = new MessageEntity(
                this.i18n.get("MobileApp.sync.message.error"),
                MessageType.ERROR,
            );
        } else {
            // Synchronisation erfolgt: Keine Fehlereinträge
            message = new MessageEntity(
                this.i18n.get("MobileApp.sync.message.success"),
                MessageType.AFFIRMATION,
            );
        }
        this.page.composeMessages([message], new GUIContext(this.i18n, new GUIEventPool()));
    }

    private errorSyncStateRowClicked(path: string, parameters?: { [key: string]: string }) {
        this.navigator.navigateTo(path, parameters, Animation.SLIDE_LEFT);
    }

    public popState(): void {
        this.navigateBack();
    }

    private navigateBack(): void {
        this.navigator.navigateTo(
            this.navigateBackPath,
            this.navigateBackParameters,
            Animation.SLIDE_RIGHT,
        );
    }

    public destroy(): void {
        // nichts zu tun
    }
}

Registry.registerComponent(SyncController.CONTROLLER_PATH, SyncController);
