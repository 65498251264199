import { ConfigNode } from "../../../core/Config/ConfigNode";

/**
 * Statischer Aufbau der Liste und der Formularseite. Wird um die hinzukonfigurierten Felder ergänzt.
 */
export class ContactGUIDefinitions {
    private static RECORD_LIST_VIEW_DEF: {} = {
        fieldsets: [
            {
                label: "MobileApp.Contacts.Record.set_Name",
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "account",
                            name: "name",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "userFirstname",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "userAnrede",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "school",
                            name: "userTitle",
                            mode: "edit",
                        },
                    },
                ],
            },
            {
                label: "MobileApp.Contacts.Record.set_Communication",
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "phone",
                            name: "userTel",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "cellphone-iphone",
                            name: "userHandy",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "file",
                            name: "userFax",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "at",
                            name: "mail",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "desk",
                            name: "userFunktion",
                            mode: "edit",
                        },
                    },
                ],
            },
            {
                label: "MobileApp.Contacts.Record.set_OU",
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "ou",
                            name: "ouName",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "web",
                            name: "userHomepage",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "map",
                            name: "ouStreet",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "ouAreacode",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "ouCity",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "earth",
                            name: "ouCountry",
                            mode: "display",
                            readonly: "readonly",
                        },
                        link: {
                            path: "ouCountrySelection",
                            parameters: {
                                oid: { attribute: "oid" },
                                "return-path": { value: "record" },
                                "return-parameter-oid": { attribute: "oid" },
                            }, // Parameter, um die Seite zur Länderauswahl aufzurufen
                        },
                    },
                ],
            },
            {
                label: "MobileApp.Contacts.Record.set_Description",
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "text",
                            name: "description",
                            mode: "edit",
                        },
                    },
                ],
            },
        ],
    };
    private static OVERVIEW_LIST_VIEW_DEF_SMALL: {} = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "compound",
                        field: {
                            name: "contactOverview",

                            mainContent: {
                                left: {
                                    class: "field",
                                    field: {
                                        icon: "account",
                                        name: "name",
                                        mode: "display",
                                        styleClass: "fieldBold",
                                    },
                                },
                                right: {
                                    class: "field",
                                    field: {
                                        icon: "NONE",
                                        // icon: 'file-upload-outline',
                                        name: "syncedDate",
                                        mode: "display",
                                    },
                                },
                            },
                            subContents: [
                                {
                                    left: {
                                        class: "field",
                                        field: {
                                            name: "userFirstname",
                                            mode: "display",
                                        },
                                    },
                                },
                            ],
                        },
                        link: {
                            path: "record",
                            parameters: {
                                oid: { attribute: "oid" },
                            },
                        },
                    },
                ],
            },
        ],
    };
    private static OVERVIEW_LIST_VIEW_DEF_BIG: {} = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "compound",
                        field: {
                            name: "contactOverview",
                            superContents: [
                                {
                                    left: {
                                        class: "field",
                                        field: {
                                            icon: "NONE",
                                            name: "userAnrede",
                                            mode: "display",
                                            styleClass: "fieldSmallFont",
                                        },
                                    },
                                },
                                {
                                    left: {
                                        class: "field",
                                        field: {
                                            icon: "NONE",
                                            name: "userFirstname",
                                            mode: "display",
                                            styleClass: "fieldSmallFont",
                                        },
                                    },
                                    right: {
                                        class: "field",
                                        field: {
                                            //icon: 'file-upload-outline',
                                            icon: "NONE",
                                            name: "syncedDate",
                                            mode: "display",
                                        },
                                    },
                                },
                            ],
                            mainContent: {
                                icon: "account",
                                left: {
                                    class: "field",
                                    field: {
                                        name: "name",
                                        mode: "display",
                                        styleClass: "fieldBold",
                                    },
                                },
                            },
                            subContents: [
                                {
                                    left: {
                                        class: "field",
                                        field: {
                                            name: "ouName",
                                            mode: "display",
                                            styleClass: "fieldSmallFontMarged",
                                        },
                                    },
                                },
                            ],
                        },
                        link: {
                            path: "record",
                            parameters: {
                                oid: { attribute: "oid" },
                            },
                        },
                    },
                ],
            },
        ],
    };
    private static OPTION_SELECTION_RESULT_LIST_VIEW_DEF: {} = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "compound",
                        field: {
                            name: "option",
                            mainContent: {
                                left: {
                                    class: "field",
                                    field: {
                                        icon: "NONE",
                                        name: "optionLabel",
                                        mode: "display",
                                    },
                                },
                            },
                        },
                        link: {
                            path: "SELECT",
                            parameters: {
                                selectedOptionId: { attribute: "optionId" },
                            },
                            linkImage: "NONE",
                        },
                    },
                ],
            },
        ],
    };
    private static SUB_MENU_CONFIG_NODE: ConfigNode = new ConfigNode({
        nodeName: "SubMenu",
        children: [
            {
                nodeName: "PageLink",
                children: [],
                Parameter: "createContact",
                I18nLabel: "MobileApp.Contacts.Element",
                NavigationPath: "record",
                Name: "Record",
            },
            {
                nodeName: "PageLink",
                children: [],
                Parameter: "scanContact",
                I18nLabel: "MobileApp.Contacts.Scan",
                NavigationPath: "scan",
                Name: "Scan",
            },
        ],
        Position: "fixed",
        Orientation: "above",
        Name: "addContacts",
    });

    public getRecordListViewDef(): object {
        //GUI für die Liste zur Erfassung eines neuen Kontaktes
        return ContactGUIDefinitions.RECORD_LIST_VIEW_DEF;
    }

    public getOverviewListViewDef(mode: string): object {
        //GUI für die Liste aller Kontakte in kompakter Version.
        if (mode == "small") {
            return ContactGUIDefinitions.OVERVIEW_LIST_VIEW_DEF_SMALL;
        } else {
            return ContactGUIDefinitions.OVERVIEW_LIST_VIEW_DEF_BIG;
        }
    }

    getOptionSelectionResultListViewDef(optionName: string) {
        switch (optionName) {
            case "ouCountry":
                return ContactGUIDefinitions.OPTION_SELECTION_RESULT_LIST_VIEW_DEF;
            default:
                throw new Error("ContactGUIDefinitions::UnknownOptionName " + optionName);
        }
    }

    static getSubMenuNode() {
        return ContactGUIDefinitions.SUB_MENU_CONFIG_NODE;
    }
}
