import { I18n } from "../../../common/i18n/I18n";
import { Registry } from "../../../core/Registry";
import { TextLabel } from "../../../gui/content/TextLabel";
import {
    FlexAlignItems,
    FlexBox,
    FlexDirection,
    FlexJustifyContent,
} from "../../../gui/flexbox/FlexBox";
import { GUIContext } from "../../../gui/GUIContext";
import { GUIElement } from "../../../gui/GUIElement";
import "./forecast.less";
import { forecastSummaryType } from "./ForecastManager";

export class ForecastElement implements GUIElement {
    public static BCS_COMPONENT_NAME = "ForecastElement";

    private context: GUIContext;

    private i18n: I18n;
    private forecastSummary: forecastSummaryType;

    constructor(guiContext: GUIContext, forecastSummary: forecastSummaryType) {
        this.context = guiContext;
        this.i18n = this.context.getI18n();
        this.forecastSummary = forecastSummary;
    }

    compose($parent: JQuery): void {
        this.showForecastElement($parent);
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }

    private showForecastElement($parent: JQuery) {
        const label = this.i18n.get("MobileApp.forecastEstimation");
        const containerFlexBox = new FlexBox()
            .setFlexDirection(FlexDirection.COLUMN)
            .setAlignItems(FlexAlignItems.CENTER)
            .addStyleClass("forecastBox")
            .setDOMId("forecastElement");

        containerFlexBox.newFlexItem().addContentElement(new TextLabel().setInlineText(label));
        containerFlexBox.compose($parent);
    }
}

Registry.registerGUIComponent(ForecastElement.BCS_COMPONENT_NAME, ForecastElement);
