import { GUIElement } from "../GUIElement";
import { ConfigNode } from "../../core/Config/ConfigNode";
import { GUIContext } from "../GUIContext";
import { Registry } from "../../core/Registry";

/**
 * Kann im Gegensatz zum Textlabel auch ASCII Zeichen ausgeben.
 *
 * TODO App - Könnte stattdessen auch TextLabel mit contentType=html verwenden
 */
export class HTMLLabel implements GUIElement {
    public static BCS_COMPONENT_NAME = "HTMLLabel";

    private text: string;

    private htmlTagName: string;

    private configNode: ConfigNode;

    private context: GUIContext;

    private textLabelTag: JQuery;

    private styleClasses: string[] = [];

    constructor(configNode?: ConfigNode, controller?: GUIContext) {
        this.context = controller;
        this.configNode = configNode;
    }

    public setText(text: string): HTMLLabel {
        this.text = text;
        this.htmlTagName = "div";

        if (this.textLabelTag) {
            this.textLabelTag.html(text);
        }

        return this;
    }

    public compose($parent: JQuery): void {
        let text = "";

        // erste Prio hat der Text der programmatisch gesetzt wurde.
        if (this.text) {
            text = this.text;
        } else if (this.configNode) {
            // zweite Prio hat der I18nKey der durch die Konfig gesetzt wurde
            const i18nKey = this.configNode.getAttribute("I18nKey", null);
            if (i18nKey !== null) {
                text = this.context.getI18n().get(i18nKey);
            }
        }

        this.textLabelTag = $("<" + this.htmlTagName + ">");

        this.textLabelTag.addClass(this.styleClasses.join(" ")).appendTo($parent).html(text);
    }

    public addStyleClass(styleClass: string): HTMLLabel {
        this.styleClasses.push(styleClass);
        return this;
    }

    public setAdhocText(text: string): HTMLLabel {
        this.textLabelTag.text(text);
        return this;
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}

Registry.registerGUIComponent(HTMLLabel.BCS_COMPONENT_NAME, HTMLLabel);
