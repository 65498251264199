import { Component } from "./Component";
import { SingletonComponent } from "./SingletonComponent";
import { Registry } from "./Registry";

/**
 * Verwaltung von Events innerhalb der App.
 *
 * Dient dazu verschiedene Domaine lose koppeln, damit Ereignisse in einer Domain (z.B. Objekt wurde gelöscht)
 * Auswirkungen auf eine andere Domain haben können (z.B. ein damit verknüpftes Objekt wird auch gelöscht).
 *
 * Bietet anderen Komponenten an, sich für bestimmte Events zu registrieren bzw. Events auszulösen.
 * Events werden für einen symbolischen Namen und einen Bereich registriert bzw. ausgelöst.
 * Events gelten für einen Scope (z.B. Objekttyp) oder auch für den globalen Scope.
 *
 * Es gibt bekannte Events (wie Objekt gelöscht), für die es spezielle Funktion mit speziellen Parametern gibt.
 * Es können auch generische Events verwendet werden, bei denen ein Name und Callack-Funktionen verwendet wird.
 */
export class AppEventManager implements Component, SingletonComponent {
    /** Symbolischer Name dieser Komponente */
    public static readonly BCS_COMPONENT_NAME = "AppEventManager";

    /** Ereignis "Objekt gelöscht" */
    private static readonly EVENT_OBJECT_DELETED = "ObjectDeleted";

    /** Globaler Scope */
    public static readonly SCOPE_GLOBAL = "GLOBAL";

    private eventListenerCallbacksByName: { [key: string]: { [key: string]: Function[] } } = {};

    public getDependencyNames(): string[] {
        return [];
    }

    public init(depencencyComponents: { [key: string]: Component }): void {}

    public start(): Promise<void> {
        return Promise.resolve();
    }

    public notifyBeginUserSession(isOnline: boolean): Promise<void> {
        return Promise.resolve();
    }

    public synchronize(): Promise<void> {
        return Promise.resolve();
    }

    /**
     * Registriert Listener für ein Event.
     *
     * @param eventName Symbolischer Name des Events
     * @param eventScope Bereich des Events (z.B. Objekt-Typ)
     * @param eventListenerCallback Callback-Funktion
     */
    public registerEventListener(
        eventName: string,
        eventScope: string,
        eventListenerCallback: Function,
    ): AppEventManager {
        const eventListenerCallbacksByScope =
            this.eventListenerCallbacksByName[eventName] ||
            (this.eventListenerCallbacksByName[eventName] = {});
        const eventListenerCallbacks =
            eventListenerCallbacksByScope[eventScope] ||
            (eventListenerCallbacksByScope[eventScope] = []);
        if (eventListenerCallbacks.indexOf(eventListenerCallback) < 0) {
            eventListenerCallbacks.push(eventListenerCallback);
        }
        return this;
    }

    /**
     * Löst ein Event aus.
     *
     * @param eventName Symbolischer Name des Events
     * @param eventScope Bereich des Events (z.B. Objekt-Typ)
     * @param parameters Event-Parameter (z.B. Objekt-Id)
     */
    public async triggerEvent(
        eventName: string,
        eventScope: string,
        eventParameters: object = {},
    ): Promise<void> {
        const eventListenerCallbacksByScope = this.eventListenerCallbacksByName[eventName];
        if (eventListenerCallbacksByScope) {
            const eventListenerCallbacks = eventListenerCallbacksByScope[eventScope];
            if (eventListenerCallbacks) {
                for (let i = 0; i < eventListenerCallbacks.length; i++) {
                    const eventListenerCallback = eventListenerCallbacks[i];
                    await eventListenerCallback(eventScope, eventParameters);
                }
            }
        }
    }

    /**
     * Registriert Listener für das Event, dass ein Objekt gelöscht wurde.
     *
     * @param type Objekt-Typ
     * @param eventListenerCallback Callback-Funktion mit Parameter id=Objekt-Id
     */
    public registerEventListenerObjectDeleted(
        type: string,
        eventListenerCallback: objectDeletedEventListenerCallbackType,
    ): AppEventManager {
        this.registerEventListener(
            AppEventManager.EVENT_OBJECT_DELETED,
            type,
            eventListenerCallback,
        );
        return this;
    }

    /**
     * Löst das Event aus, dass ein Objekt gelöscht wurde.
     *
     * @param type  Objekt-Typ
     * @param id Objekt-Id
     */
    public triggerObjectDeleted(
        type: string,
        id: string,
        onlyLocalDelete: boolean = false,
    ): Promise<void> {
        return this.triggerEvent(AppEventManager.EVENT_OBJECT_DELETED, type, {
            id: id,
            onlyLocalDelete: onlyLocalDelete,
        });
    }

    /**
     * Löst das Event aus, dass mehrere Objekte gelöscht wurden.
     *
     * @param type  Objekt-Typ
     * @param ids Objekt-Ids
     */
    public async triggerObjectsDeleted(
        type: string,
        ids: string[],
        onlyLocalDelete: boolean = false,
    ): Promise<void> {
        for (let i = 0; i < ids.length; i++) {
            await this.triggerObjectDeleted(type, ids[i], onlyLocalDelete);
        }
    }
}

export type objectDeletedEventListenerCallbackType = (
    eventScope: string,
    eventParameters: { id: string },
) => Promise<void>;

Registry.registerSingletonComponent(AppEventManager.BCS_COMPONENT_NAME, AppEventManager);
