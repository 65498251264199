import { ToolBar } from "./ToolBar";
import { GUIPage } from "../GUIPage";
import { FloatingActionButton } from "./FloatingActionButton";
import { BottomActionButton } from "./BottomActionButton";
import { ToolLink } from "./ToolLink";

export class PageDeleteMode {
    private page: GUIPage;

    private addButton: FloatingActionButton;

    private deleteActionButton: BottomActionButton;

    private deleteModeActive = false;

    private deleteButtonLabel: string = "[DELETE]";

    private deleteModeToggleCallback: (isActive: boolean) => void;

    private deleteCallback: () => void;

    public setPage(page: GUIPage): PageDeleteMode {
        this.page = page;
        return this;
    }

    public setHeaderToolBar(headerToolBar: ToolBar): PageDeleteMode {
        headerToolBar.addToolLinkRight(
            new ToolLink()
                .setId("pageDelete")
                .setImageName("icon-delete.svg")
                .onClick(this.toggleDeleteMode.bind(this)),
        );
        return this;
    }

    public setAddButton(addButton: FloatingActionButton): PageDeleteMode {
        this.addButton = addButton;
        return this;
    }

    public setDeleteButtonLabel(deleteButtonLabel: string): PageDeleteMode {
        this.deleteButtonLabel = deleteButtonLabel;
        return this;
    }

    private toggleDeleteMode(): void {
        if (!this.deleteModeActive) {
            this.enterDeleteMode();
        } else {
            this.leaveDeleteMode();
        }
    }

    public enterDeleteMode(): void {
        if (this.deleteModeActive) {
            return;
        }

        if (this.deleteModeToggleCallback) {
            this.deleteModeToggleCallback(true);
        }

        if (this.addButton) {
            this.addButton.hide();
        }

        this.deleteActionButton = new BottomActionButton()
            .setDeletePageLabel(this.deleteButtonLabel)
            .onClick(this.delete.bind(this));
        this.page.addFooterElement(this.deleteActionButton);

        this.deleteModeActive = true;
    }

    public leaveDeleteMode(): void {
        if (!this.deleteModeActive) {
            return;
        }

        if (this.deleteModeToggleCallback) {
            this.deleteModeToggleCallback(false);
        }

        this.page.removeFooterElement(this.deleteActionButton);
        this.deleteActionButton.remove();
        this.deleteActionButton = null;

        if (this.addButton) {
            this.addButton.show();
        }

        this.deleteModeActive = false;
    }

    public isDeleteModeActive(): boolean {
        return this.deleteModeActive;
    }

    public onDeleteModeToggle(
        deleteModeToggleCallback: (isActive: boolean) => void,
    ): PageDeleteMode {
        this.deleteModeToggleCallback = deleteModeToggleCallback;
        return this;
    }

    public onDelete(deleteCallback: () => void): PageDeleteMode {
        this.deleteCallback = deleteCallback;
        return this;
    }

    private delete(): void {
        this.deleteCallback();
    }

    isActive() {
        return this.deleteModeActive;
    }
}
