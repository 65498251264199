import { HttpRESTClient } from "../../util/http/HttpRESTClient";
import { OAUTH_CODE_QUERY } from "./oauth/OAuthGlobal";

export class LoginClient {
    public verifyOAuthAuthentification(): Promise<{ session: object; user: object }> {
        return new Promise((resolve, reject) => {
            new HttpRESTClient(true)
                .createRequest()
                .setMethod("GET")
                .addPath("oauth/login" + OAUTH_CODE_QUERY)
                .send()
                .then((result) => {
                    if (result["status"] == 200) {
                        const sessionUserData = JSON.parse(result["responseText"]);
                        resolve({
                            session: sessionUserData["session"],
                            user: sessionUserData["user"],
                        });
                    } else {
                        reject(result);
                    }
                })
                .catch(reject);
        });
    }

    public login(username: string, password: string): Promise<{ session: object; user: object }> {
        return new Promise((resolve, reject) => {
            new HttpRESTClient()
                .createRequest()
                .setMethod("POST")
                .addPath("/auth/login")
                .setJSONBody({ userLogin: username, userPwd: password })
                .send()
                .then((result) => {
                    if (result["status"] == 200) {
                        const sessionUserData = JSON.parse(result["responseText"]);
                        resolve({
                            session: sessionUserData["session"],
                            user: sessionUserData["user"],
                        });
                    } else {
                        reject(result);
                    }
                })
                .catch(reject);
        });
    }

    public logout(doClearSiteData = false): Promise<void> {
        const path = !doClearSiteData ? "/auth/logout" : "/auth/logoutAndClearSiteData";

        return new Promise((resolve, reject) => {
            new HttpRESTClient()
                .createRequest()
                .setMethod("POST")
                .addPath(path)
                .send()
                .then((result) => {
                    result["status"] == 200 ? resolve() : reject(result);
                })
                .catch(reject);
        });
    }

    public status(): Promise<PingResult> {
        return new Promise((resolve, reject) => {
            new HttpRESTClient()
                .createRequest()
                .setMethod("GET")
                .addPath("/auth/status")
                // Ping-Timeout nur 10s, damit Offline-App schneller startet,
                // wenn zwar Netz und BCS-Domain erreichbar aber nicht BCS-Server (#150535)
                .setTimeout(10000)
                .send()
                .then((result) => {
                    switch (result["status"]) {
                        case 200:
                            resolve(PingResult.REACHABLE_ACTIVE_SESSION);
                            break;
                        case 401:
                        case 403:
                            resolve(PingResult.REACHABLE_NO_SESSION);
                            break;
                        case 408:
                        case 503:
                        case 504:
                            resolve(PingResult.NOT_REACHABLE);
                            break;
                        case 500:
                            resolve(PingResult.SERVER_ERROR);
                            break;
                        default:
                            resolve(PingResult.REACHABLE_NO_SESSION);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        switch (error.response.status) {
                            case 401:
                            case 403:
                                resolve(PingResult.REACHABLE_NO_SESSION);
                                break;
                            case 408:
                            case 503:
                            case 504:
                                resolve(PingResult.NOT_REACHABLE);
                                break;
                            case 500:
                                resolve(PingResult.SERVER_ERROR);
                                break;
                            default:
                                resolve(PingResult.NOT_REACHABLE);
                                break;
                        }
                    } else {
                        resolve(PingResult.NOT_REACHABLE);
                    }
                });
        });
    }
}

/**
 * Ping Results
 *
 * Darf nicht const sein, da sonst im generierten Javascript nicht mehr verwendbar (z.B. für switch)
 */
export enum PingResult {
    REACHABLE_ACTIVE_SESSION = "reachable_active_session",
    REACHABLE_NO_SESSION = "reachable_nosession",
    NOT_REACHABLE = "not_reachable",
    SERVER_ERROR = "server_error",
}
