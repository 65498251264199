import { EntityValue } from "../../entities/values/EntityValue";
import { GUIElement } from "../GUIElement";
import { FormFieldAddOn } from "./FormFieldAddOn";

export const enum FormFieldMode {
    DISPLAY = "display",
    EDIT = "edit",
}

export type formFieldChangeEventType = { entityId: string; name: string; value: EntityValue };

export interface FormField extends GUIElement {
    setEntityId(entityId: string): FormField;

    setName(name: string): FormField;

    getName(): string;

    setValue(value: EntityValue): FormField;

    getValue(): EntityValue;

    setLabel(label: string): FormField;

    setPlaceholder(placeholder: string): FormField;

    setMode(mode: FormFieldMode): FormField;

    setReadOnly(readOnly: boolean): FormField;

    isReadOnly(): boolean;

    getMode(): FormFieldMode;

    registerFormFieldAddOn(formFieldAddOn: FormFieldAddOn): FormField;

    updateValue(value: EntityValue): FormField;

    onChange(changeCallback: (changeEvent: formFieldChangeEventType) => void): FormField;
}
