export class ContactRecordingTerms {
    private contactRecordingAllowed: boolean;
    private recordingTerms: object;
    private checkSum: number = 0;

    constructor(recordingTermValueObject?: object) {
        if (recordingTermValueObject) {
            this.recordingTerms = recordingTermValueObject;

            this.checkSum = recordingTermValueObject["checkSum"] || 0;

            this.readContactPermission(recordingTermValueObject["contactPermission"]);
        } else {
            // keine RecordingTerms aus Datenbank gelesen :/
        }
    }

    public isContactRecordingAllowed(): boolean {
        return this.contactRecordingAllowed;
    }

    public getCheckSum(): number {
        return this.checkSum;
    }

    public toValueObject(): object {
        return this.recordingTerms;
    }

    private readContactPermission(recordingTermValueObjectElement: object): void {
        this.contactRecordingAllowed =
            recordingTermValueObjectElement["isMobileRecordingEnabled"] == true &&
            recordingTermValueObjectElement["userHasRightLicense"] == true;
    }
}
