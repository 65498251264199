import { ApplicationProperties } from "./ApplicationProperties";

/**
 * Repräsentiert den Synchronisationsstatus (u.a. letzten Synchronisations-Zeitpunkt) einer Domaine (z.B. ein Objekttyp) zwischen de App und BCS.
 *
 * Wird nach Aktionen in der App bzw. nach Synchronisationen mit BCS aktualisiert.
 * Zustand wird in der App als ApplicationProperty in der IndexedDB persistent gespeichert.
 */
export class DomainSyncState {
    private static SYNC_STATE_TIMESTAMP = "syncStateTimestamp";

    private applicationProperties: ApplicationProperties;

    private currentUserOid: string;

    private domainPropertyKey: string;

    private syncStateProperty: object = {};

    public static async fetchFromApplicationProperties(
        domainPropertyKey: string,
        applicationProperties: ApplicationProperties,
        currentUserOid: string,
    ): Promise<DomainSyncState> {
        const syncStateProperty = await applicationProperties.readProperty(
            domainPropertyKey,
            currentUserOid,
            null,
        );
        return new DomainSyncState(
            applicationProperties,
            currentUserOid,
            domainPropertyKey,
            syncStateProperty,
        );
    }

    constructor(
        applicationProperties: ApplicationProperties,
        currentUserOid: string,
        domainPropertyKey: string,
        syncStateProperty: object,
    ) {
        this.applicationProperties = applicationProperties;
        this.currentUserOid = currentUserOid;
        this.domainPropertyKey = domainPropertyKey;

        // Default erstellen
        this.syncStateProperty = {};
        this.syncStateProperty[DomainSyncState.SYNC_STATE_TIMESTAMP] = 0;

        if (syncStateProperty) {
            // nur die aktuell bekannten Attribute aus gespeicherten Werten übernehmen
            for (const name in this.syncStateProperty) {
                if (syncStateProperty.hasOwnProperty(name)) {
                    this.syncStateProperty[name] = syncStateProperty[name];
                }
            }
        }
    }

    public getSyncStateTimestamp(): number {
        return this.syncStateProperty[DomainSyncState.SYNC_STATE_TIMESTAMP];
    }

    public async setSyncStateTimestamp(syncStateTimestamp: number): Promise<void> {
        if (this.getSyncStateTimestamp() !== syncStateTimestamp) {
            this.syncStateProperty[DomainSyncState.SYNC_STATE_TIMESTAMP] = syncStateTimestamp;
            this.storeSyncState();
        }
    }

    private storeSyncState(): Promise<void> {
        return this.applicationProperties.writeProperty(
            this.domainPropertyKey,
            this.currentUserOid,
            null,
            this.syncStateProperty,
        );
    }
}
