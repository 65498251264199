import { ServerConfigProperties } from "../../../../common/config/ServerConfigProperties";
import { I18n } from "../../../../common/i18n/I18n";
import { AppConsole } from "../../../../common/log/AppConsole";
import { AttributeDefinition } from "../../../../common/schema/AttributeDefinition";
import { SchemaDataType } from "../../../../common/schema/SchemaDataType";
import { Component } from "../../../../core/Component";
import { Controller } from "../../../../core/Controller";
import { Registry } from "../../../../core/Registry";
import { Animation, AppNavigator } from "../../../../core/Router";
import { EntityValue } from "../../../../entities/values/EntityValue";
import { OidValue } from "../../../../entities/values/OidValue";
import { StringValue } from "../../../../entities/values/StringValue";
import { GUIContext } from "../../../../gui/GUIContext";
import { GUIPage } from "../../../../gui/GUIPage";
import { EmptyListViewModel } from "../../../../gui/list/EmptyListViewModel";
import { ListRowModel } from "../../../../gui/list/ListRowModel";
import { ListView } from "../../../../gui/list/ListView";
import { ListViewContext } from "../../../../gui/list/ListViewContext";
import { ListViewModel } from "../../../../gui/list/ListViewModel";
import { TypeAheadSearchView } from "../../../../gui/list/TypeAheadSearchView";
import { ToolBar } from "../../../../gui/navigation/ToolBar";
import { ToolLink } from "../../../../gui/navigation/ToolLink";
import { AllowanceManager } from "../../AllowanceManager";
import { Allowance } from "../../records/Allowance";
import { AllowancePSP } from "../../records/AllowancePSP";
import { BusinessTravel } from "../../records/BusinessTravel";
import { DailyAllowance } from "../../records/DailyAllowance";
import { AllowanceGUIDefinitions } from "../AllowanceGUIDefinitions";

/**
 * Controller für Auswahlseite einer Aufgabe für ein Spesen-Tagegeld bzw. eine Belegspese.
 *
 * - Zeigt alle Spesen-Aufgaben.
 * - Bietet Möglichkeit zum Filtern durch Texteingabe
 * -- Jedes durch Leerzeichen getrennte Suchwort muss in einem der durchsuchten Aufgaben-Felder vorkommen.
 * -- Suchworte werden buchstabenweise mit Wildcards zwischen jedem Buchstaben Aufgaben-Felder gesucht.
 */
export class AllowancePSPSelectionController implements Controller {
    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static readonly CONTROLLER_PATH = "allowancepspselection";

    /** Seiten-Parameter für Oid der Dienstreise (darf undefiniert sein, wenn Einzel-Belegspese) */
    public static readonly PARAMETER_BUSINESSTRAVEL_ID = "pspSelectBusinessTravelOid";

    /** Seiten-Parameter für Oid der Tagegeldspesen bzw. Belegspese */
    public static readonly PARAMETER_ALLOWANCE_ID = "pspSelectAllowanceOid";

    /** Seiten-Parameter Pfad der Seite, zu der nach getrofener Auswahl zurückgekehrt werden soll. */
    public static readonly PARAMETER_RETURN_PATH = "return-path";

    /** Präfix für alle Seiten-Parameter, mit denen (ohne Präfix) die Rückkehrseite augerufen werden soll. */
    public static readonly PARAMETER_RETURN_PARAMETER_PREFIX = "return-parameter-";

    /** Referenz auf Navigator zum Aufruf einer anderen Seite */
    private navigator: AppNavigator;

    /** Slide-Animation beim Anzeigen der Seite */
    private animation: Animation;

    /** Parameter, mit der diese Seite aufgerufen wurde */
    private pageParameters: object;

    /** I18n */
    private i18n: I18n;

    /** Spesenverwaltung */
    private allowanceManager: AllowanceManager;

    private serverConfigProperties: ServerConfigProperties;

    /** Liste alle Spesenaufgaben (mit Projektpfad) */
    private allowancePSPList: AllowancePSP[];

    /** Dienstreise der aktuell geöffneten Tagegeld bzw. Belegspese (darf undefiniert sein, wenn Einzel-Belegspese) */
    private businessTravel: BusinessTravel;

    /** Aktuell geöffnetes Tagegeld bzw. Belegspese für Aufgabenauswahl */
    private allowance: Allowance;

    /** GUI-Definitionen für anzuzeigene Formularfelder der Ergebnisliste mit Spesenaufgaben */
    private allowanceGUIDefinitions: AllowanceGUIDefinitions;

    /** Wurzel GUI-Element für Seite */
    private page: GUIPage;

    /** GUI-Element für Sucheingabe, Filterung der Ergebnisliste und Neuanzeige des Ergebnis-ListView */
    private typeAheadSearchView: TypeAheadSearchView;

    /**
     * Aufruf vor Erzeugen vor Initialisierung einer Instanz.
     *
     * @returns Symblische Name der benötigten Abhängigkeiten dieses Controllers
     */
    public getDependencyNames(): string[] {
        return [
            I18n.BCS_COMPONENT_NAME,
            AllowanceManager.BCS_COMPONENT_NAME,
            ServerConfigProperties.BCS_COMPONENT_NAME,
        ];
    }

    /**
     * Aufruf für Initialisierung dieser Instanz.
     * @param depencencyComponents Benötigten Abhängigkeiten mit symblischen Namen
     */
    public init(depencencyComponents: { [key: string]: Component }) {
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.allowanceManager = <AllowanceManager>(
            depencencyComponents[AllowanceManager.BCS_COMPONENT_NAME]
        );
        this.serverConfigProperties = <ServerConfigProperties>(
            depencencyComponents[ServerConfigProperties.BCS_COMPONENT_NAME]
        );

        this.allowanceGUIDefinitions = new AllowanceGUIDefinitions();
    }

    /**
     * Aufruf, wenn Seite gerendert werdern soll.
     *
     * @param parameters Parameter dieser Seite
     * @param animation Animation beim Einblenden
     * @param navigator Referenz auf Navigator zum Aufruf einer anderen Seite
     */
    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        this.navigator = navigator;
        this.animation = animation;
        this.pageParameters = parameters;

        if (!this.allowanceManager.getAllowanceRecordingTerms().isAllowanceRecordingAvailable()) {
            this.navigator.navigateTo("index", {}, Animation.SLIDE_RIGHT);
            return;
        }

        // Abholen der Spesen-Aufgabenliste,
        // Laden der Dienstreise mit Tagegeld/Belegspese bze. Einzel-Belegspese, an der die Aufgabe ausgewählt werden soll,
        // Danach Rendern der Seite.
        this.fetchPSPListAndAllowance(parameters).then(() => this.composePage());
    }

    /**
     * Laden der Spesen-Aufgabenliste und
     * Laden der Dienstreise mit Tagegeld/Belegspese bze. Einzel-Belegspese, an der die Aufgabe ausgewählt werden soll.
     *
     * @param parameters Parameter dieser Seite
     */
    private async fetchPSPListAndAllowance(parameters: { [key: string]: string }): Promise<void> {
        // Abholen Spesen-Aufgabenliste
        this.allowancePSPList = this.allowanceManager
            .getAllowanceRecordingTerms()
            .getAllowancePSPList();

        // Laden der Dienstreise mit Tagegeld/Belegspese bze. Einzel-Belegspese, an der die Aufgabe ausgewählt werden soll,
        const businessTravelId =
            parameters[AllowancePSPSelectionController.PARAMETER_BUSINESSTRAVEL_ID];
        const allowanceId = parameters[AllowancePSPSelectionController.PARAMETER_ALLOWANCE_ID];
        if (allowanceId) {
            // Dienstreise oder Einzel-Belegspesen laden
            const allowance = await this.allowanceManager.fetchAllowanceById(
                businessTravelId ? businessTravelId : allowanceId,
            );

            if (allowance.getSubtype() == BusinessTravel.SUBTYPE) {
                // Tagegeld oder Beleg einer Dienstreise
                this.businessTravel = <BusinessTravel>allowance;
                this.allowance = this.businessTravel.getSubAllowanceById(allowanceId);
            } else {
                // Einzel-Beleg
                this.allowance = allowance;
            }
        }
    }

    /**
     * Rendern der Seite.
     */
    private composePage(): void {
        if (!this.allowance) {
            // Wenn Spese nicht geladen: Zurück zur Ausgangsseite
            this.navigateToReturnPath();
        }

        // Seite (Wurzelelement) erstellen
        this.page = new GUIPage(
            new GUIContext(this.i18n),
            AllowancePSPSelectionController.CONTROLLER_PATH,
        );

        // Kopfzeile (mit Navigation und Titel)
        this.composeHeader();

        // Suchfeld und Ergebnisliste mit Spesenaufgaben
        this.composeSearchAndResultList();

        // Seite rendern
        this.page
            .setAnimation(this.animation, this.navigator.doShowAnimations())
            .compose($("body"));
    }

    /**
     * Rendert Kopfzeile (mit Navigation und Titel).
     */
    private composeHeader(): void {
        const headerToolBar = new ToolBar().setId("header_toolbar").addStyleClass("headBar");

        // Zurück-Navigation
        headerToolBar.addToolLinkLeft(
            new ToolLink()
                .setId("navigateBack")
                .setImageName("icon-chevron-left.svg")
                .onClick(this.popState.bind(this)),
        );

        // Seitentitel
        headerToolBar.setTitle(this.i18n.get("MobileApp.allowancepspselection.pagetitle"));

        this.page.addHeaderElement(headerToolBar);
    }

    /**
     * Rendern des Suchfeldes und der Ergebnisliste mit Spesenaufgaben
     */
    private composeSearchAndResultList(): void {
        // ListView-Kontext vorest mit leerem ListViewModel
        // Nach Rendern der Seite und nach jeder Sucheingabe wird Ergebnisliste mit Suchergebnis als ListViewModel neu gerendert.
        const listViewContext = new ListViewContext()
            .setI18n(this.i18n)
            .setModel(new EmptyListViewModel());

        const pspSelectionResultListViewDef = this.serverConfigProperties.customizeGUIDefinitions(
            this.allowanceGUIDefinitions.getPSPSelectionResultListViewDef(),
            AllowancePSPSelectionController.CONTROLLER_PATH,
            "allowancepspselection",
            "display",
        );

        // ListView mit Aufgaben-Ergebnisliste (GUIDefinition geben an, welche Aufgaben-Attribute angezeigt werden)
        const resultListView = new ListView(listViewContext, pspSelectionResultListViewDef)
            .setDOMId("pspResult")
            .addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW)
            .onRowClicked(this.listRowClicked.bind(this));

        // GUI-Element für Sucheingabe, Filterung der Ergebnisliste und Neuanzeige des Ergebnis-ListView
        const allowancePSPSearchNames = this.allowanceManager
            .getAllowanceRecordingTerms()
            .getAllowancePSPSearchNames();
        this.typeAheadSearchView = new TypeAheadSearchView()
            .setPlaceholder(this.i18n.get("MobileApp.allowancepspselection.filterAllowancePSP"))
            .setSearchModel(
                new AllowancePSPSearchListViewModel(this.allowancePSPList),
                allowancePSPSearchNames,
            )
            .setResultListView(resultListView);
        this.page.addPageElement(this.typeAheadSearchView);
    }

    /**
     * Callback: Wird aufgerufen, wenn eine Zeile der Aufgaben-Ergebnisliste angeklickt wurde.
     * Pfad und Parameter werden in der GUIDefinition als "link" definiert.
     * Hier: "link": {"path": "SELECT",  "parameters": {"selectedPSPOid": {"attribute": "taskOid"}}
     *
     * @param selectionPath Pfad der zu öffnenden Seite (hier nicht verwendet)
     * @param parameters Parameter für zu öffnenden Seite (enthalten hier die Oid der ausgewählten Aufgabe)
     */
    private listRowClicked(selectionPath: string, parameters: { [key: string]: string }): void {
        // Ausgewählte Aufgabe an Tagegeld bzw. Belegspese setzen
        const selectedPSPOid = parameters["selectedPSPOid"];

        this.allowance.setValue(DailyAllowance.PSP_OID, new OidValue(selectedPSPOid));

        // Dienstreise (mit Tagegeld bzw. Belegspese) oder Einzel-Belegspese speichern,
        // dann zur aufrufenden Seite (typischerweise mit Formular, in dem u.a. die Aufgabe auswählbar ist) zurückkehren.
        this.allowanceManager
            .storeAllowance(this.businessTravel ? this.businessTravel : this.allowance)
            .then((allowance) => this.navigateToReturnPath())
            .catch((error) => AppConsole.error(error)); // TODO App - Fehler anzeigen
    }

    /**
     * Aufruf via Callback nachdem die Browser-Back-Taste angeklickt wurde.
     * Controller soll zum vorherigen Zustand zurückgehen, z.B. ein Menü schließen
     * oder abschließend zur übergeordneten Seite zurücknavigieren.
     */
    public popState(): void {
        this.navigateToReturnPath();
    }

    /**
     * Navigiert zurück zur aufrufenden Seite, typischerweise mit Formular, in dem u.a. die Aufgabe ausgewählt werden kann.
     * Aufruf der Seite, die als Parameter "return-path"  beim Aufruf dieser Seite angegeben wurde.
     * Als Parametern, werden die Parameter mitgesendet, die beim Aufruf dieser Seite mit dem Präfix "return-parameter-" gegeben waren.
     */
    private navigateToReturnPath() {
        let returnPath = null;
        const returnParameters: { [key: string]: string } = {};

        for (const name in this.pageParameters) {
            if (name == AllowancePSPSelectionController.PARAMETER_RETURN_PATH) {
                // Pfad der Zielaseite aus Parameter "return-path" auslesen
                returnPath = this.pageParameters[name];
            } else if (
                name.substr(
                    0,
                    AllowancePSPSelectionController.PARAMETER_RETURN_PARAMETER_PREFIX.length,
                ) == AllowancePSPSelectionController.PARAMETER_RETURN_PARAMETER_PREFIX
            ) {
                // Parameter der Zielaseite aus Parametern mit Präfix "return-parameter-" auslesen
                const returnParameterName = name.substr(
                    AllowancePSPSelectionController.PARAMETER_RETURN_PARAMETER_PREFIX.length,
                );
                returnParameters[returnParameterName] = this.pageParameters[name];
            }
        }

        this.navigator.navigateTo(returnPath, returnParameters, Animation.SLIDE_RIGHT);
    }

    public destroy(): void {
        // leer
    }
}

/**
 * ListViewModel mit Aufgaben-Ergebnisliste
 */
class AllowancePSPSearchListViewModel implements ListViewModel {
    private allowancePSPList: AllowancePSP[];

    constructor(allowancePSPList: AllowancePSP[]) {
        this.allowancePSPList = allowancePSPList;
    }

    public countRows(): number {
        return this.allowancePSPList.length;
    }

    public getRowModelByNo(rowNo: number): ListRowModel {
        return new AllowancePSPSearchListRowModel(this.allowancePSPList[rowNo]);
    }

    public getRowModelById(entityId: string): ListRowModel {
        return new AllowancePSPSearchListRowModel(
            this.allowancePSPList.filter(
                (allowancePSP) => allowancePSP.getTaskOid() == entityId,
            )[0],
        );
    }

    public isResultTruncated(): boolean {
        return false;
    }
}

/**
 * ListRowModel mit einer Aufgabe
 */
class AllowancePSPSearchListRowModel implements ListRowModel {
    private allowancePSP: AllowancePSP;

    constructor(allowancePSP: AllowancePSP) {
        this.allowancePSP = allowancePSP;
    }

    public getEntityId(name?: string): string {
        return this.allowancePSP.getTaskOid();
    }

    public getDataType(name: string): SchemaDataType {
        return SchemaDataType.STRING;
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return null;
    }

    public getValue(name: string): EntityValue {
        return new StringValue(this.allowancePSP.get(name));
    }

    public setValue(name: string, value: EntityValue): void {
        // geht hier nicht
    }

    public isEditable(): boolean {
        return false;
    }

    public isDeletable(): boolean {
        return false;
    }
}

// Controller unter seinem Pfad registrieren
Registry.registerComponent(
    AllowancePSPSelectionController.CONTROLLER_PATH,
    AllowancePSPSelectionController,
);
