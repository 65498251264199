import { I18n } from "../../../../common/i18n/I18n";
import { GUIPage } from "../../../../gui/GUIPage";
import { ListView } from "../../../../gui/list/ListView";
import { FileManager } from "../../../files/FileManager";
import { VoucherAllowance } from "../../records/VoucherAllowance";
import { ListRow } from "../../../../gui/list/ListRow";
import { Image } from "../../../../gui/content/Image";
import { FloatingActionButton } from "../../../../gui/navigation/FloatingActionButton";
import { UserSession } from "../../../../common/auth/UserSession";
import { TextLabel } from "../../../../gui/content/TextLabel";
import { Log } from "../../../../common/log/Log";
import { AllowanceManager } from "../../AllowanceManager";
import { FooterTabBar } from "../../../../board/FooterTabBar";
import { SyncStateManager } from "../../../../sync/SyncStateManager";
import { FileUploadField } from "../../../files/pages/FileUploadField";
import { BCSFile } from "../../../files/records/BCSFile";
import { ImageDimension } from "../../../files/util/ImageDimension";
import { ImageRotationType } from "../../../files/util/ImageTransformation";
import { ListRowCompoundField } from "../../../../gui/list/ListRowCompoundField";
import { ListRowGUIElement } from "../../../../gui/list/ListRowGUIElement";
import { PageDeleteMode } from "../../../../gui/navigation/PageDeleteMode";

export class VoucherPhotoListController {
    private allowanceManager: AllowanceManager;

    private fileManager: FileManager;

    private syncStateManager: SyncStateManager;

    private i18n: I18n;

    private userSession: UserSession;

    private page: GUIPage;

    private navigateToPhotoCallback: (voucherFileId: string) => void;

    private voucherAllowance: VoucherAllowance;

    private voucherFiles: BCSFile[];

    private voucherFileListView: ListView;

    private addVoucherPhotoButton: FloatingActionButton;

    private footerTabBar: FooterTabBar;

    private maxWidth: number;

    private maxHeight: number;

    private fileUploadField: FileUploadField;

    private noVoucherFilesTextLabel: TextLabel;

    private countVoucherFiles: number;

    constructor(
        allowanceManager: AllowanceManager,
        fileManager: FileManager,
        syncStateManager: SyncStateManager,
        i18n: I18n,
        userSession: UserSession,
    ) {
        this.allowanceManager = allowanceManager;
        this.fileManager = fileManager;
        this.syncStateManager = syncStateManager;
        this.i18n = i18n;
        this.userSession = userSession;

        this.maxWidth = Math.floor(($(window).innerWidth() * 6) / 10);
        this.maxHeight = Math.floor(($(window).innerHeight() * 7) / 10);
    }

    public onNavigateToPhoto(navigateToPhotoCallback: (voucherFileId: string) => void): void {
        this.navigateToPhotoCallback = navigateToPhotoCallback;
    }

    public async fetchFiles(voucherAllowance: VoucherAllowance): Promise<void> {
        if (!voucherAllowance) {
            return;
        }
        this.voucherAllowance = voucherAllowance;
        this.voucherFiles = await this.fileManager.fetchFilesByComponent(
            this.voucherAllowance.getId(),
        );
    }

    public compose(page: GUIPage, pageDeleteMode: PageDeleteMode): void {
        this.page = page;

        this.voucherFileListView = new ListView()
            .setDOMId("voucherFiles")
            .setTitle(this.i18n.get("MobileApp.voucher.title.voucherFiles"))
            .addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW)
            .addStyleClass("voucherPhotoList");
        this.page.addPageElement(this.voucherFileListView);

        this.voucherFiles.forEach((voucherFile) => {
            if (voucherFile.isImage()) {
                // Beleganhänge im Bildformat
                this.voucherFileListView.addRow(this.createImageRow(voucherFile));
            } else {
                // Beleganhänge im PDF-Format
                this.voucherFileListView.addRow(this.createPDFRow(voucherFile));
            }
        });

        this.countVoucherFiles = this.voucherFiles.length;
        this.voucherFiles = null;

        // Button zum Hinzufügen von Belegfotos (sofern Beleg bearbeitbar)
        if (this.voucherAllowance.isEditable()) {
            this.addVoucherPhotoButton = new FloatingActionButton()
                .setImageName("icon-add-photo-white.svg")
                .onClick(this.addVoucherPhoto.bind(this));
            pageDeleteMode.setAddButton(this.addVoucherPhotoButton);
            this.page.addFooterElement(this.addVoucherPhotoButton);
        }

        this.noVoucherFilesTextLabel = new TextLabel()
            .setText(this.i18n.get("MobileApp.voucher.noVoucherFiles"))
            .addStyleClass("textLabelNoContent")
            .setDisplay(this.countVoucherFiles == 0);
        this.page.addPageElement(this.noVoucherFilesTextLabel);

        this.fileUploadField = new FileUploadField().onFileUpload(
            this.storeNewVoucherPhoto.bind(this),
        );
        this.page.addPageElement(this.fileUploadField);
    }

    public setFooterTabBar(footerTabBar: FooterTabBar): VoucherPhotoListController {
        this.footerTabBar = footerTabBar;
        return this;
    }

    private createImageRow(voucherFile: BCSFile): ListRow {
        const imageDimension = new ImageDimension(
            voucherFile.getImageWidth(),
            voucherFile.getImageHeight(),
        ).reduce(this.maxWidth * 0.8, this.maxHeight * 0.8, false);

        return new ListRow()
            .setEntityId(voucherFile.getId())
            .addContentElement(
                new ListRowCompoundField().setMainContentFields(
                    "NONE",
                    new ListRowGUIElement().addContentElement(
                        new Image()
                            .setImageDataURL(voucherFile.getImageDataURL())
                            .setImageWidthHeight(
                                imageDimension.getWidth(),
                                imageDimension.getHeight(),
                            ),
                    ),
                    null,
                ),
            )
            .setOpenImageName("open_right")
            .setDeletable(true)
            .onClick(
                ((voucherFileId) => {
                    return () => this.navigateToPhotoCallback(voucherFileId);
                })(voucherFile.getId()),
            );
    }

    private createPDFRow(voucherFile: BCSFile): ListRow {
        return new ListRow()
            .setEntityId(voucherFile.getId())
            .addContentElement(
                new ListRowCompoundField()
                    .addStyleClass("voucherPDFRow")
                    .setMainContentFields(
                        "NONE",
                        new ListRowGUIElement()
                            .addContentElement(new Image().setImageName("pdf.svg"))
                            .addContentElement(
                                new TextLabel().setInlineText(voucherFile.getFilename()),
                            ),
                        null,
                    ),
            )
            .setOpenImageName("open_right")
            .setDeletable(true)
            .onClick(
                ((voucherFileId) => {
                    return () => this.navigateToPhotoCallback(voucherFileId);
                })(voucherFile.getId()),
            );
    }

    private addVoucherPhoto(): void {
        this.fileUploadField.selectFile();
    }

    private async storeNewVoucherPhoto(imageData: string, filename?: string): Promise<void> {
        const voucherFile = BCSFile.create(
            this.userSession.getCurrentUserOid(),
            this.voucherAllowance.getId(),
            imageData,
            filename,
        );

        this.countVoucherFiles++;
        this.noVoucherFilesTextLabel.setDisplay(false);

        if (voucherFile.isImage()) {
            const photoDimensions = this.allowanceManager
                .getAllowanceRecordingTerms()
                .getVoucherPhotoDimensions();
            const photoQuality = this.allowanceManager
                .getAllowanceRecordingTerms()
                .getVoucherPhotoQuality();
            await voucherFile.transformImage(
                photoDimensions.width,
                photoDimensions.height,
                photoQuality,
                ImageRotationType.AUTO,
            );
        }

        if (voucherFile.isImage() || voucherFile.isPDF()) {
            await this.fileManager.storeFile(voucherFile);

            let listRow: ListRow;
            if (voucherFile.isImage()) {
                listRow = this.createImageRow(voucherFile);
            } else {
                listRow = this.createPDFRow(voucherFile);
            }

            const voucherFileRowId = "ListRow_voucherfile_" + voucherFile.getId();
            listRow.setDOMId(voucherFileRowId);
            this.voucherFileListView.addRow(listRow);

            this.page.scrollToElement(voucherFileRowId);

            const self = this;
            await this.footerTabBar.updateSyncBadgeCounter(FooterTabBar.ICON_SYNC, () =>
                self.syncStateManager.countUnsyncedElements(),
            );
        } else {
            // TODO Fehler in GUI anzeigen
            Log.error(
                "[VoucherPhotoListController] Upload voucher file is nor an image neighter a pdf: content-type=" +
                    voucherFile.getContentType(),
            );
        }
    }

    public getVoucherFilesCount(): number {
        return this.countVoucherFiles;
    }
}
