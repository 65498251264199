import { GUIElement } from "../GUIElement";
import { Image } from "./Image";

export class Link implements GUIElement {
    private imageName: string = null;

    private text: string = null;

    private styleClass: string = null;

    private clickCallback: Function;

    private guiElements: GUIElement[] = [];

    private $linkTag: JQuery = null;

    constructor() {}

    public setImageName(imageName: string): Link {
        this.imageName = imageName;
        return this;
    }

    public setText(text: string): Link {
        this.text = text;
        return this;
    }

    public setStyleClass(styleClass: string): Link {
        this.styleClass += " " + styleClass;
        return this;
    }

    public onClick(clickCallback: Function): Link {
        this.clickCallback = clickCallback;
        return this;
    }

    /**
     * HTML-DOM generieren
     *
     * @param $parent Parent-jQuery-Objekt
     */
    public compose($parent: JQuery): void {
        this.$linkTag = $("<a>")
            .appendTo($parent)
            .addClass("link " + this.styleClass)
            .css("cursor", "pointer")
            .on("click", this.notifyClicked.bind(this));

        if (this.imageName) {
            new Image().setImageName(this.imageName).compose(this.$linkTag);
        }
        if (this.text) {
            this.$linkTag.text(this.text);
        }

        for (let i = 0; i < this.guiElements.length; i++) {
            this.guiElements[i].compose(this.$linkTag);
        }
    }

    public addElement(guiElement: GUIElement) {
        this.guiElements.push(guiElement);
    }

    public notifyClicked(event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        if (this.clickCallback) {
            this.clickCallback(event);
        }
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }

    public adhocRemoveClass(styleclass: string) {
        this.$linkTag.removeClass(styleclass);
    }

    public adhocAddClass(styleclass: string) {
        this.$linkTag.addClass(styleclass);
    }
}
