import { UserSession } from "../common/auth/UserSession";
import { BCSDate } from "../common/BCSDate";
import { ServerConfigProperties } from "../common/config/ServerConfigProperties";
import { I18n } from "../common/i18n/I18n";
import { AppConsole } from "../common/log/AppConsole";
import { Log } from "../common/log/Log";
import { PreferencesManager } from "../common/preferences/PreferencesManager";
import { Component } from "../core/Component";
import { ConfigNode } from "../core/Config/ConfigNode";
import { Controller } from "../core/Controller";
import { MessageEntity, MessageType } from "../core/Message/MessageEntity";
import { MessagePool } from "../core/Message/MessagePool";
import { Registry } from "../core/Registry";
import { Animation, AppNavigator } from "../core/Router";
import { AllowanceManager } from "../domain/allowances/AllowanceManager";
import { ContactManager } from "../domain/contactRecording/ContactManager";
import { AttendanceClockState } from "../domain/time_recording/attendance/AttendanceClock";
import { AttendanceClockControl } from "../domain/time_recording/attendance/AttendanceClockControl";
import { BookingsPerDay } from "../domain/time_recording/bookings/BookingsPerDay";
import { ForecastController } from "../domain/time_recording/forecast/ForecastController";
import { ForecastManager } from "../domain/time_recording/forecast/ForecastManager";
import { TimeRecordingManager } from "../domain/time_recording/TimeRecordingManager";
import { BoardElement } from "../gui/board/BoardElement";
import { BoardView } from "../gui/board/BoardView";
import { CalendarControll } from "../gui/content/CalendarControll";
import { Link } from "../gui/content/Link";
import { SubMenu } from "../gui/content/SubMenu";
import { GUIEventPool } from "../gui/event/GUIEventPool";
import { GUIContext } from "../gui/GUIContext";
import { GUIPage } from "../gui/GUIPage";
import { NavigationBar } from "../gui/navigation/NavigationBar";
import { SyncStateManager } from "../sync/SyncStateManager";
import { CurrentTimeRecordingDetailsBoardElement } from "./elements/CurrentTimeRecordingDetailsBoardElement";
import { FooterTabBar, FooterTabIcon } from "./FooterTabBar";
import { CalendarOverviewBoardElement } from "./elements/CalendarOverviewBoardElement";
import { ForecastBoardElement } from "./elements/ForecastSummaryBoardElement";
import { TimeRecordingOptions } from "../domain/time_recording/TimeRecordingOptions";
import { AttendanceClockBoardElement } from "./elements/AttendanceClockBoardElement";
import { ContactBoardElement } from "./elements/ContactBoardElement";
import { AllowanceTravelBoardElement } from "./elements/AllowanceTravelBoardElement";
import { AllowanceSummaryBoardElement } from "./elements/AllowanceSummaryBoardElement";
import { PlaceholderElement } from "./elements/PlaceholderElement";
import { BoardGUIDefinitions } from "../core/Config/BoardGUIDefinitions";

/**
 * Der BoardController initialisiert alle Boardelemente und fragt die dafür benötigten Daten ab. Hierüber können auch weitere Elemente hinzugefügt werden.
 */
export class BoardController implements Component, Controller {
    private navigator: AppNavigator;
    private parameters: { [key: string]: string };
    private animation: Animation;

    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static BCS_COMPONENT_NAME = "index";

    private context: GUIContext;

    private userSession: UserSession;

    private i18n: I18n;

    private eventPool: GUIEventPool;

    private messagePool: MessagePool;

    private timeRecordingManager: TimeRecordingManager;

    private allowanceManager: AllowanceManager;

    private contactManager: ContactManager;

    private forecastManager: ForecastManager;

    private syncStateManager: SyncStateManager;

    private preferencesManager: PreferencesManager;

    private page: GUIPage;

    private overviewBoard: BoardView;

    constructor() {
        this.eventPool = new GUIEventPool();
        this.registerEvents();
    }

    public getDependencyNames(): string[] {
        return [
            UserSession.BCS_COMPONENT_NAME,
            I18n.BCS_COMPONENT_NAME,
            MessagePool.BCS_COMPONENT_NAME,
            TimeRecordingManager.BCS_COMPONENT_NAME,
            AllowanceManager.BCS_COMPONENT_NAME,
            SyncStateManager.BCS_COMPONENT_NAME,
            ServerConfigProperties.BCS_COMPONENT_NAME,
            ContactManager.BCS_COMPONENT_NAME,
            ForecastManager.BCS_COMPONENT_NAME,
            PreferencesManager.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }): void {
        //Setzt die Theme Color nur für das Boardelement um
        if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
            $("meta[name='theme-color']").attr("content", "#121212"); // Farbe für den Darkmode
        } else {
            $("meta[name='theme-color']").attr("content", "#EBEFF3"); // Farbe für den Lightmode
        }

        this.userSession = <UserSession>depencencyComponents[UserSession.BCS_COMPONENT_NAME];

        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];

        this.context = new GUIContext(this.i18n, this.eventPool);

        this.messagePool = <MessagePool>depencencyComponents[MessagePool.BCS_COMPONENT_NAME];

        this.timeRecordingManager = <TimeRecordingManager>(
            depencencyComponents[TimeRecordingManager.BCS_COMPONENT_NAME]
        );

        this.allowanceManager = <AllowanceManager>(
            depencencyComponents[AllowanceManager.BCS_COMPONENT_NAME]
        );

        this.syncStateManager = <SyncStateManager>(
            depencencyComponents[SyncStateManager.BCS_COMPONENT_NAME]
        );

        this.contactManager = <ContactManager>(
            depencencyComponents[ContactManager.BCS_COMPONENT_NAME]
        );

        this.forecastManager = <ForecastManager>(
            depencencyComponents[ForecastManager.BCS_COMPONENT_NAME]
        );

        this.preferencesManager = <PreferencesManager>(
            depencencyComponents[PreferencesManager.BCS_COMPONENT_NAME]
        );
    }

    public registerEvents() {
        this.eventPool.registerEventListener(
            "Message",
            "remove",
            this.removeFromMessagePool.bind(this),
        );
        this.eventPool.registerEventListener(
            "ControllerLink",
            "click",
            this.navigateToPage.bind(this),
        );
        this.eventPool.registerEventListener(
            CalendarControll.BCS_COMPONENT_NAME,
            "click",
            this.navigateToPage.bind(this),
        );

        this.eventPool.registerEventListener(
            AttendanceClockControl.BCS_COMPONENT_NAME,
            "clickedAttendance",
            this.clickedAttendanceBTN.bind(this),
        );
        this.eventPool.registerEventListener(
            AttendanceClockControl.BCS_COMPONENT_NAME,
            "clickedPause",
            this.clickedPauseeBTN.bind(this),
        );
        this.eventPool.registerEventListener(
            SubMenu.BCS_COMPONENT_NAME,
            "openSubmenu",
            this.openSubMenu.bind(this),
        );
        this.eventPool.registerEventListener(
            NavigationBar.BCS_COMPONENT_NAME,
            NavigationBar.ICON_CLICKED_TRIGGER,
            this.navigateToPageByBar.bind(this),
        );
    }

    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        const self = this;
        this.fetchDataAndInitializeBoardElements(parameters, animation, navigator)
            .then(() => self.preCompose(parameters, animation, navigator))
            .catch((error) =>
                Log.error("[BoardController] Error fetch data for composing", error, error),
            );
    }

    private async fetchDataAndInitializeBoardElements(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): Promise<void> {
        const timeRecordingOptions: TimeRecordingOptions =
            this.timeRecordingManager.getRecordingOptions();
        this.overviewBoard = new BoardView(
            6,
            BoardGUIDefinitions.getBoardGUIDefinitions(),
            this.context,
        );

        // Optionen der Zeiterfassung abholen und weiterreichen (z.b. für Rechteabfrage und für den TimerecordingTacho)
        if (
            timeRecordingOptions.isTimeRecordingEnabled() &&
            !timeRecordingOptions.getUserLicenses().includes("License_TicketCustomer")
        ) {
            // Kalender-Übersicht
            const calendarOverviewConf: ConfigNode = new ConfigNode(
                CalendarOverviewBoardElement.ELEMENT_NAME,
            );
            const self = this;
            try {
                var bookings = await this.timeRecordingManager.getBookingsBetweeenDates(
                    BCSDate.getToday().subtractMonth(1),
                    BCSDate.getToday(),
                );
            } catch (error: any) {
                Log.error("[BoardController] Error while get bookings:", error, error);

                // Buchungen können nicht von BCS angefragt werden, dann wahrscheinlich offline
                this.userSession.setToOfflineMode();
                // this.preCompose(this.parameters, this.animation, this.navigator);
            }

            try {
                var timespans = await self.timeRecordingManager.getTimeSpansBetweeenDates(
                    BCSDate.getToday().subtractMonth(1),
                    BCSDate.getToday(),
                );
            } catch (error: any) {
                AppConsole.log(error);
                Log.error("[BoardController] Error while get timespans:", error, error);
            }

            const bookingsPerDay = new BookingsPerDay(bookings, timespans);
            this.context.setBookingsPerDay(bookingsPerDay);

            const calendarOverview: BoardElement = new CalendarOverviewBoardElement(
                calendarOverviewConf,
                this.context,
                bookingsPerDay,
            );
            this.overviewBoard.addBoardElement(calendarOverview);

            // Im folgenden werden die (restlichen, abgesehen vom Kalender) Bordelemente spezifisch zusammengebaut. Weitere Elemente können mit identischem Schema hier ergänzt werden

            // Übersicht über heutige Buchungen
            const currentTimeRecordingDetailsConf: ConfigNode = new ConfigNode(
                CurrentTimeRecordingDetailsBoardElement.ELEMENT_NAME,
            );
            const useAttendances: boolean =
                this.preferencesManager.getEffortRecordingWithAttendances();
            const currentTimeRecordingDetails: BoardElement =
                new CurrentTimeRecordingDetailsBoardElement(
                    currentTimeRecordingDetailsConf,
                    this.context,
                    timeRecordingOptions,
                    useAttendances,
                );
            this.overviewBoard.addBoardElement(currentTimeRecordingDetails);
        }

        // Spesen (Aktuelle/Neue Dienstreise und Anzahl Reisen/Belege)
        const allowanceSummary = await this.allowanceManager.fetchAllowanceSummary();
        if (allowanceSummary.isAllowanceRecordingAvailable()) {
            const allowanceTravelConf: ConfigNode = new ConfigNode(
                AllowanceTravelBoardElement.ELEMENT_NAME,
            );
            const allowanceSummaryConf: ConfigNode = new ConfigNode(
                AllowanceSummaryBoardElement.ELEMENT_NAME,
            );
            this.overviewBoard.addBoardElement(
                new AllowanceTravelBoardElement(
                    allowanceSummary,
                    this.i18n,
                    allowanceTravelConf,
                    this.context,
                ),
            );
            this.overviewBoard.addBoardElement(
                new AllowanceSummaryBoardElement(
                    allowanceSummary,
                    this.i18n,
                    allowanceSummaryConf,
                    this.context,
                ),
            );
        }

        // Restaufwandsschätzungen
        const forecastSummary = await this.forecastManager.fetchForecastSummary();
        if (forecastSummary.forecastAvailable) {
            const forecastElementConf: ConfigNode = new ConfigNode(
                ForecastBoardElement.ELEMENT_NAME,
            );
            this.overviewBoard.addBoardElement(
                new ForecastBoardElement(forecastElementConf, this.context, forecastSummary),
            );
        }

        // AnwesenheitsStoppuhr
        // Optionen der Zeiterfassung abholen und weiterreichen (für den TimerecordingTacho)
        if (
            timeRecordingOptions.isAttendanceClockEnabled() &&
            this.userSession.isOnline() &&
            !"License_TicketCustomer".includes(timeRecordingOptions.getUserLicenses())
        ) {
            const attendanceClockConf: ConfigNode = new ConfigNode(
                AttendanceClockBoardElement.ELEMENT_NAME,
            );
            this.context.setAttendanceClockEntity(this.timeRecordingManager.getAttendanceClock());
            const attendanceClock: BoardElement = new AttendanceClockBoardElement(
                attendanceClockConf,
                this.context,
            );
            this.overviewBoard.addBoardElement(attendanceClock);
        }

        // Kontaktelement
        const contactSummary = await this.contactManager.fetchContactSummary();
        if (contactSummary.contactRecordingAvailable) {
            const contactElementConf: ConfigNode = new ConfigNode(ContactBoardElement.ELEMENT_NAME);
            const contactBoard: BoardElement = new ContactBoardElement(
                contactElementConf,
                this.context,
                contactSummary,
                this.i18n,
            );
            this.overviewBoard.addBoardElement(contactBoard);
        }

        if (this.overviewBoard.getBoardElements().length == 0) {
            const placeholderConf: ConfigNode = new ConfigNode(PlaceholderElement.ELEMENT_NAME);
            const placeholderElement: BoardElement = new PlaceholderElement(
                placeholderConf,
                this.context,
                this.i18n,
            );
            this.overviewBoard.addBoardElement(placeholderElement);
        }
    }

    public preCompose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ) {
        this.navigator = navigator;
        this.animation = animation;
        this.parameters = parameters;

        this.page = new GUIPage(this.context, BoardController.BCS_COMPONENT_NAME);
        this.page.addStyleClass(BoardController.BCS_COMPONENT_NAME);

        this.page.composeMessagesContainer();
        // Dynamische Größenbestimmung
        this.overviewBoard.updateDimensions();

        this.page.addPageElement(this.overviewBoard);
        this.page.setMinHeightFunction(this.overviewBoard.getBoardHeight.bind(this.overviewBoard));
        const navigationbar = new NavigationBar();
        this.syncStateManager
            .countUnsyncedElements()
            .then((count: number) => {
                navigationbar.setSyncBadge(count);

                navigationbar.composeNavigationBar(
                    this.page,
                    this.context,
                    BoardGUIDefinitions.getFooterDefinition(),
                    BoardController.BCS_COMPONENT_NAME,
                );
                this.page
                    .setAnimation(this.animation, this.navigator.doShowAnimations())
                    .compose($("body"));

                // Auf Änderung Hoch-/Querformat bzw. Größe (Desktop) reagieren
                this.registerRotateAndResizeEvents();
            })
            .catch((e) => console.error(e));

        // Für Tests...
        const linkGoOffline = new Link().setStyleClass("hidden setToOfflineMode").onClick(() => {
            this.userSession.setToOfflineMode();
        });
        this.page.addPageElement(linkGoOffline);
        const linkGoOnline = new Link().setStyleClass("hidden setToOnlineMode").onClick(() => {
            this.userSession.setToOnlineMode();
        });
        this.page.addPageElement(linkGoOnline);

        if (parameters[ForecastController.PARAMETER_RETURN_MESSAGE]) {
            this.messagePool.addMessage(
                MessageEntity.createFromJSON(
                    parameters[ForecastController.PARAMETER_RETURN_MESSAGE],
                ),
            );
            this.composeMessages();
        }
    }

    public composeMessages() {
        const messages: MessageEntity[] = this.messagePool.getMessages();
        this.page.composeMessages(messages, this.context);
    }

    private navigateToPage(clickEvent: Event, clickContext: any): void {
        if (clickContext.navigate !== BoardController.BCS_COMPONENT_NAME) {
            this.navigator.pushPage({}, BoardController.BCS_COMPONENT_NAME);
        }

        this.navigator.navigateTo(clickContext.navigate, clickContext, Animation.SLIDE_LEFT);
    }

    private navigateToPageByBar(clickEvent: Event, clickContext: FooterTabIcon): void {
        if (clickContext.iconName !== FooterTabBar.ICON_OVERVIEW) {
            this.navigateToPage(clickEvent, { navigate: clickContext.iconName });
        }
    }

    public toolLinkNavigateToPage(targetComponentName: string): void {
        this.navigateToPage(null, { navigate: targetComponentName });
    }

    public clickedAttendanceBTN(event, transferObject): void {
        const attendanceControll: AttendanceClockControl = <AttendanceClockControl>(
            transferObject[AttendanceClockControl.BCS_COMPONENT_NAME]
        );

        const attendanceClock = this.context.getAttendanceClockEntity();

        if (attendanceClock.getPauseState() == AttendanceClockState.PauseRunning) {
            const message = new MessageEntity(
                this.i18n.get("MobileApp.UI_Error_Pause_Runnning_NotStoppingAttendance"),
                MessageType.ERROR,
            );
            this.messagePool.addMessage(message);
            AppConsole.error("AddError DEBUG");
        } else {
            if (
                attendanceClock.getAttendanceState() == AttendanceClockState.None ||
                attendanceClock.getAttendanceState() == AttendanceClockState.AttandanceStoped
            ) {
                attendanceClock.startAttendance();
            } else if (
                attendanceClock.getAttendanceState() == AttendanceClockState.AttandanceRuning
            ) {
                attendanceClock.stopAttendance();
            }

            const self = this;

            this.timeRecordingManager.clickedAttendanceClock(attendanceClock).then(function (
                result: object,
            ) {
                if (result["success"]) {
                    attendanceClock.transferedState();
                }
                attendanceControll.adjustButtonState();
                attendanceControll.reComposeCurrentAttendances();
                self.composeMessages();
            });
        }
        this.composeMessages();
    }

    public clickedPauseeBTN(event, transferObject): void {
        const attendanceControll: AttendanceClockControl = <AttendanceClockControl>(
            transferObject[AttendanceClockControl.BCS_COMPONENT_NAME]
        );

        const attendanceClock = this.context.getAttendanceClockEntity();

        const noAttendanceRunning: boolean =
            attendanceClock.getAttendanceState() == AttendanceClockState.None ||
            attendanceClock.getAttendanceState() == AttendanceClockState.AttandanceStoped;

        if (
            attendanceClock.getAttendanceState() == AttendanceClockState.AttandanceRuning &&
            attendanceClock.getPauseState() != AttendanceClockState.PauseRunning
        ) {
            attendanceClock.startPause();
        } else if (attendanceClock.getPauseState() == AttendanceClockState.PauseRunning) {
            attendanceClock.stopPause();
        }

        const self = this;
        if (noAttendanceRunning) {
            const message = new MessageEntity(
                this.i18n.get("MobileApp.UI_Error_No_Attendance_Runnning"),
                MessageType.ERROR,
            );
            this.messagePool.addMessage(message);
            AppConsole.error("AddError DEBUG");
        } else {
            this.timeRecordingManager.clickedAttendanceClock(attendanceClock).then(function (
                result: object,
            ) {
                if (result["success"]) {
                    attendanceClock.transferedState();
                }

                attendanceControll.adjustButtonState();
                attendanceControll.reComposeCurrentAttendances();
                self.composeMessages();
            });
        }
        self.composeMessages();
    }

    private removeFromMessagePool(event, transferObject: object) {
        if (transferObject.hasOwnProperty("messageEntity")) {
            this.messagePool.removeMessage(transferObject["messageEntity"]);
        } else {
            Log.debug(
                "[TimeRecordingController] Error while removing message in GUI.:" +
                    JSON.stringify(transferObject) +
                    " event: " +
                    JSON.stringify(event),
            );
        }
    }

    private openSubMenu(event, transferObject: object) {
        const callback = (action: string, navigationContext?: {}) => {
            if (action === "logout") {
                this.userSession.logout();
                this.navigateToPage(null, navigationContext);
            } else if (action === "navigate") {
                this.navigateToPage(null, navigationContext);
            }
        };

        this.page.openSubMenu(event, transferObject, this.i18n, callback);
    }

    /**
     * Auf Änderung Hoch-/Querformat bzw. Größe (Desktop) reagieren (beiders feuert das Event "resize")
     */
    private registerRotateAndResizeEvents(): void {
        $(window).one("resize.board", this.reComposeAfterRotationOrResize.bind(this));
    }

    /**
     * Board neu composen nach Änderung Hoch-/Querformat bzw. Größe (Desktop).
     */
    private reComposeAfterRotationOrResize(): void {
        this.preCompose(this.parameters, Animation.NONE, this.navigator);
    }

    public popState(): void {
        // TODO siehe Controller.popState
    }

    public destroy(): void {
        //Setzt die Theme Color für alle anderen Seiten
        if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
            $("meta[name='theme-color']").attr("content", "#093552"); // Farbe für den Darkmode
        } else {
            $("meta[name='theme-color']").attr("content", "#CCD8E0"); // Farbe für den Lightmode
        }
        $(window).off("resize.board");
    }
}

Registry.registerComponent(BoardController.BCS_COMPONENT_NAME, BoardController);
