import { Preferences } from "./Preferences";
import { IdGenerator } from "../../util/text/IdGenerator";
import { AttributeDefinition } from "../schema/AttributeDefinition";
import { EntityValue } from "../../entities/values/EntityValue";
import { Entity } from "../../entities/Entity";
import { TypeSubtypeDefinition } from "../schema/TypeSubtypeDefinition";

export class LocalPreferences extends Preferences {
    private preferencesEntity: Entity;

    private id: string;

    constructor() {
        super();
        this.id = IdGenerator.createId() + "_Profiles";

        const schema = {
            showDescriptionInDayTimeRecordingRows: { default: "false", datatype: "Bool" },
        };

        const typeSubtypeDefinition = new TypeSubtypeDefinition("profiles", "local", schema);

        const preferences = { showDescriptionInDayTimeRecordingRows: false };

        this.preferencesEntity = new Entity(typeSubtypeDefinition, preferences);
    }

    getId(): string {
        return this.id;
    }

    getSubtype(): string {
        return "localPrefernces";
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return this.preferencesEntity.getTypeSubtypeDefinition().getAttributeDefinition(name);
    }

    public getValue(name: string): EntityValue {
        return this.preferencesEntity.getValue(name);
    }

    public setValue(name: string, value: EntityValue): void {
        this.preferencesEntity.setValue(name, value);
    }

    toValueObject(): object {
        throw new Error("Method not implemented.");
    }
}
