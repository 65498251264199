import { GUIElement } from "../GUIElement";
import { FormField, formFieldChangeEventType, FormFieldMode } from "./FormField";
import { StringField } from "./StringField";
import { StringValue } from "../../entities/values/StringValue";
import { FormFieldAddOn } from "./FormFieldAddOn";

export class PasswordField implements GUIElement, FormField {
    private stringField: StringField;

    constructor() {
        this.stringField = new StringField().setType("password");
    }

    public setEntityId(entityId: string): FormField {
        this.stringField.setEntityId(entityId);
        return this;
    }

    public setReadOnly(readOnly: boolean): FormField {
        this.stringField.setReadOnly(readOnly);
        return this;
    }

    public isReadOnly(): boolean {
        return this.stringField.isReadOnly();
    }

    public setName(name: string): PasswordField {
        this.stringField.setName(name);
        return this;
    }

    public getName(): string {
        return this.stringField.getName();
    }

    public setValue(value: StringValue): PasswordField {
        this.stringField.setValue(value);
        return this;
    }

    public setLabel(label: string): PasswordField {
        this.stringField.setLabel(label);
        return this;
    }

    public setPlaceholder(placeholder: string): PasswordField {
        this.stringField.setPlaceholder(placeholder);
        return this;
    }

    public setMode(mode: FormFieldMode): FormField {
        this.stringField.setMode(mode);
        return this;
    }

    public getMode(): FormFieldMode {
        return this.stringField.getMode();
    }

    public registerFormFieldAddOn(formFieldAddOn: FormFieldAddOn): FormField {
        // untetstützt bislang bislang keine AddOns
        return this;
    }

    public onChange(
        changeCallback: (changeEvent: formFieldChangeEventType) => void,
    ): PasswordField {
        this.stringField.onChange(changeCallback);
        return this;
    }

    public onSubmit(submitCallback: () => void): PasswordField {
        this.stringField.onSubmit(submitCallback);
        return this;
    }

    public compose($parent: JQuery): void {
        this.stringField.compose($parent);
    }

    public updateValue(value: StringValue): PasswordField {
        this.stringField.updateValue(value);
        return this;
    }

    public getValue(): StringValue {
        return this.stringField.getValue();
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}
