import { ConfigNode } from "../../core/Config/ConfigNode";
import { AttendanceClockControl } from "../../domain/time_recording/attendance/AttendanceClockControl";
import { BoardElement } from "../../gui/board/BoardElement";
import { GUIContext } from "../../gui/GUIContext";

/*
<BoardElement PosX= "1" PosY= "13" SizeX= "6" SizeY="8" Name="attendanceClock">
                    <AttendanceClockControl/>
                </BoardElement> */
/**
 * Anwesenheits-Stoppuhr als Board-Element:
 * - Enthält start-/stoppbare Uhr für das Erfassen von Anwesenheiten
 * - Wird irgendwie nur von einem exotischen Kunden verwendet
 */
export class AttendanceClockBoardElement extends BoardElement {
    static readonly ELEMENT_NAME: string = "attendanceClock";

    constructor(boardElementConfig: ConfigNode, context: GUIContext) {
        super(boardElementConfig, context);

        this.name = AttendanceClockBoardElement.ELEMENT_NAME;

        this.xTiles = boardElementConfig.getIntAttribute("PosX", 1);
        this.yTiles = boardElementConfig.getIntAttribute("PosY", 13);
        this.widthTiles = boardElementConfig.getIntAttribute("SizeX", 6);
        this.heightTiles = boardElementConfig.getIntAttribute("SizeY", 3);

        this.wholeContainerLinksTo = null;

        this.styleClasses.push(this.name);
    }

    protected initializeChildren() {
        this.context.setPossibleContentHeight(
            this.currentTileHeight * this.heightTiles - this.CSS_ELEMENT_MARGIN * 2,
        );
        this.guiElements = [
            new AttendanceClockControl(
                this.configNode.getChildByTagOrEmpty(AttendanceClockControl.BCS_COMPONENT_NAME),
                this.context,
            ),
        ];
    }
}
