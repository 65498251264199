import { Entity } from "../../../entities/Entity";
import { IdGenerator } from "../../../util/text/IdGenerator";
import { Allowance } from "./Allowance";
import { EntityValue } from "../../../entities/values/EntityValue";
import { TypeSubtypeDefinition } from "../../../common/schema/TypeSubtypeDefinition";
import { AttributeDefinition } from "../../../common/schema/AttributeDefinition";
import { TravelSection } from "./TravelSection";
import { DateTimeValue } from "../../../entities/values/DateTimeValue";
import { DailyAllowance } from "./DailyAllowance";
import { TravelAllowance } from "./TravelAllowance";
import { DurationValue } from "../../../entities/values/DurationValue";

export class TravelDurationAllowance implements Allowance, TravelAllowance {
    public static readonly TYPE = "JAllowance";

    public static readonly SUBTYPE = "travelDurationAllowance";

    public static readonly USER_OID = "allowanceUserOid";

    public static readonly TRAVEL_DURATION_ARRIVAL = "allowanceTravelDurationArrival";

    public static readonly TRAVEL_DURATION_DEPARTURE = "allowanceTravelDurationDeparture";

    /** Reiseabschnitt zu dem diese Reisedauerspese gehört */
    private travelSection: TravelSection;

    private allowanceEntity: Entity;

    public static create(
        travelSection: TravelSection,
        typeSubtypeDefinition: TypeSubtypeDefinition,
        userOid: string,
        businessTravelOid: string,
    ): TravelDurationAllowance {
        const allowanceValueObject = {
            oid: IdGenerator.createId() + "_" + TravelDurationAllowance.TYPE,
            typ: TravelDurationAllowance.TYPE,
            subtyp: TravelDurationAllowance.SUBTYPE,
            allowanceUserOid: userOid,
            allowanceTravelOid: businessTravelOid,
        };

        const dailyAllowance = new TravelDurationAllowance(
            travelSection,
            typeSubtypeDefinition,
            allowanceValueObject,
            true,
        );

        return dailyAllowance;
    }

    constructor(
        travelSection: TravelSection,
        typeSubtypeDefinition: TypeSubtypeDefinition,
        allowanceValueObject: object,
        isNew = false,
    ) {
        this.travelSection = travelSection;
        this.allowanceEntity = new Entity(typeSubtypeDefinition, allowanceValueObject, isNew);
    }

    public getId(): string {
        return this.allowanceEntity.getId();
    }

    public getSubtype(): string {
        return TravelDurationAllowance.SUBTYPE;
    }

    public getUserOid(): string {
        return this.allowanceEntity.getString(TravelDurationAllowance.USER_OID);
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return this.allowanceEntity.getTypeSubtypeDefinition().getAttributeDefinition(name);
    }

    public setStartDateTime(startDateTime: DateTimeValue): void {
        this.allowanceEntity.setValue(DailyAllowance.START_DATE_TIME, startDateTime);
    }

    public setEndDateTime(endDateTime: DateTimeValue): void {
        this.allowanceEntity.setValue(DailyAllowance.END_DATE_TIME, endDateTime);
    }

    public getValue(name: string): EntityValue {
        return this.allowanceEntity.getValue(name);
    }

    public setValue(name: string, value: EntityValue): void {
        this.allowanceEntity.setValue(name, value);
    }

    public hasAmout(): boolean {
        const travelDurationArrivalValue = this.allowanceEntity.getValue(
            TravelDurationAllowance.TRAVEL_DURATION_ARRIVAL,
        );
        const travelDurationDepartureValue = this.allowanceEntity.getValue(
            TravelDurationAllowance.TRAVEL_DURATION_DEPARTURE,
        );
        return (
            (travelDurationArrivalValue.isDefined() &&
                (<DurationValue>travelDurationArrivalValue).getDurationInMinutes() > 0) ||
            (travelDurationDepartureValue.isDefined() &&
                (<DurationValue>travelDurationDepartureValue).getDurationInMinutes() > 0)
        );
    }

    public isEditable(): boolean {
        return this.travelSection.isEditable();
    }

    public isDeletable(): boolean {
        return false;
    }

    public toValueObject(): object {
        return this.allowanceEntity.toValueObject();
    }

    /**
     * @returns Warnung durch letzte Attributänderungen (seit letzter Abfrage)
     */
    public getAndClearChangeWarningKey(): string {
        return null;
    }
}
