import { EntityValue } from "./EntityValue";
import { BCSDate } from "../../common/BCSDate";

export class TimeValue implements EntityValue {
    private value: string;

    public static parseFromString(strValue: string) {
        return new TimeValue(strValue);
    }

    public static fromMinutes(minutes: number) {
        return new TimeValue(BCSDate.minnutesToDisplayTime(minutes));
    }

    constructor(simpleValue: string) {
        // TODO: Jens quickfix, der server sendet schon 00:-1 für solche Timeattribute.
        if (simpleValue != null) {
            if (typeof simpleValue === "undefined" || simpleValue.length == 0) {
                simpleValue = "00:00";
            }
        }

        this.value = simpleValue;
    }

    public isDefined(): boolean {
        // Minuten = -1 bedeutet in BCS undefiniert
        return !!this.value && this.value !== "00:-1" && this.value !== "0:-1";
    }

    public getSimpleValue(): string | number | boolean {
        return this.value;
    }

    public getString(): string {
        return this.value ? this.value : "";
    }

    public format(): string {
        return this.getString();
    }

    public getHours(): number {
        return this.value ? parseInt(this.value.substr(0, 2)) : 0;
    }

    public getMinutes(): number {
        return this.value ? parseInt(this.value.substr(3, 2)) : 0;
    }

    public getTimeInMinutes(): number {
        return this.getMinutes() + this.getHours() * 60;
    }
}
