import { Component } from "../../core/Component";
import { Registry } from "../../core/Registry";
import { SingletonComponent } from "../../core/SingletonComponent";
import { IdGenerator } from "../../util/text/IdGenerator";
import { AppConsole } from "../log/AppConsole";
import { ApplicationProperties } from "../properties/ApplicationProperties";
import { ServerConfigPropertiesClient } from "./ServerConfigPropertiesClient";

/**
 * Bietet ausgewählte ServerConfig-Properties zur Verwendung in der App.
 *
 * ServerConfig-Properties werden per REST von BCS gelesen.
 */
export type PropertySet = { [key: string]: string };

export class ServerConfigProperties implements Component, SingletonComponent {
    /** Name der Singleton-Komponente */
    public static BCS_COMPONENT_NAME = "ServerConfigProperties";

    private static EMPTY_PROPERTIES: { [key: string]: object } = {
        properties: {},
        sets: {},
        propertysets: {},
    };

    private applicationProperties: ApplicationProperties;

    private serverConfigProperties: { [key: string]: object } =
        ServerConfigProperties.EMPTY_PROPERTIES;

    private serverConfigAge: number;

    public getDependencyNames(): string[] {
        return [ApplicationProperties.BCS_COMPONENT_NAME];
    }

    public init(depencencyComponents: { [key: string]: Component }): void {
        this.applicationProperties = <ApplicationProperties>(
            depencencyComponents[ApplicationProperties.BCS_COMPONENT_NAME]
        );
    }

    public start(): Promise<void> {
        return Promise.resolve();
    }

    public async notifyBeginUserSession(isOnline: boolean): Promise<void> {
        await this.readPropertiesFromDatabase();
        if (isOnline) {
            this.readPropertiesFromBCS();
        }
    }

    public synchronize(): Promise<void> {
        return this.readPropertiesFromBCS();
    }

    async readPropertiesFromDatabase(): Promise<void> {
        const propertiesResult = await this.applicationProperties.readProperty(
            ServerConfigProperties.BCS_COMPONENT_NAME,
            null,
            null,
        );
        if (propertiesResult) {
            this.serverConfigProperties = propertiesResult["serverconfig"];
            this.serverConfigAge = propertiesResult["age"];
        } else {
            this.serverConfigProperties = ServerConfigProperties.EMPTY_PROPERTIES;
            this.serverConfigAge = 0;
        }

        this.preProcessProperties();
    }

    async readPropertiesFromBCS(): Promise<void> {
        const propertiesResult =
            await new ServerConfigPropertiesClient().fetchServerConfigProperties(
                this.serverConfigAge,
            );

        if (propertiesResult["serverconfig"] && propertiesResult["cache"] != "uptodate") {
            // wenn lokal gespeicherte ServerConfig-Properties nicht mehr aktuell,
            // von BCS geladene ServerConfig-Properties verwenden und lokal speichern
            this.serverConfigProperties = propertiesResult["serverconfig"];
            this.serverConfigAge = propertiesResult["age"];

            await this.applicationProperties.writeProperty(
                ServerConfigProperties.BCS_COMPONENT_NAME,
                null,
                null,
                propertiesResult,
            );

            this.preProcessProperties();
        }
    }

    /**
     * @returns ServerConfig-Property
     */
    public getProperty(name: string, defaultValue: string = null): string {
        return this.serverConfigProperties["properties"][name] || defaultValue;
    }

    /**
     * @returns ServerConfig-Set
     */
    public getSet(name: string): string[] {
        return this.serverConfigProperties["sets"][name] || [];
    }

    /**
     * @returns ServerConfig-PropertySet
     */
    public getPropertySet(name: string): PropertySet {
        return this.serverConfigProperties["propertysets"][name] || {};
    }

    /**
     * Prozessiert Properties: U.a. Setzt globalen Oid-Prefix.
     */
    private preProcessProperties(): void {
        IdGenerator.setOidPrefix(this.getProperty("Oid.Instance.Prefix", ""));
    }

    /**
     * @returns ServerConfig-PropertySet
     */
    public getPropertySetOfLists(name: string): { [key: string]: string[] } {
        const propertySet = this.getPropertySet(name);

        const propertySetOfLists: { [key: string]: string[] } = {};
        for (const name in propertySet) {
            const value = propertySet[name] || "";
            propertySetOfLists[name] = value.split(" ");
        }
        return propertySetOfLists;
    }

    /**
     * Konfigurationsanpassungen: Attrtibute einblenden/ausblenden anwenden.
     *
     * @param originalGUIDefinitions Original GUI-Definitionen
     * @param pagePath Pfad der aktuellen Seite (z.B. "aaa" oder "bbb/ccc")
     * @param elementName Name des ListView-Elements (zur Untertscheidung von potentiell mehreren ListViews auf einer Seite)
     */
    public customizeGUIDefinitions(
        originalGUIDefinitions: object,
        pagePath: string,
        elementName: string,
        defaultFieldMode: "display" | "edit" = "edit",
    ): object {
        const guiDefinitionCustomizations = this.getPropertySetOfLists("MobileApp.GUIDefinitions");

        const customAddedGUIFieldNames: { [key: string]: string[] } = {};
        Object.keys(guiDefinitionCustomizations)
            .map((key) =>
                new RegExp(
                    "ADD[:.]([A-Za-z0-9_/-]+)[:.]([A-Za-z0-9_/-]+)[:.]([A-Za-z0-9_/-]+)",
                ).exec(key),
            )
            .filter((result) => !!result && result[1] == pagePath && result[2] == elementName)
            .forEach(
                (result) =>
                    (customAddedGUIFieldNames[result[3]] = guiDefinitionCustomizations[result[0]]),
            );

        let customRemovedGUIFieldNames: string[] = [];
        Object.keys(guiDefinitionCustomizations)
            .map((key) => new RegExp("REMOVE[:.]([A-Za-z0-9_/-]+)[:.]([A-Za-z0-9_/-]+)").exec(key))
            .filter((result) => !!result && result[1] == pagePath && result[2] == elementName)
            .forEach(
                (result) =>
                    (customRemovedGUIFieldNames = customRemovedGUIFieldNames.concat(
                        guiDefinitionCustomizations[result[0]],
                    )),
            );

        // Global deaktivierte Attribute entfernen (per ServerConfig oder automatisch z.B. "Arbeitszeittyp", wenn Baustein "Zuschläge" deaktiviert)    .
        if (guiDefinitionCustomizations.hasOwnProperty("REMOVE_GLOBAL")) {
            customRemovedGUIFieldNames = customRemovedGUIFieldNames.concat(
                guiDefinitionCustomizations["REMOVE_GLOBAL"],
            );
        }

        let customReadOnlyGUIFieldNames: string[] = [];
        Object.keys(guiDefinitionCustomizations)
            .map((key) => {
                return new RegExp("ReadOnly[:.]([A-Za-z0-9_/-]+)[:.]([A-Za-z0-9_/-]+)").exec(key);
            })
            .filter((result) => !!result && result[1] == pagePath && result[2] == elementName)
            .forEach(
                (result) =>
                    (customReadOnlyGUIFieldNames = customReadOnlyGUIFieldNames.concat(
                        guiDefinitionCustomizations[result[0]],
                    )),
            );

        if (
            Object.keys(customAddedGUIFieldNames).length == 0 &&
            customRemovedGUIFieldNames.length == 0 &&
            customReadOnlyGUIFieldNames.length == 0
        ) {
            return originalGUIDefinitions;
        }

        let addRemoveApplyedConfig = this.applyCustomizationToGUIDefinitions(
            originalGUIDefinitions,
            customAddedGUIFieldNames,
            customRemovedGUIFieldNames,
            defaultFieldMode,
        );

        if (Object.keys(customReadOnlyGUIFieldNames).length == 0) {
            return addRemoveApplyedConfig;
        }

        addRemoveApplyedConfig = this.applyReadOnlyCustomizationToGUIDefinitions(
            addRemoveApplyedConfig,
            customReadOnlyGUIFieldNames,
            defaultFieldMode,
        );

        return addRemoveApplyedConfig;
    }

    public applyCustomizationToGUIDefinitions(
        originalGUIDefinitions: object,
        customAddedGUIFieldNames: { [key: string]: string[] },
        customRemovedGUIFieldNames: string[],
        defaultFieldMode: "display" | "edit",
    ): object {
        // GUI-Definition kopieren
        const guiDefinition = JSON.parse(JSON.stringify(originalGUIDefinitions));

        const fieldsetDefinitions: object[] = guiDefinition["fieldsets"] || [];
        for (let s = 0; s < fieldsetDefinitions.length; s++) {
            const fieldsetDefinition = fieldsetDefinitions[s];
            const fieldDefinitions: object[] = fieldsetDefinition["fields"] || [];

            this.insertCustomFieldDefinition(
                fieldDefinitions,
                s.toString(),
                -1,
                customAddedGUIFieldNames,
                defaultFieldMode,
            );

            for (let f = 0; f < fieldDefinitions.length; f++) {
                const fieldDefinition = fieldDefinitions[f];
                const fieldName = fieldDefinition["field"]["name"];

                this.insertCustomFieldDefinition(
                    fieldDefinitions,
                    fieldName,
                    f,
                    customAddedGUIFieldNames,
                    defaultFieldMode,
                );
            }

            for (let f = 0; f < fieldDefinitions.length; f++) {
                const fieldDefinition = fieldDefinitions[f];
                const fieldName = fieldDefinition["field"]["name"];

                const wasDeleted = this.deleteCustomFieldDefinition(
                    fieldDefinitions,
                    fieldName,
                    f,
                    customRemovedGUIFieldNames,
                );
                if (wasDeleted) {
                    // Nach Löschen eines Feldes, Index um 1 zurückverschieben
                    f--;
                } else {
                    this.disableDeleteCellFieldDefinitions(
                        fieldDefinition,
                        customRemovedGUIFieldNames,
                    );
                }
            }
        }

        return guiDefinition;
    }

    private applyReadOnlyCustomizationToGUIDefinitions(
        originalGUIDefinitions: object,
        customReadOnlyGUIFieldNames: string[],
        defaultFieldMode: "display" | "edit",
    ): object {
        const fieldsetDefinitions: object[] = originalGUIDefinitions["fieldsets"] || [];
        for (let s = 0; s < fieldsetDefinitions.length; s++) {
            const fieldsetDefinition = fieldsetDefinitions[s];
            const fieldDefinitions: object[] = fieldsetDefinition["fields"] || [];

            for (let f = 0; f < fieldDefinitions.length; f++) {
                const fieldDefinition = fieldDefinitions[f];
                const fieldName = fieldDefinition["field"]["name"];
                if (typeof fieldName === "undefined") {
                    const stack = [];
                    stack.push(fieldDefinition);
                    while (stack.length !== 0) {
                        const current = stack.pop();
                        for (const key in current) {
                            const value = current[key];

                            if (current.hasOwnProperty(key)) {
                                AppConsole.log("analayse", typeof current[key]);
                                if (
                                    typeof current &&
                                    typeof current["field"] !== "undefined" &&
                                    typeof current["field"]["name"] !== "undefined"
                                ) {
                                    AppConsole.log(current[key]);
                                    this.setReadOnlyCustomFieldDefinition(
                                        current,
                                        current["field"]["name"],
                                        f,
                                        customReadOnlyGUIFieldNames,
                                    );
                                } else if (typeof current[key] === "object") {
                                    stack.push(current[key]);
                                }
                            }
                        }
                    }
                }

                this.setReadOnlyCustomFieldDefinition(
                    fieldDefinitions,
                    fieldName,
                    f,
                    customReadOnlyGUIFieldNames,
                );
            }
        }
        return originalGUIDefinitions;
    }

    /**
     * Gibt alle Feldnamen der gegebenen Gui Definition zurück.
     * Damit wissen wir beispielsweise, welche der Benutzer geändert haben könnte und können diese an den Server senden.
     * Da es Custom Attribute gibt, können wir nicht one Weiteres wissen, welche Attribute das sind.
     *
     * @untested
     * @param givenGUIDefinitions
     */
    public static getAllFieldNames(givenGUIDefinitions: object): string[] {
        const fieldNames: string[] = [];
        const fieldsetDefinitions: object[] = givenGUIDefinitions["fieldsets"] || [];
        for (let s = 0; s < fieldsetDefinitions.length; s++) {
            const fieldsetDefinition = fieldsetDefinitions[s];
            const fieldDefinitions: object[] = fieldsetDefinition["fields"] || [];

            for (let f = 0; f < fieldDefinitions.length; f++) {
                const fieldDefinition = fieldDefinitions[f];
                const stack = [];
                stack.push(fieldDefinition);
                while (stack.length !== 0) {
                    const current = stack.pop();
                    for (const key in current) {
                        if (current.hasOwnProperty(key)) {
                            const value = current[key];
                            if (
                                key === "field" &&
                                value !== undefined &&
                                value["name"] !== undefined
                            ) {
                                // if (typeof current && typeof current["field"] !== "undefined" && typeof current["field"]["name"] !== "undefined") {
                                fieldNames.push(value["name"]);
                            } else if (typeof current[key] === "object") {
                                stack.push(current[key]);
                            }
                        }
                    }
                }
            }
        }
        return fieldNames;
    }

    private insertCustomFieldDefinition(
        fieldDefinitions: object[],
        fieldName: string,
        position: number,
        customAddedGUIFieldNames: { [key: string]: string[] },
        defaultFieldMode: "display" | "edit",
    ): void {
        let addedGUIFieldNames: string[] = customAddedGUIFieldNames[fieldName] || [];
        addedGUIFieldNames = addedGUIFieldNames.slice().reverse();
        for (let a = 0; a < addedGUIFieldNames.length; a++) {
            const fieldNameAndMode = addedGUIFieldNames[a].split(",");
            const addedGUIFieldName = fieldNameAndMode[0];
            const addedGUIFieldMode = fieldNameAndMode[1] || defaultFieldMode;

            const addedFieldDefinition = {
                class: "field",
                field: {
                    icon: "NONE",
                    name: addedGUIFieldName,
                    mode: addedGUIFieldMode,
                },
            };

            fieldDefinitions.splice(position + 1, 0, addedFieldDefinition);
        }
    }

    /**
     * Löscht ein Feld aus GUI-Definition
     * @returns true wenn Feld gelöscht wurde
     */
    private deleteCustomFieldDefinition(
        fieldDefinitions: object[],
        fieldName: string,
        position: number,
        customRemovedGUIFieldNames: string[],
    ): boolean {
        if (customRemovedGUIFieldNames.indexOf(fieldName) >= 0) {
            fieldDefinitions.splice(position, 1);
            return true;
        }
        return false;
    }

    /**
     * Setzt in Table-Layout-Felder an allen Column-Field disabled=true, wenn per Konfiguration ausgeblendet.
     */
    private disableDeleteCellFieldDefinitions(
        fieldDefinition: object,
        customRemovedGUIFieldNames: string[],
    ) {
        if (fieldDefinition["class"] === "table") {
            if (fieldDefinition["field"] && fieldDefinition["field"]["rows"]) {
                const rowDefs = fieldDefinition["field"]["rows"];
                rowDefs.forEach((rowDef) => {
                    if (rowDef["columns"]) {
                        const columnDefs = rowDef["columns"];
                        columnDefs.forEach((columnDef) => {
                            if (
                                columnDef["field"] &&
                                customRemovedGUIFieldNames.indexOf(columnDef["field"]["name"]) >= 0
                            ) {
                                columnDef["disabled"] = true;
                            }
                        });
                    }
                });
            }
        }
    }

    /**
     * Löscht ein Feld aus GUI-Definition
     * @returns true wenn Feld gelöscht wurde
     */
    private setReadOnlyCustomFieldDefinition(
        fieldDefinitions: object[],
        fieldName: string,
        position: number,
        customRemovedGUIFieldNames: string[],
    ) {
        AppConsole.log(fieldName);
        if (customRemovedGUIFieldNames.indexOf(fieldName) >= 0) {
            fieldDefinitions["field"]["readonly"] = "readonly";
        }
    }

    public returnContactListDef(): string {
        const listMode: string = "MobileApp.ContactListMode";
        const small: string = "small";
        const big: string = "big";
        if (this.getProperty(listMode) != null) {
            if (this.getProperty(listMode).valueOf().toLowerCase() == big) {
                return big;
            } else {
                return small;
            }
        } else {
            return small;
        }
    }

    /**
     * @returns Property zur Ein / Abschaltung der Restaufwandsschätzung
     */
    public forecastEstimationEnabled(): boolean {
        return this.getProperty("MobileApp.ForecastEstimationEnabled", "false") === "true";
    }
}

Registry.registerSingletonComponent(
    ServerConfigProperties.BCS_COMPONENT_NAME,
    ServerConfigProperties,
);
