import { Entity } from "../../../entities/Entity";
import { IdGenerator } from "../../../util/text/IdGenerator";
import { Allowance } from "./Allowance";
import { EntityValue } from "../../../entities/values/EntityValue";
import { TypeSubtypeDefinition } from "../../../common/schema/TypeSubtypeDefinition";
import { AttributeDefinition } from "../../../common/schema/AttributeDefinition";
import { TravelSection } from "./TravelSection";
import { DateTimeValue } from "../../../entities/values/DateTimeValue";
import { DailyAllowance } from "./DailyAllowance";
import { TravelAllowance } from "./TravelAllowance";
import { NumberValue } from "../../../entities/values/NumberValue";

export class KilometreAllowance implements Allowance, TravelAllowance {
    public static readonly TYPE = "JAllowance";

    public static readonly SUBTYPE = "kilometreAllowance";

    public static readonly USER_OID = "allowanceUserOid";

    /** Anzahl gefahrener Kilometer */
    public static readonly TRAVEL_DISTANCE = "allowanceTravelDistance";

    /** Anzahl abrechenbarer gefahrener Kilometer */
    public static readonly TRAVEL_DISTANCE_INVOICABLE = "allowanceTravelDistanceInvoiceable";

    /** Pseudo-Attribute nur zur Verwendung in App: Gibt an, ob Kilometeranzahl vollständig abrechenbar ist. */
    public static readonly TRAVEL_DISTANCE_IS_FULLY_INVOICABLE =
        "allowanceTravelDistanceIsFullyInvoiceable";

    /** Reiseabschnitt zu dem diese Kilometerspese gehört */
    private travelSection: TravelSection;

    private allowanceEntity: Entity;

    public static create(
        travelSection: TravelSection,
        typeSubtypeDefinition: TypeSubtypeDefinition,
        userOid: string,
        businessTravelOid: string,
    ): KilometreAllowance {
        const allowanceValueObject = {
            oid: IdGenerator.createId() + "_" + KilometreAllowance.TYPE,
            typ: KilometreAllowance.TYPE,
            subtyp: KilometreAllowance.SUBTYPE,
            allowanceUserOid: userOid,
            allowanceTravelOid: businessTravelOid,
        };

        const dailyAllowance = new KilometreAllowance(
            travelSection,
            typeSubtypeDefinition,
            allowanceValueObject,
            true,
        );

        return dailyAllowance;
    }

    constructor(
        travelSection: TravelSection,
        typeSubtypeDefinition: TypeSubtypeDefinition,
        allowanceValueObject: object,
        isNew = false,
    ) {
        this.travelSection = travelSection;
        this.allowanceEntity = new Entity(typeSubtypeDefinition, allowanceValueObject, isNew);

        // Sofern Kilometer = Abrechenbare Kilometer: Flag "Vollständig abrechenbar" setzen
        this.flagAsFullyChargeable();
    }

    public getId(): string {
        return this.allowanceEntity.getId();
    }

    public getSubtype(): string {
        return KilometreAllowance.SUBTYPE;
    }

    public getUserOid(): string {
        return this.allowanceEntity.getString(KilometreAllowance.USER_OID);
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return this.allowanceEntity.getTypeSubtypeDefinition().getAttributeDefinition(name);
    }

    public setStartDateTime(startDateTime: DateTimeValue): void {
        this.allowanceEntity.setValue(DailyAllowance.START_DATE_TIME, startDateTime);
    }

    public setEndDateTime(endDateTime: DateTimeValue): void {
        this.allowanceEntity.setValue(DailyAllowance.END_DATE_TIME, endDateTime);
    }

    public getValue(name: string): EntityValue {
        return this.allowanceEntity.getValue(name);
    }

    public setValue(name: string, value: EntityValue): void {
        switch (name) {
            case KilometreAllowance.TRAVEL_DISTANCE:
            case KilometreAllowance.TRAVEL_DISTANCE_INVOICABLE:
                // Auf ganze Kilometer abrunden.
                value = this.roundToFullKilometres(value);
                break;
        }

        switch (name) {
            case KilometreAllowance.TRAVEL_DISTANCE_INVOICABLE:
                // Nach manueller Änderung der abrechenbaren Kilometer: Flag "Vollständig abrechenbar" entfernen
                this.allowanceEntity.setBoolean(
                    KilometreAllowance.TRAVEL_DISTANCE_IS_FULLY_INVOICABLE,
                    false,
                );
                break;
        }

        // Geänderten Wert setzen
        this.allowanceEntity.setValue(name, value);

        switch (name) {
            case KilometreAllowance.TRAVEL_DISTANCE:
            case KilometreAllowance.TRAVEL_DISTANCE_IS_FULLY_INVOICABLE:
                // Sofern Flag "Vollständig abrechenbar" gesetzt: immer Abrechenbare Kilometer = Kilometer setzen
                this.copyKilometresToInvoiceableKilometres();
                break;
        }
    }

    /**
     * Auf ganze Kilometer abrunden.
     *
     * @param value Geänderter Kilometer-Wert
     * @returns Abgerundeter Kilometer-Wert
     */
    private roundToFullKilometres(value: EntityValue): EntityValue {
        return value.isDefined() ? (<NumberValue>value).round(0, false) : value;
    }

    /**
     * Sofern Kilometer = Abrechenbare Kilometer: Flag "Vollständig abrechenbar" setzen
     */
    private flagAsFullyChargeable(): void {
        const travelDistance = <NumberValue>(
            this.allowanceEntity.getValue(KilometreAllowance.TRAVEL_DISTANCE)
        );
        const travelDistanceInvoicable = <NumberValue>(
            this.allowanceEntity.getValue(KilometreAllowance.TRAVEL_DISTANCE_INVOICABLE)
        );
        this.allowanceEntity.setBoolean(
            KilometreAllowance.TRAVEL_DISTANCE_IS_FULLY_INVOICABLE,
            travelDistance.isEqual(travelDistanceInvoicable),
        );
    }

    /**
     * Sofern Flag "Vollständig abrechenbar" gesetzt: immer Abrechenbare Kilometer = Kilometer setzen.
     */
    private copyKilometresToInvoiceableKilometres(): void {
        if (
            this.allowanceEntity.getBoolean(KilometreAllowance.TRAVEL_DISTANCE_IS_FULLY_INVOICABLE)
        ) {
            this.allowanceEntity.setValue(
                KilometreAllowance.TRAVEL_DISTANCE_INVOICABLE,
                this.allowanceEntity.getValue(KilometreAllowance.TRAVEL_DISTANCE),
            );
        }
    }

    public hasAmout(): boolean {
        const travelDistanceValue = this.allowanceEntity.getValue(
            KilometreAllowance.TRAVEL_DISTANCE,
        );
        return (
            travelDistanceValue.isDefined() && (<NumberValue>travelDistanceValue).getNumber() > 0
        );
    }

    public isEditable(): boolean {
        return this.travelSection.isEditable();
    }

    public isDeletable(): boolean {
        return false;
    }

    public toValueObject(): object {
        return this.allowanceEntity.toValueObject();
    }

    /**
     * @returns Warnung durch letzte Attributänderungen (seit letzter Abfrage)
     */
    public getAndClearChangeWarningKey(): string {
        return null;
    }
}
