import { AllowanceManager } from "../../AllowanceManager";
import { Animation, AppNavigator } from "../../../../core/Router";
import { BusinessTravel } from "../../records/BusinessTravel";
import { Component } from "../../../../core/Component";
import { Controller } from "../../../../core/Controller";
import { FileManager } from "../../../files/FileManager";
import { FooterTabBar } from "../../../../board/FooterTabBar";
import { GUIContext } from "../../../../gui/GUIContext";
import { GUIPage } from "../../../../gui/GUIPage";
import { I18n } from "../../../../common/i18n/I18n";
import { MessagePool } from "../../../../core/Message/MessagePool";
import { Registry } from "../../../../core/Registry";
import { ServerConfigProperties } from "../../../../common/config/ServerConfigProperties";
import { SyncStateManager } from "../../../../sync/SyncStateManager";
import { ToolBar } from "../../../../gui/navigation/ToolBar";
import { ToolLink } from "../../../../gui/navigation/ToolLink";
import { UserSession } from "../../../../common/auth/UserSession";
import { VoucherAllowance } from "../../records/VoucherAllowance";
import { VoucherFormController } from "./VoucherFormController";
import { VoucherPhotoListController } from "./VoucherPhotoListController";
import "./voucher.less";
import { PageDeleteMode } from "../../../../gui/navigation/PageDeleteMode";
import { GUIEventPool } from "../../../../gui/event/GUIEventPool";
import { MessageEntity, MessageType } from "../../../../core/Message/MessageEntity";
import { TravelSection } from "../../records/TravelSection";

/**
 * Zentrale Steuerung einer Seite mit einer Belegspese.
 *
 * Zeigt Belegspesen-Attribute zum Bearbeiten und Liste mit Beleganhängen (Fotos bzw. PDFs).
 *
 * WICHTIG: Seite zeigt Belegspesen, die zu einer Dienstreise gehören ODER Einzel-Belegspesen ohne Dienstreise (businessTravel == null)!
 */
export class VoucherController implements Controller {
    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static readonly CONTROLLER_PATH = "voucher";

    public static readonly PARAMETER_BUSINESS_TRAVEL_ID = "businessTravelOid";

    public static readonly PARAMETER_VOUCHER_ID = "voucherAllowanceOid";

    /** Parameter, der angibt, dass die geöffnete (zu einer Dienstreise gehörige) Belegspese direkt vor dem Aufruf erzeugt wurde */
    public static readonly PARAMETER_CREATED = "created";

    private navigator: AppNavigator;

    private animation: Animation;

    private i18n: I18n;

    private userSession: UserSession;

    private allowanceManager: AllowanceManager;

    private syncStateManager: SyncStateManager;

    private serverConfigProperties: ServerConfigProperties;

    private fileManager: FileManager;

    private businessTravel: BusinessTravel;

    private voucherAllowance: VoucherAllowance;

    private page: GUIPage;

    private headerToolBar: ToolBar;

    private footerTabBar: FooterTabBar;

    private voucherFormController: VoucherFormController;

    private voucherPhotoListController: VoucherPhotoListController;

    private pageDeleteMode: PageDeleteMode;

    public getDependencyNames(): string[] {
        return [
            I18n.BCS_COMPONENT_NAME,
            UserSession.BCS_COMPONENT_NAME,
            MessagePool.BCS_COMPONENT_NAME,
            AllowanceManager.BCS_COMPONENT_NAME,
            FileManager.BCS_COMPONENT_NAME,
            SyncStateManager.BCS_COMPONENT_NAME,
            ServerConfigProperties.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }) {
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.userSession = <UserSession>depencencyComponents[UserSession.BCS_COMPONENT_NAME];
        this.allowanceManager = <AllowanceManager>(
            depencencyComponents[AllowanceManager.BCS_COMPONENT_NAME]
        );
        this.fileManager = <FileManager>depencencyComponents[FileManager.BCS_COMPONENT_NAME];
        this.syncStateManager = <SyncStateManager>(
            depencencyComponents[SyncStateManager.BCS_COMPONENT_NAME]
        );
        this.serverConfigProperties = <ServerConfigProperties>(
            depencencyComponents[ServerConfigProperties.BCS_COMPONENT_NAME]
        );
    }

    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        this.navigator = navigator;
        this.animation = animation;

        if (!this.allowanceManager.getAllowanceRecordingTerms().isAllowanceRecordingAvailable()) {
            this.navigator.navigateTo("index", {}, Animation.SLIDE_RIGHT);
            return;
        }

        this.voucherFormController = new VoucherFormController(
            this.allowanceManager,
            this.syncStateManager,
            this.serverConfigProperties,
            this.i18n,
        );
        this.voucherPhotoListController = new VoucherPhotoListController(
            this.allowanceManager,
            this.fileManager,
            this.syncStateManager,
            this.i18n,
            this.userSession,
        );

        const self = this;
        this.fetchBusinessTravelWithVoucherOrSingleVoucher(parameters)
            .then(() => self.voucherPhotoListController.fetchFiles(self.voucherAllowance))
            .then(() => self.composePage(parameters));
    }

    private async fetchBusinessTravelWithVoucherOrSingleVoucher(parameters: {
        [key: string]: string;
    }): Promise<void> {
        const businessTravelId = parameters[VoucherController.PARAMETER_BUSINESS_TRAVEL_ID];
        const voucherAllowanceId = parameters[VoucherController.PARAMETER_VOUCHER_ID];

        if (businessTravelId) {
            // Dienstreise laden, Belegspese bei Dienstreise abfragen
            this.businessTravel = await (<Promise<BusinessTravel>>(
                this.allowanceManager.fetchAllowanceById(businessTravelId)
            ));

            if (voucherAllowanceId) {
                this.voucherAllowance = <VoucherAllowance>(
                    this.businessTravel.getSubAllowanceById(voucherAllowanceId)
                );

                // Falls Parameter für Anzeige, dass Belegspese direkt vorher erzeugt wurde, Belegspese als neu erzeugt markieren.
                this.voucherFormController.setVoucherAllowanceCreatedAndNotEdited(
                    !!parameters[VoucherController.PARAMETER_CREATED],
                );
            }
        } else if (voucherAllowanceId) {
            // Einzel-Belegspese laden
            this.voucherAllowance = await (<Promise<VoucherAllowance>>(
                this.allowanceManager.fetchAllowanceById(voucherAllowanceId)
            ));
        } else {
            // Aufruf ohne Id: Neue Einzel-Belegspese erzeugen
            this.voucherAllowance = await this.allowanceManager.createVoucherAllowance();
            this.voucherFormController.setVoucherAllowanceCreatedAndNotEdited(true);
        }

        // Namen der ausgewählten Aufgabe am OidValue (allowancePSPSOid) setzen
        if (this.voucherAllowance) {
            const pspOid = this.voucherAllowance.getPSPOid();
            const allowancePSP = this.allowanceManager
                .getAllowanceRecordingTerms()
                .getAllowancePSP(pspOid);
            if (allowancePSP) {
                this.voucherAllowance.setPSPName(allowancePSP.getTaskName());
            }
        }

        // Aufräumen der Parameter dieser Seite (für sauberere History oder DeepLinks):
        // Behalten der Parameter, die eine Eigenschaft der Seite definieren (z.B. Id der Dienstreise),
        // Entfernen der Parameter, die temporär speziellen Aufrufbedingungen definieren (z.B. Herkunft).
        if (parameters.hasOwnProperty(VoucherController.PARAMETER_CREATED)) {
            delete parameters[VoucherController.PARAMETER_CREATED];
            this.navigator.replaceCurrentParameters(parameters);
        }
    }

    private async composePage(parameters: { [key: string]: string }): Promise<void> {
        if (!this.voucherAllowance) {
            // Wenn Belegspese nicht geladen: Eine Ebene hoch zur Belegspesenliste (an der Dienstreise oder global)
            this.navigateUpToVoucherList();
            return;
        }

        // Seite (Wurzelelement) erstellen
        this.page = new GUIPage(
            new GUIContext(this.i18n),
            VoucherController.CONTROLLER_PATH,
            this.voucherAllowance.getId(),
        );
        this.page.addStyleClass("defaultBackground");

        // Kopfzeile (mit Navigation, Titel und Bearbeiten-/Löschen-Modus)
        this.composeHeader();

        // Löschen der Belegspese (wenn Dienstreise löschbar bzw. Einzelspese löschbar)
        if (
            (this.businessTravel && this.businessTravel.isDeletable()) ||
            (!this.businessTravel && this.voucherAllowance.isDeletable())
        ) {
            this.pageDeleteMode = new PageDeleteMode()
                .setPage(this.page)
                .setHeaderToolBar(this.headerToolBar)
                .setDeleteButtonLabel(this.i18n.get("MobileApp.voucher.deleteVoucher"))
                .onDelete(this.deleteVoucher.bind(this));
        }

        // Formular für Belegspese
        this.voucherFormController.onNavigateToTaskSelectionPage(
            this.navigateToTaskSelectionPage.bind(this),
        );
        this.voucherFormController.compose(this.businessTravel, this.voucherAllowance, this.page);

        // Liste mit Belegfotos/PDFs
        this.voucherPhotoListController.onNavigateToPhoto(
            this.navigateToVoucherPhotoPage.bind(this),
        );
        this.voucherPhotoListController.compose(this.page, this.pageDeleteMode);

        // Fußzeile (u.a. mit Home-Link)
        await this.composeFooterTabBar();

        this.prepareMessages(parameters);

        // Seite rendern
        this.page
            .setAnimation(this.animation, this.navigator.doShowAnimations())
            .setScrollTop(this.navigator.getControllerState().getPageScrollTop())
            .compose($("body"));
    }

    private composeHeader(): void {
        this.headerToolBar = new ToolBar()
            .setId("header_toolbar")
            .setTitle(this.i18n.get("JAllowance.voucherAllowance"))
            .addStyleClass("headBar")
            .addToolLinkLeft(
                new ToolLink()
                    .setId("navigateBack")
                    .setImageName("icon-chevron-left.svg")
                    .onClick(this.popState.bind(this)),
            )
            .addToolLinkRight(
                new ToolLink()
                    .setId("addAnotherVoucher")
                    .setImageName("icon-add-grey.svg")
                    .onClick(this.addAnotherVoucher.bind(this)),
            );
        this.page.addHeaderElement(this.headerToolBar);
    }

    /**
     * Fußzeile (u.a. mit Home-Link)
     */
    private async composeFooterTabBar(): Promise<void> {
        const self = this;
        this.footerTabBar = new FooterTabBar(new GUIContext(this.i18n));
        await this.footerTabBar.composeDefaultFooter(
            null,
            this.navigator,
            this.getNavigateBackParameters(),
            () => self.syncStateManager.countUnsyncedElements(),
        );
        this.page.addFooterElement(this.footerTabBar);

        this.voucherFormController.setFooterTabBar(this.footerTabBar);
        this.voucherPhotoListController.setFooterTabBar(this.footerTabBar);
    }

    private prepareMessages(parameters: { [key: string]: string }): void {
        const messages: MessageEntity[] = [];

        // Meldung von vorheriger Seite zum späteren Anzeigen merken
        if (parameters["affirmations"]) {
            messages.push(
                new MessageEntity(
                    this.i18n.get("MobileApp.voucher.message." + parameters["affirmations"]),
                    MessageType.AFFIRMATION,
                ),
            );
        }
        if (parameters["warnings"]) {
            messages.push(
                new MessageEntity(
                    this.i18n.get("MobileApp.voucher.message." + parameters["warnings"]),
                    MessageType.WARNING,
                ),
            );
        }

        // Meldung(en) von vorheriger Seite oder nach Aktion anzeigen
        this.page.composeMessages(messages, new GUIContext(this.i18n, new GUIEventPool()));
    }

    private getNavigateBackParameters(): { [key: string]: string } {
        const navigateBackParameters: { [key: string]: string } = {};
        if (this.businessTravel) {
            navigateBackParameters[VoucherController.PARAMETER_BUSINESS_TRAVEL_ID] =
                this.businessTravel.getId();
        }
        navigateBackParameters[VoucherController.PARAMETER_VOUCHER_ID] =
            this.voucherAllowance.getId();

        return {
            navigateBackPath: VoucherController.CONTROLLER_PATH,
            navigateBackParameters: JSON.stringify(navigateBackParameters),
        };
    }

    private deleteVoucher(): void {
        if (this.businessTravel) {
            // Entfernen der Belegspese aus Dienstreise
            this.businessTravel.deleteVoucherAllowance(this.voucherAllowance);
            this.allowanceManager.storeAllowance(this.businessTravel).then(() =>
                this.navigator.navigateTo(
                    "travel",
                    {
                        oid: this.businessTravel ? this.businessTravel.getId() : null,
                        affirmations: "voucherDeleted",
                    },
                    Animation.SLIDE_RIGHT,
                ),
            );
        } else {
            // Löschen der Einzel-Belegspese
            this.allowanceManager
                .deleteSingleVoucherAllowance([this.voucherAllowance.getId()])
                .then(() =>
                    this.navigator.navigateTo(
                        "travels",
                        { affirmations: "voucherDeleted" },
                        Animation.SLIDE_RIGHT,
                    ),
                );
        }
    }

    public popState(): void {
        if (this.pageDeleteMode && this.pageDeleteMode.isDeleteModeActive()) {
            this.pageDeleteMode.leaveDeleteMode();
        } else {
            this.navigateUpToVoucherList();
        }
    }

    private navigateUpToVoucherList(): void {
        const deleteOnNavigateBack =
            this.voucherFormController.isVoucherAllowanceCreatedAndNotEdited() &&
            this.voucherPhotoListController.getVoucherFilesCount() == 0;

        if (this.businessTravel) {
            if (deleteOnNavigateBack) {
                // Wurde Belegspese mit Aufruf dieser Seite erzeugt und nicht editiert, so wird bei Zurück-Navigation gelöscht.
                this.businessTravel.deleteVoucherAllowance(this.voucherAllowance);
                this.allowanceManager.storeAllowance(this.businessTravel).then(() =>
                    this.navigator.navigateTo(
                        "travel",
                        {
                            oid: this.businessTravel.getId(),
                            warnings: "unEditedVoucherNotSaved",
                        },
                        Animation.SLIDE_RIGHT,
                    ),
                );
            } else {
                this.navigator.navigateTo(
                    "travel",
                    { oid: this.businessTravel.getId() },
                    Animation.SLIDE_RIGHT,
                );
            }
        } else {
            if (deleteOnNavigateBack) {
                // Wurde Belegspese mit Aufruf dieser Seite erzeugt und nicht editiert, so wird bei Zurück-Navigation gelöscht.
                this.allowanceManager
                    .deleteSingleVoucherAllowance([this.voucherAllowance.getId()])
                    .then(() =>
                        this.navigator.navigateTo(
                            "travels",
                            { warnings: "unEditedVoucherNotSaved" },
                            Animation.SLIDE_RIGHT,
                        ),
                    );
            } else {
                this.navigator.navigateTo("travels", {}, Animation.SLIDE_RIGHT);
            }
        }
    }

    private navigateToTaskSelectionPage(path: string, parameters: { [key: string]: string }): void {
        this.navigator.navigateTo(path, parameters, Animation.SLIDE_LEFT);
    }

    private navigateToVoucherPhotoPage(voucherFileId: string): void {
        const navigateBackParameters = {};
        if (this.businessTravel) {
            navigateBackParameters[VoucherController.PARAMETER_BUSINESS_TRAVEL_ID] =
                this.businessTravel.getId();
        }
        navigateBackParameters[VoucherController.PARAMETER_VOUCHER_ID] =
            this.voucherAllowance.getId();

        const parameters = {
            businessTravelOid: this.businessTravel ? this.businessTravel.getId() : null,
            voucherAllowanceOid: this.voucherAllowance.getId(),
            imageFileId: voucherFileId,
            titleKey: "MobileApp.voucherimage.title",
            navigateBackPath: VoucherController.CONTROLLER_PATH,
            navigateBackParameters: JSON.stringify(navigateBackParameters),
        };
        this.navigator.navigateTo("voucherimage", parameters, Animation.SLIDE_LEFT);
    }

    private addAnotherVoucher() {
        const deleteOnLeaveCurrentPage =
            this.voucherFormController.isVoucherAllowanceCreatedAndNotEdited() &&
            this.voucherPhotoListController.getVoucherFilesCount() == 0;

        if (this.businessTravel) {
            const travelSection: TravelSection = this.voucherAllowance.getTravelSection();
            const newVoucherAllowance: VoucherAllowance = travelSection.createVoucherAllowance();

            if (deleteOnLeaveCurrentPage) {
                // Wurde Belegspese mit Aufruf dieser Seite erzeugt und nicht editiert, so wird bei Weiter-Navigation gelöscht.
                this.businessTravel.deleteVoucherAllowance(this.voucherAllowance);
                this.allowanceManager.storeAllowance(this.businessTravel).then(() =>
                    this.navigator.navigateTo(
                        "voucher",
                        {
                            businessTravelOid: this.businessTravel.getId(),
                            voucherAllowanceOid: newVoucherAllowance.getId(),
                            created: "true", // Anzeige, dass Belegspese direkt vorher erzeugt wurde
                            warnings: "unEditedVoucherNotSaved",
                        },
                        Animation.SLIDE_LEFT,
                    ),
                );
            } else {
                // Machen hier ein Store, da der neu erzeugte Beleg am Reiseabschnitt gespeichert werden muss,
                // weil wir ihn dort sonst nicht finden, wenn wir einen weiteren anlegen wollen.
                this.allowanceManager.storeAllowance(this.businessTravel).then(() =>
                    this.navigator.navigateTo(
                        "voucher",
                        {
                            businessTravelOid: this.businessTravel.getId(),
                            voucherAllowanceOid: newVoucherAllowance.getId(),
                            created: "true", // Anzeige, dass Belegspese direkt vorher erzeugt wurde
                        },
                        Animation.SLIDE_LEFT,
                    ),
                );
            }
        } else {
            if (deleteOnLeaveCurrentPage) {
                // Wurde Belegspese mit Aufruf dieser Seite erzeugt und nicht editiert, so wird bei Weiter-Navigation gelöscht.
                this.allowanceManager
                    .deleteSingleVoucherAllowance([this.voucherAllowance.getId()])
                    .then(() =>
                        this.navigator.navigateTo(
                            "voucher",
                            { warnings: "unEditedVoucherNotSaved" },
                            Animation.SLIDE_LEFT,
                        ),
                    );
            } else {
                this.navigator.navigateTo("voucher", {}, Animation.SLIDE_LEFT);
            }
        }
    }

    public destroy(): void {
        // Temporäre Lösung um potentiell geöffneten Popup-Kalender-Picker zu schließen
        if (this.page) {
            this.page.leave();
        }
    }
}

Registry.registerComponent(VoucherController.CONTROLLER_PATH, VoucherController);
