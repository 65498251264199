import { ProgressFeedback } from "../../util/progress/ProgressFeedback";
import { HttpRESTClient } from "../../util/http/HttpRESTClient";
import { RESTSaveResult } from "../../sync/RESTSaveResult";
import { AppConsole } from "../../common/log/AppConsole";

/**
 * Client, um die Kontakte per REST an BCS zu schicken, oder die Rechtekonfiguration per REST aus BCS abzufragen. Endpoint in BCS ist der AppContactEndpoint.
 */
export class ContactClient {
    private static CONTACT_PATH: string = "contactRecording";

    async sendContactsToBCS(contactValueObjects: object[], progressFeedback: ProgressFeedback) {
        AppConsole.debug("About to send the following Contact JSON to BCS:");
        AppConsole.debug(contactValueObjects);
        const result = await new HttpRESTClient()
            .createRequest()
            .setMethod("POST")
            .addPath(ContactClient.CONTACT_PATH + "/contacts")
            .setJSONBody(contactValueObjects)
            .onProgress((isDownload, loaded, total) =>
                progressFeedback.notifyProgress(loaded, total),
            )
            .send();
        progressFeedback.notifyProgress(100, 100);
        return new RESTSaveResult(result);
    }

    public async readTermsFromBCS(checkSum: number, feedback: ProgressFeedback): Promise<object> {
        const self = this;
        const result = await new HttpRESTClient()
            .createRequest()
            .setMethod("GET")
            .addPath(ContactClient.CONTACT_PATH + "/contactRecordingterms")
            .setParameter("checkSum", checkSum)
            .onProgress((isDownload, loaded, total) => feedback.notifyProgress(loaded, total))
            .send();
        feedback.notifyProgress(100, 100);
        switch (result["status"]) {
            case 200:
                return result["json"] ? result["json"] : null;
            case 204:
                return null;
            default:
                throw new Error(
                    "[ContactClient] Error, Failed to read ContactRecordingTerms: " +
                        result["status"],
                );
        }
    }
}
