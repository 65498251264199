import { IndexedDBTransaction } from "./IndexedDBTransaction";
import { IndexedDBStore } from "./IndexedDBStore";

/**
 * IndexedDB-Connection.
 *
 * Wird von einer IndexedDB herausgegeben.
 *
 * An ihr können Nur-Lese- oder Lese-und-Schreib-Tranaaktionen gestartet werden.
 *
 * An der Transaktion können ein oder mehrere Request aufgerufen werden,
 * für deren asynchronen Rückruf mit dem Ergebnis sich er Aufrufer registriert.
 */
export class IndexedDBConnection {
    private database: IDBDatabase;

    private dbStoresByName: { [key: string]: IndexedDBStore } = {};

    constructor(db, dbStores: IndexedDBStore[]) {
        this.database = db;

        dbStores.forEach((dbStore) => (this.dbStoresByName[dbStore.getStoreName()] = dbStore));
    }

    /**
     * Beginnt eine Transaktion nur zum Lesen- und Schreiben.
     *
     * @param storeNames Namen der Stores, die dabei verwendet werden
     **/
    readOnlyTransaction(storeNames): IndexedDBTransaction {
        return new IndexedDBTransaction(this.database, storeNames, this.dbStoresByName, "readonly");
    }

    /**
     * Beginnt eine Transaktion zum Lesen- und Schreiben.
     *
     * @param storeNames Namen der Stores, die dabei verwendet werden
     **/
    readWriteTransaction(storeNames): IndexedDBTransaction {
        return new IndexedDBTransaction(
            this.database,
            storeNames,
            this.dbStoresByName,
            "readwrite",
        );
    }

    /**
     * Beendet Transaktion (muss nicht manuell aufgerufen werden)
     **/
    close() {
        this.database.close();
    }
}
