import { ImageDimension } from "./ImageDimension";
//import * as EXIF from "exif-js";

export enum ImageRotationType {
    AUTO,
    NONE,
    LEFT,
    RIGHT,
    FLIP,
}

export type imageTransformResultType = { base64Data: string; width: number; height: number };

/**
 * Hilfsmittel, um Fotos in Breite/Höhe zu verkleinern (Größenverhältnisse beibehalten) und/oder zu drehen.
 *
 * Erzeugt Hilfsmitel mit Foto als Base64 encoded String.
 * Verkleinern und/oder Drehen bestellen.
 * Ergebnis wird asynchron als Rückruf eines Callbacks geliefert.
 */
export class ImageTransformation {
    private transformations = [];

    /**
     * Foto drehen lassen.
     * @param rotationType Drehung "left", "right", "rotate"
     */
    public rotate(rotationType: ImageRotationType): ImageTransformation {
        this.transformations.push(new ImageRotation(rotationType));
        return this;
    }

    /**
     * Foto verkleinern lassen.
     * @param maxWidth Max. Breite
     * @param maxHeight Max. Höhe
     */
    public reduce(maxWidth: number, maxHeight: number): ImageTransformation {
        this.transformations.push(new ImageReduction(maxWidth, maxHeight));
        return this;
    }

    /**
     * Callbacks zum Rückruf bei Erfolg (mit Ergebnis) oder Fehler
     */
    public async apply(base64Image: string, quality: number): Promise<ResultImage> {
        let imageTag = await this.createImageTag(base64Image);

        for (let i = 0; i < this.transformations.length; i++) {
            imageTag = await this.transformations[i].apply(imageTag);
        }

        return new ResultImage(imageTag, quality);
    }

    private createImageTag(base64Image: string): Promise<HTMLImageElement> {
        // Base64 String als src an einem Image setzen und Rückruf registrieren, wenn Bild geladen
        return new Promise((resolve, reject) => {
            const imageTag = new Image();
            imageTag.onload = () => resolve(imageTag);
            imageTag.onerror = () => reject();
            imageTag.src = base64Image;
        });
    }
}

interface ImageTransformationOperation {
    apply(
        imageTag: HTMLImageElement | HTMLCanvasElement,
    ): Promise<HTMLImageElement | HTMLCanvasElement>;
}

class ImageRotation implements ImageTransformationOperation {
    private rotationType: ImageRotationType;

    constructor(rotationType: ImageRotationType) {
        this.rotationType = rotationType;
    }

    public async apply(
        imageTag: HTMLImageElement | HTMLCanvasElement,
    ): Promise<HTMLImageElement | HTMLCanvasElement> {
        if (this.rotationType == ImageRotationType.AUTO) {
            this.rotationType = await this.autoDetermineRotationType(<HTMLImageElement>imageTag);
        }

        const width = imageTag.width;
        const height = imageTag.height;

        let targetWidth, targetHeight;
        switch (this.rotationType) {
            case ImageRotationType.LEFT:
            case ImageRotationType.RIGHT:
                targetWidth = Math.max(width, height);
                targetHeight = Math.max(width, height);
                break;
            case ImageRotationType.FLIP:
                targetWidth = width;
                targetHeight = height;
                break;
            case ImageRotationType.NONE:
            default:
                return imageTag;
        }

        return this.rotateImage(imageTag, targetWidth, targetHeight);
    }

    private autoDetermineRotationType(imageTag: HTMLImageElement): Promise<ImageRotationType> {
        return Promise.resolve(
            imageTag.width > imageTag.height ? ImageRotationType.LEFT : ImageRotationType.NONE,
        );

        // Das Auslesen der Orientierung der Fotos aus den EXIF-metadaten funktioniert nict mehr stabil,
        // manche Browser drehen die Bilder schon beim Laden (in den ImageTag) anhand der Oreinitierung,
        // so dass ein Drehen danach anhand der Orientierung falsch wäre.
        /*
        return new Promise((resolve) => {
            EXIF.getData(<any>imageTag, () => {
                //const allMetaData = EXIF.getAllTags(imageTag);
                //console.log("EXIF.allMetaData", allMetaData);
                const orientation = EXIF.getTag(imageTag, "Orientation");
                //console.log("EXIF.orientation", orientation);

                // see: http://sylvana.net/jpegcrop/exif_orientation.html
                let imageRotationType = ImageRotationType.NONE;
                switch (orientation) {
                    // 1 = Horizontal (normal)
                    // 2 = Mirror horizontal
                    case 1:
                    case 2:
                        imageRotationType = ImageRotationType.NONE;
                        break;
                    // 3 = Rotate 180
                    // 4 = Mirror vertical
                    case 3:
                    case 4:
                        imageRotationType = ImageRotationType.FLIP;
                        break;
                    // 5 = Mirror horizontal and rotate 270 CW
                    // 6 = Rotate 90 CW
                    case 5:
                    case 6:
                        imageRotationType = ImageRotationType.RIGHT;
                        break;
                    // 7 = Mirror horizontal and rotate 90 CW
                    // 8 = Rotate 270 CW
                    case 7:
                    case 8:
                        imageRotationType = ImageRotationType.LEFT;
                        break;
                    default:
                        if (imageTag.width > imageTag.height) {
                            imageRotationType = ImageRotationType.LEFT;
                        }
                        imageRotationType = ImageRotationType.NONE;
                        break;
                }

                console.log("resolve imageRotationType=", imageRotationType);

                resolve(imageRotationType);
            });
        });
        */
    }

    private rotateImage(
        imageTag: HTMLImageElement | HTMLCanvasElement,
        targetWidth: number,
        targetHeight: number,
    ): Promise<HTMLImageElement | HTMLCanvasElement> {
        let width = imageTag.width;
        let height = imageTag.height;

        const rotateCanvas = document.createElement("canvas");
        rotateCanvas.width = targetWidth;
        rotateCanvas.height = targetHeight;
        const rotateContext = rotateCanvas.getContext("2d");

        switch (this.rotationType) {
            case ImageRotationType.LEFT:
                rotateContext.setTransform(0, -1, 1, 0, 0, width);
                rotateContext.drawImage(imageTag, 0, 0, width, height);
                height = [width, (width = height)][0];
                break;
            case ImageRotationType.RIGHT:
                rotateContext.setTransform(0, 1, -1, 0, height, 0);
                rotateContext.drawImage(imageTag, 0, 0, width, height);
                height = [width, (width = height)][0];
                break;
            case ImageRotationType.FLIP:
                rotateContext.setTransform(1, 0, 0, -1, 0, height);
                rotateContext.drawImage(imageTag, 0, 0, width, height);
                break;
            default:
                return;
        }

        const clipCanvas = document.createElement("canvas");
        clipCanvas.width = width;
        clipCanvas.height = height;
        const clipContext = clipCanvas.getContext("2d");
        clipContext.clearRect(0, 0, width, height);
        clipContext.drawImage(rotateCanvas, 0, 0, width, height, 0, 0, width, height);

        return Promise.resolve(clipCanvas);
    }
}

class ImageReduction implements ImageTransformationOperation {
    private maxWidth: number;

    private maxHeight: number;

    constructor(maxWidth: number, maxHeight: number) {
        this.maxWidth = maxWidth;
        this.maxHeight = maxHeight;
    }

    public apply(
        imageTag: HTMLImageElement | HTMLCanvasElement,
    ): Promise<HTMLImageElement | HTMLCanvasElement> {
        if (!this.maxWidth || isNaN(this.maxWidth) || this.maxWidth < 400 || this.maxWidth > 6000) {
            this.maxWidth = 1024;
        }
        if (
            !this.maxHeight ||
            isNaN(this.maxHeight) ||
            this.maxHeight < 400 ||
            this.maxHeight > 6000
        ) {
            this.maxHeight = 768;
        }

        return this.reduceImage(imageTag);
    }

    private reduceImage(
        imageTag: HTMLImageElement | HTMLCanvasElement,
    ): Promise<HTMLImageElement | HTMLCanvasElement> {
        const reducedImageDimension = new ImageDimension(imageTag.width, imageTag.height).reduce(
            this.maxWidth,
            this.maxHeight,
            true,
        );
        const width = reducedImageDimension.getWidth();
        const height = reducedImageDimension.getHeight();

        const reduceCanvas = document.createElement("canvas");
        reduceCanvas.width = width;
        reduceCanvas.height = height;

        const context = reduceCanvas.getContext("2d");
        context.clearRect(0, 0, width, height);
        context.drawImage(imageTag, 0, 0, width, height);

        return Promise.resolve(reduceCanvas);
    }
}

export class ResultImage {
    private base64Image: string;

    private imageWidth: number;

    private imageHeight: number;

    constructor(imageTag: HTMLImageElement | HTMLCanvasElement, quality: number) {
        if (1 < quality || 0 > quality) {
            quality = 0.75;
        }
        if (imageTag.nodeName == "CANVAS") {
            this.base64Image = (<HTMLCanvasElement>imageTag).toDataURL("image/jpeg", quality);
        } else {
            this.base64Image = (<HTMLImageElement>imageTag).src;
        }

        this.imageWidth = imageTag.width;
        this.imageHeight = imageTag.height;
    }

    public getWidth(): number {
        return this.imageWidth;
    }

    public getHeight(): number {
        return this.imageHeight;
    }

    public toBase64Image(): string {
        return this.base64Image;
    }
}
