import { OAuthConfigClient } from "./OAuthConfigClient";

/**
 * Liefert die OAuth-Konfiguration, um den Button zum Anmelden
 * beim OAuth-Provider (z.B. Microsoft) anzeigen zu können.
 */
export class OAuthConfig {
    private oAuthConfig: IOAuthConfig;

    public getDependencyNames(): string[] {
        return [];
    }

    /**
     * Liefert asynchron die OAuth-Konfiguration vom BCS-Server über eine
     * dafür vorgesehene REST-Schnittstelle.
     */
    public async getOAuthConfig(): Promise<void> {
        try {
            this.oAuthConfig = await new OAuthConfigClient().getOAuthConfig();
        } catch (getConfigError) {
            console.debug("Error fetching Config from Client " + getConfigError);
        }
    }

    /**
     * @returns true, wenn serverseitig die Anmeldung über OAuth / OpenID Connect konfiguriert ist
     */
    public isOAuthEnabled(): boolean {
        if (this.oAuthConfig) {
            return this.oAuthConfig.oAuthEnabled;
        } else {
            return false;
        }
    }

    /**
     * @returns URL, die auf den OAuth / OpenID Connect Provider zeigt, um sich dort anzumelden
     */
    public getOAuthUrl(): string | undefined {
        return this.oAuthConfig.oAuthUrl;
    }

    /**
     * @returns Label der Schaltfläche zur OAuth / OpenID Connect Anmeldung
     */
    public getLoginLabel(): string | undefined {
        return this.oAuthConfig.loginLabel;
    }
}

export interface IOAuthConfig {
    oAuthEnabled: boolean;
    oAuthUrl: string | undefined;
    loginLabel: string | undefined;
}
