import { EntityValue } from "./EntityValue";

export class StringValue implements EntityValue {
    private value: string;

    constructor(simpleValue: string) {
        this.value = simpleValue;
    }

    public isDefined(): boolean {
        return !!this.value && this.value.length > 0;
    }

    public getSimpleValue(): string | number | boolean {
        return this.value;
    }

    /**
     * @returns Text mit ggf. escapeten HTMLTags
     */
    public getPlainText(): string {
        return this.getString()
            .replace(new RegExp("&", "g"), "&amp;")
            .replace(new RegExp("<", "g"), "&lt;")
            .replace(new RegExp(">", "g"), "&gt;");
    }

    public getString(): string {
        return this.value ? this.value : "";
    }
}
