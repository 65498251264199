import { Component } from "../../core/Component";
import { Registry } from "../../core/Registry";
import { SingletonComponent } from "../../core/SingletonComponent";
import { ProgressFeedback } from "../../util/progress/ProgressFeedback";
import { AppConsole } from "../log/AppConsole";
import { ApplicationProperties } from "../properties/ApplicationProperties";
import { SchemaClient } from "./SchemaClient";
import { TypeSubtypeDefinition } from "./TypeSubtypeDefinition";

export class Schema implements Component, SingletonComponent {
    public static BCS_COMPONENT_NAME = "Schema";

    private applicationProperties: ApplicationProperties;

    private schema: { [key: string]: object } = {};

    private schemaAge: number;

    private pseudoTypeSchema: { [key: string]: object } = {};

    public getDependencyNames(): string[] {
        return [ApplicationProperties.BCS_COMPONENT_NAME];
    }

    public init(depencencyComponents: { [key: string]: Component }): void {
        this.applicationProperties = <ApplicationProperties>(
            depencencyComponents[ApplicationProperties.BCS_COMPONENT_NAME]
        );
    }

    public start(): Promise<void> {
        return this.readSchemaFromDatabase();
    }

    public notifyBeginUserSession(
        isOnline: boolean,
        progressFeedback?: ProgressFeedback,
    ): Promise<void> {
        return isOnline ? this.readSchemaFromBCS(progressFeedback) : Promise.resolve();
    }

    public synchronize(progressFeedback?: ProgressFeedback): Promise<void> {
        return this.readSchemaFromBCS(progressFeedback);
    }

    private readSchemaFromDatabase(): Promise<void> {
        const self = this;
        return this.applicationProperties
            .readProperty(Schema.BCS_COMPONENT_NAME, null, null)
            .then((schema) => {
                if (schema) {
                    self.schema = schema["schema"];
                    self.schemaAge = schema["age"];

                    self.applyPseudoAttributes();
                }
            });
    }

    private readSchemaFromBCS(progressFeedback: ProgressFeedback): Promise<void> {
        const self = this;
        return new Promise((resolve, reject) => {
            new SchemaClient()
                .fetchSchemaConfig(self.schemaAge, progressFeedback)
                .then((schema) => {
                    if (schema["schema"] && schema["cache"] != "uptodate") {
                        AppConsole.debug(
                            "[Schema] Schema FETCHED from BCS",
                            self.schemaAge + " < " + schema["age"],
                        );

                        // wenn lokale gespeichertes Schema nicht mehr aktuell,
                        // von BCS geladenes Schema verwenden und lokal speichern
                        self.schema = schema["schema"];
                        self.schemaAge = schema["age"];

                        //AppConsole.log(""+JSON.stringify(schema)+",");
                        self.writeSchemaToDB(schema, resolve, reject);

                        self.applyPseudoAttributes();
                    } else {
                        resolve();
                    }
                })
                .catch(reject);
        });
    }

    public writeSchemaToDB(schema, resolve, reject) {
        this.applicationProperties
            .writeProperty(Schema.BCS_COMPONENT_NAME, null, null, schema)
            .then(resolve)
            .catch(reject);
    }

    /**
     * Registriert Pseudo-Attribute für Verwendung in App dynamisch hinzu.
     * @param attribute Name des Pseudo-Attributes
     * @param attributeDef Objekt mit Attributdefinition (z.B. {datatype="String"})
     */
    public registerPseudoAttribute(
        type: string,
        subtype: string,
        attribute: string,
        attributeDef: { [key: string]: string | number | boolean | string[] },
    ): void {
        const pseudoTypeSchema = this.pseudoTypeSchema[type] || (this.pseudoTypeSchema[type] = {});
        const pseudoSubtypeSchema = pseudoTypeSchema[subtype] || (pseudoTypeSchema[subtype] = {});
        pseudoSubtypeSchema[attribute] = attributeDef;
    }

    private applyPseudoAttributes(): void {
        for (const type in this.pseudoTypeSchema) {
            if (this.pseudoTypeSchema.hasOwnProperty(type)) {
                const pseudoTypeSchema = this.pseudoTypeSchema[type];

                for (const subtype in pseudoTypeSchema) {
                    if (pseudoTypeSchema.hasOwnProperty(subtype)) {
                        const pseudoSubtypeSchema = pseudoTypeSchema[subtype];

                        for (const attribute in pseudoSubtypeSchema) {
                            if (pseudoSubtypeSchema.hasOwnProperty(attribute)) {
                                const pseuoAttributeDef = pseudoSubtypeSchema[attribute];

                                this.schema[type][subtype][attribute] = pseuoAttributeDef;
                            }
                        }
                    }
                }
            }
        }
    }

    public hasType(type: string): boolean {
        return this.schema.hasOwnProperty(type);
    }

    public hasTypeSubtype(type: string, subtype: string): boolean {
        if (this.hasType(type)) {
            const typeSchema = this.schema[type];
            return typeSchema.hasOwnProperty(subtype);
        }
        return false;
    }

    public getSubtypes(type: string): string[] {
        if (!this.hasType(type)) {
            throw new Error("[Schema] No schema defined for type: " + type);
        }
        return Object.keys(this.schema[type]);
    }

    public getTypeSubtypeDefinition(type: string, subtype: string): TypeSubtypeDefinition {
        if (!this.hasTypeSubtype(type, subtype)) {
            throw new Error("[Schema] No schema defined for type/subtype: " + type + "/" + subtype);
        }
        return new TypeSubtypeDefinition(type, subtype, this.schema[type][subtype]);
    }
}

Registry.registerSingletonComponent(Schema.BCS_COMPONENT_NAME, Schema);
