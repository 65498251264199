import { IndexedDB } from "../../database/IndexedDB";
import { Schema } from "../../common/schema/Schema";
import { IndexedDBVersion } from "../../database/IndexedDBVersion";
import { Contact } from "./Contact";
import { ContactRecordingTerms } from "./ContactRecordingTerms";
import { ApplicationProperties } from "../../common/properties/ApplicationProperties";

/**
 * Alle Kontaktdaten werden über diesen Pool in der IndexedDB verwaltet. Diese Klasse bietet Methoden, um den Kontakt auszulesen, zu speichern und zu löschen.
 */
export class PoolOfContacts {
    private static readonly CONTACT_STORE_NAME: string = "contacts";
    private static readonly INS_DATE: string = "insDate";
    private static readonly INS_DATE_FIELDS: string[] = [Contact.INS_DATE];
    private static readonly RECORDING_TERMS_PROPERTY_KEY = "ContactRecordingTerms";

    private schema: Schema;
    private indexedDB: IndexedDB;
    private applicationProperties: ApplicationProperties;
    private recordingTerms: ContactRecordingTerms;

    constructor(
        indexedDB: IndexedDB,
        schema: Schema,
        applicationProperties: ApplicationProperties,
    ) {
        this.indexedDB = indexedDB;
        this.schema = schema;
        this.applicationProperties = applicationProperties;
        this.indexedDB
            .registerStore(PoolOfContacts.CONTACT_STORE_NAME, IndexedDBVersion.DB_VERSION_3)
            .setIdKey("oid")
            .addIndex(
                PoolOfContacts.INS_DATE,
                PoolOfContacts.INS_DATE_FIELDS,
                false,
                IndexedDBVersion.DB_VERSION_3,
            );
    }

    public writeContactToDB(contact: Contact): Promise<void> {
        const valuesObjects = [contact.toValueObject()];

        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([PoolOfContacts.CONTACT_STORE_NAME])
                .updateElements(PoolOfContacts.CONTACT_STORE_NAME, valuesObjects)
                .then(resolve, reject);
        });
    }

    public deleteContactFromDB(contact: Contact): Promise<void> {
        if (contact.getId().length == 0) {
            return Promise.resolve();
        }

        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([PoolOfContacts.CONTACT_STORE_NAME])
                .deleteIds(PoolOfContacts.CONTACT_STORE_NAME, [contact.getId()])
                .then(resolve, reject);
        });
    }

    public readContactFromDB(oid: string): Promise<Contact> {
        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([PoolOfContacts.CONTACT_STORE_NAME])
                .selectId(PoolOfContacts.CONTACT_STORE_NAME, oid)
                .then(
                    (result) => {
                        if (result && result.element) {
                            const typeSubtypeDef = this.schema.getTypeSubtypeDefinition(
                                Contact.TYPE,
                                Contact.SUBTYPE,
                            );
                            const contact = new Contact(typeSubtypeDef, result.element, false);
                            resolve(contact);
                        } else {
                            resolve(null);
                        }
                    },
                    (error) => reject(error),
                );
        });
    }

    readAllContactsFromDB(): Promise<Contact[]> {
        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([PoolOfContacts.CONTACT_STORE_NAME])
                .selectCursor(PoolOfContacts.CONTACT_STORE_NAME, PoolOfContacts.INS_DATE)
                .then(
                    (result) => {
                        if (result && result.resultSet) {
                            const valuesObjects = result.resultSet;
                            const contacts = this.wrapContacts(valuesObjects);
                            resolve(contacts);
                        } else {
                            resolve([]);
                        }
                    },
                    (error) => reject(error),
                );
        });
    }

    public async countContacts(): Promise<number> {
        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([PoolOfContacts.CONTACT_STORE_NAME])
                .count(PoolOfContacts.CONTACT_STORE_NAME, PoolOfContacts.INS_DATE, null)
                .then(
                    (result) => (result ? resolve(result.count || 0) : resolve(0)),
                    (error) => reject(error),
                );
        });
    }

    /**
     * Liest die Property zu den Nutzerrechten aus der Datenbank. Diese enthält den Hinweis, ob die Kontakterfassung abgeschaltet ist und ob der Nutzer aufgrund seiner Rechte dazu befähigt wird.
     * @param currentUserOid
     */
    async readTermsFromDB(currentUserOid: string): Promise<ContactRecordingTerms> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.applicationProperties
                .readProperty(PoolOfContacts.RECORDING_TERMS_PROPERTY_KEY, currentUserOid, null)
                .then((recordingTermsProperty) => {
                    this.recordingTerms = new ContactRecordingTerms(recordingTermsProperty);
                    resolve(this.recordingTerms);
                })
                .catch(reject);
        });
    }

    public async writeTermsToDB(currentUserOid: string, recordingTerms: ContactRecordingTerms) {
        this.recordingTerms = recordingTerms;

        const self = this;

        return new Promise((resolve, reject) => {
            const recrordingTermsProperty = recordingTerms.toValueObject();

            self.applicationProperties
                .writeProperty(
                    PoolOfContacts.RECORDING_TERMS_PROPERTY_KEY,
                    currentUserOid,
                    null,
                    recrordingTermsProperty,
                )
                .then(resolve)
                .catch(reject);
        });
    }

    /**
     * Die aus der Datenbank ausgelesenen Informationen werden hier in das "Contact" Objekt überführt.
     * @param contactValueObjects - die ausgelesenen Kontaktdaten aus der IndexedDB
     * @private
     */
    private wrapContacts(contactValueObjects: object[]): Contact[] {
        const typeSubtypeDef = this.schema.getTypeSubtypeDefinition(Contact.TYPE, Contact.SUBTYPE);
        if (!contactValueObjects) {
            return [];
        }
        const contacts: Contact[] = [];
        contactValueObjects.forEach((value) => {
            const contact = new Contact(typeSubtypeDef, value, false);
            contacts.push(contact);
        });
        return contacts;
    }
}
