import { FooterTabBar } from "../../board/FooterTabBar";
import { Component } from "../../core/Component";
import { Controller } from "../../core/Controller";
import { MessageEntity, MessageType } from "../../core/Message/MessageEntity";
import { MessagePool } from "../../core/Message/MessagePool";
import { Registry } from "../../core/Registry";
import { Animation, AppNavigator } from "../../core/Router";
import { BooleanValue } from "../../entities/values/BooleanValue";
import { HTMLContent } from "../../gui/content/HTMLContent";
import { GUIEventPool } from "../../gui/event/GUIEventPool";
import { FlexBox, FlexJustifyContent } from "../../gui/flexbox/FlexBox";
import { BooleanField } from "../../gui/form/BooleanField";
import { Form, formSubmitEventType } from "../../gui/form/Form";
import { FormButton } from "../../gui/form/FormButton";
import { GUIContext } from "../../gui/GUIContext";
import { GUIPage } from "../../gui/GUIPage";
import { ListRow } from "../../gui/list/ListRow";
import { ListView } from "../../gui/list/ListView";
import { ToolBar } from "../../gui/navigation/ToolBar";
import { ToolLink } from "../../gui/navigation/ToolLink";
import { SyncStateManager } from "../../sync/SyncStateManager";
import { I18n } from "../i18n/I18n";
import { LoginController } from "./LoginController";
import { UserSession } from "./UserSession";
import "./Logout.less";
import { AfterLogoutController } from "./AfterLogoutController";
import { TextLabel } from "../../gui/content/TextLabel";

/**
 * Ausloggen öffnet erst diese Zwischenseite, um den Benutzer die Option zu bieten mit dem Ausloggen auch alle Offline-DAten zu löschen.
 *
 * Nur Ausloggen öffnet gleich wieder die Login-Seite.
 * Ausloggen und alle Offline-Daten löschen führt zu einer AfterLogout-Seite mit einrm Info-Text und einrm ReloadApp-Button.
 *
 * Um sich doch nicht auszuloggen, gibt es den Zurück-Link im Header und die Footer-Navigation.
 */
export class LogoutController implements Controller {
    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static readonly CONTROLLER_PATH = "logout";

    /** Form-Field: Offline-Daten löschen */
    private static readonly PARAMETER_CLEAR_SITE_DATA = "clearSiteData";

    private navigator: AppNavigator;

    private i18n: I18n;

    private userSession: UserSession;

    /** Verwaltung der Synchronisationsstatus aller Objekte */
    private syncStateManager: SyncStateManager;

    private messagePool: MessagePool;

    private page: GUIPage;

    private context: GUIContext;
    private eventPool: GUIEventPool;

    private headerToolBar: ToolBar;

    constructor() {
        this.eventPool = new GUIEventPool();
        this.registerEvents();
    }

    public getDependencyNames(): string[] {
        return [
            I18n.BCS_COMPONENT_NAME,
            UserSession.BCS_COMPONENT_NAME,
            SyncStateManager.BCS_COMPONENT_NAME,
            MessagePool.BCS_COMPONENT_NAME,
        ];
    }

    public registerEvents() {
        this.eventPool.registerEventListener(
            "Message",
            "remove",
            this.removeFromMessagePool.bind(this),
        );
    }

    public init(depencencyComponents: { [key: string]: Component }) {
        //Setzt die Theme Color nur für das Boardelement um
        if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
            $("meta[name='theme-color']").attr("content", "#121212"); // Farbe für den Darkmode
        } else {
            $("meta[name='theme-color']").attr("content", "#EBEFF3"); // Farbe für den Lightmode
        }

        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.userSession = <UserSession>depencencyComponents[UserSession.BCS_COMPONENT_NAME];
        this.syncStateManager = depencencyComponents[
            SyncStateManager.BCS_COMPONENT_NAME
        ] as SyncStateManager;
        this.context = new GUIContext(this.i18n, this.eventPool);

        this.messagePool = <MessagePool>depencencyComponents[MessagePool.BCS_COMPONENT_NAME];
    }

    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        this.navigator = navigator;

        // Aufräumen der Parameter dieser Seite (für sauberere History oder DeepLinks):
        // Behalten der Parameter, die eine Eigenschaft der Seite definieren (z.B. Id der Dienstreise),
        // Entfernen der Parameter, die temporär speziellen Aufrufbedingungen definieren (z.B. Herkunft).
        this.navigator.replaceCurrentParameters({});

        this.composePage();
    }

    private async composePage(): Promise<void> {
        const countUnsyncedElements = await this.checkUnsynchronizedChanged();

        this.page = new GUIPage(this.context, LogoutController.CONTROLLER_PATH);

        this.composeHeader();

        this.composeLogoutForm(countUnsyncedElements);

        // Fußzeile (u.a. mit Home-Link)
        await this.composeFooterTabBar(countUnsyncedElements);

        this.page.compose($("body"));
    }

    private composeHeader(): void {
        this.headerToolBar = new ToolBar()
            .setTitle(this.i18n.get("MobileApp.logout"))
            .addStyleClass("headBar")
            .setId("header_toolbar");

        this.headerToolBar.addToolLinkLeft(
            new ToolLink()
                .setId("navigateBack")
                .setImageName("icon-chevron-left.svg")
                .onClick(this.popState.bind(this)),
        );

        this.page.addHeaderElement(this.headerToolBar);
    }

    private composeLogoutForm(countUnsyncedElements: number) {
        const logoutForm = new Form().setName("logout").onSubmit(this.formSubmitted.bind(this));
        this.page.addPageElement(logoutForm);

        const logoutListView = new ListView();

        const logoutFlexBox = new FlexBox()
            .addStyleClass("logoutFlexBox")
            .setJustifyContent(FlexJustifyContent.SPACE_BETWEEN);

        logoutFlexBox.newFlexItem().setFlexBasis("5%");

        const center = logoutFlexBox.newFlexItem().setFlexBasis("95%");
        center.addContentElement(logoutListView);

        logoutFlexBox.newFlexItem().setFlexBasis("5%");

        logoutForm.addLayoutElement(logoutFlexBox);

        // Login-Banner
        logoutListView.addRow(
            new ListRow()
                .addStyleClass("loginBanner")
                .addContentElement(
                    new HTMLContent([
                        $("<img>")
                            .addClass("image")
                            .attr("src", "images/icons/projektron-icon-big.svg"),
                        $("<span>").addClass("projektron").text("Projektron"),
                        $("<span>").addClass("bcs").text("BCS"),
                    ]),
                ),
        );

        // Option zum Löschen sller Offline-Daten
        const clearSiteDataField = new BooleanField()
            .setName(LogoutController.PARAMETER_CLEAR_SITE_DATA)
            .setLabel(this.i18n.get("MobileApp.clearSiteData"))
            .setValue(new BooleanValue(false));

        logoutForm.registerField(clearSiteDataField);
        logoutListView.addRow(
            new ListRow().addContentElement(clearSiteDataField).addStyleClass("clearSiteDataRow"),
        );

        // Info dass alle Daten synchronisiert oder Warnung, dass es unsynchronisierte Änderungen gibt
        let infoLabelSync;
        let infoLabelSyncStyleClasses;
        if (countUnsyncedElements == 0) {
            infoLabelSync = "MobileApp.clearSiteDataAndOnlySyncChanges";
            infoLabelSyncStyleClasses = "clearSiteDataRow";
        } else {
            infoLabelSync = "MobileApp.clearSiteDataAndDiscardUnsyncChanges";
            infoLabelSyncStyleClasses = "clearSiteDataRow unsynchedChangesField";
        }

        // Seperates (stylbares) Textfeld, falls es unsynchronisierte Änderungen gibt
        const unsynchedChangesField: TextLabel = new TextLabel();
        unsynchedChangesField.setText(this.i18n.format(infoLabelSync, [countUnsyncedElements]));
        logoutListView.addRow(
            new ListRow()
                .addContentElement(unsynchedChangesField)
                .addStyleClass(infoLabelSyncStyleClasses),
        );

        // Button zum Ausloggen
        const submitButton = new FormButton()
            .setName("submit")
            .setLabel(this.i18n.get("MobileApp.Button.Logout"));
        logoutForm.registerButton(submitButton);
        logoutListView.addRow(new ListRow().addContentElement(submitButton));

        logoutListView.addRow(
            new ListRow().addContentElement(
                new HTMLContent([$("<span>").addClass("authSeparator")]),
            ),
        );

        // Button zum Abbrechen
        const cancelButton = new FormButton()
            .setName("cancel")
            .setLabel(this.i18n.get("MobileApp.Button.Cancel"))
            .addStyleClass("cancelLogout")
            .onClick(() => this.cancelLogout());
        logoutListView.addRow(new ListRow().addContentElement(cancelButton));

        this.page.composeMessagesContainer();
    }

    private async formSubmitted(clickEvent: formSubmitEventType): Promise<void> {
        const doClearSiteData =
            clickEvent.formValues.hasOwnProperty(LogoutController.PARAMETER_CLEAR_SITE_DATA) &&
            (
                clickEvent.formValues[LogoutController.PARAMETER_CLEAR_SITE_DATA] as BooleanValue
            ).getBoolean();

        // Ausloggen des Benutzers
        const isOAuthRedirected = await this.userSession.logout(doClearSiteData);
        if (!isOAuthRedirected) {
            if (!doClearSiteData) {
                this.navigateToLoginPage();
            } else {
                this.navigateToAfterClearSiteDataPage();
            }
        }
    }

    private cancelLogout() {
        this.popState();
    }

    /**
     * Fußzeile (u.a. mit Home-Link)
     */
    private async composeFooterTabBar(countUnsyncedElements: number): Promise<void> {
        const footerTabBar = new FooterTabBar(new GUIContext(this.i18n));
        await footerTabBar.composeDefaultFooter(
            LogoutController.CONTROLLER_PATH,
            this.navigator,
            {},
            () => Promise.resolve(countUnsyncedElements),
        );
        this.page.addFooterElement(footerTabBar);
    }

    private composeErrorMessage(errorText: string): void {
        // Login-Fehler anzeigen (letzte Fehlermeldung entfernen)
        this.page.composeMessages([new MessageEntity(errorText, MessageType.ERROR)], this.context);
    }

    public composeMessages() {
        const messages: MessageEntity[] = this.messagePool.getMessages();
        this.page.composeMessages(messages, this.context);
    }

    public popState() {
        this.navigator.navigateTo("index", {}, Animation.SLIDE_RIGHT);
    }

    private navigateToLoginPage() {
        this.navigator.navigateTo(LoginController.CONTROLLER_PATH, {}, Animation.SLIDE_LEFT);
    }

    private navigateToAfterClearSiteDataPage(): void {
        this.navigator.navigateTo(AfterLogoutController.CONTROLLER_PATH, {}, Animation.SLIDE_LEFT);
    }

    public destroy(): void {}

    private async checkUnsynchronizedChanged(): Promise<number> {
        return await this.syncStateManager.countUnsyncedElements();
    }

    private removeFromMessagePool(event, transferObject: object) {
        if (transferObject.hasOwnProperty("messageEntity")) {
            this.messagePool.removeMessage(transferObject["messageEntity"]);
        }
    }
}

Registry.registerComponent(LogoutController.CONTROLLER_PATH, LogoutController);
