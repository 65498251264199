import { GUIElement } from "../GUIElement";
import { FlexBox, FlexJustifyContent, FlexAlignItems, FlexAlignContent } from "../flexbox/FlexBox";
import { Image } from "../content/Image";
import { FlexAlignSelf, FlexItem } from "../flexbox/FlexItem";
import { TextLabel } from "../content/TextLabel";
import { ListFieldFeedbackType } from "./ListFieldFeedback";
import { ListField } from "./ListField";

export class ListRow implements GUIElement {
    private contentElements: GUIElement[] = [];

    /** Einfacher Name des Icons zur Anzeige am rechten Rand, z.B. Pfeil nach rechts zum Öffnen einer Unterseite (optional) */
    private openImageName: string;

    private rowDeletable: boolean = false;

    private selectToBeDeletedCallback: Function;

    private clickCallback: Function;

    private styleClasses: string[] = [];

    private actionFlexItemContainer: FlexItem;

    private actionFlexItem: FlexItem;

    private showActionItems: boolean = false;

    private isDeleteModeActive = false;

    /** DOM-Id, um Element z.B. für Tests oder bei ScrollTo zu identifizieren */
    private domId: string;

    /** Id der Zeilen-Entity zur Wiedererkenung, z.B. beim Löschen. */
    private entityId: string;

    private name: string;

    /** Falls Zeile verlinkt, Pfad (für WebTests) */
    private linkPath: string;

    private rowSelected = false;

    private rowFlexbox: FlexBox;

    public setShowActionItems(showActionItems: boolean): ListRow {
        this.showActionItems = showActionItems;
        return this;
    }

    /**
     * Setzt eine DOM-Id, um Element z.B. für Tests oder bei ScrollTo zu identifizieren
     *
     * @param rowId Id der Zeile
     */
    public setDOMId(domId: string): ListRow {
        this.domId = domId;
        return this;
    }

    /**
     * Setzt die Id der Zeilen-Entity zur Wiedererkenung, z.B. beim Löschen.
     *
     * @param rowId Id der Zeilen-Entity
     */
    public setEntityId(entityId: string): ListRow {
        this.entityId = entityId;
        return this;
    }

    public setName(name: string): ListRow {
        this.name = name;
        return this;
    }

    /**
     * @param linkPath falls Zeile verlinkt, Pfad (für WebTests)
     */
    public setLinkPath(linkPath: string): ListRow {
        this.linkPath = linkPath;
        return this;
    }

    public getEntityId(): string {
        return this.entityId;
    }

    public useFieldSetStyle(): ListRow {
        this.addStyleClass("listrow_fieldset");
        return this;
    }

    public addStyleClass(styleClass: string): ListRow {
        this.styleClasses.push(styleClass);
        return this;
    }

    public addContentElement(contentElement: GUIElement): ListRow {
        this.contentElements.push(contentElement);
        return this;
    }

    /**
     * Setzt Icon zur Anzeige am rechten Rand, z.B. Pfeil nach rechts zum Öffnen einer Unterseite.
     * @param openImageName Einfacher Bildname (ohne Endung)
     */
    public setOpenImageName(openImageName: string): ListRow {
        this.openImageName = openImageName;
        return this;
    }

    public setDeletable(isDeletable: boolean): ListRow {
        this.rowDeletable = isDeletable;
        return this;
    }

    public onSelectToBeDeleted(deleteCallback: Function): ListRow {
        this.selectToBeDeletedCallback = deleteCallback;
        return this;
    }

    public onClick(clickCallback: Function): ListRow {
        this.clickCallback = clickCallback;
        return this;
    }

    public compose($parent: JQuery): void {
        this.rowFlexbox = new FlexBox()
            .setDOMId(this.domId)
            .addStyleClass("listrow")
            .setAtttribute("data-entityid", this.entityId)
            .setAtttribute("data-linkpath", this.linkPath)
            .setAtttribute("data-webtest", this.name)
            .addStyleClass(this.styleClasses.join(" "))
            .setJustifyContent(FlexJustifyContent.SPACE_BETWEEN)
            .setAlignItems(FlexAlignItems.STRETCH)
            .onClick(this.rowClicked.bind(this));

        // FlexItem für eigentlichen Inhalt
        this.rowFlexbox.newFlexItem().setFlexGrow(1).setContentElements(this.contentElements);

        // FlexItem für Icon am rechten Rand, z.B. Pfeil nach rechts zum Öffnen einer Unterseite oder zum Löschen
        if (this.showActionItems || this.rowDeletable) {
            this.actionFlexItemContainer = this.rowFlexbox.newFlexItem().addStyleClass("action");

            const self = this;
            this.actionFlexItem = this.actionFlexItemContainer
                .newFlexItem()
                .setAlignSelf(FlexAlignSelf.CENTER)
                .onClick(this.deleteImageClicked.bind(this))
                .onTransitionEnd((event) =>
                    self.actionFlexItem.removeStyleClass("feedbackFadeOut").clearContentElements(),
                );

            this.showOpenImage();
        }

        this.rowFlexbox.compose($parent);

        this.contentElements
            .filter((contentElement) => "onUpdateValues" in contentElement)
            .forEach((contentElement) =>
                (<ListField>contentElement).onUpdateValues(
                    this.showUpdateValuesFeedback.bind(this),
                ),
            );
    }

    public remove(): void {
        if (this.rowFlexbox) {
            this.rowFlexbox.remove();
            this.rowFlexbox = null;
        }
    }

    private showUpdateValuesFeedback(feedbackType: ListFieldFeedbackType): void {
        let feedbackIconName = null;
        switch (feedbackType) {
            case ListFieldFeedbackType.SUCCESS:
                feedbackIconName = "icon-checkmark-green.svg";
                break;
            case ListFieldFeedbackType.FAILURE:
                feedbackIconName = "TODO.svg"; // TODO App - Rotes Fehler-Icon?
                break;
        }
        if (feedbackIconName) {
            this.actionFlexItem.clearContentElements();
            this.actionFlexItem.removeStyleClass("feedbackFadeOut");
            this.actionFlexItem.addContentElement(new Image().setImageName(feedbackIconName));
            this.actionFlexItem.addStyleClass("feedbackFadeOut");
        }
    }

    private showOpenImage(): ListRow {
        if (this.openImageName) {
            this.actionFlexItem.clearContentElements();
            this.actionFlexItem.addContentElement(
                new Image().setImageName("icon-chevron-right-normal.svg"),
            );
        }
        return this;
    }

    public enterDeleteMode(): ListRow {
        if (this.rowDeletable) {
            this.isDeleteModeActive = true;
            this.composeDeleteImage(false);
        }
        return this;
    }

    public selectToBeDeleted(isSelected: boolean): ListRow {
        if (this.rowDeletable) {
            this.composeDeleteImage(isSelected);
            this.rowSelected = isSelected;
        }
        return this;
    }

    private composeDeleteImage(isSelected: boolean): ListRow {
        this.rowSelected = isSelected;

        this.actionFlexItem.clearContentElements();

        const imageFlexBox = new FlexBox();

        if (this.rowDeletable) {
            if (!isSelected) {
                this.actionFlexItemContainer
                    .addStyleClass("deletable")
                    .removeStyleClass("deletable_selected");
                this.actionFlexItem.addContentElement(new Image().setImageName("icon-delete.svg")); // "icon-delete-red.svg"
            } else {
                this.actionFlexItemContainer
                    .addStyleClass("deletable_selected")
                    .removeStyleClass("deletable");
                this.actionFlexItem.addContentElement(
                    new Image().setImageName("icon-delete-white.svg"),
                );
            }
        }

        this.actionFlexItem.addContentElement(imageFlexBox);

        return this;
    }

    private deleteImageClicked(event: Event): void {
        if (this.isDeleteModeActive) {
            // Nur wenn im Delete-Modus, default und propagation verhindern,
            // damit browsereigene Operationen nicht verhindert werden (z.B. Öffnen eines input type=date)
            event.preventDefault();
            event.stopPropagation();

            this.selectToBeDeleted(!this.rowSelected);

            if (this.selectToBeDeletedCallback) {
                this.selectToBeDeletedCallback();
            }
        }
    }

    public isDeletable(): boolean {
        return this.rowDeletable;
    }

    public isSelected(): boolean {
        return this.rowSelected;
    }

    public getSelectedIdsToBeDeleted(): string[] {
        return this.isSelected() ? [this.entityId] : [];
    }

    public leaveDeleteMode(): void {
        if (this.isDeletable()) {
            this.actionFlexItem.clearContentElements();
            this.actionFlexItemContainer
                .removeStyleClass("deletable")
                .removeStyleClass("deletable_selected");
            this.showOpenImage();

            this.rowSelected = false;
            this.isDeleteModeActive = false;
        }
    }

    private rowClicked(event: Event) {
        if (this.isDeleteModeActive) {
            // Wenn Löschmodus aktiv: Anklicken einer Zeile öffnet nicht Objekt sondern schaltet Markierung zum Löschen um
            this.deleteImageClicked(event);
        } else if (this.clickCallback) {
            // Nur wenn Callback registriert, default und propagation verhindern,
            // damit browsereigene Operationen nicht verhindert werden (z.B. Öffnen eines input type=date)
            event.preventDefault();
            event.stopPropagation();

            this.clickCallback(event);
        }
    }

    public getComponentChildren(): GUIElement[] {
        return this.contentElements;
    }

    public setWebtestParameter(parameter: any): any {
        this.rowFlexbox.setAtttribute(
            "data-webtest",
            JSON.stringify(parameter).replace(/\"/gm, "'"),
        );
    }
}
