export class TimeRecordingOptions {
    private timeRecordingEnabled: boolean;
    private attendanceClockEnabled: boolean;
    private ticketRecordingActivated: boolean;
    private requirementRecordingActivated: boolean;
    private workflowRecordingActivated: boolean;
    private userLicense: string;
    private workingTimes: { [weekDay: string]: number };
    private timeRecordingTachoUsesDailyTarget: boolean;

    private recordingOptions: object;
    private checkSum: number = 0;
    private activityFeatureEnabled: boolean;

    constructor(recordingTermValueObject?: object) {
        if (recordingTermValueObject) {
            this.recordingOptions = recordingTermValueObject;

            this.checkSum = recordingTermValueObject["checkSum"] || 0;

            this.readTimeRecordingOptions(recordingTermValueObject["TimeRecordingOptions"]);
        } else {
            // keine Optionen aus Datenbank gelesen :[
        }
    }

    public isTimeRecordingEnabled(): boolean {
        return this.timeRecordingEnabled;
    }

    public isAttendanceClockEnabled(): boolean {
        return this.attendanceClockEnabled;
    }

    public isTicketRecordingAllowed(): boolean {
        return this.ticketRecordingActivated;
    }

    public isWorkflowRecordingAllowed(): boolean {
        return this.workflowRecordingActivated;
    }

    public isRequirementRecordingAllowed(): boolean {
        return this.requirementRecordingActivated;
    }

    public isActivityFeatureEnabled(): boolean {
        return this.activityFeatureEnabled;
    }

    public isTimeRecordingTachoUsesDailyTarget(): boolean {
        return this.timeRecordingTachoUsesDailyTarget;
    }

    public getUserLicenses(): string {
        return this.userLicense;
    }

    public getWorkingTimes(): { [weakDay: string]: number } {
        return this.workingTimes;
    }

    public getWorkingTimeOfDay(weekDay: string): number {
        return this.workingTimes[weekDay];
    }

    public getCheckSum(): number {
        return this.checkSum;
    }

    public toValueObject(): object {
        return this.recordingOptions;
    }

    private readTimeRecordingOptions(recordingTermValueObjectElement: object): void {
        this.timeRecordingEnabled = recordingTermValueObjectElement["timeRecordingEnabled"];
        this.timeRecordingTachoUsesDailyTarget =
            recordingTermValueObjectElement["timeRecordingTachoUsesDailyTarget"];
        this.attendanceClockEnabled = recordingTermValueObjectElement["attendanceClockEnabled"];
        this.ticketRecordingActivated = recordingTermValueObjectElement["ticketFeatureEnabled"];
        this.workflowRecordingActivated = recordingTermValueObjectElement["workflowFeatureEnabled"];
        this.requirementRecordingActivated =
            recordingTermValueObjectElement["requirementFeatureEnabled"];
        this.activityFeatureEnabled = recordingTermValueObjectElement["activityFeatureEnabled"];
        this.userLicense = recordingTermValueObjectElement["userLicense"];
        this.workingTimes = JSON.parse(recordingTermValueObjectElement["workingTimes"]);
    }
}

export enum WorkDays {
    MONDAY = "userDeputatMo",
    TUESDAY = "userDeputatTu",
    WEDNESDAY = "userDeputatWe",
    THURSDAY = "userDeputatTh",
    FRIDAY = "userDeputatFr",
    SATURDAY = "userDeputatSa",
    SUNDAY = "userDeputatSu",
}
