export class IndexedDBError {
    private event: string;
    private errorCode: string;
    private indexValue: IDBValidKey;
    private element: any;
    private id: any;
    private query: any;

    constructor(event, errorCode?) {
        this.event = event;
        this.errorCode = errorCode || (event ? event.target.errorCode : null);
    }

    getEvent() {
        return this.event;
    }

    getErrorCode() {
        return this.errorCode;
    }

    setId(id) {
        this.id = id;
        return this;
    }

    getId() {
        return this.id || (this.element ? this.element.id : null);
    }

    setIndexValue(indexValue: IDBValidKey) {
        this.indexValue = indexValue;
        return this;
    }

    getIndexValue(): IDBValidKey {
        return this.indexValue;
    }

    setElement(element) {
        this.element = element;
        return this;
    }

    getElement() {
        return this.element;
    }

    setQuery(query) {
        this.query = query;
        return this;
    }

    getQuery() {
        return this.query;
    }
}
