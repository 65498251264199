import { Component } from "../../core/Component";
import { Controller } from "../../core/Controller";
import { MessageEntity, MessageType } from "../../core/Message/MessageEntity";
import { MessagePool } from "../../core/Message/MessagePool";
import { Registry } from "../../core/Registry";
import { Animation, AppNavigator } from "../../core/Router";
import { SingletonManager } from "../../core/SingletonManager";
import { StringValue } from "../../entities/values/StringValue";
import { HTMLContent } from "../../gui/content/HTMLContent";
import { TextLabel } from "../../gui/content/TextLabel";
import { GUIEventPool } from "../../gui/event/GUIEventPool";
import { FlexBox, FlexJustifyContent } from "../../gui/flexbox/FlexBox";
import { Form, formChangeEventType, formSubmitEventType } from "../../gui/form/Form";
import { FormButton } from "../../gui/form/FormButton";
import { OptionField } from "../../gui/form/OptionField";
import { PasswordField } from "../../gui/form/PasswordField";
import { StringField } from "../../gui/form/StringField";
import { GUIContext } from "../../gui/GUIContext";
import { GUIPage } from "../../gui/GUIPage";
import { ListRow } from "../../gui/list/ListRow";
import { ListView } from "../../gui/list/ListView";
import { ProcessLayer } from "../../gui/loadingProcess/ProcessLayer";
import { HTTPError } from "../../util/http/HTTPError";
import { ProgressFeedback } from "../../util/progress/ProgressFeedback";
import { I18n } from "../i18n/I18n";
import { AppConsole } from "../log/AppConsole";
import { Log } from "../log/Log";
import "./Login.less";
import { OAuthConfig } from "./oauth/OAuthConfig";
import { isOAuthRequestPending, setOAuthRequestPendingOff } from "./oauth/OAuthGlobal";
import { UserSession } from "./UserSession";

export class LoginController implements Controller {
    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static readonly CONTROLLER_PATH = "login";

    /** Login-Parameter: Benutzername */
    private static readonly PARAMETER_USERNAME = "userLogin";

    /** Login-Parameter: Passwort */
    private static readonly PARAMETER_PASSWORD = "userPwd";

    private static readonly PARAMETER_OFFLINE = "offlineLogin";

    /** Aufruf-Parameter, um vorab Benutzer auszuloggen */
    private static readonly PARAMETER_LOGOUT = "logout";

    private navigator: AppNavigator;

    private i18n: I18n;

    private userSession: UserSession;

    private singletonManager: SingletonManager;

    private previousPath: string;

    private progressFeedback: ProgressFeedback;

    private processLayer: ProcessLayer;

    private messagePool: MessagePool;

    private page: GUIPage;

    private context: GUIContext;
    private eventPool: GUIEventPool;

    constructor() {
        this.eventPool = new GUIEventPool();
        this.registerEvents();
    }

    public getDependencyNames(): string[] {
        return [
            I18n.BCS_COMPONENT_NAME,
            UserSession.BCS_COMPONENT_NAME,
            SingletonManager.BCS_COMPONENT_NAME,
            MessagePool.BCS_COMPONENT_NAME,
        ];
    }

    public registerEvents() {
        this.eventPool.registerEventListener(
            "Message",
            "remove",
            this.removeFromMessagePool.bind(this),
        );
    }

    public init(depencencyComponents: { [key: string]: Component }) {
        //Setzt die Theme Color nur für das Boardelement um
        if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
            $("meta[name='theme-color']").attr("content", "#121212"); // Farbe für den Darkmode
        } else {
            $("meta[name='theme-color']").attr("content", "#EBEFF3"); // Farbe für den Lightmode
        }

        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.userSession = <UserSession>depencencyComponents[UserSession.BCS_COMPONENT_NAME];
        this.singletonManager = <SingletonManager>(
            depencencyComponents[SingletonManager.BCS_COMPONENT_NAME]
        );
        this.context = new GUIContext(this.i18n, this.eventPool);

        this.messagePool = <MessagePool>depencencyComponents[MessagePool.BCS_COMPONENT_NAME];
    }

    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        this.previousPath = parameters["prevpath"] || null;
        this.navigator = navigator;

        // Optionales Ausloggen des Benutzers vor Anzeigen der Loginseite.
        this.logout(parameters);

        // Aufräumen der Parameter dieser Seite (für sauberere History oder DeepLinks):
        // Behalten der Parameter, die eine Eigenschaft der Seite definieren (z.B. Id der Dienstreise),
        // Entfernen der Parameter, die temporär speziellen Aufrufbedingungen definieren (z.B. Herkunft).
        this.navigator.replaceCurrentParameters({});

        if (isOAuthRequestPending()) {
            this.page = new GUIPage(this.context, LoginController.CONTROLLER_PATH);
            this.verifyOAuthAuthentication();
        } else {
            this.page = new GUIPage(this.context, LoginController.CONTROLLER_PATH);

            this.composeLoginForm(this.page);

            this.page.compose($("body"));
        }
    }

    /**
     * Sendet das Redirect vom OAuth/OpenID Connect Server (enthält Authorization Code) an die
     * BCS-REST-Schnittstelle "oauth/login", um den User von BCS authentifizieren zu lassen.
     */
    private verifyOAuthAuthentication(): void {
        this.progressFeedback = new ProgressFeedback();
        this.processLayer = new ProcessLayer();
        this.processLayer.show(this.progressFeedback);

        const self = this;
        this.userSession.setToOnlineMode();
        this.userSession
            .verifyOAuthAuthentification()
            .then(() => {
                setOAuthRequestPendingOff();
                self.userLoginGranted();
            })
            .catch((error: HTTPError) => {
                setOAuthRequestPendingOff();
                this.page = new GUIPage(this.context, LoginController.CONTROLLER_PATH);
                this.composeLoginForm(this.page);
                self.userLoginError(error);
                this.page.compose($("body"));

                // Parameter 'code' aus Browser-Adresszeile entfernen
                window.history.pushState({}, "", "/app/#" + LoginController.CONTROLLER_PATH);
            });
    }

    /**
     * Optionales Ausloggen des Benutzers vor Anzeigen der Loginseite.
     *
     * @param parameters Seiten-Parameter
     */
    private logout(parameters: { [key: string]: string }): void {
        if (parameters.hasOwnProperty(LoginController.PARAMETER_LOGOUT)) {
            this.userSession.logout();
        }
    }

    private async composeLoginForm(page: GUIPage): Promise<void> {
        const loginForm = new Form()
            .setName("login")
            .onFieldChange(this.formFieldChanged.bind(this))
            .onSubmit(this.formSubmitted.bind(this));
        page.addPageElement(loginForm);

        const loginListView = new ListView();

        const loginFlexBox = new FlexBox()
            .addStyleClass("loginFlexBox")
            .setJustifyContent(FlexJustifyContent.SPACE_BETWEEN);

        // Pfeil nach links für den vorherigen Monat
        $("<div class='selectorArrowLeft'></div>");
        // Pfeil nach rechts für den nächsten Monat
        loginFlexBox.newFlexItem().setFlexBasis("5%");

        const center = loginFlexBox.newFlexItem().setFlexBasis("95%");
        center.addContentElement(loginListView);

        loginFlexBox.newFlexItem().setFlexBasis("5%");

        loginForm.addLayoutElement(loginFlexBox);

        // Login-Banner (optional mit beta)
        loginListView.addRow(
            new ListRow().addStyleClass("loginBanner").addContentElement(
                new HTMLContent([
                    $("<img>")
                        .addClass("image")
                        .attr("src", "images/icons/projektron-icon-big.svg"),
                    $("<span>").addClass("projektron").text("Projektron"),
                    $("<span>").addClass("bcs").text("BCS"),
                    //$("<span>").addClass("beta").text("beta")
                ]),
            ),
        );

        await this.composeOAuthLoginButton(loginListView);

        const usernameTextField = new StringField()
            .setName(LoginController.PARAMETER_USERNAME)
            .setPlaceholder(this.i18n.get("MobileApp.userLogin"))
            .setAutoCapitalize(false)
            .setSpellCheck(false)
            .onSubmit(() => loginForm.submit());
        loginForm.registerField(usernameTextField);
        loginListView.addRow(
            new ListRow().addContentElement(usernameTextField).addStyleClass("usernameRow"),
        );

        const passwordField = new PasswordField()
            .setName(LoginController.PARAMETER_PASSWORD)
            .setPlaceholder(this.i18n.get("MobileApp.userPwd"))
            .onSubmit(() => loginForm.submit());
        loginForm.registerField(passwordField);
        loginListView.addRow(
            new ListRow().addContentElement(passwordField).addStyleClass("passwordRow"),
        );

        const offlineMode: { label: string; value: string }[] = [
            { label: "online", value: "online" },
            { label: "offline", value: "offline" },
        ];
        const offlineModeTextField = new OptionField()
            .setName(LoginController.PARAMETER_OFFLINE)
            .setPlaceholder(this.i18n.get("MobileApp.offlineLogin"))
            .setValue(new StringValue("online"))
            .setOptions(offlineMode);

        loginForm.registerField(offlineModeTextField);
        loginListView.addRow(
            new ListRow().addContentElement(offlineModeTextField).addStyleClass("offlineOnlineRow"),
        );

        const submitButton = new FormButton()
            .setName("submit")
            .setLabel(this.i18n.get("MobileApp.Button.Login"));
        loginForm.registerButton(submitButton);
        loginListView.addRow(new ListRow().addContentElement(submitButton));

        this.page.composeMessagesContainer();
    }

    /**
     * Erzeugt den OAuth/OpenID Connect Anmeldebutton (z.B. Mit Microsoft anmelden),
     * wenn die Anmeldung über OAuth/OpenID Connect auf dem Server konfigutiert ist.
     * Die Konfiguration des Links wird über ein REST-Request beschafft.
     *
     * Die Methode wird synchron verwendet. Das bedeutet, dass die Anmelde-Seite der
     * App erst dann angezeigt wird, wenn die Konfiguration von der REST-Schnittstelle
     * geladen wurde.
     */
    private async composeOAuthLoginButton(loginListView: ListView): Promise<void> {
        const oAuthConfig = new OAuthConfig();
        await oAuthConfig
            .getOAuthConfig()
            .then(() => {
                if (oAuthConfig && oAuthConfig.isOAuthEnabled()) {
                    const oAuthLoginButton = new FormButton()
                        .setName("oAuthLogin")
                        .setLabel(oAuthConfig.getLoginLabel())
                        .onClick((_) => {
                            window.location.replace(oAuthConfig.getOAuthUrl());
                        });
                    loginListView.addRow(new ListRow().addContentElement(oAuthLoginButton));
                    loginListView.addRow(
                        new ListRow().addContentElement(
                            new HTMLContent([$("<span>").addClass("authSeparator")]),
                        ),
                    );
                }
            })
            .catch((error: HTTPError) => {
                loginListView.addRow(
                    new ListRow().addContentElement(
                        new TextLabel().setText(
                            this.i18n.get("MobileApp.txtError.noConnectionToServer"),
                        ),
                    ),
                );
                // Fehler ans BCS-Log-REST-Schnittstelle nach nächstem erfolgreichen Anmelden senden
                Log.error("LoginController_ComposeOAuthLoginButtonFailed", {
                    error: JSON.stringify(error),
                });
            });
    }

    public formFieldChanged(changeEvent: formChangeEventType): void {
        // nix
    }

    public formSubmitted(clickEvent: formSubmitEventType): void {
        this.progressFeedback = new ProgressFeedback();
        this.processLayer = new ProcessLayer();
        this.processLayer.show(this.progressFeedback);

        const username = clickEvent.formValues[LoginController.PARAMETER_USERNAME].getString();
        const password = clickEvent.formValues[LoginController.PARAMETER_PASSWORD].getString();

        const offline = clickEvent.formValues[LoginController.PARAMETER_OFFLINE].getString();

        if (offline === "offline") {
            this.userSession.setToOfflineMode();
        } else {
            this.userSession.setToOnlineMode();
        }

        const self = this;
        this.userSession
            .login(username, password)
            .then(() => {
                self.userLoginGranted();
            })
            .catch((error: HTTPError) => {
                self.userLoginError(error);
            });
    }

    private userLoginGranted(): void {
        AppConsole.log("[UserSession] user login granted/denied");

        this.singletonManager
            .notifySingletonsBeginUserSession(this.userSession.isOnline(), this.progressFeedback)
            .then(this.openInitialPage.bind(this), this.userLoginError.bind(this));
    }

    private openInitialPage(): void {
        this.navigator.navigateTo("index");
    }

    private userLoginError(error: HTTPError): void {
        this.processLayer.hide();

        if (error instanceof HTTPError) {
            // Es wurde ein HTTPError gefangen
            if (typeof error.isLoginDataIncorrect !== "undefined" && error.isLoginDataIncorrect()) {
                if (error.hasBCSErrorMessage()) {
                    const errorText = error.getBCSErrorMessage();
                    this.composeErrorMessage(this.i18n.get("MobileApp." + errorText));
                    Log.error(
                        "Login failed: " + error.getBCSErrorMessage(),
                        error.getOriginalErrorInfo(),
                    );
                } else {
                    const errorText = this.i18n.get("MobileApp.txtError.loginFailed");
                    this.composeErrorMessage(errorText);
                    Log.error("Login failed: LoginDataIncorrect", error.getOriginalErrorInfo());
                }
            } else if (error.isServiceUnavailable()) {
                const errorText = this.i18n.get("MobileApp.txtError.mobileAppDeactivated");
                this.composeErrorMessage(errorText);
                Log.error(
                    "Service unavailable: Mobile App deactivated",
                    error.getOriginalErrorInfo(),
                );
            } else if (error.isInternalServerError()) {
                if (error.hasBCSErrorMessage()) {
                    const errorText = error.getBCSErrorMessage();
                    this.composeErrorMessage(errorText);
                    Log.error("Login failed: ServerError", error.getOriginalErrorInfo());
                } else {
                    const errorText =
                        this.i18n.get("MobileApp.txtError.unknownServerError") +
                        ": " +
                        error.getBCSErrorMessage();
                    this.composeErrorMessage(errorText);
                    Log.error("Login failed: UnknownServerError", error.getOriginalErrorInfo());
                }
            } else if (error.isNoResponseFromServer()) {
                const errorText = this.i18n.get("MobileApp.txtError.noConnectionToServer");
                this.composeErrorMessage(errorText);
                Log.error("Login failed: NoConnectionToServer", error.getOriginalErrorInfo());
            } else {
                const errorText =
                    "Error(" + error.getStatusCode() + "): " + error.getBCSErrorMessage();
                this.composeErrorMessage(errorText);
                Log.error("Login failed: Error", error.getOriginalErrorInfo());
            }
        } else {
            // Es wurde ein anderer Error gefangen (kein HTTPError)
            Log.error("Login failed: Error: " + error);
            throw error;
        }
    }

    private composeErrorMessage(errorText: string): void {
        // Login-Fehler anzeigen (letzte Fehlermeldung entfernen)
        this.page.composeMessages([new MessageEntity(errorText, MessageType.ERROR)], this.context);
    }

    public composeMessages() {
        const messages: MessageEntity[] = this.messagePool.getMessages();
        this.page.composeMessages(messages, this.context);
    }

    public popState(): void {
        if (this.previousPath) {
            this.navigator.navigateTo(this.previousPath, {}, Animation.SLIDE_RIGHT);
        }
    }

    public destroy(): void {}

    private removeFromMessagePool(event, transferObject: object) {
        if (transferObject.hasOwnProperty("messageEntity")) {
            this.messagePool.removeMessage(transferObject["messageEntity"]);
        } else {
            // TODO Jens 12.02.2018
            AppConsole.log("Konnte Nachricht nicht löschen");
        }
    }
}

Registry.registerComponent(LoginController.CONTROLLER_PATH, LoginController);
