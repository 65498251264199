import { Entity } from "../../../entities/Entity";
import { TypeSubtypeDefinition } from "../../../common/schema/TypeSubtypeDefinition";
import { TravelDay } from "./TravelDay";
import { DateValue } from "../../../entities/values/DateValue";
import { TravelSection } from "./TravelSection";

export class TravelDays {
    public static readonly TYPE = "JAllowanceTravelDay";

    public static readonly SUBTYPE = "dailyAllowanceTravelDay";

    private travelDayTypeSubtypeDefinition: TypeSubtypeDefinition;

    private travelSection: TravelSection;

    private travelDays: TravelDay[] = [];

    public static create(
        travelSection: TravelSection,
        typeSubtypeDefinition: TypeSubtypeDefinition,
    ): TravelDays {
        const travelDays = new TravelDays(travelSection, typeSubtypeDefinition, []);
        return travelDays;
    }

    constructor(
        travelSection: TravelSection,
        travelDayTypeSubtypeDefinition: TypeSubtypeDefinition,
        travelSectionValueObjects: object[],
    ) {
        this.travelSection = travelSection;
        this.travelDayTypeSubtypeDefinition = travelDayTypeSubtypeDefinition;

        for (let i = 0; i < travelSectionValueObjects.length; i++) {
            this.travelDays.push(
                new TravelDay(
                    travelSection,
                    new Entity(travelDayTypeSubtypeDefinition, travelSectionValueObjects[i]),
                ),
            );
        }
    }

    public adjustStartEndDate(startDate: DateValue, endDate: DateValue): void {
        // Existierende Reisetage je Datum als Keys merken
        const travelDaysByDate: { [key: string]: TravelDay } = {};
        this.travelDays.forEach(
            (travelDay) => (travelDaysByDate[travelDay.getTravelDate().getISODate()] = travelDay),
        );

        // Startdatum bis Endatum durchlaufen: Im Zeitraum existierende Reisetage übernehmen bzw. fehlende Reisetage ergänzen.
        this.travelDays = [];
        DateValue.dateValues(startDate, endDate).forEach((date) => {
            if (travelDaysByDate.hasOwnProperty(date.getISODate())) {
                this.travelDays.push(travelDaysByDate[date.getISODate()]);
            } else {
                const dailyAllowanceId = this.travelSection.getId();
                this.travelDays.push(
                    TravelDay.create(
                        this.travelSection,
                        this.travelDayTypeSubtypeDefinition,
                        dailyAllowanceId,
                        date,
                    ),
                );
            }
        });
    }

    public countTravelDates(): number {
        return this.travelDays.length;
    }

    public getAllTravelDays(): TravelDay[] {
        return this.travelDays;
    }

    public countMeals(): { [key: string]: number } {
        const mealSums: { [key: string]: number } = {};

        this.travelDays.forEach((travelDay) => {
            travelDay.getMealNamesAndValues().forEach((mealNameAndValue) => {
                mealSums[mealNameAndValue.name] =
                    (mealSums[mealNameAndValue.name] || 0) + (mealNameAndValue.value ? 1 : 0);
            });
        });

        return mealSums;
    }

    public toValueObject(): object[] {
        const travelSectionValueObjects = [];

        for (let i = 0; i < this.travelDays.length; i++) {
            travelSectionValueObjects.push(this.travelDays[i].toValueObject());
        }

        return travelSectionValueObjects;
    }
}
