import { ListRowModel } from "../../../../gui/list/ListRowModel";
import { SchemaDataType } from "../../../../common/schema/SchemaDataType";
import { AttributeDefinition } from "../../../../common/schema/AttributeDefinition";
import { EntityValue } from "../../../../entities/values/EntityValue";
import { StringValue } from "../../../../entities/values/StringValue";
import { allowanceOptionType } from "../../../allowances/pages/pspselection/AllowanceOptionSelectionController";
import { contactOptionType } from "./ContactOuContrySelectionController";
import { I18n } from "../../../../common/i18n/I18n";

export class ContactOptionSearchListRowModel implements ListRowModel {
    private contactOption: contactOptionType;

    private i18n: I18n;

    constructor(contactOption: allowanceOptionType, i18n: I18n) {
        this.contactOption = contactOption;
        this.i18n = i18n;
    }

    public getEntityId(name?: string): string {
        return this.contactOption.optionId;
    }

    public getDataType(name: string): SchemaDataType {
        return SchemaDataType.STRING;
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return null;
    }

    public getValue(name: string): EntityValue {
        return new StringValue(this.contactOption[name] || "???");
    }

    /**
     * GEHT hier nicht!
     * @param name
     * @param value
     * @deprecated - nicht benutzen, geht hier nicht
     */
    public setValue(name: string, value: EntityValue): void {
        // geht hier nicht :(
    }

    public isEditable(): boolean {
        return false;
    }

    public isDeletable(): boolean {
        return false;
    }
}
