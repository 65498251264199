/**
 * Browser-Hilfmittel (z.B. zur Identifikation oder Bestimmung des Vollbildmodus)
 */
export class Browser {
    /**
     * @returns true wenn Browser auf einem iOS Mobilgerät läuft (iPhone/iPad)
     */
    public static isIOS(): boolean {
        const userAgent = navigator.userAgent;
        return (
            ((!!userAgent.match(/iPhone/i) && !userAgent.match(/like iPhone/i)) ||
                (!!userAgent.match(/iPad/i) && !userAgent.match(/like iPad/i))) &&
            !!userAgent.match(/AppleWebKit/)
        );
    }

    /**
     * @returns true wenn Browser ein Internet Explorer 11 / Edge ist
     */
    public static isInternetExplorer(): boolean {
        const userAgent = navigator.userAgent;
        return !!userAgent.match(/MSIE \d|Trident.*rv:/) || !!userAgent.match(/Edge/);
    }

    /**
     * @returns true wenn der Browser im Vollbildmosus läuft
     */
    public static isStandalone(): boolean {
        return (
            window.navigator["standalone"] ||
            window.matchMedia("(display-mode: standalone)").matches
        );
    }

    public static isIOSVersion(iOSVersion: number): boolean {
        return window.navigator.userAgent.includes("iPhone OS " + iOSVersion);
    }
}
