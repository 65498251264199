import { ComponentRegistry } from "./ComponentRegistry";
import { Controller } from "./Controller";
import { HttpURL } from "../util/http/HttpURL";
import { ServerConfigProperties } from "../common/config/ServerConfigProperties";

export class Router {
    public static readonly LOCAL_STORAGE_PAGE_HISOTRY = "PageHistory";

    private componentRegistry: ComponentRegistry;

    private currentState: { path: string; parameters: { [key: string]: string } };

    private currentController: Controller;

    private controllerStates: { [key: string]: ControllerState } = {};

    constructor(componentRegistry: ComponentRegistry) {
        this.componentRegistry = componentRegistry;

        const self = this;
        window.onpopstate = (event) => self.navigateToolBarBack(event);
    }

    public gotoLoginPage(): void {
        this.gotoPage("login");
    }

    public gotoIndexPage(): void {
        this.gotoPage("index");
    }

    public gotoPage(
        path: string,
        parameters?: { [key: string]: string },
        animation?: Animation,
    ): void {
        const state = { path: path, parameters: parameters || {} };
        this.open(state, true, animation);
    }

    /**
     * Ruft Seite mit dem Pfad und den Parametern auf, die im Hash der aktuellen Browser-URL gegeben ist.
     */
    public gotoCurrentURL() {
        const state = this.readStateFromURL();
        state.parameters = state.parameters || {};
        this.open(state, true, Animation.NONE);
    }

    /**
     * Ersetzt Parameter der aktuellen Seite.
     * @param parameters Neue Parameter
     */
    public replaceCurrentParameters(parameters) {
        const url = this.composeRelativeURL(this.currentState.path, parameters || {});
        window.history.replaceState(null, null, url);
    }

    public replaceCurrentParameter(parameter: string, value: string): void {
        const currentState = this.readStateFromURL();
        currentState.parameters[parameter] = value;
        this.replaceCurrentParameters(currentState.parameters);
    }

    public removeCurrentParameter(parameter: string): void {
        const currentState = this.readStateFromURL();
        delete currentState.parameters[parameter];
        this.replaceCurrentParameters(currentState.parameters);
    }

    /**
     * Öffnen Seite mit gegebenem Status (Pfad, Parameter)
     * @param Status (Pfad, Parameter)
     * @param pushToHistory true, um neue Seite zur Browser-Historie hinzuzufügen
     * @param animation Slide-Animation (optional)
     */
    private open(
        state: { path: string; parameters: { [key: string]: string } },
        pushToHistory: boolean,
        animation?: Animation,
    ) {
        if (this.currentController) {
            this.getControllerState(this.currentState.path).setPageScrollTop($(window).scrollTop());
            this.currentController.destroy();
        }

        if (!state.path || state.path.length == 0 || state.path == "/") {
            state.path = "index";
        }

        if (pushToHistory) {
            const url = this.composeRelativeURL(state.path, state.parameters);
            window.history.pushState({ path: state.path }, state.path, url);
        }
        this.currentState = state;

        const appNavigator = new AppNavigator(this, this.getControllerState(state.path));

        const serverConfigProperties = <ServerConfigProperties>(
            this.componentRegistry.getComponent(ServerConfigProperties.BCS_COMPONENT_NAME)
        );
        if (serverConfigProperties.getProperty("MobileApp.GUI.UsePageAnimations") != "true") {
            appNavigator.setShowAnimations(false);
        }

        this.currentController = this.componentRegistry.getController(state.path);
        this.currentController.compose(state.parameters, animation || Animation.NONE, appNavigator);
    }

    /**
     * Erzeugt URL mit Hash, der aktuelle Seite (Pfad und Parameter) repräsentiert
     * @param path Pfad
     * @param parameters Parameters
     * @returns URL
     */
    private composeRelativeURL(path, parameters) {
        const appPathURL = new HttpURL()
            .setPath("/" + path || "/")
            .setAllParameters(parameters || {});
        const appRelativURL = appPathURL.toRelativeURL().substring(1);
        const httpURL = new HttpURL(window.location.href).setHash(appRelativURL);
        return httpURL.toRelativeURL();
    }

    /**
     * Parst aktuelle URL, um den Pfad und die Parameter der aktuellen Seite auszulesen.
     * Pfad und die Parameter werden nur aus dem Hash der aktuellen URL gelesen.
     *
     * return Objekt mit Pfad und Parametern
     */
    private readStateFromURL(): { path: string; parameters: { [key: string]: string } } {
        const httpURL = new HttpURL(window.location.href);
        const appPathURL = new HttpURL("/" + httpURL.getHash());
        const appPath = appPathURL.getPath().substring(1);

        return { path: appPath, parameters: appPathURL.getParameters() };
    }

    /**
     * Aufruf nach Browser-Back-Navigation:
     * Ruft am aktuellen Controller (sofern vorhanden) die Funktion zum Navigation zur nächst höheren Ebene auf.
     */
    private navigateToolBarBack(event: Event) {
        if (this.currentController) {
            if (window.location.href.indexOf("#") < window.location.href.length - 1) {
                this.currentController.popState();
            }
        }
    }

    private getControllerState(path): ControllerState {
        return this.controllerStates[path] || (this.controllerStates[path] = new ControllerState());
    }
}

export const enum Animation {
    NONE = "none",
    SLIDE_LEFT = "slide_left",
    SLIDE_RIGHT = "slide_right",
}

// TODO App - Kann der AppTestNavigator weg und wir benutzen AppNavigator.pageStack ?
export class AppTestNavigator {
    public static pageStack: { parameters: {}; page: string }[] = [];
}

export class AppNavigator {
    private static pageStack: { parameters: {}; page: string }[];

    private router: Router;

    private controllerState: ControllerState;

    private showAnimations = true;

    constructor(router: Router, controllerState: ControllerState) {
        this.router = router;
        this.controllerState = controllerState;
    }

    public setShowAnimations(showAnimations: boolean): AppNavigator {
        this.showAnimations = showAnimations;
        return this;
    }

    public doShowAnimations(): boolean {
        return this.showAnimations;
    }

    public getControllerState(): ControllerState {
        return this.controllerState;
    }

    public navigateTo(
        path: string,
        parameters?: { [key: string]: string },
        animation?: Animation,
    ): void {
        this.router.gotoPage(path, parameters, animation);
    }

    public replaceCurrentParameters(parameters: { [key: string]: string }): void {
        this.router.replaceCurrentParameters(parameters);
    }

    public replaceCurrentParameter(parameter: string, value: string): void {
        this.router.replaceCurrentParameter(parameter, value);
    }

    public removeCurrentParameter(parameter: string): void {
        this.router.removeCurrentParameter(parameter);
    }

    public pushPage(parameters: {}, page: string) {
        //AppConsole.debug("[pushPage]:",{ "parameters": parameters, "page": page });
        AppTestNavigator.pageStack.push({ parameters: parameters, page: page });
        this.saveHistoryToLocalStorage(AppTestNavigator.pageStack);
    }

    public popPage(): { parameters: {}; page: string } {
        if (
            typeof AppTestNavigator.pageStack === "undefined" ||
            AppTestNavigator.pageStack.length === 0
        ) {
            AppTestNavigator.pageStack = this.restoreHistoryFromLocalStorage();
        }
        const lastIndex = AppTestNavigator.pageStack.length - 1;
        const result = AppTestNavigator.pageStack[lastIndex];
        AppTestNavigator.pageStack.splice(lastIndex, 1);
        return result;
    }

    public cleanPages(): void {
        AppNavigator.pageStack = [];
    }

    public saveHistoryToLocalStorage(pageStack) {
        window.localStorage.setItem(Router.LOCAL_STORAGE_PAGE_HISOTRY, JSON.stringify(pageStack));
    }
    public restoreHistoryFromLocalStorage() {
        const history = window.localStorage.getItem(Router.LOCAL_STORAGE_PAGE_HISOTRY);
        if (history && history !== null && typeof history !== "undefined") {
            return JSON.parse(history);
        }
    }
}

export class ControllerState {
    private properties: { [key: string]: string | number | boolean | object } = {};

    public setProperty(name: string, value: string | number | boolean | object) {
        this.properties[name] = value;
    }

    public getProperty(
        name: string,
        defaultValue: string | number | boolean | object = null,
    ): string | number | boolean | object {
        return this.properties[name] || defaultValue;
    }

    public setPageScrollTop(pageScrollTop: number): ControllerState {
        this.setProperty("Page.PageScrollTop", pageScrollTop);
        return this;
    }

    public getPageScrollTop(): number {
        return <number>this.getProperty("Page.PageScrollTop") || 0;
    }
}
