import { GUIElement } from "../../../../gui/GUIElement";
import { FlexBox, FlexJustifyContent, FlexAlignItems } from "../../../../gui/flexbox/FlexBox";
import { FlexItem, FlexAlignSelf } from "../../../../gui/flexbox/FlexItem";
import { TextLabel } from "../../../../gui/content/TextLabel";
import { TravelSection } from "../../records/TravelSection";
import { I18n } from "../../../../common/i18n/I18n";

/**
 * Titel eines Reiseabschnittes.
 * Enthält Reisezweck, Reisestart/ende um Anzahl Belege.
 * Kann nach Bearbeitung dieser Felder aktualisiert werden.
 */
export class TravelSectionHeader implements GUIElement {
    private i18n: I18n;

    private sectionNo: number;

    private travelSection: TravelSection;

    private titleTextLabel: TextLabel;

    constructor(sectionNo: number, travelSection: TravelSection, i18n: I18n) {
        this.sectionNo = sectionNo;
        this.travelSection = travelSection;
        this.i18n = i18n;
    }

    public compose($parent: JQuery): void {
        const sectionHeaderFlexBox = new FlexBox()
            .setJustifyContent(FlexJustifyContent.FLEX_START)
            .setAlignItems(FlexAlignItems.FLEX_START)
            .addStyleClass("travelsection_header");

        sectionHeaderFlexBox.addFlexItem(
            new FlexItem()
                .setAlignSelf(FlexAlignSelf.CENTER)
                .addContentElement(new TextLabel().setInlineText((this.sectionNo + 1).toString()))
                .addStyleClass("travelsection_number"),
        );

        this.titleTextLabel = new TextLabel();

        this.updateTitle();

        sectionHeaderFlexBox.addFlexItem(
            new FlexItem()
                .setAlignSelf(FlexAlignSelf.CENTER)
                .addContentElement(this.titleTextLabel)
                .addStyleClass("travelsection_title"),
        );

        sectionHeaderFlexBox.compose($parent);
    }

    /**
     * Titel-Elemente nach Bearbeitung von Reisezweck oder Start/Ende aktualisieren (je 1x für Tab Reisedaten und für Belege).
     * @param sectionNo Nummer des Reiseabschnitts
     * @param travelSection Reiseabschnitt
     */
    public updateTitle(): void {
        const startDateTimeStr = this.travelSection
            .getStartDateTime()
            .format(this.i18n.getDateFormat("MobileApp.DateMonthFormat"));
        const endDateTimeStr = this.travelSection
            .getEndDateTime()
            .format(this.i18n.getDateFormat("MobileApp.DateMonthFormat"));

        this.titleTextLabel
            .addStyleClass("nowrap")
            .setInlineText(
                this.i18n.get("MobileApp.travels.section") +
                    " " +
                    startDateTimeStr +
                    " - " +
                    endDateTimeStr,
            );
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}
