import { GUIElement } from "../GUIElement";

export class Collapsable implements GUIElement {
    private header: CollapsableElements = new CollapsableElements();

    private content: CollapsableElements = new CollapsableElements();

    public addHeaderElement(headerElement: GUIElement): Collapsable {
        this.header.addElement(headerElement);
        return this;
    }

    public addContentElement(contentElement: GUIElement): Collapsable {
        this.content.addElement(contentElement);
        return this;
    }

    public compose($parent: JQuery): void {
        this.header.compose($parent);
        this.content.compose($parent);
    }

    public getHeaderElement(): GUIElement {
        return this.header;
    }

    public getConentElement(): GUIElement {
        return this.content;
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}

export class CollapsableElements implements GUIElement {
    private elements: GUIElement[] = [];

    public addElement(element: GUIElement): CollapsableElements {
        this.elements.push(element);
        return this;
    }

    public compose($parent: JQuery): void {
        const $tag = $("<div>").appendTo($parent);
        this.elements.forEach((element) => element.compose($tag));
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}
