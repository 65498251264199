import { FixedGUIElement } from "../FixedGUIElement";
import { GUIElement } from "../GUIElement";
import "./BottomActionButton.less";

/**
 * Aktions-Button, rechteckig, zentriert, fixiert am unteren Seitenende.
 *
 * Anwendungsfälle: Löschen markierter Zeilen / Primäraktion der Seite auslösen
 */
export class BottomActionButton implements GUIElement, FixedGUIElement {
    private buttonMode: BottomActionButtonMode;

    private primaryActionLabel: string;

    private deletePageLabel: string;

    private downloadLabel: string;

    private emptySelectionLabel: string;

    private deleteSelectionLabel: string;

    private countSelectedElements: number = 0;

    private clickCallback: Function;

    private $buttonContainerTag: JQuery;

    private $buttonTag: JQuery;

    public setPrimaryActionLabel(primaryActionLabel: string): BottomActionButton {
        this.buttonMode = BottomActionButtonMode.PRIMARY_ACTION;
        this.primaryActionLabel = primaryActionLabel;
        return this;
    }

    public setDeletePageLabel(deletePageLabel: string): BottomActionButton {
        this.buttonMode = BottomActionButtonMode.DELETE_PAGE;
        this.deletePageLabel = deletePageLabel;
        return this;
    }

    public setDownloadPageLabel(downloadLabel: string): BottomActionButton {
        this.buttonMode = BottomActionButtonMode.DOWNLOAD_PAGE;
        this.downloadLabel = downloadLabel;
        return this;
    }

    public setEmptySelectionLabel(emptySelectionLabel: string): BottomActionButton {
        this.buttonMode = BottomActionButtonMode.DELETE_SELECTIONS;
        this.emptySelectionLabel = emptySelectionLabel;
        return this;
    }

    public setDeleteSelectionLabel(deleteSelectionLabel: string): BottomActionButton {
        this.buttonMode = BottomActionButtonMode.DELETE_SELECTIONS;
        this.deleteSelectionLabel = deleteSelectionLabel;

        this.notifyCountSelectedElements(this.countSelectedElements);

        return this;
    }

    public onClick(clickCallback: Function): BottomActionButton {
        this.clickCallback = clickCallback;
        return this;
    }

    /**
     * HTML-DOM generieren
     *
     * @param $parentTag Parent-jQuery-Objekt
     */
    public compose($parentTag: JQuery): void {
        this.$buttonContainerTag = $("<div>")
            .appendTo($parentTag)
            .addClass("bottom_action_container");

        this.$buttonTag = $("<div>")
            .appendTo(this.$buttonContainerTag)
            .addClass("bottom_action_button")
            .on("click", this.actionButtonClicked.bind(this));

        switch (this.buttonMode) {
            case BottomActionButtonMode.PRIMARY_ACTION:
                this.$buttonTag.text(this.primaryActionLabel);
                this.$buttonTag.addClass("primary_action");
                break;
            case BottomActionButtonMode.DELETE_PAGE:
                this.$buttonTag.text(this.deletePageLabel);
                this.$buttonTag.addClass("selection");
                break;
            case BottomActionButtonMode.DELETE_SELECTIONS:
                this.$buttonTag.text(this.emptySelectionLabel);
                break;
            case BottomActionButtonMode.DOWNLOAD_PAGE:
                this.$buttonTag.text(this.downloadLabel);
                this.$buttonTag.addClass("selectionGreen");
                break;
            default:
                break;
        }
    }

    public notifyCountSelectedElements(countSelectedElements: number): BottomActionButton {
        this.countSelectedElements = countSelectedElements;

        if (this.$buttonTag) {
            if (this.countSelectedElements == 0) {
                this.$buttonTag.removeClass("selection");
                this.$buttonTag.text(this.emptySelectionLabel);
            } else {
                this.$buttonTag.addClass("selection");
                this.$buttonTag.text(this.countSelectedElements + " " + this.deleteSelectionLabel);
            }
        }

        return this;
    }

    public remove(): void {
        this.$buttonContainerTag.remove();
    }

    /**
     * @return true, wenn GUIElement fixiert, false wenn nicht (für Elemente, die sowohl fixiert als auch mitscrollend sein können)
     */
    public isFixed(): boolean {
        return true;
    }

    /**
     * @return Höhe des fixierten GUIElementes (in px) nach dem alle GUIElemente der Seite gerendert wurden
     */
    public getFixedHeight(): number {
        return this.$buttonContainerTag.outerHeight() + 20; // Höhe + je 10px Luft oberhalb+unterhalöb
    }

    /**
     * Setzt Y-Position des fixierten GUIELementes (absoluten Abstand zum oberen bzw. unteren Rand).
     *
     * @param verticalPositionKey "top" oder "bottom" für Header bzw. Footer
     * @param verticalPos Y-Position vom oberen bzw. unteren Rand (z.B. 15 für "15px")
     */
    public setVerticalPosition(verticalPositionKey: string, verticalPos: number): void {
        this.$buttonContainerTag.css(verticalPositionKey, verticalPos + "px");
    }

    private actionButtonClicked(event: JQueryEventObject): void {
        if (this.clickCallback) {
            this.clickCallback(event, this.$buttonContainerTag.offset());
        }
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}

enum BottomActionButtonMode {
    PRIMARY_ACTION,
    DELETE_PAGE,
    DELETE_SELECTIONS,
    DOWNLOAD_PAGE,
}
