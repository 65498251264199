import { HTTPError } from "../../util/http/HTTPError";
import { I18n } from "../../common/i18n/I18n";

export const enum MessageType {
    ERROR = "error",
    WARNING = "warning",
    AFFIRMATION = "affirmation",
    INFO = "info",
    SYNC = "sync",
}

export class MessageEntity {
    private messageType: MessageType;

    private text: string = null;

    /**
     * @see isSticky()
     */
    private readonly stickyTypes: MessageType[] = [MessageType.ERROR, MessageType.INFO];

    constructor(text?: string, messagetype?: MessageType) {
        this.text = text;
        if (typeof messagetype != "undefined") {
            this.messageType = messagetype;
        }
    }

    public static createServerErrorMessage(
        errorServerInformation: HTTPError,
        i18n: I18n,
    ): MessageEntity {
        if (errorServerInformation.hasBCSErrorMessage()) {
            return new MessageEntity(
                errorServerInformation.getBCSErrorMessage(),
                MessageType.ERROR,
            );
        } else if (errorServerInformation.isInternalServerError()) {
            const errorText = i18n.get("MobileApp.txtError.unknownServerError");
            return new MessageEntity(errorText, MessageType.ERROR);
        } else if (errorServerInformation.isNoResponseFromServer()) {
            const errorText = i18n.get("MobileApp.txtError.noConnectionToServer");
            return new MessageEntity(errorText, MessageType.ERROR);
        }
    }

    public static createFromJSON(jsonMessage: string): MessageEntity {
        const messageContent = JSON.parse(jsonMessage);
        return new MessageEntity(messageContent.text, messageContent.messageType);
    }

    /**
     * initialer Anwendungsfall:
     *
     * Falls die Nachricht nicht Sticky ist. Also nicht beständig, sollte sie automatisch nach X Sekunden ausgeblendet werden.
     * Eine Erfolgsnachricht benötigt muss man nicht die ganze Zeit sehen, es reicht wenn man gesehen hat, dass es funktioniert hat.
     * Dagegen soll eine Fehlermeldung die sticky ist, solange erscheinen, bis der Benutzer sie entfernt hat.
     */
    public isSticky(): boolean {
        if (this.stickyTypes.indexOf(this.getType()) !== -1) {
            return true;
        } else {
            return false;
        }
    }

    public getType(): MessageType {
        return this.messageType;
    }

    public setMessage(text: string): void {
        this.text = text;
    }

    public getMessage(): string {
        return this.text;
    }

    public equals(otherMessageEntity: MessageEntity) {
        const sameMessage: boolean = otherMessageEntity.getMessage() == this.getMessage();
        const sameType: boolean = otherMessageEntity.getType() == this.getType();
        if (sameMessage && sameType) {
            return true;
        }
        return false;
    }
}
