import { HttpRESTClient } from "../../util/http/HttpRESTClient";
import { ProgressFeedback } from "../../util/progress/ProgressFeedback";

export class I18nClient {
    public async fetchI18nLabels(
        age: number,
        userLocale: string,
        progressFeedback: ProgressFeedback,
    ): Promise<object> {
        const result = await new HttpRESTClient()
            .createRequest()
            .addPath("i18n")
            .setParameter("age", age)
            .setParameter("locale", userLocale)
            .onProgress((isDownload, loaded, total) =>
                progressFeedback.notifyProgress(loaded, total),
            )
            .send();

        progressFeedback.notifyProgress(100, 100);

        switch (result["status"]) {
            case 200:
                return result["json"];
            case 204:
                return { cache: "uptodate" };
            default:
                throw new Error("[I18nClient] Error fetchI18nLabels: " + result["status"]);
        }
    }
}
