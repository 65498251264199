import { GUIElement } from "../GUIElement";
import { FormField, FormFieldMode } from "../form/FormField";
import { TextLabel } from "../content/TextLabel";
import { ListRowContext } from "./ListRowContext";
import { EntityValue } from "../../entities/values/EntityValue";
import { ListField } from "./ListField";
import { FlexBox } from "../flexbox/FlexBox";
import { Image } from "../content/Image";
import { FlexItem } from "../flexbox/FlexItem";
import { ListFieldFeedback, ListFieldFeedbackType } from "./ListFieldFeedback";
import { Log } from "../../common/log/Log";

export class ListRowField implements GUIElement, ListField {
    private listRowContext: ListRowContext;

    private name: string;

    private labelKey: string;

    private mode: FormFieldMode;

    /** Ist Field in GUI als Link zu Unterseite/Auswahlseite dargestellt */
    private isLink: boolean;

    private formField: FormField;

    /** Icon vor dem ListRow-Feld (optional), um Art der Eingabe zu symbolisieren, z.B. Uhrzeit, Betrag, Ort */
    private iconName: string;

    private cssClasses: string;

    private placeholder: string;

    private readOnly: boolean = false;

    /** Weitere Parameter, die bis ans eigentliche Field durchgereicht werden können. Bisher nur bei DurationField implementiert. */
    private additionalParameters;

    /*
    private $feedbackCellTag: JQuery;
    */

    private updateValuesCallback: (feedbackType: ListFieldFeedbackType) => void;

    /**
     * @param listViewContext ListView-Kontext (I18n, Erzeugen von FormFields, ...)
     * @param listFieldSetRowDefinition Definition dieser ListFieldSet-Komponente als JSON ausgelesen aus einer Konfiguration
     */
    constructor(listRowContext: ListRowContext, listRowFieldDefinition?: object) {
        this.listRowContext = listRowContext;

        if (listRowFieldDefinition) {
            this.appyDefinition(listRowFieldDefinition);
        }
    }

    private appyDefinition(listRowFieldDefinition: object): void {
        // Field aus Definition auslesen
        this.name = listRowFieldDefinition["name"];
        this.labelKey = listRowFieldDefinition["label"];
        this.iconName = listRowFieldDefinition["icon"];
        this.mode = <FormFieldMode>listRowFieldDefinition["mode"];
        this.isLink = listRowFieldDefinition["isLink"] === true;
        this.cssClasses = listRowFieldDefinition["styleClass"] || "";
        this.additionalParameters = listRowFieldDefinition["additionalParameters"];

        this.placeholder = listRowFieldDefinition["placeholder"];
        if (typeof listRowFieldDefinition["readonly"] !== "undefined") {
            this.readOnly = true;
        }
    }

    public compose($parent: JQuery): void {
        const $fieldContainer = $("<div>")
            .appendTo($parent)
            .addClass("listRowField " + this.cssClasses);

        if (this.labelKey) {
            const $labelContainer = $("<div>").appendTo($fieldContainer).addClass("title");

            if (this.labelKey != "NONE") {
                new TextLabel()
                    .setInlineText(this.listRowContext.getI18n().get(this.labelKey))
                    .compose($labelContainer);
            }
        }

        const $layoutTableTag = $("<table>").appendTo($fieldContainer).addClass("layout");

        const $layoutRowTag = $("<tr>").appendTo($layoutTableTag);

        if (this.iconName) {
            const $iconLayoutCellTag = $("<td>").appendTo($layoutRowTag).addClass("icon");
            new Image().setImageName(this.iconName + ".svg").compose($iconLayoutCellTag); // TODO App - +".svg" später entfernen
        }

        const $contentLayoutCellTag = $("<td>").appendTo($layoutRowTag).addClass("content");

        const listRowModel = this.listRowContext.getRowModel();
        const entityId = listRowModel.getEntityId(this.name);
        try {
            const dataType = listRowModel.getDataType(this.name);
            const attributeDefinition = listRowModel.getAttributeDefinition(this.name);
            const value = listRowModel.getValue(this.name);
            this.mode = listRowModel.isEditable() ? this.mode : FormFieldMode.DISPLAY;

            this.formField = this.listRowContext
                .getFormFieldFactory()
                .createFormField(
                    dataType,
                    attributeDefinition,
                    this.listRowContext.getI18n(),
                    this.additionalParameters,
                )
                .setEntityId(entityId)
                .setName(this.name)
                .setMode(this.mode);

            if (this.readOnly && this.formField.setReadOnly) {
                this.formField.setReadOnly(true);
            }
            if (typeof this.placeholder !== "undefined") {
                const placeholderConfText = this.listRowContext.getI18n().get(this.placeholder);
                this.formField.setPlaceholder(placeholderConfText).setValue(value);
            } else {
                this.formField.setValue(value);
            }

            // Undefinierte Pflichtfelder markieren
            if (
                (this.mode == FormFieldMode.EDIT || this.isLink) &&
                (!value || !value.isDefined()) &&
                attributeDefinition &&
                attributeDefinition.isRequired()
            ) {
                $contentLayoutCellTag.addClass("required");
            }

            this.formField.compose($contentLayoutCellTag);
        } catch (exception) {
            $contentLayoutCellTag.text("[" + this.name + "?]");

            Log.error(
                "[ListRowField] Error composing field " + this.name + ": " + exception,
                { entityId: entityId, name: this.name },
                exception,
            );
        }
    }

    public updateValues(listFieldFeedback: ListFieldFeedback): void {
        const value = this.listRowContext.getRowModel().getValue(this.name);
        this.formField.updateValue(value);

        const entityId = this.listRowContext.getRowModel().getEntityId(this.name);

        const feedback = listFieldFeedback.getFeedback(entityId, this.name);
        if (feedback) {
            if (feedback.type && this.updateValuesCallback) {
                this.updateValuesCallback(feedback.type);
            }
        }
    }

    public onUpdateValues(
        updateValuesCallback: (feedbackType: ListFieldFeedbackType) => void,
    ): void {
        this.updateValuesCallback = updateValuesCallback;
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}
