import { FormFieldAddOn } from "../form/FormFieldAddOn";
import { FormField } from "../form/FormField";
import { EntityValue } from "../../entities/values/EntityValue";
import { StringValue } from "../../entities/values/StringValue";

export class TypeAheadSearchResultHighlightFormFieldAddOn implements FormFieldAddOn {
    /** Modus: Highlighten von Suchworten (Standard) oder einzelnen Suchzeichen (starke Fragmentierung) */
    private static readonly HIGHLIGHT_WORDS_TOKENS = true;

    private searchInput: string = "";

    public setSearchInput(searchInput: string): void {
        this.searchInput = searchInput;
    }

    public isApplicableFor(formField: FormField): boolean {
        return true;
    }

    public applyToTag(entityValue: EntityValue, formFieldTag: JQuery): void {
        if (entityValue && entityValue.isDefined()) {
            // Da Ergebnis als HTML behandelt wird, vorab HTMLTags escapen
            const text = (<StringValue>entityValue).getPlainText();

            let html = "";
            if (TypeAheadSearchResultHighlightFormFieldAddOn.HIGHLIGHT_WORDS_TOKENS) {
                html = this.highlightWordTokens(text);
            } else {
                html = this.highlightCharacterTokens(text);
            }

            formFieldTag.html(html);
        }
    }

    private highlightWordTokens(text: string): string {
        if (this.searchInput != "") {
            const searchTokens = this.searchInput
                .trim()
                .replace(/[^\wÄÖÜäöüß ]/g, " ") // nicht nach Sonderzeichen suchen, führt manchmal zu seltsamen Fehlern
                .split(" ")
                .filter((searchToken) => searchToken.length > 0);
            for (let i = 0; i < searchTokens.length; i++) {
                const searchToken = searchTokens[i];
                // Suchtext-Token in Ergebnistexten durch sich selbst und Platzhalter ersetzen
                text = text.replace(
                    new RegExp("(" + searchToken + ")", "ig"),
                    "\u0007" + "$1" + "\u0008",
                );
            }

            // Platzhalter durch HTML-Tags zum Herheben ersetzen (damit nicht oben strong als Ergebnis gefunden wird)
            text = text
                .replace(new RegExp("\u0007", "g"), "<strong>")
                .replace(new RegExp("\u0008", "g"), "</strong>");

            return text;
        }
    }

    private highlightCharacterTokens(text: string): string {
        if (this.searchInput != "") {
            const searchTokens = this.searchInput
                .trim()
                .replace(/[^\w ]/g, "")
                .split(" ")
                .filter((searchToken) => searchToken.length > 0);

            for (let i = 0; i < searchTokens.length; i++) {
                const searchToken = searchTokens[i];

                const expression: string[] = [];
                const replacement: string[] = [];
                let c = 0;
                for (let x = 0; x < searchToken.length; x++) {
                    const character = searchToken.charAt(x);

                    expression.push((x > 0 ? "(.*?)" : "") + "(" + character + ")");
                    replacement.push((x > 0 ? "$" + ++c : "") + "\u0007" + "$" + ++c + "\u0008");
                }

                text = text.replace(new RegExp(expression.join(""), "ig"), replacement.join(""));
            }

            text = text
                .replace(new RegExp("\u0007", "g"), "<strong>")
                .replace(new RegExp("\u0008", "g"), "</strong>");

            return text;
        }
    }
}
