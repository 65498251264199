import { Component } from "../../core/Component";
import { SingletonComponent } from "../../core/SingletonComponent";
import { Registry } from "../../core/Registry";
import { IndexedDB } from "../../database/IndexedDB";
import { IndexedDBQuery } from "../../database/IndexedDBQuery";
import { lang } from "moment";
import { IndexedDBVersion } from "../../database/IndexedDBVersion";

/**
 * Verwaltet alle Properties, die sich die App persistent merken möchte.
 *
 * Properties sind Name-Wert-Paar und können optional zu einem User (z.B. Aufgabenliste) bzw. zu einer Sprache (z.B. I18n) gehören.
 *
 * Properties können eindedutig anhand ihres Namens bzw. ihres Namen und der UserOid bzw. der Sprache gelesen werden.
 * Alternativ können alle Properties nach UserOid bzw. Sprache gesucht/gefiltert werden.
 */
export class ApplicationProperties implements Component, SingletonComponent {
    /** Name der Singleton-Komponente */
    public static BCS_COMPONENT_NAME = "ApplicationProperties";

    private static PROPERTIES_STORE_NAME = "properties";

    private static ID_KEY = "id";

    private static USER_INDEX_NAME = "user";

    private static LOCALE_INDEX_NAME = "locale";

    private indexedDB: IndexedDB;

    private properties: object = {};

    public getDependencyNames(): string[] {
        return [IndexedDB.BCS_COMPONENT_NAME];
    }

    public init(depencencyComponents: { [key: string]: Component }) {
        this.indexedDB = <IndexedDB>depencencyComponents[IndexedDB.BCS_COMPONENT_NAME];

        // Allgemeinen Store für Properties anmelden
        this.indexedDB
            .registerStore(
                ApplicationProperties.PROPERTIES_STORE_NAME,
                IndexedDBVersion.DB_VERSION_1,
            )
            .setIdKey(ApplicationProperties.ID_KEY)
            .addIndex(
                ApplicationProperties.USER_INDEX_NAME,
                [ApplicationProperties.USER_INDEX_NAME],
                false,
                IndexedDBVersion.DB_VERSION_1,
            )
            .addIndex(
                ApplicationProperties.LOCALE_INDEX_NAME,
                [ApplicationProperties.LOCALE_INDEX_NAME],
                false,
                IndexedDBVersion.DB_VERSION_1,
            );
    }

    public start(): Promise<void> {
        return Promise.resolve();
    }

    public notifyBeginUserSession(isOnline: boolean): Promise<void> {
        return Promise.resolve();
    }

    public synchronize(): Promise<void> {
        return Promise.resolve();
    }

    /**
     * Liest eine Property aus der Datenbank.
     * @param name Name der Property
     * @param userOid Oid des Users für den diese Property gilt (oder null)
     * @param locale Sprache für die diese Property gilt (oder null). Sprache wird ignoriert, wenn userOid gegeben.
     */
    public readProperty(name: string, userOid: string | null, locale: string): Promise<object> {
        // Die id setzt sich aus name und ggf userOid bzw. locale zusammen
        const id = this.composePropertyId(name, userOid, locale);

        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([ApplicationProperties.PROPERTIES_STORE_NAME])
                .selectId(ApplicationProperties.PROPERTIES_STORE_NAME, id)
                .then(
                    (result) => {
                        resolve(result && result.element ? result.element : null);
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    /**
     * Schreibt eine Property in die Datenbank.
     * @param name Name der Property
     * @param userOid Oid des Users für den diese Property gilt (oder null)
     * @param locale Sprache für die diese Property gilt (oder null). Sprache wird ignoriert, wenn userOid gegeben.
     * @param value Wert der Property (ein Javascript object)
     */
    public writeProperty(
        name: string,
        userOid: string,
        locale: string,
        value: object,
    ): Promise<void> {
        // Die id setzt sich aus name und ggf userOid bzw. language zusammen
        // Zusätzlich werden ggf userOid bzw. language als Key gesetzt, um danach Suchen/Filtern zu können.
        value = this.defineIdAndIndexValues(name, userOid, locale, value);

        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([ApplicationProperties.PROPERTIES_STORE_NAME])
                .updateElements(ApplicationProperties.PROPERTIES_STORE_NAME, [value])
                .then(
                    () => {
                        resolve();
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    /**
     * Löscht eine Property aus der Datenbank.
     * @param name Name der Property
     * @param userOid Oid des Users für den diese Property gilt (oder null)
     * @param language Sprache für die diese Property gilt (oder null. )Sprache wird ignoriert, wenn userOid gegeben.
     */
    public removeProperty(name: string, userOid: string, language: string): Promise<void> {
        // Die id setzt sich aus name und ggf userOid bzw. language zusammen
        const id = this.composePropertyId(name, userOid, language);

        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([ApplicationProperties.PROPERTIES_STORE_NAME])
                .deleteIds(ApplicationProperties.PROPERTIES_STORE_NAME, [id])
                .then(
                    () => {
                        resolve();
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    /**
     * Liefert die Ids aller Properties zu einem User bzw. zu einer Sprache aus der Datenbank.
     * @param userOid Oid des Users für den diese Property gelten (oder null)
     * @param language Sprache für die diese Property gelten (oder null). Sprache wird ignoriert, wenn userOid gegeben.
     */
    public readAllPropertiesIds(userOid: string, language: string): Promise<string> {
        // Gesucht wird nach UserOid bzw. Sprache
        const index = userOid
            ? ApplicationProperties.USER_INDEX_NAME
            : ApplicationProperties.LOCALE_INDEX_NAME;
        const query = IndexedDBQuery.only(userOid ? [userOid] : [language]);

        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([ApplicationProperties.PROPERTIES_STORE_NAME])
                .selectIdCursor(ApplicationProperties.PROPERTIES_STORE_NAME, index, query)
                .then(
                    (result) => {
                        resolve(result && result.resultSet ? result.resultSet : null);
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    private composePropertyId(name: string, userOid: string | null, language: string): string {
        // Die id setzt sich aus name und ggf userOid bzw. language zusammen
        const id = null;
        if (userOid) {
            return name + "|" + userOid;
        } else if (language) {
            return name + "|" + language;
        }
        return name;
    }

    private defineIdAndIndexValues(
        name: string,
        userOid: string,
        language: string,
        value: object,
    ): object {
        // Die id setzt sich aus name und ggf userOid bzw. language zusammen
        // Zusätzlich werden ggf userOid bzw. language als Key gesetzt, um danach Suchen/Filtern zu können.
        if (userOid) {
            value[ApplicationProperties.ID_KEY] = name + "|" + userOid;
            value[ApplicationProperties.USER_INDEX_NAME] = userOid;
        } else if (language) {
            value[ApplicationProperties.ID_KEY] = name + "|" + language;
            value[ApplicationProperties.LOCALE_INDEX_NAME] = language;
        } else {
            value[ApplicationProperties.ID_KEY] = name;
        }
        return value;
    }
}

Registry.registerSingletonComponent(
    ApplicationProperties.BCS_COMPONENT_NAME,
    ApplicationProperties,
);
