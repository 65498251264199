import { GUIElement } from "../GUIElement";
import { ToolLink } from "./ToolLink";
import { FlexBox, FlexJustifyContent, FlexAlignItems } from "../flexbox/FlexBox";
import { TextLabel } from "../content/TextLabel";
import { FlexItem, FlexAlignSelf } from "../flexbox/FlexItem";
import { NavigationLink } from "./NavigationLink";
import { FixedGUIElement } from "../FixedGUIElement";
import "./Navigation.less";

export class ToolBar implements GUIElement, FixedGUIElement {
    private title: string;

    private toolLinksLeft: NavigationLink[] = [];

    private toolLinksCenter: NavigationLink[] = [];

    private toolLinksRight: NavigationLink[] = [];

    private styleClasses: string[] = [];

    private miniLabel: string;

    private $toolbarTag: JQuery;

    private toolbarGroupLeftFlexItem: FlexItem;

    private toolbarGroupCenterFlexItem: FlexItem;

    private toolbarGroupRightFlexItem: FlexItem;

    /** Merken des ToolLionks, der den Delete-Modus beendet (und die Anzahl der selektierten Zeilen anzeigt) */
    private cancelDeleteModeToolLink: ToolLink;

    /**
     *  Identifziert die Componente in der GUI.
     */
    private componentId: string;

    public setId(componentId: string): ToolBar {
        this.componentId = componentId;
        return this;
    }

    public setTitle(title: string): ToolBar {
        this.title = title;
        return this;
    }

    public addToolLinkLeft(toolLink: NavigationLink): ToolBar {
        this.toolLinksLeft.push(toolLink);
        return this;
    }

    public addToolLink(toolLink: GUIElement): ToolBar {
        this.toolLinksCenter.push(toolLink);
        return this;
    }

    public addToolLinkRight(toolLink: NavigationLink): ToolBar {
        this.toolLinksRight.push(toolLink);
        return this;
    }

    public addStyleClass(styleClass: string): ToolBar {
        this.styleClasses.push(styleClass);
        return this;
    }

    public setMiniLabel(miniLabel: string): ToolBar {
        this.miniLabel = miniLabel;
        return this;
    }

    /**
     * HTML-DOM generieren
     *
     * @param $parent Parent-jQuery-Objekt
     */
    public compose($parent: JQuery): void {
        this.$toolbarTag = $("<div>")
            .appendTo($parent)
            .addClass("toolbar " + this.styleClasses.join(" "))
            .attr("id", this.componentId);

        const toolbarFlexBox = new FlexBox()
            .setAlignItems(FlexAlignItems.STRETCH)
            .setJustifyContent(FlexJustifyContent.SPACE_BETWEEN)
            .addStyleClass("toolbar_container");

        this.toolbarGroupLeftFlexItem = toolbarFlexBox
            .newFlexItem()
            .setAlignSelf(FlexAlignSelf.CENTER)
            .setFlexBasis("33%");

        this.toolbarGroupCenterFlexItem = toolbarFlexBox
            .newFlexItem()
            .setAlignSelf(FlexAlignSelf.CENTER)
            .setFlexBasis("33%");

        this.toolbarGroupRightFlexItem = toolbarFlexBox
            .newFlexItem()
            .setAlignSelf(FlexAlignSelf.CENTER)
            .setFlexBasis("33%");

        this.composeToolLinksGroups();

        this.composeMiniLabel();

        toolbarFlexBox.compose(this.$toolbarTag);
    }

    private composeToolLinksGroups(): void {
        this.composeToolLinksGroup(
            this.toolbarGroupLeftFlexItem,
            FlexJustifyContent.FLEX_START,
            this.toolLinksLeft,
        );
        this.composeToolLinksGroup(
            this.toolbarGroupCenterFlexItem,
            FlexJustifyContent.CENTER,
            this.toolLinksCenter,
            this.title,
        );
        this.composeToolLinksGroup(
            this.toolbarGroupRightFlexItem,
            FlexJustifyContent.FLEX_END,
            this.toolLinksRight,
        );
    }

    private composeToolLinksGroup(
        toolbarGroupFlexItem: FlexItem,
        justifyContent: FlexJustifyContent,
        toolLinks: NavigationLink[],
        title?: string,
    ): void {
        toolbarGroupFlexItem.clearContentElements();

        for (let i = 0; i < toolLinks.length; i++) {
            toolbarGroupFlexItem
                .newFlexItem(justifyContent)
                .setAlignSelf(FlexAlignSelf.CENTER)
                .addStyleClass("toolLinkDiv")
                .addContentElement(toolLinks[i]);
        }

        if (title) {
            toolbarGroupFlexItem
                .newFlexItem(justifyContent)
                .setAlignSelf(FlexAlignSelf.CENTER)
                .addContentElement(new TextLabel().addStyleClass("title").setText(this.title));
        }
    }

    private composeMiniLabel(): void {
        if (this.miniLabel) {
            $("<div>").appendTo(this.$toolbarTag).addClass("minilabel").text(this.miniLabel);
        }
    }

    /**
     * @return true, wenn GUIElement fixiert, false wenn nicht (für Elemente, die sowohl fixiert als auch mitscrollend sein können)
     */
    public isFixed(): boolean {
        return true;
    }

    /**
     * @return Höhe des fixierten GUIElementes (in px) nach dem alle GUIElemente der Seite gerendert wurden
     */
    public getFixedHeight(): number {
        return this.$toolbarTag.outerHeight();
    }

    /**
     * Setzt Y-Position des fixierten GUIELementes (absoluten Abstand zum oberen bzw. unteren Rand).
     *
     * @param verticalPositionKey "top" oder "bottom" für Header bzw. Footer
     * @param verticalPos Y-Position vom oberen bzw. unteren Rand (z.B. 15 für "15px")
     */
    public setVerticalPosition(verticalPositionKey: string, verticalPos: number): void {
        this.$toolbarTag.css(verticalPositionKey, verticalPos + "px");
    }

    public enterDeleteMode(
        selectAllLabel: string,
        leaveModeCallback: Function,
        selectAllCallback: Function,
    ): void {
        const deleteToolLinksLeft: ToolLink[] = [
            new ToolLink()
                .setId("quitEditMode")
                .setImageName("icon-chevron-left.svg")
                .setText("0")
                .onClick(leaveModeCallback),
        ];
        const deleteToolLinksRight: ToolLink[] = [
            new ToolLink().setId("selectAll").setText(selectAllLabel).onClick(selectAllCallback),
        ];

        this.composeToolLinksGroup(
            this.toolbarGroupLeftFlexItem,
            FlexJustifyContent.FLEX_START,
            deleteToolLinksLeft,
        );
        this.composeToolLinksGroup(
            this.toolbarGroupRightFlexItem,
            FlexJustifyContent.FLEX_END,
            deleteToolLinksRight,
        );

        this.cancelDeleteModeToolLink = deleteToolLinksLeft[0];
    }

    public notifyCountSelectedElements(countSelectedElements: number): void {
        this.cancelDeleteModeToolLink.setText(countSelectedElements.toString());
    }

    public leaveDeleteMode(): void {
        this.composeToolLinksGroups();
    }

    public getComponentChildren(): GUIElement[] {
        const toolLinks: GUIElement[] = [];
        Array.prototype.push.apply(toolLinks, this.toolLinksLeft);
        Array.prototype.push.apply(toolLinks, this.toolLinksCenter);
        Array.prototype.push.apply(toolLinks, this.toolLinksRight);
        return toolLinks;
    }
}
