import { ListViewModel } from "../../../gui/list/ListViewModel";
import { ListRowModel } from "../../../gui/list/ListRowModel";
import { ContactListRowModel } from "./ContactListRowModel";
import { Contact } from "../Contact";
import { I18n } from "../../../common/i18n/I18n";

export class ContactOverviewListViewModel implements ListViewModel {
    private contacts: Contact[];

    private contactsById: { [key: string]: Contact } = {};

    private i18n: I18n;

    constructor(contacts: Contact[], i18n: I18n) {
        this.contacts = contacts;
        this.contacts.forEach((contact) => (this.contactsById[contact.getId()] = contact));
        this.i18n = i18n;
    }

    countRows(): number {
        return this.contacts.length;
    }

    getRowModelByNo(rowNo: number): ListRowModel {
        if (rowNo < 0 || rowNo >= this.contacts.length) {
            throw new Error(
                "ContactOverviewListViewModel::InvalidRowNo (" +
                    this.contacts.length +
                    " rows): " +
                    rowNo,
            );
        }
        return new ContactListRowModel(this.contacts[rowNo], this.i18n);
    }

    getRowModelById(id: string): ListRowModel {
        if (!this.contactsById.hasOwnProperty(id)) {
            throw new Error("[ContactOverviewListViewModel] Invalid id " + id);
        }
        return new ContactListRowModel(this.contacts[id], this.i18n);
    }

    isResultTruncated(): boolean {
        return false;
    }
}
