import { FooterTabBar, FooterTabIcon } from "../../board/FooterTabBar";
import { UserSession } from "../../common/auth/UserSession";
import { BCSDate } from "../../common/BCSDate";
import { I18n } from "../../common/i18n/I18n";
import { AppConsole } from "../../common/log/AppConsole";
import { Log } from "../../common/log/Log";
import { PreferencesManager } from "../../common/preferences/PreferencesManager";
import { Component } from "../../core/Component";
import { ConfigNode } from "../../core/Config/ConfigNode";
import { Controller } from "../../core/Controller";
import { MessageEntity, MessageType } from "../../core/Message/MessageEntity";
import { MessagePool } from "../../core/Message/MessagePool";
import { Registry } from "../../core/Registry";
import { Animation, AppNavigator } from "../../core/Router";
import { DaySelector } from "../../gui/content/DaySelector";
import { SubMenu } from "../../gui/content/SubMenu";
import { GUIEventPool } from "../../gui/event/GUIEventPool";
import { GUIContext } from "../../gui/GUIContext";
import { GUIPage } from "../../gui/GUIPage";
import { FloatingActionButton } from "../../gui/navigation/FloatingActionButton";
import { NavigationBar } from "../../gui/navigation/NavigationBar";
import { ToolBar } from "../../gui/navigation/ToolBar";
import { ToolLink } from "../../gui/navigation/ToolLink";
import { SyncState } from "../../sync/SyncState";
import { SyncStateManager } from "../../sync/SyncStateManager";
import { Booking } from "./bookings/Booking";
import { BookingsPerDay } from "./bookings/BookingsPerDay";
import { DummyBooking } from "./DummyBooking";
import { TimeRecord } from "./TimeRecord";
import "./TimeRecording.less";
import { TimeRecordingDetailController } from "./TimeRecordingDetailController";
import { TimeRecordingList } from "./TimeRecordingList";
import { TimeRecordingManager } from "./TimeRecordingManager";
import { Appointment } from "./timesheet/Appointment";
import { Timesheet } from "./timesheet/Timesheet";
import { TimeSpan } from "./timespans/TimeSpan";
import { TimeRecordingGUIDefinitions } from "./TimeRecordingGUIDefinitions";
import { ServerConfigProperties } from "../../common/config/ServerConfigProperties";

export class TimeRecordingController implements Controller {
    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static BCS_COMPONENT_NAME = "timeRecordingOverview";

    private static ATTENDANCE = "Attendance";
    private static PAUSE = "Pause";
    private static TASK = "Task";
    private static TICKET = "Ticket";
    private static REQUIREMENT = "Requirement";
    private static WORKFLOW = "Workflow";

    private static PROPERTY_SET_NAME: string = "MobileApp.TimeRecordingOverviewSubMenu";

    private navigator: AppNavigator;

    private animation: Animation;

    private i18n: I18n;

    private timeRecordingManager: TimeRecordingManager;

    private page: GUIPage;

    private context: GUIContext;

    private userSession: UserSession;

    private bookings: Booking[];
    private timespans: TimeSpan[];
    private appointments: Appointment[];

    private daySelector: DaySelector;

    private timeRecordingList: TimeRecordingList;

    private messagePool: MessagePool;
    private eventPool: GUIEventPool;

    private syncStateManager: SyncStateManager;
    private footerTabBar: FooterTabBar;

    private preferencesManager: PreferencesManager;
    private messages: MessageEntity[] = [];
    private serverConfigProperties: ServerConfigProperties;

    constructor() {
        this.eventPool = new GUIEventPool();
        this.registerEvents();
    }

    public getDependencyNames(): string[] {
        return [
            "I18n",
            "TimeRecordingManager",
            MessagePool.BCS_COMPONENT_NAME,
            UserSession.BCS_COMPONENT_NAME,
            SyncStateManager.BCS_COMPONENT_NAME,
            ServerConfigProperties.BCS_COMPONENT_NAME,
            PreferencesManager.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }) {
        this.i18n = <I18n>depencencyComponents["I18n"];
        this.timeRecordingManager = <TimeRecordingManager>(
            depencencyComponents["TimeRecordingManager"]
        );

        this.context = new GUIContext(this.i18n, this.eventPool);

        this.messagePool = <MessagePool>depencencyComponents[MessagePool.BCS_COMPONENT_NAME];

        this.userSession = <UserSession>depencencyComponents[UserSession.BCS_COMPONENT_NAME];

        this.syncStateManager = <SyncStateManager>(
            depencencyComponents[SyncStateManager.BCS_COMPONENT_NAME]
        );

        this.preferencesManager = <PreferencesManager>(
            depencencyComponents[PreferencesManager.BCS_COMPONENT_NAME]
        );

        this.serverConfigProperties = <ServerConfigProperties>(
            depencencyComponents[ServerConfigProperties.BCS_COMPONENT_NAME]
        );

        // Optionen der Zeiterfassung abholen und weiterreichen (für den TimerecordingTacho unterhalb des DaySelectors...)
        const timeRecordingOptions = this.timeRecordingManager.getRecordingOptions();
        const useAttendances = this.preferencesManager.getEffortRecordingWithAttendances();
        //leere neue ConfigNode, da durch die Methode das benötigte JSON erzeugt wird
        this.daySelector = new DaySelector(
            new ConfigNode("").getChildByTagOrEmpty(DaySelector.COMPONENT_NAME),
            this.context,
            timeRecordingOptions,
            useAttendances,
        ).addOnChangeDayListener(this.onChangeDay.bind(this));
    }

    public registerEvents() {
        this.eventPool.registerEventListener(
            "Message",
            "remove",
            this.removeFromMessagePool.bind(this),
        );
        this.eventPool.registerEventListener(
            SubMenu.BCS_COMPONENT_NAME,
            "openSubmenu",
            this.openSubMenu.bind(this),
        );
        this.eventPool.registerEventListener(
            NavigationBar.BCS_COMPONENT_NAME,
            NavigationBar.ICON_CLICKED_TRIGGER,
            this.navigateToPageByBar.bind(this),
        );
        this.eventPool.registerEventListener(
            "Message",
            "remove",
            this.removeFromMessagePool.bind(this),
        );
        this.eventPool.registerEventListener(
            SubMenu.BCS_COMPONENT_NAME,
            "openSubmenu",
            this.openSubMenu.bind(this),
        );
    }

    public async compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): Promise<void> {
        // Fallback ist Heute:
        let date: BCSDate = BCSDate.getToday();
        // ansonsten:
        if (typeof parameters["clickedDate"] !== "undefined") {
            date = BCSDate.getDateFromStamp(parameters["clickedDate"]);
            this.daySelector.setShownDate(BCSDate.getDateFromStamp(parameters["clickedDate"]));
        }

        this.navigator = navigator;
        this.animation = animation;

        if (parameters["warnings"]) {
            this.addMessage(
                new MessageEntity(
                    this.i18n.get("MobileApp.booking.message." + parameters["warnings"]),
                    MessageType.WARNING,
                ),
            );
        }

        this.page = new GUIPage(this.context);
        this.page.addStyleClass(TimeRecordingController.BCS_COMPONENT_NAME);

        this.composeHeaderToolBar(this.page);

        const self = this;
        this.timeRecordingManager.getTimesheetEventFromDate(date).then((readedAppointments) => {
            this.appointments = <Appointment[]>readedAppointments;

            self.timeRecordingManager.getTimSpansFromDate(date).then((readedTimespans) => {
                this.timespans = readedTimespans;
                const promises = [];
                let bookingByID;
                this.timespans.forEach((timespan) => {
                    const booking_oid = timespan.getId();
                    promises.push(
                        this.timeRecordingManager
                            .getSyncStateForBooking(booking_oid)
                            .then((syncState: SyncState) => {
                                timespan.attachSyncState(syncState);
                            }),
                    );
                });

                Promise.all(promises).then((values) => {
                    self.timeRecordingManager
                        .getBookingsFromDate(date)
                        .then((bookings) => {
                            const promises = [];
                            let bookingByID;
                            bookings.forEach((booking) => {
                                const booking_oid = booking.getId();
                                promises.push(
                                    this.timeRecordingManager
                                        .getSyncStateForBooking(booking_oid)
                                        .then((syncState: SyncState) => {
                                            booking.attachSyncState(syncState);
                                        }),
                                );
                            });

                            Promise.all(promises).then((values) => {
                                const bookingsPerDay = new BookingsPerDay(bookings, this.timespans);
                                self.context.setBookingsPerDay(bookingsPerDay);

                                self.context.setBookingsTodayEntites(bookings);
                                self.bookingsFetched(bookings);
                            });
                        })
                        .catch((error) => {
                            AppConsole.log(error);

                            // Buchungen können nicht von BCS angefragt werden, dann wahrscheinlich offline
                            if (this.userSession.isOnline()) {
                                this.userSession.setToOfflineMode();
                                this.compose(parameters, animation, navigator);
                            }
                        });
                });
            });
        });

        this.page.composeMessagesContainer();

        this.page.addFixedElement(
            new FloatingActionButton()
                .setImageName("icon-add-white.svg")
                .onClick(this.showSubMenu.bind(this)),
        );

        await this.composeFooterTabBar();
        // Meldung(en) von vorheriger Seite oder nach Aktion anzeigen
        this.page.composeMessages(this.messages, new GUIContext(this.i18n, new GUIEventPool()));
        this.messages = [];
    }

    public composeMessages() {
        const messages: MessageEntity[] = this.messagePool.getMessages();
        this.page.composeMessages(messages, this.context);
    }

    public toolLinkNavigateToPage(targetComponentName: string): void {
        this.navigateToPage(null, { navigate: targetComponentName });
    }

    public showSubMenu(
        event: JQueryEventObject,
        offset: { left: number; top: number },
        submenuName: string,
    ): void {
        // damit SubMenu nicht gleich wieder geschlosssen wird.
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();

        let submenuNode = ConfigNode.removeDisabledNodes(
            TimeRecordingGUIDefinitions.TIMERECORDING_SUBMENU_CONFIG_NODE,
            this.serverConfigProperties.getPropertySet(TimeRecordingController.PROPERTY_SET_NAME),
        );
        submenuNode = this.rebuildSubMenu(submenuNode); // Hier wird das SubMenü basierend auf Lizenz, Bausteinen und aktivierter Answesenheitserfassung neu zusammengesetzt.
        const subMenu = new SubMenu(submenuNode, offset.left, offset.top, 50);

        const self: TimeRecordingController = this;
        const subMenuEntries = submenuNode.getChildren();
        for (let i = 0; i < subMenuEntries.length; i++) {
            const submenuItemConfigNode = subMenuEntries[i];
            const i18nLabel = submenuItemConfigNode.getAttribute(
                "I18nLabel",
                submenuItemConfigNode.getName(),
            );
            subMenu.addEntry(
                submenuItemConfigNode.getName(),
                function () {
                    const navigationPath = submenuItemConfigNode.getAttribute("NavigationPath");
                    const navigationContext: {} = { navigate: navigationPath };
                    const rawParameter: string = submenuItemConfigNode.getAttribute("Parameter");
                    const parameters: string[] = rawParameter.split(";");
                    // Übermittelt für das anlegen einer neuen Buchung/TimeSpan das Datum, an dem sie angelegt werden sollte
                    parameters.push("clickedDate=" + self.daySelector.getShownDate().getISODate());

                    const resultParameters: {} = {};
                    for (let i = 0; i < parameters.length; i++) {
                        const keyValue: string[] = parameters[i].split("=");
                        resultParameters[keyValue[0]] = keyValue[1];
                    }
                    navigationContext["parameters"] = resultParameters;
                    self.navigateToPage(null, navigationContext);
                },
                this.i18n.get(i18nLabel),
            );
        }
        subMenu.compose(this.page.getPageDOMElement());
    }

    public showDummySubMenu(
        event: JQueryEventObject,
        offset: { left: number; top: number },
        submenuName: string,
        choosenTimerecord: TimeRecord,
    ): void {
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();

        const topOffsetWithScroll = offset.top - $(window).scrollTop(); // scrolling berücksichtigen
        // TODO: nimmt hier die Dummy Node, unterscheidet sich von der anderen nur darin, dass es keine Attendance gibt. Man könnte auch einfach das erste Child der anderen Node löschen und sich hier noch eine GUIDefinition sparen
        const submenuNode: ConfigNode = ConfigNode.removeDisabledNodes(
            TimeRecordingGUIDefinitions.TIMERECORDING_SUBMENU_DUMMY_CONFIG_NODE,
            this.serverConfigProperties.getPropertySet(TimeRecordingController.PROPERTY_SET_NAME),
        );
        const subMenu = new SubMenu(submenuNode, offset.left, topOffsetWithScroll, 50);
        subMenu.setLowerBoundOffset(this.page.getFooterBarsSumHeight());

        const self: TimeRecordingController = this;
        const subMenuEntries: ConfigNode[] = submenuNode.getChildren();
        for (let i = 0; i < subMenuEntries.length; i++) {
            const submenuItemConfigNode = subMenuEntries[i];
            const i18nLabel = submenuItemConfigNode.getAttribute(
                "I18nLabel",
                submenuItemConfigNode.getName(),
            );
            subMenu.addEntry(
                submenuItemConfigNode.getName(),
                function () {
                    const newBookingType: string = submenuItemConfigNode.getName().toLowerCase();
                    const navigationPath = submenuItemConfigNode.getAttribute("NavigationPath");
                    const navigationContext: {} = { navigate: navigationPath };
                    const rawParameter: string = submenuItemConfigNode.getAttribute("Parameter");
                    const parameters: string[] = rawParameter.split(";");
                    // Übermittelt für das anlegen einer neuen Buchung/TimeSpan das Datum, an dem sie angelegt werden sollte
                    parameters.push("clickedDate=" + self.daySelector.getShownDate().getISODate());

                    const resultParameters: {} = {};
                    for (let i = 0; i < parameters.length; i++) {
                        const keyValue: string[] = parameters[i].split("=");
                        resultParameters[keyValue[0]] = keyValue[1];
                    }
                    navigationContext["parameters"] = resultParameters;

                    if (choosenTimerecord.hasDurationOnly()) {
                        if (newBookingType === "pause") {
                            self.timeRecordingManager
                                .createTimeSpanWithDuration(
                                    newBookingType,
                                    choosenTimerecord.getEffortExpense(),
                                    new BCSDate(choosenTimerecord.getDate()),
                                )
                                .then(function (timespan: TimeSpan) {
                                    navigationContext["parameters"].oid = timespan.getId();
                                    self.navigateToPage(null, navigationContext);
                                });
                        } else {
                            self.timeRecordingManager
                                .createBookingWithDuration(
                                    choosenTimerecord.getEffortExpense(),
                                    new BCSDate(choosenTimerecord.getDate()),
                                    newBookingType,
                                )
                                .then(function (booking: Booking) {
                                    navigationContext["parameters"].oid = booking.getId();
                                    self.navigateToPage(null, navigationContext);
                                });
                        }
                    } else {
                        if (newBookingType === "pause") {
                            self.timeRecordingManager
                                .createTimeSpanWithTime(
                                    newBookingType,
                                    choosenTimerecord.getEffortExpense(),
                                    new BCSDate(choosenTimerecord.getStartTime()),
                                    new BCSDate(choosenTimerecord.getStartTime()),
                                    new BCSDate(choosenTimerecord.getEndTime()),
                                )
                                .then(function (timespan: TimeSpan) {
                                    navigationContext["parameters"].oid = timespan.getId();
                                    self.navigateToPage(null, navigationContext);
                                });
                        } else {
                            self.timeRecordingManager
                                .createBookingWithTime(
                                    new BCSDate(choosenTimerecord.getStartTime()),
                                    new BCSDate(choosenTimerecord.getEndTime()),
                                    new BCSDate(choosenTimerecord.getStartTime()),
                                    newBookingType,
                                )
                                .then(function (booking: Booking) {
                                    navigationContext["parameters"].oid = booking.getId();
                                    self.navigateToPage(null, navigationContext);
                                });
                        }
                    }
                },
                this.i18n.get(i18nLabel),
            );
        }
        subMenu.compose(this.page.getPageDOMElement());
    }

    public popState(): void {
        this.navigator.navigateTo("index", {}, Animation.SLIDE_RIGHT);
    }

    public destroy(): void {
        // TODO ...
    }

    private composeHeaderToolBar(page: GUIPage): void {
        const headerToolBar = new ToolBar()
            .setId("header_toolbar")
            .addToolLinkLeft(
                new ToolLink()
                    .setImageName("icon-chevron-left.svg")
                    .onClick(this.popState.bind(this)),
            )
            .setTitle(this.i18n.get("MobileApp.dayeffortrecording"));
        page.addHeaderElement(headerToolBar);

        headerToolBar.addStyleClass("headBar");

        const self = this;
        /*headerToolBar.addToolLinkRight(new ToolLink()
            .setImageName("toolbar_edit")
            .onClick(function(event) {
                AppConsole.log("RightToolLink1 clicked");
            })
        );*/
    }

    private bookingsFetched(bookings: Booking[]) {
        this.bookings = bookings;
        new Promise<void>((resolve, reject) => {
            this.createTimesheetCollection()
                .then((timesheetCollection: Timesheet) => {
                    this.timeRecordingList = new TimeRecordingList(
                        this.bookings,
                        timesheetCollection,
                        this.timespans,
                        this.appointments,
                        this.context,
                        this.daySelector,
                        this.preferencesManager,
                    );
                    this.timeRecordingList.addClickBookingListener(this.bookingClicked.bind(this));
                    this.timeRecordingList.setBookingTaskNotAvaliableClickCallback(() => {
                        this.messagePool.addMessage(
                            new MessageEntity(
                                this.i18n.get("MobileApp.bookingDetailsNotAvaliableCause"),
                                MessageType.INFO,
                            ),
                        );
                        this.composeMessages();
                    });
                    this.page.addPageElement(this.timeRecordingList);
                    this.page
                        .setAnimation(this.animation, this.navigator.doShowAnimations())
                        .compose($("body"));
                    resolve();
                })
                .catch((error) => {
                    reject();
                    Log.error(
                        "[TimeRecordingController] Error while loading Timesheets from internal db.",
                        undefined,
                        error,
                    );
                });
        }).then(() => {
            // this.page.compose($("body"));
        });
    }

    private createTimesheetCollection(): Promise<Timesheet> {
        const ticketTimesheet = this.timeRecordingManager.getTimesheetTickets();
        const requirementsTimesheet = this.timeRecordingManager.getTimesheetRequirements();
        const workflowsTimesheet = this.timeRecordingManager.getTimesheetWorkflows();
        const taskTimesheet = this.timeRecordingManager.getTimesheetTasks(
            this.preferencesManager.postFilterTaskList(),
        );
        const appointmentTimesheet = this.timeRecordingManager.getTimesheetEventFromDate(
            this.daySelector.getShownDate(),
        );

        return Promise.all([
            ticketTimesheet,
            requirementsTimesheet,
            workflowsTimesheet,
            taskTimesheet,
            appointmentTimesheet,
        ]).then(([ticket, requirement, workflow, task, appointment]) => {
            const timesheet = new Timesheet();
            timesheet.setTasks(task);
            timesheet.setTickets(ticket);
            timesheet.setRequirements(requirement);
            timesheet.setWorkflows(workflow);
            timesheet.setAppointments(appointment);
            return timesheet;
        });
    }

    private onChangeDay() {
        const self: TimeRecordingController = this;
        self.navigateTAnotherDay(self.daySelector.getShownDate());
    }

    private bookingClicked(clickContext: {
        parameters: {
            oid?: string;
            isDummy?: boolean;
            booking?: TimeRecord;
            event?: JQueryEventObject;
        };
    }): void {
        const isDummy: boolean = clickContext.parameters.isDummy;
        const event: JQueryEventObject = clickContext.parameters.event;
        const oid: string = clickContext.parameters.oid;
        const choosenTimerecord: TimeRecord = clickContext.parameters.booking;

        // Bereinigen die Parameter die in die URL gepackt werden, von unnötigen Objecten(welche ohnehin nicht serialisierbar sind).
        clickContext.parameters = {};
        clickContext.parameters.oid = oid;

        const self = this;
        // Falls es sich um eine Dummy Buchung handelt, so muss diese erst angelegt und mit den entsprechenden Start und Endzeiten gefüllt werden.
        if (isDummy) {
            const isAppointment = choosenTimerecord.isAppointment();
            const dummyBooking: DummyBooking = <DummyBooking>choosenTimerecord;
            const appointment = dummyBooking.getAppointment();
            if (typeof appointment !== "undefined" && appointment !== null) {
                clickContext.parameters["effortEventRefOid"] = appointment.getId();
                clickContext.parameters["new_timerecord"] = "appointment";
                self.navigateToPage(null, clickContext);
            } else {
                this.showDummySubMenu(
                    event,
                    $(event.target).offset(),
                    "addTimeRecordViaDummyBooking",
                    choosenTimerecord,
                );
            }
        } else {
            self.navigateToPage(null, clickContext);
        }
    }

    private navigateTAnotherDay(newShownDate: BCSDate): void {
        this.navigator.navigateTo(
            TimeRecordingController.BCS_COMPONENT_NAME,
            { clickedDate: newShownDate.getISODate() },
            Animation.NONE,
        );
    }

    private navigateToPage(clickEvent: Event, clickContext: any): void {
        if (clickContext.navigate == TimeRecordingDetailController.BCS_COMPONENT_NAME) {
            clickContext.parameters["clickedDate"] = this.daySelector.getShownDate().getISODate();
            this.navigator.pushPage(
                { clickedDate: this.daySelector.getShownDate().getISODate() },
                TimeRecordingController.BCS_COMPONENT_NAME,
            );
        }
        this.navigator.navigateTo(clickContext.navigate, clickContext.parameters, Animation.NONE);
    }

    private navigateToPageByBar(clickEvent: Event, clickContext: FooterTabIcon): void {
        this.navigateToPage(clickEvent, { navigate: clickContext.iconName });
    }

    private removeFromMessagePool(event, transferObject: object) {
        if (transferObject.hasOwnProperty("messageEntity")) {
            this.messagePool.removeMessage(transferObject["messageEntity"]);
        } else {
            Log.debug(
                "[TimeRecordingController] Error while removing message in GUI.:" +
                    JSON.stringify(transferObject) +
                    " event: " +
                    JSON.stringify(event),
            );
        }
    }

    private openSubMenu(event, transferObject: object) {
        const callback = (action: string, navigationContext?: {}) => {
            if (action === "logout") {
                this.userSession.logout();
                this.navigateToPage(null, navigationContext);
            } else if (action === "navigate") {
                this.navigateToPage(null, navigationContext);
            }
        };

        this.page.openSubMenu(event, transferObject, this.i18n, callback);
    }

    /**
     * Hier wird das SubMenü (Plus Icon) basierend auf Lizenz, Bausteinen und aktivierter Answesenheitserfassung neu zusammengesetzt.
     * @param submenuNode das Submenü wird reingereicht (als ConfigNode)
     * @returns die neue ConfigNode mit den gefilterten Elementen
     */
    private rebuildSubMenu(submenuNode: ConfigNode): ConfigNode {
        const allChildren: ConfigNode[] = submenuNode.getChildren();
        allChildren.forEach((node, index) => {
            const nodeName: string = node.getName();
            const licenses: string = this.timeRecordingManager
                .getRecordingOptions()
                .getUserLicenses();
            switch (nodeName) {
                case TimeRecordingController.ATTENDANCE: {
                    if (!this.preferencesManager.getEffortRecordingWithAttendances()) {
                        submenuNode = submenuNode.removeChildByNameAndCopy(nodeName);
                    }
                    break;
                }
                case TimeRecordingController.PAUSE: {
                    if (!this.preferencesManager.getEffortRecordingWithAttendances()) {
                        submenuNode = submenuNode.removeChildByNameAndCopy(nodeName);
                    }

                    break;
                }
                case TimeRecordingController.TASK: {
                    if (licenses.includes("License_AttendanceRecording")) {
                        submenuNode = submenuNode.removeChildByNameAndCopy(nodeName);
                    }
                    break;
                }
                case TimeRecordingController.TICKET: {
                    if (
                        !this.timeRecordingManager.getRecordingOptions().isTicketRecordingAllowed()
                    ) {
                        submenuNode = submenuNode.removeChildByNameAndCopy(nodeName);
                    } else if (
                        licenses.includes("License_AttendanceRecording") ||
                        licenses.includes("License_TimeRecording1")
                    ) {
                        submenuNode = submenuNode.removeChildByNameAndCopy(nodeName);
                    }
                    break;
                }
                case TimeRecordingController.REQUIREMENT: {
                    if (
                        !this.timeRecordingManager
                            .getRecordingOptions()
                            .isRequirementRecordingAllowed()
                    ) {
                        submenuNode = submenuNode.removeChildByNameAndCopy(nodeName);
                    } else if (
                        licenses.includes("License_AttendanceRecording") ||
                        licenses.includes("License_TimeRecording1") ||
                        licenses.includes("License_TimeRecording2")
                    ) {
                        submenuNode = submenuNode.removeChildByNameAndCopy(nodeName);
                    }
                    break;
                }
                case TimeRecordingController.WORKFLOW: {
                    if (
                        !this.timeRecordingManager
                            .getRecordingOptions()
                            .isWorkflowRecordingAllowed()
                    ) {
                        submenuNode = submenuNode.removeChildByNameAndCopy(nodeName);
                    } else if (
                        licenses.includes("License_AttendanceRecording") ||
                        licenses.includes("License_TimeRecording1") ||
                        licenses.includes("License_TimeRecording2")
                    ) {
                        submenuNode = submenuNode.removeChildByNameAndCopy(nodeName);
                    }
                    break;
                }
                default: {
                    //sollte nicht passieren, aber sicher ist sicher
                    Log.error("Unexpected Option in TimeRecordingController SubMenu: " + nodeName);
                    break;
                }
            }
        });
        return submenuNode;
    }

    private async composeFooterTabBar(): Promise<void> {
        const self = this;
        this.footerTabBar = new FooterTabBar(new GUIContext(this.i18n));
        await this.footerTabBar.composeDefaultFooter(
            null,
            this.navigator,
            this.getNavigateBackParameters(),
            () => self.syncStateManager.countUnsyncedElements(),
        );
        this.page.addFooterElement(this.footerTabBar);
    }

    private getNavigateBackParameters(): { [key: string]: string } {
        return {
            navigateBackPath: TimeRecordingController.BCS_COMPONENT_NAME,
            navigateBackParameters: JSON.stringify({
                clickedDate: this.daySelector.getShownDate().getISODate(),
            }),
        };
    }

    private addMessage(messageEntity: MessageEntity): void {
        this.messages.push(messageEntity);
    }
}

Registry.registerComponent(TimeRecordingController.BCS_COMPONENT_NAME, TimeRecordingController);
