import { FormField } from "./FormField";
import { StringField } from "./StringField";
import { SchemaDataType } from "../../common/schema/SchemaDataType";
import { TextField } from "./TextField";
import { PasswordField } from "./PasswordField";
import { NumberField } from "./NumberField";
import { DateTimeField } from "./DateTimeField";
import { DateField } from "./DateField";
import { TimeField } from "./TimeField";
import { BooleanField } from "./BooleanField";
import { OptionField } from "./OptionField";
import { AttributeDefinition } from "../../common/schema/AttributeDefinition";
import { I18n } from "../../common/i18n/I18n";
import { DurationField } from "./DurationField";
import { CurrencyField } from "./CurrencyField";
import { FormFieldAddOn } from "./FormFieldAddOn";
import { OidField } from "./OidField";
import { HTMLField } from "./HTMLField";
import { LongDurationField } from "./LongDurationField";
import { InputModes } from "./InputModes";

export class FormFieldFactory {
    private formFieldAddOns: FormFieldAddOn[] = [];

    private formFieldCallback: (formField: FormField) => any;

    public registerFormFieldAddOn(formFieldAddOn: FormFieldAddOn): FormFieldFactory {
        this.formFieldAddOns.push(formFieldAddOn);
        return this;
    }

    public registerFormFieldAddOns(formFieldAddOns: FormFieldAddOn[]): FormFieldFactory {
        this.formFieldAddOns = this.formFieldAddOns.concat(formFieldAddOns);
        return this;
    }

    public onCreateFormField(
        registerFormFieldCallback: (formField: FormField) => any,
    ): FormFieldFactory {
        this.formFieldCallback = registerFormFieldCallback;
        return this;
    }

    public createFormField(
        dataType: SchemaDataType,
        attributeDefinition?: AttributeDefinition,
        i18n?: I18n,
        additionalParameters?,
    ): FormField {
        let formField = null;

        let attributeLabel = null;
        if (attributeDefinition) {
            attributeLabel = i18n.getAttribute(
                attributeDefinition.getType(),
                attributeDefinition.getSubtype(),
                attributeDefinition.getAttribute(),
            );
        }
        switch (dataType) {
            case SchemaDataType.STRING:
                formField = new StringField();
                if (attributeDefinition) {
                    if (attributeDefinition.getAttribute().endsWith("name")) {
                        formField
                            .setSpellCheck(false)
                            .setMaxLength(attributeDefinition.getMaxLength());
                    } else if (attributeDefinition.getAttribute() === "ouAreacode") {
                        formField
                            .setInputmode(InputModes.INT)
                            .setMaxLength(attributeDefinition.getMaxLength());
                    } else {
                        formField.setMaxLength(attributeDefinition.getMaxLength());
                    }
                }
                break;
            case SchemaDataType.OPTION:
                const optionField = new OptionField();

                if (attributeDefinition) {
                    optionField.setOptions(
                        attributeDefinition.getOptions().map((v) => {
                            return {
                                value: v,
                                label: i18n
                                    ? i18n.getAttributeOption(
                                          attributeDefinition.getType(),
                                          attributeDefinition.getSubtype(),
                                          attributeDefinition.getAttribute(),
                                          v,
                                      )
                                    : v,
                            };
                        }),
                    );

                    optionField.showUnselectedOption(
                        true,
                        attributeDefinition.isRequired(),
                        i18n.format("MobileApp.OptionField.chose", [attributeLabel]),
                        "*",
                    );
                }

                formField = optionField;
                break;
            case SchemaDataType.CLASSIFIER:
                const classifierField = new OptionField();

                if (attributeDefinition) {
                    classifierField.setOptions(
                        attributeDefinition.getClassifier().map((classifier) => {
                            return {
                                value: classifier["id"],
                                label: classifier["displayName"],
                            };
                        }),
                    );
                    // prüfe auf require oder nicht
                    classifierField.showUnselectedOption(
                        true,
                        false,
                        i18n.format("MobileApp.OptionField.chose", [attributeLabel]),
                        "*",
                    );
                }

                formField = classifierField;
                break;
            case SchemaDataType.TEXT:
                formField = new TextField().setMaxLength(attributeDefinition.getMaxLength());
                break;
            case SchemaDataType.HTML:
                formField = new HTMLField();
                break;
            case SchemaDataType.LSTRING:
                formField = new TextField();
                break;
            case SchemaDataType.PWD:
                formField = new PasswordField();
                break;
            case SchemaDataType.INT:
                formField = new NumberField(i18n).setInputmode(InputModes.INT);
                break;
            case SchemaDataType.LONG:
                formField = new NumberField(i18n).setInputmode(InputModes.FLOAT);
                break;
            case SchemaDataType.FLOAT:
                formField = new NumberField(i18n).setInputmode(InputModes.FLOAT);
                break;
            case SchemaDataType.NUMBER:
                formField = new NumberField(i18n).setInputmode(InputModes.INT);
                break;
            case SchemaDataType.DURATION:
                if (LongDurationField.isLongDurationFieldRequested(additionalParameters)) {
                    formField = new LongDurationField(i18n);
                } else {
                    formField = new DurationField(i18n);
                }
                break;
            case SchemaDataType.CURRENCY:
                formField = new CurrencyField(i18n).setInputmode(InputModes.FLOAT);
                break;
            case SchemaDataType.DATETIME:
                formField = new DateTimeField(i18n);
                break;
            case SchemaDataType.DATE:
                formField = new DateField(i18n);
                break;
            case SchemaDataType.TIME:
                formField = new TimeField();
                break;
            case SchemaDataType.BOOL:
                const booleanField = new BooleanField();
                booleanField.setLabel(attributeLabel);
                formField = booleanField;
                break;
            case SchemaDataType.OID:
                formField = new OidField();
                break;
            case SchemaDataType.MAIL:
                formField = new StringField()
                    .setInputmode(InputModes.EMAIL)
                    .setMaxLength(attributeDefinition.getMaxLength());
                break;
            case SchemaDataType.PHONE:
                // RFR: Leider ist die MaxLength für Telefonnummern undefiniert. Da es dieses Feld aber nur in der Kontakterfassung gibt, beschränke ich es selbst auf 100 Zeichen (die max lenght in bcs beträgt 120). Nur zur Sicherheit, da ich nicht davon ausgehe, dass jemals jemand eine so lange Telefonnummer eingibt
                formField = new StringField().setInputmode(InputModes.TEL).setMaxLength(100);
                break;
            case SchemaDataType.HANDY:
                // RFR: Leider ist die MaxLength für Handynummern undefiniert. Da es dieses Feld aber nur in der Kontakterfassung gibt, beschränke ich es selbst auf 100 Zeichen (die max lenght in bcs beträgt 120). Nur zur Sicherheit, da ich nicht davon ausgehe, dass jemals jemand eine so lange Telefonnummer eingibt
                formField = new StringField().setInputmode(InputModes.TEL).setMaxLength(100);
                break;
            case SchemaDataType.URL:
                formField = new StringField()
                    .setInputmode(InputModes.URL)
                    .setMaxLength(attributeDefinition.getMaxLength());
                break;

            /** Diese Werte gibt es auch noch als Schema DataType, dafür ist in der App bisher aber kein formField nötig
            case SchemaDataType.PHOTO:
                break;
            case SchemaDataType.MULTISTRING:
                break;
            case SchemaDataType.DOUBLE:
                break;
*/

            default:
                throw new Error(
                    "[FormFieldFactory] Unknown form field for EntityAttribute: dataType=" +
                        dataType,
                );
        }

        if (attributeDefinition) {
            formField.setName(attributeDefinition.getAttribute());
        }

        if (attributeLabel) {
            formField.setPlaceholder(attributeLabel);
        }

        this.formFieldAddOns
            .filter((formFieldAddOn) => formFieldAddOn.isApplicableFor(formField))
            .forEach((formFieldAddOn) => formField.registerFormFieldAddOn(formFieldAddOn));

        if (this.formFieldCallback) {
            this.formFieldCallback(formField);
        }

        switch (dataType) {
            case SchemaDataType.OID:
                if (attributeDefinition) {
                    // OidFields ohne Wert zeigen speziellen Text (z.B. "Aufgabe auswählen ...")
                    formField.setPlaceholder(
                        i18n.get(
                            "MobileApp.OidField.emptyValueName." +
                                attributeDefinition.getAttribute(),
                        ),
                    );
                }
                break;
            case SchemaDataType.OPTION:
                if (attributeDefinition) {
                    // OptionFields ohne Wert zeigen optional speziellen Text (z.B. "[Option] auswählen ...")
                    const emptyValueName = i18n.get(
                        "MobileApp.OptionField.emptyValueName." +
                            attributeDefinition.getAttribute(),
                        "",
                    );
                    if (emptyValueName != null && emptyValueName.length > 0) {
                        (<OptionField>formField).setEmptyValueName(emptyValueName);
                    }
                }
                break;

            default:
            // leer
        }

        return formField;
    }
}
