import { GUIElement } from "../GUIElement";
import { NavigationLink } from "./NavigationLink";
import { Image } from "../content/Image";
import { IconBadge } from "./IconBadge";

export class ToolLink implements NavigationLink {
    private imageName: string = null;

    private isSVG: boolean = false;

    private text: string = null;

    private textBadge: string = null;

    private styleClass: string = null;

    private id: string = null;

    private clickCallback: Function; // oder: () => void

    private guiElements: GUIElement[] = [];

    private iconBadge: IconBadge;

    private $textTag: JQuery;

    public setId(id: string): ToolLink {
        this.id = id;
        return this;
    }

    public setImageName(imageName: string, isSVG: boolean = false): ToolLink {
        this.imageName = imageName;
        this.isSVG = isSVG;
        return this;
    }

    public setText(text: string): ToolLink {
        this.text = text;

        if (this.$textTag) {
            this.$textTag.text(text);
        }

        return this;
    }

    public setBadgeText(textBadge: string): ToolLink {
        this.textBadge = textBadge;
        return this;
    }

    public setStyleClass(styleClass: string): ToolLink {
        this.styleClass = styleClass;
        return this;
    }

    public onClick(clickCallback: Function): ToolLink {
        this.clickCallback = clickCallback;
        return this;
    }

    /**
     * HTML-DOM generieren
     *
     * @param $parent Parent-jQuery-Objekt
     */
    public compose($parent: JQuery): void {
        const $toolLinkTag = $("<a>")
            .appendTo($parent)
            .addClass("toollink " + this.styleClass)
            .css("cursor", "pointer")
            .on("click", this.notifyClicked.bind(this));

        if (this.id) {
            $toolLinkTag.attr("id", this.id);
        }

        if (this.imageName) {
            new Image().setImageName(this.imageName).setIsSVG(this.isSVG).compose($toolLinkTag);
        }

        if (this.textBadge || this.textBadge === "") {
            this.iconBadge = new IconBadge("sync_badge", this.textBadge);
            this.iconBadge.compose($toolLinkTag);
        }

        if (this.text) {
            this.$textTag = $("<span>").appendTo($toolLinkTag).text(this.text);
        }

        for (let i = 0; i < this.guiElements.length; i++) {
            this.guiElements[i].compose($toolLinkTag);
        }
    }

    public addElement(guiElement: GUIElement) {
        this.guiElements.push(guiElement);
    }

    public notifyClicked(event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        if (this.clickCallback) {
            this.clickCallback(event);
        }
    }

    public updateIconBadge(textBadge: string): void {
        if (this.iconBadge) {
            this.iconBadge.updateBadgeText(textBadge);
        }
    }

    public getComponentChildren(): GUIElement[] {
        return this.guiElements;
    }
}
