import { FooterTabBar } from "../../../../board/FooterTabBar";
import { ServerConfigProperties } from "../../../../common/config/ServerConfigProperties";
import { I18n } from "../../../../common/i18n/I18n";
import { MessageEntity, MessageType } from "../../../../core/Message/MessageEntity";
import { GUIEventPool } from "../../../../gui/event/GUIEventPool";
import { formChangeEventType } from "../../../../gui/form/Form";
import { GUIContext } from "../../../../gui/GUIContext";
import { GUIPage } from "../../../../gui/GUIPage";
import { ListFieldFeedback, ListFieldFeedbackType } from "../../../../gui/list/ListFieldFeedback";
import { ListView } from "../../../../gui/list/ListView";
import { ListViewContext } from "../../../../gui/list/ListViewContext";
import { SyncStateManager } from "../../../../sync/SyncStateManager";
import { AllowanceManager } from "../../AllowanceManager";
import { BusinessTravel } from "../../records/BusinessTravel";
import { VoucherAllowance } from "../../records/VoucherAllowance";
import { AllowanceGUIDefinitions } from "../AllowanceGUIDefinitions";
import { AllowancesListViewModel } from "../AllowancesListViewModel";

export class VoucherFormController {
    private allowanceManager: AllowanceManager;

    private syncStateManager: SyncStateManager;

    private serverConfigProperties: ServerConfigProperties;

    private i18n: I18n;

    private page: GUIPage;

    private businessTravel: BusinessTravel;

    private voucherAllowance: VoucherAllowance;

    /**
     * Gibt an, ob die Einzelbelegspese mit dem Aufruf der aktuellen Seite erstellt wurde
     * Sofern sie nicht mindestens einmal gespeichert wird, wird sie bei Zurück-Navigation wieder gelöscht.
     */
    private voucherAllowanceCreatedAndNotEdited = false;

    private allowanceGUIDefinitions: AllowanceGUIDefinitions;

    private voucherAllowanceListView: ListView;

    private footerTabBar: FooterTabBar;

    private allowanceWasEdited = false;

    private navigateToTaskSelectionPageCallback: (
        path: string,
        parameters: { [key: string]: string },
    ) => void;

    constructor(
        allowanceManager: AllowanceManager,
        syncStateManager: SyncStateManager,
        serverConfigProperties: ServerConfigProperties,
        i18n: I18n,
    ) {
        this.allowanceManager = allowanceManager;
        this.syncStateManager = syncStateManager;
        this.serverConfigProperties = serverConfigProperties;
        this.i18n = i18n;

        this.allowanceGUIDefinitions = new AllowanceGUIDefinitions();
    }

    public setVoucherAllowanceCreatedAndNotEdited(
        voucherAllowanceCreatedAndNotEdited: boolean,
    ): void {
        this.voucherAllowanceCreatedAndNotEdited = voucherAllowanceCreatedAndNotEdited;
    }

    public isVoucherAllowanceCreatedAndNotEdited(): boolean {
        return this.voucherAllowanceCreatedAndNotEdited;
    }

    public onNavigateToTaskSelectionPage(
        navigateToTaskSelectionPageCallback: (
            path: string,
            parameters: { [key: string]: string },
        ) => void,
    ): void {
        this.navigateToTaskSelectionPageCallback = navigateToTaskSelectionPageCallback;
    }

    public compose(
        businessTravel: BusinessTravel,
        voucherAllowance: VoucherAllowance,
        page: GUIPage,
    ): void {
        this.businessTravel = businessTravel;
        this.voucherAllowance = voucherAllowance;
        this.page = page;

        const voucherListViewDef = this.serverConfigProperties.customizeGUIDefinitions(
            this.allowanceGUIDefinitions.getVoucherListViewDef(),
            "voucher",
            "voucherallowance",
        );

        const listViewContext = new ListViewContext()
            .setI18n(this.i18n)
            .setModel(new AllowancesListViewModel([this.voucherAllowance], this.i18n));
        this.voucherAllowanceListView = new ListView(listViewContext, voucherListViewDef)
            .setDOMId("voucher")
            .addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW)
            .onFormFieldChange(this.formFieldChanged.bind(this))
            .onRowClicked(this.listRowClicked.bind(this));

        this.page.addPageElement(this.voucherAllowanceListView);
    }

    public setFooterTabBar(footerTabBar: FooterTabBar): VoucherFormController {
        this.footerTabBar = footerTabBar;
        return this;
    }

    private async formFieldChanged(changeEvent: formChangeEventType): Promise<void> {
        this.updateFormFields(changeEvent);

        const saveAllowance = this.businessTravel ? this.businessTravel : this.voucherAllowance;

        await this.allowanceManager.storeAllowance(saveAllowance);

        this.voucherAllowanceCreatedAndNotEdited = false;

        // Nach erster Formularfeldänderung, Zähler der zu synchronisierenden Objekt in Footer-Navigation aktualisieren
        if (!this.allowanceWasEdited) {
            this.allowanceWasEdited = true;

            const self = this;
            await this.footerTabBar.updateSyncBadgeCounter(FooterTabBar.ICON_SYNC, () =>
                self.syncStateManager.countUnsyncedElements(),
            );
        }

        // Gab es Warnungen beim Änderen von Attributen?
        const changeWarningKey = saveAllowance.getAndClearChangeWarningKey();
        if (changeWarningKey) {
            // Falls es Warnungen beim Änderen von Attributen gab, nicht Speichern und erste Warnung anzeigen.
            this.showInputWarningMessage(changeWarningKey);
        }
    }

    private updateFormFields(changeEvent: formChangeEventType): void {
        // Alle Formularfelder aktualisieren
        const listFieldFeedback = new ListFieldFeedback();
        listFieldFeedback.addFeedback(
            ListFieldFeedbackType.SUCCESS,
            changeEvent.entityId,
            changeEvent.fieldName,
        );
        this.voucherAllowanceListView.updateFields(listFieldFeedback);
    }

    private showInputWarningMessage(changeErrorKey: string): void {
        const messages = [
            new MessageEntity(
                this.i18n.get("MobileApp.voucher.inputerror." + changeErrorKey),
                MessageType.WARNING,
            ),
        ];
        this.page.composeMessages(messages, new GUIContext(this.i18n, new GUIEventPool()));
    }

    private listRowClicked(path: string, parameters: { [key: string]: string }): void {
        let doNavigateLink = true;

        if (path.endsWith("selection")) {
            // Aufgaben-Auswahl-Seite nur öffnen, wenn Beleg noch bearbeitbar
            doNavigateLink = this.businessTravel
                ? this.businessTravel.isEditable()
                : this.voucherAllowance.isEditable();
        }

        if (doNavigateLink) {
            this.navigateToTaskSelectionPageCallback(path, parameters);
        }
    }
}
