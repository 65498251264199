import { Entity } from "../../../entities/Entity";
import { DateValue } from "../../../entities/values/DateValue";
import { BooleanValue } from "../../../entities/values/BooleanValue";
import { TypeSubtypeDefinition } from "../../../common/schema/TypeSubtypeDefinition";
import { IdGenerator } from "../../../util/text/IdGenerator";
import { TravelSection } from "./TravelSection";

export class TravelDay {
    private static readonly TYPE = "JAllowanceTravelDay";

    private static readonly SUBTYPE = "dailyAllowanceTravelDay";

    private static readonly ALLOWANCE_OID = "allowanceOid";

    private static readonly TRAVEL_DAY = "travelDay";

    private static readonly HAS_BREAKFAST = "hasBreakfast";

    private static readonly HAS_LUNCH = "hasLunch";

    private static readonly HAS_DINNER = "hasDinner";

    private static readonly HAS_EMPLOYER_BREAKFAST = "hasEmployerBreakfast";

    private static readonly HAS_EMPLOYER_LUNCH = "hasEmployerLunch";

    private static readonly HAS_EMPLOYER_DINNER = "hasEmployerDinner";

    // TODO App - Arbeitgeberbewirtungen?
    private static MEAL_ATTRIBUTES = [
        TravelDay.HAS_BREAKFAST,
        TravelDay.HAS_LUNCH,
        TravelDay.HAS_DINNER,
        //TravelDay.HAS_EMPLOYER_BREAKFAST, TravelDay.HAS_EMPLOYER_LUNCH, TravelDay.HAS_EMPLOYER_DINNER
    ];

    private travelDayEntity: Entity;

    private travelSection: TravelSection;

    public static create(
        travelSection: TravelSection,
        travelDayTypeSubtypeDefinition: TypeSubtypeDefinition,
        dailyAllowanceId: string,
        date: DateValue,
    ): TravelDay {
        const entity = new Entity(travelDayTypeSubtypeDefinition, {
            oid: IdGenerator.createId() + "_" + TravelDay.TYPE,
            typ: TravelDay.TYPE,
            subtyp: TravelDay.SUBTYPE,
        });
        entity.setOid(TravelDay.ALLOWANCE_OID, dailyAllowanceId);
        entity.setValue(TravelDay.TRAVEL_DAY, date);
        return new TravelDay(travelSection, entity);
    }

    constructor(travelSection: TravelSection, travelDayEntity: Entity) {
        this.travelDayEntity = travelDayEntity;
        this.travelSection = travelSection;
    }

    public getTravelDate(): DateValue {
        return <DateValue>this.travelDayEntity.getValue(TravelDay.TRAVEL_DAY);
    }

    public getMealNamesAndValues(): { name: string; value: boolean }[] {
        const values: { name: string; value: boolean }[] = [];

        TravelDay.MEAL_ATTRIBUTES.forEach((name) => {
            const value = this.getValue(name);
            values.push({ name: name, value: value });
        });

        return values;
    }

    public getValue(name: string): boolean {
        return this.travelDayEntity.getBoolean(name);
    }

    public setValue(name: string, value: boolean): void {
        this.travelDayEntity.setValue(name, new BooleanValue(value));
    }

    public toValueObject(): object {
        return this.travelDayEntity.toValueObject();
    }
}
