import { Entity } from "../../../entities/Entity";
import { IdGenerator } from "../../../util/text/IdGenerator";
import { Allowance } from "./Allowance";
import { EntityValue } from "../../../entities/values/EntityValue";
import { TypeSubtypeDefinition } from "../../../common/schema/TypeSubtypeDefinition";
import { DateTimeValue } from "../../../entities/values/DateTimeValue";
import { AttributeDefinition } from "../../../common/schema/AttributeDefinition";
import { TravelSection } from "./TravelSection";
import { TravelAllowance } from "./TravelAllowance";
import { OidValue } from "../../../entities/values/OidValue";
import { StringValue } from "../../../entities/values/StringValue";
import { AllowanceRecordingTerms } from "../AllowanceRecordingTerms";

export class DailyAllowance implements Allowance, TravelAllowance {
    public static readonly TYPE = "JAllowance";

    public static readonly SUBTYPE = "dailyAllowance";

    public static readonly USER_OID = "allowanceUserOid";

    public static readonly PSP_OID = "allowancePspOid";

    public static readonly BUSINESS_TRAVEL_OID = "allowanceTravelOid";

    public static readonly TRAVEL_DESTINATION = "allowanceTravelDestination";

    public static readonly START_DATE_TIME = "allowanceStartTime";

    public static readonly END_DATE_TIME = "allowanceEndTime";

    public static readonly CHARGEABILITY = "allowanceChargeability";

    public static readonly TRAVEL_COUNTRY = "allowanceTravelCountry";

    public static readonly TRAVEL_ROUTE = "allowanceTravelRoute";

    public static readonly DESCRIPTION = "description";

    /** Spesenerfassungsmodalitäten (Spesenaufgaben, Abrechenbarkeiten, Belegarten, ...) */
    private allowanceRecordingTerms: AllowanceRecordingTerms;

    /** Reiseabschnitt zu dem diese Tagegeldspese gehört */
    private travelSection: TravelSection;

    private allowanceEntity: Entity;

    public static create(
        travelSection: TravelSection,
        allowanceRecordingTerms: AllowanceRecordingTerms,
        typeSubtypeDefinition: TypeSubtypeDefinition,
        userOid: string,
        businessTravelOid: string,
    ): DailyAllowance {
        const allowanceValueObject = {
            oid: IdGenerator.createId() + "_" + DailyAllowance.TYPE,
            typ: DailyAllowance.TYPE,
            subtyp: DailyAllowance.SUBTYPE,
        };

        const now = DateTimeValue.roundedDownNow(5);

        allowanceValueObject[DailyAllowance.USER_OID] = userOid;
        allowanceValueObject[DailyAllowance.BUSINESS_TRAVEL_OID] = businessTravelOid;
        allowanceValueObject[DailyAllowance.START_DATE_TIME] = now.getISODate();
        allowanceValueObject[DailyAllowance.END_DATE_TIME] = now.getISODate();

        // Reiseland mit Arbeitsland der Benutzes vorbelegen
        allowanceValueObject[DailyAllowance.TRAVEL_COUNTRY] =
            allowanceRecordingTerms.getWorkCountry();

        const dailyAllowance = new DailyAllowance(
            travelSection,
            allowanceRecordingTerms,
            typeSubtypeDefinition,
            allowanceValueObject,
            true,
        );

        return dailyAllowance;
    }

    constructor(
        travelSection: TravelSection,
        allowanceRecordingTerms: AllowanceRecordingTerms,
        typeSubtypeDefinition: TypeSubtypeDefinition,
        allowanceValueObject: object,
        isNew = false,
    ) {
        this.allowanceRecordingTerms = allowanceRecordingTerms;
        this.travelSection = travelSection;
        this.allowanceEntity = new Entity(typeSubtypeDefinition, allowanceValueObject, isNew);

        // Aufgabennamen aus Spesendatenobjekt übernehmen (als Fallback, falls Aufgabe nicht (mehr) in Spesenaufgabenliste enthalten).
        if (allowanceValueObject.hasOwnProperty(DailyAllowance.PSP_OID + ".name")) {
            this.setPSPName(allowanceValueObject[DailyAllowance.PSP_OID + ".name"]);
        }
    }

    public getId(): string {
        return this.allowanceEntity.getId();
    }

    public getSubtype(): string {
        return DailyAllowance.SUBTYPE;
    }

    public getUserOid(): string {
        return this.allowanceEntity.getString(DailyAllowance.USER_OID);
    }

    public getBusinessTravelOid(): string {
        return this.allowanceEntity.getString(DailyAllowance.BUSINESS_TRAVEL_OID);
    }

    public getPSPOid(): string {
        return this.allowanceEntity.getId(DailyAllowance.PSP_OID);
    }

    public setPSPName(pspName: string): void {
        const pspOidValue = OidValue.fromOidAndName(this.getPSPOid(), pspName);
        this.allowanceEntity.setValue(DailyAllowance.PSP_OID, pspOidValue);
    }

    public getTravelDestination(): string {
        return this.allowanceEntity.getString(DailyAllowance.TRAVEL_DESTINATION);
    }

    public getStartDateTime(): DateTimeValue {
        return <DateTimeValue>this.allowanceEntity.getValue(DailyAllowance.START_DATE_TIME);
    }

    public getEndDateTime(): DateTimeValue {
        return <DateTimeValue>this.allowanceEntity.getValue(DailyAllowance.END_DATE_TIME);
    }

    public setStartDateTime(startDateTime: DateTimeValue): void {
        this.allowanceEntity.setValue(DailyAllowance.START_DATE_TIME, startDateTime);
    }

    public setEndDateTime(endDateTime: DateTimeValue): void {
        this.allowanceEntity.setValue(DailyAllowance.END_DATE_TIME, endDateTime);
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return this.allowanceEntity.getTypeSubtypeDefinition().getAttributeDefinition(name);
    }

    public getValue(name: string): EntityValue {
        return this.allowanceEntity.getValue(name);
    }

    public setValue(name: string, value: EntityValue): void {
        this.allowanceEntity.setValue(name, value);

        switch (name) {
            case DailyAllowance.START_DATE_TIME:
                // Sofern jetzt Start nach Ende liegt: Ende auf Start setzen
                if (this.getStartDateTime().after(this.getEndDateTime())) {
                    this.setEndDateTime(this.getStartDateTime());
                }

                // Ggf. wird Start/Ende aller vorherigen und nachfolgenden Reiseabschnitte sowie der Dienstreise angepasst.
                this.travelSection.reArrangeTravelSectionStartEnd();
                break;
            case DailyAllowance.END_DATE_TIME:
                // Sofern jetzt Ende vor Start liegt: Start auf Ende setzen
                if (this.getEndDateTime().before(this.getStartDateTime())) {
                    this.setStartDateTime(this.getEndDateTime());
                }

                // Ggf. wird Start/Ende aller vorherigen und nachfolgenden Reiseabschnitte sowie der Dienstreise angepasst.
                this.travelSection.reArrangeTravelSectionStartEnd();
                break;
            case DailyAllowance.PSP_OID:
                // Nach Änderung der Spesen-Aufgabe: Abrechenbarkeit der Aufgabe vorbelegen
                const allowancePSP = this.allowanceRecordingTerms.getAllowancePSP(
                    value.getString(),
                );
                const defaultChargeability = allowancePSP
                    ? allowancePSP.getDefaultChargeability(DailyAllowance.SUBTYPE)
                    : null;
                this.allowanceEntity.setString(DailyAllowance.CHARGEABILITY, defaultChargeability);
                break;
            default:
            // nichts
        }

        // Bestimmte Attribute bei allen Reisespesen des selben Reiseabschnitts angleichen
        this.travelSection.matchSharedTravelAllowanceAttributes(name);
    }

    public countMeals(): { [key: string]: number } {
        return this.travelSection.getTravelDays().countMeals();
    }

    public hasAmout(): boolean {
        return true;
    }

    public isEditable(): boolean {
        return this.travelSection.isEditable();
    }

    public isDeletable(): boolean {
        return false;
    }

    public toValueObject(): object {
        return this.allowanceEntity.toValueObject();
    }

    /**
     * @returns Warnung durch letzte Attributänderungen (seit letzter Abfrage)
     */
    public getAndClearChangeWarningKey(): string {
        return null;
    }
}
