import { AttributeDefinition } from "../../common/schema/AttributeDefinition";
import { TypeSubtypeDefinition } from "../../common/schema/TypeSubtypeDefinition";
import { Entity } from "../../entities/Entity";
import { DateTimeValue } from "../../entities/values/DateTimeValue";
import { EntityValue } from "../../entities/values/EntityValue";
import { OidValue } from "../../entities/values/OidValue";
import { IdGenerator } from "../../util/text/IdGenerator";
import { StringValue } from "../../entities/values/StringValue";

/**
 * Repräsentiert den Kontakt innerhalb der App. Bietet Methoden zur Abfrage von Daten.
 */
export class Contact {
    public static readonly TYPE = "JUser";
    public static readonly SUBTYPE = "ouperson";
    public static OID = "oid";
    public static INS_DATE = "insDate";
    public static readonly SYNCED_DATE: string = "syncedDate";
    public static readonly SYNC_YEAR: string = "synchedYear";
    private contactEntity: Entity;

    constructor(
        typeSubtypeDefinition: TypeSubtypeDefinition,
        contactObject: object,
        isNew: boolean,
    ) {
        this.contactEntity = new Entity(typeSubtypeDefinition, contactObject, isNew);
        if (isNew) {
            const iD = new OidValue(IdGenerator.createId() + "_" + Contact.TYPE);
            this.setValue(Contact.OID, iD);
            this.setValue(Contact.INS_DATE, DateTimeValue.now());
            this.setValue(Contact.SYNCED_DATE, new StringValue(undefined));
            this.setValue(Contact.SYNC_YEAR, new StringValue(undefined));
        }
    }

    public getId(): string {
        return this.contactEntity.getId();
    }

    public getSubtype(): string {
        return Contact.SUBTYPE;
    }

    public getValue(name: string): EntityValue {
        return this.contactEntity.getValue(name);
    }

    public setValue(name: string, value: EntityValue): void {
        this.contactEntity.setValue(name, value);
    }

    getAttributeDefinition(name: string): AttributeDefinition {
        return this.contactEntity.getTypeSubtypeDefinition().getAttributeDefinition(name);
    }

    toValueObject(): object {
        return this.contactEntity.toValueObject();
    }
}
