import { GUIElement } from "../GUIElement";
import { BCSDate, BCSWeekday } from "../../common/BCSDate";
import { Registry } from "../../core/Registry";
import { OnRenderBehavior } from "../OnRenderBehavior";

import { PieChart } from "../chart/PieChart";
import { PieChartEntry } from "../chart/PieChartEntry";

import { AppColors } from "../../util/globalDefinitions/AppColors";

import * as d3 from "d3";

import "./TimeRecordingTacho.less";
import {
    BookingsPerDay,
    DayBookingStructure,
} from "../../domain/time_recording/bookings/BookingsPerDay";
import { TimeRecordingOptions, WorkDays } from "../../domain/time_recording/TimeRecordingOptions";
import { ConfigNode } from "../../core/Config/ConfigNode";
import { GUIContext } from "../GUIContext";

export class TimeRecordingTacho implements GUIElement, OnRenderBehavior {
    public static BCS_COMPONENT_NAME = "TimeRecordingTacho";

    private shownDate: BCSDate = BCSDate.today();

    private layout: TimeRecordingTacho.Layout = TimeRecordingTacho.Layout.Board;

    private configNode: ConfigNode;

    private guiContext: GUIContext;

    private timeRecordingOptions: TimeRecordingOptions;

    private useAttendances: boolean;

    private datePattern;

    constructor(
        configNode: ConfigNode,
        guiContext: GUIContext,
        timeRecordingOptions: TimeRecordingOptions,
        useAttendances: boolean,
        shownDate?: BCSDate,
        layout?: TimeRecordingTacho.Layout,
    ) {
        this.configNode = configNode;
        this.guiContext = guiContext;

        this.timeRecordingOptions = timeRecordingOptions;
        this.useAttendances = useAttendances;

        if (shownDate) {
            this.shownDate = shownDate;
        }

        if (layout) {
            this.layout = layout;
        }

        this.datePattern = this.configNode.getAttribute("DatePattern", "DD.MM.");
    }

    compose($parent: JQuery): void {
        d3.select($parent[0]).append("svg").attr("class", "timeRecordingTacho");

        // Im Board-Layout zeigen wir noch das Datum darunter an
        if (this.layout === TimeRecordingTacho.Layout.Board) {
            const todayString = this.guiContext.getI18n().get("MobileApp.today") + ", ";
            $parent.append(
                "<div class='timeRecordingTachoTitle'>" +
                    todayString +
                    this.shownDate.format(this.datePattern) +
                    "</div>",
            );
        }
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }

    /**
     * Aufruf, um Verhalten nach dem Rendern der Seite auszuführen.
     *
     * @param $body Body-DOM-Element (Abstände werden vor dem Rendern der nächten Seiten immer wieder auf 0 gesetzt)
     */
    public executeOnRender($body: JQuery): void {
        const bookings = this.guiContext.getBookingsPerDay();

        const day: DayBookingStructure = bookings.getBookingsAtDay(this.shownDate);

        // Summe der für den Tag eingetragenen Anwesenheiten
        const attendanceSum: number = bookings.getAttendancesAtDayExpense(this.shownDate);

        // Sollarbeitszeit für den Tag
        const weekDay: string =
            BCSWeekday[this.shownDate.getWeekday(this.guiContext.getI18n().getLocale())];
        const expectedWorktime: number = this.timeRecordingOptions.getWorkingTimeOfDay(
            WorkDays[weekDay],
        );
        const useDailyTarget: boolean =
            this.timeRecordingOptions.isTimeRecordingTachoUsesDailyTarget();

        const width = 100,
            height = 100;

        let dayBookingSum: number = 0;
        let dayBookingSumSynced: number = 0;
        let dayBookingSumNotSynced: number = 0;

        if (day !== null) {
            dayBookingSum = day.getDayBookingSum();
            dayBookingSumSynced = day.getDayBookingSumSynced();
            dayBookingSumNotSynced = day.getDayBookingSumNotSynced();
        }

        if (day !== null || attendanceSum !== 0) {
            // ### Diagramm anzeigen
            const svg: any = d3
                .select("svg.timeRecordingTacho")
                .attr("width", 100)
                .attr("height", 100);
            const pichart = new PieChart(width, height, "timeRecordingTacho");
            let deltaColor = AppColors.TimeRecording_UnbookedTimeColor;

            // Gebuchte Werte im Diagramm setzen
            pichart.setData(
                new PieChartEntry(dayBookingSumSynced, AppColors.TimeRecording_BookedTimeColor),
            );
            pichart.setData(
                new PieChartEntry(
                    dayBookingSumNotSynced,
                    AppColors.TimeRecording_IsSyncOnlyChangedInApp_Color_hex_BG,
                ),
            );

            // Abweichung von Anwesenheit / Soll-Wert berechnen
            let delta: number = 0;
            if (attendanceSum > 0 && this.useAttendances) {
                // Abweichung zur Anwesenheit
                delta = attendanceSum - dayBookingSum;
            } else if (expectedWorktime > 0 && useDailyTarget) {
                // Abweichung zur Sollarbeitszeit
                delta = expectedWorktime - dayBookingSum;
            } else {
                // Wenn es keine Differenz anzuzeigen gibt, dann nur die gebuchte Dauer zeigen
                delta = 0;
            }

            // Differenz zu Anwesenheit / Soll im Diagramm setzen
            if (delta < 0) {
                deltaColor = AppColors.TimeRecording_AlertColor;
            }
            pichart.setData(new PieChartEntry(Math.abs(delta), deltaColor));

            pichart.draw(svg);

            // ### Wert anzeigen
            let color = AppColors.TimeRecording_UnbookedTimeColor_dark;

            let textIndicator = 0;
            if (
                (attendanceSum > 0 && this.useAttendances) ||
                (expectedWorktime > 0 && useDailyTarget)
            ) {
                // Abweichung Anzeigen
                textIndicator = delta;
            } else {
                // Wenn es keine Differenz anzuzeigen gibt, dann nur die gebuchte Dauer zeigen
                color = AppColors.TimeRecording_BookedTimeColor;
                textIndicator = dayBookingSum;
            }

            let fontSize = 28;

            if (this.layout === TimeRecordingTacho.Layout.Overview) {
                fontSize = 25;
            }

            if (delta < 0) {
                color = AppColors.TimeRecording_AlertColor;
            }

            let displayedDuration: string =
                BCSDate.minutesToDisplayTimeWithoutLeadingHourZero(textIndicator) + "";

            displayedDuration += "h";
            if (displayedDuration.length > 4) {
                fontSize -= 3;
            }
            const fontSizeStyle = fontSize + "px";
            svg.append("text")
                .attr("dx", width / 2)
                .attr("dy", height / 2 + 2)
                .attr("fill", color)
                .attr("text-anchor", "middle")
                .attr("alignment-baseline", "middle")
                // für FireFox:
                .attr("dominant-baseline", "middle")
                .style("font-size", fontSizeStyle)
                .text(displayedDuration);
        } else {
            const parent = $("svg.timeRecordingTacho").parent();
            $("svg.timeRecordingTacho").detach();
            if (this.layout === TimeRecordingTacho.Layout.Board) {
                $(parent).prepend(
                    "<div class='noBookings'><span>" +
                        this.guiContext.getI18n().get("MobileApp.noBookings") +
                        "</span></div>",
                );
            }
        }
    }
}

Registry.registerGUIComponent(TimeRecordingTacho.BCS_COMPONENT_NAME, TimeRecordingTacho);

export namespace TimeRecordingTacho {
    export enum Layout {
        /** Layout auf Übersichtsseite des Tagesbuchens */
        Overview = "timeRecordingOverview",
        /** Layout als Boardelement (auf der Haupt-Seite) */
        Board = "boardElement",
    }
}
