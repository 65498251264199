import { Task } from "./Task";
import { Appointment } from "./Appointment";
import { Ticket } from "./Ticket";
import { Requirement } from "./Requirement";
import { Workflow } from "./Workflow";

export class Timesheet {
    private tasks: { [key: string]: Task };
    private tickets: { [key: string]: Ticket };
    private requirements: { [key: string]: Requirement };
    private workflows: { [key: string]: Workflow };

    private appointments: { [key: string]: Appointment };

    constructor() {}

    /**
     * @returns Alle Aufgaben der Aufgabenliste
     */
    public getTasklist(): Task[] {
        const tasklist: Task[] = [];

        for (const id in this.tasks) {
            tasklist.push(this.tasks[id]);
        }

        return tasklist;
    }

    public getTasksById(id: string): Task {
        if (this.tasks.hasOwnProperty(id)) {
            return this.tasks[id];
        } else {
        }
    }

    public getTicketById(id: string): Ticket {
        if (this.tickets.hasOwnProperty(id)) {
            return this.tickets[id];
        } else {
        }
    }

    public getRequirementById(id: string): Requirement {
        if (this.requirements.hasOwnProperty(id)) {
            return this.requirements[id];
        } else {
        }
    }

    public getWorkflowById(id: string): Workflow {
        if (this.workflows.hasOwnProperty(id)) {
            return this.workflows[id];
        } else {
        }
    }

    public getAppointmentById(id: string): Appointment {
        if (this.appointments.hasOwnProperty(id)) {
            return this.appointments[id];
        } else {
        }
    }

    public addTasks(tasks: { [key: string]: Task }) {
        // AppConsole.log(JSON.stringify(tasks,null,2));
        this.tasks = tasks;
    }

    public addTask(oid: string, task: Task) {
        this.tasks[oid] = task;
    }

    public addTicket(oid: string, ticket: Ticket) {
        this.tickets[oid] = ticket;
    }

    public addRequirement(oid: string, requirement: Requirement) {
        this.requirements[oid] = requirement;
    }

    public addWorkflow(oid: string, workflow: Workflow) {
        this.workflows[oid] = workflow;
    }

    public addAppointment(oid: string, appointment: Appointment) {
        this.appointments[oid] = appointment;
    }

    public addAppointments(appointments: { [key: string]: Appointment }) {
        this.appointments = appointments;
    }

    public setTasks(tasks: Task[]): void {
        this.tasks = {};
        tasks.forEach((task: Task) => {
            this.addTask(task.getId(), task);
        });
    }

    public setTickets(tickets: Ticket[]): void {
        this.tickets = {};
        tickets.forEach((ticket: Ticket) => {
            this.addTicket(ticket.getId(), ticket);
        });
    }

    public setRequirements(requirements: Requirement[]): void {
        this.requirements = {};
        requirements.forEach((requirement: Requirement) => {
            this.addRequirement(requirement.getId(), requirement);
        });
    }

    public setWorkflows(workflows: Workflow[]): void {
        this.workflows = {};
        workflows.forEach((workflow: Workflow) => {
            this.addWorkflow(workflow.getId(), workflow);
        });
    }

    public setAppointments(appointments: Appointment[]): void {
        this.appointments = {};
        appointments.forEach((appointment: Appointment) => {
            this.addAppointment(appointment.getId(), appointment);
        });
    }
}
