import { TravelSection } from "../../records/TravelSection";
import { DateValue } from "../../../../entities/values/DateValue";
import { TravelDays } from "../../records/TravelDays";
import { TravelDay } from "../../records/TravelDay";

/**
 * Kapselt Zugriff auf Mahlzeiten je Kalendertag des aktuellen Reiseabschnitts für die Bearbeitung.
 *
 * Vereinfacht Prüfung, ob je Mahlzeit alle bzw. keine Reisetag markiert ist.
 * Markiert je Mahlzeit alle Reisetage.
 *
 * Merkt sich für alle Kalendertag der gesamten Dienstreise, welche Mahlzeiten angegeben wurden.
 *
 * Hilft dabei, in der Liste der Mahlzeiten eines Reiseabschnitts diejenigen Mahlzeiten an dem ersten bzw. letzten Reisetag
 * zu disablen, die bereits an einem vorherigen bzw. nachfolgenden Reiseabschnitt angegeben sind.
 *
 * Ansonsten würde es beim Speichern der Spesen in BCS eine Fehlermeldung geben, wenn am selben Kalendertag eine Mahlzeit doppelt angegeben wäre.
 */
export class TravelDaysViewModel {
    private currentTravelDays: TravelDay[];

    private dayAndMealDisabled: { [key: string]: { [key: string]: boolean } } = {};

    private isTravelDaysEditable: boolean;

    /**
     * @param currentTravelSection Aktuell angezeigter Reiseabschnitt
     * @param allTravelSections Alle Reiseabschnitte der Dienstreise
     */
    constructor(currentTravelSection: TravelSection, allTravelSections: TravelSection[]) {
        this.currentTravelDays = currentTravelSection.getTravelDays().getAllTravelDays();

        allTravelSections
            .filter((section) => section != currentTravelSection)
            .forEach((section) =>
                section
                    .getTravelDays()
                    .getAllTravelDays()
                    .forEach((travelDay) => {
                        const selectionsPerMealName: { [key: string]: boolean } = {};
                        this.dayAndMealDisabled[travelDay.getTravelDate().getISODate()] =
                            selectionsPerMealName;

                        travelDay.getMealNamesAndValues().forEach((mealNamesAndValue) => {
                            if (mealNamesAndValue.value) {
                                selectionsPerMealName[mealNamesAndValue.name] = true;
                            }
                        });
                    }),
            );

        this.isTravelDaysEditable = currentTravelSection.isEditable();
    }

    public countTravelDates(): number {
        return this.currentTravelDays.length;
    }

    public getTravelDay(travelDayNo: number): TravelDay {
        return this.currentTravelDays[travelDayNo];
    }

    public isAllTravelDaySelected(mealName: string): boolean {
        return this.currentTravelDays
            .filter((travelDay) => !this.isTravelDayAndMealDisabled(travelDay, mealName))
            .reduce((result, travelDay) => result && travelDay.getValue(mealName), true);
    }

    public isNoneTravelDaySelected(mealName: string): boolean {
        return this.currentTravelDays
            .filter((travelDay) => !this.isTravelDayAndMealDisabled(travelDay, mealName))
            .reduce((result, travelDay) => result && !travelDay.getValue(mealName), true);
    }

    public selectAllTravelDays(mealName: string, value: boolean): void {
        return this.currentTravelDays
            .filter((travelDay) => !this.isTravelDayAndMealDisabled(travelDay, mealName))
            .forEach((travelDay) => travelDay.setValue(mealName, value));
    }

    private isTravelDayAndMealDisabled(travelDay: TravelDay, mealName: string): boolean {
        return this.isDayAndMealDisabled(travelDay.getTravelDate(), mealName);
    }

    public isDayAndMealDisabled(travelDate: DateValue, mealName: string): boolean {
        const isoDate = travelDate.getISODate();
        return this.dayAndMealDisabled[isoDate] && this.dayAndMealDisabled[isoDate][mealName];
    }

    public isEditable(): boolean {
        return this.isTravelDaysEditable;
    }
}
