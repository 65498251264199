import { Controller } from "../../../../core/Controller";
import { Animation, AppNavigator } from "../../../../core/Router";
import { I18n } from "../../../../common/i18n/I18n";
import { ServerConfigProperties } from "../../../../common/config/ServerConfigProperties";
import { Schema } from "../../../../common/schema/Schema";
import { GUIPage } from "../../../../gui/GUIPage";
import { TypeAheadSearchView } from "../../../../gui/list/TypeAheadSearchView";
import { Component } from "../../../../core/Component";
import { ContactManager } from "../../ContactManager";
import { Contact } from "../../Contact";
import { Log } from "../../../../common/log/Log";
import { GUIContext } from "../../../../gui/GUIContext";
import { ToolBar } from "../../../../gui/navigation/ToolBar";
import { ToolLink } from "../../../../gui/navigation/ToolLink";
import { ListViewContext } from "../../../../gui/list/ListViewContext";
import { EmptyListViewModel } from "../../../../gui/list/EmptyListViewModel";
import { ListView } from "../../../../gui/list/ListView";
import { StringValue } from "../../../../entities/values/StringValue";
import { AppConsole } from "../../../../common/log/AppConsole";
import { ContactGUIDefinitions } from "../ContactGUIDefinitions";
import { ContactOptionSearchListViewModel } from "./ContactOptionSearchListViewModel";
import { Registry } from "../../../../core/Registry";

const optionName = "ouCountry";
export type contactOptionType = { optionId: string; optionLabel: string };

/**
 * Controller für Auswahlseite des Landes an einem Kontakt
 * bietet Möglichkeit zum Filtern durch Texteingabe
 *
 * @author richard.franke
 */
export class ContactOuContrySelectionController implements Controller {
    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static readonly CONTROLLER_PATH = "ouCountrySelection";

    /** Seiten-Parameter Pfad der Seite, zu der nach getroffener Auswahl zurückgekehrt werden soll. */
    public static readonly PARAMETER_RETURN_PATH = "return-path";

    /** Präfix für alle Seiten-Parameter, mit denen (ohne Präfix) die Rückkehrseite aufgerufen werden soll. */
    public static readonly PARAMETER_RETURN_PARAMETER_PREFIX = "return-parameter-";

    /** Referenz auf Navigator zum Aufruf einer anderen Seite */
    private navigator: AppNavigator;

    /** Slide-Animation beim Anzeigen der Seite */
    private animation: Animation;

    /** Parameter, mit der diese Seite aufgerufen wurde */
    private pageParameters: object;

    /** I18n */
    private i18n: I18n;

    /** Spesenverwaltung */
    private contactManager: ContactManager;

    private serverConfigProperties: ServerConfigProperties;

    private schema: Schema;

    /** Wurzel GUI-Element für Seite */
    private page: GUIPage;

    /** GUI-Element für Sucheingabe, Filterung der Ergebnisliste und Neuanzeige des Ergebnis-ListView */
    private typeAheadSearchView: TypeAheadSearchView;
    /**
     * Der übergebene Kontakt der vorherigen Seite
     * @private
     */
    private contact: Contact;

    /**
     * Liste der einzelnen Länderoptionen mit Anzeigelabel (zum Suchen)
     * @private
     */
    private ouCountryOptionList: { optionLabel: string; optionId: string }[];
    private contactGUIDefinitions: ContactGUIDefinitions;

    /**
     * Aufruf vor Erzeugen vor Initialisierung einer Instanz.
     *
     * @returns Symblische Name der benötigten Abhängigkeiten dieses Controllers
     */
    public getDependencyNames(): string[] {
        return [
            I18n.BCS_COMPONENT_NAME,
            ContactManager.BCS_COMPONENT_NAME,
            ServerConfigProperties.BCS_COMPONENT_NAME,
            Schema.BCS_COMPONENT_NAME,
        ];
    }

    /**
     * Aufruf für Initialisierung dieser Instanz.
     * @param depencencyComponents Benötigten Abhängigkeiten mit symblischen Namen
     */
    public init(depencencyComponents: { [key: string]: Component }) {
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.contactManager = <ContactManager>(
            depencencyComponents[ContactManager.BCS_COMPONENT_NAME]
        );
        this.serverConfigProperties = <ServerConfigProperties>(
            depencencyComponents[ServerConfigProperties.BCS_COMPONENT_NAME]
        );
        this.schema = <Schema>depencencyComponents[Schema.BCS_COMPONENT_NAME];
        this.contactGUIDefinitions = new ContactGUIDefinitions();
    }

    /**
     * Aufruf, wenn Seite gerendert werdern soll.
     *
     * @param parameters Parameter dieser Seite
     * @param animation Animation beim Einblenden
     * @param navigator Referenz auf Navigator zum Aufruf einer anderen Seite
     */
    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        this.navigator = navigator;
        this.animation = animation;
        this.pageParameters = parameters;

        if (!this.contactManager.getContactTerms().isContactRecordingAllowed()) {
            this.navigator.navigateTo("index", {}, Animation.SLIDE_RIGHT);
            return;
        }

        /**
         * Holt sich die Länderliste als Optionen und lädt den Kontakt
         */
        this.fetchContactCountryOptions(parameters["oid"]).then(() => this.composePage());
    }

    destroy(): void {
        // leer
    }

    /**
     * Aufruf via Callback, nachdem die Browser-Back-Taste angeklickt wurde.
     * Controller soll zum vorherigen Zustand zurückgehen, z.B. ein Menü schließen
     * oder abschließend zur übergeordneten Seite zurücknavigieren.
     */
    public popState(): void {
        this.navigateToReturnPath();
    }

    /**
     * Navigiert zurück zur aufrufenden Seite, der Kontaktliste, wo der Kontakt jetzt bearbeitet wurde
     * Aufruf der Seite, die als Parameter 'return-path' beim Aufruf dieser Seite angegeben wurde.
     * Als Parametern, werden die Parameter mitgesendet, die beim Aufruf dieser Seite mit dem Präfix 'return-parameter-' gegeben waren.
     */
    private navigateToReturnPath() {
        let returnPath = null;
        const returnParameters: { [key: string]: string } = {};

        for (const name in this.pageParameters) {
            if (name == ContactOuContrySelectionController.PARAMETER_RETURN_PATH) {
                // Pfad der Zielseite aus Parameter 'return-path' auslesen
                returnPath = this.pageParameters[name];
            } else if (
                name.substring(
                    0,
                    ContactOuContrySelectionController.PARAMETER_RETURN_PARAMETER_PREFIX.length,
                ) == ContactOuContrySelectionController.PARAMETER_RETURN_PARAMETER_PREFIX
            ) {
                // Parameter der Zielseite aus Parametern mit Präfix 'return-parameter-' auslesen
                const returnParameterName = name.substring(
                    ContactOuContrySelectionController.PARAMETER_RETURN_PARAMETER_PREFIX.length,
                );
                returnParameters[returnParameterName] = this.pageParameters[name];
            }
        }
        this.navigator.navigateTo(returnPath, returnParameters, Animation.SLIDE_RIGHT);
    }

    private async fetchContactCountryOptions(oid: string): Promise<void> {
        /**
         * Lädt den Kontakt, der als Parameter übergeben wurde
         */
        this.fetchContact(oid).then((promise) => {
            Log.debug("OuCountrySelectionPromise: " + promise);
            const optionIds: string[] = this.schema
                .getTypeSubtypeDefinition(Contact.TYPE, Contact.SUBTYPE)
                .getAttributeDefinition(optionName)
                .getOptions();
            // Optionen und Optionslabel zusammenstellen
            this.ouCountryOptionList = optionIds.map((optionId) => ({
                optionId: optionId,
                optionLabel: this.i18n.getAttributeOption(
                    Contact.TYPE,
                    Contact.SUBTYPE,
                    optionName,
                    optionId,
                ),
            }));
        });
    }

    private composePage() {
        // if (!this.contact) {
        // Wenn Kontakt nicht geladen: Zurück zur Ausgangsseite
        // this.navigateToReturnPath();
        //}

        // Seite (Wurzelelement) erstellen
        this.page = new GUIPage(
            new GUIContext(this.i18n),
            ContactOuContrySelectionController.CONTROLLER_PATH,
        );

        // Kopfzeile (mit Navigation und Titel)
        this.composeHeader();

        // Suchfeld und Ergebnisliste mit Spesenaufgaben
        this.composeSearchAndResultList();

        // Seite rendern
        this.page
            .setAnimation(this.animation, this.navigator.doShowAnimations())
            .compose($("body"));
    }

    private async fetchContact(oid: string): Promise<void> {
        try {
            // lade den Kontakt, damit wir später die Option setzen können
            if (typeof oid !== "undefined" && oid) {
                this.contactManager.readContactFromDB(oid).then(
                    (contact) => {
                        this.contact = contact;
                    },
                    (reason) => {
                        Log.error(reason);
                        this.navigateToReturnPath();
                    },
                );
            }
        } catch (loadingErr) {
            Log.error("Error while Opening optionName Selection => " + loadingErr);
            this.navigateToReturnPath();
        }
    }

    private composeHeader(): void {
        const headerToolBar = new ToolBar()
            .setId("header_toolbar")
            .setTitle(this.i18n.get("MobileApp.contactOuCountrySelection.pagetitle"))
            .addStyleClass("headBar")
            .addToolLinkLeft(
                new ToolLink()
                    .setId("navigateBack")
                    .setImageName("icon-chevron-left.svg")
                    .onClick(this.popState.bind(this)),
            );
        this.page.addHeaderElement(headerToolBar);
    }

    private composeSearchAndResultList(): void {
        // ListView-Kontext vorerst mit leerem ListViewModel
        // nach Rendern der Seite und nach jeder Sucheingabe wird Ergebnisliste mit Suchergebnis als ListViewModel neu gerendert.
        const listViewContext = new ListViewContext()
            .setI18n(this.i18n)
            .setModel(new EmptyListViewModel());

        const optionSelectionResultListViewDef =
            this.serverConfigProperties.customizeGUIDefinitions(
                this.contactGUIDefinitions.getOptionSelectionResultListViewDef(optionName),
                ContactOuContrySelectionController.CONTROLLER_PATH,
                optionName,
                "display",
            );

        // ListView mit Ergebnisliste
        const resultListView: ListView = new ListView(
            listViewContext,
            optionSelectionResultListViewDef,
        )
            .setDOMId("optionResult")
            .addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW)
            .onRowClicked(this.listRowClicked.bind(this));

        this.typeAheadSearchView = new TypeAheadSearchView()
            .setPlaceholder(this.i18n.get("MobileApp." + optionName))
            .setSearchModel(
                new ContactOptionSearchListViewModel(this.ouCountryOptionList, this.i18n),
                ["optionId", "optionLabel"],
            )
            .setResultListView(resultListView);

        this.page.addPageElement(this.typeAheadSearchView);
    }

    private listRowClicked(selectionPath: string, parameters: { [key: string]: string }): void {
        Log.debug("Selection Path of ContactOuCountrySelectionController: " + selectionPath);
        // Ausgewählte Option am Kontakt setzen
        const selectedOptionId = parameters["selectedOptionId"];

        this.contact.setValue(optionName, new StringValue(selectedOptionId));

        // aktualisierten Kontakt in der Datenbank speichern
        this.contactManager
            .storeContact(this.contact)
            .then((prom) => {
                AppConsole.debug("ContactStored, Promise should be void => " + prom);
                this.navigateToReturnPath();
            })
            .catch((error) => AppConsole.error(error));
    }
}

// Controller unter seinem Pfad registrieren
Registry.registerComponent(
    ContactOuContrySelectionController.CONTROLLER_PATH,
    ContactOuContrySelectionController,
);
