import { HttpRESTClient } from "../../../util/http/HttpRESTClient";
import { I18nFallBackLabels } from "../../i18n/I18nFallBackLabels";
import { IOAuthConfig } from "./OAuthConfig";

/**
 * Client, der sich asynchron die OAuth-Konfiguration über eine REST-Schnittstelle
 * vom BCS-Server holt.
 *
 * Wenn OAuth auf dem BCS-Server aktiv ist, wird in der App
 * der Button angezeigt, um sich bspw. bei Microsoft anzumelden.
 */
export class OAuthConfigClient {
    public async getOAuthConfig(): Promise<IOAuthConfig> {
        const userLocale = I18nFallBackLabels.getFallbackLanguage();
        console.debug("UserLocale: " + userLocale);
        return await new HttpRESTClient(true)
            .createRequest()
            .setMethod("GET")
            .addPath("oauth/config")
            .setParameter("locale", userLocale)
            .send()
            .then((result) => {
                if (result["json"]["oAuthEnabled"]) {
                    return {
                        oAuthEnabled: true,
                        oAuthUrl: result["json"]["oAuthUrl"],
                        loginLabel: result["json"]["loginLabel"],
                    } as IOAuthConfig;
                } else {
                    return {
                        oAuthEnabled: false,
                        oAuthUrl: undefined,
                        loginLabel: undefined,
                    } as IOAuthConfig;
                }
            })
            .catch((error) => {
                console.error("Error fetching OAuthConfig: " + error);
                throw error;
            });
    }
}
