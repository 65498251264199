import { AccomodationAllowance } from "../../records/AccomodationAllowance";
import { AllowanceGUIDefinitions } from "../AllowanceGUIDefinitions";
import { AllowanceManager } from "../../AllowanceManager";
import { AllowancesListViewModel } from "../AllowancesListViewModel";
import { Animation, AppNavigator } from "../../../../core/Router";
import { BusinessTravel } from "../../records/BusinessTravel";
import { BusinessTravelSectionListViewModel } from "./BusinessTravelSectionListViewModel";
import { Collapsable } from "../../../../gui/navigation/Collapsable";
import { Component } from "../../../../core/Component";
import { Controller } from "../../../../core/Controller";
import { FlexAlignItems, FlexBox, FlexJustifyContent } from "../../../../gui/flexbox/FlexBox";
import { FlexAlignSelf, FlexItem } from "../../../../gui/flexbox/FlexItem";
import { FooterTabBar } from "../../../../board/FooterTabBar";
import { formChangeEventType } from "../../../../gui/form/Form";
import { GUIContext } from "../../../../gui/GUIContext";
import { GUIElement } from "../../../../gui/GUIElement";
import { GUIEventPool } from "../../../../gui/event/GUIEventPool";
import { GUIPage } from "../../../../gui/GUIPage";
import { I18n } from "../../../../common/i18n/I18n";
import { KilometreAllowance } from "../../records/KilometreAllowance";
import { ListFieldFeedback, ListFieldFeedbackType } from "../../../../gui/list/ListFieldFeedback";
import { ListRow } from "../../../../gui/list/ListRow";
import { ListView } from "../../../../gui/list/ListView";
import { ListViewContext } from "../../../../gui/list/ListViewContext";
import { ListViewDeleteMode } from "../../../../gui/list/ListViewDeleteMode";
import { MessageEntity, MessageType } from "../../../../core/Message/MessageEntity";
import { Registry } from "../../../../core/Registry";
import { ServerConfigProperties } from "../../../../common/config/ServerConfigProperties";
import { SyncStateManager } from "../../../../sync/SyncStateManager";
import { TabbedViewBar } from "../../../../gui/navigation/TabbedViewBar";
import { TextLabel } from "../../../../gui/content/TextLabel";
import { ToolBar } from "../../../../gui/navigation/ToolBar";
import { ToolLink } from "../../../../gui/navigation/ToolLink";
import { TravelSection } from "../../records/TravelSection";
import { TravelSectionHeader } from "./TravelSectionHeader";
import { VoucherAllowance } from "../../records/VoucherAllowance";
import { PageDeleteMode } from "../../../../gui/navigation/PageDeleteMode";
import "./travelsections.less";
import { VoucherController } from "../vouchers/VoucherController";

/**
 * Zentrale Steuerung einer Seite mit den Reiseabschnitten einer Dienstreise.
 *
 * Alternativ werden die Reisedaten (Start/Ende, Aufgabe, Abrechenbarkeit, Links zu Kilometergeld, Übernachtungen usw.)
 * oder die Belegspesen angezeigt.
 *
 * Blockweise werden die einzelnen Reiseabschnitte dargestellt.
 *
 * Verwendet den AllowanceManager um eine Dienstreise zu beschaffen bzw. abzulegen.
 */
export class BusinessTravelSectionsController implements Controller {
    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static readonly CONTROLLER_PATH = "travel";

    public static readonly PARAMETER_BUSINESS_TRAVEL_ID = "oid";

    public static readonly PARAMETER_OPEN_SUB_ALLOWANCE_ID = "openSubAllowanceOid";

    private static readonly TAB_SWITCH_TRAVEL_DATA = "travelData";

    private static readonly TAB_SWITCH_TRAVEL_VOUCHERS = "travelVouchers";

    private static readonly STATE_PROPERTY_TAB_SWITCH = "currentTabSwitch";

    /** Dienstreisen-Id, um eine neue Dienstreise zu erzeugen */
    private static readonly NEW_ID = "*";

    /** Um eine untergeordnete Spesenansicht (z.B. Mahlzeiten) oder die übergrordnete Liste der Dienstreisen öffnen zu können */
    private navigator: AppNavigator;

    private animation: Animation;

    /** Meldung von vorheriger Seite oder nach Aktion zum Anzeigen nach Seitenaufbau */
    private messages: MessageEntity[] = [];

    private i18n: I18n;

    private allowanceManager: AllowanceManager;

    private syncStateManager: SyncStateManager;

    private serverConfigProperties: ServerConfigProperties;

    private businessTravel: BusinessTravel;

    /**
     * Gibt an, ob die Dienstreise mit dem Aufruf der aktuellen Seite erstellt wurde
     * Sofern sie nicht mindestens einmal gespeichert wird, wird sie bei Zurück-Navigation wieder gelöscht.
     */
    private isBusinessTravelCreatedAndNotEdited = false;

    /**
     * Id einer Reise- oder Belegspese der geöffneten Dienstreise,
     * die über einen Fehler-Link aus der Sync-Seite direkt geöffnet werden soll (optional)
     */
    private openSubAllowanceId: string;

    private allowanceGUIDefinitions: AllowanceGUIDefinitions;

    private page: GUIPage;

    private headerToolBar: ToolBar;

    /** TabbedBar zur Umschalten zwischen Reisedaten (Start/Ende/Aufgabe/...) und Belegliste */
    private tabbedViewBar: TabbedViewBar;

    /** Name des aktiven Tabs */
    private activeTabSwitchName: string;

    private businessTravelEditListView: ListView;

    /** Alternativ sichtbare ListViews je nach aktivem Tab (Reiseabschnitte bzw. Belege) */
    private listViews: { [key: string]: ListView[] } = {};

    private travelEditListViews: ListView[] = [];

    /** Titel-Elemente merken, um Reisezweck und Start/Ende nach Bearbeitung zu aktualisieren (je 1x für Tab Reisedaten und für Belege). */
    private travelSectionHeaders: TravelSectionHeader[] = [];

    private pageDeleteMode: PageDeleteMode;

    private sectionsListViewDeleteMode: ListViewDeleteMode;

    private footerTabBar: FooterTabBar;

    private allowanceWasEdited = false;

    public getDependencyNames(): string[] {
        return [
            I18n.BCS_COMPONENT_NAME,
            AllowanceManager.BCS_COMPONENT_NAME,
            SyncStateManager.BCS_COMPONENT_NAME,
            ServerConfigProperties.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }) {
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.allowanceManager = <AllowanceManager>(
            depencencyComponents[AllowanceManager.BCS_COMPONENT_NAME]
        );
        this.syncStateManager = <SyncStateManager>(
            depencencyComponents[SyncStateManager.BCS_COMPONENT_NAME]
        );
        this.serverConfigProperties = <ServerConfigProperties>(
            depencencyComponents[ServerConfigProperties.BCS_COMPONENT_NAME]
        );

        this.allowanceGUIDefinitions = new AllowanceGUIDefinitions();
    }

    /**
     * Aufruf nachdem zu der Seite einer Dienstreise mit ihren Reiseabschnitten navigiert wurde.
     *
     * Generiert Seite mit Dienstreise und ihren Reiseabschnitten.
     *
     * @param parameters Parameter der aufgerufenen Seite (z.B. id der PageEntity)
     * @param animation Animation beim Seitenwechsel (ob die Seite nach "links" bzw. "rechts" geöffnet wird) [optional]
     * @param navigator Dient der expliziten Navigation zu anderen Seiten
     */
    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        // Merken, um eine untergeordnete Spesenansicht (z.B. Mahlzeiten) oder die übergrordnete Liste der Dienstreisen öffnen zu können.
        this.navigator = navigator;
        this.animation = animation;

        // Meldung von vorheriger Seite zum späteren Anzeigen merken
        if (parameters["affirmations"]) {
            this.addMessage(
                new MessageEntity(
                    this.i18n.get("MobileApp.travel.message." + parameters["affirmations"]),
                    MessageType.AFFIRMATION,
                ),
            );
        }
        if (parameters["warnings"]) {
            this.addMessage(
                new MessageEntity(
                    this.i18n.get("MobileApp.travel.message." + parameters["warnings"]),
                    MessageType.WARNING,
                ),
            );
        }

        if (!this.allowanceManager.getAllowanceRecordingTerms().isAllowanceRecordingAvailable()) {
            this.navigator.navigateTo("index", {}, Animation.SLIDE_RIGHT);
            return;
        }

        const self = this;
        this.fetchBusinessTravel(parameters).then(() => self.composePage());
    }

    private async fetchBusinessTravel(parameters: { [key: string]: string }): Promise<void> {
        const businessTravelId =
            parameters[BusinessTravelSectionsController.PARAMETER_BUSINESS_TRAVEL_ID];

        if (businessTravelId && businessTravelId != BusinessTravelSectionsController.NEW_ID) {
            // Aufräumen der Parameter dieser Seite (für sauberere History oder DeepLinks):
            // Behalten der Parameter, die eine Eigenschaft der Seite definieren (z.B. Id der Dienstreise),
            // Entfernen der Parameter, die temporär speziellen Aufrufbedingungen definieren (z.B. Herkunft).
            const replacementParameters: { [key: string]: string } = {};
            replacementParameters[BusinessTravelSectionsController.PARAMETER_BUSINESS_TRAVEL_ID] =
                businessTravelId;
            this.navigator.replaceCurrentParameters(replacementParameters);

            // Dienstreise laden
            this.businessTravel = await (<Promise<BusinessTravel>>(
                this.allowanceManager.fetchAllowanceById(businessTravelId)
            ));
        } else if (!businessTravelId) {
            // Aufruf aus Board (ohne Parameter)
            // Spezialfall: Direkter Link vom Board zu einer neuen bzw. der laufenden Dienstreise

            // Gibt es eine laufende Dienstreise?
            const allowanceSummary = await this.allowanceManager.fetchAllowanceSummary();
            if (allowanceSummary.isTravelRunning()) {
                // Laufende Dienstreise fortsetzen
                this.businessTravel = await (<Promise<BusinessTravel>>(
                    this.allowanceManager.fetchAllowanceById(allowanceSummary.getRunningTravelId())
                ));
            }
        }

        // Neue Dienstreise beginnen
        if (!this.businessTravel) {
            // Entweder nach Aufruf aus Board (ohne Parameter) und es  läuft aktuell keine Dienstreise
            // oder aus Spesenliste mit expizitem Neu-Parameter "*"
            this.businessTravel = await this.allowanceManager.createBusinessTravel();
            this.isBusinessTravelCreatedAndNotEdited = true;
        }

        // Namen der ausgewählten Aufgabe am OidValue (allowancePSPSOid) setzen
        if (this.businessTravel) {
            this.businessTravel.getTravelSections().forEach((travelSection) => {
                const pspOid = travelSection.getPSPOid();

                const allowancePSP = this.allowanceManager
                    .getAllowanceRecordingTerms()
                    .getAllowancePSP(pspOid);
                if (allowancePSP) {
                    travelSection.setPSPName(allowancePSP.getTaskName());
                }
            });
        }

        this.openSubAllowanceId =
            parameters[BusinessTravelSectionsController.PARAMETER_OPEN_SUB_ALLOWANCE_ID];
    }

    private async composePage(scrollToSectionId?: string): Promise<void> {
        this.page = new GUIPage(
            new GUIContext(this.i18n),
            BusinessTravelSectionsController.CONTROLLER_PATH,
            JSON.stringify(this.businessTravel.getEntityIdTree()),
        );

        if (!this.checkShowBusinessTravel()) {
            return;
        }

        this.composeHeader();

        this.composeTabBar();

        await this.composeFooterTabBar();

        // Falls Löschen der Dienstreise erlaubt ...
        if (this.businessTravel.isDeletable()) {
            // Löschen von markierten Reiseabschnitten (nicht mehr von Belegen)
            this.sectionsListViewDeleteMode = new ListViewDeleteMode()
                .setI18n(this.i18n)
                .setPage(this.page)
                .setHeaderToolBar(this.headerToolBar)
                .setDeleteSingleSelectionLabel(
                    this.i18n.get(
                        "MobileApp.travel.DeleteActionButton.deleteSelectedTravelSection",
                    ),
                )
                .setDeleteMultipleSelectionLabel(
                    this.i18n.get(
                        "MobileApp.travel.DeleteActionButton.deleteSelectedTravelSections",
                    ),
                )
                .onDelete(this.deleteSelectedRows.bind(this));
            this.sectionsListViewDeleteMode.setListViews(
                this.listViews[BusinessTravelSectionsController.TAB_SWITCH_TRAVEL_DATA],
            );

            // Löschen der ganzen Dienstreise
            this.pageDeleteMode = new PageDeleteMode()
                .setPage(this.page)
                .setHeaderToolBar(this.headerToolBar)
                .setDeleteButtonLabel(this.i18n.get("MobileApp.travels.deleteTravel"))
                .onDeleteModeToggle(() => this.sectionsListViewDeleteMode.resetDeleteSelections())
                .onDelete(this.deleteBusinessTravel.bind(this));

            // Selektieren von Reiseabschnitten zum Löschen -> nicht erlauben wenn Löschen der Dienstreise aktiv
            this.sectionsListViewDeleteMode.onSelectCheckAllow(
                () => !this.pageDeleteMode.isDeleteModeActive(),
            );

            setTimeout(() => this.sectionsListViewDeleteMode.showDeleteSelections(), 1000);
        }

        // Meldung(en) von vorheriger Seite oder nach Aktion anzeigen
        this.page.composeMessages(this.messages, new GUIContext(this.i18n, new GUIEventPool()));
        this.messages = [];

        this.page
            .setAnimation(this.animation, this.navigator.doShowAnimations())
            .setScrollTop(this.navigator.getControllerState().getPageScrollTop())
            .setScrollToElement(scrollToSectionId)
            .compose($("body"));
    }

    private checkShowBusinessTravel(): boolean {
        if (!this.businessTravel) {
            // Wenn Dienstreise nicht geladen: Eine Ebene hoch zur Liste der Diesnstreisen
            this.navigateUp();
            return false;
        }

        if (this.openSubAllowanceId) {
            // Falls über einen Fehler-Link aus der Sync-Seite direkt geöffnet, direkt die fehlhafte Reisespese/Belegspese öffnen
            const openAllowance = this.businessTravel.getSubAllowanceById(this.openSubAllowanceId);
            if (openAllowance) {
                switch (openAllowance.getSubtype()) {
                    case KilometreAllowance.SUBTYPE:
                        this.navigator.navigateTo(
                            "travel/kilometres",
                            {
                                oid: this.businessTravel.getId(),
                                kilometreAllowanceOid: this.openSubAllowanceId,
                            },
                            Animation.SLIDE_RIGHT,
                        );
                        return false;
                    case AccomodationAllowance.SUBTYPE:
                        this.navigator.navigateTo(
                            "travel/accomodations",
                            {
                                oid: this.businessTravel.getId(),
                                accomodationAllowanceOid: this.openSubAllowanceId,
                            },
                            Animation.SLIDE_RIGHT,
                        );
                        return false;
                    case VoucherAllowance.SUBTYPE:
                        this.navigator.navigateTo(
                            "voucher",
                            {
                                businessTravelOid: this.businessTravel.getId(),
                                voucherAllowanceOid: this.openSubAllowanceId,
                            },
                            Animation.SLIDE_RIGHT,
                        );
                        return false;
                }
            }
        }

        return true;
    }

    /**
     * Generiert Kopfbereich mit Zurück-Navigation, Seitentitel und ggf. Umschalter in Lösch-Modus.
     */
    private composeHeader(): void {
        this.headerToolBar = new ToolBar()
            .addStyleClass("headBar")
            .setId("header_toolbar")
            .setTitle(this.i18n.get("JAllowance.businessTravel"));

        this.headerToolBar.addToolLinkLeft(
            new ToolLink()
                .setId("navigateBack")
                .setImageName("icon-chevron-left.svg")
                .onClick(this.popState.bind(this)),
        );

        this.page.addHeaderElement(this.headerToolBar);
    }

    /**
     * Generiert weitere Kopfleiste mit Auswahl, ob die Reisedaten (Start/Ende/Aufgabe/...) bzw. die Listen der Belegspesen angezeigt werden.
     */
    private composeTabBar(): void {
        // Bei Rückkehr von einer Unterseite: Letzten aktiven Tab wieder aktiv setzen
        if (!this.activeTabSwitchName) {
            this.activeTabSwitchName = BusinessTravelSectionsController.TAB_SWITCH_TRAVEL_DATA;
            if (this.animation == Animation.SLIDE_RIGHT) {
                this.activeTabSwitchName = <string>(
                    this.navigator
                        .getControllerState()
                        .getProperty(
                            BusinessTravelSectionsController.STATE_PROPERTY_TAB_SWITCH,
                            this.activeTabSwitchName,
                        )
                );
            }
        }

        this.listViews[BusinessTravelSectionsController.TAB_SWITCH_TRAVEL_DATA] = [];
        this.listViews[BusinessTravelSectionsController.TAB_SWITCH_TRAVEL_VOUCHERS] = [];

        // TabbedBar zur Umschalten zwischen Reisedaten (Start/Ende/Aufgabe/...) und Belegliste
        this.tabbedViewBar = new TabbedViewBar()
            .setFixed(true)
            .addContentTabSwitch(
                BusinessTravelSectionsController.TAB_SWITCH_TRAVEL_DATA,
                this.i18n.get("MobileApp.travel.TabSwitch.travelData"),
                this.activeTabSwitchName == BusinessTravelSectionsController.TAB_SWITCH_TRAVEL_DATA,
                this.composeTravelSections(
                    this.composeBusinessTravel.bind(this),
                    this.composeTravelAllowancesOfSection.bind(this),
                    true,
                    true,
                ),
            )
            .addContentTabSwitch(
                BusinessTravelSectionsController.TAB_SWITCH_TRAVEL_VOUCHERS,
                this.i18n.get("MobileApp.travels.TabSwitch.travelVouchers"),
                this.activeTabSwitchName ==
                    BusinessTravelSectionsController.TAB_SWITCH_TRAVEL_VOUCHERS,
                this.composeTravelSections(
                    null,
                    this.composeVoucherAllowancesOfSection.bind(this),
                    false,
                    false,
                ),
            )
            .onTabChange(this.tabbedViewChanged.bind(this));

        this.page.addHeaderElement(this.tabbedViewBar);
    }

    private composeTravelSections(
        composeBusinessTravelContent: () => GUIElement,
        composeSectionContent: (sectionNo: number, travelSection: TravelSection) => GUIElement,
        showCreateSection: boolean,
        showDeleteSection: boolean,
    ): GUIElement {
        // Reiseabschnitte an Dienstreise abfragen
        const travelSections = this.businessTravel.getTravelSections();

        // ListView mit einer Row je Reiseabschnitt (Titel und einklappbarer Inhalt)
        const travelSectionsListView = new ListView()
            .setDOMId(showDeleteSection ? "travelSectionsList" : "travelSectionsVouchersList")
            .addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW)
            .addStyleClass("travelBoxLayout");

        // Dienstreise
        if (composeBusinessTravelContent) {
            travelSectionsListView.addRow(
                new ListRow().addContentElement(composeBusinessTravelContent()),
            );
        }

        // Alle Reiseabschnitte durchlaufen ...
        for (let sectionNo = 0; sectionNo < travelSections.length; sectionNo++) {
            // Aktueller Reiseabschnitt
            const travelSection = travelSections[sectionNo];

            // Titel und (später vielleicht einklappbarer) Inhalt
            const travelSectionCollapsable = new Collapsable();

            // Titel eines Reiseabscnitts (Reisezweck, Start/Ende/ Anzahl Belege)
            const travelSectionHeader = new TravelSectionHeader(
                sectionNo,
                travelSections[sectionNo],
                this.i18n,
            );
            this.travelSectionHeaders.push(travelSectionHeader);
            travelSectionCollapsable.addHeaderElement(travelSectionHeader);

            // ...
            travelSectionCollapsable.addContentElement(
                composeSectionContent(sectionNo, travelSection),
            );

            // Reiseabschnitts-Trenner als Row hinzufügen
            if (composeBusinessTravelContent || sectionNo > 0) {
                travelSectionsListView.addRow(
                    new ListRow().addStyleClass("travelsection_separator"),
                );
            }

            // Reiseabschnitts-Titel als Row hinzufügen
            travelSectionsListView.addRow(
                new ListRow()
                    .addContentElement(travelSectionCollapsable.getHeaderElement())
                    .addStyleClass("travelsection")
                    .setDOMId("travelSectionHeader" + sectionNo.toString())
                    .setEntityId(travelSection.getId())
                    .setDeletable(sectionNo > 0),
            );

            // Reiseabschnitt-Inhalt als Row hinzufügen
            travelSectionsListView.addRow(
                new ListRow().addContentElement(travelSectionCollapsable.getConentElement()),
            );
        }

        if (showCreateSection && this.businessTravel.isEditable()) {
            // Reiseabschnitts-Trenner als Row hinzufügen
            travelSectionsListView.addRow(new ListRow().addStyleClass("travelsection_separator"));

            // Link zum Hinzufügen eines weiteren Reiseabschnitts
            travelSectionsListView.addRow(
                new ListRow()
                    .addContentElement(this.composeNewSectionRow(travelSections.length))
                    .onClick(this.newSectionRowClicked.bind(this)),
            );
        }

        if (showDeleteSection) {
            this.listViews[BusinessTravelSectionsController.TAB_SWITCH_TRAVEL_DATA].push(
                travelSectionsListView,
            );
        }

        return travelSectionsListView;
    }

    private composeBusinessTravel(): GUIElement {
        // ListView/Form mit ausgewählten Attributen der Diestreise (Reiseziel, Innerstädtisch/Kein Tagegeld)
        const businessTravelEditListViewContext = new ListViewContext()
            .setI18n(this.i18n)
            .setModel(new AllowancesListViewModel([this.businessTravel], this.i18n));

        let businessTravelListViewDef = this.serverConfigProperties.customizeGUIDefinitions(
            this.allowanceGUIDefinitions.getBusinessTravelListViewDef(),
            BusinessTravelSectionsController.CONTROLLER_PATH,
            "businesstravel",
        );

        if (!this.businessTravel.isEditable()) {
            const stateAddedGUIFieldNames: { [key: string]: string[] } = {};
            stateAddedGUIFieldNames["0"] = [BusinessTravel.STATE];
            businessTravelListViewDef =
                this.serverConfigProperties.applyCustomizationToGUIDefinitions(
                    businessTravelListViewDef,
                    stateAddedGUIFieldNames,
                    [],
                    "display",
                );
        }

        this.businessTravelEditListView = new ListView(
            businessTravelEditListViewContext,
            businessTravelListViewDef,
        )
            .addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW)
            .addStyleClass("travelAllowanceForm")
            .setDOMId("businessTravelForm")
            .onFormFieldChange(this.formFieldChanged.bind(this));

        return this.businessTravelEditListView;
    }

    private composeTravelAllowancesOfSection(
        sectionNo: number,
        travelSection: TravelSection,
    ): GUIElement {
        const listViewContext = new ListViewContext()
            .setI18n(this.i18n)
            .setModel(new BusinessTravelSectionListViewModel([travelSection], this.i18n));

        const travelSectionListViewDef = this.serverConfigProperties.customizeGUIDefinitions(
            this.allowanceGUIDefinitions.getTravelSectionListViewDef(),
            BusinessTravelSectionsController.CONTROLLER_PATH,
            "travelsection",
        );

        const travelAllowancesListView = new ListView(listViewContext, travelSectionListViewDef)
            .addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW)
            .addStyleClass("travelAllowanceForm")
            .setDOMId("travelSectionForm" + sectionNo.toString())
            .onRowClicked(this.listRowClicked.bind(this))
            .onFormFieldChange(this.formFieldChanged.bind(this));

        this.travelEditListViews.push(travelAllowancesListView);

        return travelAllowancesListView;
    }

    private composeVoucherAllowancesOfSection(
        sectionNo: number,
        travelSection: TravelSection,
    ): GUIElement {
        const listViewContext = new ListViewContext()
            .setI18n(this.i18n)
            .setModel(new AllowancesListViewModel(travelSection.getVoucherAllowances(), this.i18n));

        const voucherSectionListView = new ListView(
            listViewContext,
            this.allowanceGUIDefinitions.getSectionVouchersListViewDef(),
        )
            .addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW)
            .addStyleClass("travelAllowanceForm")
            .setDOMId("vouchersList" + sectionNo.toString())
            .onRowClicked(this.listRowClicked.bind(this))
            .onFormFieldChange(this.formFieldChanged.bind(this));

        if (this.businessTravel.isEditable()) {
            const self = this;
            voucherSectionListView.addRow(
                new ListRow()
                    .addContentElement(
                        new TextLabel().setInlineText(
                            this.i18n.get("MobileApp.travel.createVoucherAllowance"),
                        ),
                    )
                    .addStyleClass("create_voucher")
                    .onClick(
                        ((sectionNo) => {
                            return (event) => self.newVoucherAllowanceRowClicked(sectionNo);
                        })(sectionNo),
                    ),
            );
        }

        this.listViews[BusinessTravelSectionsController.TAB_SWITCH_TRAVEL_VOUCHERS].push(
            voucherSectionListView,
        );

        return voucherSectionListView;
    }

    /**
     * Generiert Link zum Hinzufügen eines weiteren Reiseabschnitts.
     * @param countTravelSections Aktuelle Anzahl Reiseabschnitte
     */
    private composeNewSectionRow(countTravelSections: number): GUIElement {
        const sectionHeaderFlexBox = new FlexBox()
            .setJustifyContent(FlexJustifyContent.FLEX_START)
            .setAlignItems(FlexAlignItems.FLEX_START)
            .addStyleClass("create_travelsection");
        sectionHeaderFlexBox.addFlexItem(
            new FlexItem()
                .addContentElement(
                    new TextLabel().setInlineText((countTravelSections + 1).toString()),
                )
                .addStyleClass("create_travelsection_number"),
        );
        sectionHeaderFlexBox.addFlexItem(
            new FlexItem()
                .setAlignSelf(FlexAlignSelf.CENTER)
                .addContentElement(
                    new TextLabel().setInlineText(
                        this.i18n.get("MobileApp.travel.createTravelSection"),
                    ),
                )
                .addStyleClass("create_travelsection_label"),
        );

        return sectionHeaderFlexBox;
    }

    private async composeFooterTabBar(): Promise<void> {
        const self = this;
        this.footerTabBar = new FooterTabBar(new GUIContext(this.i18n));
        await this.footerTabBar.composeDefaultFooter(
            null,
            this.navigator,
            this.getNavigateBackParameters(),
            () => self.syncStateManager.countUnsyncedElements(),
        );
        this.page.addFooterElement(this.footerTabBar);
    }

    /**
     * Aufruf nach Wechsel des Tabs ("Reise" oder "Belege")
     * @param switchName Name des aktiven Tabs
     */
    private tabbedViewChanged(switchName: string): void {
        this.activeTabSwitchName = switchName;

        this.page.scrollTop(0);
    }

    private listRowClicked(path: string, parameters: { [key: string]: string }): void {
        let doNavigateLink = true;

        if (path.endsWith("selection")) {
            // Aufgaben-Auswahl-Seite nur öffnen, wenn Dienstreise noch bearbeitbar
            doNavigateLink = this.businessTravel.isEditable();
        }

        if (doNavigateLink) {
            this.navigator.navigateTo(path, parameters, Animation.SLIDE_LEFT);
        }
    }

    private newVoucherAllowanceRowClicked(sectionNo: number): void {
        const travelSection = this.businessTravel.getTravelSections()[sectionNo];
        const newVoucherAllowance = travelSection.createVoucherAllowance();

        const self = this;
        this.storeBusinessTravel().then(() => {
            self.navigator.navigateTo(
                "voucher",
                {
                    businessTravelOid: self.businessTravel.getId(),
                    voucherAllowanceOid: newVoucherAllowance.getId(),
                    created: "true", // Anzeige, dass Belegspese direkt vorher erzeugt wurde
                },
                Animation.SLIDE_LEFT,
            );
        });
    }

    private newSectionRowClicked(): void {
        this.businessTravel.createTravelSection();
        const scrollToSectionId =
            "travelSectionHeader" + (this.businessTravel.getTravelSections().length - 1).toString();

        this.storeBusinessTravel().then(() => {
            this.animation = Animation.NONE;
            this.composePage(scrollToSectionId);
        });
    }

    private async formFieldChanged(changeEvent: formChangeEventType): Promise<void> {
        this.updateTravelSectionsFormFields(changeEvent);
        await this.storeBusinessTravel();

        // Nach erster Formularfeldänderung, Zähler der zu synchronisierenden Objekt in Footer-Navigation aktualisieren
        if (!this.allowanceWasEdited) {
            this.allowanceWasEdited = true;

            const self = this;
            await this.footerTabBar.updateSyncBadgeCounter(FooterTabBar.ICON_SYNC, () =>
                self.syncStateManager.countUnsyncedElements(),
            );
        }
    }

    private updateTravelSectionsFormFields(changeEvent: formChangeEventType): void {
        // Alle Formularfelder aktualisieren
        const listFieldFeedback = new ListFieldFeedback();
        listFieldFeedback.addFeedback(
            ListFieldFeedbackType.SUCCESS,
            changeEvent.entityId,
            changeEvent.fieldName,
        );

        this.businessTravelEditListView.updateFields(listFieldFeedback);

        this.travelEditListViews.forEach((travelEditListViews) => {
            travelEditListViews.updateFields(listFieldFeedback);
        });

        // Reiseabschnittstitel aktualisieren
        this.travelSectionHeaders.forEach((travelSectionHeader) =>
            travelSectionHeader.updateTitle(),
        );
    }

    private async deleteSelectedRows(selectToBeDeletedIds: string[]): Promise<void> {
        if (
            selectToBeDeletedIds.length > 0 &&
            this.tabbedViewBar.getActiveTabSwitchName() ==
                BusinessTravelSectionsController.TAB_SWITCH_TRAVEL_DATA
        ) {
            selectToBeDeletedIds.forEach((travelSectionId) => {
                const travelSection = this.businessTravel.getSectionById(travelSectionId);
                if (travelSection) {
                    this.businessTravel.deleteTravelSection(travelSection);
                }
            });
            const deleteMessageKey = "MobileApp.travel.message.sectionDeleted";

            await this.storeBusinessTravel();

            this.sectionsListViewDeleteMode.leaveDeleteMode();

            // Erfolgsmeldung nach Löschen von Reiseabschnitten ausgeben.
            const deleteMessageText = this.i18n.formatMessageWithQuantity(
                deleteMessageKey,
                selectToBeDeletedIds.length,
            );
            this.addMessage(new MessageEntity(deleteMessageText, MessageType.AFFIRMATION));

            this.animation = Animation.NONE;
            await this.composePage();
        }
    }

    private storeBusinessTravel(): Promise<void> {
        this.isBusinessTravelCreatedAndNotEdited = false;
        return this.allowanceManager.storeAllowance(this.businessTravel);
    }

    /**
     * Fügt Meldung zum Anzeigen nach Seitenaufbau hinzu.
     * @param message Meldung
     */
    private addMessage(message: MessageEntity): void {
        this.messages.push(message);
    }

    private deleteBusinessTravel(): void {
        this.allowanceManager
            .deleteBusinessTravels([this.businessTravel.getId()])
            .then(() =>
                this.navigator.navigateTo(
                    "travels",
                    { affirmations: "travelDeleted" },
                    Animation.SLIDE_RIGHT,
                ),
            );
    }

    private getNavigateBackParameters(): { [key: string]: string } {
        return {
            navigateBackPath: BusinessTravelSectionsController.CONTROLLER_PATH,
            navigateBackParameters: JSON.stringify({
                oid: this.businessTravel.getId(),
            }),
        };
    }

    public popState(): void {
        if (this.pageDeleteMode && this.pageDeleteMode.isDeleteModeActive()) {
            this.pageDeleteMode.leaveDeleteMode();
        } else if (
            this.sectionsListViewDeleteMode &&
            this.sectionsListViewDeleteMode.isShowDeleteSelectionsActive() &&
            this.sectionsListViewDeleteMode.countSelectToBeDeleted() > 0
        ) {
            this.sectionsListViewDeleteMode.resetDeleteSelections();
        } else if (
            this.sectionsListViewDeleteMode &&
            this.sectionsListViewDeleteMode.isDeleteModeActive()
        ) {
            this.sectionsListViewDeleteMode.leaveDeleteMode();
        } else {
            this.navigateUp();
        }
    }

    private navigateUp(): void {
        if (this.isBusinessTravelCreatedAndNotEdited) {
            // Sofern die Dienstreise mit dem Aufruf der aktuellen Seite erstellt wurde und
            // sie nicht mindestens einmal gespeichert wird, wird sie bei Zurück-Navigation wieder gelöscht.
            this.allowanceManager
                .deleteBusinessTravels([this.businessTravel.getId()])
                .then(() =>
                    this.navigator.navigateTo(
                        "travels",
                        { warnings: "unEditedTravelNotSaved" },
                        Animation.SLIDE_RIGHT,
                    ),
                );
        } else {
            this.navigator.navigateTo("travels", {}, Animation.SLIDE_RIGHT);
        }
    }

    public destroy(): void {
        // Temporäre Lösung um potentiell geöffneten Popup-Kalender-Picker zu schließen
        if (this.page) {
            this.page.leave();
        }

        // Merken, welcher Tab (Reisedaten / Belege zuletzt aktiv war)
        if (this.tabbedViewBar) {
            this.navigator
                .getControllerState()
                .setProperty(
                    BusinessTravelSectionsController.STATE_PROPERTY_TAB_SWITCH,
                    this.tabbedViewBar.getActiveTabSwitchName(),
                );
        }
    }
}

Registry.registerComponent(
    BusinessTravelSectionsController.CONTROLLER_PATH,
    BusinessTravelSectionsController,
);
