import { HttpRESTClient } from "../../../util/http/HttpRESTClient";
import { BCSDate } from "../../../common/BCSDate";
import { Log } from "../../../common/log/Log";

export class BookingsClient {
    public fetchBookings(
        lastBCSSyncStateTimestamp,
    ): Promise<{ bookings: object[]; syncStateTimestamp: number }> {
        return new Promise((resolve, reject) => {
            new HttpRESTClient()
                .createRequest()
                .setMethod("GET")
                .addPath("/timerecording/bookings")
                .setParameter("syncStateTimestamp", lastBCSSyncStateTimestamp)
                .setParameter("minDate", BCSDate.getToday().subtractMonth(1).getISODate())
                .send()
                .then((result) => {
                    switch (result["status"]) {
                        case 200:
                            const syncStateTimestamp: number = result["json"]["syncStateTimestamp"];
                            var resultObject = {
                                bookings: result["json"]["bookings"],
                                syncStateTimestamp: syncStateTimestamp,
                            };
                            resolve(resultObject);
                            break;
                        case 204:
                            resolve(null);
                            break;
                        default:
                            reject(result);
                    }
                })
                .catch(reject);
        });
    }

    public saveBooking(age, transportObject: object): Promise<object> {
        Log.debug("[BookingClient] svaeBooking", transportObject);
        return new Promise((resolve, reject) => {
            new HttpRESTClient()
                .createRequest()
                .setMethod("POST")
                .addPath("/timerecording/saveBooking")
                .setParameter("age", age)
                .setJSONBody(transportObject)
                .send()
                .then((result) => {
                    switch (result["status"]) {
                        case 200:
                            resolve(result["json"]);
                            break;
                        case 204:
                            resolve({});
                            break;
                        default:
                            reject(result);
                    }
                })
                .catch((errorInformations) => {
                    reject(errorInformations);
                });
        });
    }

    public deleteBooking(age, transportObject: object): Promise<object> {
        return new Promise((resolve, reject) => {
            new HttpRESTClient()
                .createRequest()
                .setMethod("POST")
                .addPath("/timerecording/deleteBooking")
                .setParameter("age", age)
                .setJSONBody(transportObject)
                .send()
                .then((result) => {
                    switch (result["status"]) {
                        case 200:
                            resolve(result["json"]);
                            break;
                        case 204:
                            resolve({});
                            break;
                        default:
                            reject(result);
                    }
                })
                .catch((errorInformations) => {
                    reject(errorInformations);
                });
        });
    }

    async readOptionsFromBCS(checkSum: number): Promise<object> {
        const self = this;
        const result = await new HttpRESTClient()
            .createRequest()
            .setMethod("GET")
            .addPath("/timerecording/bookingOptions")
            .setParameter("checkSum", checkSum)
            .send();
        switch (result["status"]) {
            case 200:
                return result["json"] ? result["json"] : null;
            case 204:
                return null;
            default:
                throw new Error(
                    "[BookingsClient] Error Failed to Read the TimeRecording Options: " +
                        result["status"],
                );
        }
    }
}
