import * as d3 from "d3";

export class ProgressArc {
    private innerRadius: number;

    private outerRadius: number;

    private meter: any;

    private arc: any;

    private foreground: any;

    private text: any;

    private currentProgressPercentage = 0;

    constructor(svg: any, arcName: string, subtractRadius: number, showText: boolean) {
        const windowSize = Math.min(window.innerWidth, window.innerHeight);
        this.innerRadius = Math.floor(windowSize / 4) - subtractRadius;
        this.outerRadius = Math.floor(windowSize / 4) - 20 - subtractRadius;

        this.arc = d3
            .arc()
            .startAngle(0)
            .innerRadius(this.innerRadius)
            .outerRadius(this.outerRadius);

        this.meter = svg.append("g").attr("class", "progress-meter");

        this.meter
            .append("path")
            .attr("class", arcName + "_background")
            .attr("d", this.arc.endAngle(2 * Math.PI));

        this.foreground = this.meter.append("path").attr("class", arcName + "_foreground");

        if (showText) {
            this.text = this.meter.append("text").attr("text-anchor", "middle").attr("dy", ".35em");
        }
    }

    public aprogress(progressPercentage: number): void {
        //this.progress(this.currentProgressPercentage / 100);

        //let i = d3.interpolate(this.currentProgressPercentage / 100, progressPercentage / 100);

        this.currentProgressPercentage = progressPercentage;

        this.progress(this.currentProgressPercentage / 100);

        /*
        let self = this;
        d3.transition().tween("progress", function() {
            return function(t) {
                let progress = i(t);
                self.progress(progress);
            };
        });
        */
    }

    public progress(progress: number): void {
        this.foreground.attr("d", this.arc.endAngle(2 * Math.PI * progress));

        if (this.text) {
            const formatPercent = d3.format(".0%");
            this.text.text(formatPercent(progress));
        }
    }

    public hide(): void {
        this.meter.transition().delay(250).attr("transform", "scale(0)");
    }
}
