import { IndexedDBError } from "../IndexedDBError";

export class IndexedDBSelectIndexRequest {
    private storeName: string;
    private transaction: IDBTransaction;
    private indexName: string;
    private indexValue: IDBValidKey;

    constructor(
        transaction: IDBTransaction,
        storeName: string,
        indexName: string,
        indexValue: IDBValidKey,
    ) {
        this.transaction = transaction;
        this.storeName = storeName;
        this.indexName = indexName;
        this.indexValue = indexValue;
    }

    then(successCallback, errorCallback) {
        //BCS.Log.debug("[IndexedDBSelectIndexRequest] select by index: " + this.indexValue);

        const self = this;

        const index = this.transaction.objectStore(this.storeName).index(this.indexName);
        const request = index.get(this.indexValue);
        request.onsuccess = function (event) {
            self.success(event, successCallback);
        };
        request.onerror = function (event) {
            self.error(event, errorCallback);
        };
    }

    private success(event, successCallback) {
        successCallback.call(this, { element: event.target.result });
    }

    private error(event, errorCallback) {
        errorCallback.call(this, new IndexedDBError(event).setIndexValue(this.indexValue));
    }
}
