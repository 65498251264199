import { AppConsole } from "../common/log/AppConsole";
import { Browser } from "../util/common/Browser";
import { IndexedDBError } from "./IndexedDBError";
import { IndexedDBStore } from "./IndexedDBStore";
import { IndexedDBClearRequest } from "./requests/IndexedDBClearRequest";
import { IndexedDBCountRequest } from "./requests/IndexedDBCountRequest";
import { IndexedDBDeleteIdsRequest } from "./requests/IndexedDBDeleteIdsRequest";
import { IndexedDBSelectCursorRequest } from "./requests/IndexedDBSelectCursorRequest";
import { IndexedDBSelectIdRequest } from "./requests/IndexedDBSelectIdRequest";
import { IndexedDBSelectIndexRequest } from "./requests/IndexedDBSelectIndexRequest";
import { IndexedDBUpdateDeleteQueryRequest } from "./requests/IndexedDBUpdateDeleteQueryRequest";
import { IndexedDBUpdateElementsRequest } from "./requests/IndexedDBUpdateElementsRequest";

/**
 * IndexedDB-Transaktion.
 *
 * An der Transaktion können ein oder mehrere Request aufgerufen werden,
 * für deren asynchronen Rückruf mit dem Ergebnis sich er Aufrufer registriert.
 *
 * Es gibt Request speziell für verschiedene Anwendungsfälle (z.B. Objekte per Id oder per Query lesen, Objekte schreiben oder löschen).
 */
export class IndexedDBTransaction {
    private storeNames: string[];

    private dbStoresByName: { [key: string]: IndexedDBStore };

    private readWriteMode: any; //IDBTransactionMode

    private transaction: IDBTransaction;

    constructor(
        db: IDBDatabase,
        storeNames,
        dbStoresByName: { [key: string]: IndexedDBStore },
        readWriteMode: any,
    ) {
        this.storeNames = Array.isArray(storeNames) ? storeNames : [storeNames];
        this.dbStoresByName = dbStoresByName;
        this.readWriteMode = readWriteMode;
        this.transaction = db.transaction(this.storeNames, this.readWriteMode);
    }

    after(successCallback, errorCallback) {
        const self = this;

        this.transaction.oncomplete = function (event) {
            self._error(event, successCallback);
        };
        this.transaction.onabort = function (event) {
            self._abort(event, errorCallback);
        };
        this.transaction.onerror = function (event) {
            self._error(event, errorCallback);
        };
    }

    _success(event, successCallback) {
        successCallback.call(this);
    }

    _abort(event, errorCallback) {
        errorCallback.call(this, new IndexedDBError(event));
    }

    _error(event, errorCallback) {
        AppConsole.log("[IndexedDBTransaction] ERROR", event);

        errorCallback.call(this, new IndexedDBError(event));
    }

    /**
     * Zählt Objekte.
     *
     * @param storeName Store-Name
     * @param indexName Index-Name
     * @param query Query (siehe IndexedDBQuery)
     * @returns IndexedDBCountRequest (zum Registrieren eines Callbacks bei Erfolg bzw. Fehler)
     */
    public count(storeName: string, indexName: string, query: IDBKeyRange): IndexedDBCountRequest {
        return new IndexedDBCountRequest(this.transaction, storeName, indexName, query);
    }

    /**
     * Selektiert ein Objekt mit gegebener Id.
     *
     * @param storeName Store-Name
     * @param id id
     * @returns IndexedDBSelectIdRequest (zum Registrieren eines Callbacks bei Erfolg bzw. Fehler)
     */
    public selectId(storeName: string, id: any): IndexedDBSelectIdRequest {
        return new IndexedDBSelectIdRequest(this.transaction, storeName, id);
    }

    /**
     * Selektiert ein Objekt, bei dem das indexierte Feld den gegebenen Wert hat.
     * @param storeName Store-Name
     * @param indexName Index-Name
     * @param indexValue Wert
     * @returns IndexedDBSelectIndexRequest (zum Registrieren eines Callbacks bei Erfolg bzw. Fehler)
     */
    public selectIndex(
        storeName: string,
        indexName: string,
        indexValue: IDBValidKey,
    ): IndexedDBSelectIndexRequest {
        return new IndexedDBSelectIndexRequest(this.transaction, storeName, indexName, indexValue);
    }

    /**
     * Selektiert Objekte als ResultSet, die zu der Query passen.
     * @param storeName Store-Name
     * @param indexName Index-Name
     * @param query Query (siehe IndexedDBQuery)
     * @returns IndexedDBSelectCursorRequest (zum Registrieren eines Callbacks bei Erfolg bzw. Fehler)
     */
    public selectIdCursor(
        storeName: string,
        indexName: string,
        query: IDBKeyRange,
    ): IndexedDBSelectCursorRequest {
        return this.selectCursor(storeName, indexName, query, true);
    }

    /**
     * Selektiert Objekte oder Ids der Objekte als ResultSet, die zu der Query passen.
     * @param storeName Store-Name
     * @param indexName Index-Name
     * @param query Query (siehe IndexedDBQuery)
     * @param doSelectIdsOnly wenn true werden nur die Ids selektiert
     * @returns IndexedDBSelectCursorRequest (zum Registrieren eines Callbacks bei Erfolg bzw. Fehler)
     */
    public selectCursor(
        storeName: string,
        indexName?: string,
        query?: IDBKeyRange,
        doSelectIdsOnly: boolean = false,
    ): IndexedDBSelectCursorRequest {
        return new IndexedDBSelectCursorRequest(
            this.transaction,
            storeName,
            indexName,
            query,
            doSelectIdsOnly,
        );
    }

    /**
     * Fügt Objekte ein (wenn id noch nicht existiert) oder aktualisiert Objekte (wenn id schon existiert).
     * @param storeName Store-Name
     * @param elements Objekte
     * @returns IndexedDBUpdateElementsRequest (zum Registrieren eines Callbacks bei Erfolg bzw. Fehler)
     */
    public updateElements(storeName: string, elements: object[]): IndexedDBUpdateElementsRequest {
        elements.forEach((element) => {
            if (Browser.isInternetExplorer()) {
                const dbStore = this.dbStoresByName[storeName];

                if (dbStore) {
                    dbStore.getIndexes().forEach((index) => {
                        if (index.keys.length > 1) {
                            const joinedIndexKey = index.keys.join("_");
                            const joinedIndexValue = index.keys
                                .map((key) => element[key])
                                .join("_");

                            element[joinedIndexKey] = joinedIndexValue;
                        }
                    });
                }
            }
        });

        return new IndexedDBUpdateElementsRequest(this.transaction, storeName, elements);
    }

    /**
     * Aktualisiert Objekte, die zu der Query passen mit den gegebenen Werten.
     * @param storeName Store-Name
     * @param indexName Index-Name
     * @param query Query (siehe IndexedDBQuery)
     * @param updateValues Zu setzende Werte
     * @returns IndexedDBUpdateDeleteQueryRequest (zum Registrieren eines Callbacks bei Erfolg bzw. Fehler oder zum Löschen einelner Objekte)
     */
    public updateQuery(
        storeName: string,
        indexName: string,
        query: IDBKeyRange,
        updateValues: object[],
    ): IndexedDBUpdateDeleteQueryRequest {
        return new IndexedDBUpdateDeleteQueryRequest(
            this.transaction,
            storeName,
            indexName,
            query,
        ).updateResult(updateValues);
    }

    /**
     * Löscht Objekte mit gegebener Id.
     * @param storeName Store-Name
     * @param ids Ids
     * @returns IndexedDBDeleteIdsRequest (zum Registrieren eines Callbacks bei Erfolg bzw. Fehler)
     */
    deleteIds(storeName: string, ids: string[]): IndexedDBDeleteIdsRequest {
        return new IndexedDBDeleteIdsRequest(this.transaction, storeName, ids);
    }

    /**
     * Löscht Objekte, die zu der Query passen.
     * @param storeName Store-Name
     * @param indexName Index-Name
     * @param query Query (siehe IndexedDBQuery)
     * @returns IndexedDBUpdateDeleteQueryRequest (zum Registrieren eines Callbacks bei Erfolg bzw. Fehler)
     */
    public deleteQuery(
        storeName: string,
        indexName: string,
        query: IDBKeyRange,
    ): IndexedDBUpdateDeleteQueryRequest {
        return new IndexedDBUpdateDeleteQueryRequest(this.transaction, storeName, indexName, query); //.deleteResult();
    }

    /**
     * Löscht alle Objekte.
     * @param storeName Store-Name
     * @returns IndexedDBClearRequest (zum Registrieren eines Callbacks bei Erfolg bzw. Fehler)
     */
    public clear(storeName: string): IndexedDBClearRequest {
        return new IndexedDBClearRequest(this.transaction, storeName);
    }
}
