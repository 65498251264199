import { I18n } from "../../common/i18n/I18n";
import { FormFieldFactory } from "../form/FormFieldFactory";
import { ListRowModel } from "./ListRowModel";

export class ListRowContext {
    private i18n: I18n;

    private listRowModel: ListRowModel;

    private formFieldFactory: FormFieldFactory;

    public setI18n(i18n: I18n): ListRowContext {
        this.i18n = i18n;
        return this;
    }

    public setModel(listRowModel: ListRowModel): ListRowContext {
        this.listRowModel = listRowModel;
        return this;
    }

    public setFormFieldFactory(formFieldFactory: FormFieldFactory): ListRowContext {
        this.formFieldFactory = formFieldFactory;
        return this;
    }

    public getI18n(): I18n {
        return this.i18n;
    }

    public getRowModel(): ListRowModel {
        return this.listRowModel;
    }

    public getFormFieldFactory(): FormFieldFactory {
        return this.formFieldFactory;
    }
}
