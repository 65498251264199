import { ListViewModel } from "../../../../gui/list/ListViewModel";
import { TravelSection } from "../../records/TravelSection";
import { ListRowModel } from "../../../../gui/list/ListRowModel";
import { BusinessTravelSectionListRowModel } from "./BusinessTravelSectionListRowModel";
import { I18n } from "../../../../common/i18n/I18n";

export class BusinessTravelSectionListViewModel implements ListViewModel {
    private travelSections: TravelSection[];

    private i18n: I18n;

    constructor(travelSections: TravelSection[], i18n: I18n) {
        this.travelSections = travelSections;

        this.i18n = i18n;
    }

    public countRows(): number {
        return this.travelSections.length;
    }

    public getRowModelByNo(rowNo: number): ListRowModel {
        return new BusinessTravelSectionListRowModel(this.travelSections[rowNo], this.i18n);
    }

    public getRowModelById(id: string): ListRowModel {
        for (let i = 0; i < this.travelSections.length; i++) {
            if (this.travelSections[i].getSubAllowanceById(id)) {
                return new BusinessTravelSectionListRowModel(this.travelSections[i], this.i18n);
            }
        }

        throw new Error("[BusinessTravelSectionListViewModel] Allowance not exist: id=" + id);
    }

    public isResultTruncated(): boolean {
        return false;
    }
}
