import { I18n } from "../../common/i18n/I18n";
import { GUIPage } from "../../gui/GUIPage";
import { ListView } from "../../gui/list/ListView";
import { SyncStateManager } from "../SyncStateManager";
import { SyncMessage } from "../SyncMessage";
import { ListRow } from "../../gui/list/ListRow";
import { ListRowCompoundField } from "../../gui/list/ListRowCompoundField";
import { ListRowTextLabel } from "../../gui/list/ListRowTextLabel";
import { SyncStateType, SyncStateObjectType } from "../SyncState";
import { TextLabelContentType } from "../../gui/content/TextLabel";
import { ForecastController } from "../../domain/time_recording/forecast/ForecastController";

/**
 * Liste mit Synchronisationsmeldungen (z.B. 3 Buchungen zu synchronisieren / 3 Buchungen wurden synchronisiert).
 */
export class SyncMessagesListSubController {
    private syncStateManager: SyncStateManager;

    /** Label */
    private i18n: I18n;

    private syncMessages: SyncMessage[];

    private syncMessagesListView: ListView;

    private errorSyncStateRowClickedCallback: (
        path: string,
        parameters?: { [key: string]: string },
    ) => void;

    constructor(syncStateManager: SyncStateManager, i18n: I18n) {
        this.syncStateManager = syncStateManager;
        this.i18n = i18n;
    }

    public async countAllSyncStatesWithChangesInApp(): Promise<void> {
        this.syncMessages = [];

        const countSyncStatesByObjectType =
            await this.syncStateManager.countSyncStatesByObjectType();

        for (const objectType in countSyncStatesByObjectType) {
            const countSyncStatesBySyncStateType = countSyncStatesByObjectType[objectType];

            const numberWithChanesInApp =
                countSyncStatesBySyncStateType[SyncStateType.ChangesInApp];
            const numberWithErrorInObjectInApp =
                countSyncStatesBySyncStateType[SyncStateType.ErrorInObjectInApp];
            const numberWithSynchronisationIssue =
                countSyncStatesBySyncStateType[SyncStateType.SynchronisationIssue];
            const numberWithToBeSync =
                numberWithChanesInApp +
                numberWithErrorInObjectInApp +
                numberWithSynchronisationIssue;

            if (numberWithToBeSync > 0) {
                const typeLabel = this.i18n.get(
                    objectType + ".plural",
                    this.i18n.get("MobileApp.SyncStateObjectType.plural." + objectType),
                );
                let message = "";
                if (numberWithChanesInApp > 0) {
                    message +=
                        this.i18n.get("MobileApp.sync.changesInAppToBeSyncToBCS") +
                        " (" +
                        numberWithChanesInApp +
                        ")";
                }
                if (numberWithErrorInObjectInApp > 0) {
                    if (numberWithChanesInApp > 0) {
                        message += "<br>";
                    }
                    message +=
                        this.i18n.get("MobileApp.sync.errorInObjectInAppToBeSyncToBCS") +
                        " (" +
                        numberWithErrorInObjectInApp +
                        ")";
                }
                if (numberWithSynchronisationIssue > 0) {
                    if (numberWithChanesInApp + numberWithErrorInObjectInApp > 0) {
                        message += "<br>";
                    }
                    message +=
                        this.i18n.get("MobileApp.sync.synchronisationIssuesInAppToBeSyncToBCS") +
                        " (" +
                        numberWithSynchronisationIssue +
                        ")";
                }

                const syncMessage = new SyncMessage(
                    objectType,
                    typeLabel,
                    message,
                    numberWithToBeSync,
                );
                this.syncMessages.push(syncMessage);
            }
        }

        if (this.syncMessages.length == 0) {
            const syncMessage = new SyncMessage(
                "NoChangesInApp",
                this.i18n.get("MobileApp.sync.NoChangesInApp"),
                null,
            );
            this.syncMessages.push(syncMessage);
        }
    }

    /**
     * SyncMessages anzeigen (z.B. "3 Buchungen synchronisiert")
     *
     * @param page Wurzel-Element des GUI-Baums der aktuellen Seite
     */
    public composeSyncMessages(page: GUIPage): void {
        this.syncMessagesListView = new ListView()
            .setDOMId("syncMessages")
            .addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW)
            .setTitle(this.i18n.get("MobileApp.sync.syncMessages"))
            .addStyleClass("firstListView")
            .onRowClicked(this.errorSyncStateRowClicked.bind(this));

        this.appendSyncMessageListRows();

        page.addPageElement(this.syncMessagesListView);
    }

    private appendSyncMessageListRows(): void {
        this.syncMessages.forEach((syncMessage) => {
            this.syncMessagesListView.addRow(
                new ListRow()
                    .setEntityId(syncMessage.getId())
                    .useFieldSetStyle()
                    .addContentElement(
                        new ListRowCompoundField()
                            .setMainContentFields(
                                "NONE",
                                new ListRowTextLabel().setText(syncMessage.getTitle()),
                                new ListRowTextLabel().setText(
                                    syncMessage.getNumber() != 0
                                        ? syncMessage.getNumber().toString()
                                        : null,
                                ),
                            )
                            .addSubContentFields(
                                "NONE",
                                new ListRowTextLabel()
                                    .setText(syncMessage.getMessage())
                                    .setContentType(TextLabelContentType.HTML),
                                null,
                            ),
                    ),
            );
        });
    }

    public onErrorSyncStateRowClicked(
        errorSyncStateRowClickedCallback: (
            path: string,
            parameters?: { [key: string]: string },
        ) => void,
    ): SyncMessagesListSubController {
        this.errorSyncStateRowClickedCallback = errorSyncStateRowClickedCallback;
        return this;
    }

    private errorSyncStateRowClicked(path: string, parameters: { [key: string]: string }): void {
        if (this.errorSyncStateRowClickedCallback) {
            const clickedSyncStateId = parameters["oid"];

            this.syncStateManager.getSyncStateById(clickedSyncStateId).then((clickedSyncState) => {
                let path: string;
                let parameters: { [key: string]: string } = {};

                switch (clickedSyncState.getSyncStateObjectType()) {
                    case SyncStateObjectType.Attendance:
                        path = "timeRecordingDetail";
                        parameters = { oid: clickedSyncState.getId() };
                        break;
                    case SyncStateObjectType.Pause:
                        path = "timeRecordingDetail";
                        parameters = { oid: clickedSyncState.getId() };
                        break;
                    case SyncStateObjectType.Booking:
                        path = "timeRecordingDetail";
                        parameters = { oid: clickedSyncState.getId() };
                        break;
                    case SyncStateObjectType.Forecast:
                        path = ForecastController.CONTROLLER_PATH;
                        parameters = { sync_oid: clickedSyncState.getId() };
                        break;
                    case SyncStateObjectType.Allowance:
                        if (clickedSyncState.getSubtype() != "voucherAllowance") {
                            path = "travel";
                            parameters = { oid: clickedSyncState.getId() };

                            if (clickedSyncState.getErrorObjectOid()) {
                                parameters["openSubAllowanceOid"] =
                                    clickedSyncState.getErrorObjectOid();
                            }
                        } else {
                            path = "voucher";
                            parameters = { voucherAllowanceOid: clickedSyncState.getId() };
                        }
                        break;
                }

                if (path) {
                    this.errorSyncStateRowClickedCallback(path, parameters);
                }
            });
        }
    }
}
