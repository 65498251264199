import { BCSDate } from "../common/BCSDate";

/**
 * Synchronisationsmeldung (z.B. 3 Buchungen zu synchronisieren / 3 Buchungen wurden synchronisiert).
 */
export class SyncMessage {
    private id: string;

    private title: string;

    private message: string;

    private number: number;

    private date: BCSDate;

    constructor(id: string, title: string, message: string, number: number = 0, date?: BCSDate) {
        this.id = id;
        this.title = title;
        this.message = message;
        this.number = number;
        this.date = date;
    }

    public getId(): string {
        return this.id;
    }

    public getTitle(): string {
        return this.title;
    }

    public getMessage(): string {
        return this.message;
    }

    public getNumber(): number {
        return this.number;
    }

    public getDate(): BCSDate {
        return this.date;
    }
}
