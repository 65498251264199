import { GUIElement } from "../GUIElement";
import { FormField, formFieldChangeEventType, FormFieldMode } from "./FormField";
import { StringField } from "./StringField";
import { NumberValue } from "../../entities/values/NumberValue";
import { StringValue } from "../../entities/values/StringValue";
import { FormFieldAddOn } from "./FormFieldAddOn";
import { I18n } from "../../common/i18n/I18n";
import { InputModes } from "./InputModes";

export class NumberField implements GUIElement, FormField {
    // TODO App - Nachkommastellen aus Konfig

    private i18n: I18n;

    private value: NumberValue;

    private stringField: StringField;

    private inputMode: string = InputModes.INT;

    private changeCallback: (changeEvent: formFieldChangeEventType) => void;

    constructor(i18n: I18n) {
        this.i18n = i18n;

        this.stringField = new StringField()
            .setType("number")
            .onChange(this.stringValueChanged.bind(this));
    }

    public setEntityId(entityId: string): FormField {
        this.stringField.setEntityId(entityId);
        return this;
    }

    public setReadOnly(readOnly: boolean): FormField {
        this.stringField.setReadOnly(readOnly);
        return this;
    }

    public isReadOnly(): boolean {
        return this.stringField.isReadOnly();
    }

    public setName(name: string): NumberField {
        this.stringField.setName(name);
        return this;
    }

    public getName(): string {
        return this.stringField.getName();
    }

    public setValue(value: NumberValue): NumberField {
        this.value = value;
        const stringValue = new StringValue(
            value.isDefined() ? value.getNumber().toString() : null,
        );
        this.stringField.setValue(stringValue);
        return this;
    }

    public setLabel(label: string): NumberField {
        this.stringField.setLabel(label);
        return this;
    }

    public setPlaceholder(placeholder: string): NumberField {
        this.stringField.setPlaceholder(placeholder);
        return this;
    }

    public setMode(mode: FormFieldMode): FormField {
        this.stringField.setMode(mode);
        return this;
    }

    public getMode(): FormFieldMode {
        return this.stringField.getMode();
    }

    public registerFormFieldAddOn(formFieldAddOn: FormFieldAddOn): FormField {
        // untetstützt bislang bislang keine AddOns
        return this;
    }

    public onChange(changeCallback: (changeEvent: formFieldChangeEventType) => void): NumberField {
        this.changeCallback = changeCallback;
        return this;
    }

    public compose($parent: JQuery): void {
        if (this.getMode() == FormFieldMode.DISPLAY) {
            if (this.value && this.value.isDefined()) {
                const formattedValue = this.value.format(this.i18n.getLocale("-"));

                const stringValue = new StringValue(formattedValue);
                this.stringField.setValue(stringValue);
            }
        }
        this.stringField.setInputmode(this.inputMode);
        this.stringField.compose($parent);
    }

    public updateValue(value: NumberValue): NumberField {
        const stringValue = new StringValue(
            value.isDefined() ? value.getNumber().toString() : null,
        );
        this.stringField.updateValue(stringValue);
        return this;
    }

    public getValue(): NumberValue {
        return NumberValue.parseFromString(this.stringField.getValue().getString());
    }

    private stringValueChanged(changeEvent: formFieldChangeEventType): void {
        if (this.changeCallback) {
            this.changeCallback({
                entityId: changeEvent.entityId,
                name: this.getName(),
                value: this.getValue(),
            });
        }
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }

    public setInputmode(inputMode: string): NumberField {
        this.inputMode = inputMode;
        return this;
    }
}
