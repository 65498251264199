import "./ContactRecording.less";
import { FooterTabBar } from "../../../../board/FooterTabBar";
import { ServerConfigProperties } from "../../../../common/config/ServerConfigProperties";
import { I18n } from "../../../../common/i18n/I18n";
import { Component } from "../../../../core/Component";
import { Controller } from "../../../../core/Controller";
import { Registry } from "../../../../core/Registry";
import { Animation, AppNavigator } from "../../../../core/Router";
import { formChangeEventType } from "../../../../gui/form/Form";
import { GUIContext } from "../../../../gui/GUIContext";
import { GUIPage } from "../../../../gui/GUIPage";
import { ListFieldFeedback, ListFieldFeedbackType } from "../../../../gui/list/ListFieldFeedback";
import { ListView } from "../../../../gui/list/ListView";
import { ListViewContext } from "../../../../gui/list/ListViewContext";
import { DownloadMode } from "../../../../gui/navigation/DownloadMode";
import { PageDeleteMode } from "../../../../gui/navigation/PageDeleteMode";
import { ToolBar } from "../../../../gui/navigation/ToolBar";
import { ToolLink } from "../../../../gui/navigation/ToolLink";
import { SyncStateManager } from "../../../../sync/SyncStateManager";
import { Contact } from "../../Contact";
import { ContactManager } from "../../ContactManager";
import { VCFFileDownloader } from "../../methods/VCFFileDownloader";
import { ContactGUIDefinitions } from "../ContactGUIDefinitions";
import { ContactListViewModel } from "../ContactListViewModel";
import { Log } from "../../../../common/log/Log";
import { AppConsole } from "../../../../common/log/AppConsole";
import { PreferencesManager } from "../../../../common/preferences/PreferencesManager";

/**
 * Controller für die Formularseite zum Erstellen / Bearbeiten eines Kontaktes. Leitet die Speicherung des Kontaktes ein und übergibt diesen ggf. an den Downloader.
 */
export class ContactRecordController implements Controller {
    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static BCS_COMPONENT_NAME = "record";

    private navigator: AppNavigator;

    private animation: Animation;

    private i18n: I18n;

    private syncStateManager: SyncStateManager;

    private page: GUIPage;

    private contactListView: ListView;

    private footerTabBar: FooterTabBar;

    private contactManager: ContactManager;

    private contactGUIDefinitions: ContactGUIDefinitions;

    private serverConfigProperties: ServerConfigProperties;

    private contact: Contact;

    private deleteLink: PageDeleteMode;

    private preferencesManager: PreferencesManager;

    private downloadLink: DownloadMode;

    private fileDownloader: VCFFileDownloader = new VCFFileDownloader();

    private contactWasEdited = false;

    private oid: string;

    constructor() {}

    public getDependencyNames(): string[] {
        return [
            I18n.BCS_COMPONENT_NAME,
            ContactManager.BCS_COMPONENT_NAME,
            SyncStateManager.BCS_COMPONENT_NAME,
            ServerConfigProperties.BCS_COMPONENT_NAME,
            PreferencesManager.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }): void {
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.contactManager = <ContactManager>(
            depencencyComponents[ContactManager.BCS_COMPONENT_NAME]
        );
        this.syncStateManager = <SyncStateManager>(
            depencencyComponents[SyncStateManager.BCS_COMPONENT_NAME]
        );
        this.serverConfigProperties = <ServerConfigProperties>(
            depencencyComponents[ServerConfigProperties.BCS_COMPONENT_NAME]
        );
        this.contactGUIDefinitions = new ContactGUIDefinitions();

        this.serverConfigProperties = <ServerConfigProperties>(
            depencencyComponents[ServerConfigProperties.BCS_COMPONENT_NAME]
        );
        this.preferencesManager = <PreferencesManager>(
            depencencyComponents[PreferencesManager.BCS_COMPONENT_NAME]
        );
    }

    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        const oid = parameters["oid"];
        this.navigator = navigator;
        this.animation = animation;

        if (!this.contactManager.getContactTerms().isContactRecordingAllowed()) {
            this.navigator.navigateTo("index", {}, Animation.SLIDE_RIGHT);
        }
        try {
            if (typeof oid !== "undefined" && oid) {
                this.contactManager.readContactFromDB(oid).then(
                    (contact) => {
                        this.contact = contact;
                        this.composeList().catch(console.error);
                    },
                    (reason) => {
                        Log.error(reason);
                        this.navigator.navigateTo("contactList", {}, Animation.SLIDE_RIGHT);
                    },
                );
                this.oid = oid;
            } else {
                this.contact = this.contactManager.createContact();
                this.composeList().catch(console.error);
            }
        } catch (loadingErr) {
            Log.error("Error while Opening Contact Creation Page => " + loadingErr);
            this.navigateUpAfterDeleted();
        }
    }

    public popState(): void {
        this.navigateUpToList().then((prom) =>
            AppConsole.debug("Promise from NavigateUpToList:" + prom),
        );
    }

    public destroy(): void {
        // nichts zu tun
    }

    private composeHeaderToolBar(page: GUIPage): ToolBar {
        let title: string;
        if (typeof this.oid !== "undefined" && this.oid) {
            title = "MobileApp.Contacts.Edit";
        } else {
            title = "MobileApp.Contacts.Record";
        }
        const headerToolBar = new ToolBar()
            .setId("header_toolbar")
            .setTitle(this.i18n.get(title))
            .addStyleClass("headBar")
            .addToolLinkLeft(
                new ToolLink()
                    .setId("navigateBack")
                    .setImageName("icon-chevron-left.svg")
                    .onClick(this.popState.bind(this)),
            );
        page.addHeaderElement(headerToolBar);

        return headerToolBar;
    }

    private async composeList(): Promise<void> {
        this.page = new GUIPage(
            new GUIContext(this.i18n),
            ContactRecordController.BCS_COMPONENT_NAME,
        );

        const headerToolBar = this.composeHeaderToolBar(this.page);
        if (typeof this.oid !== "undefined" && this.oid) {
            // hole ich mir über den Preferences Manager, da es angedacht ist, das lokal überschreiben zu können
            if (this.preferencesManager.downloadEnabled()) {
                this.composeDownloadButton(this.page, headerToolBar);
            }
            try {
                await this.syncStateManager.getSyncStateById(this.contact.getId());
                if (!this.contact.getValue(Contact.SYNC_YEAR).isDefined()) {
                    this.composeDeleteLink(this.page, headerToolBar);
                }
            } catch (composingErr) {
                Log.error("Error while Composing the Page => " + composingErr);
                this.navigateUpAfterDeleted();
            }
        }

        this.composeRecordList();
        await this.composeFooterTabBar();

        this.page
            .setAnimation(this.animation, this.navigator.doShowAnimations())
            .compose($("body"));
    }

    private composeRecordList(): void {
        const listViewContext = new ListViewContext()
            .setI18n(this.i18n)
            .setModel(new ContactListViewModel(this.contact, this.i18n));

        const contactListViewDef = this.serverConfigProperties.customizeGUIDefinitions(
            this.contactGUIDefinitions.getRecordListViewDef(),
            ContactRecordController.BCS_COMPONENT_NAME,
            "recordList",
        );
        this.contactListView = new ListView(listViewContext, contactListViewDef)
            .setDOMId("record")
            .addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW)
            .onRowClicked(this.listRowClicked.bind(this))
            .onFormFieldChange(this.formFieldChanged.bind(this));
        this.page.addPageElement(this.contactListView);
    }

    /**
     * Fußzeile (u.a. mit Home-Link)
     */
    private async composeFooterTabBar(): Promise<void> {
        const self = this;
        this.footerTabBar = new FooterTabBar(new GUIContext(this.i18n));
        await this.footerTabBar.composeDefaultFooter(
            ContactRecordController.BCS_COMPONENT_NAME,
            this.navigator,
            this.getNavigateBackParameters(),
            () => self.syncStateManager.countUnsyncedElements(),
        );
        this.page.addFooterElement(this.footerTabBar);
    }

    private async listRowClicked(
        path: string,
        parameters: { [key: string]: string },
    ): Promise<void> {
        // wenn man den Kontakt klickt und der Klick auf eine Unterseite führt (aktuell nur bei der @link ContactOuCountrySelection der Fall), muss der Kontakt gespeichert werden, damit die Unterseite die Werte setzen kann
        await this.storeContactAndUpdateSyncState();
        const oid: string = this.contact.getId();
        this.navigator.navigateTo(path, parameters, Animation.SLIDE_LEFT);
    }

    private async formFieldChanged(changeEvent: formChangeEventType): Promise<void> {
        this.updateView();
        this.updateFormFields(changeEvent);
        await this.storeContactAndUpdateSyncState();
    }

    private async storeContactAndUpdateSyncState(): Promise<void> {
        await this.contactManager.storeContact(this.contact);
        // Nach erster Formularfeldänderung, Zähler der zu synchronisierenden Objekte in Footer-Navigation aktualisieren
        if (!this.contactWasEdited) {
            // Attribut setzen, sodass der Kontakt beim Zurücknavigieren nochmal gespeichert wird. Sonst könnte die Änderung in einem zweiten Feld verloren gehen, wenn nicht aus dem Feld heraus geklickt wird.
            this.contactWasEdited = true;
            const self = this;
            await this.footerTabBar.updateSyncBadgeCounter(FooterTabBar.ICON_SYNC, () =>
                self.syncStateManager.countUnsyncedElements(),
            );
        }
    }

    private updateFormFields(changeEvent: formChangeEventType): void {
        // Alle Formularfelder aktualisieren
        const listFieldFeedback = new ListFieldFeedback();
        listFieldFeedback.addFeedback(
            ListFieldFeedbackType.SUCCESS,
            changeEvent.entityId,
            changeEvent.fieldName,
        );
        this.contactListView.updateFields(listFieldFeedback);
    }

    private async navigateUpToList(): Promise<void> {
        if (this.contactWasEdited) {
            await this.contactManager.storeContact(this.contact);
        }

        this.navigator.navigateTo("contactList", {}, Animation.SLIDE_RIGHT);
    }

    private getNavigateBackParameters(): { [key: string]: string } {
        return {
            navigateBackPath: ContactRecordController.BCS_COMPONENT_NAME,
            navigateBackParameters: JSON.stringify({ oid: this.contact.getId() }),
        };
    }

    private composeDeleteLink(page: GUIPage, headerToolBar: ToolBar): void {
        this.deleteLink = new PageDeleteMode()
            .setPage(page)
            .setHeaderToolBar(headerToolBar)
            .setDeleteButtonLabel(this.i18n.get("MobileApp.Contacts.Record.Delete"))
            .onDelete(() => {
                this.contactManager
                    .deleteContact(this.contact)
                    .then(() => this.navigateUpAfterDeleted());
            });
    }

    private composeDownloadButton(page: GUIPage, headerToolBar: ToolBar): void {
        this.downloadLink = new DownloadMode()
            .setPage(page)
            .setHeaderToolBar(headerToolBar)
            .setDownloadButtonLabel(this.i18n.get("MobileApp.Contacts.Record.Download"))
            .onDownload(() => {
                this.fileDownloader.createFileFromContact(this.contact);
                this.downloadLink.leaveDownloadMode();
            });
    }

    private navigateUpAfterDeleted() {
        this.navigator.navigateTo("contactList", {}, Animation.SLIDE_RIGHT);
    }

    /**
     * Blendet etwaige Download- oder Löschenmeldungen aus, wenn eine Zeile bearbeitet wird.
     * @private
     */
    private updateView(): void {
        if (this.downloadLink && this.downloadLink.isActive()) {
            this.downloadLink.leaveDownloadMode();
        }

        if (this.deleteLink && this.deleteLink.isActive()) {
            this.deleteLink.leaveDeleteMode();
        }
    }
}

Registry.registerComponent(ContactRecordController.BCS_COMPONENT_NAME, ContactRecordController);
