import { GUIElement } from "../GUIElement";
import { TextLabel, TextLabelContentType } from "../content/TextLabel";
import { ListRowContext } from "./ListRowContext";
import { ListField } from "./ListField";
import { ListFieldFeedback, ListFieldFeedbackType } from "./ListFieldFeedback";

export class ListRowTextLabel implements GUIElement, ListField {
    private listRowContext: ListRowContext;

    private labelKey: string;

    private directText: string;

    private contentType: TextLabelContentType = TextLabelContentType.TEXT;

    private styleClass: string = "";

    /**
     * @param listViewContext ListView-Kontext (I18n, Erzeugen von FormFields, ...)
     * @param listFieldSetRowDefinition Definition dieser ListFieldSet-Komponente als JSON ausgelesen aus einer Konfiguration
     */
    constructor(listRowContext?: ListRowContext, listRowFieldDefinition?: object) {
        this.listRowContext = listRowContext;

        if (listRowFieldDefinition) {
            this.appyDefinition(listRowFieldDefinition);
        }
    }

    private appyDefinition(listRowFieldDefinition: object): void {
        this.labelKey = listRowFieldDefinition["label"];
        this.directText = listRowFieldDefinition["directText"];

        this.contentType =
            listRowFieldDefinition["contentType"] == "html"
                ? TextLabelContentType.HTML
                : TextLabelContentType.TEXT;

        if (typeof listRowFieldDefinition["styleClass"] !== "undefined") {
            this.styleClass = listRowFieldDefinition["styleClass"];
        }
    }

    public setLabelKey(labelKey: string): ListRowTextLabel {
        this.labelKey = labelKey;
        return this;
    }

    public setText(text: string): ListRowTextLabel {
        this.directText = text;
        return this;
    }

    public setContentType(contentType: TextLabelContentType): ListRowTextLabel {
        this.contentType = contentType;
        return this;
    }

    public setStyleClass(styleClass: string): ListRowTextLabel {
        this.styleClass = styleClass;
        return this;
    }

    public compose($parent: JQuery): void {
        const textLabel = new TextLabel()
            .addStyleClass(this.styleClass)
            .setContentType(this.contentType);

        if (typeof this.labelKey !== "undefined") {
            textLabel.setInlineText(this.listRowContext.getI18n().get(this.labelKey));
        } else {
            textLabel.setInlineText(this.directText);
        }

        textLabel.compose($parent);
    }

    public onUpdateValues(
        updateValuesCallback: (feedbackType: ListFieldFeedbackType) => void,
    ): void {
        // nichts zu tun
    }

    public updateValues(listFieldFeedback: ListFieldFeedback): void {
        // nichts zu tun
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}
