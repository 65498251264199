import { IndexedDBError } from "../IndexedDBError";
import { IndexedDBSelectCursorRequest } from "./IndexedDBSelectCursorRequest";

export class IndexedDBUpdateDeleteQueryRequest {
    private storeName: string;
    private transaction: IDBTransaction;
    private query: IDBKeyRange;
    private indexName: string;
    private updateValues: any[];
    private countUpdated: number;
    private countDeleted: number;
    private doDeleteResult: boolean;

    constructor(
        transaction: IDBTransaction,
        storeName: string,
        indexName: string,
        query: IDBKeyRange,
    ) {
        this.transaction = transaction;
        this.storeName = storeName;
        this.indexName = indexName;
        this.query = query;
        this.countUpdated = 0;
        this.countDeleted = 0;
    }

    public updateResult(updateValues) {
        this.updateValues = updateValues || [];
        this.countUpdated = 0;
        return this;
    }

    public deleteResult() {
        this.doDeleteResult = true;
        this.countDeleted = 0;
        return this;
    }

    public then(successCallback, errorCallback) {
        const self = this;

        const selectRequest = new IndexedDBSelectCursorRequest(
            this.transaction,
            this.storeName,
            this.indexName,
            this.query,
        );
        selectRequest.each(this.updateDeleteEachElement.bind(this)).then(
            function (event) {
                self.success(event, successCallback);
            },
            function (event) {
                self.error(event, errorCallback);
            },
        );
    }

    public updateDeleteEachElement(result) {
        let element = result.element;
        const cursor = result.cursor;

        if (this.updateValues) {
            this.countUpdated++;

            element = $.extend(true, element, this.updateValues);
            cursor.updateElement(element);
        } else if (this.deleteResult) {
            this.countDeleted++;

            cursor.deleteElement();
        }
    }

    private success(event, successCallback) {
        successCallback.call(this, {
            countUpdated: this.countUpdated,
            countDeleted: this.countDeleted,
        });
    }

    private error(event, errorCallback) {
        errorCallback.call(this, new IndexedDBError(event).setQuery(this.query));
    }
}
