import { Component } from "../core/Component";
import { IndexedDBStore } from "./IndexedDBStore";
import { IndexedDBOpenRequest } from "./requests/IndexedDBOpenRequest";
import { IndexedDBDestroyRequest } from "./requests/IndexedDBDestroyRequest";
import { Registry } from "../core/Registry";
import { IndexedDBConnection } from "./IndexedDBConnection";
import { SingletonComponent } from "../core/SingletonComponent";

declare let window: any;

export class IndexedDB implements Component, SingletonComponent {
    public static BCS_COMPONENT_NAME = "IndexedDB";

    public static DATABASE_NAME = "bcsapp";

    public static PROPERTIES_STORE_NAME = "properties";

    public static OBJECT_KEY_ID = "id";

    /**
     * Ganzzahlige Versionsnummer der IndexedDB (keine floats wie 1.2)
     * Neue Stores in neuen BCS-Versionen werden mit der nächsten Versionsnummer erstellt.
     * Je Datenbank-Version wird ein Update-Callback zum Erstellen von Stores aufgerufen (siehe IndexedDBStore).
     */
    private dbVersionNumber: number = 0;

    private storeNamesbyDB = {};

    private storesbyDB = {};

    private connections = {};

    private openDBNameQueue: string[];

    constructor() {
        if (!window.indexedDB) {
            window.indexedDB = window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
        }
        if (!window.IDBTransaction) {
            window.IDBTransaction = window.webkitIDBTransaction || window.msIDBTransaction;
        }
        if (!window.IDBKeyRange) {
            window.IDBKeyRange = window.webkitIDBKeyRange || window.msIDBKeyRange;
        }
    }

    getDependencyNames(): string[] {
        return [];
    }

    init(depencencyComponents: { [key: string]: Component }) {}

    public isStoreRegistered(storeName: string, dbName: string = IndexedDB.DATABASE_NAME) {
        const storeNames = this.storeNamesbyDB[dbName] || (this.storeNamesbyDB[dbName] = []);
        return storeNames.indexOf(storeName) >= 0;
    }

    public registerStore(
        storeName: string,
        dbVersion: number,
        dbName: string = IndexedDB.DATABASE_NAME,
    ) {
        const storeNames = this.storeNamesbyDB[dbName] || (this.storeNamesbyDB[dbName] = []);

        storeNames.push(storeName);

        const store = new IndexedDBStore(storeName, this).setDBVersion(dbVersion);
        const stores = this.storesbyDB[dbName] || (this.storesbyDB[dbName] = []);
        stores.push(store);

        this.dbVersionNumber = Math.max(this.dbVersionNumber, dbVersion);

        return store;
    }

    /**
     * Die Version bildet das Maximum der regisiteren Stores und deren einzelnen Operationen, wie addIndex.
     * Daher können diese Steps/Operationen auch ihre Version einreichen.
     * Verwendet wird die höchste, die im System gefunden wurde.
     *
     * Ist wichtig, damit die Datenbank erkennt, dass ihre Version upgeraded werden muss.
     * Darauf hin werden die benötigsten UpdateSteps getriggert.
     *
     * @param dbVersion Datenbank Version der Operation.
     */
    public setMaxVersionFromChildrenSteps(dbVersion: number) {
        this.dbVersionNumber = Math.max(this.dbVersionNumber, dbVersion);
    }

    public start(): Promise<void> {
        this.openDBNameQueue = Object.keys(this.storesbyDB);

        const self = this;
        return new Promise((resolve, reject) => {
            self.openNextConnection(resolve, reject);
        });
    }

    public notifyBeginUserSession(isOnline: boolean): Promise<void> {
        return Promise.resolve();
    }

    public synchronize(): Promise<void> {
        return Promise.resolve();
    }

    private openNextConnection(resolve, reject): void {
        const self = this;
        if (this.openDBNameQueue.length > 0) {
            const dbName = this.openDBNameQueue.pop();

            const openRequest = new IndexedDBOpenRequest(
                dbName,
                this.dbVersionNumber,
                this.storesbyDB[dbName],
            );
            openRequest.then(
                (result) => {
                    self.connections[result.dbName] = result.connection;
                    self.openNextConnection(resolve, reject);
                },
                (error) => {
                    reject(error);
                },
            );
        } else {
            resolve();
        }
    }

    public getConnection(dbName: string = IndexedDB.DATABASE_NAME): IndexedDBConnection {
        const connection = this.connections[dbName];
        if (!connection) {
            throw new Error(
                "[IndexedDB] database not registered: " +
                    dbName +
                    " (DBs=" +
                    Object.keys(this.connections) +
                    ")",
            );
        }
        return connection;
    }

    public destroyDatabase(dbName: string = IndexedDB.DATABASE_NAME) {
        this.getConnection(dbName).close();
        delete this.connections[dbName];
        return new IndexedDBDestroyRequest(dbName);
    }
}

Registry.registerSingletonComponent(IndexedDB.BCS_COMPONENT_NAME, IndexedDB);
