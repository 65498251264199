export class ArrayUtil {
    public static contains(anArray, anElement) {
        let result = false;
        for (let i = 0; i < anArray.length; i++) {
            if (anArray[i] == anElement) {
                result = true;
                break;
            }
        }
        return result;
    }
}
