import { ToolBar } from "../navigation/ToolBar";
import { I18n } from "../../common/i18n/I18n";
import { ListView } from "./ListView";
import { GUIPage } from "../GUIPage";
import { FloatingActionButton } from "../navigation/FloatingActionButton";
import { BottomActionButton } from "../navigation/BottomActionButton";

export class ListViewDeleteMode {
    private i18n: I18n;

    private emptySelectionLabel: string;

    private deleteSingleSelectionLabel: string;

    private deleteMultipleSelectionLabel: string;

    private page: GUIPage;

    private headerToolBar: ToolBar;

    private listViews: ListView[] = [];

    private addButton: FloatingActionButton;

    private deleteActionButton: BottomActionButton;

    private deleteModeActive = false;

    private showDeleteSelectionsActive = false;

    private selectCheckAllowCallback: () => boolean;

    private deleteCallback: (selectToBeDeletedIds: string[]) => void;

    public setI18n(i18n: I18n): ListViewDeleteMode {
        this.i18n = i18n;

        if (!this.emptySelectionLabel) {
            this.emptySelectionLabel = this.i18n.get("MobileApp.DeleteActionButton.emptySelection");
        }
        if (!this.deleteSingleSelectionLabel) {
            this.deleteSingleSelectionLabel = this.i18n.get(
                "MobileApp.DeleteActionButton.deleteSelectedElements",
            );
        }
        if (!this.deleteMultipleSelectionLabel) {
            this.deleteMultipleSelectionLabel = this.i18n.get(
                "MobileApp.DeleteActionButton.deleteSelectedElements",
            );
        }

        return this;
    }

    public setEmptySelectionLabel(emptySelectionLabel: string): ListViewDeleteMode {
        this.emptySelectionLabel = emptySelectionLabel;
        return this;
    }

    public setDeleteSingleSelectionLabel(deleteSingleSelectionLabel: string): ListViewDeleteMode {
        this.deleteSingleSelectionLabel = deleteSingleSelectionLabel;
        return this;
    }

    public setDeleteMultipleSelectionLabel(
        deleteMultipleSelectionLabel: string,
    ): ListViewDeleteMode {
        this.deleteMultipleSelectionLabel = deleteMultipleSelectionLabel;
        return this;
    }

    public setPage(page: GUIPage): ListViewDeleteMode {
        this.page = page;
        return this;
    }

    public setHeaderToolBar(headerToolBar: ToolBar): ListViewDeleteMode {
        this.headerToolBar = headerToolBar;
        return this;
    }

    public setListView(listView: ListView): ListViewDeleteMode {
        this.listViews = [listView];
        return this;
    }

    public setListViews(listViews: ListView[]): ListViewDeleteMode {
        this.listViews = listViews;
        return this;
    }

    public setAddButton(addButton: FloatingActionButton): ListViewDeleteMode {
        this.addButton = addButton;
        return this;
    }

    public onSelectCheckAllow(selectCheckAllowCallback: () => boolean): ListViewDeleteMode {
        this.selectCheckAllowCallback = selectCheckAllowCallback;
        return this;
    }

    public onDelete(deleteCallback: (selectToBeDeletedIds: string[]) => void): ListViewDeleteMode {
        this.deleteCallback = deleteCallback;
        return this;
    }

    public enterDeleteMode(): ListViewDeleteMode {
        this.headerToolBar.enterDeleteMode(
            this.i18n.get("MobileApp.ToolLink.SelectAll"),
            this.leaveDeleteMode.bind(this),
            this.toggleAllToBeDeleted.bind(this),
        );

        this.listViews.forEach((listView) => {
            listView.onRowSelectDeleted(this.listRowSelectDeleted.bind(this));
            listView.enterDeleteMode();
        });

        if (this.addButton) {
            this.addButton.hide();
        }

        this.deleteActionButton = new BottomActionButton()
            .setEmptySelectionLabel(this.emptySelectionLabel)
            .setDeleteSelectionLabel(this.deleteMultipleSelectionLabel)
            .onClick(this.deleteSelectedRows.bind(this));
        this.page.addFooterElement(this.deleteActionButton);

        this.deleteModeActive = true;

        return this;
    }

    public showDeleteSelections(): ListViewDeleteMode {
        this.listViews.forEach((listView) => {
            listView.onRowSelectDeleted(this.listRowSelectDeleted.bind(this));
            listView.enterDeleteMode();
        });

        this.showDeleteSelectionsActive = true;

        return this;
    }

    public resetDeleteSelections(): ListViewDeleteMode {
        if (this.deleteActionButton) {
            this.listViews.forEach((listView) => listView.leaveDeleteMode());
            this.listViews.forEach((listView) => listView.enterDeleteMode());

            this.page.removeFooterElement(this.deleteActionButton);
            this.deleteActionButton.remove();
            this.deleteActionButton = null;

            if (this.addButton) {
                this.addButton.show();
            }
        }

        return this;
    }

    public isDeleteModeActive(): boolean {
        return this.deleteModeActive;
    }

    public isShowDeleteSelectionsActive(): boolean {
        return this.showDeleteSelectionsActive;
    }

    public countSelectToBeDeleted(): number {
        return this.listViews
            .map((listView) => listView.countSelectToBeDeleted())
            .reduce((totalSelected, countSelect) => totalSelected + countSelect, 0);
    }

    private toggleAllToBeDeleted(): void {
        this.listViews.forEach((listView) => listView.toggleAllToBeDeleted());
        this.listRowSelectDeleted();
    }

    private listRowSelectDeleted(): void {
        if (this.selectCheckAllowCallback && !this.selectCheckAllowCallback()) {
            this.listViews.forEach((listView) => listView.leaveDeleteMode());
            this.listViews.forEach((listView) => listView.enterDeleteMode());
            return;
        }

        const countSelectToBeDeleted = this.countSelectToBeDeleted();

        if (this.showDeleteSelectionsActive) {
            if (countSelectToBeDeleted > 0) {
                if (!this.deleteActionButton) {
                    if (this.addButton) {
                        this.addButton.hide();
                    }

                    this.deleteActionButton = new BottomActionButton().onClick(
                        this.deleteSelectedRows.bind(this),
                    );
                    this.page.addFooterElement(this.deleteActionButton);
                }

                this.deleteActionButton.setDeleteSelectionLabel(
                    countSelectToBeDeleted == 1
                        ? this.deleteSingleSelectionLabel
                        : this.deleteMultipleSelectionLabel,
                );
                this.deleteActionButton.notifyCountSelectedElements(countSelectToBeDeleted);
            } else {
                if (this.deleteActionButton) {
                    this.page.removeFooterElement(this.deleteActionButton);
                    this.deleteActionButton.remove();
                    this.deleteActionButton = null;

                    if (this.addButton) {
                        this.addButton.show();
                    }
                }
            }
        } else {
            this.headerToolBar.notifyCountSelectedElements(countSelectToBeDeleted);
            this.deleteActionButton.notifyCountSelectedElements(countSelectToBeDeleted);
        }
    }

    public leaveDeleteMode(): ListViewDeleteMode {
        this.headerToolBar.leaveDeleteMode();

        this.listViews.forEach((listView) => listView.leaveDeleteMode());

        this.page.removeFooterElement(this.deleteActionButton);
        this.deleteActionButton.remove();
        this.deleteActionButton = null;

        if (this.addButton) {
            this.addButton.show();
        }

        this.deleteModeActive = false;

        return this;
    }

    private deleteSelectedRows(): void {
        if (this.deleteCallback) {
            let selectToBeDeletedIds = [];
            this.listViews.forEach(
                (listView) =>
                    (selectToBeDeletedIds = selectToBeDeletedIds.concat(
                        listView.getSelectedIdsToBeDeleted(),
                    )),
            );
            this.deleteCallback(selectToBeDeletedIds);
        }
    }
}
