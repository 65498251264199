import { GUIElement } from "../GUIElement";
import { GUIContext } from "../GUIContext";
import { ConfigNode } from "../../core/Config/ConfigNode";
import { BCSDate } from "../../common/BCSDate";
import { I18n } from "../../common/i18n/I18n";
import { FlexBox, FlexJustifyContent } from "../flexbox/FlexBox";
import { HTMLContent } from "./HTMLContent";
import { TimeRecordingTacho } from "./TimeRecordingTacho";
import { TimeRecordingOptions } from "../../domain/time_recording/TimeRecordingOptions";

export class DaySelector implements GUIElement {
    public static readonly COMPONENT_NAME: string = "DaySelector";

    private changeDayCallback: Function;

    private configNode: ConfigNode;
    private context: GUIContext;

    private i18n: I18n;

    private shownDate: BCSDate;

    private parent: JQuery;

    private tacho: TimeRecordingTacho;
    private timeRecordingOptions: TimeRecordingOptions;
    private useAttendances: boolean;

    constructor(
        configNode: ConfigNode,
        guiContext: GUIContext,
        timeRecordingOptions: TimeRecordingOptions,
        useAttendances: boolean,
    ) {
        this.configNode = configNode;
        this.context = guiContext;

        if (typeof this.context !== "undefined") {
            this.i18n = this.context.getI18n();
        }

        this.shownDate = BCSDate.getToday();
        this.timeRecordingOptions = timeRecordingOptions;
        this.useAttendances = useAttendances;
    }

    public compose($parent: JQuery): void {
        this.parent = $parent;
        let headerDatePatternKey = "DD.MM."; //this.configNode.getAttribute("I18nHeaderDatePattern",  "MM - YYYY");
        let datePrefix = "";
        if (this.shownDate.isToday()) {
            datePrefix = this.i18n.get("MobileApp.today") + ", ";
        } else if (this.shownDate.isYesterday()) {
            datePrefix = this.i18n.get("MobileApp.yesterday") + ", ";
        } else if (this.shownDate.isTomorrow()) {
            datePrefix = this.i18n.get("MobileApp.tomorrow") + ", ";
        } else {
            headerDatePatternKey = "dddd, DD.MM.";
        }

        const headerMonthSelectorWrapper: JQuery = $(
            "<div class='selectorHeadDate'>" +
                datePrefix +
                this.shownDate.format(headerDatePatternKey, this.i18n.getLocale()) +
                "</div>",
        );
        const timeTacho = headerMonthSelectorWrapper.append("<div class='tacho'></div>");

        this.tacho = new TimeRecordingTacho(
            this.configNode.getChildByTagOrEmpty("TimeRecordingTacho"),
            this.context,
            this.timeRecordingOptions,
            this.useAttendances,
            this.shownDate,
            TimeRecordingTacho.Layout.Overview,
        );
        this.tacho.compose(timeTacho);

        const selectorHeadFlexBox = new FlexBox()
            .addStyleClass("selectorFlexBox")
            .setJustifyContent(FlexJustifyContent.SPACE_BETWEEN);

        // Pfeil nach links für den vorherigen Monat
        const calendarArrowLeft = $("<div class='selectorArrowLeft'></div>");
        calendarArrowLeft.on("click", this.changeShownDayViaOffset.bind(this, -1));
        // Pfeil nach rechts für den nächsten Monat
        const leftBeforeMonth = selectorHeadFlexBox.newFlexItem().setFlexBasis("20%");
        leftBeforeMonth.addContentElement(new HTMLContent(calendarArrowLeft));

        const headerDateLine = new HTMLContent();
        headerDateLine.setContent(headerMonthSelectorWrapper);
        const centerMonthAndYear = selectorHeadFlexBox.newFlexItem().setFlexBasis("60%");
        centerMonthAndYear.addContentElement(headerDateLine);

        const calendarArrowRight = $("<div class='selectorArrowRight'></div>");
        calendarArrowRight.on("click", this.changeShownDayViaOffset.bind(this, 1));
        const rightNextMonth = selectorHeadFlexBox.newFlexItem().setFlexBasis("20%");
        rightNextMonth.addContentElement(new HTMLContent(calendarArrowRight));

        selectorHeadFlexBox.compose($parent);
    }

    public changeShownDayViaOffset(monthOffset): void {
        this.shownDate.addDays(monthOffset);
        this.parent.find(".selectorFlexBox").empty();
        if (typeof this.changeDayCallback === "undefined") {
            this.compose(this.parent);
        } else {
            if (this.changeDayCallback) {
                this.changeDayCallback();
            }
        }
    }

    public getShownDate(): BCSDate {
        return this.shownDate;
    }

    public setShownDate(showDate: BCSDate) {
        this.shownDate = showDate;
    }

    public addOnChangeDayListener(changeDayCallback: Function): DaySelector {
        this.changeDayCallback = changeDayCallback;
        return this;
    }

    getComponentChildren(): GUIElement[] {
        return [this.tacho];
    }
}
