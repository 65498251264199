import { Image } from "../content/Image";
import { GUIElement } from "../GUIElement";
import { ListField } from "./ListField";
import { ListFieldFeedback, ListFieldFeedbackType } from "./ListFieldFeedback";
import { ListRowContext } from "./ListRowContext";
import { ListRowElementFactory } from "./ListRowElementFactory";

type ListRowFieldTableCell = {
    // Definition einer ListField-Komponente
    field: GUIElement;
};

type ListRowFieldTableRow = {
    // Icon vor dem ListRow-Feld (optional), um Art der Eingabe zu symbolisieren, z.B. Uhrzeit, Betrag, Ort
    icon: string;
    // Definition der ListRowFieldTable-Zellen
    columns: ListRowFieldTableCell[];
};

export class ListRowFieldTable implements GUIElement, ListField {
    private listRowContext: ListRowContext;

    private tableIconName: string;

    private tableStyleClass: string = "";

    private tableRows: ListRowFieldTableRow[] = [];

    private fieldElements: ListField[] = [];

    /**
     * @param listViewContext ListView-Kontext (I18n, Erzeugen von FormFields, ...)
     * @param listFieldSetRowDefinition Definition dieser ListFieldSet-Komponente als JSON ausgelesen aus einer Konfiguration
     */
    constructor(listRowContext: ListRowContext, listRowFieldDefinition?: object) {
        this.listRowContext = listRowContext;

        if (listRowFieldDefinition) {
            this.applyDefinition(listRowFieldDefinition);
        }
    }

    private applyDefinition(listRowFieldDefinition: object): void {
        const self = this;

        this.tableIconName = listRowFieldDefinition["icon"];
        if (typeof listRowFieldDefinition["styleClass"] !== "undefined") {
            this.tableStyleClass = listRowFieldDefinition["styleClass"];
        }

        const tableRowDefinitions = listRowFieldDefinition["rows"];
        if (tableRowDefinitions) {
            tableRowDefinitions.forEach((tableRowDefinition) => {
                const tableCells: ListRowFieldTableCell[] = [];

                const tableColumnDefinitions = tableRowDefinition["columns"];
                if (tableColumnDefinitions) {
                    tableColumnDefinitions
                        .filter((tableCellDefinition) => !tableCellDefinition["disabled"])
                        .forEach((tableCellDefinition) => {
                            const fieldElement = ListRowElementFactory.createRowRowElement(
                                this.listRowContext,
                                tableCellDefinition,
                            );
                            this.fieldElements.push(fieldElement);

                            const tableCell = { field: fieldElement };

                            if (tableCellDefinition["styleClass"]) {
                                tableCell["styleClass"] = tableCellDefinition["styleClass"];
                            }
                            tableCells.push(tableCell);
                        });
                }

                const tableRow = {
                    icon: tableRowDefinition["icon"],
                    columns: tableCells,
                };

                this.tableRows.push(tableRow);
            });
        }
    }

    public compose($parent: JQuery): void {
        const $tableTag = $("<table>")
            .appendTo($parent)
            .addClass("listRowFieldTable " + this.tableStyleClass);

        for (let rowNo = 0; rowNo < this.tableRows.length; rowNo++) {
            const tableRow = this.tableRows[rowNo];
            const tableRowColumns = tableRow["columns"];

            const $rowTag = $("<tr>").appendTo($tableTag);

            this.composeIcon(rowNo, this.tableRows.length, tableRow, $rowTag);

            tableRowColumns.forEach((tableColumn) => this.composeCell(tableColumn, $rowTag));
        }
    }

    private composeIcon(rowNo: number, countRows: number, tableRow: object, $rowTag: JQuery): void {
        let iconName = null;
        let iconRowSpan = 1;
        // Icon vor Tabelle / vor allen Zeilen?
        if (this.tableIconName && rowNo == 0) {
            iconName = this.tableIconName;
            iconRowSpan = countRows;
        }
        // Icon vor diese Zeile?
        if (tableRow["icon"]) {
            const iconName = tableRow["icon"];
        }
        if (iconName) {
            const $iconCellTag = $("<td>")
                .appendTo($rowTag)
                .addClass("icon")
                .attr("rowspan", iconRowSpan);

            new Image()
                .setImageName(iconName + ".svg") // TODO App - +".svg" später entfernen
                .compose($iconCellTag);
        }
    }

    private composeCell(tableColumn: object, $rowTag: JQuery): void {
        const $cellTag = $("<td>")
            .appendTo($rowTag)
            .addClass(tableColumn["styleClass"] || "");

        const fieldElement = tableColumn["field"];
        fieldElement.compose($cellTag);
    }

    public updateValues(listFieldFeedback: ListFieldFeedback): void {
        this.fieldElements.forEach((fieldElement) => fieldElement.updateValues(listFieldFeedback));
    }

    public onUpdateValues(
        updateValuesCallback: (feedbackType: ListFieldFeedbackType) => void,
    ): void {
        this.fieldElements.forEach((fieldElement) =>
            fieldElement.onUpdateValues(updateValuesCallback),
        );
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}
