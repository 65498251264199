import { I18n } from "../../../../common/i18n/I18n";
import { HTMLContent } from "../../../../gui/content/HTMLContent";
import { TextLabel } from "../../../../gui/content/TextLabel";
import {
    FlexAlignItems,
    FlexBox,
    FlexDirection,
    FlexJustifyContent,
} from "../../../../gui/flexbox/FlexBox";
import { GUIElement } from "../../../../gui/GUIElement";
import { contactSummaryType } from "../../ContactManager";

export class ContactElement implements GUIElement {
    public static BCS_COMPONENT_NAME = "ContactElement";

    private i18n: I18n;
    private contactSummary: contactSummaryType;

    constructor(contactSummary: contactSummaryType, i18n: I18n) {
        this.i18n = i18n;
        this.contactSummary = contactSummary;
    }

    compose($parent: JQuery<HTMLElement>, parentElement?: GUIElement): void {
        if (this.contactSummary.contactRecordingAvailable) {
            this.showContactElement($parent);
        } else {
            $parent.parent().addClass("disabled");
        }
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }

    private showContactElement($parent: JQuery): void {
        const contactLabel: string = this.i18n.get("MobileApp.Contacts.Element");
        const containerFlexBox = new FlexBox()
            .setFlexDirection(FlexDirection.COLUMN)
            .setAlignItems(FlexAlignItems.CENTER)
            .addStyleClass("contactBox")
            .setDOMId("contactBoardElement");

        containerFlexBox
            .newFlexItem()
            .addContentElement(
                new TextLabel()
                    .setInlineText(contactLabel)
                    .adjustFontSizeRegardingLabelLengh(35, "medium"),
            );
        containerFlexBox
            .newFlexItem()
            .addContentElement(
                new TextLabel()
                    .setInlineText(this.numberOfContacts())
                    .adjustFontSizeRegardingLabelLengh(35, "medium")
                    .addStyleClass("contactCounterLabel"),
            );
        containerFlexBox.compose($parent);
    }

    private numberOfContacts(): string {
        const labelMultiple: string = this.i18n.get("MobileApp.Contacts.CounterMultiple");
        const labelSingle: string = this.i18n.get("MobileApp.Contacts.CounterSingle");
        const number: number = this.contactSummary.contactNumber;
        if (number == 1) {
            return number + " " + labelSingle;
        } else {
            return number + " " + labelMultiple;
        }
    }
}
