import { HttpRESTClient } from "../../util/http/HttpRESTClient";
import { LogLevel } from "./LogLevel";

export class LogClient {
    public fetchLogLevelAndSendLogs(logEntries: object[]): Promise<object> {
        const self = this;
        const logSettings = {};

        return new Promise((resolve, reject) => {
            self.fetchLogLevel()
                .then((result) => {
                    logSettings["level"] =
                        result["status"] == 200 ? result["json"]["level"] : LogLevel.ERROR;
                })
                .then(() => {
                    self.postLogs(logEntries);
                })
                .then(() => {
                    resolve(logSettings);
                })
                .catch(reject);
        });
    }

    private fetchLogLevel(): Promise<object> {
        return new HttpRESTClient().createRequest().setMethod("GET").addPath("log/level").send();
    }

    private postLogs(logEntries: object[]): Promise<object> {
        return new HttpRESTClient()
            .createRequest()
            .setMethod("POST")
            .addPath("log/logs")
            .setJSONBody(logEntries)
            .send();
    }
}
