import { TimeRecord } from "./TimeRecord";
import { TimeValue } from "../../entities/values/TimeValue";
import { DurationValue } from "../../entities/values/DurationValue";
import { TimeAttibutesDefinitions } from "./TimeAttibutesDefinitions";

export const enum TimeChanged {
    START = "start",
    END = "end",
    DURATION = "duration",
}

export class TimeRuleOfThree {
    private static readonly UNDEFINED: number = -1;

    /**
     * Prüft, ob das Verhältnis aus Startzeit, Endzeit und Dauer gegeben ist
     * @param start - Die Startzeit der Buchung
     * @param end - Das Ende der Buchung
     * @param duration - Buchungsdauer
     * @param timeChanged - was wurde grad geändert
     * @param timerecord - Die gesamte Buchung
     */
    public static maintainRuleOfThree(
        start: number,
        end: number,
        duration: number,
        timeChanged: TimeChanged,
        timerecord: TimeRecord,
    ): boolean {
        const hasStartTime: boolean = start !== this.UNDEFINED && !isNaN(start) && start !== null;
        const hasEndTime: boolean = end !== this.UNDEFINED && !isNaN(end) && end !== null;
        const hasDuration: boolean =
            duration !== this.UNDEFINED && !isNaN(duration) && duration !== null;

        if (timeChanged === TimeChanged.START) {
            // Wenn Ende gesetzt ist, dann passen wir die Dauer an.
            if (hasEndTime) {
                // Um zu vermeiden, dass die Duration negativ wird, wenn man versucht eine Buchung in eine Zeit nach der Endzeit der Buchung zu verschieben.
                // Gibt es diesen Sonderfall, wir passen dann doch die Endzeit an, da wir vermuten, dass der Benutzer einfach nur die Buchung verschieben wollte.
                // Die Endzeit kann er in jedemfall nachach noch anpassen.
                if (hasDuration && end < start) {
                    end = TimeRuleOfThree.changeEndTime(start, duration, timerecord);
                } else {
                    duration = TimeRuleOfThree.changeDurationTime(start, end, timerecord);
                }
            } else if (hasDuration) {
                end = TimeRuleOfThree.changeEndTime(start, duration, timerecord);
            }
        } else if (timeChanged === TimeChanged.END) {
            if (hasStartTime) {
                duration = TimeRuleOfThree.changeDurationTime(start, end, timerecord);
            } else if (hasDuration) {
                start = TimeRuleOfThree.changeStartTime(end, duration, timerecord);
            }
        } else if (timeChanged === TimeChanged.DURATION) {
            if (hasStartTime) {
                end = TimeRuleOfThree.changeEndTime(start, duration, timerecord);
            } else if (hasEndTime) {
                start = TimeRuleOfThree.changeStartTime(end, duration, timerecord);
            }
        }
        let isValid: boolean = false;

        isValid = hasDuration || (hasStartTime && hasEndTime);
        if (isValid) {
            if (hasDuration && hasStartTime && hasEndTime) {
                // RFR für Ticket #127390: Es soll auch Möglich sein, Buchungen ab / bis Mitternacht zu erfassen. Für diesen Zweck muss Start / Ende auch = 0 sein dürfen. Die Dauer darf aber trotzdem nur >0 sein
                // Daher auch das start > end am Ende weggenommen, da es ja auch sein kann, dass Start zB 23 ist und end 00
                isValid = duration > 0 && start >= 0 && end >= 0 && end < 24 * 60;
            } else if (hasDuration) {
                isValid = duration > 0;
            } else if (hasStartTime && hasEndTime) {
                // RFR für Ticket #127390: Es soll auch Möglich sein, Buchungen ab / bis Mitternacht zu erfassen. Für diesen Zweck muss Start / Ende auch = 0 sein dürfen. Die Dauer darf aber trotzdem nur >0 sein
                isValid = duration > 0 && start >= 0 && end >= 0 && end < 24 * 60;
            }
        }
        return isValid;
    }

    private static changeEndTime(start: number, duration: number, timerecord: TimeRecord): number {
        const timeAttibutesDefinitions: TimeAttibutesDefinitions =
            timerecord.getTimeAttibutesDefinitions();
        const minutes: TimeValue = TimeValue.fromMinutes(start + duration);
        timerecord.setValue(
            timeAttibutesDefinitions.getEnd(),
            TimeValue.fromMinutes(start + duration),
        );
        return minutes.getTimeInMinutes();
    }

    private static changeStartTime(end: number, duration: number, timerecord: TimeRecord): number {
        const timeAttibutesDefinitions: TimeAttibutesDefinitions =
            timerecord.getTimeAttibutesDefinitions();
        // RFR für Ticket #127390: Es soll auch Möglich sein, Buchungen ab / bis Mitternacht zu erfassen. Für diesen Zweck muss die Startzeit bei Ende == 00:00 aber aus 24-DAUER gerechnet werden, nicht aus 00 - DAUER
        if (end.valueOf() == 0) {
            end = 1440;
        }
        const minutes: TimeValue = TimeValue.fromMinutes(end - duration);
        timerecord.setValue(timeAttibutesDefinitions.getStart(), minutes);
        return minutes.getTimeInMinutes();
    }

    private static changeDurationTime(start: number, end: number, timerecord: TimeRecord): number {
        const timeAttibutesDefinitions: TimeAttibutesDefinitions =
            timerecord.getTimeAttibutesDefinitions();
        // RFR für Ticket #127390: Es soll auch Möglich sein, Buchungen ab / bis Mitternacht zu erfassen. Für diesen Zweck muss die Dauer bei Ende == 00:00 aber aus 1440-START gerechnet werden, nicht aus 00 - START
        // 24*60=1440
        if (end.valueOf() == 0) {
            end = 1440;
        }
        const minutes: DurationValue = new DurationValue(end - start);
        timerecord.setValue(timeAttibutesDefinitions.getDuration(), minutes);
        return minutes.getDurationInMinutes();
    }
}
