import { UserSession } from "../../../common/auth/UserSession";
import { I18n } from "../../../common/i18n/I18n";
import { AppConsole } from "../../../common/log/AppConsole";
import { Log } from "../../../common/log/Log";
import { ApplicationProperties } from "../../../common/properties/ApplicationProperties";
import { DomainSyncState } from "../../../common/properties/DomainSyncState";
import { Schema } from "../../../common/schema/Schema";
import { Component } from "../../../core/Component";
import { MessageEntity, MessageType } from "../../../core/Message/MessageEntity";
import { Registry } from "../../../core/Registry";
import { SingletonComponent } from "../../../core/SingletonComponent";
import { IndexedDB } from "../../../database/IndexedDB";
import { SyncState, SyncStateObjectType, SyncStateType } from "../../../sync/SyncState";
import { SyncStateManager } from "../../../sync/SyncStateManager";
import { ForecastRecord } from "./ForecastRecord";
import { ForecastRecordPool } from "./ForecastRecordPool";
import { ServerConfigProperties } from "../../../common/config/ServerConfigProperties";

export type forecastSummaryType = { forecastAvailable: boolean };

export class ForecastManager implements Component, SingletonComponent {
    public static BCS_COMPONENT_NAME = "ForecastManager";

    private static SYNC_STATE_PROPERTY_KEY = "ForecastSyncState";

    private schema: Schema;

    private userSession: UserSession;

    private applicationProperties: ApplicationProperties;

    private i18n: I18n;

    private forecastRecordPool: ForecastRecordPool;

    private syncStateManager: SyncStateManager;

    private indexedDB: IndexedDB;

    private forecastsDomainSyncState: DomainSyncState;

    private serverConfigProperties: ServerConfigProperties;

    getDependencyNames(): string[] {
        return [
            Schema.BCS_COMPONENT_NAME,
            UserSession.BCS_COMPONENT_NAME,
            ApplicationProperties.BCS_COMPONENT_NAME,
            I18n.BCS_COMPONENT_NAME,
            SyncStateManager.BCS_COMPONENT_NAME,
            IndexedDB.BCS_COMPONENT_NAME,
            ServerConfigProperties.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }): void {
        this.schema = <Schema>depencencyComponents[Schema.BCS_COMPONENT_NAME];
        this.userSession = <UserSession>depencencyComponents[UserSession.BCS_COMPONENT_NAME];
        this.applicationProperties = <ApplicationProperties>(
            depencencyComponents[ApplicationProperties.BCS_COMPONENT_NAME]
        );
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.syncStateManager = <SyncStateManager>(
            depencencyComponents[SyncStateManager.BCS_COMPONENT_NAME]
        );
        this.indexedDB = <IndexedDB>depencencyComponents[IndexedDB.BCS_COMPONENT_NAME];
        this.serverConfigProperties = <ServerConfigProperties>(
            depencencyComponents[ServerConfigProperties.BCS_COMPONENT_NAME]
        );
        this.forecastRecordPool = new ForecastRecordPool(
            this.indexedDB,
            this.applicationProperties,
            this.schema,
        );
    }

    public start(): Promise<void> {
        return Promise.resolve();
    }
    /**
     * Aufruf, wenn nach Start der App der Benutzer eingeloggt und online ist.
     *
     * @param isOnline
     */
    public notifyBeginUserSession(isOnline: boolean): Promise<void> {
        return Promise.resolve();
    }

    public synchronize(): Promise<void> {
        return this.doSynchronize();
    }

    public async sendForecastRecord(forecast: ForecastRecord): Promise<MessageEntity> {
        const isOnline = this.userSession.isOnline();
        if (isOnline) {
            return this.forecastRecordPool
                .writeForecastRecordToBCSServer(forecast)
                .then(async () => {
                    const syncState: SyncState = SyncState.fromForecast(
                        this.userSession.getCurrentUserOid(),
                        forecast,
                        SyncStateType.ChangesInApp,
                    );
                    syncState.markSyncSucess();
                    await this.forecastRecordPool.writeForecastRecordToDB(forecast);
                    await this.syncStateManager.writeSyncStateToDB(syncState);
                    return new MessageEntity(
                        this.i18n.get("MobileApp.forecasts.message.savedOnServer"),
                        MessageType.AFFIRMATION,
                    );
                })
                .catch(async () => {
                    // Fehler-Fall:
                    const syncState: SyncState = SyncState.fromForecast(
                        this.userSession.getCurrentUserOid(),
                        forecast,
                        SyncStateType.ChangesInApp,
                    );
                    syncState.markSyncError(
                        "errorSendingForeCastToBCS",
                        this.i18n.get("MobileApp.forecasts.message.errorSendingForeCastToBCS"),
                    );
                    await this.forecastRecordPool.writeForecastRecordToDB(forecast);
                    await this.syncStateManager.writeSyncStateToDB(syncState);
                    return new MessageEntity(
                        this.i18n.get("MobileApp.forecasts.message.errorSendingForeCastToBCS"),
                        MessageType.ERROR,
                    );
                });
        } else {
            // Offline Fall:
            const syncState: SyncState = SyncState.fromForecast(
                this.userSession.getCurrentUserOid(),
                forecast,
                SyncStateType.ChangesInApp,
            );
            syncState.markChanged(true);
            await this.forecastRecordPool.writeForecastRecordToDB(forecast);
            await this.syncStateManager.writeSyncStateToDB(syncState);

            return new MessageEntity(
                this.i18n.get("MobileApp.forecasts.message.syncPending"),
                MessageType.SYNC,
            );
        }
    }

    public async getForecastById(forecastOid: string): Promise<ForecastRecord> {
        return this.forecastRecordPool.getForecastById(forecastOid);
    }

    async fetchForecastSummary(): Promise<forecastSummaryType> {
        const available: boolean = await this.isForecastAvailable();
        return { forecastAvailable: available };
    }

    private async doSynchronize(): Promise<void> {
        await this.tryToSynUnSyncedForecasts();
        await this.readDomainSyncStateFromDatabase();
        // Wenn die ForecastRecords erfolgreich auf dem Server gespeichert wurden, brauchen wir sie in der App nicht mehr.
        await this.cleanupForecastsAndSyncStatesIfSent();
    }

    private async readDomainSyncStateFromDatabase(): Promise<void> {
        this.forecastsDomainSyncState = await DomainSyncState.fetchFromApplicationProperties(
            ForecastManager.SYNC_STATE_PROPERTY_KEY,
            this.applicationProperties,
            this.userSession.getCurrentUserOid(),
        );
    }

    private async tryToSynUnSyncedForecasts(): Promise<any[]> {
        let syncStates: SyncState[] = [];
        syncStates = syncStates.concat(
            await this.syncStateManager.readSyncAllStatesToBeSentToBCS(
                SyncStateObjectType.Forecast,
            ),
        );

        const promises = [];

        AppConsole.debug("[ForecastRecordPool] tryToSynUnSyncedForecasts", syncStates);
        syncStates.forEach((syncState: SyncState) => {
            promises.push(
                new Promise<void>(async (resolve) => {
                    await this.forecastRecordPool
                        .getForecastById(syncState.getId())
                        .then(async (forecast: ForecastRecord) => {
                            // TODO: über sinnvollere Prüfung nachdenken. Aktuell können wir gar nichts invalides eingeben
                            const isValidAppSide = true;
                            if (isValidAppSide) {
                                await this.sendForecastRecord(forecast)
                                    .then(() => {
                                        Log.debug(
                                            "[ForecastRecordPool] - tryToSynUnSyncedForecasts stored forecast",
                                            forecast,
                                        );
                                        resolve();
                                    })
                                    .catch((error) => {
                                        Log.error(
                                            "[ForecastRecordPool] Error while store forecast on server:" +
                                                JSON.stringify(syncState.getId()),
                                            forecast,
                                            error,
                                        );
                                        resolve();
                                    });
                            }
                        })
                        .catch((error) => {
                            Log.error(
                                "[ForecastRecordPool] Error while reading forecast vom mobile db:" +
                                    JSON.stringify(syncState.getId()),
                                syncState,
                                error,
                            );
                            resolve();
                        });
                }),
            );
        });

        return Promise.all(promises);
    }

    private async cleanupForecastsAndSyncStatesIfSent(): Promise<void> {
        let forecastSyncStates: SyncState[] = [];
        forecastSyncStates = forecastSyncStates.concat(
            await this.syncStateManager.readAllSyncStates(SyncStateObjectType.Forecast),
        );

        forecastSyncStates.forEach((syncState: SyncState) => {
            if (
                syncState.getSyncStateType() === SyncStateType.NoChangesInApp &&
                !syncState.isNew()
            ) {
                this.forecastRecordPool.deleteForecastRecordFromDB(syncState.getId());
                this.syncStateManager.deleteSyncState(syncState.getId());
            }
        });

        // Einen echten SyncStateTimestamp haben wir nicht. Den gibts sonst vom Server wenn man Daten zum synchronisieren anfragt.
        // Das machen wir aber bei Forecasts nicht, weil die nur an den Server gesendet aber nicht abgefragt werden.
        // Deshalb zählen wir hier einfach hoch.
        return this.forecastsDomainSyncState.setSyncStateTimestamp(
            this.forecastsDomainSyncState.getSyncStateTimestamp() + 1,
        );
    }

    private async isForecastAvailable(): Promise<boolean> {
        let license: boolean = true; // Wenn wir uns nicht ganz sicher sind, dass er die Restaufwandsschätzung bzgl. der Liznez NICHT aufrufen darf gehen wir lieber erstmal davon aus.
        const enabled: boolean = this.serverConfigProperties.forecastEstimationEnabled();
        await this.forecastRecordPool
            .getBookingTerms(this.userSession.getCurrentUserOid())
            .then((term) => {
                if (
                    term.getUserLicenses().includes("License_AttendanceRecording") ||
                    term.getUserLicenses().includes("License_TicketCustomer")
                ) {
                    license = false;
                }
            });
        return enabled && license;
    }
}

Registry.registerSingletonComponent(ForecastManager.BCS_COMPONENT_NAME, ForecastManager);
