import { Component } from "../../core/Component";
import { Registry } from "../../core/Registry";
import { SingletonComponent } from "../../core/SingletonComponent";
import { HTTPError } from "../../util/http/HTTPError";
import { AppConsole } from "../log/AppConsole";
import { Log } from "../log/Log";
import { ApplicationProperties } from "../properties/ApplicationProperties";
import { LoginClient } from "./LoginClient";
import { OIDCLogoutClient } from "./oauth/OIDCLogoutClient";

/**
 * Repräsentiert die aktuelle Session,
 * enthält Informationen zum Online/Offline-Status, zum eingeloggten Benutzer, ...
 */
export class UserSession implements Component, SingletonComponent {
    /** Name der Singleton-Komponente */
    public static BCS_COMPONENT_NAME = "UserSession";

    private applicationProperties: ApplicationProperties;

    /** Eigenschaften der Session (siehe   AppUserSession#composeSessionUserData) */
    private currentSession: object;

    /** Eingeloggter Benutzer mit ausgewählten Attributen (siehe AppUserSession#SEND_USER_ATTRIBUTES) */
    private currentUser: object;

    private isInOfflineMode: boolean = false;

    public getDependencyNames(): string[] {
        return [ApplicationProperties.BCS_COMPONENT_NAME];
    }

    public init(depencencyComponents: { [key: string]: Component }) {
        this.applicationProperties = <ApplicationProperties>(
            depencencyComponents[ApplicationProperties.BCS_COMPONENT_NAME]
        );
    }

    public start(): Promise<void> {
        const self = this;
        return new Promise((resolve, reject) => {
            this.applicationProperties
                .readProperty(UserSession.BCS_COMPONENT_NAME, null, null)
                .then((currentSessionData) => {
                    Log.info("[UserSession] start", currentSessionData);

                    if (currentSessionData) {
                        self.currentSession = currentSessionData["session"];
                        self.currentUser = currentSessionData["user"];
                    }
                    resolve();
                })
                .catch(reject);
        });
    }

    public notifyBeginUserSession(isOnline: boolean): Promise<void> {
        return Promise.resolve();
    }

    public synchronize(): Promise<void> {
        return Promise.resolve();
    }

    public isLoggedIn(): boolean {
        return !!this.currentUser;
    }

    public getCurrentUser(): object {
        return this.currentUser;
    }

    public getCurrentUserOid(): string {
        return this.currentUser["oid"];
    }

    public getCurrentUserLanguage(): string {
        return this.currentUser["lang"];
    }

    public getCurrentUserCountry(): string {
        return this.currentUser["ouCountry"];
    }

    public isOnline(): boolean {
        return !this.isInOfflineMode;
    }

    public isOffline(): boolean {
        return this.isInOfflineMode;
    }

    public setToOnlineMode(): void {
        this.isInOfflineMode = false;
    }

    public setToOfflineMode(): void {
        this.isInOfflineMode = true;
    }

    public login(username: string, password: string): Promise<object | void> {
        const self = this;
        if (this.isOnline()) {
            return new LoginClient().login(username, password).then((currentSessionData) => {
                AppConsole.log("[UserSession] Login Accepted", currentSessionData.user["oid"]);

                self.currentSession = currentSessionData["session"];
                self.currentUser = currentSessionData["user"];

                return self.applicationProperties.writeProperty(
                    UserSession.BCS_COMPONENT_NAME,
                    null,
                    null,
                    currentSessionData,
                );
            });
        } else {
            return new Promise((resolve, reject) => {
                self.applicationProperties
                    .readProperty(UserSession.BCS_COMPONENT_NAME, null, null)
                    .then((currentSessionData) => {
                        AppConsole.log("[UserSession] OFFLINE Login Accepted");
                        AppConsole.log(currentSessionData);
                        if (username == currentSessionData["user"].userLogin) {
                            self.currentSession = currentSessionData["session"];
                            self.currentUser = currentSessionData["user"];
                            resolve();
                        } else {
                            reject(
                                new HTTPError("offlineandWrongUser", "offlineandWrongUser", {}, -1),
                            );
                        }
                    });
            });
        }
    }

    public verifyOAuthAuthentification(): Promise<object | void> {
        const self = this;
        return new LoginClient().verifyOAuthAuthentification().then((currentSessionData) => {
            AppConsole.log("[UserSession] Login Accepted", currentSessionData.user["oid"]);

            self.currentSession = currentSessionData["session"];
            self.currentUser = currentSessionData["user"];

            return this.applicationProperties.writeProperty(
                UserSession.BCS_COMPONENT_NAME,
                null,
                null,
                currentSessionData,
            );
        });
    }

    /**
     * Loggt User in BCS aus und beendet lokale Session.
     * @param doClearSiteData wenn true sollen alle Offline-Daten der App gelöscht werden
     * @returns true wenn OAuth Logout auf eine andere Seite redirected hat
     */
    public async logout(doClearSiteData = false): Promise<boolean> {
        const oauthLogoutData = await new OIDCLogoutClient().getLogoutData();
        await Promise.all([
            new LoginClient().logout(doClearSiteData),
            this.terminateLocalSession(),
        ]);
        if (oauthLogoutData && oauthLogoutData.needsRedirect) {
            window.location.replace(oauthLogoutData.redirectURI);
            return true;
        }
        return false;
    }

    /**
     * Beendet lokale Session (damit nächster Aufruf weiß, dass nicht mehr eingeloggt).
     */
    public async terminateLocalSession(): Promise<void> {
        this.currentUser = null;
        await this.applicationProperties.removeProperty(UserSession.BCS_COMPONENT_NAME, null, null);
    }
}

Registry.registerSingletonComponent(UserSession.BCS_COMPONENT_NAME, UserSession);
