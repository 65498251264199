import * as d3 from "d3";
import { GUIElement } from "../../../gui/GUIElement";
import { OnRenderBehavior } from "../../../gui/OnRenderBehavior";
import { ConfigNode } from "../../../core/Config/ConfigNode";
import { GUIContext } from "../../../gui/GUIContext";
import { I18n } from "../../../common/i18n/I18n";
import { Image } from "../../../gui/content/Image";

export class AttendanceClockButtons implements GUIElement, OnRenderBehavior {
    private imageName: string = null;

    private text: string = null;

    private styleClass: string = null;

    private clickAttendanceCallback: Function;
    private clickPauseCallback: Function;

    private guiElements: GUIElement[] = [];

    private $SVGButtonTag: JQuery = null;

    private configNode: ConfigNode;
    private context: GUIContext;
    private i18n: I18n;

    private svgRoot;

    constructor(configNode?: ConfigNode, guiContext?: GUIContext) {
        this.context = guiContext;
        this.configNode = configNode;
        if (typeof this.context !== "undefined") {
            this.i18n = this.context.getI18n();
        }
    }

    public setImageName(imageName: string): AttendanceClockButtons {
        this.imageName = imageName;
        return this;
    }

    public setText(text: string): AttendanceClockButtons {
        this.text = text;
        return this;
    }

    public setStyleClass(styleClass: string): AttendanceClockButtons {
        this.styleClass += " " + styleClass;
        return this;
    }

    public onAttendanceClick(clickCallback: Function): AttendanceClockButtons {
        this.clickAttendanceCallback = clickCallback;
        return this;
    }

    public onPauseClick(clickCallback: Function): AttendanceClockButtons {
        this.clickPauseCallback = clickCallback;
        return this;
    }

    /**
     * HTML-DOM generieren
     *
     * @param $parent Parent-jQuery-Objekt
     */
    public compose($parent: JQuery): void {
        /*
        this.$SVGButtonTag = $("<a>")
            .appendTo($parent)
            .addClass("AttendanceClockButtons "+this.styleClass)
            .css("cursor", "pointer")
            .on("click", this.notifyClicked.bind(this));
        */

        if (this.imageName) {
            new Image().setImageName(this.imageName).compose(this.$SVGButtonTag);
        }

        this.svgRoot = d3
            .select($parent[0])
            .append("svg")
            .attr("width", "230px")
            .attr("height", "35px")
            .attr("xmlns", "http://www.w3.org/2000/svg")
            .attr("xmlns:xlink", "http://www.w3.org/1999/xlink")
            .attr("class", "svgButton");
        const defs = this.svgRoot.append("defs");

        var gradient = defs
            .append("linearGradient")
            .attr("id", "svgAttendanceGradient")
            .attr("x1", "100%")
            .attr("x2", "100%")
            .attr("y1", "0%")
            .attr("y2", "100%");

        gradient
            .append("stop")
            .attr("class", "start")
            .attr("offset", "0%")
            .attr("stop-color", "#fafafa")
            .attr("stop-opacity", 1);

        gradient
            .append("stop")
            .attr("class", "end")
            .attr("offset", "100%")
            .attr("stop-color", "#eceded")
            .attr("stop-opacity", 1);

        var gradient = defs
            .append("linearGradient")
            .attr("id", "svgPauseGradient")
            .attr("x1", "100%")
            .attr("x2", "100%")
            .attr("y1", "0%")
            .attr("y2", "100%");

        gradient
            .append("stop")
            .attr("class", "start")
            .attr("offset", "0%")
            .attr("stop-color", "#fafafa")
            .attr("stop-opacity", 1);

        gradient
            .append("stop")
            .attr("class", "end")
            .attr("offset", "100%")
            .attr("stop-color", "#eceded")
            .attr("stop-opacity", 1);
    }

    executeOnRender($body: JQuery): void {
        this.svgRoot
            .append("text")
            .attr("x", 25)
            .attr("y", 10)
            .text(this.i18n.get("MobileApp.attendanceClock.inAttendance"))
            .style("dominant-baseline", "hanging")
            .style("text-anchor", "start");

        const sizeAttendance = this.svgRoot.node().getBBox();
        this.svgRoot.empty();

        this.svgRoot
            .append("text")
            .attr("x", 25)
            .attr("y", 10)
            .text(this.i18n.get("MobileApp.attendanceClock.inPause"))
            .style("dominant-baseline", "hanging")
            .style("text-anchor", "start");

        const sizePause = this.svgRoot.node().getBBox();

        const calcWidth = sizeAttendance.width + 30;
        const calcWidthPause = sizePause.width;
        const pauseOffset = calcWidth + 10;

        $(this.svgRoot.node()).append(
            "<svg id='fakeSvg'><path id='attendanceSVG_BTN' d=\"m 10.479 0.54701 h " +
                calcWidth +
                " l 9.4002 16.729-9.2506 16.586-" +
                calcWidth +
                '-0.0041c-8.6023-2.02e-4 -9.9321-0.71525-9.9321-6.7073v-19.896c0-5.6759 1.3298-6.7073 9.9321-6.7073z" fill="url(#svgAttendanceGradient)" fill-rule="evenodd" stroke="#e1e1e1" stroke-width="1.094"/>' +
                this.composePlayIcon("attendancePlay", 0) +
                "</svg>" +
                "<svg id='fakeSvg2'>" +
                "<path id='pauseSVG_BTN' transform='translate(" +
                pauseOffset +
                ")' d=\"M " +
                calcWidthPause +
                ",33.331042 0.74808781,33.580731 7.0154518,17.064842 0.64830581,0.68975187 " +
                calcWidthPause +
                ',0.44406187 c 2.86768,-0.009 6.622,3.66411003 6.622,6.62203003 V 26.709031 c 0,2.801861 -3.75432,6.612933 -6.622,6.622011 z" fill="url(#svgPauseGradient)" fill-rule="evenodd" stroke="#e1e1e1" stroke-width="1.094"/>' +
                this.composePlayIcon("pausePlay", pauseOffset + 5) +
                "</svg>" +
                "",
        );

        this.svgRoot.select("#fakeSvg").on("click", this.notifyClicked.bind(this));
        this.svgRoot.select("#fakeSvg2").on("click", this.notifyPauseClicked.bind(this));

        this.svgRoot
            .select("#fakeSvg")
            .append("text")
            .attr("x", 30)
            .attr("y", 10)
            .text(this.i18n.get("MobileApp.attendanceClock.inAttendance"))
            .style("dominant-baseline", "hanging")
            .style("text-anchor", "start");

        this.svgRoot
            .select("#fakeSvg2")
            .append("text")
            .attr("x", pauseOffset + 35)
            .attr("y", 10)
            .text(this.i18n.get("MobileApp.attendanceClock.inPause"))
            .style("dominant-baseline", "hanging")
            .style("text-anchor", "start");
    }

    public addElement(guiElement: GUIElement) {
        this.guiElements.push(guiElement);
    }

    public notifyClicked(event): void {
        if (typeof event !== "undefined") {
            event.preventDefault();
            event.stopPropagation();
        }

        if (this.onAttendanceClick) {
            this.clickAttendanceCallback();
        }
    }

    public notifyPauseClicked(event): void {
        if (typeof event !== "undefined") {
            event.preventDefault();
            event.stopPropagation();
        }

        if (this.onPauseClick) {
            this.clickPauseCallback();
        }
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }

    public adhocRemoveClass(styleclass: string) {
        this.$SVGButtonTag.removeClass(styleclass);
    }

    public adhocAddClass(styleclass: string) {
        this.$SVGButtonTag.addClass(styleclass);
    }

    public setAttendanceRunning() {
        this.switchPlayAndStopIcon("Play", "Stop", "attendance");

        // Gradient anpassen
        this.svgRoot.select("#svgAttendanceGradient .start").attr("stop-color", "#EAEAEA");
        this.svgRoot.select("#svgAttendanceGradient .end").attr("start-color", "#EAEAEA");
    }

    public setAttendanceStopped() {
        this.switchPlayAndStopIcon("Stop", "Play", "attendance");

        this.svgRoot.select("#svgAttendanceGradient .start").attr("stop-color", "#fafafa");
        this.svgRoot.select("#svgAttendanceGradient .end").attr("start-color", "#EAEAEA");
    }

    public setPauseRunning() {
        this.switchPlayAndStopIcon("Play", "Stop", "pause");

        this.svgRoot.select("#svgPauseGradient .start").attr("stop-color", "#EAEAEA");
        this.svgRoot.select("#svgPauseGradient .end").attr("start-color", "#EAEAEA");
    }

    public setPauseStopped() {
        this.switchPlayAndStopIcon("Stop", "Play", "pause");

        this.svgRoot.select("#svgPauseGradient .start").attr("stop-color", "#fafafa");
        this.svgRoot.select("#svgPauseGradient .end").attr("start-color", "#EAEAEA");
    }

    private composePlayIcon(id: string, xOffset: number): string {
        const playIconString =
            "<path id='" +
            id +
            "' transform='translate(" +
            xOffset +
            ", -1)'" +
            '     style="fill:#525252;fill-opacity:1;stroke:none;stroke-width:0.2982313px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"' +
            '     d="m 10.722494,26.279754 10.53011,-7.103249 -10.49477,-7.063101 z"' +
            '     id="path968-1"' +
            "     />";

        return playIconString;
    }

    private composeStopIcon(id: string, xOffset: number): string {
        const playIconString =
            "<rect id='" +
            id +
            "' transform='translate(" +
            xOffset +
            ", -1)'" +
            '       style="fill:#525252;fill-opacity:1;stroke:none;stroke-width:0.74899995;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"' +
            '       id="rect885"' +
            '       width="15"' +
            '       height="15"' +
            '       x="10.896315"' +
            '       y="12" />';

        return playIconString;
    }

    private switchPlayAndStopIcon(oldIcon: string, newIcon: string, subtype: string) {
        const attendancePlayNode = this.svgRoot.select(".svgButton #" + subtype + oldIcon).node();
        // Falls null, nehmen wir an das shcon getauscht wurde.
        if (attendancePlayNode != null) {
            const $buttonIcon = $(attendancePlayNode);
            const transform = $buttonIcon.attr("transfom");

            const t: any = this.parse(
                this.svgRoot
                    .select(".svgButton #" + subtype + oldIcon)
                    .attr("transform")
                    .replace(" ", ""),
            );
            let x = 0;
            if (typeof t.translate != "undefined") {
                x = t.translate[0];
            }

            if (newIcon == "Play") {
                d3.select(attendancePlayNode).html(this.composePlayIcon(subtype + newIcon, x));
            } else {
                d3.select(attendancePlayNode).html(this.composeStopIcon(subtype + newIcon, x));
            }

            $("#" + subtype + newIcon).insertBefore($("#" + subtype + oldIcon));
            $("#" + subtype + oldIcon).detach();
        }
    }

    private parse(a) {
        const b = {};
        for (const i in (a = a.match(/(\w+\((\-?\d+\.?\d*e?\-?\d*,?)+\))+/g))) {
            const c = a[i].match(/[\w\.\-]+/g);
            b[c.shift()] = c;
        }
        return b;
    }
}
