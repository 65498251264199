import { GUIElement } from "../GUIElement";
import "./ProgressBar.less";

export class ProgressBar implements GUIElement {
    /** Aktueller Fortschrittswert (in %) */
    private progressPercentage: number;

    /** Text im Fortschrittsbalken (optional) */
    private text: string;

    private $progressBarTag: JQuery;

    private $progressLabelTag: JQuery;

    /**
     * @param text Aktueller Fortschrittswert (in %)
     */
    public setProgressPercentage(progressPercentage: number): ProgressBar {
        this.progressPercentage = Math.round(progressPercentage);

        if (this.$progressBarTag) {
            this.$progressBarTag.css("width", this.progressPercentage + "%");
        }

        return this;
    }

    /**
     * @param text Text im Fortschrittsbalken (optional)
     */
    public setText(text: string): ProgressBar {
        this.text = text;

        if (this.$progressLabelTag) {
            this.$progressLabelTag.text(this.text);
        }

        return this;
    }

    public compose($parent: JQuery): void {
        const $progressTag = $("<div>").addClass("progressbar").appendTo($parent);

        this.$progressLabelTag = $("<span>")
            .appendTo($progressTag)
            .addClass("percent")
            .text(this.text);

        this.$progressBarTag = $("<div>")
            .appendTo($progressTag)
            .addClass("bar")
            .css("width", this.progressPercentage + "%");
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}
