import { GUIElement } from "../GUIElement";
import { MessageEntity } from "../../core/Message/MessageEntity";
import { FlexBox, FlexJustifyContent } from "../flexbox/FlexBox";
import { HTMLContent } from "../content/HTMLContent";
import { Link } from "../content/Link";

import "./Messages.less";
import { MessageContainer } from "./MessageContainer";
import { GUIContext } from "../GUIContext";

export class Message implements GUIElement {
    private messageItem: MessageEntity;

    private imageName: string = null;

    private clickCallback: Function; // oder: () => void

    private removeCallback: () => void; // oder: () => void

    private context: GUIContext;

    private guiElements: GUIElement[] = [];

    constructor(messageItem: MessageEntity, guiContext: GUIContext) {
        this.messageItem = messageItem;
        this.context = guiContext;
    }

    public onClick(clickCallback: Function): Message {
        this.clickCallback = clickCallback;
        return this;
    }

    /**
     * HTML-DOM generieren
     *
     * @param $parent Parent-jQuery-Objekt
     */
    public compose($parent: JQuery, parentElement: MessageContainer): void {
        const $message = $("<div>")
            .appendTo($parent)
            .addClass("message")
            .addClass(this.messageItem.getType())
            .on("click", this.notifyClicked.bind(this));

        const messageBox = new FlexBox().setJustifyContent(FlexJustifyContent.SPACE_BETWEEN);
        const messagePart = messageBox.newFlexItem().setFlexBasis("90%");
        messagePart.addContentElement(
            new HTMLContent(
                $("<span>").addClass("messageText").html(this.messageItem.getMessage()),
            ),
        );

        const closeLink: Link = new Link();
        closeLink.setStyleClass("close");
        closeLink.onClick(this.closeMessage.bind(this, $message));
        const closePart = messageBox.newFlexItem().setFlexBasis("10%");
        closePart.addStyleClass("messageCloseWrapper");
        closePart.addContentElement(closeLink);
        messageBox.compose($message);

        /*
         * Falls die Nachricht nicht Sticky ist. Also nicht beständig, sollte sie automatisch nach X Sekunden ausgeblendet werden.
         * Eine Erfolgsnachricht benötigt muss man nicht die ganze Zeit sehen, es reicht wenn man gesehen hat, dass es funktioniert hat.
         * Dagegen soll eine Fehlermeldung die sticky ist, solange erscheinen, bis der Benutzer sie entfernt hat.
         */
        if (!this.messageItem.isSticky()) {
            setTimeout(() => this.closeMessage($message), 4000);
        }
    }

    public addElement(guiElement: GUIElement) {
        this.guiElements.push(guiElement);
    }

    public closeMessage($message: JQuery, event?: Event): void {
        $message.detach();
        this.context.triggerEvent("Message", "remove", event, {
            messageEntity: this.messageItem,
        });
    }

    public notifyClicked(event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        if (this.clickCallback) {
            this.clickCallback();
        }
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}
