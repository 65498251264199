import { HttpRESTClient } from "../../../util/http/HttpRESTClient";
import { Log } from "../../../common/log/Log";

export class ForecastRecordsClient {
    public save(age, transportObject: object): Promise<object> {
        Log.debug("[ForecastRecordsClient] save", transportObject);
        return new Promise((resolve, reject) => {
            new HttpRESTClient()
                .createRequest()
                .setMethod("POST")
                .addPath("/timerecording/saveForecastRecord")
                .setParameter("age", age)
                .setJSONBody(transportObject)
                .send()
                .then((result) => {
                    switch (result["status"]) {
                        case 200:
                            resolve({});
                            // resolve(result["json"]);
                            break;
                        case 204:
                            resolve({});
                            break;
                        default:
                            reject(result);
                    }
                })
                .catch((errorInformations) => {
                    reject(errorInformations);
                });
        });
    }
}
