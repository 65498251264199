import { AllowanceManager } from "../allowances/AllowanceManager";
import { AppEventManager } from "../../core/AppEventManager";
import { BCSFile } from "./records/BCSFile";
import { Component } from "../../core/Component";
import { FileClient } from "./FileClient";
import { FilePool } from "./FilePool";
import { IndexedDB } from "../../database/IndexedDB";
import { Log } from "../../common/log/Log";
import { Registry } from "../../core/Registry";
import { SingletonComponent } from "../../core/SingletonComponent";
import { SyncState, SyncStateObjectType } from "../../sync/SyncState";
import { SyncStateManager } from "../../sync/SyncStateManager";
import { ServerConfigProperties } from "../../common/config/ServerConfigProperties";

export class FileManager implements Component, SingletonComponent {
    /** Symbolischer Name dieser Komponente */
    public static BCS_COMPONENT_NAME = "FileManager";

    /** Objekttypen mit protentiellen Dateianhängen */
    private static FILE_COMPONENT_TYPES = ["JAllowance"];

    /** SyncStateObjectType für Objekttypen mit protentiellen Dateianhängen */
    private static FILE_COMPONENT_SYNCTYPES: { [key: string]: SyncStateObjectType } = {
        JAllowance: SyncStateObjectType.Allowance,
    };

    private indexedDB: IndexedDB;

    private filePool: FilePool;

    private syncStateManager: SyncStateManager;
    private serverConfigProperties: ServerConfigProperties;

    public getDependencyNames(): string[] {
        return [
            IndexedDB.BCS_COMPONENT_NAME,
            SyncStateManager.BCS_COMPONENT_NAME,
            AppEventManager.BCS_COMPONENT_NAME,
            // Abhängigkeit vom AllowanceManager nur, damit beim Synchronisieren Spesen VOR Dateien gesendet werden,
            // sonst gäbe es Fehler, wenn eine Datei zu einer noch nicht gesendeten Belegspese gspeichert werden soll.
            AllowanceManager.BCS_COMPONENT_NAME,
            ServerConfigProperties.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }): void {
        this.indexedDB = <IndexedDB>depencencyComponents[IndexedDB.BCS_COMPONENT_NAME];
        this.filePool = new FilePool(this.indexedDB);

        this.syncStateManager = <SyncStateManager>(
            depencencyComponents[SyncStateManager.BCS_COMPONENT_NAME]
        );

        const appEventManager = <AppEventManager>(
            depencencyComponents[AppEventManager.BCS_COMPONENT_NAME]
        );

        this.serverConfigProperties = <ServerConfigProperties>(
            depencencyComponents[ServerConfigProperties.BCS_COMPONENT_NAME]
        );

        FileManager.FILE_COMPONENT_TYPES.forEach((fileComponentType) =>
            appEventManager.registerEventListenerObjectDeleted(
                fileComponentType,
                this.notifyFileComponentObjectDeleted.bind(this),
            ),
        );
    }

    public start(): Promise<void> {
        return Promise.resolve();
    }

    public notifyBeginUserSession(isOnline: boolean): Promise<void> {
        return isOnline ? this.sendFilesToBCS() : Promise.resolve();
    }

    public synchronize(): Promise<void> {
        return this.sendFilesToBCS();
    }

    /**
     * In App geänderte bzw. gelöschte Dateien in BCS speichern bzw. löschen.
     */
    private async sendFilesToBCS(): Promise<void> {
        // Alle SyncStates mit Änderungen in App holen
        const fileSyncStatesWithChanges =
            await this.syncStateManager.readSyncAllStatesToBeSentToBCS(SyncStateObjectType.File);
        if (fileSyncStatesWithChanges.length == 0) {
            return Promise.resolve();
        }

        // Einsammeln der geänderten und zu löschen SyncStates
        const fileSyncStatesUpdated: SyncState[] = [];
        const fileSyncStateIdsDeleted: string[] = [];

        // Neue Dateien in BCS speichern
        await this.sendNewFilesToBCS(fileSyncStatesWithChanges, fileSyncStatesUpdated);

        // Gelöschte Dateien in BCS löschen
        await this.sendDeletedFilesToBCS(
            fileSyncStatesWithChanges,
            fileSyncStatesUpdated,
            fileSyncStateIdsDeleted,
        );

        // SyncStates in DB speichern
        await this.syncStateManager.storeSyncStates(fileSyncStatesUpdated);
        await this.syncStateManager.deleteSyncStates(fileSyncStateIdsDeleted);
    }

    /**
     * In App hinzugefügte Dateien in BCS speichern.
     */
    private async sendNewFilesToBCS(
        fileSyncStatesWithChanges: SyncState[],
        fileSyncStatesUpdated: SyncState[],
    ): Promise<void> {
        // SyncStates für neue Dateien raussuchen
        const fileSyncStatesWithCreate = fileSyncStatesWithChanges.filter(
            (syncState) => !syncState.isDeleted(),
        );

        for (let i = 0; i < fileSyncStatesWithCreate.length; i++) {
            const syncState = fileSyncStatesWithCreate[i];

            // Datei anhand der Id laden
            const file = await this.filePool.readFileFromDB(syncState.getId());

            const skipUpload = await this.skipUploadDueToFileComponentError(file);
            if (!skipUpload) {
                // Datei zu BCS hochladen
                Log.debug("File to be sent to BCS for Debugging: ", file);
                const restSaveResult = await new FileClient().sendFileToBCS(file);
                restSaveResult.convertOidsToUids();

                // Datei anhand ihres SyncStates als geändert markieren
                restSaveResult.updateSyncStates([syncState]);
                fileSyncStatesUpdated.push(syncState);
            }
        }
    }

    /**
     * Prüft, ob Datei-Upload übersprungen werden soll, da es vorher Fehler beim Speichern des Datei-Bezugs gab.
     * Dateiupload wäre überflüssig, da es beim Speichern der datei in BCS einen Fehler geben könnte, dass der Bezug noch nicht existiert.
     * Außerdem gäbe es eine überflüssige zusätzliche Fehlermeldung, dass auch die Datei nicht gespeichert werden konnte.
     *
     * @param file Hochzuladende Datei
     * @returns true wenn Datei nicht hochgeladen werden soll, da es vorher Fehler beim Speichern des Datei-Bezugs gab
     */
    private async skipUploadDueToFileComponentError(file: BCSFile): Promise<boolean> {
        for (let t = 0; t < FileManager.FILE_COMPONENT_TYPES.length; t++) {
            const componentType = FileManager.FILE_COMPONENT_TYPES[t];

            // Prüfung für Objekttyp des Datei-Bezugs
            if (file.getFileComponentOid().indexOf("_" + componentType) > 0) {
                const componentSyncType = FileManager.FILE_COMPONENT_SYNCTYPES[componentType];
                const fileComponentSyncStatesWithErrors =
                    await this.syncStateManager.readSyncAllStatesWithErrors(componentSyncType);
                for (let s = 0; s < fileComponentSyncStatesWithErrors.length; s++) {
                    // Falls für Objekttyp und Id des Datei-Bezugs ein Sync-Fehler existiert: Datei-Upload überspringen!
                    if (
                        file.getFileComponentOid() == fileComponentSyncStatesWithErrors[s].getId()
                    ) {
                        Log.debug(
                            "[FileManager] Skip file upload due to error while saving file component",
                            {
                                fileId: file.getId(),
                                fileComponentOid: file.getFileComponentOid(),
                                fileSyncState: fileComponentSyncStatesWithErrors[s].toValueObject(),
                            },
                        );
                        // da es am Ticket #218745 App-Sync Belege immer wieder zu Problemen bei der Dateisynchronisation kam, schaffen wir die Möglichkeit, das Überspringen fehlerhafter Dateien abzuschalten
                        if (
                            this.serverConfigProperties.getProperty(
                                "MobileApp.RetryFailedFileSynchronisation",
                                "false",
                            )
                        ) {
                            return false;
                        }
                        return true;
                    }
                }
            }
        }
        return false;
    }

    /**
     * In App gelöschte Dateien in BCS löschen.
     */
    private async sendDeletedFilesToBCS(
        fileSyncStatesWithChanges: SyncState[],
        fileSyncStatesUpdated: SyncState[],
        fileSyncStateIdsDeleted: string[],
    ): Promise<void> {
        // SyncState für zu löschende Dateien raussuchen
        const fileSyncStatesWithDeletion = fileSyncStatesWithChanges.filter((syncState) =>
            syncState.isDeleted(),
        );

        for (let i = 0; i < fileSyncStatesWithDeletion.length; i++) {
            const syncState = fileSyncStatesWithDeletion[i];

            const restSaveResult = await new FileClient().deleteFileInBCS(syncState.getId());
            restSaveResult.convertOidsToUids();

            // Datei anhand ihres SyncStates als geändert markieren
            if (restSaveResult.countErrors() == 0) {
                fileSyncStateIdsDeleted.push(syncState.getId());
            } else {
                restSaveResult.updateSyncStates([syncState]);
                fileSyncStatesUpdated.push(syncState);
            }
        }
    }

    public fetchFileById(fileId: string): Promise<BCSFile> {
        return this.filePool.readFileFromDB(fileId);
    }

    public async fetchFilesByComponent(fileComponentOid: string): Promise<BCSFile[]> {
        return await this.filePool.readFilesFromDB(fileComponentOid);
    }

    public async storeFile(file: BCSFile): Promise<void> {
        await this.filePool.writeFileToDB(file);

        // Datei anhand ihres SyncStates als geändert markieren
        const fileSyncState = await this.syncStateManager.getOrCreateSyncStateById(
            file.getId(),
            SyncStateObjectType.File,
        );
        fileSyncState.markChanged(true);
        await this.syncStateManager.storeSyncState(fileSyncState);
    }

    public async deleteFile(deleteFileId: string, onlyLocalDelete: boolean = false): Promise<void> {
        return this.deleteFiles([deleteFileId], onlyLocalDelete);
    }

    public async deleteFiles(
        deleteFileIds: string[],
        onlyLocalDelete: boolean = false,
    ): Promise<void> {
        if (deleteFileIds.length == 0) {
            return Promise.resolve();
        }

        if (!onlyLocalDelete) {
            // Abholen des SyncStates für zu löschende Dateien
            const fileSyncStates = await this.syncStateManager.getSyncStatesByIds(deleteFileIds);

            // Einsammeln der als zu löschen zu markierenden und der zu löschenden SyncStates
            const updateFileSyncStates: SyncState[] = [];
            const deleteFileSyncStateIds: string[] = [];

            for (let i = 0; i < fileSyncStates.length; i++) {
                const fileSyncState = fileSyncStates[i];

                if (fileSyncState.isNew()) {
                    // In App neu erstellte Dateien, die noch nicht an BCS gesandt wurden, können einfach zusammen mit ihrem SyncState gelöscht werden.
                    deleteFileSyncStateIds.push(fileSyncState.getId());
                } else {
                    // Bereits an BCS vorhandene Dateien, müssen anhand ihres SyncState als in BCS zu löschen markiert werden.
                    fileSyncState.markDeleted();
                    updateFileSyncStates.push(fileSyncState);
                }
            }

            // SyncStates als zu löschen markieren oder löschen
            await this.syncStateManager.storeSyncStates(updateFileSyncStates);
            await this.syncStateManager.deleteSyncStates(deleteFileSyncStateIds);
        }

        // Dateien in DB löschen
        await this.filePool.deleteFilesFromDB(deleteFileIds);
    }

    /**
     * Aufgerufen, wenn ein Objekt gelöscht wird, an dem Dateien hängen können.
     *
     * @param eventScope Event-Scope (hier gelöschter Objekttyp)
     * @param eventParameters Event-Parameter (hier gelöschte Objekt-Id)
     */
    private async notifyFileComponentObjectDeleted(
        eventScope: string,
        eventParameters: { id: string; onlyLocalDelete?: boolean },
    ): Promise<void> {
        const deletedFileComponentId = eventParameters["id"];
        const onlyLocalDelete = eventParameters["onlyLocalDelete"] || false;

        const deleteFileIds = await this.filePool.readFileIdsFromDB(deletedFileComponentId);
        await this.deleteFiles(deleteFileIds, onlyLocalDelete);
    }
}

Registry.registerSingletonComponent(FileManager.BCS_COMPONENT_NAME, FileManager);
