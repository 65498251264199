import { GUIElement } from "../GUIElement";
import { ConfigNode } from "../../core/Config/ConfigNode";
import { GUIContext } from "../GUIContext";
import { I18n } from "../../common/i18n/I18n";
import { BCSDate } from "../../common/BCSDate";
import { FlexBox, FlexJustifyContent } from "../flexbox/FlexBox";
import { HTMLContent } from "./HTMLContent";
import { Registry } from "../../core/Registry";

import * as d3 from "d3";

import "./CalendarControll.less";
import { AppColors } from "../../util/globalDefinitions/AppColors";
import { BookingsPerDay } from "../../domain/time_recording/bookings/BookingsPerDay";
import { BookingsPerDayInTimeRange } from "../../domain/time_recording/bookings/BookingsPerDayInTimeRange";
import { TimeRecordingController } from "../../domain/time_recording/TimeRecordingController";
import { ClickOutSidePool } from "../event/ClickOutSidePool";

/** Ausrichtung als Reihe/Spalte */
export const enum CalendarMode {
    DAY = "day",
    MONTH = "month",
    YEAR = "year",
}

/**
 *
 * @see CalendarControll.less
 */
export class CalendarControll implements GUIElement {
    public static BCS_COMPONENT_NAME = "CalendarControll";
    private configNode: ConfigNode;
    private context: GUIContext;
    private i18n: I18n;

    private parent: JQuery;

    private today: BCSDate;
    private shownDate: BCSDate;

    private isDateChooserMode = false;
    private dateSelectionCallback: Function;

    private bookingsPerDay: BookingsPerDay;

    /**
     * Welches Auswahlmittel zeigt der Kalender gerade an:
     * Tage
     * Monate
     * Jahre
     *
     * Default: CalendarMode.DAY
     */
    private calendarMode: CalendarMode = CalendarMode.DAY;

    private clickOutsidePool: ClickOutSidePool = null;

    /**
     * Wollen dem Kalendar-Blatt in eingeschränkten Parametern eine dynamische Höhe geben.
     * Daher merken wir uns wie viel Platz wir noch für die Ziffern übrig haben.
     */
    private availableCalendarHeight: number;

    constructor(configNode: ConfigNode, guiContext: GUIContext, bookingsPerDay?: BookingsPerDay) {
        this.context = guiContext;
        this.configNode = configNode;
        if (typeof this.context !== "undefined") {
            this.i18n = this.context.getI18n();
        }

        this.bookingsPerDay = bookingsPerDay;
        this.today = BCSDate.getToday();
        this.shownDate = this.today;
    }

    /**
     * Dinge die immer vor dem Zeichnen passieren sollen, jedoch nicht zur Initialisierung der Komponente.
     * Wie beispielsweise das Setzten der Kalendarhöhe,
     * diese soll bei jedem Neuzeichnen des Kalendars wieder zurück gesetzt werden.
     */
    public preCompose() {
        if (this.isDateChooserMode) {
            // 40% der Bildschirmhöhe
            this.availableCalendarHeight = $(window).height() * 0.4;
        } else {
            this.availableCalendarHeight = this.context.getPossibleContentHeightPx();
        }
    }

    public compose($parent: JQuery): void {
        this.preCompose();
        this.parent = $parent;
        this.parent.empty();

        const datePattern = this.configNode.getAttribute("DatePattern", "DD");

        const date = new Date();
        //$parent.append(datePattern);

        const calendarWrapper = $("<div>");
        calendarWrapper.appendTo($parent).addClass("calendarControll");

        this.composeHeader(calendarWrapper);

        const calendarTable = $("<table>");
        calendarTable.appendTo(calendarWrapper);

        this.composeTableHeader(calendarTable);
        this.composeCalendarSheet(calendarTable);
    }

    public composeHeader($calendarWrapper: JQuery) {
        let headerDatePatternKey = this.configNode.getAttribute(
            "I18nHeaderDatePattern",
            "MMMM YYYY",
        );
        if (this.calendarMode !== CalendarMode.DAY) {
            headerDatePatternKey = headerDatePatternKey + "_MonthChoser";
        }
        const headerDatePattern = this.i18n.get(headerDatePatternKey, headerDatePatternKey);

        const headerMonthSelectorWrapper = $(
            "<div class='calendarHeadDate'>" + this.shownDate.format(headerDatePattern) + "</div>",
        );

        const calendarHeadFlexBox = new FlexBox().setJustifyContent(
            FlexJustifyContent.SPACE_BETWEEN,
        );

        // Pfeil nach links für den vorherigen Monat
        const calendarArrowLeft = $("<div class='calendarArrowLeft'></div>");
        calendarArrowLeft.on("click", this.changeShownMonthViaOffset.bind(this, -1));
        // Pfeil nach rechts für den nächsten Monat
        const leftBeforeMonth = calendarHeadFlexBox.newFlexItem().setFlexBasis("33%");
        if (this.calendarMode == CalendarMode.DAY) {
            leftBeforeMonth.addContentElement(new HTMLContent(calendarArrowLeft));
        }

        const headerDateLine = new HTMLContent();
        headerDateLine.setContent(headerMonthSelectorWrapper);
        const centerMonthAndYear = calendarHeadFlexBox.newFlexItem().setFlexBasis("33%");
        centerMonthAndYear.addContentElement(headerDateLine);

        const calendarArrowRight = $("<div class='calendarArrowRight'></div>");
        calendarArrowRight.on("click", this.changeShownMonthViaOffset.bind(this, 1));
        const rightNextMonth = calendarHeadFlexBox.newFlexItem().setFlexBasis("33%");
        if (this.calendarMode == CalendarMode.DAY) {
            rightNextMonth.addContentElement(new HTMLContent(calendarArrowRight));
        }

        calendarHeadFlexBox.compose($calendarWrapper);
        // ziehen für den Kopf des Kalendars X Pixel vom noch übrigen Platz ab.
        this.reduceAvaliableHeightPx(42);
    }

    public composeTableHeader($table: JQuery) {
        const daysOfWeek = this.i18n.getLocaleDaysOfWeek();

        for (let i = 0; i < daysOfWeek.length; i++) {
            const currentDayKey = daysOfWeek[i] + ".short";
            $("<th>" + this.i18n.get(currentDayKey) + "</th>").appendTo($table);
        }

        this.reduceAvaliableHeightPx(24);
    }

    public composeCalendarSheet($table: JQuery) {
        const self = this;
        const firstDayOfMonth: number = this.shownDate.getFirstDayOfMonth();
        const numberOfDaysInMonth: number = this.shownDate.getNumberOfDaysInMonth();
        const numberOfDaysInNextMonth: number = 7 - this.shownDate.getLastDayOfMonth();
        const monthBeforeNumberDays: number = this.shownDate
            .getClone()
            .subtractMonth(1)
            .getNumberOfDaysInMonth();

        const numberOfWeekRows: number = this.getWeekCount(
            firstDayOfMonth,
            numberOfDaysInMonth,
            numberOfDaysInNextMonth,
        );
        let calendarTileHeight: number = 30;
        if (this.availableCalendarHeight / numberOfWeekRows > 20) {
            calendarTileHeight = Math.floor(this.availableCalendarHeight / numberOfWeekRows);
        }

        let cellCounter: number = 0;
        let currentTr: JQuery = $("<tr></tr>");
        // Vorheriger Monat
        for (var i = firstDayOfMonth - 2; i >= 0; i--) {
            if (cellCounter % 7 == 0) {
                var newTr = $("<tr></tr>");
                currentTr = newTr;
                $(currentTr).appendTo($table);
            }

            $(
                "<td style='height:" +
                    calendarTileHeight +
                    "px' class='dayOfMonthCell monthBefore'>" +
                    (monthBeforeNumberDays - i) +
                    "</td>",
            ).appendTo(currentTr);
            cellCounter++;
        }
        // jetziger Monat
        for (var i = 1; i <= numberOfDaysInMonth; i++) {
            if (cellCounter % 7 == 0) {
                var newTr = $("<tr></tr>");
                currentTr = newTr;
                $(currentTr).appendTo($table);
            }

            const iteratedDate: BCSDate = this.shownDate.setDayOfMonth(i);
            let styleClasses = "";

            // Entscheidung welcher Style
            if (iteratedDate.isWeekend()) {
                styleClasses += " weekend";
            }

            if (iteratedDate.isToday()) {
                styleClasses += " today";
            }
            const calendarCell: JQuery = $(
                "<td style='height:" +
                    calendarTileHeight +
                    "px' class='dayOfMonthCell" +
                    styleClasses +
                    "'><div>" +
                    i +
                    "</div></td>",
            );
            calendarCell.appendTo(currentTr);

            const selectedDate = iteratedDate.getClone();
            calendarCell.on("click", function (event) {
                self.selectDate(event, selectedDate, i);
            });

            const barWrapper = $("<div class='timeBar'>");
            calendarCell.append(barWrapper);
            if (!this.isDateChooserMode && this.bookingsPerDay) {
                const currentDayBookings = this.bookingsPerDay.getBookingsAtDay(iteratedDate);

                if (currentDayBookings !== null) {
                    const svg = d3
                        .select(barWrapper[0])
                        .append("svg")
                        .attr("height", 2)
                        .attr("width", 15);

                    const miscellaneousBookingSum =
                        this.bookingsPerDay.getAttendancesAtDayExpense(iteratedDate);
                    //var miscellaneousBookingSum = currentDayBookings.getDayMiscellaneousBookingSum();
                    const dayBookingSum = currentDayBookings.getDayBookingSum();
                    let bookingPercent = 0;
                    if (miscellaneousBookingSum !== 0 && dayBookingSum != 0) {
                        bookingPercent = dayBookingSum / miscellaneousBookingSum;
                    } else if (miscellaneousBookingSum == 0 && dayBookingSum != 0) {
                        bookingPercent = 1;
                    }

                    svg.append("rect")
                        .attr("class", "bg-rect")
                        .attr("fill", "#F2F3F5")
                        .attr("height", 4)
                        .attr("width", 15)
                        .attr("x", 0);

                    svg.append("rect")
                        .attr("class", "bg-rect")
                        .attr("fill", AppColors.TimeRecording_BookedTimeColor)
                        .attr("height", 4)
                        .attr("width", 15 * bookingPercent)
                        .attr("x", 0);
                }
            }

            cellCounter++;
        }

        // nächster Monat
        for (var i = 1; i <= numberOfDaysInNextMonth; i++) {
            if (cellCounter % 7 == 0) {
                var newTr = $("<tr></tr>");
                currentTr = newTr;
                $(currentTr).appendTo($table);
            }

            $(
                "<td style='height:" +
                    calendarTileHeight +
                    "px' class='dayOfMonthCell monthAfter'>" +
                    i +
                    "</td>",
            ).appendTo(currentTr);
            cellCounter++;
        }
    }

    public setDateChooserMode(): CalendarControll {
        this.isDateChooserMode = true;
        return this;
    }

    private selectDate(event, date: BCSDate, count: number) {
        if (this.isDateChooserMode) {
            this.dateSelectionCallback(date);
        } else {
            this.context.triggerEvent(CalendarControll.BCS_COMPONENT_NAME, "click", event, {
                navigate: TimeRecordingController.BCS_COMPONENT_NAME,
                clickedDate: date.getISODate(),
            });
        }
    }
    public getWeekCount(
        firstDayOfMonth: number,
        numberOfDaysInMonth: number,
        numberOfDaysInNextMonth: number,
    ): number {
        let numberOfWeekRows = 0;
        let cellCounter = 0;
        for (var i = firstDayOfMonth - 2; i >= 0; i--) {
            if (cellCounter % 7 == 0) {
                numberOfWeekRows++;
            }
            cellCounter++;
        }
        for (var i = 1; i <= numberOfDaysInMonth; i++) {
            if (cellCounter % 7 == 0) {
                numberOfWeekRows++;
            }
            cellCounter++;
        }

        for (var i = 1; i <= numberOfDaysInNextMonth; i++) {
            if (cellCounter % 7 == 0) {
                numberOfWeekRows++;
            }
            cellCounter++;
        }
        return numberOfWeekRows;
    }

    public addCalendarDayEntitie(): void {
        // TODO, wenn Daten da sind
    }

    public changeShownMonthViaOffset(monthOffset, event): void {
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();

        this.shownDate.addMonth(monthOffset);
        this.parent.empty();
        this.compose(this.parent);
    }

    public setDateSelectionCallback(dateSelectionCallback: Function): void {
        this.dateSelectionCallback = dateSelectionCallback;
    }

    public reduceAvaliableHeightPx(reduce: number) {
        this.availableCalendarHeight -= reduce;
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}
Registry.registerGUIComponent(CalendarControll.BCS_COMPONENT_NAME, CalendarControll);
