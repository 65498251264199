import { GUIElement } from "../GUIElement";
import { ListRowElementFactory } from "./ListRowElementFactory";
import { ListRow } from "./ListRow";
import { ListRowClickCallback, ButtonInteractCallback } from "./ListView";
import { ListRowContext } from "./ListRowContext";
import { ListField } from "./ListField";
import { ListFieldFeedback } from "./ListFieldFeedback";
import { ListRowButton } from "./ListRowButton";

type linkType = { path: string; parameters: { [key: string]: string }; linkImage: string };

type ListFieldSetField = { element: ListField; isDeletable: boolean; link?: linkType; styleClass? };

export class ListFieldSet implements GUIElement {
    private listRowContext: ListRowContext;

    private fieldSetLabelKey: string;

    private listRows: ListRow[] = [];

    private fields: ListFieldSetField[] = [];

    private rowClickCallback: ListRowClickCallback;

    private rowSelectDeleteCallback: Function;

    /**
     * @param listViewContext ListView-Kontext (I18n, Erzeugen von FormFields, ...)
     * @param listFieldSetRowDefinition Definition dieser ListFieldSet-Komponente als JSON ausgelesen aus einer Konfiguration
     */
    constructor(listRowContext: ListRowContext, listFieldSetRowDefinition?: object) {
        this.listRowContext = listRowContext;

        if (listFieldSetRowDefinition) {
            this.appyDefinition(listFieldSetRowDefinition);
        }
    }

    public setInteractCallback(buttonInteractCallback: ButtonInteractCallback[]): ListFieldSet {
        this.fields.forEach((field) =>
            buttonInteractCallback.forEach((callbackEntity) => {
                if (callbackEntity.buttonName === field.element["name"]) {
                    if (field.element instanceof ListRowButton) {
                        (field.element as ListRowButton).addInteractCallback(callbackEntity);
                    }
                }
            }),
        );
        return this;
    }

    private appyDefinition(listFieldSetRowDefinition: object): void {
        // FieldRows aus Definition auslesen und FieldRow-Komonenten erstellen
        // "label": "LABEL", "fields": [ {"class": "COMPONENT", "name": "NAME"}, ... ]

        this.fieldSetLabelKey = listFieldSetRowDefinition["label"];

        const fieldDefinitions = <object[]>listFieldSetRowDefinition["fields"];
        if (fieldDefinitions) {
            fieldDefinitions.forEach((fieldDefinition) => {
                const rowRowElement = ListRowElementFactory.createRowRowElement(
                    this.listRowContext,
                    fieldDefinition,
                );
                const field: ListFieldSetField = {
                    element: rowRowElement,
                    isDeletable: this.listRowContext.getRowModel().isDeletable(),
                };

                // "link": {"path": "travel/kilometres", "parameters": {"oid": {"attribute": "kilometreAllowance:oid"}}}
                const linkDefinition = fieldDefinition["link"];
                if (linkDefinition) {
                    field.link = this.appyLinkDefinition(this.listRowContext, linkDefinition);
                }

                const styleClasses = fieldDefinition["styleClass"];
                if (styleClasses) {
                    field.styleClass = styleClasses;
                }

                this.fields.push(field);
            });
        }
    }

    private appyLinkDefinition(listRowContext: ListRowContext, linkDefinition: object): linkType {
        const linkPath = linkDefinition["path"];

        const linkParameterDefinition = linkDefinition["parameters"] || {}; // {"oid": {"attribute": "kilometreAllowance:oid"}}}
        const linkParameters = {};
        for (const parameterName in linkParameterDefinition) {
            const linkValueDefinition = linkParameterDefinition[parameterName]; // {"attribute": "kilometreAllowance:oid"}

            let value = null;
            if ("value" in linkValueDefinition) {
                value = <string>linkValueDefinition["value"];
            } else if ("attribute" in linkValueDefinition) {
                const attribute = linkValueDefinition["attribute"];
                value = listRowContext.getRowModel().getValue(attribute).getString();
            }

            if (value) {
                linkParameters[parameterName] = value;
            }
        }

        const linkImage = linkDefinition["linkImage"] || "open_right";

        return { path: linkPath, parameters: linkParameters, linkImage: linkImage };
    }

    public onRowClicked(rowClickCallback: ListRowClickCallback): ListFieldSet {
        this.rowClickCallback = rowClickCallback;
        return this;
    }

    public onRowSelectDelete(rowSelectDeleteCallback: Function): ListFieldSet {
        this.rowSelectDeleteCallback = rowSelectDeleteCallback;
        return this;
    }

    public compose($parent: JQuery): void {
        const self = this;

        // FieldSet-Label nicht anzeigen, wenn keine Fields vorhanden (#136224)
        if (this.fields.length === 0) {
            return;
        }

        const $fieldSetTag = $("<div>").appendTo($parent).addClass("listview_fieldset");

        if (this.fieldSetLabelKey) {
            $("<div>")
                .appendTo($fieldSetTag)
                .addClass("fieldset_title")
                .text(this.listRowContext.getI18n().get(this.fieldSetLabelKey));
        }

        this.fields.forEach((field) => {
            const listRow = new ListRow()
                .setEntityId(this.listRowContext.getRowModel().getEntityId())
                .setShowActionItems(true)
                .addContentElement(field.element);

            if (field.isDeletable) {
                listRow
                    .setDeletable(field.isDeletable)
                    .onSelectToBeDeleted(() => self.listRowSelectDelete());
            }
            if (field.styleClass) {
                listRow.addStyleClass(field.styleClass);
            }

            if (field.link) {
                if (field.link.linkImage != "NONE") {
                    listRow.setOpenImageName(field.link.linkImage);
                }
                listRow.onClick(() => self.listRowClicked(field.link.path, field.link.parameters));

                listRow.setLinkPath(field.link.path);
            }

            listRow.compose($fieldSetTag);

            if (field.link) {
                listRow.setWebtestParameter(field.link.parameters);
            }

            this.listRows.push(listRow);
        });
    }

    public enterDeleteMode(): void {
        this.listRows.forEach((listRow) => listRow.enterDeleteMode());
    }

    public selectToBeDeleted(isSelected: boolean): void {
        this.listRows.forEach((listRow) => listRow.selectToBeDeleted(isSelected));
    }

    public hasNotSelectedRows(): boolean {
        return (
            this.listRows.filter((listRow) => listRow.isDeletable() && !listRow.isSelected())
                .length > 0
        );
    }

    public countSelectToBeDeleted(): number {
        return this.listRows.filter((listRow) => listRow.isSelected()).length;
    }

    public getSelectedIdsToBeDeleted(): string[] {
        const listFieldSetSelected = this.listRows
            .map((listRow) => listRow.isSelected())
            .reduce((selected, listRowSelected) => selected && listRowSelected);

        return listFieldSetSelected ? [this.listRowContext.getRowModel().getEntityId()] : [];
    }

    public leaveDeleteMode(): void {
        this.listRows.forEach((listRow) => listRow.leaveDeleteMode());
    }

    public updateValues(listFieldFeedback: ListFieldFeedback): ListFieldSet {
        this.fields.forEach((field) => {
            field.element.updateValues(listFieldFeedback);
        });
        return this;
    }

    private listRowClicked(linkName: string, parameters: { [key: string]: string }): void {
        this.rowClickCallback(linkName, parameters);
    }

    private listRowSelectDelete(): void {
        this.rowSelectDeleteCallback();
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}
