import { AttributeDefinition } from "./AttributeDefinition";

export class TypeSubtypeDefinition {
    private type: string;

    private subtype: string;

    private typeSubtypeDef: object = {};

    constructor(type: string, subtype: string, typeSubtypeDef: object) {
        this.type = type;
        this.subtype = subtype;
        this.typeSubtypeDef = typeSubtypeDef;
    }

    public getType(): string {
        return this.type;
    }

    public getSubtype(): string {
        return this.subtype;
    }

    public getAttributeNames(): string[] {
        return Object.keys(this.typeSubtypeDef);
    }

    public hasAttribute(attribute: string): boolean {
        return this.typeSubtypeDef.hasOwnProperty(attribute);
    }

    public getAttributeDefinition(attribute: string): AttributeDefinition {
        this.checkAttributeDefined(attribute);

        if (!this.typeSubtypeDef.hasOwnProperty(attribute)) {
            throw new Error("TypeSubtypeDefinition::UndefinedAttribute: " + attribute);
        }
        return new AttributeDefinition(
            this.type,
            this.subtype,
            attribute,
            this.typeSubtypeDef[attribute],
        );
    }

    public checkAttributeDefined(attribute: string): void {
        if (!this.typeSubtypeDef.hasOwnProperty(attribute)) {
            if (attribute.indexOf(":")) {
                attribute = attribute.substring(attribute.indexOf(":") + 1);
            }

            if (!this.typeSubtypeDef.hasOwnProperty(attribute)) {
                throw new Error(
                    "[Schema] No schema defined for type/subtype/attribute: " +
                        this.type +
                        "/" +
                        this.subtype +
                        "/" +
                        attribute,
                );
            }
        }
    }

    /**
     * @returns Alle Pflichtattribute dieses Objekt-/Subtypes.
     *          Attribute mit Default="#undefined" werden ausgenommen, da sie implizit zu Pflichtattributen werden,
     *          wenn UndefinedInsteadOfNull="true" und Default="#undefined" konfiguriert ist (z.B. "JEffort.effortWorkingTimeType"),
     *          die App aber die vom Benutzer auszufüllenden Attribute wissen möchte.
     */
    public getRequiredAttributes(): string[] {
        const requiredAttributes = [];
        for (const attributeName in this.typeSubtypeDef) {
            if (this.typeSubtypeDef.hasOwnProperty(attributeName)) {
                const attributeDef: AttributeDefinition =
                    this.getAttributeDefinition(attributeName);
                if (attributeDef.isRequired()) {
                    requiredAttributes.push(attributeName);
                }
            }
        }
        return requiredAttributes;
    }
}
