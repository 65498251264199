import { ToolBar } from "./ToolBar";
import { GUIPage } from "../GUIPage";
import { FloatingActionButton } from "./FloatingActionButton";
import { BottomActionButton } from "./BottomActionButton";
import { ToolLink } from "./ToolLink";

export class DownloadMode {
    private page: GUIPage;

    private addButton: FloatingActionButton;

    private downloadActionButton: BottomActionButton;

    private downloadModeActive = false;

    private downloadButtonLabel: string = "[DOWNLOAD]";

    private downloadModeToggleCallback: (isActive: boolean) => void;

    private downloadCallback: () => void;

    public setPage(page: GUIPage): DownloadMode {
        this.page = page;
        return this;
    }

    public setHeaderToolBar(headerToolBar: ToolBar): DownloadMode {
        headerToolBar.addToolLinkRight(
            new ToolLink()
                .setId("pageDownload")
                .setImageName("download.svg")
                .onClick(this.toggleDownloadMode.bind(this)),
        );
        return this;
    }

    public setDownloadButtonLabel(downloadButtonLabel: string): DownloadMode {
        this.downloadButtonLabel = downloadButtonLabel;
        return this;
    }

    private toggleDownloadMode(): void {
        if (!this.downloadModeActive) {
            this.enterDownloadMode();
        } else {
            this.leaveDownloadMode();
        }
    }

    public enterDownloadMode(): void {
        if (this.downloadModeActive) {
            return;
        }

        if (this.downloadModeToggleCallback) {
            this.downloadModeToggleCallback(true);
        }

        if (this.addButton) {
            this.addButton.hide();
        }

        this.downloadActionButton = new BottomActionButton()
            .setDownloadPageLabel(this.downloadButtonLabel)
            .onClick(this.download.bind(this));
        this.page.addFooterElement(this.downloadActionButton);
        this.downloadModeActive = true;
    }

    public leaveDownloadMode(): void {
        if (!this.downloadModeActive) {
            return;
        }

        if (this.downloadModeToggleCallback) {
            this.downloadModeToggleCallback(false);
        }

        this.page.removeFooterElement(this.downloadActionButton);
        this.downloadActionButton.remove();
        this.downloadActionButton = null;

        if (this.addButton) {
            this.addButton.show();
        }

        this.downloadModeActive = false;
    }

    public onDownload(downloadCallback: () => void): DownloadMode {
        this.downloadCallback = downloadCallback;
        return this;
    }

    private download(): void {
        this.downloadCallback();
    }

    isActive(): boolean {
        return this.downloadModeActive;
    }
}
