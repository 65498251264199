import type { Bootstrap } from "./core/Bootstrap";
import type { ComponentRegistry } from "./core/ComponentRegistry";

declare global {
    interface Window {
        bootstrap: Bootstrap;
        componentReg: ComponentRegistry;
        MessagePool: any;
        IndexedDBQuery: any;
        IndexedDB: any;
        IdGenerator: any;
        I18n: any;
        GUIEventPool: any;

        MessageEntity: any;
        Booking: any;
        BCSDate: any;

        TimeSpan: any;
        BookingsPerDay: any;
    }
}

export default {};
