import { BCSDate } from "../../../common/BCSDate";
import { AppConsole } from "../../../common/log/AppConsole";
import { AttributeDefinition } from "../../../common/schema/AttributeDefinition";
import { Schema } from "../../../common/schema/Schema";
import { Entity } from "../../../entities/Entity";
import { DateValue } from "../../../entities/values/DateValue";
import { EntityValue } from "../../../entities/values/EntityValue";
import { NumberValue } from "../../../entities/values/NumberValue";
import { OidValue } from "../../../entities/values/OidValue";
import { SyncState } from "../../../sync/SyncState";
import { IdGenerator } from "../../../util/text/IdGenerator";
import { BookingType } from "../bookings/Booking";
import { TimeAttibutesDefinitions } from "../TimeAttibutesDefinitions";
import { TimeRecord } from "../TimeRecord";

export class TimeSpan implements TimeRecord {
    public static TYPE = "JTimeSpan";
    private bookingEntity: Entity;
    public static ATTENDANCE: string = "attendance";
    private readonly PAUSE: string = "pause";
    private syncState: SyncState = null;

    constructor(schema: Schema, bookingObject?: object) {
        const typeSubtypeDefinition = schema.getTypeSubtypeDefinition("JTimeSpan", "attendance");
        let bookingValues = null;
        if (bookingObject) {
            bookingValues = bookingObject;
        } else {
            bookingValues = {
                oid: IdGenerator.createId() + "_JTimeSpan",
                typ: "JTimeSpan",
                subtyp: "attendance",
                startedBooking: false,
            };
        }
        this.bookingEntity = new Entity(typeSubtypeDefinition, bookingValues);
    }

    public static create(schema: Schema, userOid: string, subtype: string): TimeSpan {
        const typeSubtypeDefinition = schema.getTypeSubtypeDefinition(TimeSpan.TYPE, subtype);

        const currentDate = BCSDate.getToday().getISODate();

        const timeSpanObject = {
            oid: IdGenerator.createId() + "_" + TimeSpan.TYPE,
            typ: TimeSpan.TYPE,
            subtyp: subtype,
            userOid: userOid,
            timeSpanDate: currentDate,
            startedBooking: false,
        };

        const newTimeSpan = new TimeSpan(schema, timeSpanObject);

        // TODO Defaults setzen ...

        return newTimeSpan;
    }

    public getId(): string {
        return this.bookingEntity.getId();
    }

    public getDate(): Date {
        const dateValue = <DateValue>this.bookingEntity.getValue("timeSpanDate");
        return dateValue.getDate();
    }

    public setId(id: string): void {
        this.bookingEntity.setValue("oid", new OidValue(id));
    }

    public getEffortExpense(): number {
        const effortExpense: number = this.bookingEntity.getNumber("timeSpanDuration");
        return effortExpense;
    }

    public getSubtype(): string {
        return this.bookingEntity.getString("subtyp");
    }

    public isAttendance(): boolean {
        return this.getSubtype() === TimeSpan.ATTENDANCE;
    }

    public isPause(): boolean {
        return this.getSubtype() === this.PAUSE;
    }

    public isBooking(): boolean {
        return false;
    }

    public isDummy(): boolean {
        return false;
    }

    public isAppointment(): boolean {
        return false;
    }

    /*
        public getTimeSpanDuration(): number {
            var effortExpense: number = this.bookingEntity.getNumber("timeSpanDuration");
            return effortExpense;
            // TODO Error
        }
    */
    public getStartTime(): Date {
        const startTimeRaw: string = this.bookingEntity.getString("timeSpanStartTime");
        if (startTimeRaw == null) {
            return null;
        }
        const hourAndMinute = startTimeRaw.split(":");
        let date = this.getDate();
        if (parseInt(hourAndMinute[1]) == -1) {
            date = null;
        } else {
            date.setHours(parseInt(hourAndMinute[0]));
            date.setMinutes(parseInt(hourAndMinute[1]));
        }
        return date;
    }

    public isEndTimeDefined(): boolean {
        const endTime: EntityValue = this.bookingEntity.getValue("timeSpanEndTime");

        return endTime.isDefined();
    }

    public isStartTimeDefined(): boolean {
        const endTime: EntityValue = this.bookingEntity.getValue("timeSpanStartTime");
        return endTime.isDefined();
    }

    public getEndTime(): Date {
        const endTimeRaw: string = this.bookingEntity.getString("timeSpanEndTime");
        if (endTimeRaw == null) {
            return null;
        }
        const hourAndMinute = endTimeRaw.split(":");
        let date = this.getDate();

        if (parseInt(hourAndMinute[1]) == -1) {
            date = null;
        } else {
            date.setHours(parseInt(hourAndMinute[0]));
            date.setMinutes(parseInt(hourAndMinute[1]));
        }

        return date;
    }

    public hasDurationOnly(): boolean {
        if (this.isStartTimeDefined() && this.isEndTimeDefined()) {
            return false;
        } else {
            return true;
        }
    }

    isMiscellaneousEffort(): boolean {
        return false;
    }

    public toValueObject(): object {
        return this.bookingEntity.toValueObject();
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return this.bookingEntity.getTypeSubtypeDefinition().getAttributeDefinition(name);
    }

    public getValue(name: string): EntityValue {
        return this.bookingEntity.getValue(name);
    }

    public setValue(name: string, value: EntityValue): void {
        this.bookingEntity.setValue(name, value);
    }

    public setDate(date: BCSDate): TimeRecord {
        const dateValue: DateValue = DateValue.parseFromString(date.getISODate());

        this.bookingEntity.setValue("timeSpanDate", dateValue);
        return this;
    }

    /**
     * Wird nur für Buchungen verwendet.
     *
     */
    public getAvaliableTaskTypes() {
        return undefined;
    }

    public setDuration(value: number): TimeSpan {
        this.bookingEntity.setValue("timeSpanDuration", new NumberValue(value));
        return this;
    }

    public setBookingType(_bookingType: string | BookingType) {
        AppConsole.error("Hier sollten wir eigentlich nicht landen.");
    }

    public getBookingType(): BookingType {
        return BookingType.TimeSpan;
    }

    public getTimeAttibutesDefinitions(): TimeAttibutesDefinitions {
        return new TimeAttibutesDefinitions(
            "timeSpanStartTime",
            "timeSpanEndTime",
            "timeSpanDuration",
        );
    }

    public getRequiredAttributes(): string[] {
        return this.bookingEntity.getTypeSubtypeDefinition().getRequiredAttributes();
    }

    public attachSyncState(syncState: SyncState): void {
        this.syncState = syncState;
    }

    /**
     *
     * @return syncState:boolean true => es gibt einen Synchronisations Stand
     */
    public hasSyncState(): boolean {
        return this.syncState !== null;
    }

    public getSyncState(): SyncState {
        return this.syncState;
    }

    public isSyncOnlyChangedInApp(): boolean {
        return this.syncState.isSyncOnlyChangedInApp() || this.syncState.hasSynchronisationIssue();
    }

    /**
     * Methode hinzugefügt im Rahmen der Fehlerbehebung von #153736 Pauseneinträge in App nicht sichtbar ohne Endzeit
     * prüft, ob nur die Start oder nur die Endzeit eines Pauseneintrags gesetzt ist und ergänzt den jeweils anderen Wert, sodass immer beide gefüllt sind und es nicht zu einem Fehler beim Speichern / Anzeigen kommt
     */
    public addEndTimeToTimespan(): void {
        if (this.isStartTimeDefined() && !this.isEndTimeDefined()) {
            this.setValue("timeSpanEndTime", this.getValue("timeSpanStartTime"));
        }
        if (!this.isStartTimeDefined() && this.isEndTimeDefined()) {
            this.setValue("timeSpanStartTime", this.getValue("timeSpanEndTime"));
        }
    }
}
