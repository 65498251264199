import "./ListView.less";
import { GUIElement } from "../GUIElement";
import { ListRow } from "./ListRow";
import { ListFieldSet } from "./ListFieldSet";
import { ListViewContext } from "./ListViewContext";
import { Form, formChangeCallbackType, formChangeEventType } from "../form/Form";
import { ListViewModel } from "./ListViewModel";
import { ListFieldFeedback } from "./ListFieldFeedback";
import { FormFieldAddOn } from "../form/FormFieldAddOn";

export type ListRowClickCallback = (
    linkName: string,
    parameters: { [key: string]: string },
) => void;

export class ListView implements GUIElement {
    /**
     * CSS-Klasse für Standard-ListViews mit Standard-Forms.
     * An jedem Standard-ListView muss diese CSS-Klasse gesetzt werden.
     * Andere ListViews werden durch die Standard-CSS-Definition nicht beeinflusst.
     */
    public static STYLE_CLASS_DEFAULT_LIST_VIEW = "defaultListView";

    private domId: string;

    private listViewContext: ListViewContext;

    /** Titel über gesamtem ListView  */
    private listViewTitle: string;

    private listViewDefinition: object;

    private form: Form;

    private formChangeCallback: formChangeCallbackType;

    /** FieldSets als Kontainer für eine Menge von ListRows (für komplexere ListViews optional mit FormFields) */
    private listFieldSets: ListFieldSet[] = [];

    /** FieldRows (ohne FieldSets, für einfache ListViews) */
    private listRows: ListRow[] = [];

    private formFieldAddOns: FormFieldAddOn[] = [];

    private rowClickCallback: ListRowClickCallback;
    private buttonInteractCallback: ButtonInteractCallback[] = [];

    private rowClickSelectDeleteCallback: Function;

    private styleClasses: string[] = [];

    private $listViewTag: JQuery;

    /** Status: Modus zum Löschen von ausgewählten Zeilen (an/aus) */
    private isDeleteMode = false;

    /**
     * @param listViewContext ListView-Kontext (I18n, Erzeugen von FormFields, ...)
     * @param listFieldSetRowDefinition Definition dieser ListFieldSet-Komponente als JSON ausgelesen aus einer Konfiguration
     */
    constructor(listViewContext?: ListViewContext, listViewDefinition?: object) {
        this.listViewContext = listViewContext;
        this.listViewDefinition = listViewDefinition;
    }

    public setDOMId(domId: string): ListView {
        this.domId = domId;
        return this;
    }

    public setTitle(listViewTitle: string): ListView {
        this.listViewTitle = listViewTitle;
        return this;
    }

    public addStyleClass(styleClass: string): ListView {
        this.styleClasses.push(styleClass);
        return this;
    }

    public addRow(listRow: ListRow): ListView {
        listRow.onSelectToBeDeleted(this.listRowSelectDelete.bind(this));
        this.listRows.push(listRow);

        if (this.$listViewTag) {
            listRow.compose(this.$listViewTag);
        }

        return this;
    }

    public registerFormFieldAddOn(formFieldAddOn: FormFieldAddOn): ListView {
        this.formFieldAddOns.push(formFieldAddOn);
        return this;
    }

    public onRowClicked(rowClickCallback: ListRowClickCallback): ListView {
        this.rowClickCallback = rowClickCallback;
        return this;
    }

    public onFormButtonClick(buttonName, rowClickCallback: () => void): ListView {
        this.buttonInteractCallback.push(new ButtonInteractCallback(buttonName, rowClickCallback));
        return this;
    }

    public onRowSelectDeleted(rowClickSelectDeleteCallback: Function): ListView {
        this.rowClickSelectDeleteCallback = rowClickSelectDeleteCallback;
        return this;
    }

    public onFormFieldChange(formChangeCallback: formChangeCallbackType): ListView {
        this.formChangeCallback = formChangeCallback;
        return this;
    }

    public compose($parent: JQuery): void {
        this.applyDefinition();

        this.$listViewTag =
            this.$listViewTag ||
            $("<div>")
                .appendTo($parent)
                .attr("id", this.domId)
                .addClass("listview")
                .addClass(this.styleClasses.join(" "));

        if (this.listViewTitle) {
            $("<p>").appendTo(this.$listViewTag).addClass("title").text(this.listViewTitle);
        }

        if (this.form) {
            this.listFieldSets.forEach((listFieldSet) => this.form.addLayoutElement(listFieldSet));
            this.listRows.forEach((listRow) => this.form.addLayoutElement(listRow));

            this.form.compose(this.$listViewTag);
        } else {
            this.listFieldSets.forEach((listFieldSet) => listFieldSet.compose(this.$listViewTag));
            this.listRows.forEach((listRow) => listRow.compose(this.$listViewTag));
        }

        this.ensureIconAndActionColumnWidth();

        if (this.listViewContext && this.listViewContext.getListModel().isResultTruncated()) {
            $("<p>")
                .appendTo(this.$listViewTag)
                .addClass("textLabelNoContent")
                .text(this.listViewContext.getI18n().get("MobileApp.ListView.resultTruncated"));
        }
    }

    private applyDefinition(): void {
        if (this.listViewContext && this.listViewDefinition) {
            this.form = new Form();
            this.form.onFieldChange(this.formFieldChanged.bind(this));

            const formFieldFactory = this.form.createFormFieldFactory();
            formFieldFactory.registerFormFieldAddOns(this.formFieldAddOns);
            this.listViewContext.setFormFieldFactory(formFieldFactory);

            this.listViewContext.checkComplete();

            // FieldSets aus Definition auslesen und FieldSet-Komonenten erstellen
            //  "fieldsets": [ {"label": "LABEL", "fields": [ ... ]} ]
            const fieldSetDefinitions = <object[]>this.listViewDefinition["fieldsets"];
            if (fieldSetDefinitions) {
                for (
                    let rowNo = 0;
                    rowNo < this.listViewContext.getListModel().countRows();
                    rowNo++
                ) {
                    const listRowContext = this.listViewContext.getListRowContext(rowNo);

                    fieldSetDefinitions.forEach((fieldSetDefinition) => {
                        const fieldSet = new ListFieldSet(listRowContext, fieldSetDefinition)
                            .onRowClicked(this.listRowClicked.bind(this))
                            .onRowSelectDelete(this.listRowSelectDelete.bind(this))
                            .setInteractCallback(this.buttonInteractCallback);
                        this.listFieldSets.push(fieldSet);
                    });
                }
            }
        }
    }

    private ensureIconAndActionColumnWidth(): void {
        let iconCellsMaxWidth = 0;
        const $iconCells = $(".icon");
        $iconCells.each((index, element) => {
            iconCellsMaxWidth = Math.max(iconCellsMaxWidth, $(element).outerWidth());
        });

        let actionCellsMaxWidth = 0;
        const $actionCells = $(".action");
        $actionCells.each((index, element) => {
            actionCellsMaxWidth = Math.max(actionCellsMaxWidth, $(element).outerWidth());
        });
        $actionCells.css("width", Math.round(actionCellsMaxWidth) + "px");
        $iconCells.css("width", Math.round(iconCellsMaxWidth) + "px");
    }

    /**
     * Löscht ListView aus DOM und rendert es entsprechend dem ListViewModel neu.
     *
     * Funktioniert aktuell nur, wenn ListView mit ListViewContext ListViewModel verwendet wird.
     *
     * @param listViewModel
     */
    public recompose(listViewModel: ListViewModel): void {
        if (!this.listViewContext) {
            throw new Error("ListView::ReComposeWithoutListViewContext");
        }

        this.listViewContext.setModel(listViewModel);
        this.listFieldSets = [];
        this.$listViewTag.empty();
        this.compose(this.$listViewTag.parent());
    }

    /**
     * Löscht ListRows aus DOM.
     *
     * Funktioniert nur, wenn die Zeilen des ListViews eine Id bekommen haben.
     *
     * @param rowIds Ids der zu löschenden ListRows
     */
    public removeRows(rowIds: string[]): void {
        const removedListRows = this.listRows.filter(
            (listRow) => rowIds.indexOf(listRow.getEntityId()) >= 0,
        );
        removedListRows.forEach((removedListRow) => removedListRow.remove());

        this.listRows = this.listRows.filter(
            (listRow) => rowIds.indexOf(listRow.getEntityId()) < 0,
        );
    }

    /**
     * Löscht alle ListRows aus DOM. Neue ListRows können mit addRow hinzugfügt und sofort gerendert  werden.
     */
    public removeAllRows(): void {
        this.listRows = [];
        this.listFieldSets = [];
        this.$listViewTag.empty();
    }

    public updateFields(listFieldFeedback: ListFieldFeedback): void {
        this.listFieldSets.forEach((listFieldSet) => {
            listFieldSet.updateValues(listFieldFeedback);
        });
    }

    public enterDeleteMode(): void {
        this.isDeleteMode = true;
        this.listFieldSets.forEach((listFieldSet) => listFieldSet.enterDeleteMode());
        this.listRows.forEach((listRow) => listRow.enterDeleteMode());
    }

    public toggleAllToBeDeleted(): void {
        const notSelectedRowExist =
            this.listFieldSets.filter((listFieldSet) => listFieldSet.hasNotSelectedRows()).length >
                0 ||
            this.listRows.filter((listRow) => listRow.isDeletable() && !listRow.isSelected())
                .length > 0;

        const selectToBeDeleted = notSelectedRowExist;

        this.listFieldSets.forEach((listFieldSet) =>
            listFieldSet.selectToBeDeleted(selectToBeDeleted),
        );
        this.listRows.forEach((listRow) => listRow.selectToBeDeleted(selectToBeDeleted));
    }

    public countSelectToBeDeleted(): number {
        let countSelectedRows = 0;

        countSelectedRows += this.listFieldSets
            .map((listFieldSet) => listFieldSet.countSelectToBeDeleted())
            .reduce((sum, count) => sum + count, 0);
        countSelectedRows += this.listRows.filter((listRow) => listRow.isSelected()).length;

        return countSelectedRows;
    }

    public getSelectedIdsToBeDeleted(): string[] {
        let selectedIds: string[] = [];
        this.listFieldSets.forEach(
            (listFieldSet) =>
                (selectedIds = selectedIds.concat(listFieldSet.getSelectedIdsToBeDeleted())),
        );
        this.listRows.forEach(
            (listRow) => (selectedIds = selectedIds.concat(listRow.getSelectedIdsToBeDeleted())),
        );
        return selectedIds;
    }

    public leaveDeleteMode(): void {
        this.listFieldSets.forEach((listFieldSet) => listFieldSet.leaveDeleteMode());
        this.listRows.forEach((listRow) => listRow.leaveDeleteMode());
        this.isDeleteMode = false;
    }

    private listRowClicked(path: string, parameters: { [key: string]: string }): void {
        if (this.isDeleteMode) {
            // Wenn Löschmodus aktiv: Anklicken einer Zeile öffnet nicht Objekt sondern schaltet Markierung zum Löschen um
            this.listRowSelectDelete();
        } else if (this.rowClickCallback) {
            this.rowClickCallback(path, parameters);
        }
    }

    private listRowSelectDelete(): void {
        if (this.rowClickSelectDeleteCallback) {
            this.rowClickSelectDeleteCallback();
        }
    }

    private formFieldChanged(changeEvent: formChangeEventType): void {
        if (this.listViewContext && this.listViewContext.getListModel()) {
            const listRowModel = this.listViewContext
                .getListModel()
                .getRowModelById(changeEvent.entityId);
            listRowModel.setValue(changeEvent.fieldName, changeEvent.value);
        }

        if (this.formChangeCallback) {
            this.formChangeCallback(changeEvent);
        }
    }

    public getComponentChildren(): GUIElement[] {
        return this.listRows;
    }
}

export class ButtonInteractCallback {
    public buttonName;
    public callback;

    constructor(buttonName, callback) {
        this.buttonName = buttonName;
        this.callback = callback;
    }
}
