/**
 * Ganzzahlige Versionsnummer der IndexedDB (keine floats wie 1.2)
 * Neue Stores in neuen BCS-Versionen werden mit der nächsten Versionsnummer erstellt.
 * Je Datenbank-Version wird ein Update-Callback zum Erstellen von Stores aufgerufen (siehe IndexedDBStore).
 */
export class IndexedDBVersion {
    /** Initiale Datenbank-Version 1 (beinhaltet alle Stores für die initiale Version der App) */
    public static readonly DB_VERSION_1 = 1;

    /** Nächste Datenbank-Version 2 (für neue Stores in einer neuen BCS-Version ...) */
    public static readonly DB_VERSION_2 = 2;

    /** Datenbank-Version für Kontakterfassung */
    public static readonly DB_VERSION_3 = 3;
}
