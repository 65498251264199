import { AttributeDefinition } from "../../../common/schema/AttributeDefinition";
import { SchemaDataType } from "../../../common/schema/SchemaDataType";
import { EntityValue } from "../../../entities/values/EntityValue";
import { ListRowModel } from "../../../gui/list/ListRowModel";
import { Contact } from "../Contact";
import { I18n } from "../../../common/i18n/I18n";

export class ContactListRowModel implements ListRowModel {
    private contact: Contact;

    private i18n: I18n;

    constructor(contact: Contact, i18n: I18n) {
        this.contact = contact;
        this.i18n = i18n;
    }

    public getEntityId(name?: string): string {
        return this.contact.getId();
    }

    public getDataType(name: string): SchemaDataType {
        return this.contact.getAttributeDefinition(name).getDataType();
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return this.contact.getAttributeDefinition(name);
    }

    public getValue(name: string): EntityValue {
        return this.contact.getValue(name);
    }

    public setValue(name: string, value: EntityValue): void {
        this.contact.setValue(name, value);
    }

    public isEditable(): boolean {
        return true;
    }

    public isDeletable(): boolean {
        return true;
    }
}
