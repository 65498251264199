export const enum SchemaDataType {
    OID = "Oid",
    //  BASE = "Base",
    DATE = "Date",
    TIME = "Time",
    DATETIME = "DateTime",
    INT = "Int",
    LONG = "Long",
    FLOAT = "Float",
    CURRENCY = "Currency",
    // PRECISECURRENCY = "PreciseCurrency",
    DURATION = "Duration",
    BOOL = "Bool",
    STRING = "String",
    OPTION = "Option",
    CLASSIFIER = "classifier",
    LSTRING = "LString",
    TEXT = "Text",
    HTML = "Html",
    MULTISTRING = "MultiString",
    NUMBER = "number",
    PHONE = "Phone",
    HANDY = "Handy",
    //  FILELINK = "FileLink",
    PWD = "Pwd",
    URL = "Url",
    PHOTO = "Photo",
    MAIL = "Mail",
    // HIERARCHIC_NUMBER = "HierarchicNumber",
    //   DATETIME_TIMEZONE = "DateTimeWithTimezone",
    //  BLOB = "Blob",
    DOUBLE = "Double",
}
