import "./ProgressLayer.less";
import { ProgressMeter } from "./ProgressMeter";
import { ProgressFeedback } from "../../util/progress/ProgressFeedback";

export class ProcessLayer {
    private $layer: JQuery;

    private progressFeedback: ProgressFeedback;

    private progressMeter: ProgressMeter;

    constructor() {
        this.$layer = $("#processLayer");

        if (this.$layer.length == 0) {
            this.$layer = $("<div>").appendTo($("body")).attr("id", "processLayer");
        }
    }

    public show(progressFeedback?: ProgressFeedback): void {
        this.progressFeedback = progressFeedback;

        this.$layer.css({ display: "block" });
        this.$layer.empty();

        if (this.progressFeedback) {
            this.$layer.addClass("hasProgressFeedback").css({ "background-image": "none" });

            this.progressMeter = new ProgressMeter(this.progressFeedback);
            this.progressMeter.show("processLayer");
        } else {
            this.$layer.removeClass("hasProgressFeedback");

            const self = this;
            window.setTimeout(() => {
                if (!self.$layer.hasClass("hasProgressFeedback")) {
                    self.$layer.css({
                        "background-image":
                            "url(images/spinner.svg),url(images/icons/projektron_logo-144x144.svg)",
                        "background-position": "50% 30%,50% 50%",
                        "background-repeat": "no-repeat,no-repeat",
                    });
                }
            }, 500);
        }
    }

    public hide() {
        if (this.progressFeedback) {
            this.progressFeedback.notifyProgress(100, 100);
            this.progressFeedback = null;
        }

        const self = this;
        window.setTimeout(() => {
            self.$layer.css({ display: "none" });
            self.$layer.empty();
        }, 100);
    }
}
