import { Preferences } from "./Preferences";
import { Schema } from "../schema/Schema";
import { IdGenerator } from "../../util/text/IdGenerator";
import { AttributeDefinition } from "../schema/AttributeDefinition";
import { Entity } from "../../entities/Entity";

export class ServerPreferences extends Preferences {
    constructor(schema: Schema, preferenceObject?: object) {
        super();
        const typeSubtypeDefinition = schema.getTypeSubtypeDefinition("JProfile", "*");

        let preferenceValues = null;
        if (preferenceObject) {
            preferenceValues = preferenceObject;
        } else {
            preferenceValues = {
                oid: IdGenerator.createId() + "_JProfile",
                typ: "JProfile",
                subtyp: "*",
            };
        }

        this.preferenceEntity = new Entity(typeSubtypeDefinition, preferenceValues);
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return this.preferenceEntity.getTypeSubtypeDefinition().getAttributeDefinition(name);
    }
}
