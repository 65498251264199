import { I18nFallBackLabels } from "../../common/i18n/I18nFallBackLabels";
import { AppConsole } from "../../common/log/AppConsole";
import { Log } from "../../common/log/Log";
import { ErrorMessageBox } from "../../gui/messages/ErrorMessageBox";
import { IndexedDBConnection } from "../IndexedDBConnection";
import { IndexedDBError } from "../IndexedDBError";
import { IndexedDBStore } from "../IndexedDBStore";

export class IndexedDBOpenRequest {
    private dbName: string;
    private dbVersion: number;
    private dbStores: IndexedDBStore[];
    private db: any;

    constructor(dbName, dbVersion, dbStores) {
        this.dbName = dbName;
        this.dbVersion = dbVersion;
        this.dbStores = dbStores || {};
        this.db = null;
    }

    public then(successCallback, errorCallback): void {
        const self = this;

        if (!window.indexedDB) {
            Log.error("[IndexedDB] Your browser does not support IndexedDB");
            errorCallback.call(this, "[IndexedDB] Your browser does not support IndexedDB");
            return;
        }

        const request = window.indexedDB.open(this.dbName, this.dbVersion);
        request.onsuccess = function (event) {
            self.success(event, this.result, successCallback);
        };
        request.onerror = function (event) {
            self.error(event, errorCallback);
        };
        request.onblocked = function (event) {
            self.error(event, errorCallback);
        };
        request.onupgradeneeded = function (event) {
            self.upgrade(event);
        };
    }

    public success(event, db, successCallback) {
        db.onerror = function (error) {
            // Generic error handler for all errors targeted at this database's requests!
            Log.error("[IndexedDB] Database error: " + error.target.errorCode);
        };

        const self = this;
        successCallback.call(this, {
            dbName: self.dbName,
            connection: new IndexedDBConnection(db, this.dbStores),
        });
    }

    public error(event, errorCallback) {
        Log.error("[IndexedDBOpenRequest] open db error: " + event, event);

        // Versuch, bestimmte Fehlerursachen zu erkennen und eine sprechende Fehlermeldung anzuzeigen.
        const errorMessage =
            event && event.explicitOriginalTarget && event.explicitOriginalTarget.error
                ? event.explicitOriginalTarget.error.message
                : "";
        let displayMessageKey = null;
        let displayOriginalError = "";
        if (
            errorMessage.indexOf(
                "The operation failed for reasons unrelated to the database itself and not covered by any other error code",
            ) >= 0
        ) {
            // Fehler aufgrund beschäfdigtem Firefox-Profil (#113088 App kann im Browser nicht aufgerufen werden: "isTrusted true")
            displayMessageKey = "IndexedDBOpenRequest.txtError.FirefoxProfileCorrupt";
        } else if (
            errorMessage.indexOf(
                "A mutation operation was attempted on a database that did not allow mutations",
            ) >= 0
        ) {
            // Fehler wenn Aufruf in privatem Fenster und IndexedDB nicht verfügbar
            displayMessageKey = "IndexedDBOpenRequest.txtError.IndexedDBNotAvalaibleInPrivateMode";
        } else {
            // Anderer Fehler mit Ausgabe der Originalfehlermeldung
            displayMessageKey = "IndexedDBOpenRequest.txtError.OpenDatabaseFailed";
            displayOriginalError = "<br><br>" + errorMessage;
        }

        const i18nLabels = I18nFallBackLabels.getFallbackLabels(
            I18nFallBackLabels.getFallbackLanguage(),
        );
        const displayMessage = i18nLabels[displayMessageKey] || displayMessageKey;
        new ErrorMessageBox().setMessage(displayMessage + displayOriginalError).compose($("body"));

        $("#processLayer").css("display", "none");

        errorCallback.call(this, { dbName: this.dbName, error: new IndexedDBError(event) });
    }

    public upgrade(event) {
        Log.debug("[IndexedDBOpenRequest] Upgrade DB", {
            oldVersion: event.oldVersion,
            newVersion: event.newVersion,
        });

        const db = event.target.result;
        const transaction = event.target.transaction;

        // Für alle registrierten Stores ...
        for (const storeNo in this.dbStores) {
            const dbStore = this.dbStores[storeNo];

            Log.debug("[IndexedDBOpenRequest] Create/Update Store", {
                store: dbStore.getStoreName(),
                dbversion: dbStore.getDBVersion(),
            });

            for (let i = event.oldVersion + 1; i <= dbStore.getDBVersion(); i++) {
                AppConsole.debug("Step: " + (i - 1) + " => " + i + " " + dbStore["storeName"]);

                if (!dbStore.storeExists(transaction)) {
                    dbStore.createStore(db, transaction, i);
                } else {
                    dbStore.upgradeStore(db, transaction, i);
                }
            }
        }
    }
}
