import { UserSession } from "../../common/auth/UserSession";
import { ServerConfigProperties } from "../../common/config/ServerConfigProperties";
import { I18n } from "../../common/i18n/I18n";
import { Log } from "../../common/log/Log";
import { ApplicationProperties } from "../../common/properties/ApplicationProperties";
import { Schema } from "../../common/schema/Schema";
import { TypeSubtypeDefinition } from "../../common/schema/TypeSubtypeDefinition";
import { Component } from "../../core/Component";
import { Registry } from "../../core/Registry";
import { SingletonComponent } from "../../core/SingletonComponent";
import { IndexedDB } from "../../database/IndexedDB";
import { StringValue } from "../../entities/values/StringValue";
import { SyncStateManager } from "../../sync/SyncStateManager";
import { ProgressFeedback } from "../../util/progress/ProgressFeedback";
import { Contact } from "./Contact";
import { ContactClient } from "./ContactClient";
import { PoolOfContacts } from "./PoolOfContacts";
import { SyncState, SyncStateObjectType } from "../../sync/SyncState";
import { ContactRecordingTerms } from "./ContactRecordingTerms";
import { DomainSyncState } from "../../common/properties/DomainSyncState";
import { DateTimeValue } from "../../entities/values/DateTimeValue";
import { EntityValue } from "../../entities/values/EntityValue";
import { NumberValue } from "../../entities/values/NumberValue";

export type contactSummaryType = { contactNumber: number; contactRecordingAvailable: boolean };

export class ContactManager implements Component, SingletonComponent {
    public static BCS_COMPONENT_NAME = "ContactManager";
    public static SYNC_STATE_CONTACT_KEY = "ContactsSyncState";
    private parameter: string;
    private contactPool: PoolOfContacts;
    private i18n: I18n;
    private applicationProperties: ApplicationProperties;
    private schema: Schema;
    private userSession: UserSession;
    private syncStateManager: SyncStateManager;
    private serverConfigProperties: ServerConfigProperties;
    private contactNumber: number = -1;
    private recordingTerms: ContactRecordingTerms;
    private contactDomainSyncState: DomainSyncState;

    public getDependencyNames(): string[] {
        return [
            IndexedDB.BCS_COMPONENT_NAME,
            ApplicationProperties.BCS_COMPONENT_NAME,
            I18n.BCS_COMPONENT_NAME,
            Schema.BCS_COMPONENT_NAME,
            UserSession.BCS_COMPONENT_NAME,
            SyncStateManager.BCS_COMPONENT_NAME,
            ServerConfigProperties.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }): void {
        const indexedDB = <IndexedDB>depencencyComponents[IndexedDB.BCS_COMPONENT_NAME];
        this.applicationProperties = <ApplicationProperties>(
            depencencyComponents[ApplicationProperties.BCS_COMPONENT_NAME]
        );
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.schema = <Schema>depencencyComponents[Schema.BCS_COMPONENT_NAME];
        this.userSession = <UserSession>depencencyComponents[UserSession.BCS_COMPONENT_NAME];
        this.syncStateManager = <SyncStateManager>(
            depencencyComponents[SyncStateManager.BCS_COMPONENT_NAME]
        );
        this.contactPool = new PoolOfContacts(indexedDB, this.schema, this.applicationProperties);
        this.serverConfigProperties = <ServerConfigProperties>(
            depencencyComponents[ServerConfigProperties.BCS_COMPONENT_NAME]
        );

        //Meldet ein Pseudo-Attribut zur Verwendung in der App an (wann die Erstsynchronisation erfolgte, getrennt in Jahr und Rest, zwecks Anzeige)
        this.schema.registerPseudoAttribute(Contact.TYPE, Contact.SUBTYPE, Contact.SYNCED_DATE, {
            datatype: "String",
        });
        this.schema.registerPseudoAttribute(Contact.TYPE, Contact.SUBTYPE, Contact.SYNC_YEAR, {
            datatype: "number",
        });
    }

    public start(): Promise<void> {
        return Promise.resolve();
    }

    public getContactTerms(): ContactRecordingTerms {
        return this.recordingTerms;
    }

    public notifyBeginUserSession(
        isOnline: boolean,
        progressFeedback?: ProgressFeedback,
    ): Promise<void> {
        // Cache für Kontaktzussammenfassung zurücksetzen
        this.contactNumber = -1;
        const partProgressFeedbacks = progressFeedback.getPartProgressFeedbacks(2);
        const self = this;
        return self
            .readTermsAndConditionsFromDatabase()
            .then(() => {
                return isOnline
                    ? self.sendContactsToBCS(partProgressFeedbacks[0])
                    : Promise.resolve();
            })
            .then(() => {
                return isOnline
                    ? self.readRecordingTerms(partProgressFeedbacks[1])
                    : Promise.resolve();
            });
    }

    synchronize(progressFeedback?: ProgressFeedback): Promise<void> {
        const partProgressFeedbacks = progressFeedback.getPartProgressFeedbacks(2);
        const self = this;
        return this.sendContactsToBCS(partProgressFeedbacks[0]).then(() =>
            self.readRecordingTerms(partProgressFeedbacks[1]),
        );
    }

    public getTypeSubtypeDef(): TypeSubtypeDefinition {
        return this.schema.getTypeSubtypeDefinition(Contact.TYPE, Contact.SUBTYPE);
    }

    public async storeContact(contact: Contact, isNew: boolean = true): Promise<void> {
        const contactSyncState = await this.syncStateManager.getOrCreateSyncStateById(
            contact.getId(),
            SyncStateObjectType.Contact,
        );
        contactSyncState.setSubtype(contact.getSubtype());
        contactSyncState.markChanged(isNew);
        await this.syncStateManager.storeSyncState(contactSyncState);
        this.contactNumber = -1;
        return this.contactPool.writeContactToDB(contact);
    }

    //Speichert den Kontakt, aktualisiert den SyncState aber NICHT. Wird zZ nur verwendet, um nach der Sync das SycDate am Kontakt zu setzten, ohne dass er dadurch wieder auftaucht
    public async storeContactWithoutUpdateSync(contact: Contact): Promise<void> {
        this.contactNumber = -1;
        return this.contactPool.writeContactToDB(contact);
    }

    public async deleteContact(contact: Contact): Promise<void> {
        const contactSyncState = await this.syncStateManager.getSyncStateById(contact.getId());
        const deleteContactSyncState: string[] = [];
        deleteContactSyncState.push(contactSyncState.getId());
        await this.syncStateManager.deleteSyncStates(deleteContactSyncState);
        this.contactNumber = -1;
        return this.contactPool.deleteContactFromDB(contact);
    }

    public async readContactFromDB(oid: string): Promise<Contact> {
        return this.contactPool.readContactFromDB(oid);
    }

    public async readAllContactsFromDB(): Promise<Contact[]> {
        return this.contactPool.readAllContactsFromDB();
    }

    /**
     * Erzeugt einen neuen Kontakt, wird im RecordController verwendet, wenn ihm kein zu ladender Kontakt als Parameter übergeben wurde.
     *
     * Neu ab 23.1: Ein neuer Kontakt soll das Land des Nutzers standardmäßig vorbelegt haben. Diese Vorbelegung ist erstmal nur dummymäßig, wenn der Nutzer also nicht mindestens ein anderes Attribut ändert, wird der Kontakt trotzdem nicht gespeichert.
     */
    createContact(): Contact {
        const contact: Contact = new Contact(this.getTypeSubtypeDef(), {}, true);
        contact.setValue("ouCountry", new StringValue(this.userSession.getCurrentUserCountry()));

        return contact;
    }

    // Sichert das geparste Objekt als korrekten Contact. wird durch den VCFParser aufgerufen.
    async saveMyCardAsContact(myvcard: {}): Promise<string> {
        const userFunktion = "userFunktion";
        const ouCountry = "ouCountry";
        const description = "description";

        let contact: Contact;

        contact = this.createContact();
        for (const key in myvcard) {
            if (myvcard[key] !== undefined) {
                contact.setValue(key, new StringValue(myvcard[key]));
            }
        }

        if (myvcard[userFunktion] !== undefined) {
            const functions: string[] = this.schema
                .getTypeSubtypeDefinition(Contact.TYPE, Contact.SUBTYPE)
                .getAttributeDefinition(userFunktion)
                .getOptions();
            const myCardFunktion = myvcard[userFunktion];
            functions.forEach((userfnkt) => {
                if (userfnkt.toLowerCase().includes(myCardFunktion.toLowerCase())) {
                    contact.setValue(userFunktion, new StringValue(userfnkt.toString()));
                }
            });
            if (contact.getValue(userFunktion) == null) {
                const key: string = this.i18n.get("JUser.ouperson." + userFunktion);
                contact.setValue(description, new StringValue(key + ": " + myCardFunktion));
            }
        }
        if (myvcard[ouCountry] !== undefined) {
            this.getSchemaLabel(ouCountry);
            contact.setValue(ouCountry, new StringValue(myvcard[ouCountry]));
        }
        if (myvcard[description] !== undefined) {
            if (contact.getValue(description) != null) {
                myvcard[description] = contact.getValue(description) + "\n" + myvcard[description];
            }
            contact.setValue(description, new StringValue(myvcard[description]));
        }

        await this.storeContact(contact);
        return contact.getId();
    }

    public async counter(): Promise<number> {
        if (this.contactNumber == -1) {
            this.contactNumber = await this.contactPool.countContacts();
        }
        return this.contactNumber;
    }

    public async fetchContactSummary(): Promise<contactSummaryType> {
        const contactNumber = await this.counter();
        const contactRecordingAvailable = this.recordingTerms.isContactRecordingAllowed();
        return { contactNumber: contactNumber, contactRecordingAvailable };
    }

    private async readRecordingTerms(feedback: ProgressFeedback): Promise<void> {
        try {
            const recordingTermsValueObject = await new ContactClient().readTermsFromBCS(
                this.recordingTerms.getCheckSum(),
                feedback,
            );
            if (recordingTermsValueObject) {
                this.recordingTerms = new ContactRecordingTerms(recordingTermsValueObject);
                await this.contactPool.writeTermsToDB(
                    this.userSession.getCurrentUserOid(),
                    this.recordingTerms,
                );
            }
        } catch (error) {
            Log.error("[ContactManager] Failed to Read ContactRecordingTerms:" + error, {
                error: error,
            });
        }
    }

    private async readTermsAndConditionsFromDatabase(): Promise<void> {
        this.recordingTerms = await this.contactPool.readTermsFromDB(
            this.userSession.getCurrentUserOid(),
        );
        this.contactDomainSyncState = await DomainSyncState.fetchFromApplicationProperties(
            ContactManager.SYNC_STATE_CONTACT_KEY,
            this.applicationProperties,
            this.userSession.getCurrentUserOid(),
        );
    }

    private async sendContactsToBCS(progressFeedback: ProgressFeedback): Promise<void> {
        try {
            const contactSyncStatesChanged =
                await this.syncStateManager.readSyncAllStatesToBeSentToBCS(
                    SyncStateObjectType.Contact,
                );
            if (contactSyncStatesChanged.length == 0) {
                progressFeedback.notifyProgress(100, 100);
                return Promise.resolve();
            }

            const contactUpdatedSyncStates: SyncState[] = [];
            await this.sendNewAndEditedContactsToBCS(
                contactSyncStatesChanged,
                contactUpdatedSyncStates,
                progressFeedback,
            );
            await this.syncStateManager.storeSyncStates(contactUpdatedSyncStates);

            contactUpdatedSyncStates.forEach((syncState) => {
                if (!syncState.hasSynchronisationIssue()) {
                    this.contactPool.readContactFromDB(syncState.getId()).then(async (contact) => {
                        contact.setValue(
                            Contact.SYNCED_DATE,
                            new StringValue(DateTimeValue.now().getDateValue().formatShortDate()),
                        );
                        contact.setValue(
                            Contact.SYNC_YEAR,
                            new NumberValue(DateTimeValue.now().getDate().getFullYear()),
                        );
                        await this.storeContactWithoutUpdateSync(contact);
                    });
                }
            });

            await this.deleteOldContacts();

            // await this.sendAllContactsToBCS(contacts, progressFeedback);
            // Zu Testzwecken kann man auch alle Kontakte, unabhängig ob geändert oder nicht nach BCS Schicken
        } catch (error) {
            Log.error("[ContactManager] SendContactsToBCS failed: " + error, { error: error });
            return Promise.reject();
        }
    }

    private async sendAllContactsToBCS(
        contacts: Contact[],
        progressFeedback: ProgressFeedback,
    ): Promise<void> {
        const contactValueObjects: object[] = [];
        contacts.forEach((value) => {
            contactValueObjects.push(value.toValueObject());
        });
        await new ContactClient().sendContactsToBCS(contactValueObjects, progressFeedback);
    }

    private getSchemaLabel(attribute: string): { [key: string]: string } {
        const labelMap: { [key: string]: string } = {};
        this.schema
            .getTypeSubtypeDefinition(Contact.TYPE, Contact.SUBTYPE)
            .getAttributeDefinition(attribute)
            .getOptions()
            .forEach((option) => (labelMap[option] = this.translate(attribute, option)));
        return labelMap;
    }

    private translate(attribute: string, option: string): string {
        return this.i18n.getAttributeOption(Contact.TYPE, Contact.SUBTYPE, attribute, option);
    }

    // Sendet alle neuen und bearbeiteten Kontakte nach BCS und updatet dann die SyncStates als erfolgreich oder fehlerhaft
    private async sendNewAndEditedContactsToBCS(
        contactSyncStatesChanged: SyncState[],
        contactUpdatedSyncStates: SyncState[],
        progressFeedback: ProgressFeedback,
    ): Promise<void> {
        const changedContacts: Contact[] = [];
        for (let i = 0; i < contactSyncStatesChanged.length; i++) {
            const syncState = contactSyncStatesChanged[i];
            changedContacts.push(await this.readContactFromDB(syncState.getId()));
        }
        const contactValueObject = changedContacts.map((contact) => contact.toValueObject());
        const restSaveResult = await new ContactClient().sendContactsToBCS(
            contactValueObject,
            progressFeedback,
        );
        restSaveResult.updateSyncStates(contactSyncStatesChanged);

        contactSyncStatesChanged.forEach((sycState) => contactUpdatedSyncStates.push(sycState));
    }

    //Löscht alte Kontakte, bei denen die Erstsynchronisation länger als X (Wert aus Property) Tage zurückliegt
    private async deleteOldContacts(): Promise<void> {
        const contactArray: Contact[] = await this.readAllContactsFromDB();
        contactArray.forEach((contact) => {
            try {
                const contactSynchedDate: EntityValue = contact.getValue(Contact.SYNCED_DATE);
                const deleteAfter: number = parseInt(
                    this.serverConfigProperties.getProperty("MobileApp.DeleteContactAfter"),
                );
                const contactYear: number = <number>(
                    contact.getValue(Contact.SYNC_YEAR).getSimpleValue()
                );
                if (contactSynchedDate.isDefined() && deleteAfter && contactYear) {
                    const contactSyncValues: string[] = contactSynchedDate.getString().split(".");
                    const syncedDate: Date = new Date(
                        contactYear,
                        parseInt(contactSyncValues[1]) - 1,
                        parseInt(contactSyncValues[0]),
                    );
                    const deleteDate: Date = new Date(
                        syncedDate.getTime() + 1000 * 60 * 60 * 24 * deleteAfter,
                    );
                    const currentDate: Date = new Date();
                    if (deleteDate < currentDate) {
                        Log.debug(
                            "CONTACT MANAGER => Contact " +
                                contact.getId() +
                                " deleted from App, because his Synch Date was " +
                                syncedDate +
                                " the current Date " +
                                currentDate +
                                ", and the Property sets the Deletion Date on plus " +
                                deleteAfter +
                                " Days",
                        );
                        this.deleteContact(contact);
                    }
                }
            } catch (automaticDelErr) {
                Log.error(
                    "Automatic Deletion failed with Error => " +
                        automaticDelErr +
                        "|| ADVICE: If this Error continues, delete all Data manually by reinstalling the BCS-App",
                );
            }
        });
    }
}

Registry.registerSingletonComponent(ContactManager.BCS_COMPONENT_NAME, ContactManager);
