import { I18n } from "../../common/i18n/I18n";
import { ConfigNode } from "../../core/Config/ConfigNode";
import { contactSummaryType } from "../../domain/contactRecording/ContactManager";
import { ContactElement } from "../../domain/contactRecording/pages/board/ContactElement";
import { BoardElement } from "../../gui/board/BoardElement";
import { GUIContext } from "../../gui/GUIContext";
import "./../../domain/contactRecording/pages/board/ContactElement.less";

/**
 * Boardelement, welches die Anzahl der bisher erfassten Kontakte anzeigt
 */
export class ContactBoardElement extends BoardElement {
    public static ELEMENT_NAME = "showContactElement";

    private i18n: I18n;

    private contactSummary: contactSummaryType;

    constructor(
        boardElementConfig: ConfigNode,
        context: GUIContext,
        contactSummary: contactSummaryType,
        i18n: I18n,
    ) {
        super(boardElementConfig, context);
        this.name = ContactBoardElement.ELEMENT_NAME;

        this.xTiles = boardElementConfig.getIntAttribute("PosX", 1);
        this.yTiles = boardElementConfig.getIntAttribute("PosY", 14);
        this.widthTiles = boardElementConfig.getIntAttribute("SizeX", 6);
        this.heightTiles = boardElementConfig.getIntAttribute("SizeY", 2);

        this.wholeContainerLinksTo = "contactList";
        this.styleClasses.push(this.name);

        this.i18n = i18n;
        this.contactSummary = contactSummary;
    }

    protected initializeChildren() {
        this.context.setPossibleContentHeight(
            this.currentTileHeight * this.heightTiles - this.CSS_ELEMENT_MARGIN * 2,
        );
        this.guiElements = [new ContactElement(this.contactSummary, this.i18n)];
    }
}
