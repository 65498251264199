import { EntityValue } from "./EntityValue";

/**
 * Repräsentiert einen Währungswert einer Entity.
 *
 * Währungswerte bestehen aus einem Währungssymbol (3 Zeichen, z.B. "EUR") und
 * einem numerischen Wert (float, z.B. 123.45).
 *
 * Als SimpleValue wird das Währungssymbol und der numerische konkatiniert (z.B. "EUR123.45").
 */
export class CurrencyValue implements EntityValue {
    private value: string;

    public static parseFromString(currencySign: string, strValue: string) {
        return CurrencyValue.fromSignAndAmount(currencySign, parseFloat(strValue));
    }

    public static fromSignAndAmount(currencySign: string, currencyAmount: number) {
        if (isNaN(currencyAmount)) {
            currencyAmount = 0;
        }
        return new CurrencyValue(currencySign + currencyAmount);
    }

    constructor(simpleValue: string) {
        this.value = simpleValue;

        // Betrag auf 2 Nachkommastellen abrunden
        if (this.isDefined()) {
            this.value = this.getCurrencySign() + this.getNumber().toFixed(2);
        }
    }

    public isDefined(): boolean {
        return !!this.value;
    }

    public getSimpleValue(): string | number | boolean {
        return this.value;
    }

    public getString(): string {
        return this.value ? this.value : "";
    }

    public getCurrencySign(): string {
        return this.value ? this.value.substr(0, 3) : "???";
    }

    public getNumber(): number {
        return this.value ? parseFloat(this.value.substr(3)) : 0;
    }

    public formatDecimalPlaces(decimalPlaces: number): string {
        return this.value ? this.getNumber().toFixed(decimalPlaces) : "";
    }

    public format(userLocale: string): string {
        if (!this.isDefined()) {
            return "";
        }

        const numberFormat = new Intl.NumberFormat(userLocale, {});
        return numberFormat.format(this.getNumber());
    }
}
