import { GUIElement } from "../GUIElement";

export class Image implements GUIElement {
    private imageName: string;

    private imageDataURL: string;

    private imageWidth: number;

    private imageHeight: number;

    private isSVG: boolean = false;

    private focusable: boolean = false;

    private styleClasses: string[] = [];

    public setImageName(imageName: string): Image {
        this.imageName = imageName;
        return this;
    }

    public setImageDataURL(imageDataURL: string): Image {
        this.imageDataURL = imageDataURL;
        return this;
    }

    public setIsSVG(isSVG: boolean): Image {
        this.isSVG = isSVG;
        return this;
    }

    public setFocusable(focusable: boolean): Image {
        this.focusable = focusable;
        return this;
    }

    public setImageWidthHeight(imageWidth: number, imageHeight: number): Image {
        this.imageWidth = imageWidth;
        this.imageHeight = imageHeight;
        return this;
    }

    public addStyleClass(styleClass: string): Image {
        this.styleClasses.push(styleClass);
        return this;
    }

    public compose($parentTag: JQuery): void {
        if (this.imageName && this.imageName != "NONE" && this.imageName != "NONE.svg") {
            let useImageName =
                this.imageName.indexOf(".svg") > 0 ? this.imageName : this.imageName + ".png";

            if (this.isSVG) {
                useImageName = this.imageName + ".svg";
            }

            $("<img>")
                .appendTo($parentTag)
                .addClass(this.styleClasses.join(" "))
                .attr({
                    src: "images/icons/" + useImageName,
                });
        } else if (this.imageDataURL) {
            $("<img>")
                .appendTo($parentTag)
                .addClass(this.styleClasses.join(" "))
                .attr({
                    src: this.imageDataURL,
                    width: this.imageWidth,
                    height: this.imageHeight,
                    tabindex: this.focusable ? "0" : "-1",
                });

            // Speicher freigeben
            this.imageDataURL = null;
        }
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}
