export class ClickOutSidePool {
    private _insideEntries: InsideEntry[] = [];
    private static insideEntries: InsideEntry[] = [];

    public static init() {
        ClickOutSidePool.insideEntries = [];
        $(document).click(ClickOutSidePool.bodyClicked.bind(this));
    }

    public static bodyClicked(event: Event) {
        let indexToDelete: number = -1;

        ClickOutSidePool.insideEntries.forEach((element, index) => {
            const isOutSide: boolean = element.ifClickIsNotClickOfSelectorTrigger(event);
            if (isOutSide) {
                indexToDelete = index;
            }
        });
        // Löschen nach dem ersten Triggern, da das SubMenu oder der Calendar dannach geschlossen sein sollte.
        // Diese Klasse ist zur Zeit nur fürs Schließen, geeignet.
        if (indexToDelete > -1) {
            ClickOutSidePool.insideEntries.splice(indexToDelete, 1);
        }
    }

    public static registerClickInsideLayer(selector: string, clickoutSideCallback: () => void) {
        ClickOutSidePool.insideEntries.push(new InsideEntry(selector, clickoutSideCallback));
    }
}

class InsideEntry {
    protected insideSelector: string = null;
    protected clickoutSideCallback: () => void = null;

    constructor(selector: string, clickoutSideCallback: () => void) {
        this.insideSelector = selector;
        this.clickoutSideCallback = clickoutSideCallback;
    }
    /**
     *
     * @returns isOutSide: boolean => true, wenn es außerhalb des InideSelectors liegt, also wir z.B. das Submenu schließen wollen.
     */
    public ifClickIsNotClickOfSelectorTrigger(event: Event): boolean {
        const clickedTarget: EventTarget = event.target;
        const timestamp = new Date().getTime();
        $(clickedTarget).attr("data-searchUnder", timestamp);
        const foundUnderInside = $(this.insideSelector).find(
            "[data-searchUnder='" + timestamp + "']",
        );
        const isFoundUnderInsideWrapper = foundUnderInside.length > 0;
        $(clickedTarget).removeAttr("data-searchUnder");
        if (isFoundUnderInsideWrapper) {
            // wenn wir innerhalb geklick haben tun wir nichts;
            return false;
        } else {
            // wenn wir außerhalb geklickt haben, führen wir den Callback aus.
            this.clickoutSideCallback();
            return true;
        }
    }
}
