import { ListRowModel } from "../../gui/list/ListRowModel";
import { EntityValue } from "../../entities/values/EntityValue";
import { SchemaDataType } from "../../common/schema/SchemaDataType";
import { AttributeDefinition } from "../../common/schema/AttributeDefinition";
import { ListViewModel } from "../../gui/list/ListViewModel";
import { Preferences } from "./Preferences";

export class PreferencesListViewModel implements ListViewModel {
    private preference: Preferences;

    constructor(givenPreferences: Preferences) {
        this.preference = givenPreferences;
    }

    public countRows(): number {
        return 1;
    }

    public getRowModelByNo(rowNo: number): ListRowModel {
        return new PreferencesListRowModel(this.preference);
    }

    public getRowModelById(entityId: string): ListRowModel {
        return new PreferencesListRowModel(this.preference);
    }

    public isResultTruncated(): boolean {
        return false;
    }
}

export class PreferencesListRowModel implements ListRowModel {
    private preferences: Preferences;

    constructor(givenPreferences: Preferences) {
        this.preferences = givenPreferences;
    }

    public getEntityId(name?: string): string {
        return this.preferences.getId();
    }

    public getDataType(name: string): SchemaDataType {
        return this.preferences.getAttributeDefinition(name).getDataType();
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return this.preferences.getAttributeDefinition(name);
    }

    public getValue(name: string): EntityValue {
        return this.preferences.getValue(name);
    }

    public setValue(name: string, value: EntityValue): void {
        this.preferences.setValue(name, value);
    }

    public isEditable(): boolean {
        return true;
    }

    public isDeletable(): boolean {
        return false;
    }
}
