import { ListRowModel } from "./ListRowModel";
import { ListViewModel } from "./ListViewModel";

export class TypeAheadSearch {
    /** Modus: Highlighten von Suchworten (Standard) oder einzelnen Suchzeichen (starke Fragmentierung) */
    private static readonly HIGHLIGHT_WORDS_TOKENS = true;

    /** Maximale Ergebnismenge, damit Ausgabe in GUI performant bleibt. Ansonsten muss der Benutzer konkreter suchen. */
    private static readonly MAX_RESULT_SIZE = 100;

    private totalListViewModel: ListViewModel;

    private searchFieldNames: string[];

    constructor(totalListViewModel: ListViewModel, searchFieldNames: string[]) {
        this.totalListViewModel = totalListViewModel;
        this.searchFieldNames = searchFieldNames;
    }

    public search(searchInput: string): ListViewModel {
        const searchWords = searchInput
            .trim()
            .replace(/[^\wÄÖÜäöüß ]/g, " ") // nicht nach Sonderzeichen suchen, führt manchmal zu seltsamen Fehlern
            .split(TypeAheadSearch.HIGHLIGHT_WORDS_TOKENS ? " " : "")
            .filter((searchToken) => searchToken.length > 0);
        const searchExpressions = searchWords.map(
            (searchToken) => new RegExp(searchToken.split(" ").join(".*?"), "i"),
        );

        const searchResults: TypeAheadSearchResult[] = [];
        let resultTruncated = false;
        for (let rowNo = 0; rowNo < this.totalListViewModel.countRows(); rowNo++) {
            const resultListRowModel = this.totalListViewModel.getRowModelByNo(rowNo);

            const values: string[] = [];
            this.searchFieldNames.forEach((fieldName) =>
                values.push(resultListRowModel.getValue(fieldName).getString()),
            );

            if (this.isSearchMatch(values, searchExpressions)) {
                searchResults.push(
                    new TypeAheadSearchResult(rowNo).computeScore(searchWords, values),
                );
            }

            if (searchResults.length >= TypeAheadSearch.MAX_RESULT_SIZE) {
                resultTruncated = true;
                break;
            }
        }

        searchResults.sort((result1, result2) => result1.getScore() - result2.getScore());

        const resultListRowModels: ListRowModel[] = searchResults.map((searchResult) =>
            this.totalListViewModel.getRowModelByNo(searchResult.getRowNo()),
        );

        return new ResultListViewModel(resultListRowModels, resultTruncated);
    }

    private isSearchMatch(values: string[], searchExpressions: RegExp[]): boolean {
        values = values.filter((value) => value && value.length > 0).map((value) => value);

        return this.allSearchTokenMatches(values, searchExpressions);
    }

    private allSearchTokenMatches(values: string[], searchExpressions: RegExp[]): boolean {
        return searchExpressions.reduce(
            (isMatch: boolean, searchExpression) =>
                isMatch && this.anyValueMatchesSearchToken(values, searchExpression),
            true,
        );
    }

    private anyValueMatchesSearchToken(values: string[], searchExpression: RegExp): boolean {
        return values.reduce(
            (isMatch: boolean, value) => isMatch || searchExpression.test(value),
            false,
        );
    }
}

class TypeAheadSearchResult {
    private rowNo: number;

    private score: number = 0;

    constructor(rowNo: number) {
        this.rowNo = rowNo;
    }

    public computeScore(searchWords: string[], values: string[]): TypeAheadSearchResult {
        this.score = searchWords.reduce((score, searchWord) => {
            return (
                score +
                values
                    .map((value) => value.toLowerCase().indexOf(searchWord.toLowerCase()))
                    .map((index) => (index >= 0 ? index : 9999))
                    .reduce((minIndex, index) => Math.min(minIndex, index), 999999)
            );
        }, 0);

        return this;
    }

    public getScore(): number {
        return this.score;
    }

    public getRowNo(): number {
        return this.rowNo;
    }
}

class ResultListViewModel implements ListViewModel {
    private resultListRowModels: ListRowModel[] = [];

    private resultTruncated: boolean = false;

    constructor(resultListRowModels: ListRowModel[], resultTruncated: boolean) {
        this.resultListRowModels = resultListRowModels;
        this.resultTruncated = resultTruncated;
    }

    public countRows(): number {
        return this.resultListRowModels.length;
    }

    public getRowModelByNo(rowNo: number): ListRowModel {
        return this.resultListRowModels[rowNo];
    }

    public getRowModelById(entityId: string): ListRowModel {
        const listRowModelWithId = this.resultListRowModels.filter(
            (listRowModel) => listRowModel.getEntityId() == entityId,
        );
        return listRowModelWithId.length > 0 ? listRowModelWithId[0] : null;
    }

    public isResultTruncated(): boolean {
        return this.resultTruncated;
    }
}
