import { ListViewModel } from "../../gui/list/ListViewModel";
import { SyncState } from "../SyncState";
import { I18n } from "../../common/i18n/I18n";
import { SyncStateListRowModel } from "./SyncStateListRowModel";

export class SyncStateListViewModel implements ListViewModel {
    private syncStates: SyncState[];

    private syncStatesById: { [key: string]: SyncState } = {};

    private i18n: I18n;

    constructor(syncStates: SyncState[], i18n: I18n) {
        this.syncStates = syncStates;
        this.syncStates.forEach(
            (syncState) => (this.syncStatesById[syncState.getId()] = syncState),
        );

        this.i18n = i18n;
    }

    public countRows(): number {
        return this.syncStates.length;
    }

    public getRowModelByNo(rowNo: number): SyncStateListRowModel {
        if (rowNo < 0 || rowNo >= this.syncStates.length) {
            throw new Error(
                "AllowancesListViewModel::InvalidRowNo (" +
                    this.syncStates.length +
                    " rows): " +
                    rowNo,
            );
        }
        return new SyncStateListRowModel(this.syncStates[rowNo], this.i18n);
    }

    public getRowModelById(id: string): SyncStateListRowModel {
        if (!this.syncStatesById.hasOwnProperty(id)) {
            throw new Error("[SyncStateListViewModel] Invalid id " + id);
        }
        return new SyncStateListRowModel(this.syncStatesById[id], this.i18n);
    }

    public isResultTruncated(): boolean {
        return false;
    }
}
