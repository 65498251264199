import { FooterTabBar } from "../../../../board/FooterTabBar";
import { I18n } from "../../../../common/i18n/I18n";
import { AppConsole } from "../../../../common/log/AppConsole";
import { Component } from "../../../../core/Component";
import { Controller } from "../../../../core/Controller";
import { Registry } from "../../../../core/Registry";
import { Animation, AppNavigator } from "../../../../core/Router";
import { GUIContext } from "../../../../gui/GUIContext";
import { GUIElement } from "../../../../gui/GUIElement";
import { GUIPage } from "../../../../gui/GUIPage";
import { ToolBar } from "../../../../gui/navigation/ToolBar";
import { ToolLink } from "../../../../gui/navigation/ToolLink";
import { SyncStateManager } from "../../../../sync/SyncStateManager";
import { AllowanceManager } from "../../AllowanceManager";
import { BusinessTravel } from "../../records/BusinessTravel";
import { TravelDays } from "../../records/TravelDays";
import { TravelDaysView } from "./TravelDaysView";
import { TravelDaysViewModel } from "./TravelDaysViewModel";

export class MealsController implements Controller {
    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static CONTROLLER_PATH = "travel/meals";

    public static PARAMETER_BUSINESS_TRAVEL_ID = "oid";

    public static PARAMETER_DAILY_ID = "dailyAllowanceOid";

    private navigator: AppNavigator;

    private animation: Animation;

    private i18n: I18n;

    private allowanceManager: AllowanceManager;

    private syncStateManager: SyncStateManager;

    private businessTravel: BusinessTravel;

    private dailyAllowanceId: string;

    private travelDays: TravelDays;

    private footerTabBar: FooterTabBar;

    private allowanceWasEdited = false;

    /**
     * Kapselt Zugriff auf Mahlzeiten je Kalendertag des aktuellen Reiseabschnitts für die Bearbeitung.
     *
     * Merkt sich für alle Kalendertag der gesamten Dienstreise, welche Mahlzeiten angegeben wurden.
     */
    private travelDaysViewModel: TravelDaysViewModel;

    private page: GUIPage;

    private travelDaysView: TravelDaysView;

    public getDependencyNames(): string[] {
        return [
            I18n.BCS_COMPONENT_NAME,
            AllowanceManager.BCS_COMPONENT_NAME,
            SyncStateManager.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }) {
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.allowanceManager = <AllowanceManager>(
            depencencyComponents[AllowanceManager.BCS_COMPONENT_NAME]
        );
        this.syncStateManager = <SyncStateManager>(
            depencencyComponents[SyncStateManager.BCS_COMPONENT_NAME]
        );
    }

    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        this.navigator = navigator;
        this.animation = animation;

        if (!this.allowanceManager.getAllowanceRecordingTerms().isAllowanceRecordingAvailable()) {
            this.navigator.navigateTo("index", {}, Animation.SLIDE_RIGHT);
            return;
        }

        const businessTravelId = parameters[MealsController.PARAMETER_BUSINESS_TRAVEL_ID];
        this.dailyAllowanceId = parameters[MealsController.PARAMETER_DAILY_ID];
        if (businessTravelId && this.dailyAllowanceId) {
            const self = this;

            this.allowanceManager
                .fetchAllowanceById(businessTravelId)
                .then((allowance) => {
                    if (allowance) {
                        self.businessTravel = <BusinessTravel>allowance;
                        const travelSection = self.businessTravel.getSectionById(
                            this.dailyAllowanceId,
                        );

                        self.travelDays = travelSection.getTravelDays();
                        self.travelDaysViewModel = new TravelDaysViewModel(
                            travelSection,
                            self.businessTravel.getTravelSections(),
                        );

                        self.composeMeals();
                    } else {
                        this.navigator.navigateTo("travels", {});
                    }
                })
                .catch((error) => {
                    AppConsole.log("ERROR", error);
                }); // TODO App - Fehler anzeigen
        } else {
            this.navigator.navigateTo("travels", {});
        }
    }

    private async composeMeals(): Promise<void> {
        this.page = new GUIPage(
            new GUIContext(this.i18n),
            MealsController.CONTROLLER_PATH,
            this.dailyAllowanceId,
        );

        const headerToolBar = new ToolBar().setId("header_toolbar");

        headerToolBar.addToolLinkLeft(
            new ToolLink()
                .setId("navigateBack")
                .setImageName("icon-chevron-left.svg")
                .onClick(this.popState.bind(this)),
        );
        headerToolBar.setTitle(this.i18n.get("MobileApp.meals.title"));
        headerToolBar.addStyleClass("headBar");

        this.page.addHeaderElement(headerToolBar);

        this.page.addPageElement(this.composeMealsPerDay());

        await this.composeFooterTabBar();

        this.page
            .setAnimation(this.animation, this.navigator.doShowAnimations())
            .compose($("body"));
    }

    private composeMealsPerDay(): GUIElement {
        this.travelDaysView = new TravelDaysView()
            .setI18n(this.i18n)
            .setTravelDaysViewModel(this.travelDaysViewModel)
            .onValueChanged(this.travelDayValueChanged.bind(this));
        return this.travelDaysView;
    }

    private async travelDayValueChanged(): Promise<void> {
        await this.allowanceManager.storeAllowance(this.businessTravel);

        // Nach erster Formularfeldänderung, Zähler der zu synchronisierenden Objekt in Footer-Navigation aktualisieren
        if (!this.allowanceWasEdited) {
            this.allowanceWasEdited = true;

            const self = this;
            await this.footerTabBar.updateSyncBadgeCounter(FooterTabBar.ICON_SYNC, () =>
                self.syncStateManager.countUnsyncedElements(),
            );
        }
    }

    /**
     * Fußzeile (u.a. mit Home-Link)
     */
    private async composeFooterTabBar(): Promise<void> {
        const self = this;
        this.footerTabBar = new FooterTabBar(new GUIContext(this.i18n));
        await this.footerTabBar.composeDefaultFooter(
            null,
            this.navigator,
            this.getNavigateBackParameters(),
            () => self.syncStateManager.countUnsyncedElements(),
        );
        this.page.addFooterElement(this.footerTabBar);
    }

    private getNavigateBackParameters(): { [key: string]: string } {
        return {
            navigateBackPath: MealsController.CONTROLLER_PATH,
            navigateBackParameters: JSON.stringify({
                oid: this.businessTravel.getId(),
                dailyAllowanceOid: this.dailyAllowanceId,
            }),
        };
    }

    public popState(): void {
        this.navigator.navigateTo(
            "travel",
            { oid: this.businessTravel.getId() },
            Animation.SLIDE_RIGHT,
        );
    }

    public destroy(): void {
        // nichts zu tun
    }
}

Registry.registerComponent(MealsController.CONTROLLER_PATH, MealsController);
