import { FormField, formFieldChangeEventType, FormFieldMode } from "./FormField";
import { FormFieldAddOn } from "./FormFieldAddOn";
import { GUIElement } from "../GUIElement";
import { EntityValue } from "../../entities/values/EntityValue";
import { StringValue } from "../../entities/values/StringValue";
import { InputModes } from "./InputModes";

export class StringField implements GUIElement, FormField {
    private type: string = "text";

    private inputMode: string = InputModes.STRING;

    private autocomplete: string;

    private entityId: string;

    private name: string;

    private value: StringValue = new StringValue("");

    private label: string;

    private placeholder: string;

    private mode: FormFieldMode = FormFieldMode.EDIT;

    private readOnly: boolean = false;

    private autocapitalize: boolean = true;

    private spellcheck: boolean = true;

    /**
     * Default: Wir zeigen den Label als extra Zeile vor dem Input Feld an.
     */
    private isShowLabel: boolean = true;

    private formFieldAddOns: FormFieldAddOn[] = [];

    private changeCallback: (changeEvent: formFieldChangeEventType) => void;

    private blurCallback: (changeEvent: formFieldChangeEventType) => void;

    private submitCallback: () => void;

    private $displayTag: JQuery;

    private $inputTag: JQuery;

    private maxLength: number;

    public setType(type: string): StringField {
        this.type = type;
        return this;
    }
    public setInputmode(inputMode: string): StringField {
        this.inputMode = inputMode;
        return this;
    }
    public setAutocomplete(autocomplete: string): StringField {
        this.autocomplete = autocomplete;
        return this;
    }

    public setReadOnly(readOnly: boolean): StringField {
        this.readOnly = readOnly;
        return this;
    }

    setMaxLength(length: number): StringField {
        this.maxLength = length;
        return this;
    }
    public isReadOnly(): boolean {
        return this.readOnly;
    }

    public setAutoCapitalize(autocapitalize: boolean): StringField {
        this.autocapitalize = autocapitalize;
        return this;
    }

    public setSpellCheck(spellcheck: boolean): StringField {
        this.spellcheck = spellcheck;
        return this;
    }

    public setEntityId(entityId: string): StringField {
        this.entityId = entityId;
        return this;
    }

    public setName(name: string): StringField {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setValue(value: StringValue): StringField {
        this.value = value;
        return this;
    }

    public setLabel(label: string): StringField {
        this.label = label;
        return this;
    }

    public setIfUndefinedLabelAndReadOnly(label: string): StringField {
        this.label = label;
        this.mode = FormFieldMode.DISPLAY;
        return this;
    }

    public setPlaceholder(placeholder: string): StringField {
        this.placeholder = placeholder;
        return this;
    }

    public setMode(mode: FormFieldMode): StringField {
        this.mode = mode;
        return this;
    }

    public getMode(): FormFieldMode {
        return this.mode;
    }

    public registerFormFieldAddOn(formFieldAddOn: FormFieldAddOn): StringField {
        this.formFieldAddOns.push(formFieldAddOn);
        return this;
    }

    public onChange(changeCallback: (changeEvent: formFieldChangeEventType) => void): StringField {
        this.changeCallback = changeCallback;
        return this;
    }

    public onBlur(blurCallback: (blurEvent: formFieldChangeEventType) => void): StringField {
        this.blurCallback = blurCallback;
        return this;
    }

    public onSubmit(submitCallback: () => void): StringField {
        this.submitCallback = submitCallback;
        return this;
    }

    public setShowLabel(showLabel: boolean) {
        this.isShowLabel = showLabel;
        return this;
    }

    public compose($parent: JQuery): void {
        switch (this.mode) {
            case FormFieldMode.DISPLAY:
                this.composeDisplayMode($parent);
                break;
            case FormFieldMode.EDIT:
            default:
                this.composeEditMode($parent);
                break;
        }
    }

    private composeDisplayMode($parent: JQuery): void {
        if (this.label) {
            $("<label>").appendTo($parent).attr({ for: this.name }).text(this.label);
        }

        this.$displayTag = $("<div>").appendTo($parent).addClass("formfieldDisplayMode");
        this.composeDisplayText();

        this.$inputTag = $("<input>")
            .appendTo($parent)
            .addClass("formfield")
            .attr({
                type: "hidden",
                name: this.name,
            })
            .val(this.value && this.value.isDefined() ? this.value.getString() : "");
    }

    private composeDisplayText(): void {
        const stringValue = this.value && this.value.isDefined() ? this.value.getString() : "";
        this.$displayTag.text(stringValue);

        this.formFieldAddOns.forEach((formFieldAddOn) =>
            formFieldAddOn.applyToTag(this.value, this.$displayTag),
        );
    }

    public appendComposeDisplayText(text: string): void {
        this.$displayTag.append(text);
    }

    private composeEditMode($parent: JQuery): void {
        this.composeFormField($parent);

        this.$inputTag
            .addClass("editMode")
            .on("change", this.valueChanged.bind(this))
            .on("blur", this.fieldBlured.bind(this))
            .keyup(this.keyPressed.bind(this));
        if (this.readOnly) {
            this.$inputTag.prop("readonly", true);
        }
        if (!this.autocapitalize) {
            this.$inputTag.prop("autocapitalize", "off");
        }

        if (!this.spellcheck) {
            this.$inputTag.prop("spellcheck", false);
        }
    }

    private composeFormField($parent: JQuery): void {
        if (this.label) {
            $("<label>").appendTo($parent).attr({ for: this.name }).text(this.label);
        }

        this.$inputTag = $("<input>")
            .appendTo($parent)
            .addClass("formfield")
            .attr({
                type: this.type,
                inputmode: this.inputMode,
                maxlength: this.maxLength,
                autocomplete: this.autocomplete ? this.autocomplete : this.name,
                name: this.name,
                placeholder: this.placeholder,
            })
            .val(this.value && this.value.isDefined() ? this.value.getString() : "");
    }

    public updateValue(value: StringValue): StringField {
        if (this.value != value) {
            this.value = value;
            this.$inputTag.val(this.value.getString());
            if (this.mode == FormFieldMode.DISPLAY) {
                this.composeDisplayText();
            }
        }
        return this;
    }

    public clearValue() {
        this.$inputTag.empty();
    }

    public getValue(): StringValue {
        return new StringValue(<string>this.$inputTag.val());
    }

    private valueChanged(event: Event): void {
        this.updateRequired(this.getValue());
        if (this.changeCallback) {
            event.preventDefault();
            event.stopPropagation();

            this.changeCallback({
                entityId: this.entityId,
                name: this.getName(),
                value: this.getValue(),
            });
        }
    }

    public updateRequired(newValue: EntityValue): void {
        // Nach Auswahl eines Wertes: Markierung als Pflichtfeld entfernen
        if (newValue.isDefined()) {
            this.$inputTag.closest(".required").addClass("notRequired");
        } else {
            this.$inputTag.closest(".required").removeClass("notRequired");
        }
    }

    private fieldBlured(event: Event): void {
        if (this.blurCallback) {
            event.preventDefault();
            event.stopPropagation();

            this.blurCallback({
                entityId: this.entityId,
                name: this.getName(),
                value: this.getValue(),
            });
        }
    }

    private keyPressed(event: KeyboardEvent): void {
        if (event.keyCode == 13 && this.submitCallback) {
            this.submitCallback();
        }
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}
