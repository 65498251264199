import { FooterTabBar } from "../../../../board/FooterTabBar";
import { ServerConfigProperties } from "../../../../common/config/ServerConfigProperties";
import { I18n } from "../../../../common/i18n/I18n";
import { Component } from "../../../../core/Component";
import { Controller } from "../../../../core/Controller";
import { Registry } from "../../../../core/Registry";
import { Animation, AppNavigator } from "../../../../core/Router";
import { HTMLContent } from "../../../../gui/content/HTMLContent";
import { GUIContext } from "../../../../gui/GUIContext";
import { GUIPage } from "../../../../gui/GUIPage";
import { ToolBar } from "../../../../gui/navigation/ToolBar";
import { ToolLink } from "../../../../gui/navigation/ToolLink";
import { SyncStateManager } from "../../../../sync/SyncStateManager";
import { Browser } from "../../../../util/common/Browser";
import { ContactManager } from "../../ContactManager";
import { VCFParser } from "../../methods/VCFParser";
import { Log } from "../../../../common/log/Log";
import { AppConsole } from "../../../../common/log/AppConsole";
import { EntityValue } from "../../../../entities/values/EntityValue";
import { Contact } from "../../Contact";
import { Result } from "@zxing/library/";
import { BrowserQRCodeReader } from "@zxing/browser";

/**
 * Controller für den Scanner. Bindet die Bibliothek zur QR-Code Erkennung ein und leitet den erfassten QR-Code an den Parser weiter.
 */
export class ContactScanController implements Controller {
    // Pfad zum Aufruf der Seite dieses Controllers
    // public static CONTROLLER_PATH = "contactList/Scan";
    public static BCS_COMPONENT_NAME = "scan";
    static mode: string = "environment";
    private navigator: AppNavigator;

    private animation: Animation;

    private i18n: I18n;

    private syncStateManager: SyncStateManager;

    private contactManager: ContactManager;

    private page: GUIPage;

    private footerTabBar: FooterTabBar;

    private vcfParser: VCFParser;
    private codeReader: BrowserQRCodeReader = new BrowserQRCodeReader();

    private static getNavigateBackParameters(): { [key: string]: string } {
        return {
            navigateBackPath: ContactScanController.BCS_COMPONENT_NAME,
        };
    }

    public getDependencyNames(): string[] {
        return [
            I18n.BCS_COMPONENT_NAME,
            SyncStateManager.BCS_COMPONENT_NAME,
            ContactManager.BCS_COMPONENT_NAME,
            ServerConfigProperties.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }): void {
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.syncStateManager = <SyncStateManager>(
            depencencyComponents[SyncStateManager.BCS_COMPONENT_NAME]
        );
        this.contactManager = <ContactManager>(
            depencencyComponents[ContactManager.BCS_COMPONENT_NAME]
        );
    }

    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        this.navigator = navigator;
        this.animation = animation;
        if (!this.contactManager.getContactTerms().isContactRecordingAllowed()) {
            this.navigator.navigateTo("index", {}, Animation.SLIDE_RIGHT);
        }

        this.composeList().catch(console.error);
        this.decodeOnce();
    }

    public composeVideoElement(): void {
        const width: number = 100; // Statische Breite, wird in % umgewandelt
        const height: number = 100; // Statische Höhe, wird in % umgewandelt

        const video = $("<video id='video' ></video>");
        const content = new HTMLContent(video);
        video.css("width", width.toString() + "%");
        video.css("height", height.toString() + "%");
        video.css("margin-top", "25px");
        this.page.addPageElement(content);
    }

    public popState(): void {
        this.navigateUpToList();
    }

    public destroy(): void {
        BrowserQRCodeReader.releaseAllStreams();
    }

    /**
     * Das eigentliche Decoding erfolgt in dieser Methode. Zudem werden verschiedene Fehler abgefangen, etwa wenn keine Berechtigung gewährt wird.
     * @private
     */
    private decodeOnce(): void {
        // Der Code hier...
        if (Browser.isIOSVersion(12) && Browser.isStandalone()) {
            alert(this.i18n.get("MobileApp.Scan.OldIOS"));
            this.navigator.navigateTo("contactList", null, Animation.NONE);
        }

        // ... bis hier kann komplett raus, sobald wir iOS 12.* und Älter nicht mehr unterstützen
        this.codeReader
            .decodeOnceFromVideoDevice(undefined, "video")
            .finally(() => BrowserQRCodeReader.releaseAllStreams())
            .then((result) => this.handleMyCard(result))
            .catch((err) => {
                if (err.toString().includes("Permission denied")) {
                    alert(this.i18n.get("MobileApp.Scan.CameraPermissionDenied"));
                } else if (
                    err
                        .toString()
                        .includes("Video stream has ended before any code could be detected")
                ) {
                    err = null;
                } else if (err.toString().includes("Requested device not found")) {
                    alert(this.i18n.get("MobileApp.Scan.NoCameraOnDevice"));
                } else {
                    Log.error(err);
                }
            });
    }

    private async composeList(): Promise<void> {
        this.page = new GUIPage(
            new GUIContext(this.i18n),
            ContactScanController.BCS_COMPONENT_NAME,
        );

        const headerToolBar = new ToolBar().setId("header_toolbar");

        headerToolBar.addToolLinkLeft(
            new ToolLink()
                .setId("navigateBack")
                .setImageName("icon-chevron-left.svg")
                .onClick(this.popState.bind(this)),
        );
        headerToolBar.setTitle(this.i18n.get("MobileApp.Contacts.List.Scan"));
        headerToolBar.addStyleClass("headBar");
        this.page.addHeaderElement(headerToolBar);
        this.composeVideoElement();
        await this.composeFooterTabBar();
        this.page
            .setAnimation(this.animation, this.navigator.doShowAnimations())
            .compose($("body"));
    }

    /**
     * Fußzeile (u.a. mit Home-Link)
     */
    private async composeFooterTabBar(): Promise<void> {
        const self = this;
        this.footerTabBar = new FooterTabBar(new GUIContext(this.i18n));
        await this.footerTabBar.composeDefaultFooter(
            null,
            this.navigator,
            ContactScanController.getNavigateBackParameters(),
            () => self.syncStateManager.countUnsyncedElements(),
        );

        this.page.addFooterElement(this.footerTabBar);
    }

    private navigateUpToList(): void {
        this.navigator.navigateTo("contactList", {}, Animation.SLIDE_RIGHT);
    }

    /**
     * Übernimmt die Weiterbehandlung der vCard nach dem Scan.
     * @param result - das Ergebnis des Scans
     * @private
     */
    private async handleMyCard(result: Result): Promise<void> {
        this.vcfParser = new VCFParser();
        let myvcard: object;
        // Die folgende Methode bietet die Möglichkeit, direkt nach Einscannen eine VCF-Datei im Telefonspeicher zu sichern. Ist aktuell noch deaktiviert, da es auch den separaten Download in der Kontaktliste gibt
        // TODO: Klären, ob man den "Instant-Download" ggf. mit einem Toggle anschalten können soll.
        // this.vcfFileDownloader.createFileFromScan(result.getText());
        const resultText: string = result.getText();
        AppConsole.debug("The following String was parsed from scanned QR-Code:");
        AppConsole.debug(resultText);
        if (resultText.includes("BEGIN:VCARD") && resultText.includes("END:VCARD")) {
            // Wir prüfen hier sicherheitshalber, ob wirklich eine vCard gescannt wurde
            myvcard = this.vcfParser.parse(resultText);
        }
        this.isThereADuplicate(myvcard).then((duplicateFound) =>
            this.createOrGoBack(duplicateFound, myvcard),
        );
    }

    private async isThereADuplicate(myvcard: {}): Promise<boolean> {
        let userMail: string = myvcard["mail"];
        let duplicateFound: boolean = false;
        if (typeof userMail !== "undefined") {
            userMail = userMail.toString().toLowerCase().trim();
            const contactArray: Contact[] = await this.contactManager.readAllContactsFromDB();
            contactArray.forEach((contact) => {
                const contactMail: EntityValue = contact.getValue("mail");
                if (typeof contactMail !== "undefined") {
                    if (userMail === contactMail.getString().toLowerCase().trim()) {
                        duplicateFound = true;
                    }
                }
            });
            return duplicateFound;
        } else {
            return duplicateFound;
        }
    }

    /**
     * Legt den Kontakt neu an oder springt zurück, falls es ein Duplikat war.
     * @param duplicateFound - wurde ein Duplikat gefunden ?
     * @param myvcard - der Kontakt
     * @private
     */
    private async createOrGoBack(duplicateFound: boolean, myvcard: {}): Promise<void> {
        if (!duplicateFound) {
            this.contactManager
                .saveMyCardAsContact(myvcard)
                .then((oid) =>
                    this.navigator.navigateTo("record", { oid: oid }, Animation.SLIDE_LEFT),
                );
        } else {
            alert(this.i18n.get("MobileApp.Scan.ContactWasDuplicate"));
            this.navigator.navigateTo("contactList", {}, Animation.SLIDE_LEFT);
        }
    }
}

Registry.registerComponent(ContactScanController.BCS_COMPONENT_NAME, ContactScanController);
