import { Controller } from "../../../../core/Controller";
import { AppNavigator, Animation } from "../../../../core/Router";
import { I18n } from "../../../../common/i18n/I18n";
import { BCSFile } from "../../../files/records/BCSFile";
import { GUIPage } from "../../../../gui/GUIPage";
import { FileManager } from "../../../files/FileManager";
import { Component } from "../../../../core/Component";
import { ToolBar } from "../../../../gui/navigation/ToolBar";
import { ToolLink } from "../../../../gui/navigation/ToolLink";
import { Registry } from "../../../../core/Registry";
import { Image } from "../../../../gui/content/Image";
import { PageDeleteMode } from "../../../../gui/navigation/PageDeleteMode";
import { BusinessTravel } from "../../records/BusinessTravel";
import { AllowanceManager } from "../../AllowanceManager";
import { VoucherAllowance } from "../../records/VoucherAllowance";
import { TextLabel } from "../../../../gui/content/TextLabel";
import { TableView } from "../../../../gui/content/TableView";
import { GUIContext } from "../../../../gui/GUIContext";

/**
 * Detailseite für Beleganhänge, die eine Bild-Datei aus der Dateiablage im Vollbild anzeigt.
 *
 * Beleganhang kann über Icon in HeaderToolBar gelöscht werden.
 *
 * Als Parameter werden die Oid der Bilddatei und der anzuzeigende Seitentitel
 * sowie der Pfad und die Parameter (als stringified JSON) zum Aufruf bei Zurück-Navigation erwartet.
 *
 * WICHTIG: Seite zeigt Beleganhänge von Belegspesen, die zu einer Dienstreise gehören ODER Einzel-Belegspesen ohne Dienstreise (businessTravel == null)!
 */
export class VoucherPhotoController implements Controller {
    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static CONTROLLER_PATH = "voucherimage";

    public static readonly PARAMETER_BUSINESS_TRAVEL_ID = "businessTravelOid";

    public static readonly PARAMETER_VOUCHER_ALLOWANCE_ID = "voucherAllowanceOid";

    /** Aufruf-Parameter für die Oid der anzuzeigenden Bilddatei */
    public static PARAMETER_IMAGE_FILE_ID = "imageFileId";

    /** Aufruf-Parameter für I18n-Key des Seitentitles */
    public static PARAMETER_TITLE_KEY = "titleKey";

    /** Aufruf-Parameter für Pfad zur aufzurufende Seite bei Zurück-Navigation */
    public static PARAMETER_NAVIGATE_BACK_PATH = "navigateBackPath";

    /** Aufruf-Parameter für Parameter (als stringified JSON) für aufzurufende Seite bei Zurück-Navigation */
    public static PARAMETER_NAVIGATE_BACK_PARAMETERS = "navigateBackParameters";

    private navigator: AppNavigator;

    private animation: Animation;

    private i18n: I18n;

    private allowanceManager: AllowanceManager;

    private fileManager: FileManager;

    private businessTravel: BusinessTravel;

    private singleVoucherAllowance: VoucherAllowance;

    private imageFile: BCSFile;

    private page: GUIPage;

    /** I18n-Key des Seitentitles */
    private pageTitleKey;

    private headerToolBar: ToolBar;

    private pageDeleteMode: PageDeleteMode;

    /** Pfad zur aufzurufende Seite bei Zurück-Navigation */
    private navigateBackPath;

    /** Parameter für aufzurufende Seite bei Zurück-Navigation */
    private navigateBackParameters;

    public getDependencyNames(): string[] {
        return [
            I18n.BCS_COMPONENT_NAME,
            AllowanceManager.BCS_COMPONENT_NAME,
            FileManager.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }) {
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.allowanceManager = <AllowanceManager>(
            depencencyComponents[AllowanceManager.BCS_COMPONENT_NAME]
        );
        this.fileManager = <FileManager>depencencyComponents[FileManager.BCS_COMPONENT_NAME];
    }

    public compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): void {
        this.navigator = navigator;
        this.animation = animation;

        this.pageTitleKey = parameters[VoucherPhotoController.PARAMETER_TITLE_KEY];
        this.navigateBackPath = parameters[VoucherPhotoController.PARAMETER_NAVIGATE_BACK_PATH];
        this.navigateBackParameters = JSON.parse(
            parameters[VoucherPhotoController.PARAMETER_NAVIGATE_BACK_PARAMETERS] || "{}",
        );

        if (!this.allowanceManager.getAllowanceRecordingTerms().isAllowanceRecordingAvailable()) {
            this.navigator.navigateTo("index", {}, Animation.SLIDE_RIGHT);
            return;
        }

        this.fetchBusinessTravelAndPhoto(parameters).then(() => {
            if (this.imageFile) {
                // Wenn Bild geladen: Seite anzeigen
                this.composePage();
            } else {
                // Wenn Bild nicht geladen: Zurück zur Aufrufer-Seite
                this.navigateUp();
            }
        });
    }

    private async fetchBusinessTravelAndPhoto(parameters: {
        [key: string]: string;
    }): Promise<void> {
        const businessTravelId = parameters[VoucherPhotoController.PARAMETER_BUSINESS_TRAVEL_ID];
        if (businessTravelId) {
            // Dienstreise laden
            this.businessTravel = await (<Promise<BusinessTravel>>(
                this.allowanceManager.fetchAllowanceById(businessTravelId)
            ));
        } else {
            const voucherId = parameters[VoucherPhotoController.PARAMETER_VOUCHER_ALLOWANCE_ID];
            if (voucherId) {
                // Einzel-Belegspese laden
                this.singleVoucherAllowance = await (<Promise<VoucherAllowance>>(
                    this.allowanceManager.fetchAllowanceById(voucherId)
                ));
            }
        }

        const imageFileId = parameters[VoucherPhotoController.PARAMETER_IMAGE_FILE_ID];

        if (imageFileId) {
            // Beleganhang laden
            this.imageFile = await (<Promise<BCSFile>>this.fileManager.fetchFileById(imageFileId));
        }
    }

    private composePage() {
        if ((!this.businessTravel && !this.singleVoucherAllowance) || !this.imageFile) {
            this.navigateUp();
            return;
        }

        // Seite (Wurzelelement) erstellen
        this.page = new GUIPage(
            new GUIContext(this.i18n),
            VoucherPhotoController.CONTROLLER_PATH,
            this.imageFile.getId(),
        );
        this.page.addStyleClass("voucherImage");

        // Kopfzeile (mit Navigation und Bearbeiten-/Löschen-Modus)
        this.composeHeader();

        // Belegfoto anzeigen
        this.composePhotoOrPDF();

        // Wenn Löschen der  Dienstreise bzw. der Einzel-Belegspese erlaubt, Löschen des Belegahanhangs anbieten
        if (
            (this.businessTravel && this.businessTravel.isDeletable()) ||
            (this.singleVoucherAllowance && this.singleVoucherAllowance.isDeletable())
        ) {
            this.pageDeleteMode = new PageDeleteMode()
                .setPage(this.page)
                .setHeaderToolBar(this.headerToolBar)
                .setDeleteButtonLabel(this.i18n.get("MobileApp.voucherimage.deleteVoucherPhoto"))
                .onDelete(this.deleteVoucherPhoto.bind(this));
        }

        // Seite rendern
        this.page
            .setAnimation(this.animation, this.navigator.doShowAnimations())
            .compose($("body"));
    }

    private composeHeader(): void {
        this.headerToolBar = new ToolBar()
            .setId("header_toolbar")
            .setTitle(this.i18n.get(this.pageTitleKey))
            .addStyleClass("headBar")
            .addToolLinkLeft(
                new ToolLink()
                    .setId("navigateBack")
                    .setImageName("icon-chevron-left.svg")
                    .onClick(this.popState.bind(this)),
            );
        this.page.addHeaderElement(this.headerToolBar);
    }

    private composePhotoOrPDF(): void {
        if (!this.imageFile.isPDF()) {
            this.composePhoto();
        } else {
            this.composePDF();
        }
    }

    private composePhoto(): void {
        const image = new Image()
            .addStyleClass("imagefile")
            .setFocusable(true)
            .setImageDataURL(this.imageFile.getImageDataURL());

        this.page.addPageElement(image);
    }

    private composePDF(): void {
        const table = new TableView().addStyle("voucherPDFDetail");

        const row1 = table.newRow();
        row1.newCell().addContentElement(new Image().setImageName("pdf.svg"));
        const row2 = table.newRow();
        row2.newCell().addContentElement(
            new TextLabel().setInlineText(this.imageFile.getFilename()),
        );

        this.page.addPageElement(table);
    }

    private deleteVoucherPhoto(): void {
        this.navigateBackParameters["affirmations"] = "voucherPhotoDeleted";

        this.fileManager
            .deleteFile(this.imageFile.getId())
            .then(() =>
                this.navigator.navigateTo(
                    this.navigateBackPath,
                    this.navigateBackParameters,
                    Animation.SLIDE_RIGHT,
                ),
            );
    }

    public popState(): void {
        if (this.pageDeleteMode && this.pageDeleteMode.isDeleteModeActive()) {
            this.pageDeleteMode.leaveDeleteMode();
        } else {
            this.navigateUp();
        }
    }

    private navigateUp(): void {
        this.navigator.navigateTo(
            this.navigateBackPath,
            this.navigateBackParameters,
            Animation.SLIDE_RIGHT,
        );
    }

    public destroy(): void {
        // nichts zu tun
    }
}

Registry.registerComponent(VoucherPhotoController.CONTROLLER_PATH, VoucherPhotoController);
