import { GUIElement } from "../../gui/GUIElement";
import { ListView } from "../../gui/list/ListView";
import { BookingsPerDay, DayBookingStructure } from "./bookings/BookingsPerDay";
import { Booking, BookingType } from "./bookings/Booking";
import { Timesheet } from "./timesheet/Timesheet";
import { DaySelector } from "../../gui/content/DaySelector";
import { TimeRecordingAttendanceSummary } from "./TimeRecordingAttendanceSummary";
import { ListRow } from "../../gui/list/ListRow";
import { TextLabel } from "../../gui/content/TextLabel";
import { HTMLContent } from "../../gui/content/HTMLContent";
import { GUIContext } from "../../gui/GUIContext";
import { TimeRecordingRowBackground } from "../../gui/content/TimeRecordingRowBackground";
import { FlexBox, FlexJustifyContent } from "../../gui/flexbox/FlexBox";
import { TimeSpan } from "./timespans/TimeSpan";
import { TimeRecord } from "./TimeRecord";
import { TimeRecordingDetailController } from "./TimeRecordingDetailController";
import { Appointment } from "./timesheet/Appointment";
import { DummyBooking } from "./DummyBooking";
import { I18n } from "../../common/i18n/I18n";
import { Task } from "./timesheet/Task";
import { Workflow } from "./timesheet/Workflow";
import { Log } from "../../common/log/Log";
import { PreferencesManager } from "../../common/preferences/PreferencesManager";
import { BCSDate } from "../../common/BCSDate";

export class TimeRecordingList implements GUIElement {
    private styleClasses: string[] = [];

    private bookingListView: ListView;
    private timesheet: Timesheet;
    private daySelector: DaySelector;
    private bookings: TimeRecord[];
    private timespans: TimeSpan[];
    private appointments: Appointment[];

    private wrapper: JQuery;

    private clickBookingCallback: Function;
    private dummyBookings: TimeRecord[] = [];
    private context: GUIContext;
    private i18n: I18n;

    private taskNotAvaliableClickCallback: () => void;
    private preferencesManager: PreferencesManager;
    private bookingsPerDay: BookingsPerDay;

    constructor(
        bookings: Booking[],
        timesheet: Timesheet,
        timespans: TimeSpan[],
        appointments: Appointment[],
        context: GUIContext,
        daySelector: DaySelector,
        preferencesManager: PreferencesManager,
    ) {
        this.preferencesManager = preferencesManager;
        this.bookings = bookings;
        this.timesheet = timesheet;
        this.timespans = timespans;
        this.appointments = appointments;
        this.context = context;
        this.i18n = context.getI18n();
        this.daySelector = daySelector;
    }

    public compose($parent: JQuery): void {
        this.wrapper = $("<div class='timerecordinglist'>");
        this.composeDaySeletcor(this.wrapper);
        this.composeBookingList(this.wrapper);

        $parent.append(this.wrapper);
    }

    public setBookingTaskNotAvaliableClickCallback(taskNotAvaliableClickCallback: () => void) {
        this.taskNotAvaliableClickCallback = taskNotAvaliableClickCallback;
    }

    public onChangeDay(daySelector: DaySelector) {
        this.daySelector = daySelector;
        this.wrapper.empty();
        this.composeDaySeletcor(this.wrapper);
        this.composeBookingList(this.wrapper);
    }

    public setBookings(bookings: Booking[]) {
        this.bookings = bookings;
    }

    public addClickBookingListener(clickBookingCallback: Function) {
        this.clickBookingCallback = clickBookingCallback;
    }

    public addStyleClass(styleClass: string): TimeRecordingList {
        this.styleClasses.push(styleClass);
        return this;
    }

    getComponentChildren(): GUIElement[] {
        return [this.bookingListView, this.daySelector];
    }

    private composeBookingList($parent: JQuery) {
        const selectedDate = this.daySelector.getShownDate();
        this.bookingsPerDay = this.context.getBookingsPerDay();

        this.bookingListView = new ListView().addStyleClass("timeRecordingOverview");

        for (let i = 0; i < this.appointments.length; i++) {
            const appointment: Appointment = this.appointments[i];
            const appointmentOid: string = appointment.getId();
            let appointmentBooking: TimeRecord;
            if (appointment.isFulltimmEvent()) {
                appointmentBooking = new DummyBooking(
                    null,
                    null,
                    appointment.getEndTime(),
                    selectedDate.getDate(),
                ).setToAppointment(appointment);
            } else {
                appointmentBooking = new DummyBooking(
                    appointment.getStartDateTime(),
                    appointment.getEndDateTime(),
                    appointment.getDuration(),
                    selectedDate.getDate(),
                ).setToAppointment(appointment);
            }
            const bookingsAtDay = this.bookingsPerDay.getBookingsAtDay(selectedDate);

            /* Wenn der Termin schon zu einer Buchung gemacht wurde, wollen wie den Dummy den Termin nicht mehr anzeigen.
               Wenn es noch keine Buchungen gibt, zeigen wir den Termin Dummy an.*/
            var shouldAddAppointmentToView = true;
            if (typeof bookingsAtDay !== "undefined" && bookingsAtDay !== null) {
                const bookings: TimeRecord[] = bookingsAtDay.getBookings();
                bookings.forEach((booking) => {
                    if (booking.isAppointment()) {
                        if (booking.getValue("effortEventRefOid").getString() === appointmentOid) {
                            shouldAddAppointmentToView = false;
                        }
                    }
                });
            }
            if (shouldAddAppointmentToView) {
                this.dummyBookings.push(appointmentBooking);
            }
        }
        this.sortAndAddAppointmentBooking(selectedDate);

        const self: TimeRecordingList = this;

        const windowWidth = $(window).width();
        const attendanceSummaryHeight = 23;

        const selectedDayBookings: DayBookingStructure =
            this.bookingsPerDay.getNoAttendanceBookingsAtDay(selectedDate);
        let hasBookingsToShow: boolean = false;

        var bookingsForAttendance = this.bookingsPerDay.getAttendancesToBookingAtDay(
            selectedDate.format("DD-MM-YYYY"),
        );
        let hasAttendances = false;

        // MIT Zeit:
        if (
            typeof bookingsForAttendance !== "undefined" &&
            Object.keys(bookingsForAttendance).length > 0
        ) {
            for (var attandanceKey in bookingsForAttendance) {
                hasBookingsToShow = true;
                if (bookingsForAttendance.hasOwnProperty(attandanceKey)) {
                    var currentAttendanceToBooking = bookingsForAttendance[attandanceKey];
                    var attendance = currentAttendanceToBooking.getAttendance();
                    var bookings = currentAttendanceToBooking.getBookingsWithDummys();
                    var timeRecordingAttendanceSummary = new TimeRecordingAttendanceSummary(
                        attendance.getStartTime(),
                        attendance.getEndTime(),
                        windowWidth,
                        attendanceSummaryHeight,
                        attendance,
                        bookings,
                    );
                    hasAttendances = true;
                    const listRow = new ListRow();
                    listRow.addStyleClass("attendanceSummary");
                    const attendanceID = attendance.getId();
                    listRow.onClick(function () {
                        self.attendanceClicked(attendanceID);
                    });
                    listRow.addContentElement(timeRecordingAttendanceSummary);
                    listRow.setName(attendance.getId());
                    this.bookingListView.addRow(listRow);

                    for (let i = 0; i < bookings.length; i++) {
                        const booking = bookings[i];
                        if (!booking.isMiscellaneousEffort()) {
                            const listRow = new ListRow().onClick(function (event) {
                                self.bookingClicked(event, booking);
                            });

                            this.composeBookingRow(
                                listRow,
                                booking,
                                timeRecordingAttendanceSummary,
                            );
                            listRow.setName(booking.getId());
                            this.bookingListView.addRow(listRow);
                        }
                    }
                }
                //this.bookingListView.compose($parent);
            }
        }
        // Nur DAUER:
        var bookingsForAttendance = this.bookingsPerDay.getAttendancesWithoutTimeToBookingAtDay(
            selectedDate.format("DD-MM-YYYY"),
        );
        if (
            typeof bookingsForAttendance !== "undefined" &&
            Object.keys(bookingsForAttendance).length > 0
        ) {
            for (var attandanceKey in bookingsForAttendance) {
                hasBookingsToShow = true;
                if (bookingsForAttendance.hasOwnProperty(attandanceKey)) {
                    var currentAttendanceToBooking = bookingsForAttendance[attandanceKey];
                    var attendance = currentAttendanceToBooking.getAttendance();
                    var bookings = currentAttendanceToBooking.getBookingsWithDummys();
                    var timeRecordingAttendanceSummary = new TimeRecordingAttendanceSummary(
                        attendance.getStartTime(),
                        attendance.getEndTime(),
                        windowWidth,
                        attendanceSummaryHeight,
                        attendance,
                        bookings,
                    );
                    hasAttendances = true;
                    const listRow = new ListRow();
                    listRow.addStyleClass("attendanceSummary");
                    const attendanceID = attendance.getId();
                    listRow.onClick(function () {
                        self.attendanceClicked(attendanceID);
                    });
                    listRow.addContentElement(timeRecordingAttendanceSummary);
                    listRow.setName(attendance.getId());
                    this.bookingListView.addRow(listRow);

                    for (let i = 0; i < bookings.length; i++) {
                        const booking = bookings[i];
                        if (!booking.isMiscellaneousEffort()) {
                            const textLabel = new TextLabel();
                            const listRow = new ListRow();

                            listRow.onClick(function (event) {
                                self.bookingClicked(event, booking);
                            });

                            this.composeBookingRow(
                                listRow,
                                booking,
                                timeRecordingAttendanceSummary,
                            );
                            listRow.setName(booking.getId());
                            this.bookingListView.addRow(listRow);
                        }
                    }
                }
                //this.bookingListView.compose($parent);
            }
        }

        // Keiner Anwesenheit zugeordnet:
        if (selectedDayBookings !== null) {
            const onlyDuration: TimeRecord[] =
                this.bookingsPerDay.getWithoutAttendancesWithoutTimeToBooking();
            const withTime: TimeRecord[] = this.bookingsPerDay.getWithoutAttendancesToBooking();

            const chunkedBookings: [TimeRecord[]] = [withTime]; //selectedDayBookings.getChunkedBookings();
            chunkedBookings.push(onlyDuration);

            if (chunkedBookings.length > 0 && (onlyDuration.length > 0 || withTime.length > 0)) {
                const listRow = new ListRow();
                if (this.preferencesManager.getEffortRecordingWithAttendances()) {
                    listRow.addStyleClass("attendanceSummary");
                    const headline: string = hasAttendances
                        ? this.i18n.get("MobileApp.timeRecordingOverview.noAttendanceForBooking") +
                          ":"
                        : this.i18n.get("MobileApp.timeRecordingOverview.noAttendanceAtAll");
                    listRow.addContentElement(
                        new TextLabel().setText(headline).addStyleClass("noAttendanceLabel"),
                    );
                    this.bookingListView.addRow(listRow);
                }
                for (let chunkIndex = 0; chunkIndex < chunkedBookings.length; chunkIndex++) {
                    hasBookingsToShow = true;
                    var bookings = chunkedBookings[chunkIndex];
                    const firstBooking = bookings[0];
                    if (typeof firstBooking !== "undefined") {
                        const lastBooking = bookings[bookings.length - 1];

                        for (let i = 0; i < bookings.length; i++) {
                            const booking = bookings[i];
                            if (!booking.isMiscellaneousEffort()) {
                                // let listRow2 = new ListRow();
                                // listRow2.addStyleClass("attendanceSummary");

                                var timeRecordingAttendanceSummary =
                                    new TimeRecordingAttendanceSummary(
                                        booking.getStartTime(),
                                        booking.getEndTime(),
                                        windowWidth,
                                        attendanceSummaryHeight,
                                        booking,
                                        bookings,
                                    );

                                const textLabel = new TextLabel();
                                const listRow = new ListRow().onClick(function (event) {
                                    self.bookingClicked(event, booking);
                                });

                                listRow.addStyleClass("nonAttributed");

                                // listRow2.addContentElement(timeRecordingAttendanceSummary);
                                // this.bookingListView.addRow(listRow2);

                                this.composeBookingRow(
                                    listRow,
                                    booking,
                                    timeRecordingAttendanceSummary,
                                );
                                listRow.setName(booking.getId());
                                this.bookingListView.addRow(listRow);
                            }
                        }
                    }
                }
            }
        }

        if (hasBookingsToShow) {
            this.bookingListView.compose($parent);
        } else {
            const htmlContent = new HTMLContent(
                "<div class='nobookings'>" +
                    this.context.getI18n().get("MobileApp.noBookings") +
                    "</div>",
            );
            htmlContent.compose($parent);
        }
    }

    private async composeBookingRow(
        listRow: ListRow,
        booking: TimeRecord,
        timeRecordingAttendanceSummary: TimeRecordingAttendanceSummary,
    ) {
        // TODO: Jens- 08.2018 schöner strukturieren + TimeRecordingRowBackground muss die Entscheidungen nciht erneut treffen
        let name: string = "";
        let pspPath_label: string = "";
        if (booking.isDummy()) {
            const dummy: DummyBooking = <DummyBooking>booking;
            listRow.addStyleClass("isDummy");
            name = "";
            if (dummy.isAppointment()) {
                const appointment = dummy.getAppointment();

                name = appointment.getName();
                listRow.addStyleClass("isAppointmentDummy");
            } else {
                listRow.addStyleClass("isDummy");
            }
        } else if (booking.isPause()) {
            listRow.addStyleClass("isPause");
            name = this.i18n.get("MobileApp.pause");
        } else {
            listRow.addStyleClass("isTimeRecord");
            const bookingObj: Booking = <Booking>booking;

            if (!bookingObj.isDummy()) {
                if (bookingObj.getBookingType() === BookingType.Ticket) {
                    const annotationId = bookingObj.getAnnotationID();
                    const ticket = this.timesheet.getTicketById(annotationId);
                    if (typeof ticket !== "undefined") {
                        pspPath_label = ticket.getDisplayPSPPath();
                        name = ticket.getName();
                    } else {
                        name = this.computeBookingNotAvaliable(listRow, annotationId);
                    }
                } else if (bookingObj.getBookingType() === BookingType.Requirement) {
                    const requirementId = bookingObj.getRequirementID();
                    const requirement = this.timesheet.getRequirementById(requirementId);
                    if (typeof requirement !== "undefined") {
                        pspPath_label = requirement.getDisplayPSPPath();
                        name = requirement.getName();
                    } else {
                        name = this.computeBookingNotAvaliable(listRow, requirementId);
                    }
                } else if (bookingObj.getBookingType() === BookingType.Workflow) {
                    const workflowId = bookingObj.getWorkflowID();
                    const workflow: Workflow = this.timesheet.getWorkflowById(workflowId);
                    if (typeof workflow !== "undefined") {
                        pspPath_label = workflow.getDisplayPSPPath();
                        name = workflow.getName();
                    } else {
                        name = this.computeBookingNotAvaliable(listRow, workflowId);
                    }
                } else if (bookingObj.getBookingType() === BookingType.Appointment) {
                    const appointmentId = bookingObj.getAppointmentID();
                    const appointment: Appointment =
                        this.timesheet.getAppointmentById(appointmentId);
                    if (typeof appointment !== "undefined") {
                        pspPath_label = appointment.getDisplayPSPPath();
                        name = appointment.getName();
                    } else {
                        name = this.computeBookingNotAvaliable(listRow, appointmentId);
                    }
                } else {
                    const taskId = bookingObj.getEffortTargetId();
                    if (typeof this.timesheet.getTasksById(taskId) !== "undefined") {
                        const task: Task = this.timesheet.getTasksById(taskId);
                        pspPath_label = task.getDisplayPSPPath();
                        name = this.timesheet.getTasksById(taskId).getName();
                    } else {
                        name = this.computeBookingNotAvaliable(listRow, taskId);
                    }
                }
            }
        }

        if (booking.hasSyncState()) {
            if (booking.isSyncOnlyChangedInApp()) {
                listRow.addStyleClass("isSyncOnlyChangedInApp");
            }
        }

        const effort: number = booking.getEffortExpense();
        const text = new TextLabel().setText(name);
        text.addStyleClass("descriptionText");

        const pspPath = new TextLabel().setText(pspPath_label);
        pspPath.addStyleClass("row_pspPath");
        const effortMinutes = effort % 60;
        let effortMinuteLabel: string = "";
        if (effortMinutes < 10) {
            effortMinuteLabel = "0" + effortMinutes;
        } else {
            effortMinuteLabel = "" + effortMinutes;
        }
        const unitLabel = this.i18n.get("MobileApp.txtHoursShort", "h");

        const duration = new TextLabel().setText(
            Math.floor(effort / 60) + ":" + effortMinuteLabel + unitLabel,
        );
        duration.addStyleClass("durationText");

        listRow.addContentElement(
            new TimeRecordingRowBackground(booking, timeRecordingAttendanceSummary),
        );

        const box = new FlexBox().setJustifyContent(FlexJustifyContent.SPACE_BETWEEN);

        const descriptionPart = box.newFlexItem().setFlexBasis("90%");
        descriptionPart.addContentElement(text);
        descriptionPart.addContentElement(pspPath);
        descriptionPart.addStyleClass("taskDescription");

        const durationPart = box.newFlexItem().setFlexBasis("10%");
        durationPart.addContentElement(duration);

        listRow.addContentElement(box);
    }

    /**
     * Behandelt die zwei Fälle in denen bei Buchungen keine Aufgaben hinterlegt sind:
     *
     * 1) Buchungen bei denen die Aufgabe nicht synchroniert wurde, sind nicht bearbeitbar und bekommen einen Hinweistext und ein entsprechendes Label
     * 2) Buchungen bei denen noch keine Aufgabe ausgewählt wurde, bekommen ein entsprechendes Label
     *
     * @param effortTargetId Oid der Aufgabe, Ticket, ..., falls undefined, gilt Fall 2)
     */
    private computeBookingNotAvaliable(listRow: ListRow, effortTargetId: string) {
        if (
            typeof effortTargetId === "undefined" ||
            effortTargetId === "" ||
            effortTargetId === null
        ) {
            // Fall 1:
            const bookingNotAvaliableText = this.i18n.get("MobileApp.noBookingTarget");
            return bookingNotAvaliableText;
        } else {
            // Fall 2:
            // Setzten Fülltext für das bebuchbare Objekt ein, damit der Kunde weißt, dass es kein Fehler ist, dass hier kein Objekt verfügbar ist.
            const bookingNotAvaliableText = this.i18n.get("MobileApp.bookingNotAvaliableText");
            // Sorgen zum Einen dafür, dass der Klick auf die Zeile die Buchung nicht beareitbar macht
            // Zum anderen Wollen wir den Benutzer darüber informieren, warum diese Buchung keine Details hat.
            if (typeof this.taskNotAvaliableClickCallback !== "undefined") {
                listRow.onClick(this.taskNotAvaliableClickCallback);
            } else {
                Log.error("No TaskNotAvaliableClickCallback defined.");
            }
            return bookingNotAvaliableText;
        }
    }

    private composeDaySeletcor($parent: JQuery) {
        this.daySelector.compose($parent);
    }

    private bookingClicked(event: Event, clickedBooking: TimeRecord): void {
        this.clickBookingCallback({
            navigate: TimeRecordingDetailController.BCS_COMPONENT_NAME,
            parameters: {
                oid: clickedBooking.getId(),
                isDummy: clickedBooking.isDummy(),
                booking: clickedBooking,
                event: event,
            },
        });
    }

    private attendanceClicked(id: string): void {
        this.clickBookingCallback({
            navigate: TimeRecordingDetailController.BCS_COMPONENT_NAME,
            parameters: { oid: id },
        });
    }

    private sortAndAddAppointmentBooking(selectedDate: BCSDate) {
        this.dummyBookings = this.dummyBookings.sort((rec1, rec2) => {
            if (rec1.getStartTime() > rec2.getStartTime()) {
                return 1;
            }

            if (rec1.getStartTime() < rec2.getStartTime()) {
                return -1;
            }
            return 0;
        });

        for (let i: number = 0; i < this.dummyBookings.length; i++) {
            this.bookingsPerDay.addAppointmentBooking(this.dummyBookings[i], selectedDate);
        }
    }
}
