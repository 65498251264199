export class GUIEventPool {
    private controllerListener: {
        function: Function;
        componentName: string;
        componentEventName: string;
    }[] = [];

    public registerEventListener(
        componentName: string,
        componentEventName: string,
        controllerListenerFn: Function,
    ) {
        const controllerListenerItem = {
            function: controllerListenerFn,
            componentName: componentName,
            componentEventName: componentEventName,
        };

        this.controllerListener.push(controllerListenerItem);
    }

    public triggerEvent(
        componentName: string,
        componentEventName: string,
        event: Event,
        eventContext: Object,
    ) {
        for (let i = 0; i < this.controllerListener.length; i++) {
            const currentListener = this.controllerListener[i];

            const matchesComponenName = currentListener["componentName"] == componentName;
            const matchesComponentEventName =
                currentListener["componentEventName"] == componentEventName;

            if (matchesComponenName && matchesComponentEventName) {
                currentListener["function"](event, eventContext);
            }
        }
    }
}
