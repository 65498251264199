export class StringTools {
    private static timestamp: number = new Date().getTime() - 1422745200000;

    /**
     *
     */
    public static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}
