import { BoardController } from "../../../board/BoardController";
import { FooterTabBar } from "../../../board/FooterTabBar";
import { UserSession } from "../../../common/auth/UserSession";
import { BCSDate } from "../../../common/BCSDate";
import { ServerConfigProperties } from "../../../common/config/ServerConfigProperties";
import { I18n } from "../../../common/i18n/I18n";
import { AppConsole } from "../../../common/log/AppConsole";
import { Schema } from "../../../common/schema/Schema";
import { Component } from "../../../core/Component";
import { Controller } from "../../../core/Controller";
import { MessageEntity, MessageType } from "../../../core/Message/MessageEntity";
import { MessagePool } from "../../../core/Message/MessagePool";
import { Registry } from "../../../core/Registry";
import { Animation, AppNavigator } from "../../../core/Router";
import { DateTimeValue } from "../../../entities/values/DateTimeValue";
import { OidValue } from "../../../entities/values/OidValue";
import { formChangeEventType } from "../../../gui/form/Form";
import { GUIContext } from "../../../gui/GUIContext";
import { GUIPage } from "../../../gui/GUIPage";
import { ListFieldFeedback, ListFieldFeedbackType } from "../../../gui/list/ListFieldFeedback";
import { ListView } from "../../../gui/list/ListView";
import { ListViewContext } from "../../../gui/list/ListViewContext";
import { ToolBar } from "../../../gui/navigation/ToolBar";
import { ToolLink } from "../../../gui/navigation/ToolLink";
import { SyncStateManager } from "../../../sync/SyncStateManager";
import { TimeRecordingManager } from "../TimeRecordingManager";
import { ForecastListViewModel } from "./ForecastListViewModel";
import { ForecastManager } from "./ForecastManager";
import { ForecastRecord } from "./ForecastRecord";

export class ForecastController implements Controller {
    public static BCS_COMPONENT_NAME = "forecast";

    /** Pfad zum Aufruf der Seite dieses Controllers */
    public static CONTROLLER_PATH = "timeRecordingDetail/forecast";

    public static PARAMETER_TASK_ID = "taskoid";

    public static PARAMETER_SYNC_FORECAST_ID = "sync_forecast_oid";

    public static PARAMETER_RETURN_MESSAGE = "returnMessage";

    /** Präfix für alle Seiten-Parameter, mit denen (ohne Präfix) die Rückkehrseite augerufen werden soll. */
    public static readonly PARAMETER_RETURN_PARAMETER_PREFIX = "return-parameter-";
    /** Seiten-Parameter Pfad der Seite, zu der nach getrofener Auswahl zurückgekehrt werden soll. */
    public static readonly PARAMETER_RETURN_PATH = "return-path";
    public FORECAST_LIST_VIEW_DEF = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "icon-task-grey",
                            name: "attachmentComponent",
                            mode: "display",
                            styleClass: "effortTargetOid_row",
                            readonly: "readonly",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            readonly: "readonly",
                            name: "pspPath",
                            mode: "display",
                            styleClass: "underlayingEffortTarget",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            label: "MobileApp.task.remainingDuration",
                            labelIfUndefined: "MobileApp.noBookingTarget",
                            name: "attachmentDuration",
                            mode: "edit",
                            additionalParameters: {
                                "duration.alwaysShowDays": "true",
                            },
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "description",
                            mode: "edit",
                        },
                    },
                    {
                        class: "button",
                        field: {
                            label: "MobileApp.timerecording_detail.saveButton",
                            icon: "NONE",
                            readonly: "readonly",
                            name: "saveAnnotationBtn",
                            styleClass: "sendAnnotationEffort",
                        },
                    },
                ],
            },
        ],
    };
    private navigator: AppNavigator;
    private animation: Animation;
    private i18n: I18n;
    private context: GUIContext;
    private page: GUIPage;
    private serverConfigProperties: ServerConfigProperties;
    private forecastManager: ForecastManager;
    private timeRecordingManager: TimeRecordingManager;
    private syncStateManager: SyncStateManager;
    private forecastListView: ListView;
    private footerTabBar: FooterTabBar;
    private schema: Schema;
    private userSession: UserSession;
    /** Parameter, mit der diese Seite aufgerufen wurde */
    private pageParameters: object;
    private messagePool: MessagePool;

    public getDependencyNames(): string[] {
        return [
            I18n.BCS_COMPONENT_NAME,
            SyncStateManager.BCS_COMPONENT_NAME,
            TimeRecordingManager.BCS_COMPONENT_NAME,
            ServerConfigProperties.BCS_COMPONENT_NAME,
            Schema.BCS_COMPONENT_NAME,
            UserSession.BCS_COMPONENT_NAME,
            ForecastManager.BCS_COMPONENT_NAME,
            MessagePool.BCS_COMPONENT_NAME,
        ];
    }

    public init(depencencyComponents: { [key: string]: Component }) {
        this.i18n = <I18n>depencencyComponents[I18n.BCS_COMPONENT_NAME];
        this.syncStateManager = <SyncStateManager>(
            depencencyComponents[SyncStateManager.BCS_COMPONENT_NAME]
        );
        this.schema = <Schema>depencencyComponents[Schema.BCS_COMPONENT_NAME];
        this.timeRecordingManager = <TimeRecordingManager>(
            depencencyComponents[TimeRecordingManager.BCS_COMPONENT_NAME]
        );
        this.serverConfigProperties = <ServerConfigProperties>(
            depencencyComponents[ServerConfigProperties.BCS_COMPONENT_NAME]
        );
        this.userSession = <UserSession>depencencyComponents[UserSession.BCS_COMPONENT_NAME];
        this.forecastManager = <ForecastManager>(
            depencencyComponents[ForecastManager.BCS_COMPONENT_NAME]
        );
        this.messagePool = <MessagePool>depencencyComponents[MessagePool.BCS_COMPONENT_NAME];

        this.context = new GUIContext(this.i18n);
    }

    public async compose(
        parameters: { [key: string]: string },
        animation: Animation,
        navigator: AppNavigator,
    ): Promise<void> {
        this.navigator = navigator;
        this.animation = animation;
        this.pageParameters = parameters;
        let taskOid = parameters[ForecastController.PARAMETER_TASK_ID];

        // Wenn wir von der Sync-Seite aufgerufen werden, dann holen wir den Forecast aus den Parametern
        const forecastFromSyncPageId = parameters[ForecastController.PARAMETER_SYNC_FORECAST_ID];
        if (forecastFromSyncPageId) {
            const foreCast = await this.forecastManager.getForecastById(forecastFromSyncPageId);
            taskOid = foreCast.getAttachmentOid();
        }

        if (taskOid) {
            const self = this;

            this.timeRecordingManager
                .getTimesheetTask(taskOid)
                .then((task) => {
                    const foreCast = new ForecastRecord(this.schema, {});
                    foreCast.setValue(
                        "attachmentComponent",
                        OidValue.fromOidAndName(task.getId(), task.getName()),
                    );
                    foreCast.setValue(
                        "attachmentDuration",
                        task.getValue("task__remainingDuration"),
                    );
                    //     "attachmentEstimation": this.forecastRecord.estimation,
                    foreCast.setValue(
                        "attachmentOwner",
                        new OidValue(this.userSession.getCurrentUserOid()),
                    );
                    foreCast.setValue(
                        "attachmentDate",
                        new DateTimeValue(BCSDate.now().getISODatTime()),
                    ),
                        foreCast.setValue(
                            "oid",
                            new OidValue("appForecastRecord_" + BCSDate.now().getDate().getTime()),
                        );
                    foreCast.setPspDisplayPath(task.getDisplayPSPPath());
                    self.composeForecast(foreCast);
                })
                .catch((error) => {
                    AppConsole.log("ERROR", error);
                }); // TODO Fehler anzeigen
        } else {
            this.navigator.navigateTo(BoardController.BCS_COMPONENT_NAME, {});
        }
    }

    public composeMessages() {
        const messages: MessageEntity[] = this.messagePool.getMessages();
        this.page.composeMessages(messages, this.context);
    }

    public popState(): void {
        this.navigateToReturnPath();
    }

    public destroy(): void {
        // TODO ...
    }

    private async composeForecast(forecast: ForecastRecord): Promise<void> {
        this.page = new GUIPage(
            this.context,
            ForecastController.CONTROLLER_PATH,
            forecast.getAttachmentOid(),
        );
        this.page.addStyleClass(ForecastController.BCS_COMPONENT_NAME);

        const headerToolBar = new ToolBar().setId("header_toolbar");

        headerToolBar.addToolLinkLeft(
            new ToolLink()
                .setId("navigateBack")
                .setImageName("icon-chevron-left.svg")
                .onClick(this.popState.bind(this)),
        );
        headerToolBar.setTitle(this.i18n.get("MobileApp.addforecast.pagetitle"));
        headerToolBar.addStyleClass("headBar");

        this.page.addHeaderElement(headerToolBar);

        this.composeForecastListView(forecast);

        await this.composeFooterTabBar();

        this.page
            .setAnimation(this.animation, this.navigator.doShowAnimations())
            .compose($("body"));
    }

    private composeForecastListView(forecast: ForecastRecord): void {
        const listViewContext = new ListViewContext()
            .setI18n(this.i18n)
            .setModel(new ForecastListViewModel(forecast));

        const forecastListViewDef = this.serverConfigProperties.customizeGUIDefinitions(
            this.FORECAST_LIST_VIEW_DEF,
            ForecastController.CONTROLLER_PATH,
            "forecastPath",
        );

        this.forecastListView = new ListView(listViewContext, forecastListViewDef)
            .setDOMId("forecast")
            .addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW)
            .onRowClicked(this.listRowClicked.bind(this))
            .onFormFieldChange(this.formFieldChanged.bind(this))
            .onFormButtonClick("saveAnnotationBtn", () => {
                this.sendForecastAnnotation(forecast);
            });

        this.page.addPageElement(this.forecastListView);
    }

    private async sendForecastAnnotation(forecast) {
        const resultMessage = await this.forecastManager.sendForecastRecord(forecast);

        switch (resultMessage.getType()) {
            case MessageType.SYNC: {
                // fall through
            }
            case MessageType.AFFIRMATION: {
                this.navigateToReturnPath(resultMessage);
                break;
            }
            case MessageType.ERROR: {
                this.messagePool.addMessage(resultMessage);
                this.composeMessages();
            }
        }
    }

    /**
     * Fußzeile (u.a. mit Home-Link)
     */
    private async composeFooterTabBar(): Promise<void> {
        const self = this;
        this.footerTabBar = new FooterTabBar(new GUIContext(this.i18n));
        await this.footerTabBar.composeDefaultFooter(
            null,
            this.navigator,
            this.getNavigateBackParameters(),
            () => self.syncStateManager.countUnsyncedElements(),
        );
        this.page.addFooterElement(this.footerTabBar);
    }

    private listRowClicked(path: string, parameters: { [key: string]: string }): void {
        this.navigator.navigateTo(path, parameters, Animation.SLIDE_RIGHT);
    }

    private async formFieldChanged(changeEvent: formChangeEventType): Promise<void> {
        this.updateFormFields(changeEvent);
        $("[name='saveAnnotationBtn']").addClass("showButton");
    }

    private updateFormFields(changeEvent: formChangeEventType): void {
        // Alle Formularfelder aktualisieren
        const listFieldFeedback = new ListFieldFeedback();
        listFieldFeedback.addFeedback(
            ListFieldFeedbackType.SUCCESS,
            changeEvent.entityId,
            changeEvent.fieldName,
        );
        this.forecastListView.updateFields(listFieldFeedback);
    }

    private getNavigateBackParameters(): { [key: string]: string } {
        return {
            navigateBackPath: ForecastController.CONTROLLER_PATH,
            navigateBackParameters: JSON.stringify({}),
        };
    }

    /**
     * Navigiert zurück zur aufrufenden Seite, typischerweise mit Formular, in dem u.a. die Aufgabe ausgewählt werden kann.
     * Aufruf der Seite, die als Parameter "return-path"  beim Aufruf dieser Seite angegeben wurde.
     * Als Parametern, werden die Parameter mitgesendet, die beim Aufruf dieser Seite mit dem Präfix "return-parameter-" gegeben waren.
     *
     * Optional kann eine Message mitgegeben werden, die auf der Seite, zu der zurückgekehrt wird, angezeigt werden kann.
     */
    private navigateToReturnPath(message?: MessageEntity) {
        let returnPath = null;
        const returnParameters: { [key: string]: string } = {};

        for (const name in this.pageParameters) {
            if (name == ForecastController.PARAMETER_RETURN_PATH) {
                // Pfad der Zielaseite aus Parameter "return-path" auslesen
                returnPath = this.pageParameters[name];
            } else if (
                name.substr(0, ForecastController.PARAMETER_RETURN_PARAMETER_PREFIX.length) ==
                ForecastController.PARAMETER_RETURN_PARAMETER_PREFIX
            ) {
                // Parameter der Zielaseite aus Parametern mit Präfix "return-parameter-" auslesen
                const returnParameterName = name.substr(
                    ForecastController.PARAMETER_RETURN_PARAMETER_PREFIX.length,
                );
                returnParameters[returnParameterName] = this.pageParameters[name];
            }
        }

        if (message) {
            returnParameters[ForecastController.PARAMETER_RETURN_MESSAGE] = JSON.stringify(message);
        }

        this.navigator.navigateTo(returnPath, returnParameters, Animation.SLIDE_RIGHT);
    }
}

Registry.registerComponent(ForecastController.CONTROLLER_PATH, ForecastController);
