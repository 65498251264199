import { Log } from "./Log";
import { LogLevel } from "./LogLevel";

export class AppConsole {
    private static currentLogLevel = LogLevel.WARN;

    public static debug(message?: any, ...optionalParams: any[]): void {
        if (AppConsole.doPrint(LogLevel.DEBUG)) {
            console.debug(message, optionalParams);
        }
    }

    public static log(message?: any, ...optionalParams: any[]): void {
        if (AppConsole.doPrint(LogLevel.INFO)) {
            console.log(message, optionalParams);
        }
    }

    public static warn(message?: any, ...optionalParams: any[]): void {
        if (AppConsole.doPrint(LogLevel.WARN)) {
            console.warn(message, optionalParams);
        }
    }

    public static error(message?: any, ...optionalParams: any[]): void {
        if (AppConsole.doPrint(LogLevel.ERROR)) {
            console.error(message, optionalParams);
        }
    }

    private static doPrint(logLevel: LogLevel): boolean {
        const logLevelNo = Log.LOG_LEVELS.indexOf(logLevel);
        const currentLogLevelNo = Log.LOG_LEVELS.indexOf(AppConsole.currentLogLevel);
        return logLevelNo <= currentLogLevelNo;
    }
}
