import { HttpRESTClient } from "../../util/http/HttpRESTClient";
import { ProgressFeedback } from "../../util/progress/ProgressFeedback";
import { RESTSaveResult } from "../../sync/RESTSaveResult";
import { Log } from "../../common/log/Log";

export class AllowanceClient {
    public async readAllowanceRecordingTermsFromBCS(
        checksum: number,
        progressFeedback: ProgressFeedback,
    ): Promise<object> {
        const self = this;

        const result = await new HttpRESTClient()
            .createRequest()
            .setMethod("GET")
            .addPath("/allowancerecording/allowancerecordingterms")
            .setParameter("checkSum", checksum)
            .onProgress((isDownload, loaded, total) =>
                progressFeedback.notifyProgress(loaded, total),
            )
            .send();

        progressFeedback.notifyProgress(100, 100);

        switch (result["status"]) {
            case 200:
                const recordingTerms = result["json"] ? result["json"] : null;
                return recordingTerms;
            case 204:
                return null;
            default:
                throw new Error(
                    "[AllowanceClient] Error readAllowanceRecordingTermsFromBCS: " +
                        result["status"],
                );
        }
    }

    public async readAllowancesFromBCS(
        syncStateTimestamp: number,
        progressFeedback: ProgressFeedback,
    ): Promise<{
        allowancesValueObjects: object[];
        syncStateTimestamp: number;
        syncContainsChanges: boolean;
    }> {
        const result = await new HttpRESTClient()
            .createRequest()
            .setMethod("GET")
            .addPath("/allowancerecording/allowances")
            .setParameter("syncStateTimestamp", syncStateTimestamp)
            .onProgress((isDownload, loaded, total) =>
                progressFeedback.notifyProgress(loaded, total),
            )
            .send();

        progressFeedback.notifyProgress(100, 100);

        switch (result["status"]) {
            case 200:
                if (result["json"] && result["json"]["allowances"]) {
                    return {
                        allowancesValueObjects: result["json"]["allowances"],
                        syncStateTimestamp: result["json"]["syncStateTimestamp"],
                        syncContainsChanges: true,
                    };
                } else {
                    return {
                        allowancesValueObjects: [],
                        syncStateTimestamp: syncStateTimestamp,
                        syncContainsChanges: true,
                    };
                }
            case 204:
                return {
                    allowancesValueObjects: [],
                    syncStateTimestamp: syncStateTimestamp,
                    syncContainsChanges: false,
                };
            default:
                throw new Error(
                    "[AllowanceClient] Error readAllowanceRecordingTermsFromBCS: " +
                        result["status"],
                );
        }
    }

    /**
     * Sendet Dienstreisen oder Einzelbelegspesen zum Speichern an BCS.
     *
     * @param allowancesValueObjects Dienstreisen oder Einzelbelegspesen als JSON mit simplen Werten (strings, numbes, booleans)
     * @returns Ergebnis der Speicheroperation der Spesen in BCS (Erfolg/Fehler, Spesen-Oids, Fehlermeldung)
     */
    public async sendAllowancesToBCS(
        allowancesValueObjects: object[],
        progressFeedback: ProgressFeedback,
    ): Promise<RESTSaveResult> {
        const result = await new HttpRESTClient()
            .createRequest()
            .setMethod("POST")
            .addPath("allowancerecording/allowances")
            .setJSONBody(allowancesValueObjects)
            .onProgress((isDownload, loaded, total) =>
                progressFeedback.notifyProgress(loaded, total),
            )
            .send();

        progressFeedback.notifyProgress(100, 100);
        return new RESTSaveResult(result);
    }

    /**
     * Löscht eine Dienstreise oder eine Einzelbelegspese in an BCS.
     *
     * @param allowanceId Oid einer Dienstreise oder einer Einzelbelegspese
     * @returns Ergebnis der Speicheroperation der Spesen in BCS (Erfolg/Fehler, Spesen-Oids, Fehlermeldung)
     */
    public async deleteAllowanceInBCS(allowanceId: string): Promise<RESTSaveResult> {
        const result = await new HttpRESTClient()
            .createRequest()
            .setMethod("DELETE")
            .addPath("/allowancerecording/allowance/" + allowanceId)
            .send();

        return new RESTSaveResult(result);
    }
}
