import { I18n } from "../../common/i18n/I18n";
import { GUIPage } from "../../gui/GUIPage";
import { SyncState, SyncStateObjectType } from "../SyncState";
import { ListView } from "../../gui/list/ListView";
import { SyncGUIDefinitions } from "./SyncGUIDefinitions";
import { ListViewContext } from "../../gui/list/ListViewContext";
import { SyncStateListViewModel } from "./SyncStateListViewModel";
import { SyncStateManager } from "../SyncStateManager";
import { ForecastController } from "../../domain/time_recording/forecast/ForecastController";
import { ContactRecordController } from "../../domain/contactRecording/pages/contactRecording/ContactRecordController";

/**
 * Liste mit Synchronisationsfehlern (z.B. Pflichtattribut Datum fehlt).
 */
export class SyncErrorsListSubController {
    private syncStateManager: SyncStateManager;

    /** Statische GUI-Definition der Listen der Sync-Seite */
    private syncGUIDefinitions: SyncGUIDefinitions;

    /** Label */
    private i18n: I18n;

    private syncStatesWithSynchronisationIssue: SyncState[];

    private errorSyncStatesListView: ListView;

    private errorSyncStateRowClickedCallback: (
        path: string,
        parameters?: { [key: string]: string },
    ) => void;

    constructor(
        syncStateManager: SyncStateManager,
        syncGUIDefinitions: SyncGUIDefinitions,
        i18n: I18n,
    ) {
        this.syncStateManager = syncStateManager;
        this.syncGUIDefinitions = syncGUIDefinitions;
        this.i18n = i18n;
    }

    public onErrorSyncStateRowClicked(
        errorSyncStateRowClickedCallback: (
            path: string,
            parameters?: { [key: string]: string },
        ) => void,
    ): SyncErrorsListSubController {
        this.errorSyncStateRowClickedCallback = errorSyncStateRowClickedCallback;
        return this;
    }

    public async readAllSyncStatesWithErrors(): Promise<void> {
        this.syncStatesWithSynchronisationIssue =
            await this.syncStateManager.readSyncAllStatesWithErrors();
    }

    /**
     * Persistente SyncStates mit Synchronisation-Fehlern anzeigen (z.B. "Fehler bei Dienstreise: Startdatum fehlt")
     *
     * @param page Wurzel-Element des GUI-Baums der aktuellen Seite
     */
    public composeErrorSyncStates(page: GUIPage): void {
        if (this.syncStatesWithSynchronisationIssue.length > 0) {
            const listViewContext = new ListViewContext()
                .setI18n(this.i18n)
                .setModel(
                    new SyncStateListViewModel(this.syncStatesWithSynchronisationIssue, this.i18n),
                );
            this.errorSyncStatesListView = new ListView(
                listViewContext,
                this.syncGUIDefinitions.getErrorSyncStatesListViewDef(),
            )
                .setDOMId("errorMessages")
                .addStyleClass(ListView.STYLE_CLASS_DEFAULT_LIST_VIEW)
                .setTitle(this.i18n.get("MobileApp.sync.syncErrors"))
                .onRowClicked(this.errorSyncStateRowClicked.bind(this));

            page.addPageElement(this.errorSyncStatesListView);
        }
    }

    private errorSyncStateRowClicked(path: string, parameters: { [key: string]: string }): void {
        if (this.errorSyncStateRowClickedCallback) {
            const clickedSyncStateId = parameters["oid"];

            this.syncStateManager.getSyncStateById(clickedSyncStateId).then((clickedSyncState) => {
                let path: string;
                let parameters: { [key: string]: string } = {};

                switch (clickedSyncState.getSyncStateObjectType()) {
                    case SyncStateObjectType.Attendance:
                        path = "timeRecordingDetail";
                        parameters = { oid: clickedSyncState.getId() };
                        break;
                    case SyncStateObjectType.Pause:
                        path = "timeRecordingDetail";
                        parameters = { oid: clickedSyncState.getId() };
                        break;
                    case SyncStateObjectType.Booking:
                        path = "timeRecordingDetail";
                        parameters = { oid: clickedSyncState.getId() };
                        break;
                    case SyncStateObjectType.Forecast:
                        path = ForecastController.CONTROLLER_PATH;
                        parameters[ForecastController.PARAMETER_SYNC_FORECAST_ID] =
                            clickedSyncState.getId();
                        break;
                    case SyncStateObjectType.Allowance:
                        if (clickedSyncState.getSubtype() != "voucherAllowance") {
                            path = "travel";
                            parameters = { oid: clickedSyncState.getId() };

                            if (clickedSyncState.getErrorObjectOid()) {
                                parameters["openSubAllowanceOid"] =
                                    clickedSyncState.getErrorObjectOid();
                            }
                        } else {
                            path = "voucher";
                            parameters = { voucherAllowanceOid: clickedSyncState.getId() };
                        }
                        break;
                    case SyncStateObjectType.Contact:
                        path = ContactRecordController.BCS_COMPONENT_NAME;
                        parameters = { oid: clickedSyncState.getId() };
                }

                if (path) {
                    this.errorSyncStateRowClickedCallback(path, parameters);
                }
            });
        }
    }

    public hasSyncErrors(): boolean {
        return this.syncStatesWithSynchronisationIssue.length > 0;
    }
}
