/**
 * Statische GUI-Definition der Listen/Formulare der einzelnen Spesen-Seiten.
 *
 * Vorerst statisch, perspektivisch serverseitig als XML konfigurierbar und via AppConfig per REST abfragbar.
 */
export class AllowanceGUIDefinitions {
    constructor() {}

    public getBusinessTravelsListViewDef(): object {
        return AllowanceGUIDefinitions.BUSINESS_TRAVELS_LIST_VIEW_DEF;
    }

    public getSingleVoucherAllowancesListViewDef(): object {
        return AllowanceGUIDefinitions.SINGLE_VOUCHER_ALLOWANCES_LIST_VIEW_DEF;
    }

    public getBusinessTravelListViewDef(): object {
        return AllowanceGUIDefinitions.BUSINESS_TRAVEL_LIST_VIEW_DEF;
    }

    public getTravelSectionListViewDef(): object {
        return AllowanceGUIDefinitions.TRAVEL_SECTION_LIST_VIEW_DEF;
    }

    public getSectionVouchersListViewDef(): object {
        return AllowanceGUIDefinitions.SECTION_VOUCHERS_LIST_VIEW_DEF;
    }

    public getAccomodationListViewDef(): object {
        return AllowanceGUIDefinitions.ACCOMODATIONS_LIST_VIEW_DEF;
    }

    public getKilometreListViewDef(): object {
        return AllowanceGUIDefinitions.KILOMETRE_LIST_VIEW_DEF;
    }

    public getVoucherListViewDef(): object {
        return AllowanceGUIDefinitions.VOUCHER_LIST_VIEW_DEF;
    }

    public getPSPSelectionResultListViewDef(): object {
        return AllowanceGUIDefinitions.PSP_SELECTION_RESULT_LIST_VIEW_DEF;
    }

    public getOptionSelectionResultListViewDef(optionName: string): object {
        switch (optionName) {
            case "allowanceTravelCountry":
            case "allowanceRecordType":
                return AllowanceGUIDefinitions.OPTION_SELECTION_RESULT_LIST_VIEW_DEF;
            default:
                throw new Error("AllowanceGUIDefinitions::UnknownOptionName " + optionName);
        }
    }

    /** Globale Liste der Dienstreisen */
    private static BUSINESS_TRAVELS_LIST_VIEW_DEF = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "compound",
                        field: {
                            name: "businessTravel",
                            mainContent: {
                                left: {
                                    class: "field",
                                    field: {
                                        name: "allowanceTravelDestination",
                                        mode: "display",
                                    },
                                },
                            },
                            subContents: [
                                {
                                    left: {
                                        class: "field",
                                        field: {
                                            name: "allowanceStartEndInfo",
                                            mode: "display",
                                        },
                                    },
                                    right: {
                                        class: "field",
                                        field: {
                                            name: "allowanceCountVouchersInfo",
                                            mode: "display",
                                        },
                                    },
                                },
                            ],
                        },
                        link: {
                            path: "travel",
                            parameters: {
                                oid: { attribute: "oid" },
                            },
                        },
                    },
                ],
            },
        ],
    };

    /** Globale Liste der Einzelbelegspesen */
    private static SINGLE_VOUCHER_ALLOWANCES_LIST_VIEW_DEF = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "compound",
                        field: {
                            name: "voucherAllowance",
                            mainContent: {
                                //"icon": "icon-currency-grey",
                                left: {
                                    class: "field",
                                    field: {
                                        name: "allowanceRecordType",
                                        mode: "display",
                                    },
                                },
                            },
                            subContents: [
                                {
                                    left: {
                                        class: "field",
                                        field: {
                                            name: "allowanceRecordDate",
                                            mode: "display",
                                        },
                                    },
                                    right: {
                                        class: "field",
                                        field: {
                                            name: "allowanceOriginalAmount",
                                            mode: "display",
                                        },
                                    },
                                },
                            ],
                        },
                        link: {
                            path: "voucher",
                            parameters: {
                                voucherAllowanceOid: { attribute: "oid" },
                            },
                        },
                    },
                ],
            },
        ],
    };

    /** Liste mit Formularfeldern der geöffneten Diestreise */
    private static BUSINESS_TRAVEL_LIST_VIEW_DEF = {
        fieldsets: [
            {
                label: "MobileApp.travel.ListFieldSet.businesstravel",
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "icon-place-grey",
                            name: "allowanceTravelDestination",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "allowanceTravelInnerCity",
                            mode: "edit",
                        },
                    },
                ],
            },
        ],
    };

    /** Liste mit Formularfeldern eines Reiseabschnittes (Tagegeld-Spese/Reisezeit-Spese) der geöffneten Diestreise */
    private static TRAVEL_SECTION_LIST_VIEW_DEF = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "table",
                        field: {
                            icon: "icon-connection-all-grey",
                            rows: [
                                {
                                    columns: [
                                        {
                                            class: "field",
                                            field: {
                                                label: "MobileApp.travel.ListFieldSet.arrival",
                                                name: "allowanceStartTime",
                                                mode: "edit",
                                            },
                                        },
                                    ],
                                },
                                {
                                    columns: [
                                        {
                                            class: "field",
                                            field: {
                                                label: "MobileApp.travel.ListFieldSet.departure",
                                                name: "allowanceEndTime",
                                                mode: "edit",
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                ],
            },
            {
                label: "MobileApp.travel.ListFieldSet.travelDuration",
                fields: [
                    {
                        class: "compound",
                        field: {
                            icon: "icon-stopwatch-grey",
                            name: "travelDuration",
                            mainContent: {
                                left: {
                                    class: "field",
                                    field: {
                                        name: "travelDurationAllowance:allowanceTravelDurationArrival",
                                        mode: "edit",
                                        styleClass: "compoundFieldMinWidth",
                                    },
                                },
                                right: {
                                    class: "field",
                                    field: {
                                        name: "travelDurationAllowance:allowanceTravelDurationDeparture",
                                        mode: "edit",
                                        styleClass: "compoundFieldAlignLeft",
                                    },
                                },
                            },
                        },
                    },
                ],
            },
            {
                label: "MobileApp.travel.ListFieldSet.task_destination_chargeability",
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "icon-task-grey",
                            name: "allowancePspOid",
                            mode: "edit",
                        },
                        link: {
                            path: "allowancepspselection",
                            parameters: {
                                pspSelectBusinessTravelOid: {
                                    attribute: "dailyAllowance:allowanceTravelOid",
                                },
                                pspSelectAllowanceOid: { attribute: "dailyAllowance:oid" },
                                "return-path": { value: "travel" },
                                "return-parameter-oid": {
                                    attribute: "dailyAllowance:allowanceTravelOid",
                                },
                                "return-parameter-dailyAllowanceOid": {
                                    attribute: "dailyAllowance:oid",
                                },
                            },
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "icon-currency-grey",
                            name: "allowanceChargeability",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "icon-place-grey",
                            name: "allowanceTravelDestination",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "allowanceTravelCountry",
                            mode: "edit",
                            readonly: "readonly",
                        },
                        link: {
                            path: "allowanceoptionselection",
                            parameters: {
                                pspSelectBusinessTravelOid: {
                                    attribute: "dailyAllowance:allowanceTravelOid",
                                },
                                pspSelectAllowanceOid: { attribute: "dailyAllowance:oid" },
                                optionName: { value: "allowanceTravelCountry" },
                                "return-path": { value: "travel" },
                                "return-parameter-oid": {
                                    attribute: "dailyAllowance:allowanceTravelOid",
                                },
                                "return-parameter-dailyAllowanceOid": {
                                    attribute: "dailyAllowance:oid",
                                },
                            },
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "allowanceTravelRoute",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "description",
                            mode: "edit",
                        },
                    },
                ],
            },
            {
                fields: [
                    {
                        class: "compound",
                        field: {
                            icon: "icon-meal-grey",
                            name: "meals",
                            mainContent: {
                                left: {
                                    class: "label",
                                    field: {
                                        label: "MobileApp.travel.Label.meals",
                                    },
                                },
                                right: {
                                    class: "field",
                                    field: {
                                        name: "allowanceMealsCountInfo",
                                        mode: "display",
                                    },
                                },
                            },
                        },
                        link: {
                            path: "travel/meals",
                            parameters: {
                                oid: { attribute: "allowanceTravelOid" },
                                dailyAllowanceOid: { attribute: "dailyAllowance:oid" },
                            },
                        },
                    },
                ],
            },
            {
                fields: [
                    {
                        class: "compound",
                        field: {
                            icon: "icon-car-grey",
                            name: "kilometres",
                            mainContent: {
                                left: {
                                    class: "label",
                                    field: {
                                        label: "MobileApp.travel.Label.travelmeans",
                                    },
                                },
                                right: {
                                    class: "field",
                                    field: {
                                        name: "kilometreAllowance:allowanceTravelDistance",
                                        mode: "display",
                                    },
                                },
                            },
                        },
                        link: {
                            path: "travel/kilometres",
                            parameters: {
                                oid: { attribute: "allowanceTravelOid" },
                                kilometreAllowanceOid: { attribute: "kilometreAllowance:oid" },
                            },
                        },
                    },
                ],
            },
            {
                fields: [
                    {
                        class: "compound",
                        field: {
                            icon: "icon-hotel-grey",
                            name: "accomodations",
                            mainContent: {
                                left: {
                                    class: "label",
                                    field: {
                                        label: "MobileApp.travel.Label.accomodations",
                                    },
                                },
                                right: {
                                    class: "field",
                                    field: {
                                        name: "accomodationAllowance:allowanceCountAccomodations",
                                        mode: "display",
                                    },
                                },
                            },
                        },
                        link: {
                            path: "travel/accomodations",
                            parameters: {
                                oid: { attribute: "allowanceTravelOid" },
                                accomodationAllowanceOid: {
                                    attribute: "accomodationAllowance:oid",
                                },
                            },
                        },
                    },
                ],
            },
        ],
    };

    /** Liste der Belegspesen eines Reiseabschnittes der geöffneten Diestreise */
    private static SECTION_VOUCHERS_LIST_VIEW_DEF = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "compound",
                        field: {
                            name: "voucherAllowance",
                            mainContent: {
                                //"icon": "icon-currency-grey",
                                left: {
                                    class: "field",
                                    field: {
                                        name: "allowanceRecordType",
                                        mode: "display",
                                    },
                                },
                            },
                            subContents: [
                                {
                                    left: {
                                        class: "field",
                                        field: {
                                            name: "allowanceRecordDate",
                                            mode: "display",
                                        },
                                    },
                                    right: {
                                        class: "field",
                                        field: {
                                            name: "allowanceOriginalAmount",
                                            mode: "display",
                                        },
                                    },
                                },
                            ],
                        },
                        link: {
                            path: "voucher",
                            parameters: {
                                businessTravelOid: { attribute: "allowanceTravelOid" },
                                voucherAllowanceOid: { attribute: "oid" },
                            },
                        },
                    },
                ],
            },
        ],
    };

    /** Liste mit Formularfeldern einer geöffnete Privat-Übernachtungs-Spese (eines Reiseabschnittes) */
    private static ACCOMODATIONS_LIST_VIEW_DEF = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "icon-hotel-grey",
                            name: "allowanceCountAccomodations",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "allowanceCountAccomodationsIsFullyInvoiceable",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "allowanceCountAccomodationsInvoiceable",
                            mode: "edit",
                        },
                    },
                ],
            },
        ],
    };

    /** Liste mit Formularfeldern einer geöffneten Kilometergeld-Spese (eines Reiseabschnittes) */
    private static KILOMETRE_LIST_VIEW_DEF = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "icon-car-grey",
                            name: "allowanceTravelMeans",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "allowanceTravelDistance",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "allowanceTravelDistanceIsFullyInvoiceable",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "allowanceTravelDistanceInvoiceable",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "numberOfPassengers",
                            mode: "edit",
                        },
                    },
                ],
            },
        ],
    };

    /** Liste mit Formularfeldern einer geöffneten Belegspese (eines Reiseabschnittes bzw. einer Einzelbelegspese) */
    private static VOUCHER_LIST_VIEW_DEF = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "icon-calendar-grey",
                            name: "allowanceRecordDate",
                            mode: "edit",
                        },
                    },
                ],
            },
            {
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "allowanceRecordType",
                            mode: "edit",
                            readonly: "readonly",
                        },
                        link: {
                            path: "allowanceoptionselection",
                            parameters: {
                                pspSelectBusinessTravelOid: { attribute: "allowanceTravelOid" },
                                pspSelectAllowanceOid: { attribute: "oid" },
                                optionName: { value: "allowanceRecordType" },
                                "return-path": { value: "voucher" },
                                "return-parameter-businessTravelOid": {
                                    attribute: "allowanceTravelOid",
                                },
                                "return-parameter-voucherAllowanceOid": { attribute: "oid" },
                            },
                        },
                    },
                ],
            },
            {
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "icon-task-grey",
                            name: "allowancePspOid",
                            mode: "edit",
                        },
                        link: {
                            path: "allowancepspselection",
                            parameters: {
                                pspSelectBusinessTravelOid: { attribute: "allowanceTravelOid" },
                                pspSelectAllowanceOid: { attribute: "oid" },
                                "return-path": { value: "voucher" },
                                "return-parameter-businessTravelOid": {
                                    attribute: "allowanceTravelOid",
                                },
                                "return-parameter-voucherAllowanceOid": { attribute: "oid" },
                            },
                        },
                    },
                ],
            },
            {
                label: "MobileApp.voucher.ListFieldSet.amount_and_currency",
                fields: [
                    {
                        class: "field",
                        field: {
                            icon: "icon-currency-grey",
                            name: "allowanceOriginalAmount",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "allowanceIncludedOriginalTipAmount",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "allowanceCurrency",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "allowanceChargeability",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "allowanceSource",
                            mode: "edit",
                        },
                    },
                    {
                        class: "field",
                        field: {
                            icon: "NONE",
                            name: "description",
                            mode: "edit",
                        },
                    },
                ],
            },
        ],
    };

    /** Liste mit Suchergebnissen der Aufgaben-Such/Auswahl-Seite für die Spesen-Aufgabe */
    private static PSP_SELECTION_RESULT_LIST_VIEW_DEF = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "compound",
                        field: {
                            name: "pspPath",
                            superContents: [
                                {
                                    left: {
                                        class: "field",
                                        field: {
                                            icon: "NONE",
                                            name: "grandParentName",
                                            mode: "display",
                                            styleClass: "fieldSmallFont",
                                        },
                                    },
                                },
                                {
                                    left: {
                                        class: "field",
                                        field: {
                                            icon: "NONE",
                                            name: "parentName",
                                            mode: "display",
                                            styleClass: "fieldSmallFont",
                                        },
                                    },
                                },
                            ],
                            mainContent: {
                                left: {
                                    class: "field",
                                    field: {
                                        icon: "icon-task-grey",
                                        name: "taskName",
                                        mode: "display",
                                    },
                                },
                            },
                            subContents: [
                                {
                                    left: {
                                        class: "field",
                                        field: {
                                            icon: "NONE",
                                            name: "customer.name",
                                            mode: "display",
                                            styleClass: "fieldSmallFont",
                                        },
                                    },
                                },
                            ],
                        },
                        link: {
                            path: "SELECT",
                            parameters: {
                                selectedPSPOid: { attribute: "taskOid" },
                            },
                            linkImage: "NONE",
                        },
                    },
                ],
            },
        ],
    };

    /** Liste mit Suchergebnissen für Spesen-Optionsauswahl (z.B. Reisezielländer oder Belegarten) */
    private static OPTION_SELECTION_RESULT_LIST_VIEW_DEF = {
        fieldsets: [
            {
                fields: [
                    {
                        class: "compound",
                        field: {
                            name: "option",
                            mainContent: {
                                left: {
                                    class: "field",
                                    field: {
                                        icon: "NONE",
                                        name: "optionLabel",
                                        mode: "display",
                                    },
                                },
                            },
                        },
                        link: {
                            path: "SELECT",
                            parameters: {
                                selectedOptionId: { attribute: "optionId" },
                            },
                            linkImage: "NONE",
                        },
                    },
                ],
            },
        ],
    };
}
