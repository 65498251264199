import { I18n } from "../../common/i18n/I18n";
import { ConfigNode } from "../../core/Config/ConfigNode";
import { BoardElement } from "../../gui/board/BoardElement";
import { GUIContext } from "../../gui/GUIContext";
import { PlaceholderChild } from "./PlaceholderChild";

export class PlaceholderElement extends BoardElement {
    public static ELEMENT_NAME = "placeholderElement";
    private i18n: I18n;

    constructor(boardElementConfig: ConfigNode, context: GUIContext, i18n: I18n) {
        super(boardElementConfig, context);
        this.name = PlaceholderElement.ELEMENT_NAME;

        this.xTiles = boardElementConfig.getIntAttribute("PosX", 1);
        this.yTiles = boardElementConfig.getIntAttribute("PosY", 1);
        this.widthTiles = boardElementConfig.getIntAttribute("SizeX", 6);
        this.heightTiles = boardElementConfig.getIntAttribute("SizeY", 6);

        this.styleClasses.push(this.name);

        this.i18n = i18n;
    }

    protected initializeChildren() {
        this.context.setPossibleContentHeight(
            this.currentTileHeight * this.heightTiles - this.CSS_ELEMENT_MARGIN * 2,
        );
        this.guiElements = [new PlaceholderChild(this.i18n)];
    }
}
