import { AppConsole } from "../../../common/log/AppConsole";
import { ApplicationProperties } from "../../../common/properties/ApplicationProperties";
import { Schema } from "../../../common/schema/Schema";
import { IndexedDB } from "../../../database/IndexedDB";
import { IndexedDBVersion } from "../../../database/IndexedDBVersion";
import { ForecastRecord } from "./ForecastRecord";
import { ForecastRecordsClient } from "./ForecastRecordClient";
import { TimeRecordingOptions } from "../TimeRecordingOptions";
import { BookingsPool } from "../bookings/BookingsPool";

export class ForecastRecordPool {
    public static BCS_COMPONENT_NAME = "ForecastRecordPool";
    private static FORECAST_RECORD_STORE_NAME = "forecastRecord";
    private static INDEX_USER_RECORD_DATE = "effortDate";
    private indexedDB: IndexedDB;
    private applicationProperties: ApplicationProperties;
    private schema: Schema;
    private poolUpdateAge: number;
    private recordingOptions: TimeRecordingOptions;

    constructor(
        indexedDB: IndexedDB,
        applicationProperties: ApplicationProperties,
        schema: Schema,
    ) {
        this.indexedDB = indexedDB;
        this.schema = schema;
        this.applicationProperties = applicationProperties;
        this.indexedDB
            .registerStore(
                ForecastRecordPool.FORECAST_RECORD_STORE_NAME,
                IndexedDBVersion.DB_VERSION_1,
            )
            .setIdKey("oid")
            .addIndex(
                ForecastRecordPool.INDEX_USER_RECORD_DATE,
                ["attachmentOwner" /*, "attachmentDuration", "description", "attachmentDate"*/],
                false,
                IndexedDBVersion.DB_VERSION_1,
            );
    }

    public start(): Promise<void> {
        return Promise.resolve();
    }

    public getForecastById(forecastOid: string): Promise<ForecastRecord> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([ForecastRecordPool.FORECAST_RECORD_STORE_NAME])
                .selectId(ForecastRecordPool.FORECAST_RECORD_STORE_NAME, forecastOid)
                .then(
                    (result) => {
                        if (result && result.element) {
                            resolve(self.wrapForecastRecordIntoDomainObject(result.element));
                        } else {
                            resolve(undefined);
                        }
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    public writeForecastRecordToBCSServer(forecastRecord: ForecastRecord): Promise<ForecastRecord> {
        const self = this;
        AppConsole.debug("[ForecastRecord.writeForecastRecordToBCSServer]", forecastRecord);
        return new Promise((resolve, reject) => {
            new ForecastRecordsClient()
                .save(self.poolUpdateAge, forecastRecord.toValueObject())
                .then((result) => {
                    resolve(undefined);
                })
                .catch((errorInformations) => {
                    reject(errorInformations);
                });
        });
    }

    public writeForecastRecordToDB(forecastRecord: ForecastRecord): Promise<void> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([ForecastRecordPool.FORECAST_RECORD_STORE_NAME])
                .updateElements(ForecastRecordPool.FORECAST_RECORD_STORE_NAME, [
                    forecastRecord.toValueObject(),
                ])
                .then(resolve, reject);
        });
    }

    public deleteForecastRecordFromDB(forecastRecordId: string): Promise<void> {
        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([ForecastRecordPool.FORECAST_RECORD_STORE_NAME])
                .deleteIds(ForecastRecordPool.FORECAST_RECORD_STORE_NAME, [forecastRecordId])
                .then(resolve, reject);
        });
    }

    public wrapForecastRecordIntoDomainObject(forecastRecordValueObject: object): ForecastRecord {
        return new ForecastRecord(this.schema, forecastRecordValueObject);
    }

    private wrapForecastRecordsIntoDomainObjects(forecastValueObjects: object[]): ForecastRecord[] {
        const forecast: ForecastRecord[] = [];

        for (let i = 0; i < forecastValueObjects.length; i++) {
            forecast.push(this.wrapForecastRecordIntoDomainObject(forecastValueObjects[i]));
        }

        return forecast;
    }
    public async getBookingTerms(currentUserOid: string): Promise<TimeRecordingOptions> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.applicationProperties
                .readProperty(BookingsPool.RECORDING_TERMS_PROPERTY_KEY, currentUserOid, null)
                .then((recordingTermsProperty) => {
                    this.recordingOptions = new TimeRecordingOptions(recordingTermsProperty);
                    resolve(this.recordingOptions);
                })
                .catch(reject);
        });
    }
}
