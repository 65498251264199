import { GUIElement } from "../GUIElement";
import { ListRowContext } from "./ListRowContext";
import { ListField } from "./ListField";
import { ListFieldFeedbackType, ListFieldFeedback } from "./ListFieldFeedback";
import { TextLabel } from "../content/TextLabel";
import { ButtonInteractCallback } from "./ListView";

export class ListRowButton implements GUIElement, ListField {
    private name: string;

    private label: string;

    private listRowContext: ListRowContext;

    private labelKey: string;

    private directText: string;

    private styleClass: string = "";

    private buttonInteractCallbacks: ButtonInteractCallback[] = [];

    /**
     * @param listViewContext ListView-Kontext (I18n, Erzeugen von FormFields, ...)
     * @param listFieldSetRowDefinition Definition dieser ListFieldSet-Komponente als JSON ausgelesen aus einer Konfiguration
     */
    constructor(listRowContext?: ListRowContext, listRowButtonDefinition?: object) {
        this.listRowContext = listRowContext;
        if (listRowButtonDefinition) {
            this.appyDefinition(listRowButtonDefinition);
        }
    }

    private appyDefinition(listRowButtondDefinition: object): void {
        this.labelKey = listRowButtondDefinition["label"];
        this.name = listRowButtondDefinition["name"];

        if (typeof listRowButtondDefinition["styleClass"] !== "undefined") {
            this.styleClass = listRowButtondDefinition["styleClass"];
        }
    }

    private clickCallback: (clickEvent: { name: string }) => void;

    public setName(name: string): ListRowButton {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setLabel(label: string): ListRowButton {
        this.label = label;
        return this;
    }

    public onClick(clickCallback: (clickEvent: { name: string }) => void): ListRowButton {
        this.clickCallback = clickCallback;
        return this;
    }

    public compose($parent: JQuery): void {
        let label = "";
        if (this.labelKey != "NONE") {
            label = this.listRowContext.getI18n().get(this.labelKey);
        }
        const $buttonContainer = $("<input>")
            .appendTo($parent)
            .attr({
                type: "button",
                name: this.name,
                value: label,
            })
            .addClass("formbutton " + this.styleClass)
            .on("click", this.buttonClicked.bind(this));
    }

    private buttonClicked(event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        if (this.clickCallback) {
            this.clickCallback({ name: this.getName() });
        }
        this.buttonInteractCallbacks.forEach((callbackEntity) => {
            callbackEntity.callback();
        });
    }

    public addInteractCallback(buttonInteractCallback: ButtonInteractCallback) {
        this.buttonInteractCallbacks.push(buttonInteractCallback);
    }

    public onUpdateValues(
        updateValuesCallback: (feedbackType: ListFieldFeedbackType) => void,
    ): void {
        // nichts zu tu
    }

    public updateValues(listFieldFeedback: ListFieldFeedback): void {
        // nichts zu tun
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}
