import { HttpRESTClient } from "../../../util/http/HttpRESTClient";

export type OIDCLogoutData =
    | {
          needsRedirect: false;
      }
    | {
          needsRedirect: true;
          redirectURI: string;
      };

/**
 * Client, der sich asynchron die OIDC-Konfiguration über eine REST-Schnittstelle
 * vom BCS-Server holt.
 *
 * Wenn OIDC auf dem BCS-Server aktiv ist, wird in der App
 * der Button angezeigt, um sich bspw. bei Microsoft anzumelden.
 */
export class OIDCLogoutClient {
    public async getLogoutData(): Promise<OIDCLogoutData> {
        return await new HttpRESTClient(true)
            .createRequest()
            .setMethod("GET")
            .addPath("oauth/logoutURI")
            .send()
            .then((result) => {
                return result["json"] as OIDCLogoutData;
            })
            .catch((error) => {
                console.error("Error fetching OAuthConfig: " + error);
                throw error;
            });
    }
}
