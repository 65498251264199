import { AccomodationAllowance } from "./AccomodationAllowance";
import { Allowance } from "./Allowance";
import { AllowanceRecordingTerms } from "../AllowanceRecordingTerms";
import { BusinessTravel } from "./BusinessTravel";
import { DailyAllowance } from "./DailyAllowance";
import { DateTimeValue } from "../../../entities/values/DateTimeValue";
import { EntityValue } from "../../../entities/values/EntityValue";
import { KilometreAllowance } from "./KilometreAllowance";
import { StringValue } from "../../../entities/values/StringValue";
import { TravelAllowance } from "./TravelAllowance";
import { TravelDays } from "./TravelDays";
import { TravelDurationAllowance } from "./TravelDurationAllowance";
import { TypeSubtypeDefinition } from "../../../common/schema/TypeSubtypeDefinition";
import { VoucherAllowance } from "./VoucherAllowance";

export class TravelSection {
    public static readonly SECTION_TRAVEL_ALLOWANCE_SUBTYPES = [
        DailyAllowance.SUBTYPE,
        AccomodationAllowance.SUBTYPE,
        KilometreAllowance.SUBTYPE,
        TravelDurationAllowance.SUBTYPE,
    ];

    public static readonly SECTION_ALL_ALLOWANCE_SUBTYPES = [
        DailyAllowance.SUBTYPE,
        AccomodationAllowance.SUBTYPE,
        KilometreAllowance.SUBTYPE,
        TravelDurationAllowance.SUBTYPE,
        VoucherAllowance.SUBTYPE,
    ];

    /** Spesenerfassungsmodalitäten (Spesenaufgaben, Abrechenbarkeiten, Belegarten, ...) */
    private allowanceRecordingTerms: AllowanceRecordingTerms;

    private businessTravel: BusinessTravel;

    private allowanceTypeSubtypeDefinitions: { [key: string]: TypeSubtypeDefinition };

    private subAllowancesById: { [key: string]: Allowance } = {};

    private travelAllowancesBySubtype: { [key: string]: Allowance } = {};

    private voucherAllowances: VoucherAllowance[] = [];

    private travelDays: TravelDays;

    public static create(
        businessTravel: BusinessTravel,
        allowanceRecordingTerms: AllowanceRecordingTerms,
        allowanceTypeSubtypeDefinitions: { [key: string]: TypeSubtypeDefinition },
        userOid: string,
    ): TravelSection {
        return new TravelSection(
            businessTravel,
            allowanceRecordingTerms,
            allowanceTypeSubtypeDefinitions,
            {},
            userOid,
        );
    }

    constructor(
        businessTravel: BusinessTravel,
        allowanceRecordingTerms: AllowanceRecordingTerms,
        allowanceTypeSubtypeDefinitions: { [key: string]: TypeSubtypeDefinition },
        travelSectionValueObject: object,
        userOid: string,
    ) {
        this.allowanceRecordingTerms = allowanceRecordingTerms;

        this.businessTravel = businessTravel;

        // Spesen-Typ/Subtyp-Definitionen merken, um Unter-Spesen erstellen zu können
        this.allowanceTypeSubtypeDefinitions = allowanceTypeSubtypeDefinitions;

        const travelAllowances: Allowance[] = [];

        TravelSection.SECTION_ALL_ALLOWANCE_SUBTYPES.forEach((subtype) => {
            const allowanceValueObject = travelSectionValueObject[subtype];

            if (allowanceValueObject) {
                switch (subtype) {
                    case DailyAllowance.SUBTYPE:
                        travelAllowances.push(
                            new DailyAllowance(
                                this,
                                allowanceRecordingTerms,
                                allowanceTypeSubtypeDefinitions[subtype],
                                allowanceValueObject,
                            ),
                        );
                        break;
                    case AccomodationAllowance.SUBTYPE:
                        travelAllowances.push(
                            new AccomodationAllowance(
                                this,
                                allowanceTypeSubtypeDefinitions[subtype],
                                allowanceValueObject,
                            ),
                        );
                        break;
                    case KilometreAllowance.SUBTYPE:
                        travelAllowances.push(
                            new KilometreAllowance(
                                this,
                                allowanceTypeSubtypeDefinitions[subtype],
                                allowanceValueObject,
                            ),
                        );
                        break;
                    case TravelDurationAllowance.SUBTYPE:
                        travelAllowances.push(
                            new TravelDurationAllowance(
                                this,
                                allowanceTypeSubtypeDefinitions[subtype],
                                allowanceValueObject,
                            ),
                        );
                        break;
                    case VoucherAllowance.SUBTYPE:
                        const voucherAllowanceValueObjects = <object[]>allowanceValueObject;
                        voucherAllowanceValueObjects.forEach((voucherAllowanceValueObject) => {
                            this.voucherAllowances.push(
                                new VoucherAllowance(
                                    this,
                                    allowanceRecordingTerms,
                                    allowanceTypeSubtypeDefinitions[subtype],
                                    voucherAllowanceValueObject,
                                ),
                            );
                        });
                        break;
                }
            } else {
                switch (subtype) {
                    case DailyAllowance.SUBTYPE:
                        travelAllowances.push(
                            DailyAllowance.create(
                                this,
                                allowanceRecordingTerms,
                                allowanceTypeSubtypeDefinitions[subtype],
                                userOid,
                                businessTravel.getId(),
                            ),
                        );
                        break;
                    case AccomodationAllowance.SUBTYPE:
                        travelAllowances.push(
                            AccomodationAllowance.create(
                                this,
                                allowanceTypeSubtypeDefinitions[subtype],
                                userOid,
                                businessTravel.getId(),
                            ),
                        );
                        break;
                    case KilometreAllowance.SUBTYPE:
                        travelAllowances.push(
                            KilometreAllowance.create(
                                this,
                                allowanceTypeSubtypeDefinitions[subtype],
                                userOid,
                                businessTravel.getId(),
                            ),
                        );
                        break;
                    case TravelDurationAllowance.SUBTYPE:
                        travelAllowances.push(
                            TravelDurationAllowance.create(
                                this,
                                allowanceTypeSubtypeDefinitions[subtype],
                                userOid,
                                businessTravel.getId(),
                            ),
                        );
                        break;
                }
            }
        });

        // Reise-Spesen je Subtyp / Alle Spesen je Oid merken
        travelAllowances.forEach(
            (allowance) => (this.travelAllowancesBySubtype[allowance.getSubtype()] = allowance),
        );
        travelAllowances.forEach(
            (allowance) => (this.subAllowancesById[allowance.getId()] = allowance),
        );
        this.voucherAllowances.forEach(
            (voucherAllowance) =>
                (this.subAllowancesById[voucherAllowance.getId()] = voucherAllowance),
        );

        // Reisetage mit Mahlzeiten (Frühstück, Mittag, Abendessen)
        const travelDaysValueObject = travelSectionValueObject[TravelDays.TYPE];
        if (travelDaysValueObject) {
            this.travelDays = new TravelDays(
                this,
                allowanceTypeSubtypeDefinitions[TravelDays.SUBTYPE],
                travelDaysValueObject,
            );
        } else {
            this.travelDays = TravelDays.create(
                this,
                allowanceTypeSubtypeDefinitions[TravelDays.SUBTYPE],
            );
        }

        this.adjustTravelDaysStartEndDate();
        this.matchSharedTravelAllowanceAttributes();
    }

    /**
     * Werte des vorheriges Reiseabschnitts übernehmen
     *
     * @param precedingTravelSection Vorheriger Reiseabschnitt
     */
    public applyValuesFromPrecedingTravelSection(precedingTravelSection: TravelSection) {
        this.setStartDateTime(precedingTravelSection.getEndDateTime());
        this.setEndDateTime(precedingTravelSection.getEndDateTime());

        const dailyAllowance = this.getDailyAllowance();
        const precedingdailyAllowance = precedingTravelSection.getDailyAllowance();

        this.allowanceRecordingTerms
            .getConfigSet("Allowances.copyAllowanceAttributesFromBusinessTravel")
            .forEach((attributeName) =>
                dailyAllowance.setValue(attributeName, this.businessTravel.getValue(attributeName)),
            );

        this.allowanceRecordingTerms
            .getConfigSet("Allowances.copyAllowanceAttributesFromPrecedingSection")
            .forEach((attributeName) =>
                dailyAllowance.setValue(
                    attributeName,
                    precedingdailyAllowance.getValue(attributeName),
                ),
            );

        this.matchSharedTravelAllowanceAttributes();
    }

    /**
     * @param allowanceOid Oid einer Reisespese bzw. einer Belegspese
     * @return Reisespese bzw. Belegspese
     */
    public getSubAllowanceById(allowanceOid: string): Allowance {
        return this.subAllowancesById[allowanceOid] || null;
    }

    public getTravelAllowanceBySubtype(subtype: string): Allowance {
        return this.travelAllowancesBySubtype[subtype];
    }

    private getTravelAllowances(): Allowance[] {
        return Object.keys(this.travelAllowancesBySubtype).map(
            (subtype) => this.travelAllowancesBySubtype[subtype],
        );
    }

    private getDailyAllowance(): DailyAllowance {
        return <DailyAllowance>this.getTravelAllowanceBySubtype(DailyAllowance.SUBTYPE);
    }

    /*
    private getAccomodationAllowance(): AccomodationAllowance {
        return <AccomodationAllowance>this.getTravelAllowanceBySubtype(AccomodationAllowance.SUBTYPE);
    }
    */

    /*
    private getKilometreAllowance(): KilometreAllowance {
        return <KilometreAllowance>this.getTravelAllowanceBySubtype(KilometreAllowance.SUBTYPE);
    }
    
    private getTravelDurationAllowance(): TravelDurationAllowance {
        return <TravelDurationAllowance>this.getTravelAllowanceBySubtype(TravelDurationAllowance.SUBTYPE);
    }
    */

    public getVoucherAllowances(): VoucherAllowance[] {
        const sortedVoucherAllowances = this.voucherAllowances.slice(0);
        sortedVoucherAllowances.sort((v1, v2) => v1.getRecordDate().compare(v2.getRecordDate()));
        return sortedVoucherAllowances;
    }

    public getTravelDays(): TravelDays {
        return this.travelDays;
    }

    public createVoucherAllowance(): VoucherAllowance {
        const newVoucherAllowance = VoucherAllowance.create(
            this,
            this.allowanceRecordingTerms,
            this.allowanceTypeSubtypeDefinitions[VoucherAllowance.SUBTYPE],
            this.getDailyAllowance().getUserOid(),
            this.getDailyAllowance().getBusinessTravelOid(),
        );

        let voucherAllowances = this.getVoucherAllowances();
        if (voucherAllowances.length > 0) {
            // Belegspesen nach Erstellt-Datum/Uhrzeit sortieren und die neueste als Kopiervorlage verwenden.
            voucherAllowances = voucherAllowances.slice(0);
            voucherAllowances.sort((v1, v2) => v1.getInsDate().compare(v2.getInsDate()));

            newVoucherAllowance.applyValuesFromPrecedingVoucherAllowance(
                voucherAllowances[voucherAllowances.length - 1],
            );
        }

        this.voucherAllowances.push(newVoucherAllowance);
        this.subAllowancesById[newVoucherAllowance.getId()] = newVoucherAllowance;

        return newVoucherAllowance;
    }

    public deleteVoucherAllowance(deleteVoucherAllowance: VoucherAllowance): void {
        this.voucherAllowances = this.voucherAllowances.filter(
            (voucherAllowance) => voucherAllowance != deleteVoucherAllowance,
        );
        delete this.subAllowancesById[deleteVoucherAllowance.getId()];
    }

    public getId(): string {
        return this.getDailyAllowance().getId();
    }

    public getPSPOid(): string {
        return this.getDailyAllowance().getPSPOid();
    }

    public getPSPOidValue(): StringValue {
        return <StringValue>this.getDailyAllowance().getValue(DailyAllowance.PSP_OID);
    }

    public setPSPName(pspName: string): void {
        this.getDailyAllowance().setPSPName(pspName);
    }

    public getStartDateTime(): DateTimeValue {
        return this.getDailyAllowance().getStartDateTime();
    }

    public getEndDateTime(): DateTimeValue {
        return this.getDailyAllowance().getEndDateTime();
    }

    public setStartDateTime(startDateTime: DateTimeValue): void {
        this.getTravelAllowances().forEach((allowance) =>
            (<TravelAllowance>allowance).setStartDateTime(startDateTime),
        );

        this.adjustTravelDaysStartEndDate();
    }

    public setEndDateTime(endDateTime: DateTimeValue): void {
        this.getTravelAllowances().forEach((allowance) =>
            (<TravelAllowance>allowance).setEndDateTime(endDateTime),
        );

        this.adjustTravelDaysStartEndDate();
    }

    /**
     * Aufgerufen, wenn an diesem Reiseabschnitt Start- bzw. End-Datum/Uhrzeit geändert wurde.
     *
     * Ggf. wird Start/Ende aller vorherigen und nachfolgenden Reiseabschnitte sowie der Dienstreise angepasst.
     */
    public reArrangeTravelSectionStartEnd(): void {
        // Start/Ende an allen Reisespesen gleich setzen
        this.setStartDateTime(this.getStartDateTime());
        this.setEndDateTime(this.getEndDateTime());

        this.businessTravel.reArrangeTravelSectionStartEnd(this);
    }

    private adjustTravelDaysStartEndDate(): void {
        this.travelDays.adjustStartEndDate(
            this.getStartDateTime().getDateValue(),
            this.getEndDateTime().getDateValue(),
        );
    }

    /**
     * Belegt den noch undefinierten Reisezweck des Reiseabschnitts mit dem Reisezweck der Dienstreise vor.
     *
     * @param businessTravelDestination Reisezweck der Dienstreise
     * @param sectionNo Nummer des Reiseabschnitts
     */
    public setDefaultTravelDestination(
        businessTravelDestination: EntityValue,
        sectionNo: number,
    ): void {
        if (businessTravelDestination.isDefined()) {
            const sectionDestination = this.getValue(DailyAllowance.TRAVEL_DESTINATION);
            if (
                !sectionDestination.isDefined() ||
                sectionDestination.getString() === businessTravelDestination.getString()
            ) {
                const sectionValue = new StringValue(
                    businessTravelDestination.getString() + " (" + (sectionNo + 1) + ")",
                );
                this.getDailyAllowance().setValue(DailyAllowance.TRAVEL_DESTINATION, sectionValue);
            }
        }
    }

    /**
     * Gemeinsame Attribute bei allen Reisespesen des selben Reiseabschnitts angleichen.
     *
     * @param changedAttributeName Name des geänderten Attributes, wenn wegelassen werden alle gemeinsamen Attribute angeglichen [optional]
     */
    public matchSharedTravelAllowanceAttributes(changedAttributeName?: string): void {
        const dailyAllowance = this.getDailyAllowance();

        // Attribute bei allen Reisespesen (Tagegeld, Übernachtung, Kilometer, Reisezeit) des selben Reiseabschnitts angleichen.
        this.getTravelAllowances()
            .filter((travelAllowance) => travelAllowance != dailyAllowance)
            .forEach((travelAllowance) => {
                this.allowanceRecordingTerms
                    .getConfigSet("Allowances.sharedTravelAllowanceAttributes")
                    .filter(
                        (attributeName) =>
                            !changedAttributeName || attributeName == changedAttributeName,
                    )
                    .forEach((attributeName) =>
                        travelAllowance.setValue(
                            attributeName,
                            this.getDailyAllowance().getValue(attributeName),
                        ),
                    );
            });

        // Attribute bei allen Reisekostenspesen (Tagegeld, Übernachtung, Reisezeit) des selben Reiseabschnitts angleichen.
        this.getTravelAllowances()
            .filter(
                (travelAllowance) =>
                    [AccomodationAllowance.SUBTYPE, TravelDurationAllowance.SUBTYPE].indexOf(
                        travelAllowance.getSubtype(),
                    ) >= 0,
            )
            .forEach((travelAllowance) => {
                this.allowanceRecordingTerms
                    .getConfigSet("Allowances.sharedTravelcostAllowanceAttributes")
                    .filter(
                        (attributeName) =>
                            !changedAttributeName || attributeName == changedAttributeName,
                    )
                    .forEach((attributeName) =>
                        travelAllowance.setValue(
                            attributeName,
                            this.getDailyAllowance().getValue(attributeName),
                        ),
                    );
            });
    }

    public getValue(name: string): EntityValue {
        return this.getDailyAllowance().getValue(name);
    }

    public isEditable(): boolean {
        return this.businessTravel.isEditable();
    }

    public getEntityIdTree(): object {
        const entityIdTree = {};
        this.getTravelAllowances().forEach(
            (allowance) => (entityIdTree[allowance.getSubtype()] = allowance.getId()),
        );
        entityIdTree[VoucherAllowance.SUBTYPE] = this.getVoucherAllowances().map((allowance) =>
            allowance.getId(),
        );
        return entityIdTree;
    }

    public toValueObject(onlyWithAmount: boolean = false): object {
        const travelSectionValueObject = {};

        for (const subtype in this.travelAllowancesBySubtype) {
            if (this.travelAllowancesBySubtype.hasOwnProperty(subtype)) {
                if (!onlyWithAmount || this.travelAllowancesBySubtype[subtype].hasAmout()) {
                    travelSectionValueObject[subtype] =
                        this.travelAllowancesBySubtype[subtype].toValueObject();
                }
            }
        }

        const voucherAllowanceValueObjects = [];
        for (let i = 0; i < this.voucherAllowances.length; i++) {
            voucherAllowanceValueObjects.push(this.voucherAllowances[i].toValueObject());
        }
        travelSectionValueObject[VoucherAllowance.SUBTYPE] = voucherAllowanceValueObjects;

        travelSectionValueObject[TravelDays.TYPE] = this.travelDays.toValueObject();

        return travelSectionValueObject;
    }
}
