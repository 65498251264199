import { SyncState } from "./SyncState";

export class RESTSaveResult {
    private allowanceSaveResults: object[] = [];

    constructor(result) {
        if (result.json && result.json.results) {
            this.allowanceSaveResults = result.json.results;
        }
    }

    public convertOidsToUids(): RESTSaveResult {
        this.allowanceSaveResults.forEach((saveResult) => {
            const oid: string = saveResult["oid"];
            const x = oid.lastIndexOf("_");
            const uid = x > 0 ? oid.substr(0, x) : oid;
            saveResult["oid"] = uid;
        });

        return this;
    }

    /**
     * @returns Anzahl Fehler
     */
    public countErrors(): number {
        return this.allowanceSaveResults.filter((saveResult) => saveResult["result"] == "Error")
            .length;
    }

    /**
     * Markiert SyncStates der zum speichernde an BCS gesendeten Objekte anhand des RESTResults als Erfolg oder Fehler.

     * @param syncStates SyncStates der zum speichernde an BCS gesendeten Objekte, die jetzt anhand des RESTResults
     *                   als Erfolg oder Fehler markiert werden
     */
    public updateSyncStates(syncStates: SyncState[]): void {
        const syncStatesById: { [key: string]: SyncState } = {};
        syncStates.forEach((syncState) => (syncStatesById[syncState.getId()] = syncState));

        this.allowanceSaveResults
            .filter((saveResult) => saveResult["result"] == "Success")
            .forEach((saveResult) => syncStatesById[saveResult["oid"]].markSyncSucess());

        this.allowanceSaveResults
            .filter((saveResult) => saveResult["result"] == "Error")
            .forEach((saveResult) => {
                syncStatesById[saveResult["oid"]].markSyncError(
                    saveResult["errorKey"],
                    saveResult["errorMessage"],
                    saveResult["errorObjectOid"],
                );
            });
    }
}
