/** Importe aufgrund von Dynamischem ladens*/
import { BrowserQRCodeReader } from "@zxing/browser";
import { BoardController } from "../board/BoardController";
import { LoginClient, PingResult } from "../common/auth/LoginClient";
import { LoginController } from "../common/auth/LoginController";
import { UserSession } from "../common/auth/UserSession";
import { ServerConfigProperties } from "../common/config/ServerConfigProperties";
import { AppConsole } from "../common/log/AppConsole";
import { Log } from "../common/log/Log";
import { PreferencesController } from "../common/preferences/PreferencesController";
import { PreferencesManager } from "../common/preferences/PreferencesManager";
import { Schema } from "../common/schema/Schema";
import { IndexedDB } from "../database/IndexedDB";
import { AllowanceManager } from "../domain/allowances/AllowanceManager";
import { AccomodationsController } from "../domain/allowances/pages/accomodations/AccomodationsController";
import { AllowanceSummaryElement } from "../domain/allowances/pages/board/AllowanceSummaryElement";
import { KilometreController } from "../domain/allowances/pages/kilometres/KilometreController";
import { MealsController } from "../domain/allowances/pages/meals/MealsController";
import { AllowanceOptionSelectionController } from "../domain/allowances/pages/pspselection/AllowanceOptionSelectionController";
import { AllowancePSPSelectionController } from "../domain/allowances/pages/pspselection/AllowancePSPSelectionController";
import { BusinessTravelsController } from "../domain/allowances/pages/travels/BusinessTravelsController";
import { BusinessTravelSectionsController } from "../domain/allowances/pages/travelsections/BusinessTravelSectionsController";
import { VoucherController } from "../domain/allowances/pages/vouchers/VoucherController";
import { VoucherPhotoController } from "../domain/allowances/pages/vouchers/VoucherPhotoController";
import { ContactManager } from "../domain/contactRecording/ContactManager";
import { ContactListController } from "../domain/contactRecording/pages/contactList/ContactListController";
import { ContactRecordController } from "../domain/contactRecording/pages/contactRecording/ContactRecordController";
import { ContactScanController } from "../domain/contactRecording/pages/contactScan/ContactScanController";
import { FileManager } from "../domain/files/FileManager";
import { ImageDetailController } from "../domain/files/pages/ImageDetailController";
import { AttendanceClockControl } from "../domain/time_recording/attendance/AttendanceClockControl";
import { ForecastController } from "../domain/time_recording/forecast/ForecastController";
import { ForecastManager } from "../domain/time_recording/forecast/ForecastManager";
import { ForecastTaskSelectionController } from "../domain/time_recording/forecast/ForecastTaskSelectionController";
import { TimeRecordingRequirementSelectionController } from "../domain/time_recording/TimeRecordingTaskSelectionGUIDefinition/TimeRecordingRequirementSelectionController";
import { TimeRecordingTaskSelectionController } from "../domain/time_recording/TimeRecordingTaskSelectionGUIDefinition/TimeRecordingTaskSelectionController";
import { TimeRecordingTicketSelectionController } from "../domain/time_recording/TimeRecordingTaskSelectionGUIDefinition/TimeRecordingTicketSelectionController";
import { TimeRecordingWorkflowSelectionController } from "../domain/time_recording/TimeRecordingTaskSelectionGUIDefinition/TimeRecordingWorkflowSelectionController";
import { TimeRecordingController } from "../domain/time_recording/TimeRecordingController";
import { TimeRecordingManager } from "../domain/time_recording/TimeRecordingManager";
import { CalendarControll } from "../gui/content/CalendarControll";
import { TimeRecordingTacho } from "../gui/content/TimeRecordingTacho";
import { ProcessLayer } from "../gui/loadingProcess/ProcessLayer";
import { SyncController } from "../sync/pages/SyncController";
import { SyncStateManager } from "../sync/SyncStateManager";
import { ProgressFeedback } from "../util/progress/ProgressFeedback";
import { AppEventManager } from "./AppEventManager";
import { AppRequirementCheck } from "./AppRequirementCheck";
import { ComponentRegistry } from "./ComponentRegistry";
import { Router } from "./Router";
import { ContactBoardElement } from "../board/elements/ContactBoardElement";
import { ForecastElement } from "../domain/time_recording/forecast/ForecastElement";
import { OAuthConfig } from "../common/auth/oauth/OAuthConfig";
import { ContactOuContrySelectionController } from "../domain/contactRecording/pages/contactRecording/ContactOuContrySelectionController";
import { LogoutController } from "../common/auth/LogoutController";
import { AfterLogoutController } from "../common/auth/AfterLogoutController";

export class Bootstrap {
    private componentRegistry: ComponentRegistry;

    private userSession: UserSession;

    private processLayer: ProcessLayer;

    constructor() {
        /** Damit Importe aufgrund von Dynamischem ladens funktionieren, müssen die Klassen im Code auftauchen.*/

        AppEventManager;

        BoardController;
        LoginController;
        LogoutController;
        AfterLogoutController;
        TimeRecordingTacho;
        CalendarControll;
        AttendanceClockControl;
        Schema;
        ServerConfigProperties;

        TimeRecordingController;
        TimeRecordingManager;
        TimeRecordingTaskSelectionController;
        TimeRecordingTicketSelectionController;
        TimeRecordingWorkflowSelectionController;
        TimeRecordingRequirementSelectionController;
        ForecastController;
        ForecastManager;
        ForecastTaskSelectionController;
        ForecastElement;

        Log;
        IndexedDB;

        AllowanceManager;
        BusinessTravelsController;
        BusinessTravelSectionsController;
        MealsController;
        AccomodationsController;
        KilometreController;
        VoucherController;
        VoucherPhotoController;
        AllowancePSPSelectionController;
        AllowanceOptionSelectionController;
        AllowanceSummaryElement;

        FileManager;
        ImageDetailController;

        PreferencesController;
        PreferencesManager;

        SyncStateManager;
        SyncController;

        ContactManager;
        ContactListController;
        ContactScanController;
        ContactRecordController;
        ContactBoardElement;
        ContactOuContrySelectionController;
    }

    public start(): Bootstrap {
        AppRequirementCheck.checkRequirements();

        this.processLayer = new ProcessLayer();
        this.processLayer.show();

        this.componentRegistry = new ComponentRegistry();
        this.componentRegistry.scanForComponents();

        this.startSingletons();
        return this;
    }

    private startSingletons(): void {
        const self = this;
        this.componentRegistry
            .getSingletonManager()
            .startSingletons()
            .then(() => self.notifySingletonsBeginUserSession())
            .catch((error) => self.startSingletonsFailed(error));
    }

    private startSingletonsFailed(error): void {
        AppConsole.debug("[Bootstrap] start singletons failed", error); // TODO App Fehlerbehandlung
    }

    private async notifySingletonsBeginUserSession(): Promise<void> {
        this.userSession = <UserSession>(
            this.componentRegistry.getComponent(UserSession.BCS_COMPONENT_NAME)
        );

        const pingResult = await new LoginClient().status();
        switch (pingResult) {
            case PingResult.REACHABLE_ACTIVE_SESSION:
                // weiter
                break;
            case PingResult.REACHABLE_NO_SESSION:
                await this.userSession.terminateLocalSession();
                this.userSession.setToOfflineMode();
                break;
            case PingResult.NOT_REACHABLE:
            case PingResult.SERVER_ERROR:
                this.userSession.setToOfflineMode();
                break;
            default:
            // weiter
        }

        Log.info("[Bootstrap] notifySingletonsBeginUserSession", {
            ping: pingResult,
            session: this.userSession.isLoggedIn() ? "valid" : "invalid",
        });

        const self = this;
        if (this.userSession.isLoggedIn()) {
            const progressFeedback = new ProgressFeedback();
            this.processLayer.show(progressFeedback);

            this.componentRegistry
                .getSingletonManager()
                .notifySingletonsBeginUserSession(this.userSession.isOnline(), progressFeedback)
                .then(() => self.openInitialPage(true))
                .catch((error) => self.synchronizeSingletonsFailed(error));
        } else {
            this.openInitialPage(false);
        }
    }

    private synchronizeSingletonsFailed(error): void {
        Log.error("[Bootstrap] synchronizeSingletonsFailed", error);
        AppConsole.debug("[Bootstrap] synchronize singletons failed", error); // TODO App Fehlerbehandlung
        this.openInitialPage(false);
    }

    private openInitialPage(isSuccessfulStart: boolean): void {
        const router = new Router(this.componentRegistry);

        if (isSuccessfulStart && this.userSession.isLoggedIn()) {
            router.gotoCurrentURL();
        } else {
            router.gotoLoginPage();
        }
    }
}

/*
$(document).ready(function() {
    new Bootstrap().start();
});
*/
