import { HttpRESTClient } from "../../util/http/HttpRESTClient";

export class ServerConfigPropertiesClient {
    public fetchServerConfigProperties(age: number): Promise<object> {
        return new Promise((resolve, reject) => {
            new HttpRESTClient()
                .createRequest()
                .addPath("serverconfig")
                .setParameter("age", age)
                .send()
                .then((result) => {
                    switch (result["status"]) {
                        case 200:
                            resolve(result["json"]);
                            break;
                        case 204:
                            resolve({ cache: "uptodate" });
                            break;
                        default:
                            reject(result);
                    }
                })
                .catch(reject);
        });
    }
}
