export class AppColors {
    public static TimeRecording_BookedTimeColor: string = "var(--TimeRecording_BookedTimeColor)";
    public static TimeRecording_BookedTimeColor_light: string =
        "var(--TimeRecording_BookedTimeColor_light)";
    public static TimeRecording_UnbookedTimeColor: string =
        "var(--TimeRecording_UnbookedTimeColor)";
    public static TimeRecording_UnbookedTimeColor_dark: string =
        "var(--TimeRecording_UnbookedTimeColor_dark)";
    public static TimeRecording_AlertColor: string = "var(--TimeRecording_AlertColor)";

    public static WHITE_hex: string = "#FFFFFF";

    public static TimeRecording_IsSyncOnlyChangedInApp_Color_hex: string =
        "var(--timeRecording_isOnlyChangedInApp_Color)";
    public static TimeRecording_IsSyncOnlyChangedInApp_Color_hex_BG: string =
        "var(--timeRecording_isOnlyChangedInApp_Background_Color)";
}
