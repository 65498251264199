export class ChartUtil {
    /**
     * Gibt zurück, ob sich zwei Abschnitte auf der X-Achse überlagern.
     *
     * Wird beispielseweise verwendet um überlappende Labels in der Anwesenheitszeile auf der Buchungsübersicht zu finden.
     */
    public static xCoordinatesOverlap(x1, w1, x2, w2) {
        if (
            (x1 <= x2 && x1 + w1 >= x2) ||
            (x1 > x2 && x1 + w1 >= x2 + w2) ||
            (x1 > x2 && x1 + w1 < x2 + w2)
        ) {
            return true;
        } else {
            return false;
        }
    }
}
