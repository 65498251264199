import { ListViewModel } from "../../../gui/list/ListViewModel";
import { AllowanceListRowModel } from "./AllowanceListRowModel";
import { Allowance } from "../records/Allowance";
import { I18n } from "../../../common/i18n/I18n";

export class AllowancesListViewModel implements ListViewModel {
    private allowances: Allowance[];

    private allowancesById: { [key: string]: Allowance } = {};

    private i18n: I18n;

    constructor(allowances: Allowance[], i18n: I18n) {
        this.allowances = allowances;
        this.allowances.forEach(
            (allowance) => (this.allowancesById[allowance.getId()] = allowance),
        );
        this.i18n = i18n;
    }

    public countRows(): number {
        return this.allowances.length;
    }

    public getRowModelByNo(rowNo: number): AllowanceListRowModel {
        if (rowNo < 0 || rowNo >= this.allowances.length) {
            throw new Error(
                "AllowancesListViewModel::InvalidRowNo (" +
                    this.allowances.length +
                    " rows): " +
                    rowNo,
            );
        }
        return new AllowanceListRowModel(this.allowances[rowNo], this.i18n);
    }

    public getRowModelById(id: string): AllowanceListRowModel {
        if (!this.allowancesById.hasOwnProperty(id)) {
            throw new Error("[AllowancesListViewModel] Invalid id " + id);
        }
        return new AllowanceListRowModel(this.allowancesById[id], this.i18n);
    }

    public isResultTruncated(): boolean {
        return false;
    }
}
