import { Browser } from "../util/common/Browser";

/**
 * IndexedDB Query.
 *
 * Sehr eingeschränkte Möglichkeiten, Filterbedingungen zum Selektieren, Aktualisieren oder Löschen zu definieren.
 *
 * Statische Factory zum erzeugen einer Query mit exakt 1 Bedingung:
 *   gleich Wert
 *   größer Wert
 *   größer/gleich Wert
 *   kleiner Wert
 *   kleiner/gleich Wert
 *   kleiner Wert1 und größer Wert1
 *   kleiner Wert1 und größer/gleich Wert1
 *   kleiner/gleich Wert1 und größer Wert1
 *   kleiner/gleich Wert1 und größer/gleich Wert1
 */
export class IndexedDBQuery {
    public static only(onlyValues: any[] | any[][]): IDBKeyRange {
        onlyValues = IndexedDBQuery.joinValues(onlyValues);

        return IDBKeyRange.only(onlyValues);
    }

    public static greaterThan(
        lowerBoundValues: string[] | number[] | boolean[] | string[] | number[] | boolean[][],
    ) {
        lowerBoundValues = IndexedDBQuery.joinValues(lowerBoundValues);

        return IDBKeyRange.lowerBound(lowerBoundValues, true);
    }

    public static greaterOrEqual(lowerBoundValues: string[] | number[] | boolean[]) {
        lowerBoundValues = IndexedDBQuery.joinValues(lowerBoundValues);

        return IDBKeyRange.lowerBound(lowerBoundValues, false);
    }

    public static lesserThan(upperBoundValues: string[] | number[] | boolean[]) {
        upperBoundValues = IndexedDBQuery.joinValues(upperBoundValues);

        return IDBKeyRange.upperBound(upperBoundValues, true);
    }

    public static lesserOrEqual(upperBoundValues: string[] | number[] | boolean[]) {
        upperBoundValues = IndexedDBQuery.joinValues(upperBoundValues);

        return IDBKeyRange.upperBound(upperBoundValues, false);
    }

    public static greaterThanAndLesserThan(
        lowerBoundValues: string[] | number[] | boolean[],
        upperBoundValues: string[] | number[] | boolean[],
    ) {
        lowerBoundValues = IndexedDBQuery.joinValues(lowerBoundValues);
        upperBoundValues = IndexedDBQuery.joinValues(upperBoundValues);

        return IDBKeyRange.bound(lowerBoundValues, upperBoundValues, true, true);
    }

    public static greaterThanAndLesserOrEqual(
        lowerBoundValues: string[] | number[] | boolean[],
        upperBoundValues: string[] | number[] | boolean[],
    ) {
        lowerBoundValues = IndexedDBQuery.joinValues(lowerBoundValues);
        upperBoundValues = IndexedDBQuery.joinValues(upperBoundValues);

        return IDBKeyRange.bound(lowerBoundValues, upperBoundValues, true, false);
    }

    public static greaterOrEqualAndLesserThan(lowerBoundValues, upperBoundValues) {
        lowerBoundValues = IndexedDBQuery.joinValues(lowerBoundValues);
        upperBoundValues = IndexedDBQuery.joinValues(upperBoundValues);

        return IDBKeyRange.bound(lowerBoundValues, upperBoundValues, false, true);
    }

    public static greaterOrEqualAndLesserOrEqual(
        lowerBoundValues: string[] | number[] | boolean[],
        upperBoundValues: string[] | number[] | boolean[],
    ) {
        lowerBoundValues = IndexedDBQuery.joinValues(lowerBoundValues);
        upperBoundValues = IndexedDBQuery.joinValues(upperBoundValues);

        return IDBKeyRange.bound(lowerBoundValues, upperBoundValues, false, false);
    }

    private static joinValues(values: any[]): any {
        if (Browser.isInternetExplorer()) {
            return values.join("_");
        }
        return values;
    }
}
