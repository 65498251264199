import { GUIElement } from "../GUIElement";
import { ListViewContext } from "./ListViewContext";
import { TextLabel } from "../content/TextLabel";
import { ListRowContext } from "./ListRowContext";
import { ListField } from "./ListField";
import { ListFieldFeedback, ListFieldFeedbackType } from "./ListFieldFeedback";
import { HTMLLabel } from "../content/HTMLLabel";

// TODO App - Könnte stattdessen auch ListRowTextLabel mit contentType=html verwenden
export class ListRowHTMLLabel implements GUIElement, ListField {
    private listRowContext: ListRowContext;

    private labelKey: string;
    private directText: string;

    private styleClass: string = "";

    /**
     * @param listViewContext ListView-Kontext (I18n, Erzeugen von FormFields, ...)
     * @param listFieldSetRowDefinition Definition dieser ListFieldSet-Komponente als JSON ausgelesen aus einer Konfiguration
     */
    constructor(listRowContext: ListRowContext, listRowFieldDefinition?: object) {
        this.listRowContext = listRowContext;

        if (listRowFieldDefinition) {
            this.appyDefinition(listRowFieldDefinition);
        }
    }

    private appyDefinition(listRowFieldDefinition: object): void {
        this.labelKey = listRowFieldDefinition["label"];
        this.directText = listRowFieldDefinition["directText"];
        if (typeof listRowFieldDefinition["styleClass"] !== "undefined") {
            this.styleClass = listRowFieldDefinition["styleClass"];
        }
    }

    public compose($parent: JQuery): void {
        if (typeof this.labelKey !== "undefined") {
            new HTMLLabel()
                .addStyleClass(this.styleClass)
                .setText(this.listRowContext.getI18n().get(this.labelKey))
                .compose($parent);
        } else {
            new HTMLLabel()
                .addStyleClass(this.styleClass)
                .setText(this.directText)
                .compose($parent);
        }
    }

    public onUpdateValues(
        updateValuesCallback: (feedbackType: ListFieldFeedbackType) => void,
    ): void {
        // nichts zu tun
    }

    public updateValues(listFieldFeedback: ListFieldFeedback): void {
        // nichts zu tun
    }

    public getComponentChildren(): GUIElement[] {
        return [];
    }
}
