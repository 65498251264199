import { EntityValue } from "./EntityValue";
import { BCSDate } from "../../common/BCSDate";
import { DateValue } from "./DateValue";
import { TimeValue } from "./TimeValue";

export class DateTimeValue implements EntityValue {
    /**
     * ISO Date. z.b. 2018-01-12T13:27+01:00
     */
    private value: string;

    public static parseFromString(strValue: string) {
        return new DateTimeValue(strValue);
    }

    // TODO App - Bessere Handhabung der Zeitzone (z.B. +02:00) (?)
    public static fromDateAndTime(dateValue: DateValue, timeValue: TimeValue) {
        return new DateTimeValue(
            dateValue.getSimpleValue() +
                "T" +
                timeValue.getSimpleValue() +
                BCSDate.getLocalTimeZoneISOOffset(),
        );
    }

    public static fromDate(date: Date): DateTimeValue {
        date.setSeconds(0);
        date.setMilliseconds(0);
        return new DateTimeValue(date.toISOString());
    }

    /**
     * @return Jetziges Datum mit Uhrzeit (als DateTimeValue)
     */
    public static now(): DateTimeValue {
        return DateTimeValue.fromDate(new Date());
    }

    /**
     * @param minutes Ganze Minuten (z.B. 5) auf die das jetzige Datum mit Uhrzeit abngerundet werden soll
     * @return Auf ganze Minuten abgerundetes jetziges Datum mit Uhrzeit (als DateTimeValue)
     */
    public static roundedDownNow(minutes: number): DateTimeValue {
        const f = 1000 * 60 * minutes;
        return DateTimeValue.fromDate(new Date(Math.floor(new Date().getTime() / f) * f));
    }

    /**
     * Nur für den Import vom Werbservice und der Datenbank gedacht.
     */
    constructor(simpleValue: string) {
        this.value = simpleValue;
    }

    public isDefined(): boolean {
        return !!this.value;
    }

    public getSimpleValue(): string | number | boolean {
        return this.value;
    }

    public getString(): string {
        return this.value ? this.value : "";
    }

    public getISODate(): string {
        return this.value;
    }

    public getDateValue(): DateValue {
        if (!this.isDefined()) {
            return new DateValue(null);
        }

        // TODO App - moment js timezones mit ausliefern und verwenden: z.B. moment().tz("Europe/Berlin")
        // Lokales Date (in Zeitzone des mobnilen Gerätes) verwenden und dessen Tag/Monat/Jahr verwenden
        return DateValue.fromDate(this.getDate());
    }

    public getTimeValue(): TimeValue {
        if (!this.value) {
            return new TimeValue(null);
        }

        // TODO App - moment js timezones mit ausliefern und verwenden: z.B. moment().tz("Europe/Berlin")
        // Lokales Date (in Zeitzone des mobnilen Gerätes) verwenden und dessen Stunden/Minuten verwenden
        return TimeValue.fromMinutes(this.getDate().getHours() * 60 + this.getDate().getMinutes());
    }

    public getDate(): Date {
        return new Date(this.value);
    }

    public getBCSDate(): BCSDate {
        return this.isDefined() ? new BCSDate(new Date(this.value)) : null;
    }

    // TODO App - Alle Aufrufe sollten die Locale des eingeloggten Users übergeben, wenn sie z.B. den Wochentag-Namen ausgeben wollen
    public format(pattern: string, userLocale: string = "de"): string {
        return new BCSDate(this.getDate()).format(pattern, userLocale);
    }

    // TODO App - Alle Aufrufe sollten die Locale des eingeloggten Users übergeben, wenn sie z.B. den Wochentag-Namen ausgeben wollen
    public formatLongDate(userLocale: string = "de"): string {
        return new BCSDate(this.getDate()).formatLongDate(userLocale);
    }

    /**
     * @param userLocale Sprache des eingeloggten Benutzers
     * @return Formatiertes Datum im Langformat, z.B. für Locale "de" = "24.12." / "en" = "12/24"
     */
    public formatShortDate(userLocale: string = "de"): string {
        return new BCSDate(this.getDate()).formatShortDate(userLocale);
    }

    /**
     * @param userLocale Sprache des eingeloggten Benutzers
     * @return Formatiertes Datum mit Datum und Uhrzeit, z.B. für Locale "de" = "24.12.2018 12:00"
     */
    public formatDateTime(userLocale: string = "de"): string {
        return new BCSDate(this.getDate()).formatDateTime(userLocale);
    }

    // TODO App - Name an DateValue isEquals angleichen
    public equals(other: DateTimeValue): boolean {
        return this.getDate().getTime() == other.getDate().getTime();
    }

    // TODO App - Name an DateValue isBefore angleichen
    public before(other: DateTimeValue): boolean {
        return this.getDate().getTime() < other.getDate().getTime();
    }

    // TODO App - Name an DateValue isAfter angleichen
    public after(other: DateTimeValue): boolean {
        return this.getDate().getTime() > other.getDate().getTime();
    }

    public compare(other: DateTimeValue): number {
        return this.getDate().getTime() - other.getDate().getTime();
    }
}
