import { I18n } from "../../common/i18n/I18n";
import { ConfigNode } from "../../core/Config/ConfigNode";
import { AllowanceSummary } from "../../domain/allowances/AllowanceSummary";
import { AllowanceSummaryElement } from "../../domain/allowances/pages/board/AllowanceSummaryElement";
import { BoardElement } from "../../gui/board/BoardElement";
import { GUIContext } from "../../gui/GUIContext";

/*
<BoardElement PosX="4" PosY="1" SizeX="3" SizeY="2" Name="startContinueTravel" WholeContainerLinksTo="travel">
<AllowanceSummary Name="startContinueTravel"/>
<!-- <TextLabel I18nKey="MobileApp.overviewBoard.linkToAllowanceElement"/> -->
</BoardElement>
<BoardElement PosX="4" PosY="3" SizeX="3" SizeY="2" Name="countAndLinkAllowances" WholeContainerLinksTo="travels">
<AllowanceSummary Name="countAndLinkAllowances"/>
</BoardElement>
*/

/**
 * Spesen-Boardelement:
 *   - Anzahl Dienstreise / Einzelbelege - Link zu Spesenliste
 */
export class AllowanceSummaryBoardElement extends BoardElement {
    public static ELEMENT_NAME = "countAndLinkAllowances";

    private i18n: I18n;

    private allowanceSummaryModel: AllowanceSummary;

    constructor(
        allowanceSummaryModel: AllowanceSummary,
        i18n: I18n,
        boardElementConfig: ConfigNode,
        context: GUIContext,
    ) {
        super(boardElementConfig, context);
        this.name = AllowanceSummaryBoardElement.ELEMENT_NAME;

        this.xTiles = boardElementConfig.getIntAttribute("PosX", 4);
        this.yTiles = boardElementConfig.getIntAttribute("PosY", 1);
        this.widthTiles = boardElementConfig.getIntAttribute("SizeX", 3);
        this.heightTiles = boardElementConfig.getIntAttribute("SizeY", 2);

        this.wholeContainerLinksTo = "travels";
        this.styleClasses.push(this.name);

        this.i18n = i18n;
        this.allowanceSummaryModel = allowanceSummaryModel;
    }

    protected initializeChildren() {
        this.context.setPossibleContentHeight(
            this.currentTileHeight * this.heightTiles - this.CSS_ELEMENT_MARGIN * 2,
        );
        this.guiElements = [new AllowanceSummaryElement(this.allowanceSummaryModel, this.i18n)];
    }
}
