import { Schema } from "../../common/schema/Schema";
import { BusinessTravel } from "./records/BusinessTravel";
import { IndexedDB } from "../../database/IndexedDB";
import { VoucherAllowance } from "./records/VoucherAllowance";
import { AllowanceRecordingTerms } from "./AllowanceRecordingTerms";
import { IndexedDBQuery } from "../../database/IndexedDBQuery";
import { ApplicationProperties } from "../../common/properties/ApplicationProperties";
import { IndexedDBVersion } from "../../database/IndexedDBVersion";
import { Allowance } from "./records/Allowance";
import { BCSDate } from "../../common/BCSDate";
import { RootAllowance } from "./records/RootAllowance";

export class AllowancePool {
    private static RECORDING_TERMS_PROPERTY_KEY = "AllowanceRecordingTerms";

    private static ALLOWANCES_STORE_NAME = "allowances";

    /** DB-Index, um Dienstreisen/Einzelbelege zählen zu können */
    private static INDEX_USER_SUBTYPE = "user_subtype";

    /** DB-Index-Felder, um Dienstreisen/Einzelbelege zählen zu können */
    private static INDEX_USER_SUBTYPE_FIELDS = [BusinessTravel.USER_OID, "subtyp"];

    /** DB-Index, um Dienstreisen/Einzelbelege nach Startdatum/Belegdatum suchen bzw. nach Startdatum/Belegdatum sortieren zu können */
    private static INDEX_USER_START_DATE = "user_start_date";

    /** DB-Index-Felder, um Dienstreisen/Einzelbelege in einem Datumsbereich suchen bzw. nach Datum sortieren zu können */
    private static INDEX_USER_START_DATE_FIELDS = [
        BusinessTravel.USER_OID,
        BusinessTravel.START_DATE_TIME,
    ];

    /** DB-Index, um Dienstreisen/Einzelbelege nach Enddatum/Belegdatum suchen bzw. nach Enddatum/Belegdatum sortieren zu können */
    private static INDEX_USER_END_DATE = "user_end_date";

    /** DB-Index-Felder, um Dienstreisen/Einzelbelege in einem Datumsbereich suchen bzw. nach Datum sortieren zu können */
    private static INDEX_USER_END_DATE_FIELDS = [
        BusinessTravel.USER_OID,
        BusinessTravel.END_DATE_TIME,
    ];

    private static QUERY_DATE_MIN = "1970-01-01";

    private static QUERY_DATE_MAX = "2199-12-31";

    private indexedDB: IndexedDB;

    private applicationProperties: ApplicationProperties;

    private schema: Schema;

    private allowanceRecordingTerms: AllowanceRecordingTerms;

    constructor(
        indexedDB: IndexedDB,
        applicationProperties: ApplicationProperties,
        schema: Schema,
    ) {
        this.indexedDB = indexedDB;
        this.applicationProperties = applicationProperties;
        this.schema = schema;

        this.indexedDB
            .registerStore(AllowancePool.ALLOWANCES_STORE_NAME, IndexedDBVersion.DB_VERSION_1)
            .setIdKey("oid")
            .addIndex(
                AllowancePool.INDEX_USER_SUBTYPE,
                AllowancePool.INDEX_USER_SUBTYPE_FIELDS,
                false,
                IndexedDBVersion.DB_VERSION_1,
            )
            .addIndex(
                AllowancePool.INDEX_USER_START_DATE,
                AllowancePool.INDEX_USER_START_DATE_FIELDS,
                false,
                IndexedDBVersion.DB_VERSION_1,
            )
            .addIndex(
                AllowancePool.INDEX_USER_END_DATE,
                AllowancePool.INDEX_USER_END_DATE_FIELDS,
                false,
                IndexedDBVersion.DB_VERSION_1,
            );
    }

    public readAllowanceRecordingTermsFromDB(userOid: string): Promise<AllowanceRecordingTerms> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.applicationProperties
                .readProperty(AllowancePool.RECORDING_TERMS_PROPERTY_KEY, userOid, null)
                .then((recordingTermsProperty) => {
                    this.allowanceRecordingTerms = new AllowanceRecordingTerms(
                        recordingTermsProperty,
                    );
                    resolve(this.allowanceRecordingTerms);
                })
                .catch(reject);
        });
    }

    public writeAllowanceRecordingTermsToDB(
        userOid: string,
        allowanceRecordingTerms: AllowanceRecordingTerms,
    ): Promise<void> {
        this.allowanceRecordingTerms = allowanceRecordingTerms;

        const self = this;

        return new Promise((resolve, reject) => {
            const recordingTermsProperty = allowanceRecordingTerms.toValueObject();

            self.applicationProperties
                .writeProperty(
                    AllowancePool.RECORDING_TERMS_PROPERTY_KEY,
                    userOid,
                    null,
                    recordingTermsProperty,
                )
                .then(resolve)
                .catch(reject);
        });
    }

    public readAllowanceFromDB(oid: string, userOid: string): Promise<RootAllowance> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([AllowancePool.ALLOWANCES_STORE_NAME])
                .selectId(AllowancePool.ALLOWANCES_STORE_NAME, oid)
                .then(
                    (result) => {
                        if (result && result.element) {
                            const allowance = self.wrapAllowanceIntoDomainObject(result.element);
                            resolve(allowance.getUserOid() == userOid ? allowance : null);
                        } else {
                            resolve(null);
                        }
                    },
                    (error) => reject(error),
                );
        });
    }

    /**
     * Zählt in der DB vorhandene Dienstreisen bzw. Einzelbelege.
     *
     * @param userOid Oid des Benutzers
     * @param subtype Subtyp von Dienstreisen bzw. Einzelbelegen
     * @return Anzahl
     */
    public countAllowances(userOid: string, subtype: string): Promise<number> {
        const query = IndexedDBQuery.only([userOid, subtype]);

        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([AllowancePool.ALLOWANCES_STORE_NAME])
                .count(AllowancePool.ALLOWANCES_STORE_NAME, AllowancePool.INDEX_USER_SUBTYPE, query)
                .then(
                    (result) => (result ? resolve(result.count || 0) : resolve(0)),
                    (error) => reject(error),
                );
        });
    }

    /**
     * Liest alle Dienstreisen und Einzelbelege sortiert nach Startdatum/Belegdatum aus der IndexedDB.
     *
     * @param userOid Oid des Benutzers
     * @return Liste mit Dienstreisen und Einzelbelege (sortiert nach Startdatum/Belegdatum)
     */
    public readAllAllowancesFromDB(userOid: string): Promise<RootAllowance[]> {
        const query = IndexedDBQuery.greaterOrEqualAndLesserOrEqual(
            [userOid, AllowancePool.QUERY_DATE_MIN],
            [userOid, AllowancePool.QUERY_DATE_MAX],
        );

        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([AllowancePool.ALLOWANCES_STORE_NAME])
                .selectCursor(
                    AllowancePool.ALLOWANCES_STORE_NAME,
                    AllowancePool.INDEX_USER_START_DATE,
                    query,
                )
                .then(
                    (result) => {
                        if (result) {
                            const valuesObjects = result.resultSet;
                            const allowances = self.wrapAllowancesIntoDomainObjects(valuesObjects);
                            resolve(allowances);
                        } else {
                            resolve([]);
                        }
                    },
                    (error) => reject(error),
                );
        });
    }

    /**
     * Liest alle Dienstreisen und Einzelbelege sortiert nach Startdatum/Belegdatum aus der IndexedDB.
     *
     * @param userOid Oid des Benutzers
     * @return Liste mit Dienstreisen und Einzelbelege (sortiert nach Startdatum/Belegdatum)
     */
    public async queryAllowanceByDatesFromDB(
        userOid: string,
        queryStartDate: BCSDate,
        queryEndDate: BCSDate,
    ): Promise<{ businessTravels: BusinessTravel[]; voucherAllowances: VoucherAllowance[] }> {
        // Spesen suchen, die in den gesuchten Datumsbereich (Start-Ende) fallen:
        // a) Spesen, deren Ende >= Such-Startdatum (beginnen früher/jetzt, aber enden im oder nach Datumsbereich)
        // b) Spesen, deren Start <= Such-Enddatum (enden später/jetzt, aber beginnen im oder vor Datumsbereich)
        // Ergebnis = Schnittmenge aus beiden Abfragen
        const queryStartDateAllowanceIds = await this.queryAllowanceIdsByDateFromDB(
            userOid,
            DateField.END,
            QueryOperator.GREATER_OR_EQUAL,
            queryStartDate,
        );
        const queryEndDateAllowanceIds = await this.queryAllowanceIdsByDateFromDB(
            userOid,
            DateField.START,
            QueryOperator.LESSER_OR_EQUAL,
            queryEndDate,
        );
        const queryAllowanceIds = queryStartDateAllowanceIds.filter(
            (id) => queryEndDateAllowanceIds.indexOf(id) >= 0,
        );

        //AppConsole.log("------------------------------------------------------------------------------------------------");
        //AppConsole.log("----> TODAY END >= " + queryStartDate.getISODatTime() + " => " + queryStartDateAllowanceIds);
        //AppConsole.log("----> TODAY START <= " + queryEndDate.getISODatTime() + " => " + queryEndDateAllowanceIds);
        //AppConsole.log("------------------------------------------------------------------------------------------------");

        const businessTravels: BusinessTravel[] = [];
        const singleVouchers: VoucherAllowance[] = [];
        for (let i = 0; i < queryAllowanceIds.length; i++) {
            const allowanceId = queryAllowanceIds[i];
            const allowance = await this.readAllowanceFromDB(allowanceId, userOid);

            switch (allowance.getSubtype()) {
                case BusinessTravel.SUBTYPE:
                    businessTravels.push(<BusinessTravel>allowance);
                    break;
                case VoucherAllowance.SUBTYPE:
                    singleVouchers.push(<VoucherAllowance>allowance);
                    break;
                default:
                    throw new Error(
                        "[AllowancePool] Unknown allowance subtype: " + allowance.getSubtype(),
                    );
            }
        }
        return { businessTravels: businessTravels, voucherAllowances: singleVouchers };
    }

    private queryAllowanceIdsByDateFromDB(
        userOid: string,
        dateField: DateField,
        queryOperator: QueryOperator,
        queryDate: BCSDate,
    ): Promise<string[]> {
        const dateIndexName =
            dateField == DateField.START
                ? AllowancePool.INDEX_USER_START_DATE
                : AllowancePool.INDEX_USER_END_DATE;
        let query: IDBKeyRange;
        switch (queryOperator) {
            case QueryOperator.GREATER_OR_EQUAL:
                query = IndexedDBQuery.greaterOrEqual([userOid, queryDate.getISODatTime()]);
                break;
            case QueryOperator.LESSER_OR_EQUAL:
                query = IndexedDBQuery.lesserOrEqual([userOid, queryDate.getISODatTime()]);
                break;
            default:
                throw new Error("[AllowancePool] Unknown query operator: " + queryOperator);
        }

        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([AllowancePool.ALLOWANCES_STORE_NAME])
                .selectIdCursor(AllowancePool.ALLOWANCES_STORE_NAME, dateIndexName, query)
                .then(
                    (result) => (result ? resolve(result.resultSet) : resolve([])),
                    (error) => reject(error),
                );
        });
    }

    public writeAllowanceToDB(allowance: Allowance): Promise<void> {
        return this.writeAllowancesToDB([allowance]);
    }

    public writeAllowancesToDB(allowances: Allowance[]): Promise<void> {
        if (allowances.length == 0) {
            return Promise.resolve();
        }

        const valuesObjects = allowances.map((allowance) => allowance.toValueObject());

        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([AllowancePool.ALLOWANCES_STORE_NAME])
                .updateElements(AllowancePool.ALLOWANCES_STORE_NAME, valuesObjects)
                .then(resolve, reject);
        });
    }

    public deleteAllowancesFromDB(allowanceIds: string[]): Promise<void> {
        if (allowanceIds.length == 0) {
            return Promise.resolve();
        }

        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([AllowancePool.ALLOWANCES_STORE_NAME])
                .deleteIds(AllowancePool.ALLOWANCES_STORE_NAME, allowanceIds)
                .then(resolve, reject);
        });
    }

    public importAllAllowancesToDB(allowancesValueObjects: object[]): Promise<void> {
        if (allowancesValueObjects.length == 0) {
            return Promise.resolve();
        }

        // ValuesObjekte (Daten im JSON-Format) müssen vorher in Domain-OPbjekte verpackt werden,
        // damit diese Daten ergänzen können (z.B. leere Kilometergelder, Übernachtungen, Reisezeiten)
        allowancesValueObjects = this.wrapAllowancesIntoDomainObjects(allowancesValueObjects).map(
            (allowance) => allowance.toValueObject(),
        );

        const self = this;
        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([AllowancePool.ALLOWANCES_STORE_NAME])
                .updateElements(AllowancePool.ALLOWANCES_STORE_NAME, allowancesValueObjects)
                .then(resolve, reject);
        });
    }

    private wrapAllowancesIntoDomainObjects(allowanceValueObjects: object[]): RootAllowance[] {
        if (!allowanceValueObjects) {
            return [];
        }

        const allowances: RootAllowance[] = [];

        for (let i = 0; i < allowanceValueObjects.length; i++) {
            allowances.push(this.wrapAllowanceIntoDomainObject(allowanceValueObjects[i]));
        }

        return allowances;
    }

    private wrapAllowanceIntoDomainObject(allowanceValueObject: object): RootAllowance {
        if (!allowanceValueObject) {
            return null;
        }

        switch (allowanceValueObject["subtyp"]) {
            case BusinessTravel.SUBTYPE:
                return new BusinessTravel(
                    this.allowanceRecordingTerms,
                    this.schema,
                    allowanceValueObject,
                );
            case VoucherAllowance.SUBTYPE:
                const typeSubtypeDefinition = this.schema.getTypeSubtypeDefinition(
                    VoucherAllowance.TYPE,
                    VoucherAllowance.SUBTYPE,
                );
                return new VoucherAllowance(
                    null,
                    this.allowanceRecordingTerms,
                    typeSubtypeDefinition,
                    allowanceValueObject,
                );
            default:
                throw new Error(
                    "[AllowancePool] Unknown allowance subtype: " + allowanceValueObject["subtyp"],
                );
        }
    }
}

enum DateField {
    START = "start",
    END = "end",
}

enum QueryOperator {
    GREATER_OR_EQUAL = ">=",
    LESSER_OR_EQUAL = "<=",
}
