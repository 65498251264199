import { EntityValue } from "../../entities/values/EntityValue";
import { Entity } from "../../entities/Entity";
import { AttributeDefinition } from "../schema/AttributeDefinition";

export abstract class Preferences {
    preferenceEntity: Entity;
    public getId(): string {
        return this.preferenceEntity.getId();
    }

    public getSubtype(): string {
        return this.preferenceEntity.getString("subtyp");
    }

    public getValue(name: string): EntityValue {
        return this.preferenceEntity.getValue(name);
    }
    public setValue(name: string, value: EntityValue): void {
        this.preferenceEntity.setValue(name, value);
    }

    public toString(): string {
        return this.getId() + "(" + this.getSubtype() + ") ";
    }

    public toValueObject(): object {
        return this.preferenceEntity.toValueObject();
    }

    public abstract getAttributeDefinition(name: string): AttributeDefinition;
}
