import { LoginClient, PingResult } from "../common/auth/LoginClient";
import { AppConsole } from "../common/log/AppConsole";
import { Log } from "../common/log/Log";
import { ProgressFeedback } from "../util/progress/ProgressFeedback";
import { Component } from "./Component";
import { Registry } from "./Registry";
import { SingletonComponent } from "./SingletonComponent";

export class SingletonManager implements Component, SingletonComponent {
    /** Symbolischer Name dieser Komponente */
    public static readonly BCS_COMPONENT_NAME = "SingletonManager";

    private singletonsByName: { [key: string]: SingletonComponent };

    public getDependencyNames(): string[] {
        return [];
    }

    public init(depencencyComponents: { [key: string]: Component }): void {}

    public start(): Promise<void> {
        return Promise.resolve();
    }

    public notifyBeginUserSession(isOnline: boolean): Promise<void> {
        return Promise.resolve();
    }

    public synchronize(): Promise<void> {
        return Promise.resolve();
    }

    public setSingletons(singletonsByName: { [key: string]: SingletonComponent }): void {
        this.singletonsByName = singletonsByName;
    }

    /**
     * Startet alle Singletons (z.B. zum Offline-Daten aus DB lesen) in der Reihenfolge ihrer Abhängigkeiten.
     * @return Promise
     */
    public startSingletons(): Promise<void> {
        const singletonProcessor = new SingletonProcessor(this.singletonsByName);
        return singletonProcessor.startSingletons();
    }

    /**
     * Informiert alle Singletons dass sich ein Benutzer eingeloggt hat (damit sie ggf. dessen Daten aus der DB lesen)
     * in der Reihenfolge ihrer Abhängigkeiten.
     * @param isOnline wenn true ist die App online
     * @return Promise
     */
    public notifySingletonsBeginUserSession(
        isOnline: boolean,
        progressFeedback: ProgressFeedback,
    ): Promise<void> {
        const singletonProcessor = new SingletonProcessor(this.singletonsByName, progressFeedback);
        return singletonProcessor.notifySingletonsBeginUserSession(isOnline);
    }

    /**
     * Synchronisiert alle Singletons mit BCS (Daten lesen/senden) in der Reihenfolge ihrer Abhängigkeiten.
     *
     * @param progressFeedback Fortschrittsrückmeldung
     * @return Promise<SyncResult> ob Sync erfolgreich / Offline / Login notwendig
     */
    public async synchronizeSingletons(progressFeedback: ProgressFeedback): Promise<SyncResult> {
        const pingResult = await new LoginClient().status();
        switch (pingResult) {
            case PingResult.REACHABLE_ACTIVE_SESSION:
                const singletonProcessor = new SingletonProcessor(
                    this.singletonsByName,
                    progressFeedback,
                );
                await singletonProcessor.synchronizeSingletons();
                return SyncResult.SYNC_SUCCESS;
            case PingResult.REACHABLE_NO_SESSION:
                return SyncResult.NOSYNC_NO_SESSION;
            case PingResult.NOT_REACHABLE:
                return SyncResult.NOSYNC_OFFLINE;
            case PingResult.SERVER_ERROR:
            default:
                return SyncResult.NOSYNC_ERROR;
        }
    }
}

/**
 * Sync Ergebnis
 *
 * Darf nicht const sein, da sonst im generierten Javascript nicht mehr verwendbar (z.B. für switch)
 */
export enum SyncResult {
    SYNC_SUCCESS = "sync_success",
    NOSYNC_OFFLINE = "nosync_offline",
    NOSYNC_NO_SESSION = "nosync_nosession",
    NOSYNC_ERROR = "nosync_error",
}

class SingletonProcessor {
    private singletonsByName: { [key: string]: SingletonComponent };

    private unsortedSingletonNames: string[];

    private processingSingletonNames: { [key: string]: boolean } = {};

    private sortedSingletonNames: string[] = [];

    private totalCountSingletons = 0;

    private processedCountSingletons = 0;

    private progressFeedback: ProgressFeedback;

    constructor(
        singletonsByName: { [key: string]: SingletonComponent },
        progressFeedback?: ProgressFeedback,
    ) {
        this.singletonsByName = singletonsByName;
        this.unsortedSingletonNames = Object.keys(singletonsByName);
        this.totalCountSingletons = this.unsortedSingletonNames.length;

        this.progressFeedback = progressFeedback;
    }

    public startSingletons(): Promise<void> {
        const self = this;
        return new Promise((resolve, reject) => {
            self.sortSingletonsByDependency();
            self.processNextSingleton("start", false, resolve, reject);
        });
    }

    public notifySingletonsBeginUserSession(isOnline: boolean): Promise<void> {
        const self = this;
        return new Promise((resolve, reject) => {
            self.sortSingletonsByDependency();
            self.processNextSingleton("notifyBeginUserSession", isOnline, resolve, reject);
        });
    }

    public synchronizeSingletons(): Promise<void> {
        const self = this;
        return new Promise((resolve, reject) => {
            self.sortSingletonsByDependency();
            self.processNextSingleton("synchronize", true, resolve, reject);
        });
    }

    private sortSingletonsByDependency(): void {
        while (this.unsortedSingletonNames.length > 0) {
            this.sortNextSingleton(this.unsortedSingletonNames.shift());
        }
    }

    private sortNextSingleton(singletonName: string) {
        if (this.sortedSingletonNames.indexOf(singletonName) >= 0) {
            return;
        }

        if (this.processingSingletonNames[singletonName] === true) {
            throw new Error("[SingletonManager] Cyclic dependency: " + singletonName);
        }
        this.processingSingletonNames[singletonName] = true;

        const singleton = this.singletonsByName[singletonName];
        AppConsole.log(singletonName);
        const depencencyNames = singleton.getDependencyNames();

        for (const depencencyName of depencencyNames) {
            // Falls Dependency ein Singleton ist, ...
            if (this.singletonsByName.hasOwnProperty(depencencyName)) {
                this.sortNextSingleton(depencencyName);
            }
        }

        this.sortedSingletonNames.push(singletonName);
    }

    private processNextSingleton(
        processFunction: string,
        isOnline: boolean,
        resolve,
        reject,
    ): Promise<void> {
        if (this.progressFeedback) {
            this.progressFeedback.notifyProgress(
                this.processedCountSingletons++,
                this.totalCountSingletons,
            );
        }

        if (this.sortedSingletonNames.length == 0) {
            resolve();
            return;
        }

        const singletonName = this.sortedSingletonNames.shift();
        const singleton = this.singletonsByName[singletonName];

        Log.trace("[SingletonManager] " + processFunction + " singleton", { name: singletonName });

        const self = this;
        switch (processFunction) {
            case "start":
                singleton
                    .start()
                    .then(() => {
                        self.processNextSingleton(processFunction, isOnline, resolve, reject);
                    })
                    .catch((error) => {
                        Log.error("[SingletonManager] " + processFunction + " singleton failed", {
                            Singleton: singletonName,
                            Error: error,
                        });
                        self.processNextSingleton(processFunction, isOnline, resolve, reject);
                    });
                break;
            case "notifyBeginUserSession":
                singleton
                    .notifyBeginUserSession(
                        isOnline,
                        this.progressFeedback.getSubProgressFeedback(),
                    )
                    .then(() => {
                        self.processNextSingleton(processFunction, isOnline, resolve, reject);
                    })
                    .catch((error) => {
                        // TODO: Auf Timeout Error prüfen (Error Message "timeout") und als Meldung an die GUI werfen. Wird in diesem Kontext ggf. schwierig
                        Log.error("[SingletonManager] " + processFunction + " singleton failed", {
                            Singleton: singletonName,
                            Error: error,
                        });
                        self.processNextSingleton(processFunction, isOnline, resolve, reject);
                    });
                break;
            case "synchronize":
                singleton
                    .synchronize(this.progressFeedback.getSubProgressFeedback())
                    .then(() => {
                        self.processNextSingleton(processFunction, isOnline, resolve, reject);
                    })
                    .catch((error) => {
                        Log.error("[SingletonManager] " + processFunction + " singleton failed", {
                            Singleton: singletonName,
                            Error: error,
                        });
                        self.processNextSingleton(processFunction, isOnline, resolve, reject);
                    });
                break;
            default:
                reject({ error: "Undefined singleton process function: " + processFunction });
        }
    }
}

Registry.registerSingletonComponent(SingletonManager.BCS_COMPONENT_NAME, SingletonManager);
