import { IndexedDB } from "../../database/IndexedDB";
import { IndexedDBVersion } from "../../database/IndexedDBVersion";
import { UserSession } from "../auth/UserSession";
import { AppConsole } from "../log/AppConsole";
import { ApplicationProperties } from "../properties/ApplicationProperties";
import { Schema } from "../schema/Schema";
import { Preferences } from "./Preferences";
import { PreferencesClient } from "./PreferencesClient";
import { ServerPreferences } from "./ServerPreferences";

export class PreferencesPool {
    public static PREFERENCES_STORE_NAME = "preferences";

    private perferencesUpdateAge: number;

    private indexedDB: IndexedDB;

    private schema: Schema;

    private applicationProperties: ApplicationProperties;

    private userSession: UserSession;

    constructor(
        schema: Schema,
        applicationProperties: ApplicationProperties,
        indexedDB: IndexedDB,
        userSession: UserSession,
    ) {
        this.schema = schema;

        this.applicationProperties = applicationProperties;

        this.indexedDB = indexedDB;

        this.userSession = userSession;
    }

    getServerSidePreferences(): Promise<void> {
        const self = this;
        return new Promise((resolve, reject) => {
            new PreferencesClient()
                .fetchPrefernces(self.perferencesUpdateAge)
                .then((result) => {
                    AppConsole.debug(
                        "[Preferences] Preferences FETCHED",
                        result,
                        self.perferencesUpdateAge,
                    );

                    const preference = new ServerPreferences(this.schema, result);
                    //resolve(result);

                    self.writePreferencesToDB(preference, resolve, reject);
                })
                .catch(reject);
        });
    }

    public writePreferencesToDB(preference: Preferences, resolve, reject): void {
        const currentUser = this.userSession.getCurrentUserOid();

        this.applicationProperties
            .writeProperty(
                PreferencesPool.PREFERENCES_STORE_NAME,
                currentUser,
                null,
                preference.toValueObject(),
            )
            .then(resolve)
            .catch(reject);
    }
}
