import { GUIElement } from "../GUIElement";
import { Message } from "./Message";

export class MessageContainer implements GUIElement {
    private messageCounter: number = 0;

    private imageName: string = null;

    private text: string = null;

    private clickCallback: Function; // oder: () => void

    private guiElements: Message[] = [];

    private $messageContainerWrapper: JQuery;

    constructor() {}

    /**
     * HTML-DOM generieren
     *
     * @param $parent Parent-jQuery-Objekt
     */
    public compose($parent: JQuery): void {
        this.$messageContainerWrapper = $("<div id='messageContainer'>").appendTo($parent);

        for (let i = 0; i < this.guiElements.length; i++) {
            this.guiElements[i].compose(this.$messageContainerWrapper, this);
        }
    }

    private adhocCompose() {
        this.$messageContainerWrapper.empty();
        for (let i = 0; i < this.guiElements.length; i++) {
            this.guiElements[i].compose(this.$messageContainerWrapper, this);
        }
    }

    public addElement(guiElement: Message) {
        if (this.guiElements.length > 2) {
            this.deleteOlderMessages();
        }

        this.guiElements.push(guiElement);

        // Sofern MessageContainer schon composed, Meldungen sofort anzeigen
        if (this.$messageContainerWrapper) {
            this.adhocCompose();
        }
    }

    public cleanMessage() {
        this.guiElements = [];
    }

    public notifyClicked(event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        if (this.clickCallback) {
            this.clickCallback();
        }
    }

    getComponentChildren(): GUIElement[] {
        return this.guiElements;
    }

    /**
     * Methode eingeführt, damit nicht zu viele Messages den Container vermüllen. Steigt die Anzahl an Nachrichten über 3, wird die älteste gelöscht.
     * @author richard.franke
     */
    deleteOlderMessages(): void {
        const newestMessages: Message[] = [];
        newestMessages.push(this.guiElements[this.guiElements.length - 2]);
        newestMessages.push(this.guiElements[this.guiElements.length - 1]);
        this.guiElements = newestMessages;
    }
}
