import { GUIElement } from "../GUIElement";
import { ConfigNode } from "../../core/Config/ConfigNode";
import { GUIContext } from "../GUIContext";

export class ControllerLink implements GUIElement {
    private onlyLink: string;

    private configNode: ConfigNode;

    public static BCS_COMPONENT_NAME = "ControllerLink";

    private context: GUIContext;

    constructor(configNode: ConfigNode, controller: GUIContext) {
        this.context = controller;

        //if(configNodeOrDirectLink instanceof String){
        //    this.onlyLink = configNodeOrDirectLink;
        //} else {
        this.configNode = configNode;
        //}
    }

    private contentElements: GUIElement[] = [];

    private clickCallback: Function;

    public addContentElement(contentElement: GUIElement): ControllerLink {
        this.contentElements.push(contentElement);
        return this;
    }

    public onClick(clickCallback: Function): ControllerLink {
        this.clickCallback = clickCallback;
        return this;
    }

    public compose($parent: JQuery): void {
        const $listRowTag = $("<div>")
            .appendTo($parent)
            .addClass("controllerLink")
            .css("cursor", "pointer")
            .on("click", this.linkClicked.bind(this));

        for (let i = 0; i < this.contentElements.length; i++) {
            this.contentElements[i].compose($listRowTag);
        }
    }

    private linkClicked(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        const navigate = this.configNode.getAttribute("WholeContainerLinksTo", null);

        this.context.triggerEvent(ControllerLink.BCS_COMPONENT_NAME, "click", event, {
            navigate: navigate,
        });
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}
