import { SchemaDataType } from "../../../../common/schema/SchemaDataType";
import { EntityValue } from "../../../../entities/values/EntityValue";
import { TravelSection } from "../../records/TravelSection";
import { DailyAllowance } from "../../records/DailyAllowance";
import { ListRowModel } from "../../../../gui/list/ListRowModel";
import { StringValue } from "../../../../entities/values/StringValue";
import { AttributeDefinition } from "../../../../common/schema/AttributeDefinition";
import { AllowanceListRowModel } from "../AllowanceListRowModel";
import { I18n } from "../../../../common/i18n/I18n";

export class BusinessTravelSectionListRowModel implements ListRowModel {
    private travelSection: TravelSection;

    private i18n: I18n;

    constructor(travelSection: TravelSection, i18n: I18n) {
        this.travelSection = travelSection;
    }

    public getEntityId(name?: string): string {
        const subtypeAndAttribute = this.parseSubtypeAndAttribute(name);

        const travelAllowance = this.travelSection.getTravelAllowanceBySubtype(
            subtypeAndAttribute.subtype,
        );
        return travelAllowance.getId();
    }

    public getDataType(name: string): SchemaDataType {
        //let attributeDefinition = this.getAttributeDefinition(name);
        //return attributeDefinition ? attributeDefinition.getDataType() : SchemaDataType.STRING;

        return this.getAttributeDefinition(name).getDataType();
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        const subtypeAndAttribute = this.parseSubtypeAndAttribute(name);
        const travelAllowance = this.travelSection.getTravelAllowanceBySubtype(
            subtypeAndAttribute.subtype,
        );
        //return travelAllowance.getAttributeDefinition();
        return new AllowanceListRowModel(travelAllowance, this.i18n).getAttributeDefinition(
            subtypeAndAttribute.attribute,
        );
    }

    public getValue(name: string): EntityValue {
        const subtypeAndAttribute = this.parseSubtypeAndAttribute(name);

        const travelAllowance = this.travelSection.getTravelAllowanceBySubtype(
            subtypeAndAttribute.subtype,
        );
        //return travelAllowance.getValue(subtypeAndAttribute.attribute);
        return new AllowanceListRowModel(travelAllowance, this.i18n).getValue(
            subtypeAndAttribute.attribute,
        );
    }

    public setValue(name: string, value: EntityValue): void {
        const subtypeAndAttribute = this.parseSubtypeAndAttribute(name);

        const travelAllowance = this.travelSection.getTravelAllowanceBySubtype(
            subtypeAndAttribute.subtype,
        );
        travelAllowance.setValue(subtypeAndAttribute.attribute, value);
    }

    public isEditable(): boolean {
        return this.travelSection.isEditable();
    }

    public isDeletable(): boolean {
        return false;
    }

    private parseSubtypeAndAttribute(name: string): { subtype: string; attribute: string } {
        const x = name ? name.indexOf(":") : -1;
        const subtype = x < 0 ? DailyAllowance.SUBTYPE : name.substring(0, x);
        const attribute = x < 0 ? name : name.substring(x + 1);
        return { subtype: subtype, attribute: attribute };
    }
}
