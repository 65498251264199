import { ListViewModel } from "../../../gui/list/ListViewModel";
import { ListRowModel } from "../../../gui/list/ListRowModel";
import { SchemaDataType } from "../../../common/schema/SchemaDataType";
import { AttributeDefinition } from "../../../common/schema/AttributeDefinition";
import { EntityValue } from "../../../entities/values/EntityValue";
import { ForecastRecord } from "./ForecastRecord";

export class ForecastListViewModel implements ListViewModel {
    public static forecastPrefix = "forecast__";
    private dummyForeCast: ForecastRecord;

    constructor(dummyForeCast: ForecastRecord) {
        this.dummyForeCast = dummyForeCast;
    }

    public countRows(): number {
        return 1;
    }

    public getRowModelByNo(rowNo: number): ListRowModel {
        return new ForecastListRowModel(this.dummyForeCast);
    }

    public getRowModelById(entityId: string): ListRowModel {
        return new ForecastListRowModel(this.dummyForeCast);
    }

    public isResultTruncated(): boolean {
        return false;
    }
}

export class ForecastListRowModel implements ListRowModel {
    private dummyForeCast: ForecastRecord;

    constructor(dummyForeCast: ForecastRecord) {
        this.dummyForeCast = dummyForeCast;
    }

    public getEntityId(name?: string): string {
        return this.dummyForeCast.getAttachmentOid();
    }

    public getDataType(name: string): SchemaDataType {
        if (name === "pspPath") {
            return SchemaDataType.LSTRING;
        }
        return this.getAttributeDefinition(name).getDataType();
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        if (name === "pspPath") {
            return this.dummyForeCast.getAttributeDefinition("oid");
        }
        return this.dummyForeCast.getAttributeDefinition(name);
    }

    public getValue(name: string): EntityValue {
        if (name === "pspPath") {
            return this.dummyForeCast.getPspDisplayPath();
        }
        return this.dummyForeCast.getValue(name);
    }

    public setValue(name: string, value: EntityValue): void {
        this.dummyForeCast.setValue(name, value);
    }

    public isEditable(): boolean {
        return true;
    }

    public isDeletable(): boolean {
        return false;
    }
}
