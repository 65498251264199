import { IndexedDB } from "../../database/IndexedDB";
import { IndexedDBVersion } from "../../database/IndexedDBVersion";
import { IndexedDBQuery } from "../../database/IndexedDBQuery";
import { BCSFile } from "./records/BCSFile";

export class FilePool {
    private static FILE_STORE_NAME = "files";

    private static INDEX_FILE_COMPONENT = "filecomponent";

    private static INDEX_FILE_COMPONENT_FIELDS = [BCSFile.FILE_COMPONENT_OID];

    private indexedDB: IndexedDB;

    constructor(indexedDB: IndexedDB) {
        this.indexedDB = indexedDB;

        this.indexedDB
            .registerStore(FilePool.FILE_STORE_NAME, IndexedDBVersion.DB_VERSION_1)
            .setIdKey(BCSFile.FILE_ID)
            .addIndex(
                FilePool.INDEX_FILE_COMPONENT,
                FilePool.INDEX_FILE_COMPONENT_FIELDS,
                false,
                IndexedDBVersion.DB_VERSION_1,
            );
    }

    public readFileFromDB(fileId: string): Promise<BCSFile> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([FilePool.FILE_STORE_NAME])
                .selectId(FilePool.FILE_STORE_NAME, fileId)
                .then(
                    (result) => {
                        if (result && result.element) {
                            resolve(new BCSFile(result.element));
                        } else {
                            resolve(undefined);
                        }
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    public readFilesFromDB(fileComponentOid: string): Promise<BCSFile[]> {
        const query = IndexedDBQuery.only([fileComponentOid]);

        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([FilePool.FILE_STORE_NAME])
                .selectCursor(FilePool.FILE_STORE_NAME, FilePool.INDEX_FILE_COMPONENT, query)
                .then(
                    (result) => {
                        if (result && result.resultSet) {
                            const valuesObjects = result.resultSet;

                            const files: BCSFile[] = [];
                            valuesObjects.forEach((element) => files.push(new BCSFile(element)));
                            resolve(files);
                        } else {
                            resolve([]);
                        }
                    },
                    (error) => {
                        reject(error);
                    },
                );
        });
    }

    public readFileIdsFromDB(fileComponentOid: string): Promise<string[]> {
        const query = IndexedDBQuery.only([fileComponentOid]);

        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readOnlyTransaction([FilePool.FILE_STORE_NAME])
                .selectIdCursor(FilePool.FILE_STORE_NAME, FilePool.INDEX_FILE_COMPONENT, query)
                .then(
                    (result) => resolve(result && result.resultSet ? result.resultSet : null),
                    (error) => reject(error),
                );
        });
    }

    public writeFileToDB(file: BCSFile): Promise<void> {
        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([FilePool.FILE_STORE_NAME])
                .updateElements(FilePool.FILE_STORE_NAME, [file.toValueObject()])
                .then(resolve, reject);
        });
    }

    public deleteFilesFromDB(fileIds: string[]): Promise<void> {
        if (fileIds.length == 0) {
            return Promise.resolve();
        }

        const self = this;

        return new Promise((resolve, reject) => {
            self.indexedDB
                .getConnection()
                .readWriteTransaction([FilePool.FILE_STORE_NAME])
                .deleteIds(FilePool.FILE_STORE_NAME, fileIds)
                .then(resolve, reject);
        });
    }
}
